import { defineStore } from 'pinia'
import { ref } from 'vue'
import useVuexModule from '@shared/composables/vuexModule.js'

// TODO: Partial implementation (ENG-2846)
export const useClientStore = defineStore('client', () => {
  const client = useVuexModule('client')

  const isAuthenticated = ref(false)

  return {
    isAuthenticated,
    ...client
  }
})
