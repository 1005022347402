<template>
  <feed-item-deeplink-mobile
    v-if="isMobile"
    :feed-item="look"
    :feed-item-loading="lookLoading"
    :feed-item-load-error="lookLoadError"
    :feed-item-comments="[]"
    :comments-loading="false"
    :comments-loading-error="false"/>
  <feed-item-deeplink-desktop
    v-else
    :feed-item="look"
    :feed-item-loading="lookLoading"
    :feed-item-load-error="lookLoadError"
    :feed-item-comments="[]"
    :comments-loading="false"
    :comments-loading-error="false"/>
</template>

<script>
import FeedItemDeeplinkDesktop from './FeedItemDeeplinkDesktop'
import FeedItemDeeplinkMobile from './FeedItemDeeplinkMobile'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'LookDeeplink',
  components: {
    FeedItemDeeplinkDesktop,
    FeedItemDeeplinkMobile
  },
  data () {
    return {
      lookId: this.$route.params.id,
      lookLoading: true,
      lookLoadError: false
    }
  },
  computed: {
    ...mapState('community', [
      'clientLooksById'
    ]),
    look () {
      return this.clientLooksById[this.lookId]
    }
  },
  mounted () {
    if (!this.look) {
      Promise.resolve(this.getLook(this.lookId)).then(
        () => { this.lookLoading = false },
        () => {
          this.lookLoadError = true
          this.lookLoading = false
        }
      )
    } else {
      this.lookLoading = false
    }
  },
  methods: {
    ...mapActions('community', [
      'getLook'
    ])
  }
}
</script>
