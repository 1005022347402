import store from '../store/index'
import logger from '@/logger'

export function track (eventName, eventProperties = {}) {
  if (typeof window.analytics !== 'undefined') {
    try {
      if (store.state.client?.email) {
        if (!store.state.client.reportEvents) return
        eventProperties.email = store.state.client.email
      }
      logger.debug('track() analytics plugin', eventName, eventProperties)
      window.analytics.track(eventName, eventProperties)
    } catch (e) {
      logger.error('track() error', e)
    }
  }
}

export function page (client, path) {
  if (typeof window.analytics !== 'undefined') {
    try {
      logger.debug('page() analytics plugin', path)
      window.analytics.page(path, {}, { Intercom: { user_hash: client?.clientHash } })
    } catch (e) {
      logger.error('page() error', e)
    }
  }
}

export function identify (client, callback = () => {}) {
  if (typeof window.analytics !== 'undefined' && client.isAuthenticated) {
    // eslint-disable-next-line
    if (client.reportEvents) {
      let shippingAddress = ''
      if (client.shippingAddress) {
        const a = client.shippingAddress
        const addressLine2 = a.addressLine2 ? ` ${a.addressLine2}` : ''
        shippingAddress = `${a.addressLine1}${addressLine2}, ${a.city}, ${a.state} ${a.zipcode}`
      }
      const plan = client.plans?.currentBasePlan ? client.plans.currentBasePlan.name : ''
      const phone = client.mainPhone?.replace(/-/g, '')
      // NOTE:  Keep this in sync with the backend identify call properties as much as possible
      const properties = {
        babyDate: client.babyDate,
        babyDate_at: client.babyDate,
        birthDate: client.birthDate,
        birthDate_at: client.birthDate,
        caseSize: client.caseSize,
        email: client.email,
        firstName: client.firstName,
        heightFeet: client.heightFeet,
        heightInches: client.heightInches,
        influencer: client.influencer,
        isStaff: client.isStaff,
        lastName: client.lastName,
        leadStage: client.leadStage,
        leadSource: client.leadSource,
        leadSourceDetail: client.leadSourceDetail,
        member: client.membershipStatus === 'active',
        paidThrough: client.paidThrough,
        paidThrough_at: client.paidThrough,
        phone: phone,
        phone_number: phone,
        plan: plan,
        shippingAddress: shippingAddress,
        subscriptionStatus: client.membershipStatus,
        userId: client.username,
        zipCode: client.zipCode
      }
      logger.debug('identify plugin', client.username, properties)
      window.analytics.identify(
        client.username,
        properties,
        {
          Intercom: {
            user_hash: client.clientHash
          }
        },
        callback
      )
    } else {
      callback()
    }
  }
}
