<template>
  <div>
    <size-selection
      :pdp-sizing-categories="sizingCategories"
      :initial-selections="initialSelection"
      emit-ref="pdp"
      source="pdp"
      :container-class="!isMobile ? 'size-container' : 'size-container-mobile'"
      :variant="variant"
      @update-item-selection="updateSizeSelection"/>
  </div>
</template>

<script>
import SizeSelection from '../global/sequin/SizeSelection.vue'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'

export const variants = {
  default: 'default',
  // Compact limits the displayed sizes to what's
  // in the user's style profile.
  compact: 'compact'
}

export default {
  components: {
    SizeSelection
  },
  mixins: [
    StyleColorMixin
  ],
  props: {
    styleColor: {
      type: Object,
      default: null
    },
    variant: {
      type: String,
      default: variants.default,
      validator: value => Object.values(variants).includes(value)
    }
  },
  data: function () {
    return {
      selectedItemType: null
    }
  },
  computed: {
    compactVariant () {
      return this.variant === variants.compact
    },
    sizingCategories () {
      if (this.compactVariant) {
        return [{
          value: 'compact',
          sizes: this.allItemTypesSortedBySizeFormatted.filter(size =>
            size.isMemberSize ||
            size.recommended ||
            this.styleColor.profileSizes.includes(size.trueSize))
        }]
      }

      if (this.isMobile) {
        return [{
          value: 'allSizeCategories',
          sizes: this.allItemTypesSortedBySizeFormatted
        }]
      }

      const categories = []
      if (this.hasStandardItemTypes) {
        categories.push({
          text: 'Standard',
          value: 'standardSizes',
          sizes: this.standardItemTypes
        })
      }
      if (this.waistSizeInInchesItemTypes) {
        categories.push({
          value: 'waistInches',
          sizes: this.waistSizeInInchesItemTypes
        })
      }
      if (this.hasMaternityItemTypes) {
        categories.push({
          text: 'Maternity',
          value: 'maternity',
          sizes: this.maternityItemTypes
        })
      }
      if (this.hasPlusItemTypes) {
        categories.push({
          text: 'Plus',
          value: 'plusSizes',
          sizes: this.plusItemTypes
        })
      }
      return categories
    },
    initialSelection () {
      return this.recommendedSize
    }
  },
  methods: {
    updateSizeSelection (selection) {
      if (!this.recommendedSizeLoaded) {
        return
      }
      this.selectedItemType = this.itemTypesSortedBySize?.find(itemType => itemType.trueSize === selection) ?? null
      this.$emit('update-size-selection', this.selectedItemType)
    }
  }
}
</script>

<style lang="scss" scoped>
.type-checkboxes {
  border-radius: 2px;
  border: 0.5px solid #520845;
  background: rgba(82, 8, 69, 0.10);
}
:deep(.checkbox-text) {
  color: #520845;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

:deep(.size-container) {
  display: grid;
  grid-template-columns: repeat(5, minmax(98px, 1fr));
  gap: 16px;
  padding-bottom: 12px;
}

:deep(.size-container-mobile) {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 16px;
}
</style>
