import axios from './config'
import store from '../index'

const apiStyleGames = {
  getUpcomingStylesToRate (offset = 0) {
    const username = store.state.client.username
    return axios.get('/api/clients/' + username + '/new_style_colors/?offset=' + offset)
  },
  getBeTheBuyerStyles () {
    return axios.get('/api/prebuystylecolors/?type=daily_game')
  },
  getBeTheBuyerDailySummary () {
    return axios.get('/api/prebuystylecolors/daily_summary/')
  },
  rateBeTheBuyerStyleColor (data) {
    return axios.post('/api/prebuystylecolorratings/', data)
  }
}

export default apiStyleGames
