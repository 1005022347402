import { defineStore } from 'pinia'
import { ref } from 'vue'
import useVuexModule from '@shared/composables/vuexModule.js'

// TODO: Partial implementation (ENG-2848)
export const useCollectionsStore = defineStore('collections', () => {
  const collections = useVuexModule('collections')

  const collectedStyleColors = ref([])

  return {
    collectedStyleColors,
    ...collections
  }
})
