<template>
  <accordion-with-header
    :expanded="expanded"
    :expanded-max-height="400"
    :plus-variant="!showMaternityOptions"
    title="Maternity Details"
    @accordion-click="e => $emit('accordion-click', e)">
    <template #subtitle>
      <p class="ms-2 mb-0 text-pewter">
        {{ getMaternityText }}
      </p>
    </template>
    <div>
      <div>
        <p>
          <span class="semi-bold">Congratulations!</span> When is your due date?
        </p>
        <style-quiz-due-date-input
          class="due-date-pick"
          @input="e => dueDateChange(e)"/>
      </div>
      <div
        v-if="showMaternityOptions"
        class="d-flex flex-wrap">
        <div>
          <base-checkboxes
            v-model="selectedMaternity"
            :options="maternityOptions"
            class="mt-2 d-flex flex-column align-items-start text-start"
            col-class="col-12 mb-2"/>
        </div>
      </div>
    </div>
  </accordion-with-header>
</template>

<script>
import { mapState } from 'vuex'
import { vuexAccessors } from '../global/helpers/vuex'
import AccordionWithHeader from '../global/sequin/AccordionWithHeader.vue'
import StyleQuizDueDateInput from '../styleQuiz/StyleQuizDueDateInput'
import BaseCheckboxes from '@/components/global/BaseCheckboxes.vue'

export default {
  components: {
    AccordionWithHeader,
    StyleQuizDueDateInput,
    BaseCheckboxes
  },
  props: {
    validations: {
      type: Object,
      required: true
    },
    expanded: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('styleProfile', [
      'maternityOptions'
    ]),
    ...vuexAccessors('styleProfile', [
      'dueDate',
      'selectedMaternity'
    ]),
    showMaternityOptions () {
      return this.dueDate && this.dueDate.length > 0 && !isNaN(new Date(this.dueDate))
    },
    getMaternityText () {
      if (this.showMaternityOptions) {
        // Validate and show an error?
        return `${this.dueDate}`
      } else {
        return ''
      }
    }
  },
  methods: {
    dueDateChange () {
      if (this.showMaternityOptions) {
        this.selectedMaternity = ['maternity', 'maternity_friendly']
        if (new Date(this.dueDate) < new Date()) this.selectedMaternity.push('nursing')
      } else {
        this.selectedMaternity = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.due-date-pick {
  max-width: 200px;
  margin-bottom: 12px;
}

.maternity-check {
  margin-right: 24px;
  width: 300px;
  margin-top: 12px;
}
</style>
