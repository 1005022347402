<template>
  <div
    class="available-now-toggle">
    <p class="mb-0 me-2 semi-bold small-text">
      Available Now
    </p>
    <SequinToggle
      :state="styleColorSet.available"
      @toggle-click="emit('on-available-now-toggle')"/>
  </div>
</template>

<script setup>
import SequinToggle from '@/components/global/sequin/SequinToggle.vue'

defineProps({
  styleColorSet: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['on-available-now-toggle'])
</script>

<style lang="scss" scoped>
.available-now-toggle {
  display: flex;
  flex: 0 1 auto;
  white-space: nowrap;
  margin-right: 12px;
}
</style>
