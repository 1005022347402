<template>
  <div>
    <div
      class="avatar position-relative"
      :class="`${size} ${bordered && 'bordered'} ${selected && 'selected'} ${announcement && 'announcement'}`">
      <img
        class="avatar-image"
        :alt="alt"
        :class="{'default': useDefault || avatarImage.includes('dress')}"
        :src="avatarImage">
      <div v-if="announcement">
        <img
          class="avatar-badge"
          :class="size"
          alt="Avatar announcement badge"
          :src="announcementBadge">
      </div>
      <div v-else-if="staff">
        <img
          class="avatar-badge"
          alt="Avatar staff badge"
          :class="size"
          :src="staffBadge">
      </div>
    </div>
  </div>
</template>

<script>
import dressImages from '../../global/mixins/dressImages'

export default {
  mixins: [dressImages],
  props: {
    profilePic: {
      type: String,
      default: null
    },
    clientId: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium'
    },
    staff: {
      type: Boolean,
      default: false
    },
    announcement: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    alt: {
      type: String,
      default: 'Avatar image'
    }
  },
  computed: {
    useDefault () {
      return this.profilePic === null || this.profilePic === ''
    },
    avatarImage () {
      return this.useDefault ? this.defaultAvatar : this.profilePic
    },
    defaultAvatar () {
      return this.getDressUrlFromClientId(this.clientId)
    },
    staffBadge () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}staff-badge.png`
    },
    announcementBadge () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}announcement-badge.png`
    }
  }
}
</script>

<style lang="scss" scoped>

.avatar-badge {
  position: absolute;

  &.tiny, &.small, &.medium {
    width: 20px;
    height: 20px;
    right: -4;
    bottom: -2;
  }

  &.large {
    width: 24px;
    height: 24px;
    right: -2;
    bottom: -4;
  }

  &.x-large {
    width: 28px;
    height: 28px;
    right: 0;
    bottom: -4;
  }

}

.avatar {
  border-radius: 50%;
  margin: 0 auto;
  background: $origami;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar-image {
    object-fit: cover;
    border-radius: 50%;
  }

  &.bordered {
    border: 1px solid $white;
  }

  &.selected {
    border: 1px solid $orchid;
  }

  &.tiny {
    width: 28px;
    height: 28px;

    .avatar-image {
      height: 26px;
      width: 26px;

      &.default {
        height: 20px;
        width: auto;
      }
    }
  }

  &.small {
    width: 40px;
    height: 40px;

    .avatar-image {
      height: 40px;
      width: 40px;

      &.default {
        height: 28px;
        width: auto;
      }
    }
  }

  &.medium {
    width: 52px;
    height: 52px;

    &.announcement {
      width: 58px;
      height: 58px;
      border: 3px solid rgba($warning-light, .4);
    }

    .avatar-image {
      height: 52px;
      width: 52px;

      &.default {
        height: 36px;
        width: auto;
      }
    }
  }

  &.large {
    width: 72px;
    height: 72px;

    .avatar-image {
      height: 72px;
      width: 72px;

      &.default {
        height: 48px;
        width: auto;
      }
    }
  }

  &.x-large {
    width: 96px;
    height: 96px;

    .avatar-image {
      height: 96px;
      width: 96px;

      &.default {
        height: 72px;
        width: auto;
      }
    }
  }
}
</style>
