/* eslint-disable */
import Vue from 'vue'

const VueIntercom = {
  install(Vue, voptions) {
    console.debug('VueIntercom install for workspace ' + (voptions.test ? 'TEST' : 'PROD'));
    const INTERCOM_KEY = voptions.test ? process.env.VUE_APP_INTERCOM_KEY_TEST : process.env.VUE_APP_INTERCOM_KEY_PROD;
    
    (function(){
      var w=window;
      var ic=w.Intercom;
      if (typeof ic==="function") {
        console.debug('window.Intercom exists; call reattach_activator and update with settings ' + w.intercomSettings);
        ic('reattach_activator');
        ic('update',w.intercomSettings);
      }
      else {
        console.debug('window.Intercom does not exist; create and initialize it');
        var d=document;
        var i=function() {
          i.c(arguments);
        };
        i.q=[];
        i.c=function(args ){
          i.q.push(args);
        };
        w.Intercom=i;
        var l=function() {
          console.debug('Setting timeout to load Intercom widget')
          setTimeout(() => {
            console.debug('Creating script element to load Intercom widget');
            var s=d.createElement('script');
            s.type='text/javascript';
            s.async=true;
            s.src='https://widget.intercom.io/widget/' + INTERCOM_KEY;
            var x=d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s,x);
          }, 4000);
        };
        if (d.readyState==='complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload',l);
        } else {
          w.addEventListener('load',l,false);
        }
      }
    }
    )();
    
    setTimeout(() => {
      console.debug('Calling window.Intercom boot');
      window.Intercom('boot', {
        app_id: INTERCOM_KEY,
      });
    }, 1000);
  }
}


export default VueIntercom