<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8632 17.9022C7.88398 24.1276 3.96128 30.2652 0 36.4633C4.17397 38.3262 8.25372 40.1473 12.3972 41.9966C15.0644 35.0279 17.6894 28.1692 20.3341 21.2594C17.4731 20.1261 14.7027 19.0278 11.8632 17.9022ZM21.665 21.2603C24.3232 28.2017 26.9572 35.0791 29.6073 42C33.7867 40.132 37.8664 38.3074 42 36.4598C38.0019 30.2071 34.0765 24.067 30.135 17.9022C27.2856 19.0321 24.518 20.1296 21.665 21.2603ZM21.0884 21.9217C21.0221 21.9217 20.9779 21.9217 20.9116 21.9217C18.362 28.5909 15.7978 35.2621 13.2411 41.9518C18.4363 41.9518 23.5556 41.9518 28.7589 41.9518C26.186 35.2246 23.6327 28.5738 21.0884 21.9217ZM21.2953 20.5554C21.5914 20.4538 21.8355 20.3813 22.0706 20.2883C24.3394 19.3939 26.5973 18.4731 28.8813 17.6146C29.4924 17.3851 29.7011 17.0958 29.6984 16.4737C29.6724 11.2434 29.679 6.00372 29.6763 0.773348C29.6763 0.540375 29.6486 0.310631 29.628 0C29.3856 0.104112 29.2438 0.157022 29.111 0.224439C26.6897 1.45587 24.2774 2.70607 21.8409 3.91019C21.3635 4.14572 21.2477 4.42222 21.2495 4.89585C21.2656 9.87788 21.2594 14.8591 21.2612 19.8411C21.2621 20.0587 21.2818 20.2763 21.2953 20.5554ZM35.2247 5.07151C33.5905 3.41595 31.9966 1.80537 30.4279 0.216373C30.4279 3.33121 30.4279 6.47019 30.4279 9.54833C32.1115 7.97896 33.7888 6.40961 35.2247 5.07151Z"
      fill="currentColor"/>
    <path
      d="M19.7415 20.2883C19.9766 20.3813 20.2207 20.4538 20.5169 20.5554C20.5303 20.2763 20.5501 20.0587 20.551 19.8411C20.5527 14.8591 20.5465 9.87789 20.5626 4.89585C20.5644 4.42222 20.4486 4.14573 19.9712 3.9102C17.5347 2.70608 15.1224 1.45587 12.7011 0.224443C12.5683 0.157026 12.4265 0.104116 12.1842 3.8485e-06C12.1635 0.310634 12.1358 0.540379 12.1358 0.773352C12.1331 6.00372 12.1397 11.2434 12.1137 16.4737C12.111 17.0959 12.3197 17.3851 12.9308 17.6146C15.2148 18.4731 17.4727 19.3939 19.7415 20.2883Z"
      fill="currentColor"/>
    <path
      d="M11.3842 0.220686C9.81549 1.80968 8.2216 3.42027 6.58737 5.07582C8.02327 6.41393 9.70062 7.98328 11.3842 9.55264V0.220686Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgOrigamiDressFixedSize',
  props: {
    color: {
      type: String,
      default: '#520845'
    },
    variant: {
      type: String,
      default: 'default'
    }
  }
}
</script>
