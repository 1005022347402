<template>
  <div class="p-5 d-flex flex-column align-items-center">
    <h2 class="text-center">
      Armoire Go Sign In
    </h2>
    <div
      v-if="checkinComplete"
      class="d-flex flex-column align-items-center text-center">
      <div class="position-relative my-3">
        <avatar-image
          :profile-pic="profilePic"
          :client-id="id"
          size="x-large"/>
        <svg-circle-icon
          class="success-check"
          variant="success"/>
      </div>
      <h4 class="my-3 semi-bold">
        Thanks, {{ firstName }}!
      </h4>
      <p>
        You've signed in at <span class="semi-bold">{{ getKioskFromId(selectedKiosk).name }}</span>.<br>
        Return to the Armoire Go kiosk to verify your check in.
      </p>
      <sequin-button
        block
        class="my-3"
        type="router-link"
        :to="{ name: 'closet-sections' }">
        Got It
      </sequin-button>
      <text-link
        class="my-3"
        size="paragraph"
        @click="checkinComplete = false">
        Sign In Again
      </text-link>
    </div>
    <div
      v-else-if="showList">
      <p class="text-center mb-4">
        Select the location you're signing into
      </p>
      <div class="selection-container">
        <base-radios
          v-model="selectedKiosk"
          label-class="w-100"
          :options="pickupLocationKiosks.map(kiosk => kiosk.id)"
          custom>
          <template #customOption="{ option, active }">
            <div
              :class="{ 'selected': active }"
              class="d-flex align-items-center p-3 kiosk w-100">
              <div
                :class="{ 'selected': active }"
                class="img-background">
                <square-image
                  :width="48"
                  :class="active ? 'bg-secondary' : 'bg-origami'"
                  :image-source="active ? whiteDressUrl : getRandomDressImageUrl()"/>
              </div>
              <div class="text-start">
                <h5 class="mb-0">
                  {{ getKioskFromId(option.value).name }}
                </h5>
                <p class="small-text mb-0">
                  {{ getKioskFromId(option.value).location.name }}
                </p>
                <p class="small-text mb-0">
                  {{ getKioskFromId(option.value).location.addressLine1 }} {{ getKioskFromId(option.value).location.addressLine2 }}
                </p>
                <p class="small-text mb-0">
                  {{ getKioskFromId(option.value).location.city }}, {{ getKioskFromId(option.value).location.state }}
                </p>
              </div>
            </div>
          </template>
        </base-radios>
        <sequin-button
          block
          :disabled="!selectedKiosk || submitting"
          class="mt-3"
          @click="checkInAtSelectedKiosk">
          Check In{{ selectedKiosk ? ` at ${getKioskFromId(selectedKiosk).name}` : '' }}
        </sequin-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AvatarImage from '../community/common/AvatarImage.vue'
import TextLink from '../global/sequin/TextLink.vue'

import dressImages from '../global/mixins/dressImages'
import SequinButton from '../global/sequin/SequinButton.vue'
import SquareImage from '../global/sequin/SquareImage.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import BaseRadios from '@/components/global/BaseRadios.vue'

export default {
  components: {
    SquareImage,
    SequinButton,
    AvatarImage,
    TextLink,
    SvgCircleIcon,
    BaseRadios
  },
  mixins: [dressImages],
  data () {
    return {
      selectedKiosk: null,
      submitting: false,
      errors: [],
      checkinComplete: false,
      showList: false
    }
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'id',
      'profilePic'
    ]),
    ...mapGetters('account', [
      'pickupLocationKiosks'
    ]),
    whiteDressUrl () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}armoire-dress-white.png`
    }
  },
  async mounted () {
    await this.getPickupLocations()
    this.$logger.info('route params', this.$route.params)
    if ('kiosk' in this.$route.params) {
      this.directCheckInKiosk(this.$route.params.kiosk)
    } else {
      this.showList = true
    }
  },
  methods: {
    ...mapActions('account', [
      'getPickupLocations',
      'kioskCheckin'
    ]),
    async directCheckInKiosk (kioskId) {
      if (this.getKioskFromId(kioskId)) {
        this.selectedKiosk = kioskId
        this.checkInAtSelectedKiosk()
      } else {
        this.$logger.error('Did not find kiosk id in pickupLocationKiosks', kioskId, this.pickupLocationKiosks)
        this.showList = true
      }
    },
    async checkInAtSelectedKiosk () {
      this.submitting = true
      this.errors = []
      try {
        this.$logger.debug('selectedKiosk', this.selectedKiosk)
        await this.kioskCheckin(this.selectedKiosk)
        this.checkinComplete = true
      } catch (err) {
        this.$logger.error(err)
        this.errors = err
        this.showList = true
      }
      this.submitting = false
    },
    getKioskFromId (id) {
      return this.pickupLocationKiosks.find(kiosk => kiosk.id === id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .img-background {
    border-radius: 50%;
    background-color: $origami;
    padding: 16px;
    margin-right: 20px;

    &.selected {
      background-color: $secondary
    }
  }
.selection-container {
  label {
    width: 100%;
  }
}
  .success-check {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .kiosk {
    border: $default-border;
    border-radius: $sequin-border-radius;

    &.selected {
      background-color: rgba($secondary, .1);
      border-color: $secondary;
    }
  }
  :deep(.base-radio) {
    width: 100%;
  }
</style>
