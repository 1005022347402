<template>
  <div v-if="occasionTagsLoading">
    <svg-spinner
      width="40"
      height="40"
      class="d-block mx-auto my-3"/>
  </div>
  <div v-else>
    <plain-button
      v-for="tag in occasionTags"
      id="occasion-tag"
      :key="tag.tag"
      @click="tagOccasion(tag)">
      <occasion-tag :tag="tag"/>
    </plain-button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PlainButton from '../../global/sequin/PlainButton'
import OccasionTag from './OccasionTag'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  components: {
    OccasionTag,
    PlainButton,
    SvgSpinner
  },
  computed: {
    ...mapState('community', [
      'occasionTags'
    ]),
    occasionTagsLoading () {
      return this.occasionTags === null || this.occasionTags.length === 0
    }
  },
  mounted () {
    if (this.occasionTagsLoading) {
      this.getOccasionTags()
    }
  },
  methods: {
    ...mapActions('community', [
      'getOccasionTags'
    ]),
    tagOccasion (tag) {
      this.$store.commit('community/SET_LOOK_BUILDER_TAGS', [tag])
      this.$emit('tag-occasion')
    }
  }
}
</script>
<style lang="scss" scoped>

#occasion-tag {
  width: 100%;
  height: 68px;
  padding: 0;
  border-bottom: $default-border;
  background-color: $white;
}
</style>
