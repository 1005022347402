<template>
  <TrackingLink
    :to="inWebView() ? null : '/login/'"
    @click="login">
    {{ text }}
  </TrackingLink>
</template>

<script setup>
import useMobileApp, { MobileAppMessage } from '@/composables/mobileApp.js'
import TrackingLink from '@shared/components/ADORN/TrackingLink.vue'

defineProps({
  text: {
    type: String,
    default: 'Log In'
  }
})

const { inWebView, post } = useMobileApp()

function login () {
  if (inWebView()) {
    post(MobileAppMessage.Login)
  }
}
</script>
