<!-- Stylist Collection Add/Remove Quick Action Button for creating stylist collections via impersonation -->
<template>
  <StyleColorQuickActionButton
    :style-color="styleColor"
    :style-color-source="styleColorSource"
    :style-color-source-index="styleColorSourceIndex"
    :size="size"
    @click="onClick">
    <SvgPlus v-if="!isAdded"/>
    <SvgX v-else/>
  </StyleColorQuickActionButton>
</template>

<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useStylingStore } from '@/stores/styling'
import useClient from '@/composables/client'
import StyleColorQuickActionButton from '@shared/components/ADORN/StyleColorQuickActionButton.vue'
import SvgPlus from '@/components/global/svg/SvgPlus.vue'
import SvgX from '@/components/global/svg/SvgX.vue'

const props = defineProps({
  styleColor: {
    type: Object,
    required: true
  },
  /** @type {PropType<StyleColorSource>} */
  styleColorSource: {
    type: Object,
    required: true
  },
  styleColorSourceIndex: {
    type: Number,
    default: () => null
  },
  size: {
    type: String,
    default: 'medium',
    validator: value => ['x-large', 'large', 'medium', 'small', 'tiny'].includes(value)
  }
})
const emit = defineEmits(['click'])

const {
  impersonatorIsStylist
} = useClient()

const store = useStylingStore()
const { closetStyleColorIds } = storeToRefs(store)

const isAdded = computed(() => closetStyleColorIds.value.includes(props.styleColor.id))

function onClick (event) {
  if (impersonatorIsStylist.value) {
    isAdded.value ? store.removeStyleColor(props.styleColor.id) : store.addStyleColor(props.styleColor.id)
  }
  emit('click', event)
}

</script>
