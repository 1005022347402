<template>
  <div
    class="circle-bg d-inline-flex align-items-center justify-content-center"
    :class="[variant, { inverse, circleStroke }]"
    :style="{
      '--width': `${width}px`,
      '--height': `${height}px`,
      '--padding': `${padding}px`,
      '--stroke-width': `${strokeWidth}px`,
      '--fill': fill
    }">
    <slot/>
  </div>
</template>

<script>

/**
 * Use this component to put any svg icon on a circular background
 */
export default {
  props: {
    height: {
      type: Number,
      default: 24
    },
    width: {
      type: Number,
      default: 24
    },
    strokeWidth: {
      type: Number,
      default: 3
    },
    fill: {
      type: String,
      default: 'none'
    },
    padding: {
      type: Number,
      default: 8
    },
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/(primary|secondary|tertiary|champagne|success|info|danger|warning|dark|neutral|plum)/)
      }
    },
    inverse: {
      type: Boolean,
      default: false
    },
    circleStroke: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.circle-bg {
  --width: 24px;
  --height: 24px;
  --padding: 8px;
  --icon-width: calc(var(--width) - var(--padding));
  --icon-height: calc(var(--height) - var(--padding));
  --stroke-width: 3px;
  --fill: none;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  width: var(--width);
  height: var(--height);

  :slotted(svg) {
    width: var(--icon-width);
    height: var(--icon-height);
    stroke-width: var(--stroke-width);
    fill: var(--fill);
  }

  &.primary {
    background-color: $primary;
  }

  &.secondary {
    background-color: $secondary;
  }

  &.tertiary {
    background-color: $tertiary;
  }

  &.champagne {
    background-color: $champagne;
    color: $plum;
  }

  &.success {
    background-color: $success-light;
  }

  &.info {
    background-color: $info-dark;
  }

  &.danger {
    background-color: $danger-dark;
  }

  &.warning {
    background-color: $warning-light;
  }

  &.neutral {
    background-color: $origami;
    color: $pewter;
  }

  &.dark {
    background-color: $pewter;
  }

  &.plum {
    background-color: $plum;
  }

  &.inverse {
    background-color: $white;
    color: $plum;

    &.primary {
      color: $primary;
    }

    &.secondary {
      color: $secondary;
    }

    &.tertiary {
      color: $tertiary;
    }

    &.success {
      color: $success-light;
    }

    &.info {
      color: $info-dark;
    }

    &.danger {
      color: $danger-dark;
    }

    &.warning {
      color: $warning-light;
    }

    &.neutral .dark {
      color: $pewter;
    }
  }

  &.circleStroke {
    border: 1px solid $ash;
  }
}
</style>
