<template>
  <div>
    <div class="d-flex justify-content-center">
      <!-- Scroll left -->
      <div
        v-if="!isMobile"
        class="col">
        <div
          v-if="leftScrollEnabled && !isMobile"
          class="scroll-button-wrapper left">
          <plain-button
            class="scroll-button"
            @click="scrollLeft">
            <svg-chevron-left
              :width="scrollButtonWidth"
              :height="scrollButtonHeight"
              stroke-width="1"
              class="scroll-chevron"/>
          </plain-button>
        </div>
      </div>

      <!-- Image -->
      <div
        v-if="!isMobile"
        class="col col-lg-auto d-flex justify-content-center">
        <transition
          name="fade"
          mode="out-in">
          <img
            v-if="photoSize === 'fullscreen'"
            :src="current.image"
            class="fullscreen">
          <preview-image
            v-else
            :size="photoSize"
            :src="current.image"/>
        </transition>
      </div>
      <photo-snap-scroll
        v-else
        :initial-index="initialIndex"
        :length="photoAlbumEntries.length"
        @update-index="handleIndexUpdate">
        <template #scrollable>
          <preview-image
            v-for="entry in photoAlbumEntries"
            :key="entry.image"
            class="scroll-item d-flex align-items-center"
            size="fullscreen"
            :src="entry.image"/>
        </template>
      </photo-snap-scroll>

      <!-- Scroll right -->
      <div
        v-if="!isMobile"
        class="col">
        <div
          v-if="rightScrollEnabled"
          class="scroll-button-wrapper right">
          <plain-button
            class="scroll-button"
            @click="scrollRight">
            <svg-chevron-right
              :width="scrollButtonWidth"
              :height="scrollButtonHeight"
              stroke-width="1"
              class="scroll-chevron"/>
          </plain-button>
        </div>
      </div>
    </div>

    <!-- Caption body -->
    <div class="px-4">
      <div class="col">
        <div
          v-if="current.tag"
          class="mt-2">
          <occasion-tag
            :tag="current.tag"
            variant="worn-to"/>
        </div>
        <star-rating-display
          v-if="current.starRating"
          class="mt-4"
          :rating="current.starRating"/>
        <p
          v-if="current.body"
          :class="{'mt-3': !current.starRating}">
          {{ current.body }}
        </p>
        <p class="text-gray">
          <small>
            {{ current.subtext }}
          </small>
          <br>
          <small
            v-for="(entry, index) in current.clientSizeList"
            :key="index"
            class="text-gray">
            <span class="font-italic">
              {{ entry }}
            </span>
            <span
              v-if="index !== current.clientSizeList.length - 1"
              class="px-1">
              •
            </span>
          </small>
        </p>
        <p>
          <router-link
            v-if="current.link"
            :to="current.link"
            @click="$emit('link-clicked')">
            {{ current.linkText }}
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import PlainButton from '../global/sequin/PlainButton'
import PhotoSnapScroll from '../global/sequin/PhotoSnapScroll'
import OccasionTag from '../community/sharelook/OccasionTag'
import StarRatingDisplay from '../global/sequin/StarRatingDisplay.vue'
import SvgChevronLeft from '../global/svg/SvgChevronLeft.vue'
import SvgChevronRight from '../global/svg/SvgChevronRight.vue'

export default {
  components: {
    OccasionTag,
    PhotoSnapScroll,
    PreviewImage,
    StarRatingDisplay,
    PlainButton,
    SvgChevronLeft,
    SvgChevronRight
  },
  props: {
    photoAlbumEntries: {
      type: Array,
      required: true
    },
    initialIndex: {
      type: Number,
      default: 0
    },
    photoSize: {
      type: String,
      default: 'x-large'
    }
  },
  data () {
    return {
      currentIndex: this.initialIndex,
      cachedImages: []
    }
  },
  computed: {
    current () {
      return this.photoAlbumEntries[this.currentIndex]
    },
    leftScrollEnabled () {
      return this.currentIndex > 0
    },
    rightScrollEnabled () {
      return this.currentIndex < this.photoAlbumEntries.length - 1
    },
    scrollButtonWidth () {
      return this.scrollButtonSize === 'small' ? '24' : '32'
    },
    scrollButtonHeight () {
      return this.scrollButtonSize === 'small' ? '32' : '36'
    },
    scrollOffset () {
      return this.scrollButtonSize === 'small' ? 52 : 68
    }
  },
  created () {
    // Prefetch all images in parallel upon opening the album. Maintaining a reference to them
    // in memory prevents us from going to the disk cache and causing a flicker on image change.
    this.photoAlbumEntries.forEach(entry => {
      const img = new Image()
      img.src = entry.image
      this.cachedImages.push(img)
    })
  },
  deactivated () {
    this.currentIndex = 0
  },
  mounted () {
    window.addEventListener('keydown', this.handleKeyInput)
  },
  unmounted () {
    window.removeEventListener('keydown', this.handleKeyInput)
  },
  methods: {
    scrollRight () {
      this.currentIndex++
      this.$emit('update-index', this.currentIndex)
    },
    scrollLeft () {
      this.currentIndex--
      this.$emit('update-index', this.currentIndex)
    },
    handleKeyInput ({ key }) {
      if (key === 'ArrowLeft' && this.leftScrollEnabled) this.scrollLeft()
      if (key === 'ArrowRight' && this.rightScrollEnabled) this.scrollRight()
    },
    handleIndexUpdate (index) {
      this.currentIndex = index
      this.$emit('update-index', index)
    }
  }
}
</script>

<style lang="scss" scoped>

  $xlPhotoSize: 320px;

  .scroll-button-wrapper {
    z-index: 10;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .scroll-button {
      padding: 0;
      background: $white;
      position: absolute;
      left: inherit;
      right: inherit;
      color: $pewter;
      border: $default-border;

      .scroll-chevron {
        border-radius: 2px;
        padding: 4px;
      }
    }
  }

  .fullscreen {
    min-width: $xlPhotoSize;
    max-height: 80vh
  }

  .scroll-wrapper {
    display: flex;
    overflow-x: scroll;
    max-width: $xlPhotoSize;
    scroll-snap-type: x mandatory;

    .scroll-image {
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .fade-enter-active, .fade-leave-active {
    transition-duration: 150ms;
  }

</style>
