<template>
  <div class="bottom-nav">
    <div class="w-100">
      <slot name="message"/>
      <slot name="secondary-message"/>
      <div class="my-3 px-4 w-100 d-flex justify-content-center">
        <slot name="button"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionFooter'
}
</script>

<style lang="scss" scoped>
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    border-top: $default-border;
    z-index: $zindex-fixed;
    background: $white;
  }
</style>
