<template>
  <div
    class="col-md-6 text-center mx-auto pb-2">
    <p>
      <b>Interested in saving on your membership?</b><br>
      Get up to 20% off each month when you prepay and save.
    </p>
    <a
      class="btn btn-outline-primary mx-auto mb-3"
      href="mailto:hi@armoire.style?subject=Prepay and save&body=Hi there!%0DI'm currently on the monthly membership, and I'd like to learn more about prepaying and saving.%0DCan you tell me how this works?">
      Contact to Learn More
    </a>
  </div>
</template>
