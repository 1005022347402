<template>
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.51465 11.7263C1.51465 10.8278 1.78775 10.5513 2.67533 10.5513C4.4505 10.5513 6.2598 10.5513 8.03496 10.5513C8.85427 10.5513 9.16151 10.8623 9.16151 11.6917C9.16151 15.2511 9.16151 18.8105 9.16151 22.3698C9.16151 23.3029 8.8884 23.5793 7.93255 23.6139C6.15738 23.6139 4.38222 23.6139 2.64119 23.6139C1.82189 23.6139 1.51465 23.3029 1.51465 22.4735C1.51465 20.6765 1.51465 13.4887 1.51465 11.7263Z"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"/>
    <path
      d="M27.1887 15.8386C27.1887 15.0092 26.8132 14.2835 26.1987 13.7997C26.1771 13.7778 26.1691 13.7559 26.1575 13.7339C26.1435 13.7075 26.1293 13.6788 26.1089 13.6569C26.0943 13.6412 26.1024 13.6173 26.1136 13.599C26.3418 13.2249 26.4718 12.7866 26.4718 12.3483C26.4718 11.0351 25.4135 9.96387 24.1163 9.96387C24.048 9.96387 21.4877 9.92931 20.4977 9.96387C19.5871 9.96387 19.2763 10.0313 19.4664 8.76673C19.471 8.73614 19.4733 8.70393 19.4867 8.67606C19.5051 8.63795 19.5122 8.59529 19.521 8.55389C19.8535 6.98413 20.0189 5.3807 19.9515 3.74361C19.9174 2.98336 19.7467 2.2231 19.2346 1.63563C18.347 0.633479 17.1181 0.840821 16.6401 2.08487C16.4353 2.60323 16.2988 3.15614 16.1622 3.70905C15.7867 5.16045 15.2405 6.57728 14.4212 7.82133C13.2605 9.54918 11.7926 10.7932 9.64191 10.9315C9.23225 10.966 9.0957 11.1734 9.0957 11.588C9.0957 13.4196 9.0957 20.7111 9.0957 22.5426C9.0957 22.9919 9.23225 23.1992 9.71018 23.3029C10.7343 23.5102 11.7243 23.8558 12.7484 24.1322C13.7384 24.4087 14.7284 24.8234 15.7526 24.8925C17.9374 25.0307 22.6143 24.9962 22.6825 24.9962C23.9798 24.9962 25.038 23.9249 25.038 22.6117C25.038 22.2105 24.9422 21.8093 24.7504 21.4707C24.7419 21.4556 24.7478 21.4376 24.7584 21.4239C24.764 21.4166 24.7706 21.4 24.7792 21.3966C25.6594 21.0474 26.3011 20.1872 26.3011 19.156C26.3011 18.8464 26.2332 18.5598 26.1342 18.2961C26.0937 18.1882 26.121 18.0629 26.1742 17.9607C26.1921 17.9264 26.2141 17.8992 26.2432 17.8737C26.8313 17.3582 27.1887 16.6477 27.1887 15.8386Z"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"/>
  </svg>
</template>
