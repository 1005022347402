<template>
  <div
    class="empty-collection"
    :class="{ 'mobile': isMobile }">
    <div
      :class="[titleVariant]"
      class="empty-collection-body">
      <div class="d-flex flex-column justify-content-end">
        <slot/>
      </div>
    </div>
    <div
      v-if="titleVariant.includes('overlay')"
      class="empty-collection-heading"
      :class="[backgroundVariant, titleVariant, { 'mobile': isMobile }]">
      <svg-circle-icon
        class="preview-icon"
        inverse
        :padding="12"
        :width="32"
        :height="32"
        :stroke-width="1.5"
        variant="primary">
        <component :is="icon"/>
      </svg-circle-icon>
      <text-line-limit
        tag="h6"
        class="mb-1">
        {{ title }}
      </text-line-limit>
      <p
        class="small-text mb-1 subtitle"
        :class="[{ 'text-white': backgroundVariant === 'secondary' || backgroundVariant === 'gradient' }]">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script>
import TextLineLimit from '../global/sequin/TextLineLimit.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgBookmark from '@/components/global/svg/SvgBookmark.vue'

export default {
  components: {
    TextLineLimit,
    SvgCircleIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    icon: {
      type: Object,
      default: SvgBookmark
    },
    backgroundVariant: {
      type: String,
      default: 'default'
    },
    titleVariant: {
      type: String,
      default: 'overlay-bottom'
    }
  }
}
</script>

<style lang="scss" scoped>
@import './CollectionPreviewIcon.scss';

.empty-collection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &.mobile {
    height: 280px;
  }

  .empty-collection-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $origami;
    position: absolute;
    width: 100%;
    bottom: 0;

    .subtitle {
      height: $small-text-line-height;
    }

    &.below {

      &.mobile {
        padding: 16px;
      }
    }

    &.overlay-top {
      padding: 24px 32px 4px 32px;
      top: 0;
      bottom: unset;

      .preview-icon {
        top: unset;
        bottom: -16px;
      }
    }

    &.secondary {
      background-color: $secondary;
      color: $white;
    }

    &.champagne {
      background-color: $champagne;
    }

    &.gradient {
      color: $white;
      @include sequin-gradient-diagonal;
    }
  }

  .empty-collection-body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30%;
    padding: 20px;

    &.overlay-top {
      margin-top: 100px;
      margin-bottom: unset;
    }

    &.below {
      margin-bottom: 0;
      padding: 8%;
    }
  }
}
</style>
