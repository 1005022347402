
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g
      clip-path="url(#a)"
      fill="currentColor">
      <path d="M0 18.245c.029-.207.053-.413.086-.615a5.3 5.3 0 0 1 1.508-2.88l5.385-5.385c.855-.855 1.877-1.387 3.072-1.541 1.795-.235 3.35.293 4.642 1.57q.091.094.182.182c.456.422.547 1.109.221 1.632a1.306 1.306 0 0 1-1.565.528 1.15 1.15 0 0 1-.441-.293c-.255-.254-.514-.513-.83-.7a2.79 2.79 0 0 0-3.423.436 713 713 0 0 0-5.424 5.434c-.783.792-1.018 1.757-.691 2.822.33 1.066 1.08 1.728 2.179 1.935.979.187 1.833-.106 2.539-.807a646 646 0 0 0 3.14-3.144c.071-.072.124-.077.22-.038q1.657.728 3.34.057c.034-.014.068-.024.116-.043-.029.034-.038.048-.053.063-1.67 1.67-3.336 3.345-5.016 5.006-.926.917-2.059 1.416-3.36 1.522-.033.004-.062.014-.096.019H5.07c-.024-.005-.043-.02-.067-.02a5.3 5.3 0 0 1-2.304-.71Q.58 22.016.096 19.598c-.043-.22-.062-.446-.096-.667z"/><path d="M24 5.755c-.029.207-.053.413-.091.615a5.3 5.3 0 0 1-1.507 2.88l-5.386 5.385c-.854.855-1.877 1.387-3.072 1.541-1.795.235-3.35-.293-4.642-1.57-.177-.177-.37-.34-.475-.585a1.3 1.3 0 0 1 .26-1.46c.383-.383.96-.494 1.454-.259.13.063.245.159.355.25.25.211.47.456.74.638 1.132.75 2.572.586 3.551-.388 1.32-1.316 2.63-2.63 3.95-3.946.48-.48.96-.955 1.436-1.44 1.387-1.411.989-3.72-.778-4.56a2.78 2.78 0 0 0-3.144.509c-1.09 1.07-2.165 2.155-3.245 3.23-.057.058-.096.077-.177.043q-1.698-.757-3.418-.052l-.072.024c.039-.044.063-.072.091-.101l4.901-4.901A5.4 5.4 0 0 1 17.808.053c.144-.024.288-.039.437-.058h.686c.029.005.063.015.091.02.308.052.62.071.912.153 2.165.629 3.495 2.05 3.97 4.253.043.211.062.427.096.638z"/>
    </g><defs><clipPath id="a">
      <path
        fill="currentColor"
        d="M0 0h24v24H0z"/>
    </clipPath></defs>
  </svg>
</template>
