<template>
  <div
    v-if="progress"
    class="sign-up-progress-bar d-flex flex-column gap-xxs">
    <div class="progress-bar d-flex position-relative overflow-hidden">
      <div
        v-for="step in progress.totalSteps"
        :key="step"
        class="step flex-fill"
        :class="{ 'bg-ash': step > progress.step}"/>
      <div
        class="progress position-absolute bg-plum"
        :style="{ width: `${percent}%` }"/>
    </div>
    <div
      v-if="progress.title"
      class="d-flex justify-content-between">
      <h5 class="text-uppercase m-0">
        {{ progress.title }}
      </h5>
      <h5 class="m-0">
        ({{ progress.step }}/{{ progress.totalSteps }})
      </h5>
    </div>
  </div>
</template>

<script setup>
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const props = defineProps({
  progress: {
    type: Object,
    default: null
  }
})

const { progress: signUpProgress } = storeToRefs(useSignUpNavigationStore())
const progress = computed(() => props.progress ?? signUpProgress.value)

// Round to the nearest quarter to keep the gaps between steps consistent.
const percent = computed(() => Math.round(progress.value.step * 100 / progress.value.totalSteps * 4) / 4)
</script>

<style scoped lang="scss">
.sign-up-progress-bar {
  .progress-bar {
    height: $spacing-xs;
    border-radius: 2px;
    gap: 1px; // Use 1px gap on lower resolutions to avoid overlapping steps.
    @media (min-resolution: 2x) {
      gap: 0.5px;
    }

    @include media-tablet-or-larger {
      height: $spacing-sm;
    }

    .step {
      @include transition-base(background-color);
    }

    .progress {
      height: 100%;
      border-radius: 2px;
      @include transition-base(width);
    }
  }
}
</style>
