<template>
  <div class="sequin-card">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.sequin-card {
  border-radius: 2px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  background: $white;
  padding: 24px;
}
</style>
