<template>
  <div>
    <sequin-pivot
      class="justify-content-between mx-5 px-5 pb-2 pt-4"
      :tabs="tabs"
      :tab-selected="tabs.map(t => t.id).indexOf(tabSelected)"
      @select-tab="setTabSelected"/>
    <transition
      name="fade"
      mode="out-in">
      <closet-reviews
        v-if="tabSelected === 'reviews'"
        :style-color="styleColor"/>
    </transition>
    <transition
      name="fade"
      mode="out-in">
      <div
        v-if="tabSelected === 'details'"
        class="px-4">
        <closet-detail-bullets
          class="my-4"
          :selected-item-type="selectedItemType"
          :style-color="styleColor"/>
        <accordion-with-header
          :expanded="detailsExpanded"
          arrow-orientation="alt"
          :border="false"
          direction="reverse"
          title="Fit details"
          @accordion-click="toggleExpanded('details')">
          <template #subtitle>
            <sequin-button
              v-if="sizeGuideUrl"
              class="py-2 px-2 size-guide-button"
              variant="secondary"
              small
              @click="goToSizeChart(sizeGuideUrl)">
              <svg-tape-measure
                height="14"
                width="14"
                class="me-2 mb-1"/>
              Size Chart
            </sequin-button>
          </template>
          <div
            v-if="styleColor.numSizingRatings > 0 || styleColor.style.sizeGuide"
            class="mt-4 mb-2">
            <closet-sizing-rating
              class="p-0"
              :avg-sizing-rating="styleColor.avgSizingRating"
              :num-sizing-ratings="styleColor.numSizingRatings"
              :sizing-bias-recommendation="styleColor.sizingBiasRecommendation"
              :style-color="styleColor"/>
          </div>
        </accordion-with-header>
        <template v-if="loaded">
          <accordion-with-header
            :expanded="similarExpanded"
            :expanded-max-height="800"
            arrow-orientation="alt"
            :border="false"
            direction="reverse"
            title="You Might Also Like"
            @accordion-click="toggleExpanded('similar')">
            <closet-detail-similar-styles :style-color="styleColor"/>
          </accordion-with-header>
          <accordion-with-header
            :border="false"
            arrow-orientation="alt"
            :expanded="collectionsExpanded"
            direction="reverse"
            title="Browse Collections"
            @accordion-click="toggleExpanded('collections')">
            <closet-detail-browse-collections
              class="browse-collections"
              :style-color="styleColor"/>
          </accordion-with-header>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SequinPivot from '../global/sequin/SequinPivot.vue'
import ClosetReviews from './closetPageDetailReview/ClosetReviews.vue'
import ClosetDetailBullets from './ClosetDetailBullets.vue'
import AccordionWithHeader from '../global/sequin/AccordionWithHeader.vue'
import SequinButton from '../global/sequin/SequinButton.vue'
import ClosetSizingRating from './ClosetSizingRating.vue'
import ClosetDetailSimilarStyles from './ClosetDetailSimilarStyles.vue'
import ClosetDetailBrowseCollections from './ClosetDetailBrowseCollections.vue'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'
import SvgTapeMeasure from '@/components/global/svg/SvgTapeMeasure.vue'

export default {
  components: {
    SequinPivot,
    ClosetReviews,
    ClosetDetailBullets,
    AccordionWithHeader,
    SequinButton,
    ClosetSizingRating,
    ClosetDetailSimilarStyles,
    ClosetDetailBrowseCollections,
    SvgTapeMeasure
  },
  mixins: [StyleColorMixin],
  props: {
    styleColor: {
      type: Object,
      required: true
    },
    selectedItemType: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      detailsExpanded: false,
      similarExpanded: false,
      collectionsExpanded: false,
      tabSelected: 'details',
      tabs: [{
        id: 'details',
        name: 'Details'
      }, {
        id: 'reviews',
        name: 'Reviews'
      }]
    }
  },
  computed: {
    ...mapState('client', [
      'loaded'
    ])
  },
  watch: {
    styleColor () {
      this.setExpandedSections()
    }
  },
  mounted () {
    this.setExpandedSections()
  },
  methods: {
    setTabSelected ({ id }) {
      this.tabSelected = id
    },
    setExpandedSections () {
      this.detailsExpanded = !this.styleColor.recommendedSize
      this.similarExpanded = false
      this.collectionsExpanded = false
    },
    toggleExpanded (section) {
      if (section === 'details') {
        this.detailsExpanded = !this.detailsExpanded
      }
      if (section === 'similar') {
        this.similarExpanded = !this.similarExpanded
      }
      if (section === 'collections') {
        this.collectionsExpanded = !this.collectionsExpanded
      }
    },
    goToSizeChart (sizeGuideUrl) {
      window.open(sizeGuideUrl, '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
:deep(.tab) {
  color: #171723
}
</style>
