import { slugify } from '@/utils/stringParsing.js'

export function getSectionRouteLocation ({ id, name }, isAuthenticated = false) {
  return id
    ? {
        name: isAuthenticated ? 'closet-section' : 'closet-section-anonymous',
        params: {
          id,
          name: slugify(name)
        }
      }
    : null
}

export function getSectionHref ({ id, name }, isAuthenticated = false) {
  return id
    ? `/closet/${isAuthenticated ? '' : 'u/'}${slugify(name)}/${id}`
    : null
}
