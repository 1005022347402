<template>
  <style-game-outro
    heading="Thanks for Rating!"
    class="upcoming-outro-position"
    :subheading="subheading">
    <div class="d-flex flex-column align-items-center">
      <div class="d-flex flex-column justify-content-center mt-3">
        <h5 class="semi-bold mb-3 mx-auto">
          Add styles from today's game to a Collection
        </h5>
      </div>
      <div class="d-flex flex-column align-items-center mb-3">
        <div class="d-flex flex-wrap col-12 justify-content-center mb-3 mt-md-2">
          <style-color-preview
            v-for="(style, index) in sortedByFavoritedUpcomingStyles"
            :key="style.id"
            class="mx-2 mb-3"
            :style-color="style"
            :eager-load="true"
            :style-color-set="styleColorSet"
            :style-color-source-index="index"
            :description-props="{
              showUnavailable: true,
              showStocking: true
            }"
            :size="thumbnailSize"/>
        </div>
      </div>
    </div>
  </style-game-outro>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import StyleGameOutro from './StyleGameOutro_closetpage.vue'
import StyleColorPreview from '@shared/components/ADORN/StyleColorPreview.vue'

export default {
  components: {
    StyleGameOutro,
    StyleColorPreview
  },
  props: {
    favoritedUpcomingStyles: {
      type: Array,
      default: () => []
    },
    sortedByFavoritedUpcomingStyles: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    ...mapGetters('styleGames', [
      'hasBeTheBuyerStylesToRate'
    ]),
    subheading () {
      return this.favoritedUpcomingStyles.length > 0
        ? `${this.favoritedUpcomingStyles.length} style${this.favoritedUpcomingStyles.length > 1 ? 's have' : ' has'} been saved to Favorites.`
        : null
    },
    styleColorSet () {
      return {
        source: 'explore',
        sourceType: 'explore',
        length: this.sortedByFavoritedUpcomingStyles.length
      }
    },
    thumbnailSize () {
      return this.isMobile ? 'medium' : 'large'
    }
  }
}
</script>
