<template>
  <div
    class="rate-style-container"
    :class="{ 'fade-out': fadeOutRateStyle }">
    <div class="d-flex flex-column align-items-center text-center mx-auto">
      <slot name="rating-intro"/>
    </div>
    <div
      class="image-container-frame-rate me-4"
      :class="{ 'mobile': isMobile }">
      <div
        class="style-image-container fade-element"
        :class="{ 'fade-out': fadeOutDetails, 'fade-in': fadeInDetails}">
        <preview-image
          :key="imageSource"
          :src="imageSource"
          :size="isMobile ? 'x-large' : 'xx-large'"
          @loaded="onImageLoaded"/>
        <div
          class="lottie-background-no"
          :class="{ 'fade-in': imageBackgroundFadeIn.no }">
          <div
            class="lottie-image">
            <lottie
              :options="{ animationData: lottieAnimations.noImage, autoplay: false, loop: false }"
              @animCreated="(e) => handleAnimation(e, 'noImage')"/>
          </div>
        </div>
        <div
          class="lottie-background-yes"
          :class="{ 'fade-in': imageBackgroundFadeIn.yes }">
          <div
            class="lottie-image">
            <lottie
              :options="{ animationData: lottieAnimations.yesImage, autoplay: false, loop: false }"
              @animCreated="(e) => handleAnimation(e, 'yesImage')"/>
          </div>
          <h6
            v-if="yesRatingConfirmation"
            class="rating-confirmation mt-3 px-3">
            {{ yesRatingConfirmation }}
          </h6>
        </div>
      </div>
    </div>
    <plain-button
      v-if="showSkipButton"
      class="skip-button small-text text-pewter"
      :use-disabled-styling="false"
      :disabled="buttonsDisabled"
      @click="onSkip">
      <svg-chevron-right
        class="mb-1"
        width="20"
        height="20"/>
      Skip
    </plain-button>
    <div class="rating-bar d-flex mx-auto justify-content-around">
      <plain-button
        :use-disabled-styling="false"
        :disabled="buttonsDisabled"
        class="d-flex flex-column align-items-center p-0 bg-transparent"
        @click="onNo">
        <div class="lottie-button-container">
          <lottie
            :options="{ animationData: lottieAnimations.noButton, autoplay: false, loop: false }"
            @animCreated="(e) => handleAnimation(e, 'noRating')"/>
        </div>
        <h6 class="rating-text">
          {{ noRatingName }}
        </h6>
      </plain-button>
      <plain-button
        :use-disabled-styling="false"
        :disabled="buttonsDisabled"
        class="d-flex flex-column align-items-center p-0 bg-transparent"
        @click="onYes">
        <div class="lottie-button-container">
          <lottie
            :options="{ animationData: lottieAnimations.yesButton, autoplay: false, loop: false }"
            @animCreated="(e) => handleAnimation(e, 'yesRating')"/>
        </div>
        <h6 class="rating-text">
          {{ yesRatingName }}
        </h6>
      </plain-button>
    </div>
  </div>
</template>

<script>
import Lottie from 'vue-lottie'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import PlainButton from '../global/sequin/PlainButton'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'

export default {
  components: {
    Lottie,
    PlainButton,
    PreviewImage,
    SvgChevronRight
  },
  props: {
    currentIndex: {
      type: Number,
      required: true
    },
    numStylesToRate: {
      type: Number,
      required: true
    },
    imageSource: {
      type: String,
      required: true
    },
    yesRatingName: {
      type: String,
      default: 'Yes'
    },
    yesRatingConfirmation: {
      type: String,
      default: null
    },
    noRatingName: {
      type: String,
      default: 'No'
    },
    brand: {
      type: String,
      required: true
    },
    styleName: {
      type: String,
      required: true
    },
    showSkipButton: {
      type: Boolean,
      default: false
    },
    lottieAnimations: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      animationObjects: {
        yesRating: null,
        noRating: null,
        yesImage: null,
        noImage: null
      },
      imageBackgroundFadeIn: {
        yes: false,
        no: false
      },
      fadeInDetails: false,
      fadeOutDetails: false,
      fadeOutRateStyle: false,
      buttonsDisabled: true
    }
  },
  methods: {
    handleAnimation (anim, animId) {
      this.animationObjects[animId] = {
        anim,
        duration: anim.getDuration(true)
      }
    },
    onRating ({ rating }) {
      this.buttonsDisabled = true
      const buttonRatingAnimation = rating === 1 ? this.animationObjects.yesRating.anim : this.animationObjects.noRating.anim
      const imageRatingAnimation = rating === 1 ? this.animationObjects.yesImage.anim : this.animationObjects.noImage.anim
      const ratingName = rating === 1 ? 'yes' : 'no'

      const buttonFrames = rating === 1 ? this.animationObjects.yesRating.duration : this.animationObjects.noRating.duration
      const segment1Button = Math.round(buttonFrames * 0.25)
      const segment2Button = Math.round(buttonFrames * 0.82)

      const imageFrames = rating === 1 ? this.animationObjects.yesImage.duration : this.animationObjects.noImage.duration
      const segment1Image = Math.round(imageFrames * 0.15)

      buttonRatingAnimation.setSpeed(2)
      imageRatingAnimation.setSpeed(2)
      buttonRatingAnimation.playSegments([0, segment1Button], true)

      setTimeout(() => {
        this.imageBackgroundFadeIn[ratingName] = true
        imageRatingAnimation.playSegments([0, segment1Image], true)
      }, 200)

      setTimeout(() => {
        this.fadeOut({
          rating,
          ratingName,
          fadeOutButtonAnimation: () => { buttonRatingAnimation.playSegments([segment2Button, buttonFrames], true) }
        })
      }, 1600)
    },
    fadeOut ({ rating, ratingName = 'yes', fadeOutButtonAnimation = () => {} }) {
      if (this.currentIndex === this.numStylesToRate - 1) {
        this.fadeOutRateStyle = true
      } else {
        fadeOutButtonAnimation()
        this.fadeInDetails = false
        this.fadeOutDetails = true
      }
      setTimeout(() => {
        this.imageBackgroundFadeIn[ratingName] = false
        this.$emit('style-rating', { rating })
      }, 400)
    },
    onSkip () {
      this.buttonsDisabled = true
      this.fadeOut({ rating: 0 })
    },
    onYes () {
      this.onRating({ rating: 1 })
    },
    onNo () {
      this.onRating({ rating: -1 })
    },
    onImageLoaded () {
      this.fadeInDetails = true
      this.buttonsDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleGamesStyles.scss';

.rating-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-bar {
  position: relative;
  width: 280px;
  top: -48px;
}

.rate-style-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.style-image-container {
  position: relative;
  top: -16px;
  right: -16px;
  left: 16px;
  bottom: 16px;
  z-index: 0;

  :deep(.preview-image img) {
    border: $default-border;
  }
}

.image-container-frame-rate {
  @extend .image-container-frame;
  margin-top: 36px;
}

.background-highlight {
  background-color: $champagne;
  margin: 0 4px;
}

.lottie-background-yes, .lottie-background-no {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.fade-in {
    animation: fade-in-background .25s forwards
  }
}

.lottie-button-container {
  width: 48px;
  height: 48px;
}

.lottie-background-yes {
  background: darken($primary, 8%)
}

.lottie-background-no {
  background-color: $armor;
}

.lottie-image {
  height: 120px;
  width: 120px;
}

.rating-text {
  text-transform: uppercase;
  color: $primary;
  font-weight: $font-weight-semibold;
  margin-top: 12px;
}

.rating-confirmation {
  @extend .rating-text;
  color: $white;
  letter-spacing: 3px;
  text-align: center;
}

.skip-button {
  position: absolute;
  right: calc(50% - 180px);
  padding: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $pewter;
}

</style>
