<template>
  <div>
    <error-message v-if="submitError">
      {{ errorMessage }}
    </error-message>
    <look-builder
      v-if="editLook || clientCanShareLook"
      :disabled="isSubmitting"
      data-intercom-target="intercom-share-a-look"
      @add-photo-click="setShareLookComponent('AddPhoto')"
      @tag-styles-click="setShareLookComponent('TagStyles')"
      @tag-occasion-click="setShareLookComponent('TagOccasion')"/>
    <share-a-look-disabled v-else/>
  </div>
</template>

<script>
import LookBuilder from './LookBuilder'
import ShareALookDisabled from './ShareALookDisabled'
import ErrorMessage from '../common/ErrorMessage'
import { mapState, mapActions as mapVuexActions, mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    ErrorMessage,
    LookBuilder,
    ShareALookDisabled
  },
  props: {
    editLook: {
      type: Boolean,
      default: false
    },
    isSubmitting: {
      type: Boolean,
      default: false
    },
    submitError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('community', [
      'shareLookComponent'
    ]),
    ...mapGetters('community', [
      'clientCanShareLook'
    ]),
    errorMessage () {
      return this.editLook
        ? 'We encountered an error while trying to update your look. Please try again.'
        : 'We encountered an error while trying to share your look. Please try again.'
    }
  },
  methods: {
    ...mapVuexActions('community', [
      'postLook',
      'clearLookBuilder'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    setShareLookComponent (componentName) {
      this.$store.commit('community/SET_SHARE_LOOK_COMPONENT', componentName)
    }
  }
}
</script>
