<template>
  <base-modal
    v-if="!isMobile"
    use-title-border
    flush-content>
    <template #title>
      <h5 class="mb-0">
        {{ title }}
      </h5>
    </template>
    <template #body>
      <closet-photo-album
        :photo-album-entries="memberPhotoAlbumEntries"
        :initial-index="photoAlbumIndex"
        class="pt-3"
        @update-index="handleIndexUpdate"
        @link-clicked="handleSubtextClick"/>
    </template>
  </base-modal>
  <div
    v-else>
    <action-header
      page-header>
      <h5
        class="text-center mb-0">
        {{ title }}
      </h5>
    </action-header>
    <closet-photo-album
      :photo-album-entries="memberPhotoAlbumEntries"
      :initial-index="photoAlbumIndex"
      @update-index="handleIndexUpdate"/>
  </div>
</template>

<script>
import BaseModal from '../global/BaseModal'
import ActionHeader from '../global/sequin/ActionHeader'
import ClosetPhotoAlbum from './ClosetPhotoAlbum'
import { ClosetMemberPhotoMixin } from './ClosetMemberPhotoMixin'
import { mapState as mapVuexState } from 'vuex'
import { mapActions, mapState } from 'pinia'
import { vuexAccessors } from '../global/helpers/vuex'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    ClosetPhotoAlbum,
    BaseModal,
    ActionHeader
  },
  mixins: [ClosetMemberPhotoMixin],
  props: {
    componentType: {
      type: String,
      default: 'modal'
    }
  },
  computed: {
    ...vuexAccessors('closet', [
      'photoAlbumIndex'
    ]),
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('closet', [
      'styleColorsMap'
    ]),
    styleColorId () {
      return this.isMobile ? this.$route.params.id : this.modalContext.styleColorId
    },
    styleColor () {
      return this.styleColorsMap[this.styleColorId]
    },
    title () {
      return `Member Photos (${this.photoAlbumIndex + 1}/${this.memberPhotoAlbumEntries.length})`
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    handleIndexUpdate (newIndex) {
      this.photoAlbumIndex = newIndex
    },
    handleSubtextClick () {
      this.closeModal()
    }
  }
}
</script>
