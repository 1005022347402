<template>
  <div>
    <client-settings
      :header="header"
      :header-description="headerDescription"
      :display-settings="settings"/>
  </div>
</template>

<script>
import ClientSettings from './ClientSettings'

export default {
  components: { ClientSettings },
  data () {
    return {
      header: 'Membership',
      headerDescription: 'Updates about your case and Armoire subscription',
      settings: [
        {
          name: 'Push',
          settingKey: 'notifyMembershipPush'
        },
        {
          name: 'Email',
          fixedOn: true
        }
      ]
    }
  }
}
</script>
