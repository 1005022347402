<template>
  <div
    data-intercom-target="intercom-sizes"
    class="sizes-button"
    :class="{'expanded': expanded}"
    :style="cssVars"
    @mouseover="expanded = true"
    @mouseleave="expanded = false">
    <div>
      <svg-tape-measure
        height="20"
        width="20"
        class="tape-measure"
        :class="{ 'expanded': expanded }"/>
    </div>
    <div
      class="sizes small-text"
      :class="{ 'expanded': expanded }">
      <div>
        <b>{{ `${clientName}'s Sizes` }}</b>
      </div>
      <div
        v-for="(size, index) in taggedSizes"
        :key="index"
        class="size-range">
        <span class="text-capitalize">{{ size.subcategory + ': ' }}</span>
        {{ `usually wears a size ${size.sizes}` }}
      </div>
      <div>
        {{ `Height: ${styleProfile.heightFeet}'${styleProfile.heightInches}"` +
          ` • Bust: ${styleProfile.braSizeBand}${styleProfile.braSizeCup}` }}
      </div>
    </div>
  </div>
</template>

<script>
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import SvgTapeMeasure from '@/components/global/svg/SvgTapeMeasure.vue'

export default {
  name: 'FeedItemSizes',
  components: {
    SvgTapeMeasure
  },
  mixins: [StyleColorMixin],
  props: {
    clientName: {
      type: String,
      required: true
    },
    styleProfile: {
      type: Object,
      required: true
    },
    styleColors: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    taggedSizes () {
      const getSizeRange = range => range ? range.length > 1 ? `${range[0]} - ${range[range.length - 1]}` : `${range[0]}` : ''
      return this.styleColors.map(styleColor => ({
        subcategory: styleColor.style.subcategory,
        sizes: getSizeRange(this.getProfileSizesForStyleColor(this.styleProfile, styleColor))
      }))
    },
    cssVars () {
      return {
        '--numItems': this.taggedSizes.length + 2
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sizes-button {
  display: inline-flex;
  align-items: center;
  margin: 0  0 20px 20px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  background-color: $origami;
  color: $origami;
  opacity: 0.9;
  @include transition-base-all;

  &.expanded {
    margin: 0;
    background-color: $plum;
    border-radius: 0;
    width: 100%;
    height: calc(18px + #{$small-text-line-height} * var(--numItems));
  }
}
.sizes {
  color: $origami;
  margin-left: 28px;
}

.tape-measure {
  margin-left: 8px;
  stroke: $plum;
  @include transition-base-all;
  &.expanded {
    stroke: $white;
    margin-left: 28px;
  }
}
</style>
