<template>
  <div class="bg-white px-md-3">
    <router-view #default="{ Component }">
      <component
        :is="Component"
        class="py-md-4 px-0 col-12"/>
    </router-view>
  </div>
</template>

<script setup>
</script>
