import axios from './config'

// AUTH
const apiGlobal = {
  presignAWS (val) {
    return axios.post('/api/presign_item_feedback/', val)
  },
  subscribeToMarketingEmails (data) {
    return axios.post('/api/marketing/subscribe/', data)
  },
  setAuthTokenHeader (token) {
    axios.defaults.headers.common.Authorization = `KToken ${token}`
  }
}

export default apiGlobal
