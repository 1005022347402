<template>
  <div
    class="col-12 col-md-6 mx-auto edit-profile">
    <action-header
      page-header
      no-navigation
      class="p-3"
      :show-confirm="pageComponent.showConfirm"
      :disabled="pageComponent.disabled"
      @back-click="pageComponent.onBack"
      @confirm-click="pageComponent.onConfirm">
      <template #back>
        <svg-x v-if="isEditProfileHome"/>
      </template>
      {{ pageComponent.title }}
      <template #confirm>
        <div
          v-if="isEditProfileHome"
          class="d-flex align-items-center font-weight-bold text-primary">
          <p class="m-0">
            Save
          </p>
        </div>
      </template>
    </action-header>
    <component
      :is="pageComponent.componentName"
      v-bind="pageComponent.props"
      :class="pageComponent.class"
      v-on="pageComponent.handlers"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ActionHeader from '../../../global/sequin/ActionHeader'
import EditProfileBody from './EditProfileBody'
import EditProfilePhoto from './EditProfilePhoto'
import SvgX from '@/components/global/svg/SvgX.vue'

import goBack from '../../../global/mixins/goBack'
import EditProfileMixin from './EditProfileMixin'

export default {
  components: {
    ActionHeader,
    EditProfileBody,
    EditProfilePhoto,
    SvgX
  },
  mixins: [EditProfileMixin, goBack],
  computed: {
    ...mapState('client', [
      'id'
    ]),
    pageComponentDetails () {
      return {
        EditProfile: {
          ...this.componentDetails.EditProfile,
          onConfirm: () => { this.onSave() },
          onBack: () => {
            this.cleanupEditProfile()
            this.backToProfile()
          },
          disabled: this.isSaving
        },
        EditProfilePhoto: {
          ...this.componentDetails.EditProfilePhoto,
          onBack: () => { this.setEditProfileComponent('EditProfile') },
          disabled: false
        }
      }
    },
    pageComponent () {
      return this.pageComponentDetails[this.editProfileComponent]
    }
  },
  methods: {
    async onSave () {
      await this.saveProfileUpdates()

      if (!this.saveError) {
        this.backToProfile()
      }
    },
    backToProfile () {
      this.goBack({ name: 'profile', params: { id: this.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-profile {
  height: 100vh;
  background: $white;
}
</style>
