import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('styleQuizNav', [
      'prepayGroup'
    ]),
    ...mapGetters('subscribe', [
      'prepayPlans'
    ])
  },
  methods: {
    prepayPlanGroup () {
      let group = {}
      switch (this.prepayGroup) {
        case 'unlimited':
          group = {
            name: 'unlimited',
            planCardNameOverride: 'Armoire Unlimited'
          }
          break
        case '7-item':
          group = {
            name: '7-item',
            planCardNameOverride: 'Armoire <br> 7-Item Plan'
          }
          break
        case '4-item':
          group = {
            name: '4-item',
            planCardNameOverride: 'Armoire <br> 4-Item Plan'
          }
          break
      }
      return group
    },
    prepayHeroPlan () {
      const plans = this.prepayPlans(this.prepayGroup)
      if (plans) {
        return plans.slice(0, 1)[0]
      }
      return {}
    },
    prepayPlansSorted () {
      const sortFunction = (a, b) => {
        if (a.chargeInterval < b.chargeInterval) {
          return 1
        } else if (a.chargeInterval === b.chargeInterval) {
          return 0
        }
        return -1
      }
      return this.prepayPlans(this.prepayGroup).sort(sortFunction) || []
    }
  }
}
