<template>
  <Tippy
    :max-width="maxWidth"
    :placement="placement"
    :interactive="interactive"
    :arrow="true"
    theme="light"
    animation="shift-away"
    :z-index="nextFlyoutZIndex">
    <template #default>
      <slot name="trigger"/>
    </template>
    <template #content>
      <div class="text-start p-2">
        <slot name="content"/>
      </div>
    </template>
  </Tippy>
</template>

<script setup>
import { Tippy } from 'vue-tippy'
import { useOverlaysStore } from '@/stores/overlays'

defineProps({
  maxWidth: {
    type: [Number, String],
    default: 350
  },
  placement: {
    type: String,
    default: 'bottom'
  },
  interactive: {
    type: Boolean,
    default: false
  }
})

const { nextFlyoutZIndex } = useOverlaysStore()
</script>

<style lang="scss" scoped>
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/shift-away.css';
</style>
