<template>
  <div class="email-capture pt-5 py-4 px-4 text-center">
    <div>
      <component
        :is="isMobile ? 'h6' : 'h4'">
        Take 25% off your first order in the Armoire shop
      </component>
      <p>Plus be the first to know about new arrivals and exclusive offers.</p>
      <email-capture :source="source"/>
    </div>
  </div>
</template>

<script>
import EmailCapture from './EmailCapture'

export default {
  name: 'EmailCaptureFooter',
  components: { EmailCapture },
  props: {
    source: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.email-capture {
  display: flex;
  justify-content: center;
  background-color: $champagne;
}

</style>
