<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.538 12.377c-6.01 0-10.893-4.882-10.893-10.893a.64.64 0 0 0-.634-.634.64.64 0 0 0-.634.634c0 6.01-4.882 10.893-10.893 10.893a.64.64 0 0 0-.634.634c0 .347.287.634.634.634 6.01 0 10.893 4.882 10.893 10.893 0 .347.287.634.634.634a.64.64 0 0 0 .634-.634c0-6.01 4.882-10.893 10.893-10.893a.64.64 0 0 0 .634-.634.64.64 0 0 0-.634-.634m-3.866.634a12.22 12.22 0 0 0-7.66 7.663 12.21 12.21 0 0 0-7.681-7.663c3.615-1.21 6.454-4.067 7.679-7.664a12.22 12.22 0 0 0 7.662 7.664"
      :fill="fill"
      :stroke="stroke"/>
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'white'
    },
    stroke: {
      type: String,
      default: 'white'
    }
  }
}</script>
