import { mapActions, mapState, mapGetters } from 'vuex'
import { slugify } from '@/utils/stringParsing.js'

export const StyleColorMixin = {
  computed: {
    ...mapState('styleProfile', [
      'allSizeOrders',
      'plusSizeOptions',
      'sizeLetterMapping'
    ]),
    ...mapGetters('styleProfile', [
      'waistSizeOptionsMapped'
    ]),
    additionalColors () {
      return this.styleColor?.additionalColors || []
    },
    allImages () {
      const images = this.styleColor.images
      const res = [images.front]
      if (images.back) res.push(images.back)
      if (images.alternative) res.push(...images.alternative)
      return res
    },
    brand () {
      return this.style.brand
    },
    color () {
      const color = this.styleColor.color
      return color === 'Multi' ? color + '-color' : color
    },
    fitType () {
      const fitType = this.style.fitType
      return fitType === 'Relaxed' ? fitType + ' fit' : fitType
    },
    itemTypesSortedBySize () {
      const sizeOrders = this.allSizeOrders[this.styleColor.style.department]
      const noDeptHandler = parseInt(this.styleColor.itemTypes[0].trueSize)
      // noDeptHandler exists in the event that department is an empty string. Instead seeks the size for the first item in the array and converts it to a number. The conditional below is to confirm this is a number.
      if (sizeOrders) {
        return [...this.styleColor.itemTypes].sort((a, b) => a.trueSize in sizeOrders && b.trueSize in sizeOrders
          ? sizeOrders[a.trueSize] - sizeOrders[b.trueSize]
          : 0)
      } else if (typeof noDeptHandler === 'number') {
        return [...this.styleColor.itemTypes].sort((a, b) => parseInt(a.trueSize) - parseInt(b.trueSize))
      }
      return [...this.styleColor.itemTypes]
    },
    recommendedSize () {
      return this.styleColor.recommendedSize
    },
    recommendedSizeLoaded () {
      return 'recommendedSize' in this.styleColor
    },
    plusOptionsAllSizeFormats () {
      if (!this.plusSizeOptions) return null
      const filteredValues = this.plusSizeOptions.map(option => this.sizeLetterMapping[option]).filter(Boolean)
      return [...filteredValues, ...this.plusSizeOptions]
    },
    allItemTypesSortedBySizeFormatted () {
      const allItemTypesSortedBySizeFormatted = this.itemTypesSortedBySize.map(item => ({ ...item, value: item.trueSize, text: item.trueSize, disabled: item.numAvailable === 0, recommended: item.trueSize === this.styleColor.recommendedSize }))
      return allItemTypesSortedBySizeFormatted
    },
    standardItemTypes () {
      let matchingValues = this.itemTypesSortedBySize.filter(item => (!this.plusOptionsAllSizeFormats.includes(item.trueSize) && !this.isMaternitySize(item.trueSize) && !this.isWaistSizes(item)))
      matchingValues = this.mapItemTypes(matchingValues)
      return matchingValues
    },
    plusItemTypes () {
      let matchingValues = this.itemTypesSortedBySize.filter(item => this.plusOptionsAllSizeFormats.includes(item.trueSize))
      matchingValues = this.mapItemTypes(matchingValues)
      return matchingValues
    },
    maternityItemTypes () {
      let matchingValues = this.itemTypesSortedBySize.filter(item => this.isMaternitySize(item.trueSize))
      matchingValues = this.mapItemTypes(matchingValues)
      return matchingValues
    },
    waistSizeInInchesItemTypes () {
      let matchingValues = this.itemTypesSortedBySize.filter(item => {
        return this.isWaistSizes(item)
      })
      matchingValues = this.mapItemTypes(matchingValues)
      return matchingValues
    },
    hasStandardItemTypes () {
      return this.standardItemTypes?.length > 0
    },
    hasPlusItemTypes () {
      return this.plusItemTypes?.length > 0
    },
    hasMaternityItemTypes () {
      return this.maternityItemTypes?.length > 0
    },
    isDiscontinued () {
      return !this.styleColor.itemTypes || !this.styleColor.itemTypes.length
    },
    isUnreviewed () {
      return this.styleColor && this.styleColor.writtenReviews?.count === 0 &&
              this.styleColor.clientReviews?.length === 0 && this.styleColor.expertReviews?.length === 0
    },
    writtenReviewsCount () {
      return this.styleColor?.writtenReviews?.count
    },
    totalReviews () {
      return this.styleColor?.totalReviewsCount
    },
    maxPrice () {
      if (!this.styleColor?.priceRange) return null
      return this.styleColor.priceRange.maxPrice
    },
    maxPercentOff () {
      if (!this.styleColor?.priceRange) return null
      return Math.round(((this.price - this.minPrice) / this.price) * 100)
    },
    minPrice () {
      if (!this.styleColor?.priceRange) return null
      return this.styleColor.priceRange.minPrice
    },
    minPercentOff () {
      if (!this.styleColor?.priceRange) return null
      return Math.round(((this.price - this.maxPrice) / this.price) * 100)
    },
    hasPriceRange () {
      return this.minPrice && this.maxPrice && this.minPrice !== this.maxPrice
    },
    materials () {
      return this.style.materials
    },
    price () {
      return Math.round(this.style.retailPrice)
    },
    style () {
      return this.styleColor.style
    },
    shortName () {
      return this.getShortName(this.styleColor)
    },
    sizeGuideUrl () {
      if (this.style.sizeGuide) {
        return `${process.env.VUE_APP_STYLE_GUIDE_URL_BASE}` + this.style.sizeGuide
      } else {
        return null
      }
    },
    inseam () {
      const inseamList = []
      if (this.style.inseam) { inseamList.push(this.style.inseam + ' inches') }
      if (this.style.inseam_2) { inseamList.push(this.style.inseam_2 + ' inches') }
      if (this.style.inseam_3) { inseamList.push(this.style.inseam_3 + ' inches') }
      return inseamList
    },
    rise () {
      return this.style.rise
    },
    brandPageLink () {
      return {
        name: 'brand',
        params: {
          name: slugify(this.styleColor.style.brand),
          brandId: this.styleColor.style.brandId
        }
      }
    },
    handmadeGarment () {
      const message = 'Due to the handmade nature of this garment, some variations in color may occur.'
      return this.style.handmadeGarment ? message : null
    },
    patternVariation () {
      const message = 'Pattern may vary from photo.'
      return this.styleColor.patternVariation ? message : null
    },
    beltRemoved () {
      const message = 'Belt not included.'
      return this.styleColor.beltRemoved ? message : null
    }
  },
  methods: {
    ...mapActions('closet', [
      'getClientSizingForStyleColor'
    ]),
    getShortName (styleColor) {
      if (styleColor.style.subcategory === 'Other') return ''
      if (styleColor.style.subcategory === 'Soft Pants') return ' Casual Pants'
      if (styleColor.style.subcategory === 'Soft Jacket') return ' Jacket'
      if (styleColor.style.subcategory === 'Shirt/Button-up') return ' Button-up'
      return ' ' + styleColor.style.subcategory
    },
    getProfileSizesForStyleColor (styleProfile, styleColor) {
      const sizing = `${styleColor.sizingCategory}Sizes`
      return styleProfile[sizing]
    },
    detailPageSlug (styleColor) {
      return slugify(`${styleColor.style.brand} ${styleColor.style.name}`)
    },
    getPercentOff (priceClient) {
      return Math.round(((this.price - priceClient) / this.price) * 100)
    },
    isMaternitySize (size) {
      return !size.includes('/') && size.endsWith('M') && size.length > 1
    },
    mapItemTypes (itemTypes) {
      return itemTypes.map(item => ({ ...item, value: item.trueSize, text: item.trueSize, disabled: item.numAvailable === 0, recommended: item.trueSize === this.styleColor.recommendedSize }))
    },
    isWaistSizes (item) {
      return this.waistSizeOptionsMapped.some(option => option.value === item.trueSize)
    }
  }
}
