<template>
  <p class="small-text text-center mt-3">
    This style is on its way! You've got great taste.
  </p>
</template>

<script>
export default {
  props: {
    itemTypeShippedPackage: {
      type: Object,
      default: () => null
    }
  }
}
</script>
