import { arrayToSentence, capitalizeFirst } from '@/utils/stringParsing.js'

export default {
  methods: {
    getOverallRatingText (rating) {
      if (rating === 'like') return 'Love it'
      else if (rating === 'neutral') return 'Just OK'
      else return 'Dislike'
    },
    getFitText (review) {
      const tooBig = []
      const tooSmall = []

      const fitAttrs = {
        fitRatingShouldersReason: 'shoulders',
        fitRatingBustReason: 'bust',
        fitRatingWaistReason: 'waist',
        fitRatingHipsReason: 'hips',
        fitRatingRearReason: 'rear'
      }

      for (const attr of Object.keys(fitAttrs)) {
        if (review[attr] === 'too_big') tooBig.push(fitAttrs[attr])
        if (review[attr] === 'too_small') tooSmall.push(fitAttrs[attr])
      }

      let text = ''
      if (tooBig.length) {
        text += `Too big in the ${arrayToSentence(tooBig)}`
      }
      if (tooSmall.length) {
        text += text.length ? ', too ' : 'Too '
        text += `small in the ${arrayToSentence(tooSmall)}`
      }

      const lengthReason = review.fitRatingLengthReason
      if (lengthReason) {
        const lengthText = (
          lengthReason === 'too_small' ? 'not long enough' : 'too long')

        if (!text.length) capitalizeFirst(lengthText)
        text += text.length ? `, and ${lengthText}` : `${lengthText}`
      }
      return text || 'True to size'
    },
    getShortFitText (review) {
      const sizingRating = review.sizingRating
      if (sizingRating === 0) {
        return 'True to size'
      }
      if (sizingRating > 0) {
        return 'Runs large'
      } else {
        return 'Runs small'
      }
    },
    getWornFrequencyText (review) {
      if (review.nWears === '3') return '3+ times'
      if (review.nWears === '2') return '2 times'
      if (review.nWears === '1') return '1 time'
      return 'never'
    }
  }
}
