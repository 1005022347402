import axios from './config'

const apiItems = {
  getCheckoutRecommendations () {
    return axios.get('/api/items/?type=checkout_recommendations')
  },
  getItemPurchaseSuggestions () {
    return axios.get('/api/items/?type=purchase_suggestions')
  },
  getItemPrice (id, promoCode = null) {
    let url = '/api/items/' + id + '/price/'
    if (promoCode) {
      url += '?promo_code=' + promoCode
    }
    return axios.get(url)
  },
  getItemTypePrice (id) {
    const url = '/api/itemtypes/' + id + '/price/'
    return axios.get(url)
  },
  purchaseItem (id, data) {
    return axios.post(`/api/items/${id}/purchase/`, data)
  },
  getSale () {
    return axios.get('/api/items/sale/')
  },
  getAvailableAddOnItems () {
    return axios.get('/api/addonitems/')
  },
  getAddOnItemsPrice (id, quantity) {
    return axios.get(`/api/addonitemtypes/${id}/price/?quantity=${quantity}`)
  },
  purchaseAddOnItems (id, data) {
    return axios.post(`/api/addonitems/${id}/purchase/`, data)
  },
  getBonusItemsPrice (quantity) {
    return axios.get(`/api/bonusitems/price/?quantity=${quantity}`)
  },
  fetchCaseSatisfactionSurveyOptions () {
    return axios.get('/api/packageitemsurveychoices/')
  },
  submitCaseSurveyAnswerByItem (data) {
    return axios.post('/api/packageitemsurvey/', data)
  }
}

export default apiItems
