/**
 * @typedef {{
 *  log: (...args: any[]) => void,
 *  debug: (...args: any[]) => void,
 *  info: (...args: any[]) => void,
 *  warn: (...args: any[]) => void,
 *  error: (...args: any[]) => void
 * }} Logger
 */

/** @type {Logger} */
let logger = {
  log: () => {},
  debug: () => {},
  info: () => {},
  warn: () => {},
  error: () => {}
}

/** @param {Logger} newLogger */
export function setSharedLogger (newLogger) {
  logger = newLogger
}

export default logger
