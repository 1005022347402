<template>
  <horizontal-scroll
    data-intercom-target="intercom-tagged-styles"
    class="style-colors"
    scroll-button-size="small">
    <div
      v-for="styleColor in styleColors"
      :key="styleColor.id"
      class="style-color-container"
      :class="{'discontinued': styleColor.discontinued}">
      <preview-image-button
        class="me-3"
        :src="styleColor.image.url"
        :size="imageSize"
        @click="handleProductClick(styleColor)"/>
    </div>
  </horizontal-scroll>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HorizontalScroll from '../../global/sequin/HorizontalScroll'
import PreviewImageButton from '@shared/components/ADORN/PreviewImageButton.vue'
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import { useFlyouts } from '@/composables/flyouts.js'

export default {
  components: {
    HorizontalScroll,
    PreviewImageButton
  },
  mixins: [StyleColorMixin],
  props: {
    styleColors: {
      type: Array,
      required: true
    },
    feedItemId: {
      type: String,
      required: true
    },
    imageSize: {
      type: String,
      default: 'small'
    },
    styleColorSource: {
      type: String,
      default: null
    }
  },
  setup () {
    const flyouts = useFlyouts()
    return {
      flyouts
    }
  },
  computed: {
    ...mapState('closet', [
      'ratingsMap',
      'styleColorsMap'
    ]),
    feedItemStyleColorSource () {
      if (this.styleColorSource) return this.styleColorSource
      return `feeditem-${this.feedItemId}`
    }
  },
  methods: {
    ...mapActions('closet', [
      'setStyleColorSource',
      'getStyleColor'
    ]),
    async fullStyleColor (styleColorId) {
      if (!(styleColorId in this.styleColorsMap)) {
        await this.getStyleColor(styleColorId)
      }
      return this.styleColorsMap[styleColorId]
    },
    async handleProductClick (styleColor) {
      const fullStyleColor = await this.fullStyleColor(styleColor.id)
      await this.flyouts.openClosetDetailFlyout(fullStyleColor, this.feedItemStyleColorSource)
    }
  }
}
</script>

<style lang="scss" scoped>

.style-colors {
  margin: 24px 28px 0;
}

.style-color-container {
  padding-bottom: 12px;
}

.discontinued {
  opacity: 0.6;
}
</style>
