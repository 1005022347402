<template>
  <plain-button
    v-bind="$attrs"
    class="style-color-selection border-bottom"
    :class="{ 'selected': selected }"
    :disabled="selectionDisabled"
    :aria-label="`${item.styleColor.style.brand} ${item.styleColor.style.name} link`"
    @click="$emit('style-color-click', item.styleColor)">
    <div
      :class="{'discontinued': discontinued}"
      class="style-color-image-wrapper">
      <preview-image
        v-if="imageOverride || (item.styleColor.images[0] && item.styleColor.images[0].url)"
        size="small"
        class="style-color-image"
        :alt="`${item.styleColor.style.brand} ${item.styleColor.style.name}`"
        :class="{ 'style-color-image-override': imageOverride }"
        :eager-load="!hideOutsideOfViewport"
        :src="imageOverride || item.styleColor.images[0].url">
        <template #overlay>
          <div v-if="selected">
            <svg-circle-icon
              :height="36"
              :width="36"
              variant="primary"
              :padding="16"
              :stroke-width="3"
              class="check">
              <svg-check/>
            </svg-circle-icon>
          </div>
          <div
            v-else-if="discontinued"
            class="d-flex align-items-center">
            <div class="unavailable small-text py-1">
              No longer available
            </div>
          </div>
        </template>
      </preview-image>
      <div
        v-else
        class="style-color-image placeholder"/>
    </div>
    <div
      :class="{'discontinued': discontinued}"
      class="ms-3 small-text style-color-info">
      <div class="m-0">
        <text-line-limit class="m-0">
          {{ item.styleColor.style.brand }}
        </text-line-limit>
        <text-line-limit
          :line-limit="nameLineLimit"
          class="semi-bold mb-0 one-line-limit">
          {{ item.styleColor.style.name }}
        </text-line-limit>
      </div>
      <div>
        <slot name="listItemDetails"/>
      </div>
    </div>
  </plain-button>
</template>

<script>
import PlainButton from '../../global/sequin/PlainButton'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import TextLineLimit from '../../global/sequin/TextLineLimit'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    PlainButton,
    PreviewImage,
    TextLineLimit,
    SvgCircleIcon,
    SvgCheck
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    imageOverride: {
      type: String,
      default: () => null
    },
    selected: {
      type: Boolean,
      default: false
    },
    selectionDisabled: {
      type: Boolean,
      default: false
    },
    nameLineLimit: {
      type: Number,
      default: 2
    },
    hideOutsideOfViewport: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    discontinued () {
      return this.item && this.item.styleColor && this.item.styleColor.discontinued
    }
  }
}
</script>

<style lang="scss" scoped>
.style-color-image-override {
  :deep(.style-image-constrained) {
    object-position: center center;
  }
}
.style-color-selection {
  border-radius: 0;
  background-color: $white;
  width: 100%;
  min-height: 156px;
  display: flex;
  padding: 12px 24px;

  &.selected {
    background-color: rgba($origami, .3);
  }

  .style-color-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .style-color-image-wrapper {
    position: relative;

    .style-color-image {
      background: $origami;

    }

    .check {
      width: fit-content;
      margin: 48px auto;
    }

    .unavailable {
      height: fit-content;
      background-color: $champagne;
      text-align: center;
    }
  }
  .discontinued {
    opacity: 0.6;
  }
}
</style>
