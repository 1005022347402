<template>
  <div class="img-overlay d-flex justify-content-center align-items-center">
    <div class="img-overlay-icon">
      <slot/>
    </div>
  </div>
</template>

<style lang="scss" style="scoped">
  .img-overlay {
    border-radius: 5px;
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    opacity: 0;
    transition: all .1s;
    &[persistent] {
      opacity: 1;
    }
    &:hover {
      opacity: 0.7;
    }
  }
</style>
