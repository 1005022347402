<template>
  <div class="bg-white likes-container">
    <action-header
      page-header
      :fallback-route="{ name: 'feed' }">
      Likes + Favorites
    </action-header>
    <transition
      name="fade"
      mode="out-in">
      <div
        v-if="!loaded"
        key="loading"
        class="text-center">
        <svg-spinner
          v-if="!loaded"
          height="40"
          class="mt-4"/>
      </div>
      <div v-else>
        <div
          v-for="(entry, index) in likesAndFavorites"
          :key="index"
          class="d-flex py-3 px-4 profile-border">
          <profile-header
            :client="entry.client"
            :location="entry.client.location"/>
          <feed-item-action-icons
            :show-likes="entry.liked"
            :show-favorites="entry.favorited"
            :size="28"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ActionHeader from '../../global/sequin/ActionHeader'
import ProfileHeader from './ProfileHeader'
import FeedItemActionIcons from './FeedItemActionIcons'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  components: {
    ActionHeader,
    ProfileHeader,
    FeedItemActionIcons,
    SvgSpinner
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapState('community', [
      'feedItemLikesAndFavorites'
    ]),
    likesAndFavorites () {
      return this.feedItemLikesAndFavorites[this.$route.params.id]
    }
  },
  async mounted () {
    await this.getFeedItemLikesAndFavorites(this.$route.params.id)
    this.loaded = true
  },
  methods: {
    ...mapActions('community', [
      'getFeedItemLikesAndFavorites'
    ])
  }
}
</script>

<style lang="scss" scoped>
.profile-border {
  border-bottom: 0.5px $ash solid;
}

.likes-container {
  min-height: 100vh;
}

</style>
