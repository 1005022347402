<template>
  <div>
    You've received <b>${{ retailValueReceived }}</b> of clothing
    <span v-if="membershipStatus == 'active' || membershipStatus == 'paused'">
      since joining Armoire on {{ membershipStartDate }}!
    </span>
    <span v-else>
      while a member of Armoire!
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { toLocaleDateString } from '@/utils/stringParsing.js'

export default {
  computed: {
    ...mapState('client', [
      'membershipStatus',
      'membershipDetails',
      'retailValueReceived'
    ]),
    membershipStartDate () {
      return toLocaleDateString(this.membershipDetails.membershipStart)
    }
  }
}
</script>
