<template>
  <BaseStickyFooter
    :is-visible="show"
    class="position-relative pt-md pb-lg">
    <template #content>
      <div class="w-100 d-flex flex-column align-items-center row-gap-lg">
        <BaseButton
          v-if="signUpPhase1"
          :text="buttonText"
          class="w-100"
          @click="next"/>
        <BaseButton
          v-else
          :text="buttonText"
          class="w-100"
          :to="nextScreenRouterLink"
          @click="emit('next')"/>
        <slot name="additional-content"/>
      </div>
    </template>
  </BaseStickyFooter>
</template>

<script setup>
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import BaseStickyFooter from '@shared/components/ADORN/BaseStickyFooter.vue'
import { useSignUpExperiments } from '@shared/composables/experiment.js'
import { storeToRefs } from 'pinia'
import { computed, toValue } from 'vue'

const signUpNav = useSignUpNavigationStore()
const { next } = signUpNav
const { currentScreen, nextScreenRouterLink } = storeToRefs(signUpNav)
const show = computed(() => toValue(currentScreen.value.footer?.show) ?? false)
const buttonText = computed(() => currentScreen.value.footer?.buttonText ?? 'Next')

const { signUpPhase1 } = useSignUpExperiments()

const emit = defineEmits(['next'])
</script>
