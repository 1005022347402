<template>
  <popover-dropdown
    variant="right"
    @click="$emit('more-click')">
    <template #button>
      <svg-more/>
    </template>
    <template #dropdown-content="dropdownScope">
      <edit-delete-menu
        class="edit-look"
        item-name="Look"
        @delete-click="onDelete(dropdownScope)"
        @cancel-click="dropdownScope.closeDropdown"
        @edit-click="onEdit(dropdownScope)"/>
    </template>
  </popover-dropdown>
</template>

<script>
import EditDeleteMenu from './EditDeleteMenu'
import PopoverDropdown from '../../../global/sequin/PopoverDropdown'
import { mapState, mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgMore from '@/components/global/svg/SvgMore.vue'

export default {
  components: {
    PopoverDropdown,
    EditDeleteMenu,
    SvgMore
  },
  computed: {
    ...mapState('community', [
      'itemInFocus'
    ])
  },
  methods: {
    ...mapVuexActions('community', [
      'initializeLookBuilder',
      'deleteLook'
    ]),
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    onEdit (dropdownScope) {
      this.initializeLookBuilder(this.itemInFocus)
      dropdownScope.closeDropdown()
      this.openModal({ name: 'edit-look' })
    },
    async onDelete (dropdownScope) {
      dropdownScope.closeDropdown()
      this.openModal({ name: 'community-delete-confirmation' })
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-look {
  width: 240px;
}
</style>
