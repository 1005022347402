<template>
  <div class="container-fluid">
    <div
      v-if="isMobile"
      class="pt-4 mb-3">
      <action-header page-header>
        <h5>Shipping + Pickup</h5>
      </action-header>
    </div>
    <div
      v-else
      class="container pt-4 px-0">
      <bread-crumb
        class="mb-3 mx-3"
        current-location="Shipping"
        :crumbs="[{ name: 'Account', to: { name: 'account' } }]"/>
      <h2
        class="pb-3 mx-2 mx-md-3 text-md-start">
        Shipping + Pickup
      </h2>
    </div>
    <div class="container">
      <base-alert
        v-if="showAlert"
        key="alert"/>
      <div class="row g-2">
        <div
          class="col-12 col-md-6">
          <h5>Shipping</h5>
          <div
            v-for="address in shippingAddresses"
            :key="address.id">
            <address-card
              :first-name="firstName"
              :last-name="lastName"
              :shipping-id="shippingId"
              :address="address"
              @select-address="selectAddress"/>
          </div>
          <sequin-button
            primary
            @click="addNewAddress()">
            Add New Address
          </sequin-button>
        </div>
        <div
          class="col-12 col-md-6"
          :class="{'mt-lg' : isMobile }">
          <h5>Pickup</h5>
          <div
            v-for="location in pickupLocations"
            :key="location.id">
            <pickup-location-card
              :location-id="locationId"
              :location="location"
              @select-location="selectLocation"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import AddressCard from './AddressCard'
import PickupLocationCard from './PickupLocationCard'
import ShippingAddressMixin from './ShippingAddressMixin'
import { useOverlaysStore } from '@/stores/overlays.js'
import ActionHeader from '@/components/global/sequin/ActionHeader.vue'
import BreadCrumb from '@/components/global/sequin/BreadCrumb.vue'
import { vuexAccessors } from '@/components/global/helpers/vuex'
import SequinButton from '../global/sequin/SequinButton'
import BaseAlert from '@/components/global/BaseAlert.vue'

export default {
  components: {
    BreadCrumb,
    ActionHeader,
    AddressCard,
    PickupLocationCard,
    SequinButton,
    BaseAlert
  },
  mixins: [ShippingAddressMixin],
  computed: {
    ...vuexAccessors('account', [
      'breadcrumbs'
    ]),
    ...mapState('account', [
      'selectAddressResult',
      'shippingAddresses',
      'pickupLocations'
    ]),
    ...mapState('client', [
      'canChangeShipping',
      'firstName',
      'lastName',
      'username'
    ]),
    ...mapState('global', [
      'showAlert'
    ])
  },
  mounted: async function () {
    this.breadcrumbs = [{ name: 'Shipping + Packaging' }]
    if (!this.canChangeShipping) {
      this.$router.push({ name: 'account' })
    }
    this.getShippingAddresses(this.username)
    this.getPickupLocations()
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    ...mapVuexActions('global', [
      'setAlert'
    ]),
    ...mapVuexActions('account', [
      'getShippingAddresses',
      'getPickupLocations',
      'selectShippingAddress',
      'selectPickupLocation'
    ]),
    async selectAddress (address) {
      try {
        await this.selectShippingAddress(address)
        this.setAlert({ msg: 'Your shipping address has been updated.' })
      } catch (err) {
        this.setAlert({
          msg: 'There was a problem updating your address.',
          style: 'warning'
        })
      }
    },
    async selectLocation (location) {
      try {
        await this.selectPickupLocation(location)
        this.setAlert({ msg: 'Your pickup location has been set.' })
      } catch (err) {
        this.setAlert({
          msg: 'There was a problem setting your pickup location.',
          style: 'warning'
        })
      }
    },
    addNewAddress () {
      this.openModal({
        name: 'account-edit-shipping-address',
        context: { showSetSelected: true }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .card {
    border: $default-border;
    border-radius: $sequin-border-radius;
    margin-bottom: $spacing-lg;
    padding: $spacing-md;
    display: flex;
    justify-content: space-between;
    aspect-ratio: 5 / 2;
  }
</style>
