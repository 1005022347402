// Vue-specific helpers

// Get all listeners from an object of attributes.
//
// This is useful for cases where you still want the primary element
// to inherit attributes, but you want forward listeners to a child.
//
// https://v3-migration.vuejs.org/breaking-changes/listeners-removed.html
//
// TODO: Remove this: https://armoire.atlassian.net/browse/TA-3448
export function getListeners (attributes) {
  return Object.keys(attributes)
    .filter(key => key.startsWith('on'))
    .reduce((listeners, key) => {
      listeners[key] = attributes[key]
      return listeners
    }, {})
}
