<template>
  <div
    v-if="selectedTags.length === 0"
    class="w-100 bg-white py-3 px-4 border-top">
    <plain-button
      :disabled="disabled"
      class="w-100 bg-white p-0"
      @click="$emit('full-tag-occasion-clicked')">
      <div class>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">
            Tag Occasion
          </h5>
          <svg-chevron-right/>
        </div>
      </div>
    </plain-button>
    <horizontal-scroll
      v-if="isMobile && occasionTags.length > 0"
      class="occasion-in-place">
      <plain-button
        v-for="tag in occasionTags.slice(0,10)"
        :key="tag.tag"
        :disabled="disabled"
        class="small-occasion-tag"
        @click="$emit('tag-occasion-clicked', [tag])">
        {{ tag.tag }}
      </plain-button>
      <plain-button
        :disabled="disabled"
        class="small-occasion-tag"
        @click="$emit('full-tag-occasion-clicked')">
        <svg-chevron-right
          height="22"
          width="20"/>
      </plain-button>
    </horizontal-scroll>
    <horizontal-scroll
      v-else-if="isMobile"
      class="occasion-in-place">
      <div
        v-for="index in 3"
        :key="index"
        class="small-occasion-tag placeholder"/>
    </horizontal-scroll>
  </div>
  <occasion-tag
    v-else
    class="border-top"
    show-remove
    :tag="selectedTags[0]"
    :disabled="disabled"
    @remove-click="$emit('tag-occasion-clicked', [])"/>
</template>

<script>
import PlainButton from '../../global/sequin/PlainButton'
import HorizontalScroll from '../../global/sequin/HorizontalScroll'
import OccasionTag from './OccasionTag'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'

export default {
  components: {
    OccasionTag,
    PlainButton,
    HorizontalScroll,
    SvgChevronRight
  },
  props: {
    occasionTags: {
      type: Array,
      required: true
    },
    selectedTags: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.occasion-in-place {
  margin: 16px 0 0;
  height: 32px;
  width: 100%;

  .small-occasion-tag {
    flex: 0 0 auto;
    background-color: $origami;
    text-transform: capitalize;
    padding: 4px 8px;
    margin-right: 12px;
    border-radius: $sequin-border-radius;
    font-size: $small-text-font-size;

    &.placeholder {
      width: 120px;
      height: 30px;
    }
  }
}
</style>
