<template>
  <div ref="wrapper">
    <HorizontalScrollSectionPlaceholder
      v-if="loading"
      :full-bleed="true">
      <ImageTileButtonPlaceholder
        v-for="i in 5"
        :key="`placeholder-${i}`"
        :class="tileClasses"/>
    </HorizontalScrollSectionPlaceholder>
    <HorizontalScrollSection
      v-else-if="shouldShow"
      :full-bleed="true"
      title="Featured">
      <FeaturedSectionTileButton
        v-for="section in visibleSections"
        :key="section.id"
        :section-id="section.id"
        :class="tileClasses"/>
    </HorizontalScrollSection>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import FeaturedSectionTileButton from '@/components/closet/FeaturedSectionTileButton.vue'
import ImageTileButtonPlaceholder from '@/components/global/ImageTileButtonPlaceholder.vue'
import HorizontalScrollSection from '@shared/components/ADORN/HorizontalScrollSection.vue'
import HorizontalScrollSectionPlaceholder from '@shared/components/ADORN/HorizontalScrollSectionPlaceholder.vue'
import { useElementVisibility } from '@vueuse/core'

const store = useStore()
const featuredSectionsLoaded = computed(() => store.state.closet.featuredSectionsLoaded)
const featuredSections = computed(() => store.getters['closet/featuredSections'])
const visibleSections = computed(() => featuredSections.value.filter(section => section.length > 6))

const shouldShow = computed(() => featuredSectionsLoaded.value && visibleSections.value.length > 0)
const tileClasses = 'featured-section col-12 col-sm-6 col-md-6 col-lg-3'

const loading = ref(true)
const wrapper = ref(null)
const visible = useElementVisibility(wrapper)
const stopWatching = watchEffect(async () => {
  if (visible.value && loading.value && featuredSectionsLoaded.value) {
    stopWatching()
    const promises = featuredSections.value.filter(section => section.length === null).map(section =>
      store.dispatch('closet/loadStyleColors', {
        id: section.id,
        amount: 6
      })
    )
    await Promise.allSettled(promises)
    loading.value = false
  }
})

defineOptions({
  compatConfig: {
    // The useElementVisibility composable is based on useIntersectionObserver
    // which doesn't use the deep option on the array it watches. The composable
    // works so we're suppressing the warning.
    WATCH_ARRAY: false
  }
})
</script>

<style lang="scss" scoped>
$thumbnailWidth: 300px;
$thumbnailHeight: 250px;

.banner-image {
  width: 100%;
  height: 100%;
}

.image-container {
  position: relative;
  height: 207px;
}

.custom-image {
  width: 334px;
  height: 207px;
  border-radius: 2px;
  object-fit: cover;
}

.featured-section {
  display: flex;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
}

.details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 74px;
  justify-content: center;
  align-items: center;
  background-color: rgba($armor, 0.75);
  text-align: center;
  padding: 18px;
  box-sizing: border-box;
}

.placeholders {
  display: flex;
  gap: 36px;
  overflow: hidden;
}

.section-placeholder {
  height: $thumbnailHeight;
  width: $thumbnailWidth;
  flex-shrink: 0;
  @include placeholder-loading-animation;
}
</style>
