<template>
  <div>
    <div class="container-xxl">
      <div class="row">
        <div
          v-if="categories.list"
          class="col-12 col-md-6">
          <div class="row">
            <h2 class="text-center section-headline">
              {{ categories.title }}
            </h2>
          </div>
          <div
            class="row"
            :class="{ 'border-end': !isMobile }">
            <div
              v-for="(item, index) in sortedCategories"
              :key="item.value"
              :class="['col-lg-4 col-6', { 'col-12': index >= sortedCategories.length - 3 }]">
              <TextLink
                class="d-block text-wrap mb-3"
                variant="tertiary"
                size="paragraph"
                type="router-link"
                :name="`Browse ${item.text}` "
                :underline="false"
                :to="{ name: 'browse', params: { section: categories.name, subsection: item.value} }"
                @click="$emit('close-browse')">
                {{ item.text }}
              </TextLink>
            </div>
          </div>
        </div>
        <div
          v-if="occasions.list"
          class="col">
          <div class="row">
            <h2
              class="text-center section-headline">
              {{ occasions.title }}
            </h2>
          </div>
          <div
            class="row">
            <div
              v-for="item in occasions.list"
              :key="item.value"
              class="col-lg-4 col-6">
              <TextLink
                class="d-block text-wrap mb-3"
                variant="tertiary"
                size="paragraph"
                type="router-link"
                :name="`Browse ${item.text}` "
                :underline="false"
                :to="{ name: 'browse', params: { section: occasions.name, subsection: item.value} }">
                {{ item.text }}
              </TextLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="topBrands.length > 0">
      <HorizontalScrollSection
        title="Top Brands">
        <router-link
          v-for="brand in topBrands"
          :key="brand.id"
          class="col-4 col-md-2 col-sm-3 brand-card"
          :to="{ name: 'brand', params: { name: slugifiedBrand(brand.name), brandId: brand.id }}">
          <img
            :src="brand.brandTileImgUrl"
            :alt="brand.name"
            class="brand-image">
        </router-link>
      </HorizontalScrollSection>
    </div>
    <div class="container-xxl">
      <div class="row">
        <h2 class="text-center section-headline">
          Spotlight
        </h2>
        <span/>
      </div>
      <BrowseSpotlight class="row"/>
    </div>
  </div>
</template>

<script>
import browseMixin from './browseMixin.vue'
import TextLink from '../sequin/TextLink.vue'
import BrowseSpotlight from './BrowseSpotlight.vue'
import HorizontalScrollSection from '@shared/components/ADORN/HorizontalScrollSection.vue'
import { mapState } from 'vuex'

export default {
  components: {
    TextLink,
    BrowseSpotlight,
    HorizontalScrollSection
  },
  mixins: [
    browseMixin
  ],
  computed: {
    ...mapState('brands', [
      'brandDetails'
    ]),
    topBrands () {
      return Object.values(this.brandDetails).filter(({ brandTileImgUrl }) => brandTileImgUrl !== null)
    },
    sortedCategories () {
      return this.categories.list.toSorted((a, b) => a.text.length - b.text.length)
    }
  }
}
</script>

<style lang="scss" scoped>
.brand-card {
  max-width: 245px;
  height: auto;
}
.brand-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.text-wrap {
  word-wrap: break-word;
}

:deep(.text-link) {
  &:hover {
    text-decoration: none;
  }
}

</style>
