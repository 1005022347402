<template>
  <accordion-with-header
    :expanded="expanded"
    title="Height"
    @accordion-click="e => $emit('accordion-click', e)">
    <template #subtitle>
      <p class="ms-2 mb-0 text-pewter">
        {{ getHeightText }}
      </p>
    </template>
    <div class="d-flex">
      <div>
        <p
          class="mb-0 small-text">
          Feet
        </p>
        <dropdown-single-select
          v-model="heightFeet"
          small
          class="height-input"
          label="Feet"
          placeholder="Feet"
          :initial-selection-index="getInitialSelectionIndex('heightFeet')"
          :dropdown-options="heightFeetOptions"
          :validations="v$.heightFeet"/>
      </div>
      <div>
        <p
          class="mb-0 small-text">
          Inches
        </p>
        <dropdown-single-select
          v-model="heightInches"
          small
          class="height-input"
          label="Inches"
          placeholder="Inches"
          :initial-selection-index="getInitialSelectionIndex('heightInches')"
          :dropdown-options="heightInchesOptions"
          :validations="v$.heightInches"/>
      </div>
    </div>
  </accordion-with-header>
</template>

<script>
import { mapState } from 'vuex'
import { vuexAccessors } from '../global/helpers/vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, between } from '@vuelidate/validators'

import AccordionWithHeader from '../global/sequin/AccordionWithHeader.vue'
import DropdownSingleSelect from '../global/sequin/DropdownSingleSelect'

export default {
  components: {
    AccordionWithHeader,
    DropdownSingleSelect
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    ...mapState('styleProfile', [
      'heightFeetOptions',
      'heightInchesOptions',
      'heightFeet',
      'heightInches'
    ]),
    ...vuexAccessors('styleProfile', [
      'heightFeet',
      'heightInches'
    ]),
    minHeightFeet () {
      return Math.min(...this.heightFeetOptions.map(x => parseInt(x.text)))
    },
    maxHeightFeet () {
      return Math.max(...this.heightFeetOptions.map(x => parseInt(x.text)))
    },
    getHeightText () {
      return this.heightFeet + ' ft ' + (this.heightInches ? this.heightInches : '0') + ' in'
    }
  },
  methods: {
    getInitialSelectionIndex (type) {
      const optionType = `${type}Options`
      if (this[type] && this[optionType]) {
        const index = this[optionType].findIndex(option => option.value === this[type])
        if (index > -1) return index
      }

      return null
    }
  },
  validations () {
    return {
      heightFeet: {
        required,
        numeric,
        betweenValue: between(this.minHeightFeet, this.maxHeightFeet)
      },
      heightInches: {
        required,
        numeric,
        betweenValue: between(0, 11)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.height-input {
  min-width: unset;
  width: 88px;
  margin: 8px 12px 12px 0;
}
</style>
