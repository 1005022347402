<template>
  <div>
    <div
      v-show="showUppy"
      class="bg-white position-relative">
      <div id="drag-drop-area">
        <plain-button
          v-if="allowMultiple && localImages.length > 0"
          class="close-button"
          @click="setShowUppy(false)">
          <svg-x height="20"/>
        </plain-button>
      </div>
    </div>
    <div v-if="localImages.length > 0">
      <multi-image-preview
        v-if="allowMultiple"
        :images="localImages"
        :allow-add-another="!showUppy"
        @delete-image="deletePhoto"
        @add-another="setShowUppy(true)"/>
      <single-image-preview
        v-else
        :image="localImages[0]"
        :allow-delete="!disabled"
        @delete-image="deletePhoto"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import AwsS3 from '@uppy/aws-s3'
import Webcam from '@uppy/webcam'
import PlainButton from '../PlainButton'
import SingleImagePreview from './SingleImagePreview'
import MultiImagePreview from './MultiImagePreview'
import SvgX from '@/components/global/svg/SvgX.vue'

export default {
  components: {
    MultiImagePreview,
    SingleImagePreview,
    PlainButton,
    SvgX
  },
  props: {
    localImages: {
      type: Array,
      required: true
    },
    allowMultiple: {
      type: Boolean,
      default: false
    },
    note: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 400
    },
    imageUploadUrlFunction: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uppy: {},
      showUppy: true
    }
  },
  computed: {
    ...mapState('community', [
      'lookBuilder'
    ]),
    ...mapState('client', [
      'username'
    ])
  },
  mounted () {
    this.showUppy = this.localImages.length === 0
    const beforeUpload = this.beforeUpload
    this.uppy = Uppy({
      onBeforeUpload (files) {
        beforeUpload(files)
      },
      allowMultipleUploads: false,
      restrictions: {
        allowedFileTypes: ['image/*'],
        maxFileSize: 10000000,
        maxNumberOfFiles: this.allowMultiple ? null : 1
      }
    })
      .use(Dashboard, {
        inline: true,
        target: '#drag-drop-area',
        width: 'auto',
        height: this.height,
        proudlyDisplayPoweredByUppy: false,
        note: this.note,
        locale: {
          strings: {
            dropPasteImport: 'Drop, paste, %{browse} or import a photo'
          }
        }
      })
      .use(AwsS3, {
        getUploadParameters: this.getUploadParameters
      })
      .use(Webcam, {
        target: Dashboard,
        mirror: false,
        modes: [
          'picture'
        ]
      })

    this.uppy.on('file-added', (file) => this.fileAdded(file))
    this.uppy.on('upload-success', (file) => this.uploadSuccess(file))
    this.uppy.on('complete', (result) => this.complete(result))
  },
  methods: {
    beforeUpload (files) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, file] of Object.entries(files)) {
        if (file.meta.width < 500 || file.meta.height < 500) {
          this.uppy.info('Image must be at least 500px wide and high', 'error')
          throw new Error('File is too small')
        }
      }
    },
    getImageDimensions (id, url) {
      return new Promise((resolve) => {
        const image = new Image()
        image.src = url
        image.onload = () => {
          this.uppy.setFileMeta(id, { width: image.width, height: image.height })
          URL.revokeObjectURL(url)
          resolve()
        }
      })
    },
    async fileAdded (file) {
      file.meta.username = this.username
      const data = file.data
      const url = URL.createObjectURL(data)
      await this.getImageDimensions(file.id, url)
      this.uppy.upload() // trigger the upload after we know the file size
    },
    uploadSuccess (file) {
      this.$emit('add-local-upload', file.xhrUpload.endpoint + '/' + file.meta.key)
    },
    complete (result) {
      if (result.failed.length === 0) {
        this.showUppy = false
        this.uppy.reset()
      }
    },
    deletePhoto (photo) {
      this.$emit('remove-local-upload', photo)
      if (this.localImages.length === 0) {
        this.$nextTick(() => { this.showUppy = true })
      }
    },
    getUploadParameters (file) {
      const data = {
        filename: file.name,
        contentType: file.type,
        itemId: file.meta.itemId,
        username: file.meta.username
      }
      return this.imageUploadUrlFunction(data).then((response) => {
        // Parse the JSON response.
        return response.data
      }).then((data) => {
        // Return an object in the correct shape.
        return {
          method: data.method,
          url: data.url,
          fields: data.fields
        }
      })
    },
    setShowUppy (state) {
      this.showUppy = state
    }
  }
}
</script>

<style lang="scss" scoped>

#drag-drop-area {
  :deep(.uppy-Dashboard-inner) {
    border: none;
    background-color: $origami;
    border-radius: 0;
  }
  :deep(.uppy-DashboardTab) {
    border: none;
  }
  :deep(.uppy-Dashboard-AddFiles-title) {
    font-family: $body-font-family;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: $armor;
    text-align: center;
    margin: 24px;
    margin-top: 40px;
  }
  :deep(.uppy-Dashboard-note) {
    margin-top: 24px;
  }
  :deep(.uppy-Dashboard-browse) {
    color: $orchid;
    &:hover {
      border-color: $orchid;
    }
  }
  :deep(.uppy-DashboardTab-btn) {
    background-color: $white;
    border: 1px solid $ash;
    color: $pewter;
    min-width: 220px;
    width: 80%;
    height: fit-content;
    margin: 8px auto;
    text-align: center;
    font-family: $headings-font-family !important;
    padding: 12px 24px;
    line-height: 22px;
    border-radius: 2px;
    :deep(svg) {
      display: none;
    }
    :deep(.uppy-DashboardTab-name) {
      font-weight: $font-weight-semibold;
      font-size: $font-size-base;
      width: 100%;
    }
  }
}

.close-button {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 1;
  padding: 0;
  background: none;
}

</style>
