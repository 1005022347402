<template>
  <div>
    <div>
      <span
        v-if="showPill"
        class="pickup-pill px-2 me-1"> Pickup @ </span>
      <span
        v-else-if="showLabel"
        class="semi-bold mb-4 pb-3"> Picking up at:</span>
      <div>{{ pickupAddress.name }}</div>
    </div>
    <div>{{ pickupAddress.addressLine1 }} {{ pickupAddress.addressLine2 }}</div>
    <div>{{ pickupAddress.city }}, {{ pickupAddress.state }} {{ pickupAddress.zipcode }}</div>
  </div>
</template>

<script>
export default {
  props: {
    pickupAddress: {
      type: Object,
      required: true
    },
    showPill: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .pickup-pill {
    border-radius: 5px;
    background-color: $champagne;
  }
</style>
