import apiAccount from './apiAccount'
import apiAuth from './apiAuth'
import apiClient from './apiClient'
import apiCloset from './apiCloset'
import apiCollections from './apiCollections'
import apiCommunity from './apiCommunity'
import apiGlobal from './apiGlobal'
import apiItems from './apiItems'
import apiReview from './apiReview'
import apiSearch from './apiSearch'
import apiStyleProfile from './apiStyleProfile'
import apiSubscribe from './apiSubscribe'
import apiNotifications from './apiNotifications'
import apiBrands from './apiBrands'
import apiStyleGames from './apiStyleGames'

// Add to this as our api grows
export default {
  apiAccount,
  apiAuth,
  apiBrands,
  apiClient,
  apiCloset,
  apiCollections,
  apiCommunity,
  apiGlobal,
  apiItems,
  apiReview,
  apiSearch,
  apiStyleProfile,
  apiSubscribe,
  apiNotifications,
  apiStyleGames
}
