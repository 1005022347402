<template>
  <div class="outro-container mt-6">
    <div class="background-highlight mb-2">
      <h4 class="semi-bold mb-0">
        {{ heading }}
      </h4>
    </div>
    <p
      v-if="subheading"
      class="subhead small-text">
      {{ subheading }}
    </p>
    <div class="outro-children">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true
    },
    subheading: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleGamesStyles.scss';

.outro-container {
  display: flex;
  opacity: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  animation: fade-in .45s forwards .3s;
  margin-top: 3rem;
}

.subhead {
  padding: 0 12px;
  max-width: 440px;
  text-align: center;
}

.outro-children {
  opacity: 0;
  animation: fade-in-down .45s forwards 1s;
}

@keyframes fade-in-down {
  0% { opacity: 0; transform: translateY(0); }
  100% { opacity: 1; transform: translateY(4px);}
}
</style>
