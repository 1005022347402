<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { vuexAccessors } from '../../../global/helpers/vuex'

export default {
  data () {
    return {
      isSaving: false,
      saveError: false,
      profilePhotoUploads: []
    }
  },
  computed: {
    ...mapState('community', [
      'editProfileUpdates',
      'editProfileComponent'
    ]),
    ...vuexAccessors('community', [
      'isEditingProfile'
    ]),
    ...mapGetters('community', [
      'getEditProfileHasValidUpdates'
    ]),
    isEditProfileHome () {
      return this.editProfileComponent === 'EditProfile'
    },
    componentDetails () {
      return {
        EditProfile: {
          title: 'Edit Profile',
          componentName: 'EditProfileBody',
          props: {
            saveError: this.saveError,
            disabled: this.isSaving
          },
          handlers: {
            'edit-photo-click': () => { this.setEditProfileComponent('EditProfilePhoto') }
          },
          showConfirm: this.getEditProfileHasValidUpdates || this.isSaving
        },
        EditProfilePhoto: {
          title: 'Edit Profile Photo',
          componentName: 'EditProfilePhoto',
          props: {
            profilePhotoUploads: this.profilePhotoUploads
          },
          handlers: {
            'add-local-upload': (uploadUrl) => { this.addLocalProfilePhotoUpload(uploadUrl) },
            'remove-local-upload': () => { this.removeLocalProfilePhotoUpload() }
          },
          showConfirm: (this.editProfileUpdates.profilePic === null || this.editProfileUpdates.profilePic === '')
            ? this.profilePhotoUploads.length > 0
            : this.editProfileUpdates.profilePic !== this.profilePhotoUploads[0],
          onConfirm: () => {
            this.$store.commit('community/SET_EDIT_PROFILE', { profilePic: this.profilePhotoUploads[0] })
            this.setEditProfileComponent('EditProfile')
          }
        }
      }
    },
    editProfileComponentDetails () {
      return this.componentDetails[this.editProfileComponent]
    }
  },
  mounted () {
    if (!this.isEditingProfile) {
      this.initializeEditProfile()
    }

    if (this.editProfileUpdates.profilePic) {
      this.profilePhotoUploads = [this.editProfileUpdates.profilePic]
    }
  },
  methods: {
    ...mapActions('community', [
      'initializeEditProfile',
      'cleanupEditProfile',
      'updateClientProfile'
    ]),
    setEditProfileComponent (componentName) {
      this.$store.commit('community/SET_EDIT_PROFILE_COMPONENT', componentName)
    },
    async saveProfileUpdates () {
      this.isSaving = true
      this.saveError = false

      try {
        await this.updateClientProfile()
      } catch {
        this.saveError = true
        this.isSaving = false
      }

      if (!this.saveError) {
        this.cleanupEditProfile()
        this.isEditingProfile = false
      }
    },
    addLocalProfilePhotoUpload (uploadUrl) {
      this.profilePhotoUploads.push(uploadUrl)
    },
    removeLocalProfilePhotoUpload () {
      this.profilePhotoUploads.pop()
    }
  }
}
</script>
