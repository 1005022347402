<template>
  <div>
    <div class="pb-5">
      <transition
        name="fade"
        mode="out-in"
        appear
        appear-class="fade-appear-enter-from"
        appear-active-class="fade-appear-enter-active">
        <div
          v-if="styleColor"
          key="style-color">
          <div
            class="detail-page-wrapper">
            <component
              :is="isMobile ? 'closet-detail-mobile' : 'closet-detail'"
              :style-color="styleColor"
              :style-color-source="styleColorSource"
              :style-color-source-index="styleColorSourceIndex"
              :variant="variant"/>
          </div>
          <closet-detail-additional-colors
            v-if="additionalColors.length && !isMobile"
            :class="isMobile ? 'pt-3 px-4 mb-4' : 'mt-4 additional-info-wrapper'"
            :additional-colors="additionalColors"
            :style-color="styleColor"/>
          <div v-if="!isDiscontinued && !isMobile">
            <closet-reviews
              v-if="styleColor.writtenReviews"
              :style-color="styleColor"/>
          </div>
          <closet-detail-callout
            v-if="isDiscontinued || isUnreviewed"
            v-bind="calloutContent"
            class="mb-3"/>
          <div
            v-if="!isMobile"
            class="additional-info-wrapper">
            <div
              v-if="styleColor.similar && styleColor.similar.length"
              class="pe-3">
              <div
                class="similar">
                <h4 class="pb-3 ms-4 ms-md-0">
                  You Might Also Like
                </h4>
                <div>
                  <horizontal-scroll>
                    <style-color-preview
                      v-for="(styleColor, index) in similarStyleColors"
                      :key="styleColor.id"
                      class="me-3"
                      size="large"
                      :class="{ 'ms-4': isMobile && index === 0, 'pe-3': isMobile && index === similarStyleColors.length - 1}"
                      :style-color="styleColorsMap[styleColor]"
                      :style-color-set="{source: `similar-${styleColorId}`}"
                      :style-color-source-index="index"/>
                  </horizontal-scroll>
                </div>
              </div>
              <h4 class="py-3">
                Browse Collections
              </h4>
              <closet-detail-browse-collections
                :style-color="styleColor"/>
            </div>

            <email-capture-footer
              v-if="!loaded"
              class="email-capture-footer"
              source="pdp"/>
          </div>
        </div>
        <div
          v-else
          key="style-color-placeholder">
          <component
            :is="isMobile ? 'closet-detail-placeholder-mobile' : 'closet-detail-placeholder'"/>
          <closet-reviews-placeholder :class="{'additional-info-wrapper': !isMobile }"/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import ActionHeader from '../global/sequin/ActionHeader'
import ClosetReviews from './closetPageDetailReview/ClosetReviews.vue'
import ClosetReviewsPlaceholder from './placeholder/ClosetReviewsPlaceholder'
import ClosetDetail from './ClosetDetail'
import ClosetDetailBrowseCollections from './ClosetDetailBrowseCollections.vue'
import ClosetDetailMobile from './ClosetDetailMobile'
import ClosetDetailPlaceholder from './placeholder/ClosetDetailPlaceholder'
import ClosetDetailPlaceholderMobile from './placeholder/ClosetDetailPlaceholderMobile'
import HorizontalScroll from '../global/sequin/HorizontalScroll'
import StyleColorPreview from '@shared/components/ADORN/StyleColorPreview.vue'
import { track } from '../../plugins/analytics'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'
import ClosetDetailAdditionalColors from './ClosetDetailAdditionalColors'
import ClosetDetailCallout from './ClosetDetailCallout'
import EmailCaptureFooter from '../misc/EmailCaptureFooter'
import SequinPivot from '../global/sequin/SequinPivot.vue'
import ClosetDetailBullets from './ClosetDetailBullets.vue'
import ClosetPhotoScroll from './ClosetPhotoScroll.vue'
import { ClosetMemberPhotoMixin } from './ClosetMemberPhotoMixin'
import AccordionWithHeader from '../global/sequin/AccordionWithHeader.vue'
import ClosetSizingRating from './ClosetSizingRating.vue'
import SequinButton from '../global/sequin/SequinButton.vue'
import ClosetDetailSimilarStyles from './ClosetDetailSimilarStyles.vue'

const variants = {
  default: 'default',
  flyout: 'flyout'
}

export default {
  components: {
    EmailCaptureFooter,
    ClosetDetailAdditionalColors,
    ClosetDetailCallout,
    HorizontalScroll,
    ActionHeader,
    ClosetDetail,
    ClosetDetailBrowseCollections,
    ClosetDetailMobile,
    ClosetDetailPlaceholder,
    ClosetDetailPlaceholderMobile,
    ClosetReviews,
    ClosetReviewsPlaceholder,
    ClosetPhotoScroll,
    StyleColorPreview,
    SequinPivot,
    ClosetDetailBullets,
    AccordionWithHeader,
    ClosetSizingRating,
    SequinButton,
    ClosetDetailSimilarStyles
  },
  mixins: [StyleColorMixin, ClosetMemberPhotoMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: variants.default,
      validator: value => Object.values(variants).includes(value)
    }
  },
  computed: {
    ...mapGetters('closet', [
      'selectedAvailableItemTypes',
      'styleColorSegmentData'
    ]),
    ...mapState('closet', [
      'styleColorsMap',
      'styleColorSources',
      'styleColorSourceIndexes'
    ]),
    ...mapState('client', [
      'loaded',
      'isAuthenticated'
    ]),
    styleColorId () {
      return this.id
    },
    styleColor () {
      if (this.styleColorId in this.styleColorsMap) {
        return this.styleColorsMap[this.styleColorId]
      }
      return null
    },
    styleColorSource () {
      return this.styleColorSources[this.styleColorId] ?? {}
    },
    styleColorSourceIndex () {
      return this.styleColorSource
        ? this.styleColorSourceIndexes[`${this.styleColorId}-${this.styleColorSource.id}`] ?? null
        : null
    },
    style () {
      if (this.styleColor) return this.styleColor.style
      return null
    },
    similarStyleColors () {
      if (this.styleColor && this.styleColor.similar) {
        return this.styleColor.similar
      }
      return []
    },
    calloutContent () {
      if (this.isDiscontinued) {
        return { title: 'This item is no longer available.', subtitle: 'Browse our recommendations for something similar!' }
      } else if (this.isUnreviewed) {
        return { title: 'This style doesn’t have any written reviews yet.', subtitle: 'Be the first one to rent and review it!' }
      } else return null
    }
  },
  watch: {
    styleColorId () {
      this.loadStyleColorData()
    }
  },
  async mounted () {
    await this.loadStyleColorData()

    if (!this.styleColor) {
      this.$log.error('Style color not found', this.styleColorId)
      return
    }

    if (this.variant !== variants.flyout) {
      const metaDescription = `Rent this ${this.styleColor.style.brand} ${this.styleColor.style.name} and thousands of other high-end styles for one flat monthly price!`
      document.querySelector('meta[name="description"]').setAttribute('content', metaDescription)
      const pageTitle = `Armoire | Rent this ${this.styleColor.style.brand} ${this.styleColor.style.name}`
      document.title = pageTitle
    }
  },
  methods: {
    ...mapActions('closet', [
      'getReviews',
      'getStyleColor',
      'getLooksByStyleColor',
      'getSimilarStyleColors',
      'getAdditionalColors'
    ]),
    async loadStyleColorData () {
      if (!(this.styleColorId in this.styleColorsMap)) {
        await this.getStyleColor(this.styleColorId)
      }

      this.getReviews(this.styleColorId)
      this.getLooksByStyleColor(this.styleColorId)
      if (this.loaded && this.isAuthenticated) {
        this.getSimilarStyleColors(this.styleColorId)
        this.getAdditionalColors(this.styleColorId)
      }

      track('Viewed Item Details', {
        styleColor: this.styleColorSegmentData(this.styleColorId),
        source: this.styleColorSource,
        sourceIndex: this.styleColorSourceIndex
      })
    },
    goToSizeChart (sizeGuideUrl) {
      window.open(sizeGuideUrl, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.additional-info-wrapper {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 0 0 71px;
}
.detail-page-wrapper {
  max-width: 1120px;
  margin: 0 auto;
}

.similar {
  padding-top: 32px;
}

.browse-collections {
  margin-left: -1em;
}
:deep(.scroll-button) {
  top: 30%;
}

.email-capture-footer {
  margin-top: 28px;
  position: absolute;
  width: 100%;
  left: 0;
}
:deep(.accordion) {
  > .button {
    padding: 10px 0 0 0;
    .chevron {
      margin-right: 10px;
    }
  }
}
:deep(.accordion-button) {
  div:first-of-type {
    align-items: center;
    justify-content: space-between;
    h5 {
      white-space: nowrap;
      font-family: 'Montserrat', sans-serif;
    }
  }
}
:deep(.expandable-content.expanded) {
  padding: 0;
}
:deep(.tab-slot) {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
:deep(.header-wrapper) {
  width: 100% !important;
  height: 38px;
}
</style>
