<template>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center mb-0">
      <svg-star
        class="d-inline pe-1"
        :class="(styleColor?.numStyleStarRatings) ? 'filled-star': 'unrated-star'"/>
      <h6
        v-if="styleColor?.numStyleStarRatings"
        class="d-inline mb-0 pb-0 pe-2">
        {{ styleColor.avgStyleStarRating }}
      </h6>
      <p class="review-text m-0">
        {{ ratingsText }}
      </p>
    </div>
  </div>
</template>
<script>
import { ReviewMixin } from './ReviewMixin'
import SvgStar from '@/components/global/svg/SvgStar.vue'

export default {
  components: {
    SvgStar
  },
  mixins: [ReviewMixin],
  props: {
    styleColor: {
      type: Object,
      required: true
    }
  },
  computed: {
    ratingsText () {
      const num = this.styleColor?.numStyleStarRatings
      return num ? `out of ${num} Rating${num === 1 ? '' : 's'}` : '(0 Ratings)'
    }
  }
}

</script>
<style lang="scss" scoped>
.review-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .unrated-star {
    fill: rgba($ash, .5);
    stroke-width: 0;
  }
  .filled-star {
    fill: $secondary;
    stroke-width: 0;
  }
</style>
