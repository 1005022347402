<template>
  <div>
    <div
      v-if="unreadNotificationCount === 0 && readNotificationCount === 0"
      class="empty-notifications d-flex justify-content-center align-items-center">
      <div class="text-center">
        <div>
          <SvgBellFilled
            class="empty-bell"/>
        </div>
        <div
          class="small-text mt-3">
          You don't have any notifications.
        </div>
      </div>
    </div>
    <div v-else>
      <NotificationItem
        v-for="notification in visibleNotifications"
        :key="notification.id"
        :content="notification"/>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, computed } from 'vue'
import NotificationItem from './NotificationItem.vue'
import SvgBellFilled from '@/components/global/svg/SvgBellFilled.vue'
import { useStore } from 'vuex'

const store = useStore()
const unreadNotificationCount = computed(() => store.getters['notifications/unreadNotificationCount'])
const readNotificationCount = computed(() => store.getters['notifications/readNotificationCount'])
const visibleNotifications = computed(() => store.getters['notifications/visibleNotifications'])
onBeforeUnmount(() => {
  store.dispatch('notifications/markAllAsViewed')
})
</script>
<style lang="scss" scoped>
.empty-notifications {
  min-height: 450px;
}
.empty-bell {
  height: 60px;
  opacity: 0.25;
  fill: $ash;
}
</style>
