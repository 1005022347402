<template>
  <div class="border-bottom">
    <div
      v-for="(returnPackage, index) in returnPackages"
      :key="returnPackage.trackingNumber"
      class="p-3 border-top border-start border-end"
      :class="{ 'bg-origami': index % 2 === 0 }">
      <div>
        <p class="small-text text-gray">
          Label generated on {{ toMonthDayYearString(returnPackage.objectCreated) }}
        </p>
        <div class="d-flex justify-content-between">
          <return-status :tracking-status="returnPackage.trackingStatus"/>
          <span
            v-if="returnPackage.trackingStatus === 'DELIVERED'"
            class="text-success font-italic small-text text-end">
            Received at our warehouse
            <br>
            on {{ toMonthDayYearString(returnPackage.objectUpdated) }}
          </span>
          <sequin-button
            v-else
            variant="secondary"
            type="a"
            :href="returnPackage.trackingUrlProvider">
            {{ returnPackage.trackingStatus === 'FAILURE' ? 'Details' : 'Track Return' }}
          </sequin-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toMonthDayYearString } from '@/utils/stringParsing.js'
import ReturnStatus from './ReturnStatus'
import SequinButton from '../../global/sequin/SequinButton'

export default {
  name: 'ReturnsListMobile',
  components: { ReturnStatus, SequinButton },
  props: {
    returnPackages: {
      type: Array,
      required: true
    }
  },
  methods: {
    toMonthDayYearString
  }
}
</script>
