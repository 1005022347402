<template>
  <div
    v-if="styleColors.length === 0"
    class="d-flex justify-content-between align-items-center py-3">
    <h5 class="mb-0">
      Tag Styles
    </h5>
    <div class="required">
      Tag at least one Armoire Style
    </div>
  </div>
  <horizontal-scroll
    v-else
    align-items="center"
    hide-desktop-scroll-buttons>
    <h5 class="heading">
      Tagged Styles
    </h5>
    <preview-image
      v-for="item in styleColors"
      :key="item.image.url"
      size="tiny"
      :src="item.image.url"
      class="mx-1"/>
  </horizontal-scroll>
</template>

<script>
import HorizontalScroll from '../../global/sequin/HorizontalScroll.vue'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'

export default {
  components: {
    HorizontalScroll,
    PreviewImage
  },
  props: {
    styleColors: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.required {
  color: $plum;
  font-weight: $font-weight-normal;
}

.heading {
  width: 116px;
  flex-shrink: 0;
  margin: 0 8px 0 0;
}
</style>
