<template>
  <div
    v-if="loaded">
    <div
      v-if="addEditCardMode && inlineEdit">
      <update-default-payment
        v-if="addEditCardMode"
        card-element-id="case-card-element"
        @default-payment-updated="onPaymentUpdated"/>
      <div
        v-if="hasPaymentMethod"
        class="mt-2 text-center">
        <text-link
          @click="addEditCardMode = false">
          Keep existing card
        </text-link>
      </div>
    </div>
    <div
      v-else-if="hasPaymentMethod"
      class="text-start d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <img
          class="me-2"
          :src="`${baseUrl}${defaultPayment.brand.replace(/\s/g,'')}.png`"
          height="30">
        <div class="pt-1">
          <p class="mb-0">
            ending in {{ defaultPayment.last4 }}
            <br>
          </p>
          <div
            v-if="showExpiration"
            class="text-gray fine-print">
            expires {{ defaultPayment.expMonth }}/{{ defaultPayment.expYear }}
          </div>
        </div>
      </div>
      <div>
        <div v-if="showLink">
          <text-link
            @click="addEditCardClick">
            Change
          </text-link>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex align-items-center justify-content-between">
      <div>
        <p class="me-2">
          We'll need your payment information
        </p>
        <p v-if="showLink">
          <text-link
            @click="addEditCardClick">
            Add Card
          </text-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TextLink from '../sequin/TextLink'
import UpdateDefaultPayment from './UpdateDefaultPayment'

export default {
  components: {
    TextLink,
    UpdateDefaultPayment
  },
  props: {
    showLink: {
      type: Boolean,
      default: true
    },
    showExpiration: {
      type: Boolean,
      default: true
    },
    inlineEdit: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_WEBSITE_IMAGES_URL,
      addEditCardMode: false
    }
  },
  computed: {
    ...mapState('client', [
      'id',
      'defaultPayment'
    ]),
    loaded () {
      return this.id !== null
    },
    hasPaymentMethod () {
      return Boolean(this.defaultPayment)
    }
  },
  watch: {
    defaultPayment () {
      this.addEditCardMode = !this.hasPaymentMethod
    }
  },
  methods: {
    addEditCardClick () {
      if (this.inlineEdit) {
        this.addEditCardMode = true
      } else {
        this.$emit('edit-card-click')
      }
    },
    onPaymentUpdated () {
      this.addEditCardMode = false
    }
  }
}
</script>
