<template>
  <div
    class="onboarding-slide d-grid"
    :class="{ ios: showSafariMobileStyles, safari: showSafariStyles }"
    :style="{ zIndex: isActive ? 1 : 0 }">
    <div class="copy-wrapper d-flex flex-column justify-content-center">
      <div class="copy-section d-flex flex-column justify-content-center">
        <Transition
          :name="slide.slideNum === 0 ? 'fade' : 'onboarding'"
          appear
          mode="out-in">
          <div
            v-show="isActive"
            class="text-center"
            v-html="slide.copyHTML"/>
        </Transition>
      </div>

      <div
        v-if="isDesktop"
        v-show="isActive"
        class="d-flex align-items-center flex-column"
        :class="{ hidden: !isActive }">
        <TrackingButton
          v-if="signUpPhase1"
          fixed-width
          text="Get Smart Picks Now"
          app-entry-point="signup"
          :event-label="`Get Smart Picks Now Slide ${ slide.slideNum + 1 }`"
          @click="emit('personalize-now')"/>
        <TrackingButton
          v-else
          fixed-width
          text="Get Smart Picks Now"
          app-entry-point="signup"
          :event-label="`Get Smart Picks Now Slide ${ slide.slideNum + 1 }`"
          :to="nextScreenRouterLink"
          @click="emit('personalize-now')"/>
        <TrackingButton
          v-if="signUpPhase2"
          text="Subscribe now, personalize later"
          variant="text-link"
          app-entry-point="signup"
          :event-label="`Personalize Later ${ slide.slideNum + 1 }`"
          :to="nextScreenRouterLink"
          @click="emit('personalize-later')"/>
      </div>
    </div>
    <div
      class="images-wrapper d-flex flex-column justify-content-center align-items-center"
      :class="{ 'align-items-lg-end align-items-xxl-center': slide.look }">
      <TransitionGroup
        :name="slide.look ? 'onboarding' : 'fade'"
        appear>
        <div
          v-if="isActive && slide.logo"
          key="dress">
          <IconOrigamiDress
            :width="56"
            :height="56"
            color="#520845"
            class="onboarding-logo"/>
        </div>
        <div
          v-if="isActive && slide.look"
          key="image-section"
          class="images-section d-lg-flex flex-row-reverse justify-content-center">
          <img
            :src="slide.look"
            class="look"
            alt="Boss Lady in a stylish look">
          <div
            class="styles-wrapper d-flex n flex-lg-column"
            :class="slide.styles.length < 2 ? 'justify-content-end' : 'justify-content-between justify-content-lg-evenly'">
            <img
              v-for="(image, index) in slide.styles"
              :key="image"
              :src="image"
              class="style"
              :class="`style-${index + 1}`"
              alt="Example article of clothing from Armoire inventory">
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import TrackingButton from '@shared/components/ADORN/TrackingButton.vue'
import { useSignUpExperiments } from '@shared/composables/experiment.js'
import useScreenSize from '@shared/composables/screenSize.js'
import IconOrigamiDress from '@shared/components/icons/IconOrigamiDress.vue'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'

const { signUpPhase1, signUpPhase2 } = useSignUpExperiments()
const { isDesktop } = useScreenSize()
const { nextScreenRouterLink } = storeToRefs(useSignUpNavigationStore())

defineProps({
  slide: {
    type: Object,
    required: true
  },
  isActive: {
    type: Boolean,
    required: true
  }
})
const emit = defineEmits(['personalize-now', 'personalize-later'])

const showSafariMobileStyles = computed(() =>
  navigator.userAgent.includes('iPad') ||
  navigator.userAgent.includes('iPhone') ||
  navigator.userAgent.includes('iPod')
)

const showSafariStyles = computed(() =>
  navigator.userAgent.includes('Safari') &&
  !navigator.userAgent.includes('Chrome') &&
  !showSafariMobileStyles.value
)
</script>

<style lang="scss" scoped>
@import "@/assets/styles/animations.scss";
$negative-xxl: calc($spacing-xxl * -1);
* {
  @include transition-base-all;
}
.onboarding-slide {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100vw;
  display: grid;
  grid-template-rows: auto minmax(0, 100%);
  grid-template-columns: 100%;
  padding: 0 10%;
  @include media-breakpoint-between(md, xxl) {
    padding: 0 5%;
    gap: $spacing-md;
  }
  @include media-desktop {
    height: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: 50% 50%;
  }
}
.copy-wrapper {
  height: fit-content;
  @include media-desktop {
    height: 100%;
  }
}
.copy-section {
  height: 144px;
  margin-top: 24px;
  margin-bottom: $spacing-md;
  @include media-desktop {
    margin-bottom: 124px;
  }
}
.images-section {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 70% minmax(0, 30%);
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  width: fit-content;
  aspect-ratio: 3/5;
  padding-bottom: $spacing-xxl;
  @include media-desktop {
    aspect-ratio: unset;
  }
}

.look {
  width: auto;
  height: auto;
  margin: 0;
  border: 0.5px solid $ash;
  max-width: 100%;
  box-shadow: 0px 1px 8px 0px rgba(23, 23, 35, 0.1);
  margin-bottom: 24px;
  @include media-desktop {
    grid-column: 2;
    margin: 0;
    max-height: 60vh;
  }
}
.styles-wrapper {
  height: 100%;
  margin-left: -18px;
  margin-right: -18px;
  z-index: 2;
  position: relative;
  top: -30% !important;
  @include media-tablet-or-smaller {
    bottom: 0;
    margin-top: 0;
  }
  @include media-desktop {
    grid-column: 1;
    margin-right: $negative-xxl;
    max-width: 25%;
    min-width: 126px;
    padding: $spacing-md;
    margin-top: 0;
    bottom: 0 !important;
    top: unset !important;
  }
  .style {
    height: 100%;
    border: 0.5px solid var(--Ash, #bababf);
    box-shadow: 0px 1px 8px 0px rgba(23, 23, 35, 0.1);
    width: auto;
    @include media-desktop {
      width: 100%;
      height: auto;
      margin: 0;
    }
    &-1 {
      padding-left: -20%;
      @include media-desktop {
        margin-top: $negative-xxl;
      }
    }
    &-2 {
      padding-right: -20%;
      @include media-desktop {
        margin-bottom: $negative-xxl;
      }
    }
  }
}
.onboarding-logo {
  max-width: 100%;
}
.hidden {
  opacity: 0;
}
.images-wrapper {
  height: 100%;
  display: inline-flex;
  @include media-desktop {
    padding-left: 26px;
  }
}

.ios {
  .images-section {
    max-width: unset !important;
    width: unset !important;
  }
  .look {
    margin: 0;
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .styles-wrapper {
    top: unset !important;
    @include media-tablet-or-smaller {
      bottom: 0;
      margin-top: 0;
    }
    @include media-desktop {
      bottom: 0 !important;
    }
  }
}
.safari {
  .images-section {
    padding-bottom: $spacing-xxl;
    max-width: unset !important;
    width: unset !important;
    @media screen and (min-width: 717px) {
      height: 100%;
      max-height: 50dvh;
    }
  }
  .images-wrapper {
    align-items: center !important;
  }
  .styles-wrapper {
    position: relative;
    bottom: -24px !important;
    top: unset !important;
    @include media-tablet-or-smaller {
      bottom: 0;
      margin-top: 0;
    }
    @include media-desktop {
      bottom: 0 !important;
    }
  }
  .look {
    margin: 0;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}
</style>
