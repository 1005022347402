<template>
  <div
    :style="{width: '90%'}"
    class="border-top border-bottom text-center p-4 mt-4 d-flex mx-auto">
    <div class="mx-auto">
      <p class="text-gray mb-0">
        {{ title }}
        <span
          :style="{fontWeight: 700}">{{ subtitle }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClosetDetailCallout',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  }
}
</script>
