<template>
  <div>
    <p class="mt-2 small-text">
      All transactions are secure and encrypted.
    </p>
    <div class="mb-3">
      <div
        class="bg-white mt-4"
        :class="{ 'disabled': formDisabled}">
        <div class="card-wrapper">
          <div
            id="card-element"
            class="sentry-mask"/>
        </div>
        <div
          id="card-errors"
          class="text-danger mt-2"
          role="alert"/>
        <div
          v-if="stripeErrorMessage"
          class="text-danger small">
          <p class="text-danger">
            {{ stripeErrorMessage }}
            <br><span v-html="stripeErrorFollowup"/>
          </p>
        </div>
      </div>
    </div>
    <div class="d-flex mt-4">
      <base-checkbox
        v-if="shippingAddress"
        v-model="billingShippingSame"
        :input-value="1"
        class="mb-3 billing-checkbox"
        text="My billing and shipping address are the same"
        @input="setBillingAddress"/>
    </div>
    <div
      v-if="!billingShippingSame">
      <h4 class="my-2">
        Billing Address
      </h4>
      <div class="my-sm">
        <base-input
          v-model="addressLine1"
          :validations="v$.addressLine1"
          autocomplete="billing address-line1"
          label="Address"
          name="addressLine1"/>
      </div>
      <div class="my-sm">
        <base-input
          v-model="addressLine2"
          autocomplete="billing address-line2"
          helper-text="Apartment, Suite, etc. (optional)"/>
      </div>
      <div class="my-sm">
        <base-input
          v-model="city"
          :validations="v$.city"
          autocomplete="billing locality"
          label="City"/>
      </div>
      <div class="row my-sm">
        <div class="col">
          <base-input
            v-model="state"
            :validations="v$.state"
            autocomplete="billing region"
            label="State"/>
        </div>
        <div class="col">
          <base-input
            v-model="zipcode"
            type="number"
            :validations="v$.zipcode"
            :cleave-options="{
              numericOnly: true,
              blocks: [5]}"
            autocomplete="billing postal-code"
            label="ZIP code"/>
        </div>
      </div>
    </div>

    <div
      v-if="v$.$error"
      class="text-danger">
      This field is required.
    </div>

    <div
      class="d-flex"
      :class="{ 'flex-column-reverse': isMobile }">
      <sequin-button
        v-if="stripeToken || canShowPaymentRequest"
        class="mt-2 me-4"
        variant="secondary"
        block
        @click="$emit('exit-click')">
        Cancel
      </sequin-button>
      <sequin-button
        class="mt-2"
        block
        @click="submitPaymentInformation">
        Save Payment Method
      </sequin-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { stateAbbr, zipCode } from '../global/vuelidate/customValidators'
import { PaymentMixin } from '../global/mixins/PaymentMixin'
import SequinButton from '../global/sequin/SequinButton'
import BaseCheckbox from '@/components/global/BaseCheckbox.vue'
import BaseInput from '@/components/global/BaseInput.vue'

export default {
  components: {
    SequinButton,
    BaseCheckbox,
    BaseInput
  },
  mixins: [PaymentMixin],
  props: {
    stripe: {
      type: Object,
      required: true
    },
    card: {
      type: Object,
      default: () => null
    },
    canShowPaymentRequest: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      billingAddress: '',
      billingShippingSame: true,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipcode: '',
      stripeErrorMessage: null,
      stripeErrorFollowup: null,
      formDisabled: false
    }
  },
  computed: {
    ...mapState('client', [
      'shippingAddress'
    ]),
    ...mapState('subscribe', [
      'stripeToken'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected'
    ])
  },
  async mounted () {
    this.$emit('edit-payment-mounted')
    if (!this.shippingAddress) this.billingShippingSame = false
    this.setBillingAddress()
  },
  methods: {
    async submitPaymentInformation () {
      this.v$.$touch()
      if (this.v$.$invalid) return

      this.formDisabled = true
      const billingAddress = {
        address_line1: this.addressLine1,
        address_line2: this.addressLine2,
        address_city: this.city,
        address_state: this.state.toUpperCase(),
        address_zip: this.zipcode,
        address_country: 'USA'
      }

      const res = await this.stripe.createToken(this.card, billingAddress)
      if (res.error) {
        // Inform the user if there was an error.
        const errorElement = document.getElementById('card-errors')
        errorElement.textContent = res.error.message
      } else {
        this.$emit('token-updated', res.token)
      }

      this.formDisabled = false
    },
    setBillingAddress () {
      this.v$.$reset()
      const attrs = [
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'zipcode']
      if (this.billingShippingSame) {
        for (const attr of attrs) this[attr] = this.shippingAddress[attr]
      } else {
        for (const attr of attrs) this[attr] = ''
      }
    }

  },
  validations: {
    addressLine1: {
      required
    },
    city: {
      required
    },
    state: {
      required,
      stateAbbr
    },
    zipcode: {
      required,
      zipCode
    }
  }
}
</script>

<style lang="scss" scoped>
  .disabled {
    pointer-events: none;
    opacity: .5;
  }
  .card-wrapper {
    border: $default-border;
    border-radius: 4px;
  }

  .billing-checkbox {
    :global(.checkbox-text) {
      margin-left: 20px;
    }
  }
</style>
