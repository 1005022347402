<template>
  <span>{{ getReferralCreditMsg }}</span>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatPrice } from '@/utils/stringParsing.js'

export default {
  name: 'ReferralCreditMsg',
  computed: {
    ...mapState('subscribe', [
      'referralCampaign'
    ]),
    ...mapGetters('subscribe', [
      'bestReferralPlan',
      'refereeDiscount',
      'refereeBonusItems'
    ]),
    getReferralCreditMsg () {
      const { referrerCredit } = this.referralCampaign || {}
      return `They get up to ${this.refereeDiscount}% off${this.refereeBonusItems > 0 ? ` plus ${this.refereeBonusItems} free bonus item${this.refereeBonusItems > 1 ? 's' : ''}` : ''}, you'll get ${formatPrice(referrerCredit)} credit.`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
