<template>
  <BaseLink
    ref="link"
    @click="onClick">
    <slot/>
  </BaseLink>
</template>

<script setup>
import { ref } from 'vue'
import BaseLink from '@shared/components/BaseLink.vue'
import { useTrackingLinkClick } from '@shared/composables/link.js'

const props = defineProps({
  appEntryPoint: {
    type: String,
    required: true,
    validator: value => ['homepage', 'website', 'signup'].includes(value)
  },
  eventLabel: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})

const link = ref(null)
const onClick = useTrackingLinkClick(
  () => props.appEntryPoint,
  () => props.eventLabel,
  emit,
  () => link.value.link
)
</script>
