<template>
  <image-uploader
    v-bind="listeners"
    :image-upload-url-function="profileImageUploadUrlFunc"
    :local-images="profilePhotoUploads"/>
</template>

<script>
import { mapState } from 'vuex'
import ImageUploader from '../../../global/sequin/ImageUploader'
import { getListeners } from '../../../global/helpers/vue'

export default {
  compatConfig: {
    INSTANCE_LISTENERS: false // $listeners are now apart of $attrs in Vue 3
  },
  components: {
    ImageUploader
  },
  props: {
    profilePhotoUploads: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('community', [
      'profileImageUploadUrlFunc'
    ]),
    listeners () {
      return getListeners(this.$attrs)
    }
  }
}
</script>

<style lang="scss" scoped>
.add-photo {
  height: 100vh;
}
</style>
