<template>
  <BaseButton
    class="filter-sort-button"
    variant="filter-sort"
    text="Filter and Sort"
    @click="emit('click', event)">
    <template #icon>
      <SvgFilters
        class="filter-icon sliders"
        height="18"
        width="18"/>
    </template>
  </BaseButton>
</template>
<script setup>
import BaseButton from '@shared/components/ADORN/BaseButton'
import SvgFilters from '@/components/global/svg/SvgFilters.vue'

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})
</script>
