import axios from './config'

const apiAccount = {
  addAddress (data) {
    return axios.post('/api/locations/', data)
  },
  updateAddress (data) {
    return axios.put('/api/locations/' + data.id + '/', data)
  },
  deleteAddress (id) {
    return axios.delete('/api/locations/' + id + '/')
  },
  validatePassword (data) {
    return axios.post('/api/validate_password/', data)
  },
  getShippingAddresses (username) {
    return axios.get('/api/locations/?client=' + username)
  },
  getPickupLocations () {
    return axios.get('/api/pickuplocations/')
  },
  getItemPromotions () {
    return axios.get('/api/itempromotions/')
  },
  getGiftCardTransactions () {
    return axios.get('/api/giftcardtransactions/')
  },
  activateGiftCard (code) {
    return axios.post('/api/giftcards/?activate=' + code)
  },
  kioskCheckin (kioskId) {
    return axios.post('/api/kiosks/' + kioskId + '/check_in/')
  },
  deleteClient (username) {
    return axios.delete('/api/clients/' + username + '/')
  }
}

export default apiAccount
