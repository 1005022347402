import { computed } from 'vue'
import { useStore } from 'vuex'

// Temporary helper composable to interface with Vuex (ENG-2862)
export default function useVuexModule (moduleName) {
  const store = useStore()

  const state = Object.keys(store.state[moduleName] ?? []).reduce((acc, key) => {
    acc[key] = computed(() => store.state[moduleName][key])
    return acc
  }, {})

  const prefix = `${moduleName}/`
  const getNewKey = (key) => key.replace(prefix, '')

  const getters = Object.keys(store.getters).reduce((acc, key) => {
    if (key.startsWith(prefix)) {
      acc[getNewKey(key)] = computed(() => store.getters[key])
    }
    return acc
  }, {})

  async function dispatch (action, payload) {
    if (store.hasModule(moduleName)) {
      await store.dispatch(`${moduleName}/${action}`, payload)
    }
  }

  const actions = Object.keys(store._actions).reduce((acc, key) => {
    if (key.startsWith(prefix)) {
      const action = getNewKey(key)
      acc[action] = async (payload) => await dispatch(action, payload)
    }
    return acc
  }, {})

  function commit (mutation, payload) {
    if (store.hasModule(moduleName)) {
      store.commit(`${moduleName}/${mutation}`, payload)
    }
  }

  const mutations = Object.keys(store._mutations).reduce((acc, key) => {
    if (key.startsWith(prefix)) {
      const mutation = getNewKey(key)
      acc[mutation] = (payload) => commit(mutation, payload)
    }
    return acc
  }, {})

  /** No-op function for intentionally unimplemented actions. */
  function doNothing () {}

  // Check for duplicate keys.
  if (process.env.NODE_ENV !== 'production') {
    const keys = {}
    const objs = { state, getters, actions, mutations }
    Object.keys(objs).forEach((objKey) => {
      Object.keys(objs[objKey]).forEach((key) => {
        if (!keys[key]) {
          keys[key] = [objKey]
        } else {
          keys[key].push(objKey)
        }
      })
    })
    const duplicates = Object.keys(keys).reduce((acc, key) => {
      if (keys[key].length > 1) {
        acc[key] = keys[key].join(', ')
      }
      return acc
    }, {})
    if (Object.keys(duplicates).length) {
      // eslint-disable-next-line no-console
      console.warn(`Duplicate keys found in ${moduleName} vuexModule:`, duplicates)
    }
  }

  return {
    ...state,
    ...getters,
    ...actions,
    ...mutations,
    dispatch,
    commit,
    doNothing
  }
}
