<template>
  <div>
    <closet-reviews-summary
      v-model="filters"
      :style-color="styleColor"/>
    <div
      v-if="reviewsLoading"
      class="review-placeholder"/>
    <div
      class="reviews-container"
      :class="{'reviews-container-desktop' : !isMobile}">
      <div v-if="filteredReviews">
        <closet-review
          v-for="(review, index) in filteredReviews"
          :key="'review-' + index"
          :review="review"
          :photo-album-entries="getMemberPhotoAlbumEntriesForReview(review.id)"
          :style-color-id="styleColor.id"/>
      </div>
      <div v-if="sortedReviews">
        <closet-review
          v-for="(review, index) in sortedReviews"
          :key="'review-' + index"
          :review="review"
          :photo-album-entries="getMemberPhotoAlbumEntriesForReview(review.id)"
          :style-color-id="styleColor.id"/>
      </div>
      <div v-else-if="unfilteredReviews">
        <closet-review
          v-for="(review, index) in pinnedReviews"
          :key="'pinned-review-' + index"
          :review="review"
          :photo-album-entries="getMemberPhotoAlbumEntriesForReview(review.id)"
          :style-color-id="styleColor.id"/>
        <closet-review
          v-for="(review, index) in unfilteredReviews"
          :key="'review-' + index"
          :review="review"
          :photo-album-entries="getMemberPhotoAlbumEntriesForReview(review.id)"
          :style-color-id="styleColor.id"/>
      </div>
      <div
        v-if="showMoreReviewsVisible"
        class="p-4 border-bottom">
        <plain-button
          type="a"
          @click="showAllReviews = !showAllReviews">
          <div v-if="!showAllReviews">
            Show {{ writtenReviewsCount }} more reviews
          </div>
          <div v-else>
            Hide reviews
          </div>
        </plain-button>
      </div>
    </div>
  </div>
</template>

<script>
import ClosetReviewsSummary from './ClosetReviewsSummary'
import ClosetReview from './review/ClosetReview'
import PlainButton from '../../global/sequin/PlainButton.vue'

import { ClosetMemberPhotoMixin } from '../ClosetMemberPhotoMixin'
import { ReviewMixin } from '../ReviewMixin'

export default {
  components: {
    ClosetReviewsSummary,
    ClosetReview,
    PlainButton
  },
  mixins: [ReviewMixin, ClosetMemberPhotoMixin],
  props: {
    styleColor: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      reviewsLimit: 5,
      showAllReviews: false,
      reviewsLoading: false,
      filters: []
    }
  },
  computed: {
    pinnedReviews () {
      return this.styleColor?.expertReviews?.concat(this.styleColor?.clientReviews)
    },
    filteredReviews () {
      return this.styleColor?.filteredReviews
    },
    sortedReviews () {
      return this.styleColor?.sortedReviews?.results
    },
    writtenReviewsCount () {
      return this.styleColor?.writtenReviews?.count
    },
    showMoreReviewsVisible () {
      return (this.writtenReviewsCount > this.reviewsLimit) && !this.filteredReviews
    },
    writtenReviews () {
      const reviews = this.styleColor?.writtenReviews?.results
      if (reviews) {
        if (reviews.length < this.reviewsLimit || this.showAllReviews) {
          return reviews
        } else {
          return reviews.slice(0, this.reviewsLimit)
        }
      } else {
        return []
      }
    },
    unfilteredReviews () {
      const reviews = this.styleColor?.unfilteredReviews?.results
      const reviewsLimit = this.reviewsLimit - this.pinnedReviews.length ? this.pinnedReviews.length : 0

      if (reviews) {
        if (reviews.length < this.reviewsLimit || this.showAllReviews) {
          return reviews
        } else {
          return reviews.slice(0, reviewsLimit)
        }
      } else {
        return []
      }
    },
    allReviews () {
      return { ...this.writtenReviews, ...this.sortedReviews, ...this.filteredReviews }
    }
  },
  watch: {
    allReviews: {
      handler (newValue) {
        if (newValue) {
          this.reviewsLoading = true
          setTimeout(() => {
            this.reviewsLoading = false
          }, 500)
        }
      },
      deep: true
    },
    sortedReviews (newValue) {
      if (newValue) {
        this.filters = []
      }
    }
  },
  methods: {
    getMemberPhotoAlbumEntriesForReview (reviewId) {
      return this.memberPhotoAlbumEntries.filter(entry => entry.reviewId === reviewId)
    }
  }
}
</script>

<style lang="scss" scoped>
.reviews-container {
  max-width: 90%;
  margin: 0 auto;
}
.reviews-container-desktop {
  max-width: 1120px;
  padding-left: 84px;
  padding-right: 40px;
  margin: 0 auto;
}
.review-placeholder {
  width: 100%;
  height: 100%;
  background: $origami;
  @include placeholder-loading-animation;
}
</style>
