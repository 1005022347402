<!-- Profile image for the logged in client -->
<template>
  <AvatarImage
    v-if="hasProfilePic"
    size="medium"
    :profile-pic="profileImageUrl"
    :client-id="clientId"/>
  <SvgCircleIcon
    v-else
    :width="46"
    :height="46"
    variant="dark"
    class="mx-auto">
    <SvgProfile/>
  </SvgCircleIcon>
</template>

<script setup>
import { computed } from 'vue'
import useClient from '@/composables/client.js'
import { useImage } from '@vueuse/core'
import AvatarImage from '@/components/community/common/AvatarImage.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgProfile from '@/components/global/svg/SvgProfile.vue'

const {
  clientId,
  profileImageUrl,
  hasCustomProfileImage
} = useClient()

const { isReady, error } = useImage(() => ({ src: profileImageUrl.value }))
const hasProfilePic = computed(() => hasCustomProfileImage.value && isReady.value && !error.value)
</script>
