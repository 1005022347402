<template>
  <span
    class="tag"
    :class="[variant, {'light': light, 'light-solid': lightSolid} ]">
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'SequinTag',
  props: {
    // The styling variant
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/primary|secondary|tertiary|info|success|danger|gray|darkGray|/)
      }
    },
    // Styles with a light background and dark text version of the variant
    light: {
      type: Boolean,
      default: false
    },
    lightSolid: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  text-transform: uppercase;
  border-radius: 2px;
  padding: 0 8px;
  display: inline-block;
  height: fit-content;
  font-size: 10px;
  font-weight: bold;
  color: $white;

  white-space: nowrap;

  &.primary {
    background-color: $orchid;

    &.light {
      background: rgba($orchid, .1);
      color: $orchid;
    }
  }

  &.secondary {
    background-color: $secondary;

    &.light {
      background: rgba($secondary, .07);
      color: $secondary;
    }
  }

  &.tertiary {
    background-color: $tertiary;

    &.light {
      background: rgba($tertiary, .1);
      color: $tertiary;
    }
  }

  &.info {
    background-color: $info-dark;

    &.light {
      background: rgba($info-light, .1);
      color: $info-dark;
    }
    &.light-solid {
      background: rgb(235, 248, 253);
      color: $info-dark;
    }
  }

  &.success {
    background-color: lighten($success-dark, 3%);

    &.light {
      background-color: rgba($success-dark, .6);
      color: $white;
    }
  }

  &.danger {
    background-color: $danger-dark;

    &.light {
      background: rgba($danger-light, .1);
      color: $danger-dark;
    }
  }

  &.gray {
    background-color: $origami;
    color: $pewter;
    text-transform: inherit;
  }

  &.darkGray {
    background-color: $pewter;
    color: $white;
  }

}

.tag-top {
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translateX(-50%);
}
</style>
