<template>
  <div class="d-flex">
    <avatar-image
      :client-id="id"
      :class="{ 'disabled' : disabled}"
      size="small"
      :profile-pic="profilePic"/>
    <textarea
      ref="commentTextArea"
      v-model="comment"
      rows="1"
      :disabled="disabled"
      class="comment-input"
      :class="{'mobile': isMobile}"
      placeholder="Leave a comment"
      @input="update()"/>
  </div>
</template>

<script>
import AvatarImage from '../../common/AvatarImage'
import { mapState } from 'vuex'

export default {
  components: {
    AvatarImage
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    initialComment: {
      type: String,
      default: ''
    },
    focusOnMount: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      comment: ''
    }
  },
  computed: {
    ...mapState('client', [
      'profilePic',
      'id'
    ])
  },
  mounted () {
    this.comment = this.initialComment
    if (this.focusOnMount) {
      this.$refs.commentTextArea.focus()
    }
    this.$nextTick(() => { this.resize() })
  },
  methods: {
    update () {
      this.resize()
      this.$emit('comment-update', this.comment)
    },
    reset () {
      this.comment = ''
      this.$emit('comment-update', this.comment)
      this.$refs.commentTextArea.style.height = 'auto'
      this.$emit('comment-height-change')
    },
    resize () {
      const oldHeight = this.$refs.commentTextArea.style.height
      const newHeight = this.$refs.commentTextArea.scrollHeight + 'px'
      this.$refs.commentTextArea.style.height = 'auto'
      this.$refs.commentTextArea.style.height = `${this.$refs.commentTextArea.scrollHeight}px`

      if (oldHeight !== newHeight) {
        this.$emit('comment-height-change')
      }
    },
    focus () {
      this.$refs.commentTextArea.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  opacity: .6;
}

.comment-input {
  background-color: white;
  border: $default-border;
  text-overflow: wrap;
  border-radius: 24px;
  font-size: $small-text-font-size;
  padding: 9px 12px;
  margin-left: 4px;
  line-height: 18px;
  width: 100%;
  height: 40px;
  outline: none;
  resize: none;
  overflow: hidden;
  -webkit-appearance: none;

  &.mobile {
    font-size: 1rem; // safari will zoom in if this is not 16px
  }

  &:focus {
    outline: none;
    border: 1px solid $pewter;
  }

  &:disabled {
    color: $ash;

    &::-webkit-input-placeholder {
      color: rgba($ash, .6);
    }
  }
}
</style>
