<template>
  <community-nav-wrapper>
    <div
      class="fake-feed"
      :class="{ 'desktop' : !isMobile }">
      <div
        class="selector"
        :class="{ 'mobile' : isMobile }">
        <feed-item-header
          class="header"
          :feed-item="fakeFeedItem"/>
        <feed-item-image
          class="fake-image"
          :class="{ 'mobile' : isMobile }"
          :feed-item-id="fakeFeedItem.id"
          :image-source-url="fakeFeedItem.image"/>
      </div>
      <div class="cta">
        <h4>Join Our Community</h4>
        <p class="mb-4">
          As a subscribed member, you'll gain access to our members-only feed, where you can share the creative ways you wear your Armoire styles and browse looks from other members
        </p>
        <sequin-button
          type="router-link"
          :to="{ name: ctaDestination }">
          {{ ctaText }}
        </sequin-button>
      </div>
    </div>
  </community-nav-wrapper>
</template>

<script>
import CommunityNavWrapper from '../common/CommunityNavWrapper'
import FeedItemHeader from './FeedItemHeader'
import FeedItemImage from './FeedItemImage'
import SequinButton from '../../global/sequin/SequinButton'
import { mapState } from 'vuex'

export default {
  components: {
    CommunityNavWrapper,
    FeedItemHeader,
    FeedItemImage,
    SequinButton
  },
  data () {
    return {
      fakeFeedItem: {
        id: '123456',
        client: {
          firstName: 'Kim',
          lastName: 'Mitchell',
          id: '123456'
        },
        image: `${process.env.VUE_APP_WEBSITE_IMAGES_URL}kim-mitchell-example-post.jpg`,
        item: {
          type: 'look',
          id: '123456'
        },
        tags: [{
          tag: 'Weekend Chill'
        }]
      }
    }
  },
  computed: {
    ...mapState('client', [
      'id',
      'membershipStatus'
    ]),
    ctaDestination () {
      if (!this.id) {
        return 'style-quiz-intro'
      }
      return 'select-plan'
    },
    ctaText () {
      return `${this.membershipStatus === 'former_member' ? 'Reactivate' : 'Complete'} your membership`
    }
  }
}
</script>

<style lang="scss" scoped>
$imageHeight: 42vh;
$headerHeight: 92px;
$imageHeightMobile: 35vh;

.fake-feed {
  background: $white;

  &.desktop {
    border: $default-border;
  }

  .header {
    padding: 20px 28px;
  }

  .fake-image {
    height: $imageHeight;
    overflow: hidden;

    &.mobile {
      height: $imageHeightMobile;
    }
  }
}

.selector::after {
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, transparent 0%, white 100%);
  margin-top: calc(-#{$imageHeight});
  height: calc(#{$imageHeight} + 1px);
  width: 100%;
  content: '';
}

.cta {
  padding: 28px;
  margin-top: -5vh;
  position: relative;
}
</style>
