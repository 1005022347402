import { helpers } from '@vuelidate/validators'
import { states } from '../data/states'

export function birthYear (val) {
  if (val !== '') {
    const currentYear = (new Date()).getFullYear()
    const birthYear = val.split('/')[2]
    return currentYear - 130 <= birthYear && birthYear <= currentYear - 10
  }
  return true
}

export const checkboxMinChecked = (param) =>
  helpers.withParams(
    { num: param },
    (val) => {
      return val.length >= param
    })

export const minSelected = (param) =>
  helpers.withParams(
    { num: param },
    (val) => {
      return val.length >= param
    })

export function dDay (val) {
  if (val) {
    const currentYear = (new Date()).getFullYear()
    const year = val.split('/')[2]
    return currentYear - 10 <= year && year <= currentYear + 3
  }
  return true
}

// Area codes in the US cannot start with 1, they are invalid.
export function phone (val) {
  return /^[2-9][0-9]{2}-[2-9][0-9]{2}-[0-9]{4}$/.test(val)
}

export function stateAbbr (val) {
  if (val !== '') {
    const state = val.trim().toUpperCase()
    return states.includes(state)
  }
  return true
}

export function zipCode (val) {
  return /^\d{5}$/.test(val)
}

export const validReferralCode = helpers.regex(/^[A-Za-z0-9_-]*$/)

export function futureDate (val) {
  if (val !== '') {
    return new Date() < new Date(val)
  }
  return val !== null
}

export function date (val) {
  const dateFormat = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/
  const enteredDate = new Date(val)
  return dateFormat.test(val) && !isNaN(enteredDate)
}
