<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Add</title>
    <path
      d="M16.8713 24H7.28713C3.24752 24 0 20.7525 0 16.7129V7.20792C0 3.24752 3.24752 0 7.28713 0H16.8713C20.9109 0 24.1584 3.24752 24.1584 7.28713V16.7921C24.1584 20.7525 20.8317 24 16.8713 24ZM7.28713 1.58416C4.11881 1.58416 1.58416 4.11881 1.58416 7.28713V16.7921C1.58416 19.9604 4.11881 22.495 7.28713 22.495H16.8713C20.0396 22.495 22.5743 19.9604 22.5743 16.7921V7.28713C22.5743 4.11881 20.0396 1.58416 16.8713 1.58416H7.28713Z"
      fill="currentColor"/>
    <path
      d="M17.2674 11.0099H13.0693V6.81189H11.0891V11.0099H6.89111V12.9901H11.0891V17.1881H13.0693V12.9901H17.2674V11.0099Z"
      fill="currentColor"/>
  </svg>
</template>
