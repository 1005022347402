import { computed, toValue } from 'vue'
import { useClosetStore } from '@shared/stores/closet.js'

export default function useStyleColor (styleColor) {
  const closetStore = useClosetStore()

  const id = computed(() => toValue(styleColor).id)
  const style = computed(() => toValue(styleColor).style)
  const brand = computed(() => style.value.brand)
  const name = computed(() => style.value.name)
  const frontImage = computed(() => toValue(styleColor).images.front)
  const previewImage = computed(() =>
    frontImage.value
      ? {
          ...frontImage.value,
          url: frontImage.value.url.replace('_d', '_m')
        }
      : null
  )
  const backImage = computed(() => toValue(styleColor).images.back)
  const discontinued = computed(() => toValue(styleColor).discontinued ?? false)
  const itemTypes = computed(() => toValue(styleColor).itemTypes)
  const unavailable = computed(() => !itemTypes.value.some(itemType => [true, undefined].includes(itemType.isMemberSize) && itemType.numAvailable > 0))
  const stocking = computed(() => itemTypes.value.some(itemType => itemType.stocking))
  const retailPrice = computed(() => Math.round(style.value.retailPrice))
  const minPrice = computed(() => toValue(styleColor).priceRange?.minPrice)
  const maxPrice = computed(() => toValue(styleColor).priceRange?.maxPrice)
  const hasPriceRange = computed(() => !!minPrice.value && !!maxPrice.value && minPrice.value !== maxPrice.value)
  const latestReturnDate = computed(() => toValue(styleColor).latestReturnDate ? new Date(toValue(styleColor).latestReturnDate) : null)
  const firstAvailableDate = computed(() => toValue(styleColor).firstAvailable ? new Date(toValue(styleColor).firstAvailable) : null)

  async function recordClick (source, sourceIndex) {
    await closetStore.recordClick({ styleColorId: toValue(styleColor).id, source, sourceIndex })
  }

  return {
    id,
    style,
    brand,
    name,
    frontImage,
    previewImage,
    backImage,
    discontinued,
    itemTypes,
    unavailable,
    stocking,
    retailPrice,
    minPrice,
    maxPrice,
    hasPriceRange,
    latestReturnDate,
    firstAvailableDate,
    recordClick
  }
}
