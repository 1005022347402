<template>
  <div
    v-if="styleColorCollectionSuggestions && styleColorCollectionSuggestions.length">
    <horizontal-scroll>
      <collection-preview
        v-for="(collection, index) in styleColorCollectionSuggestions"
        :key="collection.id"
        class="browse-collections-preview me-4"
        :class="{ 'ms-4': isMobile && index === 0}"
        :analytics-source="{ type: 'pdp-browse-collections', styleColorId: styleColor.id }"
        :collection="{
          ...collection,
          styleColors: styleColorsFromCollectionId(collection.id),
          toRoute: { name: 'collection', params: { id: collection.id } },
          length: `${styleColorCountFromCollectionId(collection.id)} style${styleColorCountFromCollectionId(collection.id) === 1 ? '' : 's'}`
        }"/>
    </horizontal-scroll>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import CollectionPreview from '../collections/CollectionPreview.vue'
import HorizontalScroll from '../global/sequin/HorizontalScroll.vue'

export default {
  components: {
    CollectionPreview,
    HorizontalScroll
  },
  props: {
    styleColor: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...mapState('collections', [
      'collectionsById',
      'collectionIdsByStyleColorId'
    ]),
    ...mapGetters('collections', [
      'styleColorsFromCollectionId',
      'styleColorCountFromCollectionId'
    ]),
    styleColorCollectionIds () {
      return this.collectionIdsByStyleColorId[this.styleColor.id]
    },
    styleColorCollectionSuggestions () {
      return this.styleColorCollectionIds
        ? this.styleColorCollectionIds.reduce((prev, curr) => {
          if (prev.length < 5) {
            const collection = this.collectionsById[curr]
            if (collection.client.id !== this.id) {
              return prev.concat(collection)
            }
          }
          return prev
        }, [])
        : []
    }
  },
  watch: {
    styleColor () {
      this.getCollections({ styleColorId: this.styleColor.id })
    }
  },
  mounted () {
    this.getCollections({ styleColorId: this.styleColor.id })
  },
  methods: {
    ...mapActions('collections', [
      'getCollections'
    ])
  }
}
</script>

<style lang="scss" scoped>
.browse-collections-preview {
  width: 260px;
  height: 288px;
  flex-shrink: 0;

  &.mobile {
    width: 220px;
    height: 244px;
    flex-shrink: 0;
  }
}
</style>
