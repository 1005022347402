<template>
  <div
    :style="cssVars"
    :class="[alignment]"
    class="content-stack">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    alignment: {
      type: String,
      default: 'center',
      validator: v => v.match(/(center|left|right)/)
    },
    spacing: {
      type: Number,
      default: 16
    }
  },
  computed: {
    cssVars () {
      return {
        '--vertical-margin': `${this.spacing}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-stack {
  display: flex;
  flex-direction: column;

  &.column-reverse {
    flex-direction: column-reverse;
  }

  &.center {
    align-items: center;
  }

  &.left {
    align-items: flex-start;
  }

  &.right {
    align-items: flex-end;
  }
}

.content-stack > * {
  margin-top: var(--vertical-margin);
  margin-bottom: var(--vertical-margin);
}
</style>
