<template>
  <div
    ref="self"
    class="image-container"
    :class="{ 'desktop' : !isMobile}"
    @click="$emit('click')">
    <img
      v-if="inViewport.now"
      class="feed-image"
      :alt="alt"
      :src="imageSourceUrl"
      @load="onLoad">
  </div>
</template>

<script>
import inViewport from 'vue-in-viewport-mixin'

export default {
  name: 'FeedItemImage',
  mixins: [inViewport],
  props: {
    imageSourceUrl: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: "User's Look"
    }
  }
}
</script>

<style lang="scss" scoped>
.image-container {
  width: 100%;
  background: $origami;

  .feed-image {
    width: 100%;
    object-fit: contain;
  }
}
</style>
