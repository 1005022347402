<template>
  <app-message
    v-if="!closed"
    variant="info"
    has-close-button
    @close-clicked="closed = true">
    <div>
      <h5>Membership Paused</h5>
      <p class="mb-0">
        As a paused member, you can continue to browse community looks, but you won’t be able to share new looks, like, or comment.
      </p>
    </div>
  </app-message>
</template>

<script>
import AppMessage from '../../global/sequin/AppMessage.vue'

export default {
  components: {
    AppMessage
  },
  data () {
    return {
      closed: false
    }
  }
}
</script>
