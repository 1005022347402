<template>
  <div
    v-click-outside="clearAlert"
    :class="'alert sticky-top mt-2 mx-auto animated ' + alertStyle"
    role="alert">
    <svg-check
      v-if="alertStyle === 'alert-success'"
      height="25"
      width="25"/>
    {{ alertText }}
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    SvgCheck
  },
  computed: {
    ...mapState('global', [
      'alertStyle',
      'alertText'
    ])
  },
  methods: {
    ...mapActions('global', [
      'clearAlert'
    ])
  }
}
</script>

<style lang="scss" scoped>
.sticky-top { top: 0.5em; }
</style>
