<!-- A small button with a chevron icon -->
<template>
  <BaseButton
    :class="['scroll-button', `scroll-button-${direction}`]"
    :style="{ '--button-size': size }"
    @click="emit('click', event)">
    <template #icon>
      <SvgChevronRight/>
    </template>
  </BaseButton>
</template>

<script setup>
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'
const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})
defineProps({
  size: {
    type: String,
    default: '32px'
  },
  direction: {
    type: String,
    required: true,
    validator: (value) => ['up', 'down', 'left', 'right'].includes(value)
  }
})
</script>

<style lang="scss" scoped>
.scroll-button {
  width: var(--button-size) !important;
  height: var(--button-size) !important;
  border: 1px solid $ash !important;
  color: $pewter !important;
  background-color: $white !important;
  padding: 4px !important;

  &-up :deep(svg) {
    transform: rotate(270deg);
  }
  &-down :deep(svg) {
    transform: rotate(90deg);
  }
  &-left :deep(svg) {
    transform: rotate(180deg);
  }
}
</style>
