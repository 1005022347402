<template>
  <!-- Custom Radio -->
  <div v-if="custom">
    <label
      class="base-radio custom base-custom-radio">
      <slot
        name="customOption"
        :active="active"/>
      <input
        v-model="_modelValue"
        :value="initialValue"
        :style="{ position: 'absolute', opacity: 0 }"
        type="radio">
    </label>
  </div>

  <label
    v-else-if="button"
    :class="[ { active }, { disabled }, colClass ]"
    class="base-radio btn smooth-focus">
    <input
      v-model="_modelValue"
      :value="initialValue"
      :disabled="disabledValue"
      :name="name"
      type="radio">
    <span class="text">
      {{ text }}
    </span>
  </label>

  <label
    v-else-if="sequin"
    class="sequin-radio sequin-radio-before">
    <span class="sequin-radio-input">
      <input
        v-model="_modelValue"
        :value="initialValue"
        type="radio"
        :name="name">
      <span class="sequin-radio-control"/>
    </span>
    <span
      class="sequin-radio-label pb-xs"
      :class="{'sequin-radio-label-active': _modelValue === initialValue}">
      {{ text }}</span>
  </label>

  <!-- Regular Radio -->
  <div
    v-else
    class="base-radio form-check form-check-inline">
    <label
      class="form-check-label">
      <input
        v-model="_modelValue"
        :value="initialValue"
        :name="name"
        class="form-check-input"
        type="radio">
      {{ text }}
    </label>
  </div>
</template>

<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    // The value of this group of radio buttons
    modelValue: {
      validator: v => typeof v === 'string' || v === null || v === undefined,
      required: true
    },
    initialValue: {
      validator: v => typeof v === 'string' || v === null || v === undefined,
      required: true
    },
    // The value check to determine if this radio button is disabled
    disabledValue: {
      type: Boolean,
      default: false
    },
    colClass: {
      type: String,
      default: ''
    },
    // Names are necessary for correct focusing
    name: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },

    // Flavors of Radio
    custom: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    },
    sequin: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    _modelValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    },
    active () {
      return this._modelValue === this.initialValue
    },
    disabled () {
      return this.disabledValue
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  border: $default-border !important;
  border-radius: $sequin-border-radius;
  color: $pewter;
  background-color: $white;
  font-family: $body-font-family;

  &.active {
    border-color: $plum !important;
    background-color: rgba($plum, .1) !important;
    color: $plum !important;
    box-shadow: none !important;
  }
  &.disabled {
    border-radius: 2px;
    border: 0.5px dashed rgba(186, 186, 191, 0.50);
    background: #F7F5F7;
    box-shadow: none !important;
  }

  &:focus, &:focus-within {
    border-color: $armor;
    box-shadow: none !important;
  }
}

// sequin radio from https://moderncss.dev/pure-css-custom-styled-radio-buttons/,
// although there are modifications to make it look like we want and
// behave like we want
.sequin-radio {
  display: inline-grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  font-size: 1.0rem;
  font-weight: $font-weight-semibold;
  padding: 10px 20px 0px 0px;
  color: $armor;
  &:focus-within {
    .radio__label {
      opacity: 1;
    }
  }
}

.sequin-radio-label {
  line-height: 1;
  transition: 180ms all ease-in-out;
  opacity: 0.8;
  padding-left: 3px;
  font-weight: $font-weight-normal;
  &-active {
    color: $plum;
    font-weight: $font-weight-semibold;
  }
}

.sequin-radio-input {
  display: flex;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:focus + .sequin-radio-control {
      box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
    }
  }
}

.sequin-radio-gradient input:checked + .radio__control {
  background: radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%);
}

.sequin-radio-before {
  .sequin-radio-control {
    display: grid;
    place-items: center;
  }

  input + .sequin-radio-control::before {
    content: "";
    width: 0.5em;
    height: 0.5em;
    box-shadow: inset 0.5em 0.5em currentColor;
    border-radius: 50%;
    transition: 180ms transform ease-in-out;
    transform: scale(0);
  }

  input:checked + .sequin-radio-control::before {
    color: $plum;
    transform: scale(1);
  }
}

.sequin-radio-control {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid currentColor;
}
.checkbox-radio {
  input {
    display: none;
  }
}
.hidden {
  display: none
}
.check-box {
  height: 22px;
  width: 22px;
  background-color: transparent;
  border: 1.5px solid $pewter;
  border-radius: 2px;
}
  .checkbox-text {
    margin-left: 32px;
    font-size: 16px;
    user-select: none;

    &.active {
      color: $plum;
    }

    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .checkbox-label {
      display: block;
      position: relative;
      margin: auto;
      cursor: pointer;
      font-size: 22px;
      line-height: 24px;
      height: 24px;
      clear: both;
  }

  .checkbox-label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
  }

  .checkbox-label .checkbox-custom {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 22px;
      width: 22px;
      background-color: transparent;
      border: 1.5px solid $pewter;
      border-radius: 2px;
  }

  .checkbox-label input:checked ~ .checkbox-custom {
      background-color: rgba($plum, .1);
       border: 1.5px solid $plum;
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity:1;
  }

  .checkbox-label .checkbox-custom::after {
      position: absolute;
      content: "";
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 2px;
      border: solid #009BFF;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(0deg) scale(0);
      -ms-transform: rotate(0deg) scale(0);
      transform: rotate(0deg) scale(0);
      opacity:1;
      transition: all 0.2s ease-out;
  }

  .checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity:1;
    left: 7px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid $secondary;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }
</style>
