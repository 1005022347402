<template>
  <div data-intercom-target="intercom-look">
    <feed-item-header
      class="px-4 mb-3"
      :feed-item="feedItem"/>
    <feed-item-style-color
      v-if="feedItem.styleColors && feedItem.styleColors[0] && metadata.type === 'case-rented'"
      image-size="medium"
      :feed-item-id="feedItem.id"
      :style-color-source="styleColorSource"
      :style-colors="feedItem.styleColors"/>
    <feed-item-image
      v-if="feedItem.image"
      class="pointer"
      :alt="`${feedItem.client.firstName}'s Look`"
      :style-colors="feedItem.styleColors"
      in-viewport-root-margin="900px 0%"
      :in-viewport-once="true"
      :image-source-url="feedItem.image"
      @click="$emit('main-image-click')"/>
    <feed-item-collection-preview
      v-if="metadata.type === 'collection'"
      :id="feedItem.item.id"
      class="px-0 px-md-4 mx-md-1 mb-4"
      :horizontal-border="!isMobile"
      :style-colors="feedItem.styleColors"
      :metadata="metadata"/>
    <div
      v-if="showSizes"
      class="sizes-wrapper">
      <feed-item-sizes
        :client-name="feedItem.client.firstName"
        :style-profile="feedItem.client.styleProfile"
        :style-colors="feedItem.styleColors"
        class="feed-item-sizes"/>
    </div>
    <div class="my-3">
      <feed-item-action-bar
        :feed-item-id="feedItem.id"
        :likes="feedItem.likes"
        :likes-and-favorites="feedItem.likesAndFavorites"
        :comments="feedItem.comments"
        :timestamp="feedItem.created"
        @like-click="$emit('like-click')"
        @comment-click="$emit('comment-click')"/>
      <feed-item-style-color
        v-if="feedItem.styleColors && feedItem.styleColors[0] && metadata.type !== 'case-rented' && metadata.type !== 'collection'"
        :feed-item-id="feedItem.id"
        :style-colors="feedItem.styleColors"/>
      <plain-button
        v-if="feedItem.caption && feedItem.caption !== ''"
        class="caption"
        @click="$emit('caption-click')">
        <feed-item-caption
          condensed
          :caption="feedItem.caption"/>
      </plain-button>
      <plain-button
        v-if="feedItem.comments.lastComment"
        class="first-comment p-0"
        @click="$emit('comment-click')">
        <div
          v-if="feedItem.comments.count > 1"
          class="view-all">
          View all {{ feedItem.comments.count }} comments
        </div>
        <feed-item-comment-text
          v-if="feedItem.comments.lastComment"
          condensed
          :feed-item-comment="feedItem.comments.lastComment"/>
      </plain-button>
    </div>
  </div>
</template>

<script>
import FeedItemImage from './FeedItemImage'
import FeedItemActionBar from './FeedItemActionBar'
import FeedItemHeader from './FeedItemHeader'
import FeedItemCaption from './FeedItemCaption'
import FeedItemStyleColor from './FeedItemStyleColor'
import FeedItemCollectionPreview from './FeedItemCollectionPreview.vue'
import FeedItemCommentText from './FeedItemCommentText'
import inViewport from 'vue-in-viewport-mixin'
import PlainButton from '../../global/sequin/PlainButton'
import FeedItemSizes from './FeedItemSizes'
import { mapGetters } from 'vuex'

export default {
  name: 'FeedItem',
  components: {
    FeedItemSizes,
    FeedItemImage,
    FeedItemActionBar,
    FeedItemHeader,
    FeedItemCaption,
    FeedItemCollectionPreview,
    FeedItemCommentText,
    FeedItemStyleColor,
    PlainButton
  },
  mixins: [inViewport],
  props: {
    feedItem: {
      type: Object,
      required: true
    },
    styleColorSource: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      hasBeenVisible: false
    }
  },
  computed: {
    ...mapGetters('community', [
      'feedItemMetadata'
    ]),
    metadata () {
      return this.feedItemMetadata(this.feedItem)
    },
    showSizes () {
      const { styleColors, client, item } = this.feedItem
      return styleColors.length &&
        client.styleProfile &&
        item.type === 'look' &&
        this.metadata.type !== 'case-rented' &&
        this.metadata.type !== 'collection'
    }
  },
  watch: {
    'inViewport.now': function (visible) {
      if (visible && !this.hasBeenVisible) {
        this.$emit('item-in-view')
        this.hasBeenVisible = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.view-all {
  color: $ash;
  font-size: $small-text-font-size;
  margin-bottom: 6px;
}

.look-icons {
  margin: 12px 28px;
}

.first-comment {
  margin: 12px 28px 0;
}

.timestamp {
  margin: 0 28px;
}

.caption {
  padding: 0;
  margin: 12px 28px 0;
  display: block;
}

.sizes-wrapper {
  position: relative;
}

.feed-item-sizes {
  position: absolute;
  bottom: 0;
  left: 0;
}

</style>
