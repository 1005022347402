<!-- Featured section button with a banner image that links to the section page. -->
<template>
  <ImageTileButton
    :title="name"
    :subtitle="subtitle"
    :images="[bannerImageUrl]"
    :image-count="1"
    :truncate-text="false"
    :to="routeLocation"
    @click="emit('click', $event)"/>
</template>

<script setup>
import useClosetSection from '@/composables/closetSection.js'
import ImageTileButton from '@/components/global/ImageTileButton.vue'

const props = defineProps({
  sectionId: {
    type: String,
    required: true
  }
})

const { name, subtitle, bannerImageUrl, routeLocation } = useClosetSection(() => props.sectionId, { loadOnMount: true })

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})
</script>
