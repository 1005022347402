
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none">
    <path
      d="M24 24V21.6C24 20.327 23.5785 19.106 22.8284 18.2059C22.0783 17.3057 21.0609 16.8 20 16.8H12C10.9391 16.8 9.92172 17.3057 9.17157 18.2059C8.42143 19.106 8 20.327 8 21.6V24M16.0364 16.8C18.4664 16.8 20.4364 14.83 20.4364 12.4C20.4364 9.96995 18.4664 8 16.0364 8C13.6063 8 11.6364 9.96995 11.6364 12.4C11.6364 14.83 13.6063 16.8 16.0364 16.8Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>
