<template>
  <div
    v-if="numStyleColorCollections"
    class="d-flex align-items-center">
    <svg-circle-icon
      inverse
      :width="36"
      :height="36"
      :padding="16"
      class="border bookmark">
      <svg-bookmark/>
    </svg-circle-icon>
    <p class="text-pewter small-text ms-2 mb-0">
      You've saved this style to <text-link
        type="router-link"
        :to="{ name: 'collection', params: { id: styleColorCollections[0].id }}">
        {{ styleColorCollections[0].name }}
      </text-link>
      <span v-if="numStyleColorCollections > 1"> and
        <text-link
          type="router-link"
          :to="numStyleColorCollections === 2 ?
            { name: 'collection', params: { id: styleColorCollections[1].id }}
            : { name: 'client-collections', params: { clientId: id }}">
          {{ numStyleColorCollections === 2 ? styleColorCollections[1].name : `${numStyleColorCollections - 1} others` }}
        </text-link>
      </span>
    </p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import TextLink from '../global/sequin/TextLink.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgBookmark from '@/components/global/svg/SvgBookmark.vue'

export default {
  components: {
    TextLink,
    SvgCircleIcon,
    SvgBookmark
  },
  props: {
    styleColor: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('collections', [
      'clientCollectionsByStyleColorId'
    ]),
    ...mapState('client', [
      'id'
    ]),
    styleColorCollections () {
      return this.clientCollectionsByStyleColorId[this.styleColor.id]
    },
    numStyleColorCollections () {
      return this.styleColorCollections?.length
    }
  },
  mounted () {
    this.getClientsCollectionsWithStyleColor(this.styleColor.id)
  },
  methods: {
    ...mapActions('collections', [
      'getClientsCollectionsWithStyleColor'
    ])
  }
}
</script>

<style lang="scss">
.bookmark {
  flex-shrink: 0;
  svg {
    fill: $primary;
  }
}
</style>
