<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('account', [
      'shippingAddresses',
      'pickupLocations'
    ]),
    ...mapState('client', [
      'shippingAddress',
      'pickupAddress'
    ]),
    selectedShippingAddress () {
      if (this.shippingAddress) {
        return this.shippingAddresses.filter((address) => {
          return address.id === this.shippingId
        })[0]
      }
      return null
    },
    selectedPickupLocation () {
      if (this.pickupAddress) {
        return this.pickupLocations.filter((location) => {
          return location.id === this.locationId
        })[0]
      }
      return null
    },
    nonSelectedShippingAddresses () {
      return this.shippingAddresses.filter((address) => {
        return address.id !== this.shippingId
      })
    },
    nonSelectedPickupLocations () {
      return this.pickupLocations.filter((location) => {
        return location.id !== this.locationId
      })
    },
    shippingId () {
      if (this.shippingAddress) { return this.shippingAddress.id }
      return ''
    },
    printReturnLabelLink () {
      if (this.shippingAddress) { return this.shippingAddress.printReturnLabelLink }
      if (this.pickupAddress) { return this.pickupAddress.printReturnLabelLink }
      return ''
    },
    locationId () {
      if (this.pickupAddress) { return this.pickupAddress.id }
      return ''
    }
  }
}
</script>
