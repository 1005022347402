<template>
  <SlideTransition
    v-show="isVisible">
    <div class="sticky-footer p-sm mx-xxs">
      <slot name="content"/>
    </div>
  </SlideTransition>
</template>

<script setup>
import { watch } from 'vue'
import SlideTransition from '@shared/components/ADORN/Transitions/SlideTransition.vue'

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(['show', 'hide'])

watch(() => props.isVisible, (newVal) => {
  if (newVal) emits('show')
  else emits('hide')
})
</script>

<style lang="scss" scoped>
.sticky-footer {
  position: fixed;
  bottom: 0;
  width: calc(100vw - (#{$spacing-xxs} * 2));
  // inline-flex is used to keep Safari UI from hiding the footer
  display: inline-flex;
  justify-content: center;
  background-color: white;
  border: solid 1px $ash;
  border-bottom: none;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 1px 8px 0px rgba(23, 23, 35, 0.10);
  z-index: $zindex-sticky;
}
</style>
