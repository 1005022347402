import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('subscribe', [
      'referralCampaign'
    ]),
    ...mapState('client', [
      'isAuthenticated'
    ]),
    ...mapGetters('subscribe', [
      'selectedPlanReferralBonusItems'
    ]),
    referralText () {
      return 'Refer a Friend'
    },
    shouldShowReferral () {
      return this.isAuthenticated && !(this.$route.path.includes('/membership/'))
    }
  },
  methods: {
    formatReferralSource (source) {
      const referralSourceMap = {
        purl: 'Shared link',
        email: 'Email invite',
        sms: 'SMS invite',
        fb_msg: 'Facebook message',
        fb_post: 'Facebook post'
      }
      if (source in referralSourceMap) {
        return referralSourceMap[source]
      } else {
        return 'Shared link'
      }
    },
    formatDate (str) {
      return str
        ? new Date(str).toLocaleDateString('en-US', { year: 'numeric', day: 'numeric', month: 'short' })
        : ''
    }
  }
}
