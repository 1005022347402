<template>
  <div class="pb-lg">
    <h5 :class="{'text-danger': validations.$error}">
      How did the sizing run?
    </h5>
    <div class="px-md">
      <rating-scale
        v-model="internalValue"
        name="sizing"
        :options="sizingRatingOptions"/>
    </div>
    <base-errors
      class="mt-md"
      :class="{ 'error-scroll': validations.$error }"
      :validations="validations"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RatingScale from '../global/sequin/RatingScale.vue'
import BaseErrors from '@/components/global/BaseErrors.vue'

export default {
  name: 'ReviewItemSizing',
  components: {
    RatingScale,
    BaseErrors
  },
  props: {
    value: {
      required: true,
      validator: v => typeof v === 'number' || v === null || v === undefined
    },
    validations: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('review', [
      'sizingRatingOptions'
    ]),
    internalValue: {
      get () { return this.value },
      set (v) { this.$emit('input', v) }
    }
  }
}
</script>

<style lang="scss" scoped>

  .radio-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sizing-label {
    position: absolute;
    bottom: -16px;
  }

  .scale-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .scale-axis {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $ash;
  }

  input[type=radio] {
    position: relative;
    height: 16px;
    width: 16px;
    cursor: pointer;

    &:after {
      height: 16px;
      width: 16px;
      border-radius: 100%;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 1px solid $ash;
      background-color: $origami;
    }

    &:checked {
      &:before {
        content: '';
        position: absolute;
        height: 16px;
        width: 16px;
        border-radius: 100%;
        border: 1px solid $plum;
      }
      &:after {
        content: '';
        position: relative;
        width: 12px;
        height: 12px;
        left: 2px;
        top: 2px;
        border: 1px solid $white;
        background-color: $plum;
      }
    }
}

</style>
