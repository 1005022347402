export default {
  data () {
    return {
      FAQ_URL: 'https://support.armoire.style/faq/',
      FEDEX_LOCATOR_URL: 'https://local.fedex.com/en/search',
      FEDEX_PICKUP_URL: 'https://www.fedex.com/grd/rpp/',
      PACKAGING_BLOG_URL: 'https://blog.armoire.style/sustainable-packaging-armoire-style/?utm_campaign=colleeno&utm_source=armoire_member_share&utm_medium=organic',
      USPS_PICKUP_URL: 'https://tools.usps.com/schedule-pickup-steps.htm'
    }
  }
}
