<script>
import { mapState, mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import ReferralsMixin from './mixins/ReferralsMixin'
import browseMixin from './browse/browseMixin.vue'
import SequinButton from './sequin/SequinButton'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: { SequinButton },
  mixins: [ReferralsMixin, browseMixin],
  data () {
    return {
      logoSource: process.env.VUE_APP_WEBSITE_IMAGES_URL + 'logo.jpg',
      dressSource: process.env.VUE_APP_WEBSITE_IMAGES_URL + 'armoire-dress-white.png',
      showDropdown: false,
      showFlyout: false
    }
  },
  computed: {
    ...mapState('global', [
      'windowWidth'
    ]),
    ...mapState('client', [
      'firstName',
      'id',
      'isAuthenticated',
      'isImpersonated',
      'isStaff',
      'global',
      'lastName',
      'styleProfileComplete',
      'membershipStatus'
    ]),
    displayCloset () {
      return this.isAuthenticated && !(this.$route.path.includes('signup/membership/'))
    },
    displayCommunity () {
      return this.displayCloset && this.styleProfileComplete
    },
    displayCase () {
      return this.displayCloset && this.styleProfileComplete
    },
    displaySearch () {
      return this.displayCloset && parseInt(process.env.VUE_APP_SEARCH)
    },
    navItems () {
      return [
        {
          id: 'search-home',
          intercomId: 'intercom-searchhome',
          name: 'Search',
          component: 'router-link',
          componentProps: {
            to: { name: 'search' }
          },
          classes: ['text-info'],
          shouldShow: () => { return this.displaySearch && !this.isMobile },
          isSelected: () => { return this.$route.path.startsWith('/search') },
          svgComponent: 'svg-search',
          svgClass: 'search-icon-desktop'
        },
        {
          id: 'admin',
          name: 'Admin',
          component: 'a',
          componentProps: {
            href: '/admin/'
          },
          classes: ['text-info'],
          shouldShow: () => { return this.isStaff },
          isSelected: () => { return false },
          onClick: () => {}
        },
        {
          id: 'closet',
          name: 'Closet',
          component: 'router-link',
          componentProps: {
            to: { name: 'closet-sections' }
          },
          classes: ['text-info'],
          shouldShow: () => { return this.displayCloset },
          isSelected: () => { return !this.displayBrowseMenu && this.$route.path.includes('/closet') && !this.$route.path.includes('/browse') },
          onClick: () => {},
          isBold: true
        },
        {
          id: 'community',
          intercomId: 'intercom-looks',
          name: 'The PWR Feed',
          component: 'router-link',
          componentProps: {
            to: { name: 'feed' }
          },
          classes: ['text-info'],
          onClick: () => {},
          shouldShow: () => { return this.displayCommunity },
          isSelected: () => { return this.$route.path.includes('/community') },
          isBold: true
        },
        {
          id: 'swaps-available',
          component: 'button',
          classes: ['text-info'],
          shouldShow: () => { return this.isAuthenticated && this.windowWidth > 900 && this.membershipStatus === 'active' },
          isSelected: () => { return false },
          onClick: () => { this.openModal({ name: 'swaps-available' }) },
          svgComponent: 'swaps-available-icon'
        },
        {
          id: 'case',
          intercomId: 'intercom-my-case',
          name: 'Case',
          component: 'button',
          classes: ['text-info'],
          shouldShow: () => { return !this.isMobile && this.displayCase },
          isSelected: () => { return false },
          onClick: () => { this.openFlyout({ name: 'my-case' }) },
          svgComponent: 'my-case-icon'
        },
        {
          id: 'how-it-works',
          name: 'How It Works',
          component: 'button',
          classes: ['text-info'],
          shouldShow: () => { return !this.isAuthenticated && !(this.$route.path.startsWith('/signup/')) },
          isSelected: () => { return false },
          onClick: () => { window.location = 'https://armoire.style/howitworks/' }
        },
        {
          id: 'style-quiz',
          name: 'Take Your Style Quiz',
          component: 'button',
          classes: ['text-info'],
          shouldShow: () => { return !this.isAuthenticated && !(this.$route.path.startsWith('/signup/')) },
          isSelected: () => { return false },
          onClick: () => { this.safeNav('style-quiz-intro') }
        },
        {
          id: 'create-account',
          name: 'Sign Up',
          component: 'button',
          classes: ['text-info'],
          shouldShow: () => { return !this.isAuthenticated && !(this.$route.path.startsWith('/signup/')) },
          isSelected: () => { return false },
          onClick: () => { this.safeNav('style-quiz-intro') }
        },
        {
          id: 'login',
          name: 'Login',
          component: 'button',
          classes: ['text-info'],
          shouldShow: () => { return !this.isAuthenticated && !(this.$route.name === 'login') },
          isSelected: () => { return false },
          onClick: () => { this.$router.push({ name: 'login' }) }
        }
      ]
    },
    dropdownItems () {
      return [
        {
          id: 'style-profile',
          name: 'Style Profile',
          component: 'button',
          shouldShow: () => { return this.isAuthenticated && this.styleProfileComplete && !(this.$route.path.includes('/membership/')) },
          isSelected: () => { return this.$route.path.includes('/style-profile') },
          onClick: () => { this.safeNav('style-profile') }
        },
        {
          id: 'rental-history',
          name: 'Rental History',
          component: 'button',
          shouldShow: () => this.isAuthenticated && this.styleProfileComplete,
          isSelected: () => false,
          onClick: () => { this.safeNav('profile', { tab: 'rental-history' }) }
        },
        {
          id: 'account',
          name: 'Account',
          component: 'button',
          shouldShow: () => { return this.isAuthenticated },
          isSelected: () => { return this.$route.path.includes('/account') },
          onClick: () => { this.safeNav('account') }
        },
        {
          id: 'help',
          name: 'Help + FAQs',
          component: 'button',
          shouldShow: () => true,
          isSelected: () => false,
          onClick: () => { window.open('https://support.armoire.style/faq/', '_blank') }
        },
        {
          id: 'referral',
          name: 'Refer a Friend',
          component: 'button',
          shouldShow: () => this.shouldShowReferral,
          isSelected: () => { return this.$route.path.includes('/referral') },
          onClick: () => { this.safeNav('referral') }
        },
        {
          id: 'shop',
          name: 'Shop',
          component: 'button',
          shouldShow: () => true,
          isSelected: () => false,
          onClick: () => { window.open('https://shop.armoire.style/', '_blank') }
        },
        {
          id: 'sign-out',
          name: 'Sign Out',
          component: 'button',
          shouldShow: () => { return this.isMobile && this.isAuthenticated },
          isSelected: () => { return false },
          onClick: () => { this.logout() }
        }
      ]
    },
    navItemsShown () {
      return this.navItems.filter(navItem => { return navItem.shouldShow() })
    },
    dropdownItemsShown () {
      return this.dropdownItems.filter(dropdownItem => { return dropdownItem.shouldShow() })
    },
    flyoutItemsShown () {
      return this.navItemsShown.concat(this.dropdownItemsShown)
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout',
      'openFlyout',
      'openModal'
    ]),
    ...mapVuexActions('search', [
      'startSearchSession'
    ]),
    safeNav (name, params) {
      if (this.$route.name !== name) {
        this.$router.push({ name, params })
      }
    },
    logout () {
      if (window.fbInitialized && window.fbAccessToken) {
        // eslint-disable-next-line
        FB.logout(function (response) { })
      }

      location.href = '/logout/'
    },
    flyoutOnClick (onClick) {
      onClick()
      this.closeFlyout()
    },
    iconUrl (icon) {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}${icon}`
    },
    handleBrowseClick () {
      if (this.isMobile) {
        this.$router.push({ name: 'browse-menu' })
      } else {
        this.displayBrowseMenu = true
      }
    }
  }
}
</script>
