<template>
  <div class="d-flex wrapper">
    <div class="back-button"/>
    <div class="me-3">
      <preview-image-placeholder size="tiny"/>
      <preview-image-placeholder size="tiny"/>
    </div>
    <style-color-preview-image-placeholder
      size="x-large"
      action-button-orientation="horizontal"
      class="me-4"/>
    <div class="ms-4">
      <text-placeholder
        variant="small-text"
        size="small"/>
      <text-placeholder
        variant="h3"
        size="large"/>
      <text-placeholder
        variant="h3"
        size="medium"
        class="mb-5"/>
      <div class="mb-4">
        <text-placeholder
          variant="small-text"
          size="medium"/>
        <text-placeholder
          variant="small-text"
          size="small"/>
        <text-placeholder
          variant="small-text"
          size="large"/>
      </div>
      <text-placeholder
        variant="h2"
        size="medium"/>
    </div>
  </div>
</template>

<script>
import StyleColorPreviewImagePlaceholder from '@shared/components/ADORN/StyleColorPreviewImagePlaceholder.vue'
import PreviewImagePlaceholder from '@shared/components/ADORN/PreviewImagePlaceholder.vue'
import TextPlaceholder from '../../global/sequin/TextPlaceholder'

export default {
  components: {
    StyleColorPreviewImagePlaceholder,
    PreviewImagePlaceholder,
    TextPlaceholder
  }
}
</script>

<style lang="scss" scoped>
@import '@shared/components/ADORN/PreviewImage.scss';

.wrapper {
  padding: 40px 40px 40px 0;
}

:deep(.preview-image.x-large) {
  // Preserve the "setWidth" size we use today.
  @include preview-image-size("x-large") using ($width, $height) {
    width: $height;
    height: auto;
  }
}

.back-button {
  margin: 0 24px 0 16px;
  width: 40px;
  height: 40px;
}
</style>
