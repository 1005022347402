<script>
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  data () {
    return {
      isSubmitting: false,
      submitError: false,
      locallySelectedStyles: [],
      locallyAddedPhotos: [],
      transitionName: 'slide-left'
    }
  },
  computed: {
    ...mapState('community', [
      'lookBuilder',
      'itemInFocus'
    ]),
    componentDetails () {
      return {
        Core: {
          handlers: {},
          componentName: 'ShareALookBody',
          props: {
            submitError: this.submitError,
            isSubmitting: this.isSubmitting
          }
        },
        AddPhoto: {
          title: 'Add a Photo',
          componentName: 'AddPhotoBody',
          showConfirm: this.locallyAddedPhotos.length > 0,
          onConfirm: () => {
            this.$store.commit('community/SET_LOOK_BUILDER_IMAGES', this.locallyAddedPhotos)
            this.setShareLookComponent('TagStyles')
          },
          props: {
            locallyAddedPhotos: this.locallyAddedPhotos
          },
          handlers: {
            'add-local-upload': (uploadUrl) => { this.addLocalPhotoUpload(uploadUrl) },
            'remove-local-upload': () => { this.removeLocalPhotoUpload() }
          }
        },
        TagStyles: {
          title: 'Tag Styles',
          componentName: 'TagStylesBody',
          showConfirm: this.locallySelectedStyles.length > 0,
          onConfirm: () => {
            this.$store.commit('community/SET_LOOK_BUILDER_STYLE_COLORS', this.locallySelectedStyles)
            this.setShareLookComponent('Core')
          },
          props: {
            locallySelectedStyles: this.locallySelectedStyles
          },
          handlers: {
            'select-style': (styleColor) => { this.selectStyleLocally(styleColor) },
            'unselect-style': (styleColor) => { this.unselectStyleLocally(styleColor) }
          }
        },
        TagOccasion: {
          title: 'Tag Occasion',
          componentName: 'TagOccasionBody',
          showConfirm: false,
          onConfirm: () => {},
          props: {},
          handlers: {
            'tag-occasion': () => { this.setShareLookComponent('Core') }
          }
        }
      }
    }
  },
  watch: {
    shareLookComponent (newValue) {
      if (newValue === 'Core') { this.transitionName = 'slide-right' } else this.transitionName = 'slide-left'
    }
  },
  mounted () {
    this.getStylesFromLookBuilder()
    this.getPhotosFromLookBuilder()
  },
  methods: {
    ...mapVuexActions('community', [
      'postLook',
      'updateLook',
      'clearLookBuilder'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    setShareLookComponent (componentName) {
      this.$store.commit('community/SET_SHARE_LOOK_COMPONENT', componentName)
    },
    async shareLook () {
      this.isSubmitting = true
      this.submitError = false

      try {
        await this.postLook()
      } catch (err) {
        this.submitError = true
        this.isSubmitting = false
      }
    },
    async saveLookUpdates () {
      this.isSubmitting = true
      this.submitError = false

      try {
        await this.updateLook(this.itemInFocus)
      } catch (err) {
        this.submitError = true
        this.isSubmitting = false
      }
    },
    addLocalPhotoUpload (uploadUrl) {
      this.locallyAddedPhotos.push(uploadUrl)
    },
    removeLocalPhotoUpload () {
      this.locallyAddedPhotos.pop()
    },
    getStylesFromLookBuilder () {
      this.locallySelectedStyles = this.lookBuilder.styleColors.slice()
    },
    getPhotosFromLookBuilder () {
      this.locallyAddedPhotos = this.lookBuilder.images.slice()
    },
    transformStyle (styleColor) {
      return { id: styleColor.id, image: { url: styleColor.images[0].url } }
    },
    unselectStyleLocally (styleColor) {
      const styleColorIds = this.locallySelectedStyles.map(styleColor => styleColor.id)
      this.locallySelectedStyles.splice(styleColorIds.indexOf(styleColor.id), 1)
    },
    selectStyleLocally (styleColor) {
      this.locallySelectedStyles.push(this.transformStyle(styleColor))
    }
  }
}
</script>
