<template>
  <div class="d-flex justify-content-between bg-white p-2 border-top pb-2">
    <div>
      <div class="semi-bold mt-2 ms-2">
        {{ title }}
      </div>
      <div class="p-2">
        {{ subtitle }}
      </div>
      <div
        v-if="itemsInProcessing > 0"
        class="p-2 small-text text-armor">
        You have a package in processing.  You cannot change this setting until it finishes.
      </div>
    </div>
    <div class="pe-2 mt-2">
      <sequin-button
        :disabled="itemsInProcessing > 0"
        @click="openReplacementItemFlyout()">
        Change
      </sequin-button>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import SequinButton from '../global/sequin/SequinButton'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  name: 'ChangeReplacementItems',
  components: { SequinButton },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('client', [
      'selectedReplacementItem',
      'itemsInProcessing'
    ])
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openFlyout'
    ]),
    openReplacementItemFlyout () {
      this.openFlyout({ name: 'replacement' })
    }
  }
}
</script>
