<template>
  <div
    class="sequin-breadcrumb">
    <div
      v-for="(crumb, index) in crumbs"
      :key="crumb.name"
      class="d-flex align-items-center">
      <p
        v-if="index !== 0"
        class="small-text separator">
        /
      </p>
      <text-link
        type="router-link"
        :underline="false"
        :to="crumb.to">
        {{ crumb.name }}
      </text-link>
    </div>
    <p
      v-if="crumbs.length > 0"
      class="small-text separator">
      /
    </p>
    <text-line-limit
      tag="p"
      class="small-text mb-0">
      {{ currentLocation }}
    </text-line-limit>
  </div>
</template>

<script>
import TextLink from './TextLink'
import TextLineLimit from './TextLineLimit.vue'

export default {
  components: { TextLink, TextLineLimit },
  props: {
    /**  Each crumb entry should be an object with these properties:
     * (1) Name - The name shown to the user
     * (2) To -- The vue route object where the breadcrumb should navigate
    */
    crumbs: {
      type: Array,
      default: () => []
    },
    currentLocation: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.sequin-breadcrumb {
  background-color: inherit;
  font-size: $small-text-font-size;
  line-height: $small-text-line-height;
  display: flex;
  align-items: center;
  color: $pewter;

  .separator {
    color: $pewter ;
    margin: 0 8px;
  }
}
</style>
