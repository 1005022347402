<template>
  <div
    class="accordion"
    :class="{'mobile': isMobile, 'with-border' : border}">
    <plain-button
      class="accordion-button d-flex align-items-center justify-content-between"
      :class="{'flex-row-reverse': reverseOrder}"
      @click="onExpandClick">
      <div class="d-flex header-wrapper">
        <h5
          v-if="title"
          class="mb-0"
          :class="`text-${textTransform} ${small ? 'small-text semi-bold' : ''}`">
          {{ title }}
        </h5>
        <slot name="subtitle"/>
      </div>
      <svg-plus
        v-if="plusVariant"/>
      <svg-chevron-down
        v-else-if="arrowOrientation === 'default'"
        class="chevron"
        :class="{'expanded-chevron': expanded}"/>
      <svg-chevron-up
        v-else
        class="chevron"
        :class="{'expanded-chevron': expanded}"/>
    </plain-button>
    <div
      :style="{ maxHeight: expanded ? expandedMaxHeight : collapsedMaxHeight }"
      :class="{ 'expanded': expanded }"
      class="expandable-content">
      <div class="pb-3">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import PlainButton from './PlainButton.vue'
import SvgPlus from '@/components/global/svg/SvgPlus.vue'
import SvgChevronDown from '@/components/global/svg/SvgChevronDown.vue'
import SvgChevronUp from '@/components/global/svg/SvgChevronUp.vue'

export default {
  components: {
    PlainButton,
    SvgPlus,
    SvgChevronDown,
    SvgChevronUp
  },
  props: {
    expanded: {
      type: Boolean,
      required: true
    },
    collapsedMaxHeight: {
      type: Number,
      default: 0
    },
    expandedMaxHeight: {
      type: Number || String,
      default: 200
    },
    title: {
      type: String,
      default: undefined
    },
    small: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    arrowSide: {
      type: String,
      default: 'right'
    },
    arrowOrientation: {
      type: String,
      validator: value => value.match(/(default|alt)/),
      default: 'default'
    },
    direction: {
      type: String,
      validator: value => value.match(/(default|reverse)/),
      default: 'default'
    },
    textTransform: {
      validator: value => ['capitalized', 'uppercase', 'light'].includes(value),
      default: 'capitalized'
    },
    plusVariant: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    reverseOrder () {
      return this.direction === 'reverse'
    }
  },
  methods: {
    onExpandClick () {
      this.$emit('accordion-click', !this.expanded)
    }
  }
}
</script>

<style lang="scss" scoped>
.with-border {
  border: $default-border;
}

.accordion-button {
  width: 100%;
  padding: 16px 16px;
}

.chevron {
  flex-shrink: 0;
    @include transition-base-all;

  &.expanded-chevron {
    transform: rotate(-180deg);
  }
}

.expandable-content {
  @include transition-base-all;
  overflow-y: hidden;
  padding: 0 16px;

  &.expanded {
    overflow-y: visible;
  }
}

</style>
