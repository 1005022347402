import { computed, onMounted, onUnmounted, ref } from 'vue'
import holidayData from '@shared/data/holidays.json'
import { createSharedComposable } from '@vueuse/core'

// Default to a shared instance so we're not running multiple timers.
const useHolidays = createSharedComposable(useHoliday)
export default useHolidays

// Export actual composable for unit tests.
export function useHoliday () {
  const now = ref(new Date())
  const timerInterval = ref(null)
  onMounted(() => {
    const minute = 60 * 1000
    timerInterval.value = setInterval(() => {
      now.value = new Date()
    }, minute)
  })
  onUnmounted(() => {
    clearInterval(timerInterval.value)
    timerInterval.value = null
  })

  const holidays = computed(() => {
    return Object.keys(holidayData).reduce((acc, name) => {
      const { startDate, endDate } = holidayData[name]
      acc[name] = {
        startDate: parseDate(startDate),
        endDate: parseDate(endDate),
        name
      }
      acc[name].startDate.setHours(0, 0, 0, 0)
      acc[name].endDate.setHours(23, 59, 59, 999)
      return acc
    }, {})
  })
  const currentHolidays = computed(() => {
    return Object.values(holidays.value).filter(({ startDate, endDate }) => {
      return startDate <= now.value && now.value <= endDate
    })
  })
  const isHoliday = computed(() => currentHolidays.value.length > 0)

  function parseDate (dateString) {
    const [year, month, day] = dateString.split('-')
    // Use date-time format to interpret as local time.
    return new Date(`${year}-${month}-${day}T00:00`)
  }

  return {
    holidays,
    currentHolidays,
    isHoliday,
    parseDate
  }
}
