import { useToastNotificationsStore } from '@/stores/toastNotifications.js'
import { useModals } from './modals.js'

// Helper composable built on top of the toast notifications store for showing toasts.
export function useToastNotifications () {
  const toastNotificationsStore = useToastNotificationsStore()
  const modals = useModals()

  function showAddedToFavoritesToast (styleColorId) {
    return toastNotificationsStore.showTransientToast('Added to Favorites', {
      text: 'Add to Collection',
      clickHandler: () => {
        modals.openAddToCollectionModal(styleColorId)
      }
    }, 'svg-heart-new')
  }

  return {
    showAddedToFavoritesToast
  }
}
