import axios from './config'
import store from '../index'
import { serializeParams } from './helpers/helpers'

const apiCollections = {
  getCollections ({ clientId, styleColorId }) {
    let params = ''
    const serializedParams = serializeParams({ client: clientId, style_color: styleColorId })
    if (serializeParams.length) params = '?' + serializedParams
    return axios.get('/api/memberstylecolorcollections/' + params)
  },
  getCollectionsNextPage (nextPage) {
    return axios.get(nextPage)
  },
  getCollection (collectionId) {
    return axios.get('/api/memberstylecolorcollections/' + collectionId + '/')
  },
  getCollectionsByFilter (filterType, filter, minItems) {
    switch (filterType) {
      case 'names':
        return axios.get(`/api/memberstylecolorcollections/?names_filter=${filter}&min_items=${minItems}`)
    }
  },
  getCollectionStyleColorSuggestions (collectionId) {
    return axios.get('/api/memberstylecolorcollections/' + collectionId + '/suggestions/')
  },
  getCollectionStyleColors ({ collectionId, available = null, page, filters, sizeFilter = true }) {
    let params = ''
    const serializedParams = serializeParams({ available, page, ...filters })
    if (serializedParams.length) params = '&' + serializedParams
    return axios.get('/api/memberstylecolorcollections/' + collectionId + '/style_colors/?sizeFilter=' + sizeFilter + params)
  },
  getCollectionStyleColorsNextPage (nextPage) {
    return axios.get(nextPage)
  },
  getStyleColorClientCollections (styleColorId) {
    return axios.get('/api/stylecolors/' + styleColorId + '/collections/')
  },
  getClientCollectedStyleColors () {
    const username = store.state.client.username
    return axios.get('/api/clients/' + username + '/saved_styles/')
  },
  getCollectionTags () {
    return axios.get('/api/memberstylecolorcollections/tags/')
  },
  createCollection (data) {
    return axios.post('/api/memberstylecolorcollections/', data)
  },
  updateCollection (collectionId, data) {
    return axios.patch('/api/memberstylecolorcollections/' + collectionId + '/', data)
  },
  deleteCollection (collectionId) {
    return axios.delete('/api/memberstylecolorcollections/' + collectionId + '/')
  },
  postCollectionToFeed (collectionId, data) {
    return axios.post('/api/memberstylecolorcollections/' + collectionId + '/post_to_feed/', data)
  },
  addStyleColorToCollection (collectionId, styleColorData) {
    return axios.post('/api/memberstylecolorcollections/' + collectionId + '/style_color/', styleColorData)
  },
  removeStyleColorFromCollection (collectionId, styleColorData) {
    return axios.post('/api/memberstylecolorcollections/' + collectionId + '/remove_style_color/', styleColorData)
  },
  searchCollections (name, autosuggest) {
    return axios.get(`/api/memberstylecolorcollections/?name=${name}&autosuggest=${autosuggest}`)
  }
}

export default apiCollections
