<template>
  <div class="d-flex">
    <avatar-image
      v-if="hasProfilePic"
      class="pe-2"
      :profile-pic="profilePic"/>
    <div
      v-else
      class="profile-pic me-2 d-flex justify-content-center align-items-center"
      :class="{'none' : !hasProfilePic && !reviewClientIsPrivate, 'private' : reviewClientIsPrivate}">
      <svg-origami-dress
        :width="28"
        :height="28"/>
    </div>
  </div>
</template>

<script>
import { ReviewMixin } from '../../ReviewMixin'
import AvatarImage from '../../../community/common/AvatarImage.vue'
import SvgOrigamiDress from '../../../global/svg/SvgOrigamiDress.vue'

export default {
  components: { AvatarImage, SvgOrigamiDress },
  mixins: [ReviewMixin],
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasProfilePic () {
      if (this.reviewClientIsPrivate) {
        return false
      }
      if (this.profilePic.includes('dresses')) {
        return false
      } else {
        return true
      }
    },
    profilePic () {
      return this.review.client.profilePic
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-pic {
  border-radius: 100%;
  height: 52px;
  width: 52px;
  &.private {
  background-color: $plum;
  }
  &.none {
  background-color: $orchid;
  }
}
</style>
