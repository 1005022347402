<template>
  <svg
    width="48"
    height="48"
    xmlns:svg="http://www.w3.org/1999/xhtml"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 162.9 162.9">
    <title>Share via SMS</title>
    <defs>
      <clipPath
        id="clip-path"
        transform="translate(0 -0.02)">
        <rect
          class="cls-1"
          x="0.02"
          width="162.87"
          height="162.87"/>
      </clipPath>
    </defs>
    <g
      id="Layer_2"
      data-name="Layer 2">
      <g
        id="Layer_1-2"
        data-name="Layer 1">
        <g class="cls-2">
          <path
            :fill="backgroundFill"
            d="M160.7,100.18a81.43,81.43,0,1,0-98,60.54A81.43,81.43,0,0,0,160.7,100.18Z"
            transform="translate(0 -0.02)"/>
          <path
            class="cls-4"
            :fill="color"
            :stroke="color"
            d="M122.17,79.17a38,38,0,0,1-4.07,17.19,38.44,38.44,0,0,1-34.39,21.26,37.75,37.75,0,0,1-17.19-4.07l-25.79,8.6,8.6-25.79a37.88,37.88,0,0,1-4.07-17.19A38.46,38.46,0,0,1,66.52,44.79a37.88,37.88,0,0,1,17.19-4.07H86a38.35,38.35,0,0,1,36.19,36.19Z"
            transform="translate(0 -0.02)"/>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    backgroundFill: {
      type: String,
      default: '#5bb75b'
    },
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>
<style scoped>
  .cls-1 {
    fill: none;
  }
  .cls-2 {
    clip-path: url(#clip-path);
  }
  .cls-3 {
    fill: #5bb75b;
  }
  .cls-4 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }
</style>
