<template>
  <div class="container-fluid">
    <SectionHeadline title="My Stuff"/>
    <FavoritesSectionPreview class="row"/>
    <StylistSectionPreview
      v-for="section in stylistSections"
      :key="section.id"
      :section-id="section.id"
      class="row"/>
    <div class="row">
      <MyCollections class="col-lg-8 order-lg-2"/>
      <StyleGames class="col-lg-4 order-lg-1"/>
    </div>
    <WearItAgainSectionPreview class="row pb-xl"/>
    <MyClosetChatPrompt class="row"/>
  </div>
</template>

<script setup>
import useMyStuff from '@/composables/myStuff.js'
import SectionHeadline from '@/components/global/SectionHeadline.vue'
import FavoritesSectionPreview from '@/components/myStuff/FavoritesSectionPreview.vue'
import StylistSectionPreview from '@/components/myStuff/StylistSectionPreview.vue'
import StyleGames from '@/components/myStuff/StyleGames.vue'
import MyCollections from '@/components/myStuff/MyCollections.vue'
import MyClosetChatPrompt from '@/components/closet/MyClosetChatPrompt.vue'
import WearItAgainSectionPreview from '@/components/myStuff/WearItAgainSectionPreview.vue'

const { stylistSections } = useMyStuff()
</script>
