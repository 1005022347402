<template>
  <div class="card">
    <div>
      <SequinTag
        v-if="locationId === location.id"
        class="mb-sm">
        Pickup Address
      </SequinTag>
      <pickup-address
        :pickup-address="location"/>
    </div>
    <TextLink
      v-if="locationId !== location.id"
      @click="selectLocation(location)">
      Select
    </TextLink>
  </div>
</template>

<script>
import PickupAddress from '../global/address/PickupAddress'
import SequinTag from '@/components/global/sequin/SequinTag.vue'
import TextLink from '../global/sequin/TextLink.vue'

export default {
  components: {
    SequinTag,
    PickupAddress,
    TextLink
  },
  props: {
    locationId: {
      type: String,
      required: true
    },
    location: {
      type: Object,
      required: true
    }
  },
  methods: {
    selectLocation (location) {
      if (this.locationId !== location.id) {
        this.$emit('select-location', location)
      }
    }
  }
}
</script>
