<template>
  <div>
    <!-- Allows scrolling to error on long forms -->
    <div :class="{ 'error-scroll': validations.$error }"/>

    <!-- Label -->
    <div :class="{'text-danger font-weight-bold': validations.$error}">
      <slot name="label">
        <h5 v-if="label">
          {{ label }}
        </h5>
      </slot>
    </div>

    <!-- Image Checkboxes -->
    <div v-if="image">
      <base-errors :validations="validations"/>
      <div
        class="row"
        :class="rowClass">
        <div
          v-for="option in parsedOptions"
          :key="option.id"
          :class="colClass">
          <base-checkbox
            v-model="internalValue"
            :input-value="option.value"
            image>
            <slot :option="option"/>
            <template #overlay>
              <slot
                :option="option"
                name="overlay">
                <base-image-overlay persistent>
                  <div class="btn p-2 bg-success">
                    <svg-check
                      height="30"
                      width="30"
                      color="white"
                      stroke-width="3"/>
                  </div>
                </base-image-overlay>
              </slot>
            </template>
          </base-checkbox>
          <slot
            :option="option"
            name="footer"/>
        </div>
      </div>
    </div>

    <!-- Custom Checkboxes -->
    <div v-else-if="custom">
      <base-errors :validations="validations"/>
      <div
        class="row"
        :class="rowClass">
        <div
          v-for="option in parsedOptions"
          :key="option.id"
          :class="colClass">
          <base-checkbox
            v-model="internalValue"
            :input-value="option.value"
            custom>
            <slot :option="option"/>
          </base-checkbox>
          <slot
            :option="option"
            name="footer"/>
        </div>
      </div>
    </div>

    <!-- Button Checkboxes -->
    <div v-else-if="buttons">
      <div
        class="row"
        :class="rowClass">
        <div
          v-for="option in parsedOptions"
          :key="option.id"
          :class="colClass">
          <base-checkbox
            v-model="internalValue"
            :disabled="disableUnselected && !internalValue.includes(option.value)"
            :input-value="option.value"
            :text="option.text"
            button/>
        </div>
      </div>
      <div class="text-center pb-1">
        <base-errors :validations="validations"/>
      </div>
    </div>

    <!-- Base Checkboxes -->
    <div v-else>
      <div
        class="row"
        :class="rowClass">
        <base-checkbox
          v-for="option in parsedOptions"
          :key="option.id"
          v-model="internalValue"
          :class="colClass"
          :input-value="option.value"
          :text="option.text"/>
      </div>
      <div class="text-center">
        <base-errors :validations="validations"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from './BaseCheckbox.vue'
import BaseErrors from './BaseErrors.vue'
import BaseImageOverlay from '@/components/global/BaseImageOverlay.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    BaseCheckbox,
    BaseErrors,
    BaseImageOverlay,
    SvgCheck
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    buttons: {
      type: Boolean,
      default: false
    },
    image: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: false
    },
    colClass: {
      type: String,
      default: 'col'
    },
    disableUnselected: {
      type: Boolean,
      default: false
    },
    rowClass: {
      type: String,
      default: ''
    },
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    }
  },
  computed: {
    parsedOptions () {
      if (typeof this.options[0] === 'string') {
        return this.options.map(x => {
          return { text: x, value: x }
        })
      }
      return this.options
    },
    internalValue: {
      get () { return this.value },
      set (v) { this.$emit('input', v) }
    }
  }
}
</script>
