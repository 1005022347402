<template>
  <div
    v-if="!styleProfileLoading && !styleProfileOptionsLoading"
    class="row">
    <div class="col-md-8 p-0 mx-auto mb-5 pb-5">
      <h2 class="pt-md-5 pt-2 text-center px-2 px-md-0">
        Style Profile
      </h2>
      <div class="section-header mt-md-4">
        <h4 class="mb-0">
          About You
        </h4>
      </div>
      <style-profile-birthday
        :expanded="expandedSection === 'birthday'"
        class="border-top-0"
        @accordion-click="e => expandedSection = e ? 'birthday': null "/>
      <style-profile-bra-size
        :expanded="expandedSection === 'bra-size'"
        class="border-top-0"
        @accordion-click="e => expandedSection = e ? 'bra-size': null "/>
      <style-profile-height
        :expanded="expandedSection === 'height'"
        class="border-top-0"
        @accordion-click="e => expandedSection = e ? 'height': null "/>
      <style-profile-maternity-details
        v-if="showMaternityDetails"
        class="border-top-0"
        :validations="v$"
        :show-due-date-picker="showDueDatePicker"
        :expanded="expandedSection === 'maternity'"
        @accordion-click="e => expandedSection = e ? 'maternity': null "/>
      <style-profile-clothing-sizes
        :validations="v$"
        :expanded-section="expandedSection"
        @accordion-click="e => expandedSection = e"/>
    </div>
    <div class="footer-button">
      <sequin-button
        :disabled="formDisabled"
        @click="submit">
        Save Changes
      </sequin-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { vuexAccessors } from '../global/helpers/vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { dDay, minSelected } from '../global/vuelidate/customValidators'
import SequinButton from '../global/sequin/SequinButton'
import StyleProfileBirthday from './StyleProfileBirthday'
import StyleProfileBraSize from './StyleProfileBraSize'
import StyleProfileHeight from './StyleProfileHeight'
import StyleProfileClothingSizes from './StyleProfileClothingSizes'
import StyleProfileMaternityDetails from './StyleProfileMaternityDetails.vue'
import goBack from '../global/mixins/goBack'

export default {
  components: {
    SequinButton,
    StyleProfileBirthday,
    StyleProfileBraSize,
    StyleProfileHeight,
    StyleProfileClothingSizes,
    StyleProfileMaternityDetails
  },
  mixins: [goBack],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      expandedSection: null,
      showDueDatePicker: false
    }
  },
  computed: {
    ...mapState('client', [
      'styleProfileComplete'
    ]),
    ...mapState('styleProfile', [
      'styleProfileLoading',
      'styleProfileOptionsLoading',
      'plusSizeOptions'
    ]),
    ...mapGetters('styleProfile', [
      'numberToLetterSizeOptions'
    ]),
    ...vuexAccessors('styleProfile', [
      'birthDate',
      'braSizeBand',
      'braSizeCup',
      'bustSizeInches',
      'dressSizes',
      'dueDate',
      'pantSizes',
      'shirtSizes',
      'waistSizes',
      'jumpsuitSizes'
    ]),
    showMaternityDetails () {
      const allSizes = [...this.shirtSizes, ...this.dressSizes, ...this.jumpsuitSizes]
      const standardSizes = this.numberToLetterSizeOptions(false).map(size => size.value)
      return allSizes.some(size => standardSizes.includes(size))
    },
    formDisabled () {
      return this.v$.$invalid
    }
  },
  watch: {
    showDueDatePicker (newValue) {
      if (!newValue) {
        this.$store.commit('styleProfile/SET_DUE_DATE', null)
      }
    }
  },
  async created () {
    this.showDueDatePicker = this.dueDate !== null
  },
  methods: {
    ...mapActions('styleProfile', [
      'patchStyleProfile'
    ]),
    ...mapActions('closet', [
      'reloadSections'
    ]),
    async submit () {
      await this.patchStyleProfile()
      await this.reloadSections()
      this.goBack()
    }
  },
  validations () {
    return {
      braSizeBand: {
        required
      },
      braSizeCup: {
        required
      },
      dressSizes: {
        minSelected: minSelected(1)
      },
      dueDate: { dDay },
      waistSizes: {
        minSelected: minSelected(1)
      },
      jumpsuitSizes: {
        minSelected: minSelected(1)
      },
      shirtSizes: {
        minSelected: minSelected(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-header {
  width: 100%;
  background-color: $origami;
  padding: 16px 16px;
  border: $default-border;
  border-top-left-radius: $sequin-border-radius;
  border-top-right-radius: $sequin-border-radius;
}
:deep(.checkbox-text) {
  color: #520845 !important;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 0 16px 0 0;
}

.footer-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: $default-border;
  background-color: $white;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
