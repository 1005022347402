<template>
  <div class="d-flex align-items-center border-top p-3">
    <div
      @click="onAvatarClick()">
      <avatar-image
        :profile-pic="client.profilePic"
        :client-id="client.id"
        size="large"
        class="pointer"/>
    </div>
    <div
      class="ms-3 d-flex justify-content-between align-items-center w-100">
      <div>
        <div>
          <b>{{ client.name }}</b>
        </div>
        <div
          v-if="isFollowing(client.id) && !isOwnProfile"
          class="text-pewter small-text">
          Following
        </div>
      </div>
      <sequin-button
        v-if="showFollowButton"
        class="me-3"
        variant="secondary"
        @click="followClient(client.id)">
        Follow
      </sequin-button>
    </div>
  </div>
</template>

<script>
import AvatarImage from '../common/AvatarImage'
import inViewport from 'vue-in-viewport-mixin'
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    AvatarImage,
    SequinButton
  },
  mixins: [inViewport],
  props: {
    client: {
      type: Object,
      required: true
    },
    isOwnProfile: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      hasBeenVisible: false
    }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...mapGetters('community', [
      'isFollowing'
    ]),
    showFollowButton () {
      if (this.isFollowing(this.client.id)) {
        return false
      }
      if (this.client.id === this.id) {
        return false
      }
      return true
    }
  },
  watch: {
    'inViewport.now': function (visible) {
      if (visible && !this.hasBeenVisible) {
        this.$emit('follow-in-view')
        this.hasBeenVisible = true
      }
    }
  },
  methods: {
    ...mapActions('community', [
      'followClient'
    ]),
    onAvatarClick () {
      this.$router.push({ name: 'profile', params: { id: this.client.id } })
    }
  }
}

</script>
