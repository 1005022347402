import { mapState } from 'vuex'

export default {
  data () {
    return {
      // Number of loading dress images we have in s3 bucket
      numDressImages: 50
    }
  },
  computed: {
    ...mapState('global', [
      'randomFactor'
    ])
  },
  methods: {
    getDressUrlFromClientId (id) {
      return this.getDressImageUrl(id.charCodeAt(1) * this.randomFactor % this.numDressImages + 1)
    },
    getRandomDressId () {
      return Math.round(Math.random() * (this.numDressImages - 1)) + 1
    },
    getRandomDressImageUrl () {
      return this.getDressImageUrl(this.getRandomDressId())
    },
    getDressImageUrl (dressId) {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}dresses/${dressId}.png`
    }
  }
}
