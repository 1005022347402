<template>
  <SignUpScreen
    heading="What events are you dressing for this month?"
    subheading="Select all that apply.">
    <div class="text-center">
      <div class="pill-container gap-sm mx-auto d-flex flex-wrap justify-content-center">
        <SignUpPill
          v-for="pill in styleProfile.eventsOptions"
          :key="pill.value"
          v-model="eventSelection"
          :text="pill.text"
          type="checkbox"
          :value="pill.value"/>
      </div>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import SignUpPill from '@/components/signUp/SignUpPill.vue'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { computed } from 'vue'

const styleProfile = useStyleProfileStore()

const eventSelection = computed({
  get () {
    return styleProfile.signupEvents
  },
  set (value) {
    styleProfile.setSignupEvents(value)
  }
})
</script>

<style lang="scss" scoped>
.pill-container {
  max-width: 600px;
}
</style>
