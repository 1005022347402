<template>
  <div
    v-if="images.length === 0"
    class="add-photo-cta">
    <img
      class="icon"
      :src="getIconUrl()">
    <p class="required w-100 mt-3">
      {{ cta }}
    </p>
  </div>
  <div
    v-else
    class="mx-auto text-center">
    <img
      class="upload-image"
      :src="images[0]">
  </div>
</template>

<script>
export default {
  props: {
    cta: {
      type: String,
      required: true
    },
    images: {
      type: Array,
      required: true
    }
  },
  methods: {
    getIconUrl () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}image-required-icon.png`
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-image {
  height: 30vh;
  width: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.required {
  color: $plum;
  font-weight: $font-weight-normal;
}

.add-photo-cta {
  text-align: center;
  height: 30vh;
  width: 100%;
  background-color: $white;
  border: .5px solid $ash;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .icon {
    height: 54px;
  }
}
</style>
