+<template>
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Link to Looks</title>
    <path
      d="M23 1H3C1.89543 1 1 1.89543 1 3V24C1 25.1046 1.89543 26 3 26H23C24.1046 26 25 25.1046 25 24V3C25 1.89543 24.1046 1 23 1Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M10 4H21"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M1 10H25"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M10 6H16"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <circle
      cx="5.5"
      cy="5.5"
      r="1.9"
      stroke="currentColor"
      stroke-width="1.5"/>
  </svg>
</template>
