<template>
  <div class="btn-group flex-row pt-2 pb-3 scale-container">
    <div class="scale-axis"/>
    <div
      v-for="(option, index) in options"
      :key="option.text"
      class="d-flex flex-column align-items-center">
      <div
        class="radio-wrapper">
        <input
          :id="option.text"
          v-model="internalValue"
          :value="option.value"
          :name="name"
          type="radio">
        <span class="radio-button"/>
        <span class="radio-check"/>
      </div>
      <label
        v-if="showAllOptionLabels || index % 2 === 0"
        :for="`${name}-${option.text}`"
        class="label small-text">
        {{ option.text }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      validator: v => typeof v === 'number' || typeof v === 'string' || v === null || v === undefined
    },
    options: {
      type: Array,
      required: true
    },
    showAllOptionLabels: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    internalValue: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .label {
    position: absolute;
    bottom: -20px;
  }

  .scale-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .scale-axis {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $ash;
  }

.radio-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 16px;
  width: 16px;

  input[type=radio] {
    opacity: 0;
    position: absolute;
    height: 16px;
    width: 16px;
    cursor: pointer;
    z-index: 2;
  }

  .radio-button {
    position: absolute;
    left: 0;
    top: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid $ash;
    background-color: $origami;
  }
}

.radio-check {
  position: absolute;
  border: 1px solid $white;
  display: block;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

  .radio-wrapper input:checked ~ .radio-button {
    border-color: $plum;
    background-color: $plum;
  }
</style>
