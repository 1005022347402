<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="`0 0 ${width} ${height}`"
    :width="width"
    :height="height"
    :transform="`scale(${scaleFactor})`"
    :x="x"
    :y="y">
    <g>
      <polygon
        :transform="`scale(${scaleFactor})`"
        :fill="fill"
        points="17.74,3.05 15.98,5.04 15.98,10.33 12.02,11.93 12,11.94 11.98,11.93 8.02,10.33 8.02,5.04 6.05,3.05
7.33,1 9.63,1 12.02,4.02 13.98,1 16.28,1 "/>
      <polygon
        :transform="`scale(${scaleFactor})`"
        :fill="fill"
        points="12,11.94 11.98,11.95 11.98,11.93 "/>
      <polygon
        :transform="`scale(${scaleFactor})`"
        :fill="fill"
        points="21.87,20.03 16.14,23 7.86,23 7.81,22.98 2.13,20.03 7.86,10.66 12,12.38 16.14,10.66 "/>
      <polygon
        :transform="`scale(${scaleFactor})`"
        :fill="fill"
        points="12.02,11.93 12.02,11.95 12,11.94 "/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgOrigamiDress',
  props: {
    fill: {
      type: String,
      default: 'white'
    },
    height: {
      type: Number,
      default: 24
    },
    width: {
      type: Number,
      default: 24
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    }
  },
  computed: {
    scaleFactor () {
      return Math.min(this.height, this.width) / 24
    }
  }
}
</script>
