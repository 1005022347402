<template>
  <div class="d-flex align-items-center">
    <profile-header
      :client="feedItem.client"
      :is-announcement="isAnnouncement"
      :tags="!isCollectionShare ? tags : []">
      <template #name>
        <span v-if="rentedItems">
          rented {{ rentedItems }} item{{ rentedItems > 1 ? 's' : '' }}
          <br>
          <span class="text-pewter small-text"> {{ formatPrice(retailValue, true, true) }} value</span>
        </span>
        <span v-if="isCollectionShare">
          shared a Collection
        </span>
      </template>
      <template #content>
        <div
          v-if="feedItem.client.id === id && isEditable">
          <div v-if="isLook">
            <plain-button
              v-if="isMobile"
              class="p-0"
              @click="onMoreClicked">
              <svg-more/>
            </plain-button>
            <edit-delete-look-dropdown
              v-else
              @more-click="onMoreClicked"/>
          </div>
          <div v-else>
            <plain-button
              v-if="isMobile"
              class="p-0"
              @click="onMoreGenericClicked">
              <svg-more/>
            </plain-button>
            <delete-post-dropdown
              v-else
              @more-click="onMoreGenericClicked"/>
          </div>
        </div>
      </template>
    </profile-header>
  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader'
import PlainButton from '../../global/sequin/PlainButton'
import { formatPrice } from '@/utils/stringParsing.js'
import DeletePostDropdown from './update/DeletePostDropdown'
import EditDeleteLookDropdown from './update/EditDeleteLookDropdown'
import { mapState, mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgMore from '@/components/global/svg/SvgMore.vue'

export default {
  components: {
    DeletePostDropdown,
    EditDeleteLookDropdown,
    PlainButton,
    ProfileHeader,
    SvgMore
  },
  props: {
    feedItem: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...mapGetters('community', [
      'feedItemMetadata'
    ]),
    metadata () {
      return this.feedItemMetadata(this.feedItem)
    },
    isCollectionShare () {
      return this.metadata.type === 'collection'
    },
    rentedItems () {
      return this.metadata.type === 'case-rented' ? this.metadata.itemsCount : ''
    },
    retailValue () {
      return this.metadata.type === 'case-rented' ? this.metadata.retailValue : ''
    },
    tags () {
      return this.feedItem.tags
    },
    isEditable () {
      return !this.isAnnouncement
    },
    isLook () {
      return !this.feedItem.item || this.feedItem.item.type === 'look'
    },
    isAnnouncement () {
      return this.feedItem.item && this.feedItem.item.type === 'announcement'
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    formatPrice,
    onMoreGenericClicked () {
      this.$store.commit('community/SET_ITEM_IN_FOCUS', { type: 'post', item: this.feedItem })
      if (this.isMobile) {
        this.openModal({ name: 'edit-delete-menu' })
      }
    },
    onMoreClicked () {
      let feedItemId; let lookId = null

      if (this.feedItem.item) {
        feedItemId = this.feedItem.id
        lookId = this.feedItem.item.id
      } else {
        lookId = this.feedItem.id
      }

      this.$store.commit('community/SET_ITEM_IN_FOCUS', { type: 'look', feedItemId, lookId, item: this.feedItem })

      if (this.isMobile) {
        this.openModal({ name: 'edit-delete-menu' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
