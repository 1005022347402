<!--
Clickable preview image for a style color with Quick Actions.
PlainButton props can be directly passed to this component.
-->
<template>
  <PlainButton
    class="preview-image-button"
    @click.exact="emit('click', $event)">
    <PreviewImage
      :src="src"
      :size="size"
      :alt="alt"
      :eager-load="eagerLoad"
      @visible="emit('visible', $event)"
      @loaded="emit('loaded', $event)">
      <template
        v-if="$slots.overlay"
        #overlay>
        <slot name="overlay"/>
      </template>
    </PreviewImage>
  </PlainButton>
</template>

<script setup>
import PreviewImage from './PreviewImage.vue'
import PlainButton from '@/components/global/sequin/PlainButton.vue' // TODO: ENG-3647 - Isolate shared code.

defineProps({
  src: {
    type: String,
    default: null
  },
  size: {
    type: String,
    default: 'auto',
    validator: value => ['auto', 'xx-large', 'x-large', 'large', 'medium', 'small', 'tiny'].includes(value)
  },
  alt: {
    type: String,
    default: null
  },
  eagerLoad: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits({
  visible: (value) => typeof value === 'boolean',
  loaded: (event) => event instanceof Event,
  click: (event) => event instanceof MouseEvent
})
</script>

<style lang="scss" scoped>
@import './PreviewImageButton.scss';
</style>
