<template>
  <SignUpScreen
    heading="Get the hard no's out of the way"
    subheading="Select all items we should try to avoid showing.">
    <div class="text-center">
      <div class="pill-container gap-sm">
        <SignUpPill
          v-for="pill in styleProfile.avoidsOptions"
          :key="pill.value"
          v-model="avoidsSelection"
          :text="pill.text"
          type="checkbox"
          :value="pill.value"/>
      </div>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import SignUpPill from '@/components/signUp/SignUpPill.vue'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { computed } from 'vue'

const styleProfile = useStyleProfileStore()
const avoidsSelection = computed({
  get () {
    return styleProfile.avoids
  },
  set (value) {
    styleProfile.commit('SET_AVOIDS', value)
  }
})
</script>

<style lang="scss" scoped>
.pill-container {
  max-width: 680px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
