<template>
  <div class="d-flex">
    <div>
      <p
        v-if="showLabels"
        class="mb-0 small-text">
        Band
      </p>
      <dropdown-single-select
        v-model="braSizeBand"
        small
        class="bra-dropdown"
        placeholder="Band"
        :initial-selection-index="getInitialSelectionIndex('braSizeBand')"
        :validations="v$.braSizeBand"
        :dropdown-options="braSizeBandOptions"/>
    </div>
    <div>
      <p
        v-if="showLabels"
        class="mb-0 small-text">
        Cup
      </p>
      <dropdown-single-select
        v-model="braSizeCup"
        small
        class="bra-dropdown"
        placeholder="Cup"
        :initial-selection-index="getInitialSelectionIndex('braSizeCup')"
        :validations="v$.braSizeCup"
        :dropdown-options="braSizeCupOptions"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import DropdownSingleSelect from '../global/sequin/DropdownSingleSelect'
import { vuexAccessors } from '../global/helpers/vuex'

export default {
  components: {
    DropdownSingleSelect
  },
  props: {
    showLabels: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      startAnimation: false
    }
  },
  computed: {
    ...mapState('styleProfile', [
      'braSizeBandOptions',
      'braSizeCupOptions'
    ]),
    ...vuexAccessors('styleProfile', [
      'braSizeBand',
      'braSizeCup'
    ])
  },
  methods: {
    getInitialSelectionIndex (type) {
      const optionType = `${type}Options`
      if (this[type] && this[optionType]) {
        const index = this[optionType].findIndex(option => option.value === this[type])
        if (index > -1) return index
      }

      return null
    }
  },
  validations () {
    return {
      braSizeBand: {
        required
      },
      braSizeCup: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bra-dropdown {
  min-width: unset;
  width: 88px;
}
</style>
