<template>
  <div class="explore-container text-center pt-4 pb-5">
    <h2
      class="text-white text-center mb-4">
      Explore
    </h2>
    <div class="content-container col-8 offset-2">
      <div class="header-line">
        <p
          class="text-white semi-bold text-uppercase mb-3">
          Occasions
        </p>
      </div>
      <div :class="isTabletOrLarger ? 'list-container' :'list-container-mobile'">
        <div
          v-for="occasion in occasions"
          :key="occasion.id"
          class="occasion text-start">
          <TextLink
            class="d-block text-wrap text-white mb-3 text-button"
            variant="tertiary"
            size="paragraph"
            type="router-link"
            :name="`Browse ${occasion.text}` "
            :underline="false"
            :to="{ name: 'browse', params: { section: 'occasions', subsection: occasion.value} }">
            {{ occasion.text }}
          </TextLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import useScreenSize from '@shared/composables/screenSize.js'
import TextLink from '../global/sequin/TextLink.vue'

const { isTabletOrLarger } = useScreenSize()

const store = useStore()
const occasions = computed(() => {
  return store.state.closet.globalFilters.options?.occasions.map(x => {
    return { text: x.text, value: x.value.replace('occasion_', '') }
  })
})
</script>

<style lang="scss" scoped>
.explore-container {
  background-color: $armor;
}

.list-container {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  &-mobile {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
}
.header-line {
    display: flex;
    align-items: center;
    width: 100%;
  }

.header-line::after {
  content: '';
  flex-grow: 1;
  border-top: .5px solid white;
  margin-left: 10px;
  margin-bottom: 15px;
}
.occasion {
  // to-do: convert text links to buttons
  * {
    font-weight: $font-weight-semibold !important;
  }
}
</style>
