import { computed, toValue, watch } from 'vue'
import { useStore } from 'vuex'

export default function useCollections (clientId = null) {
  const store = useStore()
  if (clientId === null) {
    clientId = computed(() => store.state.client.id)
  }
  const clientCollections = computed(() => store.getters['collections/collectionsFromClientId'](toValue(clientId)))

  const collections = computed(() => clientCollections.value ?? [])
  const collectionIds = computed(() => collections.value.map(collection => collection.id))
  // TODO: update this to null and create a component that shows a text placeholder until it loads.
  const routeLocation = computed(() => {
    return toValue(clientId)
      ? { name: 'client-collections', params: { clientId: toValue(clientId) } }
      : { path: '#' }
  })

  async function createCollection (name, options = {}) {
    return await store.dispatch('collections/createCollection', {
      name,
      tags: [],
      styleColors: [],
      private: false,
      ...options
    })
  }

  watch(() => toValue(clientId), async (clientId) => {
    if (clientId && !clientCollections.value) {
      await store.dispatch('collections/getCollections', { clientId })
    }
  }, { immediate: true })

  return {
    clientCollections,
    collections,
    collectionIds,
    routeLocation,
    createCollection
  }
}
