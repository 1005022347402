<template>
  <component
    :is="selectable ? 'PlainButton' : 'div'"
    :class="{ 'unselectable': !selectable, 'selected': selected, 'desktop': !isMobile }"
    class="review-item"
    @click="$emit('selected', packageItem)">
    <review-item-card
      compact
      :include-belt-message="includeBeltMessage"
      :selected="selected"
      :image-source="imageSource"
      :item-style="itemStyle"
      :size="size"/>
  </component>
</template>

<script>
import PlainButton from '../global/sequin/PlainButton'
import { mapState } from 'vuex'
import ReviewItemCard from './ReviewItemCard'

export default {
  name: 'ReviewPackageItemCard',
  components: { ReviewItemCard, PlainButton },
  props: {
    packageItem: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    includeBeltMessage: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    itemStyle () {
      return this.styleColorsMap[this.packageItem.item.styleColor].style
    },
    size () {
      return this.packageItem.item.trueSize
    },
    imageSource () {
      return this.styleColorsMap[this.packageItem.item.styleColor].images.front.url
    }
  }
}
</script>

<style lang="scss" scoped>
  .selected {
    background-color: $origami;
  }

  .unselectable {
    padding: 12px 24px;
  }

  .overlay-selected {
    width: 100%;
    height: 100%;
    display: flex;
    margin: -2px 0 0 -2px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba($armor, .15);
  }

  .review-item {
    border-bottom: $default-border;
    width: 100%;
    position: relative;

    &.desktop {
      border: $default-border;
    }
  }
</style>
