<template>
  <router-link
    v-if="type === 'router-link'"
    ref="self"
    :to="to"
    class="button"
    :disabled="disabled"
    :name="name"
    :class="{'block': block || mobileBlock && isMobile, 'disabled': useDisabledStyling && disabled }"
    @click="onClick">
    <slot/>
  </router-link>
  <component
    :is="type"
    v-else
    ref="self"
    class="button"
    :disabled="disabled"
    :class="{'block': block || mobileBlock && isMobile, 'disabled': useDisabledStyling && disabled }"
    :href="href"
    :name="name"
    :target="type === 'a' ? '_blank' : '_self'"
    @click="onClick">
    <slot/>
  </component>
</template>

<script>
export default {
  name: 'PlainButton',
  props: {
    // The underlying component type: button, a, or router-link
    type: {
      type: String,
      default: 'button',
      validator: value => {
        return value.match(/(button|a|router-link|)/)
      }
    },
    // The link/button name reference regardless of type
    name: {
      type: String,
      default: null
    },
    // Pass for full width button
    block: {
      type: Boolean,
      default: false
    },
    mobileBlock: {
      type: Boolean,
      default: false
    },
    // For links, the href
    href: {
      type: String,
      default: null
    },
    // For router-links, the destination
    to: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    useDisabledStyling: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClick (e) {
      if (this.type !== 'router-link') {
        this.$refs.self.blur()
      } else {
        if (this.$refs.self && this.$refs.self.$el) {
          this.$refs.self.$el.blur()
        }
      }

      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  background-color: inherit;
  color: $armor;
  border: none;
  border-radius: $sequin-border-radius;
  padding: 12px 24px;
  height: fit-content;
  cursor: pointer;
  display: inline-block;
  outline: none;
  text-align: inherit;
  text-decoration: none;
  @include transition-base-all;

  &.block {
    width: 100%;
  }

  &.disabled {
    opacity: .6;
    cursor: default;
    pointer-events: none;
  }
}
</style>
