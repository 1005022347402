<template>
  <div
    :class="['plan-card position-relative pt-sm', { selected, selectable }]"
    @click="onClick">
    <h6 class="tag-line text-nowrap position-absolute m-0 px-xs">
      {{ plan.tagLine }}
    </h6>
    <div class="details px-lg py-md d-flex flex-column align-items-center">
      <h4 class="m-0">
        {{ plan.displayName }}
      </h4>
      <div class="separator my-sm"/>
      <template v-if="promoPrice !== basePrice">
        <p class="semi-bold color-plum">
          {{ `${promoPriceFormatted} first month` }}
        </p>
        <p class="small-text color-plum">
          ({{ basePriceFormatted }}/month after)
        </p>
      </template>
      <p
        v-else
        class="semi-bold color-plum">
        {{ `${basePriceFormatted}/month` }}
      </p>
      <p>
        {{ `Rent ${plan.nItems} item${plan.nItems > 1 ? 's' : ''} at a time.` }}
      </p>
      <p v-if="plan.shipmentsPerInterval">
        Swap every 30 days.
      </p>
      <p v-else>
        <b>Unlimited</b> Swaps.
      </p>
      <p>
        Cancel anytime.
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { formatPrice } from '@/utils/stringParsing.js'
import { useSubscriptionStore } from '@/stores/subscribe.js'
import { storeToRefs } from 'pinia'

const props = defineProps({
  planId: {
    type: String,
    required: true
  },
  selectable: {
    type: Boolean,
    default: true
  }
})

const subscriptionStore = useSubscriptionStore()
const { selectedPlanId, membershipPlans, discountPriceFromPlan } = storeToRefs(subscriptionStore)
const { selectMembershipPlan } = subscriptionStore

const selected = computed(() => props.planId === selectedPlanId.value)
const plan = computed(() => membershipPlans.value.find(plan => plan.id === props.planId))
const basePrice = computed(() => plan.value.basePrice / 100.0)
const promoPrice = computed(() => discountPriceFromPlan.value(plan.value))
const basePriceFormatted = computed(() => formatPrice(basePrice.value, true, true))
const promoPriceFormatted = computed(() => formatPrice(promoPrice.value, true, true))

const emit = defineEmits(['click'])
function onClick () {
  if (props.selectable) {
    selectMembershipPlan({ planId: props.planId, trackEvent: true })
  }
  emit('click', props.planId)
}
</script>

<style scoped lang="scss">
.plan-card {
  min-width: 300px;
  user-select: none;

  .tag-line {
    border-radius: 2px;
    background-color: $pewter;
    color: $white;
    top: 1px;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .details {
    background-color: $white;
    border: 1px solid $pewter;
    border-radius: 2px;

    .separator {
      width: 32px;
      height: 1px;
      background-color: $ash;
    }
  }

  &.selected {
    .tag-line {
      background-color: $orchid;
      top: 3px;
    }
    .details {
      border: 4px solid $orchid;
    }
  }

  &.selectable {
    cursor: pointer;
  }
}
</style>
