<template>
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8 2.6H2.6A1.6 1.6 0 001 4.2v11.2A1.6 1.6 0 002.6 17h11.2a1.6 1.6 0 001.6-1.6V4.2a1.6 1.6 0 00-1.6-1.6zM11.4 1v3.2M5 1v3.2M1 7.4h14.4"
      stroke="currentColor"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>
