export default {
  data () {
    return {
      appleAppStoreLink: 'https://apps.apple.com/us/app/armoire-style/id1560230748',
      googlePlayStoreLink: 'https://play.google.com/store/apps/details?id=style.armoire.armoiremobile'
    }
  },
  computed: {
    appleAppStoreIcon () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}app-store.png`
    },
    googlePlayStoreIcon () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}google-play.png`
    }
  },
  methods: {
    getPlatformSpecificIcon (platform) {
      return platform === 'ios' ? this.appleAppStoreIcon : this.googlePlayStoreIcon
    },
    getPlatformSpecificLink (platform) {
      return platform === 'ios' ? this.appleAppStoreLink : this.googlePlayStoreLink
    },
    getPlatformSpecificAltText (platform) {
      return platform === 'ios' ? 'Download on the App Store' : 'Get it on Google Play'
    }
  }
}
