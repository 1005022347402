<template>
  <div
    ref="scroll-wrapper"
    class="scroll-wrapper">
    <div
      v-if="scrollButtonLocation === 'top'"
      class="top-arrows d-flex justify-content-end align-items-end container-xxl-wrapper">
      <h4
        v-if="title"
        class="title">
        {{ title }}
      </h4>
      <div
        v-if="showArrowsAboveScroll"
        class="d-flex">
        <div
          :class="scrollButtonSize"
          class="me-2">
          <plain-button
            class="scroll-button-top"
            aria-label="Scroll left"
            @click="scrollLeft">
            <svg-chevron-left
              :width="scrollButtonWidth"
              :height="scrollButtonHeight"
              stroke-width="1"
              class="scroll-chevron"/>
          </plain-button>
        </div>
        <div
          :class="scrollButtonSize">
          <plain-button
            class="scroll-button-top"
            aria-label="Scroll right"
            @click="scrollRight">
            <svg-chevron-right
              :width="scrollButtonWidth"
              :height="scrollButtonHeight"
              stroke-width="1"
              class="scroll-chevron"/>
          </plain-button>
        </div>
      </div>
    </div>
    <!-- below is arrows on the side layout -->
    <div
      ref="horizontalScroll"
      class="horizontal-scroll"
      :class="alignItems"
      @scroll="handleScroll">
      <slot ref="slot"/>
    </div>
    <div
      v-if="showLeftArrow && scrollButtonLocation === 'sides'"
      :class="scrollButtonSize"
      class="scroll-button-wrapper left">
      <plain-button
        class="scroll-button"
        aria-label="Scroll left"
        @click="scrollLeft">
        <svg-chevron-left
          :width="scrollButtonWidth"
          :height="scrollButtonHeight"
          stroke-width="1"
          class="scroll-chevron"/>
      </plain-button>
    </div>
    <div
      v-if="showRightArrow && scrollButtonLocation === 'sides'"
      :class="scrollButtonSize"
      class="scroll-button-wrapper right">
      <plain-button
        class="scroll-button"
        aria-label="Scroll right"
        @click="scrollRight">
        <svg-chevron-right
          :width="scrollButtonWidth"
          :height="scrollButtonHeight"
          stroke-width="1"
          class="scroll-chevron"/>
      </plain-button>
    </div>
  </div>
</template>

<script>
import PlainButton from './PlainButton'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'

export default {
  components: {
    PlainButton,
    SvgChevronLeft,
    SvgChevronRight
  },
  props: {
    scrollButtonLocation: {
      type: String,
      default: 'sides',
      validator: value => ['sides', 'top'].includes(value)
    },
    scrollButtonSize: {
      type: String,
      default: 'medium',
      validator: value => {
        return value.match(/(medium|small)/)
      }
    },
    hideDesktopScrollButtons: {
      type: Boolean,
      default: false
    },
    alignItems: {
      type: String,
      default: 'start',
      validator: value => value.match(/(start|center|end)/)
    },
    title: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      scrollPosition: 0,
      scrollAreaLeft: false,
      scrollAreaRight: false
    }
  },
  computed: {
    showArrowsAboveScroll () {
      return this.scrollButtonLocation === 'top' && !this.isMobile && !this.hideDesktopScrollButtons
    },
    showLeftArrow () {
      return this.scrollAreaLeft && !this.isMobile && !this.hideDesktopScrollButtons
    },
    showRightArrow () {
      return this.scrollAreaRight && !this.isMobile && !this.hideDesktopScrollButtons
    },
    scrollButtonWidth () {
      return this.scrollButtonSize === 'small' ? '24' : '32'
    },
    scrollButtonHeight () {
      return this.scrollButtonSize === 'small' ? '32' : '36'
    },
    scrollOffset () {
      return this.scrollButtonSize === 'small' ? 52 : 68
    }

  },
  activated () {
    this.$refs.horizontalScroll.scrollLeft = this.scrollPosition
    this.checkScrollButtons()
  },
  mounted () {
    // This is a hack, but there is no good way to make vue evaluate
    // appropriately when images might need to load within the container
    // Allow just a little bit of time for the contents to load
    this.$nextTick(() => {
      setTimeout(() => {
        this.checkScrollButtons()
      }, 250)
    })

    this.observer = new MutationObserver(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.checkScrollButtons()
        }, 250)
      })
    })

    // Setup the observer to observe any changes in the children
    // of horizontal scroll - this could result in changes to
    // needing to show the left and right arrows
    this.observer.observe(
      this.$refs.horizontalScroll,
      { childList: true, subtree: true }
    )
  },
  unmounted () {
    this.observer.disconnect()
  },
  methods: {
    scrollRight () {
      const horizontalScroll = this.$refs.horizontalScroll
      this.sideScroll(horizontalScroll, 'right', 10, horizontalScroll.clientWidth - this.scrollOffset, 10)
    },
    scrollLeft () {
      const horizontalScroll = this.$refs.horizontalScroll
      this.sideScroll(horizontalScroll, 'left', 10, horizontalScroll.clientWidth - this.scrollOffset, 10)
    },
    sideScroll (el, direction, speed, distance, step) {
      let scrollAmount = 0
      const maxScrollLeft = el.scrollWidth - el.clientWidth
      const distanceFinal = Math.min(distance, 600)

      const slideTimer = setInterval(() => {
        if (direction === 'left') {
          el.scrollLeft -= step
        } else {
          el.scrollLeft += step
        }
        scrollAmount += step
        if (
          scrollAmount >= distanceFinal ||
          el.scrollLeft >= maxScrollLeft ||
          el.scrollLeft <= 0) {
          window.clearInterval(slideTimer)
        }
        this.checkScrollButtons()
      }, speed)
    },
    handleScroll (event) {
      if (event && event.target) {
        this.scrollPosition = event.target.scrollLeft
      }
      this.checkScrollButtons()
    },
    checkScrollButtons () {
      const scrollArea = this.$refs.horizontalScroll
      if (!scrollArea) { return }
      if (scrollArea.scrollLeft <= 5) {
        this.scrollAreaLeft = false
      } else {
        this.scrollAreaLeft = true
      }

      if (scrollArea.clientWidth + scrollArea.scrollLeft >= scrollArea.scrollWidth - 5) {
        this.scrollAreaRight = false
      } else {
        this.scrollAreaRight = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  // to do: remove in typography update
.title {
  font-family: $headings-font-family;
  font-weight: 600;
  font-size: 20px;
  color: $armor;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: center;
  margin: 0 auto;
  @media (min-width: 720px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

}

.scroll-wrapper {
  position: relative;
  width: inherit;

  .scroll-button-wrapper {
    position: absolute;
    z-index: 10;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    &.left {
      left: -21px;

      &.small {
        left: -8px;
      }
    }

    &.right {
      right: -21px;

      &.small {
        right: -8px;
      }
    }

    .scroll-button {
      padding: 0;
      background: $white;
      color: $pewter;
      border: $default-border;
      border-width: 1px;
      position: absolute;
      left: inherit;
      right: inherit;

      .scroll-chevron {
        border-radius: 2px;
        padding: 4px;
        stroke-width: 1.5px;
      }
    }
  }

}

.top-arrows{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 28px;
  .scroll-button-top {
      padding: 0;
      background: $white;
      color: $pewter;
      border: $default-border;
      border-width: 1px;

      .scroll-chevron {
        border-radius: 2px;
        padding: 4px;
        stroke-width: 1.5px;
      }
}
}
.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  align-items: flex-start;
  @include hide-scrollbar;

  &.center {
    align-items: center;
  }

  &.end {
    align-items: flex-end;
  }
}
</style>
