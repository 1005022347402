import apiBrands from '../api/apiBrands'

export const state = {
  brandDetails: {}, // Brand ID to brand details object
  brandSections: {}, // Brand ID to section (SC array, filter options, applied filters, and length)
  sizeFilter: true
}

export const actions = {
  async getTopBrands ({ commit }, _query) {
    const res = await apiBrands.getTopBrands()
    commit('UPDATE_BRANDS', { data: res.data })
  },
  async getBrandDetails ({ commit }, brandId) {
    const res = await apiBrands.getBrand(brandId)
    commit('UPDATE_BRAND_DETAILS', { brandId, data: res.data })
  },
  initializeBrandSection ({ commit, rootState }, brandId) {
    commit('UPDATE_BRAND_SECTION', {
      brandId,
      styleColors: [],
      available: true,
      loading: true,
      filters: rootState.closet.baseFilters,
      filterOptions: null
    })
  },
  async getStyleColors ({ commit, state, dispatch }, { brandId, filters, available = true }) {
    dispatch('initializeBrandSection', brandId)
    const res = await apiBrands.getStyleColors({
      brandId,
      offset: 0,
      amount: 20,
      available,
      sizeFilter: state.sizeFilter,
      filters
    })
    const { styleColors, filterOptions, length, sourceType } = res.data
    const browseName = `Browse ${state.brandDetails[brandId].name}`
    commit('UPDATE_BRAND_SECTION', { brandId, name: browseName, styleColors, length, filters, filterOptions, available, sourceType, loading: false })
  },
  async getNextPage ({ commit, state }, brandId) {
    const section = state.brandSections[brandId]
    if (section.styleColors.length >= section.length || section.loading) {
      return
    }
    commit('UPDATE_BRAND_SECTION', { brandId, loading: true })
    const res = await apiBrands.getStyleColors({
      brandId,
      filters: section.filters,
      available: section.available,
      offset: section.styleColors.length,
      amount: 50,
      sizeFilter: state.sizeFilter
    })
    commit('UPDATE_BRAND_SECTION', {
      brandId,
      styleColors: [...state.brandSections[brandId].styleColors, ...res.data.styleColors],
      loading: false
    })
  },
  async filterStyleColors ({ commit, state }, brandId) {
    const section = state.brandSections[brandId]
    commit('UPDATE_BRAND_SECTION', { brandId, styleColors: [], loading: true })
    const res = await apiBrands.getStyleColors({
      brandId,
      filters: section.filters,
      available: section.available,
      offset: 0,
      amount: 20,
      sizeFilter: state.sizeFilter
    })
    const { styleColors, length } = res.data
    commit('UPDATE_BRAND_SECTION', { brandId, styleColors, length, loading: false })
  },
  updateFilters ({ commit, dispatch }, { brandId, filters }) {
    commit('UPDATE_BRAND_SECTION', { brandId, filters })
    dispatch('filterStyleColors', brandId)
  },
  updateAvailability ({ commit }, { brandId, state }) {
    commit('UPDATE_BRAND_SECTION', { brandId, available: state })
  },
  clearFilters ({ commit, dispatch, rootState }, brandId) {
    const filters = { ...rootState.closet.baseFilters }
    commit('UPDATE_BRAND_SECTION', {
      brandId,
      filters
    })
    dispatch('getStyleColors', { brandId, filters })
  },
  updateSizeFilters ({ commit, dispatch }, { brandId, sizeFilter, filters }) {
    commit('UPDATE_SIZE_FILTERS', { sizeFilter })
    dispatch('getStyleColors', { brandId, filters })
  }
}

export const mutations = {
  'UPDATE_BRAND_DETAILS' (state, { brandId, data }) {
    state.brandDetails[brandId] = data
  },
  'UPDATE_SIZE_FILTERS' (state, { sizeFilter }) {
    state.sizeFilter = sizeFilter
  },
  'UPDATE_BRANDS' (state, { data }) {
    Object.keys(data).forEach(key => {
      state.brandDetails[data[key].id] = data[key]
    })
  },
  'UPDATE_BRAND_SECTION' (state, { brandId, ...data }) {
    if (brandId in state.brandSections) {
      state.brandSections[brandId] = { ...state.brandSections[brandId], ...data }
    } else {
      state.brandSections[brandId] = data
    }
  }
}
