<template>
  <div class="d-flex container">
    <div>
      <preview-image
        :src="imageSource"
        :size="compact ? 'tiny' : 'small'">
        <template #overlay>
          <div
            v-if="selected"
            :class="{'overlay-selected': selected}">
            <svg-circle-icon
              :height="42"
              :width="42"
              :padding="12"
              inverse/>
          </div>
        </template>
      </preview-image>
    </div>
    <div class="ms-4">
      <div class="semi-bold">
        {{ brand }}
      </div>
      <div class="semi-bold">
        {{ itemStyle.subcategory }}
      </div>
      <div class="text-pewter mt-1">
        Size {{ size }}
      </div>
      <div
        v-if="itemStyle.hasBelt && includeBeltMessage">
        <div
          class="fine-print font-italic mt-1 mb-1 mb-sm-0">
          Don't forget to include this item's matching belt with your return
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import SvgCircleIcon from '../global/svg/SvgCircleIcon'

export default {
  name: 'ReviewItemCard',
  components: { SvgCircleIcon, PreviewImage },
  props: {
    imageSource: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    itemStyle: {
      type: Object,
      required: true
    },
    includeBeltMessage: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      required: true
    }
  },
  computed: {
    brand () {
      return this.itemStyle.brand
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    // ensures that the cards all have the same height even if the
    // belt text is present
    min-height: 105px;
  }

  .overlay-selected {
    width: 100%;
    height: 100%;
    display: flex;
    margin: -2px 0 0 -2px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba($armor, .15);
  }

  .review-item {
    border-bottom: $default-border;
    width: 100%;

    &.desktop {
      border: $default-border;
    }
  }
</style>
