<template>
  <div class="col col-12 mb-4 ps-0">
    <h3>
      You requested to cancel on {{ todayDate }}. Your membership will end on {{ scheduledCancelDate }}.
    </h3>
    <br>
    <div v-if="outstandingItems.length">
      <h4>
        You have {{ outstandingItems.length }} outstanding items that need to be returned.  What next?
      </h4>
      <div class="col col-12 text-md-start ps-md-0 my-3">
        1. Purchase the ones you want to keep. We've given you a <strong>$20 coupon</strong> to use! Redeem it at
        <text-link
          size="paragraph"
          variant="primary"
          type="router-link"
          name="Rewards"
          :to="{ name: 'rewards' }">
          My Rewards.
        </text-link>
      </div>
      <div class="col col-12 text-md-start ps-md-0 my-3">
        2. Drop the rest in the mail by {{ scheduledCancelDate }} to avoid additional charges.
      </div>
      <div>
        3. Once your returned items have been processed at our warehouse you’ll receive an email confirmation.
      </div>
      <br>
      <br>
    </div>
    <h4>
      If you change your mind about cancelling before {{ scheduledCancelDate }}, contact us at
      <a
        href="mailto:hi@armoire.style?subject=Undoing%20My%20Cancellation">
        hi@armoire.style.
      </a>
    </h4>
    <div class="col col-12 text-md-start ps-md-0 my-3">
      We hope we can help style you again in the future! You can reactivate your Armoire membership at any time.
    </div>
    <div class="col col-12 justify-content-center justify-content-md-start ps-md-0 my-3">
      <sequin-button
        v-if="!isMobile"
        variant="secondary"
        type="router-link"
        :to="{ name: 'account' }">
        My Account
      </sequin-button>
      <action-footer v-else>
        <template #button>
          <sequin-button
            variant="secondary"
            mobile-block
            type="router-link"
            :to="{ name: 'account' }">
            My Account
          </sequin-button>
        </template>
      </action-footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SequinButton from '../../global/sequin/SequinButton'
import ActionFooter from '../ActionFooter'
import { toLocaleDateString } from '@/utils/stringParsing.js'
import MembershipMixin from './MembershipMixin'
import TextLink from '../../global/sequin/TextLink'

export default {
  name: 'CancelConfirmation',
  components: { SequinButton, ActionFooter, TextLink },
  mixins: [MembershipMixin],
  computed: {
    ...mapGetters('closet', [
      'outstandingItems'
    ]),
    scheduledCancelDate () {
      if (this.membershipDetails && this.membershipDetails.scheduledCancelDate) {
        return toLocaleDateString(this.membershipDetails.scheduledCancelDate)
      }
      return null
    },
    todayDate () {
      return toLocaleDateString(new Date())
    }
  }
}
</script>

<style lang="scss" scoped>
  ol {
    list-style: none;
    padding-left: 0;
  }
  li:before {
    text-align: center;
    content: counter(li);
    font-family: Montserrat, Helvetica, sans-serif;
    font-size: 1.5em;
  }
  li {
    counter-increment: li;
    margin-bottom: 1em;
  }
</style>
