import apiGlobal from '../api/apiGlobal'
import { getSetters } from './helpers/shared'

export const state = {
  windowWidth: 0,
  windowHeight: 0,

  initialRoute: null,

  showAlert: false,
  alertStyle: 'alert-success',
  alertText: '',

  randomFactor: Math.round(Math.random() * 50)
}

export const DisplayConstants = {
  // Add more sizes as we need, but currently only care about
  // Bootstrap's medium breakpoint for mobile.
  MOBILE_WIDTH: 768,
  DESKTOP_WIDTH: 1024
}

export const getters = {
  isMobile: state => {
    if (!state.windowWidth) {
      // something is asking for the width before this has been initialized in app.vue
      return window.innerWidth < DisplayConstants.MOBILE_WIDTH
    }
    return state.windowWidth < DisplayConstants.MOBILE_WIDTH
  },
  viewAspectRatio: state => {
    return state.windowWidth / state.windowHeight
  },
  viewHeightUnit: state => {
    return (state.windowHeight * 0.01).toFixed(2)
  }
}

export const actions = {
  setAuthTokenHeader (context, authToken) {
    apiGlobal.setAuthTokenHeader(authToken)
  },
  setAlert ({ commit }, data) {
    commit('SET_ALERT_TEXT', data.msg)
    const style = 'alert-' + (data.style ? data.style : 'success')
    commit('SET_ALERT_STYLE', style)
    commit('SET_SHOW_ALERT', true)
  },
  clearAlert ({ commit }) {
    commit('SET_SHOW_ALERT', false)
  }
}

export const mutations = {
  ...getSetters(state)
}
