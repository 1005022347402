<template>
  <transition
    name="fade"
    mode="out-in">
    <div>
      <h4
        :class="'marketing text-center'">
        Be the Buyer
      </h4>
      <p
        class="subhead text-center">
        Vote on which styles our buying team should select for Armoire.
      </p>
      <be-the-buyer-outro
        v-if="ratingIndex === beTheBuyerStylesToRate.length"
        key="be-the-buyer-outro"/>
      <style-game-rate-style
        v-else
        key="rate-be-the-buyer-style"
        :current-index="ratingIndex"
        :num-styles-to-rate="beTheBuyerStylesToRate.length"
        :image-source="currentStyle.image"
        :lottie-animations="lottieAnimations"
        :brand="currentStyle.brand.name"
        :style-name="currentStyle.name"
        @style-rating="onRating">
        <template #rating-intro>
          <p class="small-text mb-1">
            Should we buy this
          </p>
          <h4
            class="style-brand-name">
            {{ currentStyle.brand.name }}
          </h4>
          <h5
            class="style-item-name">
            {{ currentStyle.name + '?' }}
          </h5>
        </template>
      </style-game-rate-style>
      <p
        v-if="ratingIndex !== beTheBuyerStylesToRate.length && ratingIndex !== -1"
        class="btb-footer">
        You may see items better suited for specific seasons as we buy for the season ahead.
      </p>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BeTheBuyerOutro from './BeTheBuyerOutro'
import beTheBuyerYes from '../lottieAnimations/beTheBuyerYes.json'
import beTheBuyerYesButton from '../lottieAnimations/beTheBuyerYesButton.json'
import beTheBuyerNo from '../lottieAnimations/beTheBuyerNo.json'
import beTheBuyerNoButton from '../lottieAnimations/beTheBuyerNoButton.json'
import StyleGameRateStyle from './StyleGameRateStyle.vue'
import { vuexAccessors } from '../global/helpers/vuex'
import { track } from '../../plugins/analytics'

export default {
  components: {
    BeTheBuyerOutro,
    StyleGameRateStyle
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setPrevPathName(from)
    })
  },
  data () {
    return {
      ratingIndex: 0,
      prevRoute: null
    }
  },
  computed: {
    ...mapState('styleGames', [
      'beTheBuyerStylesToRate'
    ]),
    ...vuexAccessors('styleGames', [
      'beTheBuyerStylesRatedIndex'
    ]),
    currentStyle () {
      return this.beTheBuyerStylesToRate[this.ratingIndex]
    },
    lottieAnimations () {
      return {
        yesImage: beTheBuyerYes,
        yesButton: beTheBuyerYesButton,
        noImage: beTheBuyerNo,
        noButton: beTheBuyerNoButton
      }
    }
  },
  watch: {
    ratingIndex () {
      if (this.currentStyle) {
        track('Viewed BTB Style', { styleColorId: this.currentStyle.id, index: this.ratingIndex + 1 })
      }
    }
  },
  created () {
    if (!this.beTheBuyerStylesToRate) this.getBeTheBuyerStylesToRate()
  },
  methods: {
    ...mapActions('styleGames', [
      'getBeTheBuyerStylesToRate',
      'getBeTheBuyerSummary',
      'rateBeTheBuyerStyle'
    ]),
    onRating ({ rating }) {
      this.rateBeTheBuyerStyle({ rating, id: this.currentStyle.id, index: this.ratingIndex + 1 })
      if (this.ratingIndex === this.beTheBuyerStylesToRate.length - 1) {
        this.getBeTheBuyerSummary()
      }
      this.ratingIndex = this.ratingIndex + 1
      this.beTheBuyerStylesRatedIndex = this.ratingIndex
    },
    setPrevPathName (prevPath) {
      this.prevRoute = prevPath.name
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styleGames/StyleGamesStyles.scss';
</style>
