<template>
  <div
    class="content-wrapper"
    :class="{ 'd-flex align-items-center': !isMobile }">
    <placeholder-illustration
      :class="{'mb-3': isMobile}"
      :height="isMobile ? '120' : '180'"
      :width="isMobile ? '120' : '180'"/>
    <div :class="{ 'ms-5': !isMobile }">
      <h4>Excuse our wardrobe malfunction!</h4>
      <p>We encountered an issue while trying to load this item.</p>
      <sequin-button
        type="router-link"
        variant="secondary"
        :to="{ name: 'feed'}">
        Back to Feed
      </sequin-button>
    </div>
  </div>
</template>

<script>
import PlaceholderIllustration from '../common/PlaceholderIllustration'
import SequinButton from '../../global/sequin/SequinButton'

export default {
  components: {
    SequinButton,
    PlaceholderIllustration
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  max-width: 760px;
}
</style>
