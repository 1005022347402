import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useNavBarStore } from '@shared/stores/navBar.js'
import { useFlyouts } from './flyouts'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment'

import SvgHome from '@/components/global/svg/SvgHome.vue'
import SvgSearch from '@/components/global/svg/SvgSearch.vue'
import SvgHeartNewOutline from '@/components/global/svg/SvgHeartNewOutline.vue'
import SvgCommunity from '@/components/global/svg/SvgCommunity.vue'
import MyCaseIcon from '@/components/case/common/MyCaseIcon.vue'

export default function useNavigation () {
  const navBar = useNavBarStore()
  const { openMyCaseFlyout, isMyCaseFlyoutOpen } = useFlyouts()
  const route = useRoute()
  const store = useStore()

  const itemContainerText = computed(() => store.getters['client/itemContainerText'])

  const navItems = computed(() => [
    {
      name: 'Home',
      to: { name: 'closet-sections' },
      active: route.name === 'closet-sections',
      icon: SvgHome
    },
    {
      name: 'Search',
      to: { name: 'search' },
      active: route.name === 'search',
      icon: SvgSearch
    },
    {
      name: 'My Stuff',
      to: { name: 'my-stuff' },
      active: route.name === 'my-stuff',
      icon: SvgHeartNewOutline
    },
    {
      name: 'Community',
      to: { name: 'feed' },
      active: route.name === 'feed',
      icon: SvgCommunity
    },
    {
      name: itemContainerText.value,
      onClick: openMyCaseFlyout,
      active: isMyCaseFlyoutOpen.value,
      icon: MyCaseIcon
    }
  ])
  const profilePanelItems = computed(() => ([
    { name: 'Style Profile', to: { name: 'style-profile' } },
    { name: 'Rental History', to: { name: 'profile', params: { tab: 'rental-history' } } },
    { name: 'Account', to: { name: 'account' } },
    { name: 'Help + FAQs', to: 'https://support.armoire.style/faq/' },
    { name: 'Refer a Friend', to: { name: 'referral' } },
    { name: 'Shop', to: 'https://shop.armoire.style/' },
    { name: 'Sign Out', onClick: logout }
  ]))
  const signedOutNavItems = computed(() => ([
    { name: 'Brands', to: '/#brands' },
    { name: 'Plans', to: plansLink.value },
    { name: 'How It Works', to: '/howitworks/' },
    { name: 'FAQ', to: '/frequently-asked-questions/' },
    { name: 'Log In', to: '/login/' }
  ]))
  const mobileNavItems = computed(() => navItems.value.filter(item => item.name !== itemContainerText.value))
  const { showVariant } = useHomepageRedesignExperiment()

  const plansLink = computed(() => showVariant.value ? '/#plans' : '/howitworks/#plans')

  function logout () {
    try {
      if (window.fbInitialized && window.fbAccessToken) {
        // eslint-disable-next-line
        FB.logout(function (response) {})
      }
    } finally {
      window.location.href = '/logout/'
    }
  }

  return {
    navBar,
    navItems,
    profilePanelItems,
    signedOutNavItems,
    mobileNavItems,
    logout
  }
}
