<template>
  <div class="container">
    <hr
      class="break mx-auto"
      style="max-width: 90%">
    <h3 class="referral-title my-4">
      Transforming your wardrobe with Armoire?
    </h3>
    <div class="mw-lg-75 mw-md-100 mx-auto">
      <p>Help your friends + family do the same!  <referral-credit-msg/></p>
      <referral-share/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ReferralShare from '../global/referral/ReferralShare'
import ReferralCreditMsg from '../global/referral/ReferralCreditMsg'

export default {
  name: 'ReviewSuccessReferralLink',
  components: { ReferralShare, ReferralCreditMsg },
  computed: {
    ...mapState('client', [
      'referralCode',
      'referrals',
      'referralAwards',
      'balance'
    ]),
    ...mapState('subscribe', [
      'referralCampaign'
    ])
  },
  mounted () {
    this.getReferralCampaign()
  },
  methods: {
    ...mapActions('subscribe', [
      'getReferralCampaign'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .referral-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }
  .mw-lg-75 {
    max-width: 75%;
    @media (max-width: 800px) {
      max-width: 100%;
    }
  }

</style>
