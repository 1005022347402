<template>
  <BaseExperiment :name="name">
    <template #default>
      <slot/>
    </template>
    <template #variantRedesign>
      <slot name="variantRedesign"/>
    </template>
  </BaseExperiment>
</template>

<script setup>
import BaseExperiment from '@shared/components/BaseExperiment.vue'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'

const { name } = useHomepageRedesignExperiment()
</script>
