<template>
  <div
    class="px-3 px-md-1 pt-5 pt-md-3 plum"
    :class="{ 'text-center': isMobile }">
    <placeholder-illustration class="mb-3"/>
    <h5>You haven't rented any styles yet!</h5>
    <p>Once you do, you'll be able to share looks with the Armoire Community</p>
    <sequin-button
      mobile-block
      variant="secondary"
      type="router-link"
      :to="{ name: 'closet-sections' }"
      @click="$emit('view-closet-click')">
      View Closet
    </sequin-button>
  </div>
</template>

<script>
import SequinButton from '../../global/sequin/SequinButton'
import PlaceholderIllustration from '../common/PlaceholderIllustration'

export default {
  components: {
    PlaceholderIllustration,
    SequinButton
  }
}
</script>
