<template>
  <div class="row justify-content-center py-1 py-sm-5 px-3">
    <div class="buttons text-center">
      <h2 class="py-1 py-md-3">
        Log in
      </h2>
      <div>
        <apple-button
          variant="login"
          @error="appleSignupError"
          @success="facebookOrGoogleLogin"/>
        <facebook-button
          class="mt-3"
          variant="login"
          @facebookSignupError="facebookSignupError"
          @facebookConnected="facebookOrGoogleLogin"/>

        <div ref="googleButtonWrapper">
          <google-button
            variant="login"
            class="mt-3"
            :width="wrapperWidth"
            @error="googleSignupError"
            @success="facebookOrGoogleLogin"/>
        </div>
      </div>

      <div class="row px-2 pt-2">
        <h5 class="pt-2">
          Or
        </h5>
      </div>

      <base-form-errors :errors="formErrors"/>

      <form @submit.prevent="submit">
        <div class="my-xs">
          <base-input
            v-model.trim="email"
            :validations="v$.email"
            autocomplete="email"
            type="email"
            input-id="email"
            label="Email Address"/>
        </div>
        <div class="my-xs">
          <base-input
            v-model="password"
            :validations="v$.password"
            autocomplete="current-password"
            class="mt-0"
            type="password"
            input-id="password"
            label="Password"/>
        </div>
        <button
          :disabled="formDisabled"
          class="btn btn-primary mt-3 w-100">
          Log In
        </button>
      </form>

      <router-link
        class="btn w-100 btn-outline-primary mb-3"
        :to="{ name: 'style-quiz-intro' }">
        Create an Account
      </router-link>

      <p class="small">
        <a
          class="password-link"
          href="/password_reset/">
          Forgot Password?
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FacebookButton from './FacebookButton'
import GoogleButton from './GoogleButton'
import AppleButton from './AppleButton'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import BaseFormErrors from '@/components/global/BaseFormErrors.vue'
import BaseInput from '@/components/global/BaseInput.vue'

export default {
  components: {
    AppleButton,
    FacebookButton,
    GoogleButton,
    BaseFormErrors,
    BaseInput
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      email: '',
      password: '',
      formDisabled: false,
      formErrors: [],
      wrapperWidth: 0
    }
  },
  mounted () {
    this.updateWrapperWidth()
    window.addEventListener('resize', this.updateWrapperWidth)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.updateWrapperWidth)
  },
  methods: {
    ...mapActions('client', [
      'login'
    ]),
    facebookOrGoogleLogin (formData) {
      this.completeSubmit(formData)
    },
    submit () {
      this.v$.$touch()
      if (this.v$.$invalid) return

      const formData = {
        email: this.email,
        password: this.password
      }
      this.completeSubmit(formData)
    },
    facebookSignupError (errors) {
      this.formErrors = [errors.message]
    },
    googleSignupError (error) {
      this.formErrors = [error]
    },
    appleSignupError (error) {
      if (error.error !== 'popup_closed_by_user') {
        this.formErrors = [error.error]
      }
    },
    async completeSubmit (formData) {
      this.formDisabled = true

      try {
        await this.login(formData)
        this.formErrors = []
      } catch (errors) {
        this.formErrors = errors
      }

      this.formDisabled = false
    },
    updateWrapperWidth () {
      this.wrapperWidth = this.$refs.googleButtonWrapper.clientWidth || 0
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>

.buttons {
  width: 313px;

  @media (min-width: 576px) {
    width: 400px;
  }

}
.password-link {
  color: $armor;
  text-decoration: none;
}
</style>
