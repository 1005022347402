<template>
  <component
    :is="tag"
    ref="element"
    :style="cssVars"
    :title="tooltip"
    class="line-limit">
    <slot/>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'p',
      validator: value => ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div'].includes(value)
    },
    lineLimit: {
      type: [Number, String],
      default: 1,
      validator: value => value === 'none' || Number(value) > 0
    },
    allowTooltip: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      resizeObserver: null,
      textTruncated: false,
      lineCount: null
    }
  },
  computed: {
    cssVars () {
      return {
        '--line-limit': this.lineLimit
      }
    },
    tooltip () {
      return this.allowTooltip && this.textTruncated ? this.$refs.element?.textContent : null
    }
  },
  mounted () {
    if (this.allowTooltip) {
      this.resizeObserver = new ResizeObserver(this.onResize)
      this.$nextTick(() => {
        this.resizeObserver.observe(this.$refs.element)
      })
    }
  },
  unmounted () {
    this.resizeObserver?.disconnect()
  },
  methods: {
    onResize () {
      // Round to account for browser rounding differences.
      const tolerance = 2
      const element = this.$refs.element
      const scrollHeight = Math.floor(element.scrollHeight / tolerance) * tolerance
      const clientHeight = Math.floor(element.clientHeight / tolerance) * tolerance
      this.textTruncated = scrollHeight > clientHeight

      const lineHeight = parseInt(window.getComputedStyle(element).lineHeight)
      this.lineCount = Math.floor(clientHeight / lineHeight)
    }
  }
}
</script>

<style lang="scss" scoped>
.line-limit {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: var(--line-limit);
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}
</style>
