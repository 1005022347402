<template>
  <div
    class="form-group text-start mb-0"
    :class="$attrs.class">
    <textarea
      v-bind="$attrs"
      class="form-control px-3"
      :class="[{ error }]"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @blur="onBlur"/>
    <base-errors
      v-if="error"
      class="error-label"
      :validations="validations"/>
  </div>
</template>

<script>
import BaseErrors from './BaseErrors.vue'

export default {
  components: {
    BaseErrors
  },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    }
  },
  computed: {
    error () {
      return this.validations.$error
    }
  },
  methods: {
    onBlur () {
      this.validations.$touch()
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-control {
    border: 1px solid $ash;
    border-radius: 2px;

    &:focus {
      box-shadow: none;
      border-width: 2px;
      border-color: $secondary;
    }

    &.error {
      border: 2px solid red;
      color: red;
    }
  }

  .error-label {
    margin-top: 8px;
  }

  label {
    display: block;
    font-size: 0.8rem;
    margin: 0.6rem 0 0.3rem 0;
    padding: 0;
    line-height: 1em;
  }
</style>
