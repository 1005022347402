<template>
  <div
    :class="{ 'mobile': isMobile }"
    class="banner-image-container">
    <base-image
      class="banner-image-full"
      :style="imageStyles"
      :src="`${baseImageUrl}banners/${imageName}.jpg`"
      @load="onLoad"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseImage from '@/components/global/BaseImage.vue'

export default {
  components: {
    BaseImage
  },
  props: {
    imageName: {
      type: [Array, String],
      required: true
    },
    imageStyles: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('styleQuizNav', [
      'baseImageUrl'
    ])
  },
  methods: {
    onLoad () {
      this.$emit('image-loaded')
    }
  }
}
</script>

<style lang="scss" scoped>

.banner-image-container {
  width: 50vw;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  &.mobile {
    width: 100vw;
    height: 100vh;
    left: 0;
  }
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-image-full {
  @extend .banner-image;
  opacity: 0;
  filter: blur(5px);

  animation: appear .2s forwards 0s ease;
  @keyframes appear {
    0% {
      opacity: 0;
      filter: blur(5px);

    }
    100% {
      opacity: 1;
      filter: blur(0);

    }
  }
}
</style>
