<template>
  <popover-dropdown
    variant="right"
    @click="$emit('more-click')">
    <template #button>
      <svg-more/>
    </template>
    <template #dropdown-content="dropdownScope">
      <edit-delete-menu
        class="edit-post"
        item-name="Post"
        :can-edit="false"
        @delete-click="onDelete(dropdownScope)"
        @cancel-click="dropdownScope.closeDropdown"/>
    </template>
  </popover-dropdown>
</template>

<script>
import EditDeleteMenu from './EditDeleteMenu'
import PopoverDropdown from '../../../global/sequin/PopoverDropdown'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgMore from '@/components/global/svg/SvgMore.vue'

export default {
  components: {
    PopoverDropdown,
    EditDeleteMenu,
    SvgMore
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    async onDelete (dropdownScope) {
      dropdownScope.closeDropdown()
      this.openModal({ name: 'community-delete-confirmation' })
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-post {
  width: 240px;
}
</style>
