<template>
  <div
    class="border member-box d-flex justify-content-space-around align-items-center p-3 pointer"
    @click="clickMember()">
    <avatar-image
      :profile-pic="profile.profilePic"
      :client-id="profile.id"
      size="medium"
      class="me-2"/>
    <div
      class="smaller member-details">
      <div>
        <b>{{ profile.name }}</b>
      </div>
      <div
        v-if="profile.location"
        class="fine-print text-pewter">
        {{ profile.location }}
      </div>
    </div>
  </div>
</template>

<script>
import AvatarImage from '../community/common/AvatarImage'
import inViewport from 'vue-in-viewport-mixin'

export default {
  components: {
    AvatarImage
  },
  mixins: [inViewport],
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      hasBeenVisible: false
    }
  },
  watch: {
    'inViewport.now': function (visible) {
      if (visible && !this.hasBeenVisible) {
        this.$emit('member-in-view')
        this.hasBeenVisible = true
      }
    }
  },
  methods: {
    clickMember () {
      this.$router.push({ name: 'profile', params: { id: this.profile.id } })
    }
  }
}
</script>
<style lang="scss" scoped>

.member-box {
  width: 100%;

  @media (min-width: 576px) {
    max-width: 243px;
  }
}

.member-details {
  flex: 1;
}

</style>
