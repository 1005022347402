<template>
  <div class="notifications-icon-wrapper">
    <div
      class="notifications-icon">
      <svg-bell-filled
        v-if="notificationsOpen"
        height="22"/>
      <svg-bell-outline
        v-else
        height="22"/>
      <div
        v-if="unreadNotificationCount"
        class="circle-state-indicator">
        <span>{{ unreadNotificationCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SvgBellFilled from '@/components/global/svg/SvgBellFilled.vue'
import SvgBellOutline from '@/components/global/svg/SvgBellOutline.vue'

export default {
  components: {
    SvgBellFilled,
    SvgBellOutline
  },
  computed: {
    ...mapState('notifications', [
      'notificationsOpen'
    ]),
    ...mapGetters('notifications', [
      'unreadNotificationCount'
    ])
  }
}
</script>

<style lang="scss" scoped>

.notifications-icon-wrapper {
  &:focus {
    border: none;
    outline: none;
  }
}

.notifications-icon {
  position: relative;
}

.circle-state-indicator {
  background: $primary;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -4px;
  right: -2px;
  color: $white;
  position: absolute;
  font-size: 10px;
  font-weight: $font-weight-semibold;

  @include scale-up-down-animation;
}
</style>
