<template>
  <div class="my-case-icon-wrapper">
    <div
      class="my-case-icon"
      :class="{'mobile': isMobile}">
      <svg-case height="22"/>
      <svg-pause
        v-if="iconState === 'pause'"
        class="pause state-indicator"/>
      <my-case-icon-counter
        v-else-if="iconState === 'plus' || iconState === numSelectedItems"
        class="icon-counter"/>
      <my-case-icon-in-transit
        v-else-if="iconState === 'transit'"
        class="transit state-indicator"/>
      <svg-refresh-c-w
        v-else-if="iconState === 'swap-available'"
        class="state-indicator swap"/>
      <my-case-icon-at-home
        v-else-if="iconState === 'with-me'"
        class="home state-indicator"/>
    </div>
    <tippy
      ref="tippy"
      :arrow="true"
      theme="light"
      trigger="manual"
      content="Added to case!"
      animation="shift-away"
      append-to="parent"
      placement="bottom"/>
  </div>
</template>

<script>
import MyCaseMixin from './MyCaseMixin'
import MyCaseIconInTransit from './MyCaseIconInTransit'
import MyCaseIconAtHome from './MyCaseIconAtHome'
import MyCaseIconCounter from './MyCaseIconCounter'
import SvgCase from '@/components/global/svg/SvgCase.vue'
import SvgPause from '@/components/global/svg/SvgPause.vue'
import SvgRefreshCW from '@/components/global/svg/SvgRefreshCW.vue'
import { mapState } from 'vuex'
import { Tippy } from 'vue-tippy'

export default {
  name: 'MyCaseIcon',
  components: {
    MyCaseIconAtHome,
    MyCaseIconInTransit,
    MyCaseIconCounter,
    SvgCase,
    SvgPause,
    SvgRefreshCW,
    Tippy
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('closet', [
      'selectedItemsLoaded',
      'packageItemsLoaded'
    ]),
    ...mapState('case', [
      'addedToCase'
    ]),
    iconState () {
      if (!this.selectedItemsLoaded || !this.packageItemsLoaded) return 'empty'

      if (this.membershipStatus !== 'active' || this.tooManyItemsOut) {
        return 'pause'
      }

      if (this.canSelectItems) {
        if (this.numSelectedItems) {
          return this.numSelectedItems
        } else {
          return 'plus'
        }
      }

      if (this.itemsInTransit) {
        return 'transit'
      }

      if (this.membershipDetails.allowedShipments > 0) {
        return 'swap-available'
      }

      if (this.deliveredItems.length) {
        return 'with-me'
      }

      return 'empty'
    }
  },
  watch: {
    addedToCase (val) {
      if (val) {
        this.$refs.tippy.show()
      } else {
        this.$refs.tippy.hide()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/shift-away.css';

.my-case-icon-wrapper {
  &:focus {
    border: none;
    outline: none;
  }
}

.my-case-icon {
  position: relative;
}

:deep(.tippy-content) {
  color: $pewter;
  font-weight: $font-weight-normal;
}

.icon-counter {
  position: absolute;
  bottom: -4px;
  right: -3px;
}

.state-indicator {
  position: absolute;
  left: 2px;
  right: 2px;
  top: 7px;
  width: 21px;

  &.transit {
    top: 10px;
    width: 20px;
    margin-left: -2px;
  }

  &.pause {
    top: 10px;
  }

  &.swap {
    top: 7px;
    height: 12px;
    width: 12px;
    left: 7px;
    stroke-width: 3px;
  }
}

</style>
