<template>
  <div
    class="d-flex bg-white align-items-center"
    :class="{'page-header': pageHeader }">
    <plain-button
      v-if="showBackButton"
      :disabled="disabled"
      class="p-0 back-button"
      @click="onBack">
      <slot name="back">
        <svg-chevron-left/>
      </slot>
    </plain-button>
    <text-line-limit
      tag="h5"
      class="header"
      :class="{ 'disabled': disabled }">
      <slot>
        <div class="placeholder-title-loading"/>
      </slot>
    </text-line-limit>
    <div
      :class="{ 'desktop' : !isMobile, 'confirm-wrapper' : showConfirm }">
      <slot name="rightHeaderSlot">
        <plain-button
          v-if="showConfirm"
          :disabled="disabled"
          class="m-0 px-1 py-0"
          @click="onConfirm">
          <slot name="confirm">
            <svg-check
              v-if="isMobile"
              width="24"
              height="24"
              class="confirm me-md-1"/>
            <div
              v-else
              class="text-primary">
              Done
            </div>
          </slot>
        </plain-button>
      </slot>
    </div>
  </div>
</template>

<script>
import PlainButton from './PlainButton'
import TextLineLimit from './TextLineLimit.vue'
import goBack from '../mixins/goBack'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    PlainButton,
    TextLineLimit,
    SvgChevronLeft,
    SvgCheck
  },
  mixins: [goBack],
  props: {
    showBackButton: {
      type: Boolean,
      default: true
    },
    showConfirm: {
      type: Boolean,
      default: false
    },
    pageHeader: {
      type: Boolean,
      default: false
    },
    fallbackRoute: {
      type: Object,
      default: () => { return { name: 'feed' } }
    },
    // prevents the default navigation behavior
    noNavigation: {
      type: Boolean,
      default: false
    },
    // When true, disables buttons in header
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onBack () {
      this.$emit('back-click')
      this.defaultGoBackBehavior()
    },
    onConfirm () {
      this.$emit('confirm-click')
      this.defaultGoBackBehavior()
    },
    defaultGoBackBehavior () {
      if (!this.noNavigation) {
        this.goBack(this.fallbackRoute)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  opacity: .7;
}

.page-header {
  padding: 16px;
  border-bottom: $default-border;
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 1022;
}

.confirm-wrapper {
  margin-right: 0;
  position: absolute;
  right: 14px;

  &.desktop {
    margin-right: 4px;
  }

  .confirm {
    color: $orchid;
    stroke-width: 2.2px;
  }
}

.placeholder-title-loading {
  @include placeholder-loading-animation;
  height: 22px;
  width: 140px;
  margin: 0 auto;
}

.header {
  width: 80%;
  margin: 0 auto;
  text-transform: capitalize;
  text-align: center;
  pointer-events: none;
}

.text-primary {
  font-weight: $font-weight-semibold;
}
</style>
