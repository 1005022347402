<template>
  <div>
    <div
      v-if="message"
      class="overlay-container">
      <div class="message small">
        <b>{{ message }}</b>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>

.message {
  color: $white;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px 10px;
  background-color: $success-light;
  text-transform: uppercase;
  border-radius: 2px;
  z-index: $zindex-sticky;
}

.overlay-container {
  position: relative;
}

</style>
