<template>
  <div
    class="pt-2 pt-md-3"
    :class="{ 'col-8 offset-3': !isMobile }">
    <div v-if="isMobile">
      <action-header page-header>
        <h5>Gift Cards</h5>
      </action-header>
    </div>
    <div
      v-else
      class="container pt-4 px-0">
      <bread-crumb
        class="mb-3"
        current-location="Gift Cards"
        :crumbs="[{ name: 'Account', to: { name: 'account' } }]"/>
      <h2
        class="pb-3 mx-2 mx-md-3 text-md-start">
        Gift Cards
      </h2>
    </div>
    <div class="container">
      <div
        class="row">
        <app-message
          v-if="gcErrors.length > 0"
          variant="danger"
          class="p-4 no-padding">
          <div
            v-for="(error, index) in gcErrors"
            :key="index">
            <span v-html="error"/>
            <br>
          </div>
        </app-message>
        <base-alert
          v-if="showAlert"
          key="alert"/>
        <div
          class="col">
          <h3>Balance: {{ formatPrice(balance) }}</h3>
          Add a new gift card to your balance
          <div class="d-flex pt-2">
            <base-input
              v-model="code"
              input-id="gift-code"
              label="Enter Gift Code"/>
            <div
              class="button-container">
              <BaseButton
                variant="secondary"
                :disabled="applyDisabled"
                text="Apply"
                @click="apply"/>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mt-4">
        <base-checkbox
          v-model="applyToSubscription"
          class="mb-3"
          text="Apply gift card balance to my subscription"
          @input="setApplyToSubscription($event)"/>
      </div>
      <div
        class="col pt-5">
        <h3>Transaction Summary</h3>
      </div>
      <div
        class="transactions">
        <div
          v-for="(transaction, index) in giftCardTransactions"
          :key="index">
          <gift-card-transaction
            :transaction="transaction"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ActionHeader from '../../global/sequin/ActionHeader'
import BreadCrumb from '../../global/sequin/BreadCrumb'
import BaseButton from '@shared/components/ADORN/BaseButton'
import GiftCardTransaction from './GiftCardTransaction'
import AppMessage from '../../global/sequin/AppMessage'
import { useStore } from 'vuex'
import { formatPrice } from '@/utils/stringParsing.js'
import BaseAlert from '@/components/global/BaseAlert.vue'
import BaseInput from '@/components/global/BaseInput.vue'
import BaseCheckbox from '@/components/global/BaseCheckbox.vue'

import { computed, onMounted, ref } from 'vue'

const gcErrors = ref([])
const code = ref('')
const applyDisabled = ref(false)

const store = useStore()
const giftCardTransactions = computed(() => store.state.account.giftCardTransactions)
const applyToSubscription = computed(() => store.state.client.settings.applyGiftCardsToSubscriptions)
const showAlert = computed(() => store.state.global.showAlert)
const balance = computed(() => {
  if (giftCardTransactions.value && giftCardTransactions.value.length > 0) {
    return giftCardTransactions.value[0].clientClosingBalance
  }
  return 0
})

onMounted(() => {
  store.dispatch('account/getGiftCardTransactions')
})

async function setApplyToSubscription (event) {
  try {
    store.dispatch('account/updateApplyGiftCardsToSubscriptionChoice', event)
  } catch (errors) {
  }
}

async function apply () {
  applyDisabled.value = true
  gcErrors.value = []
  try {
    await store.dispatch('account/activateGiftCard', code.value)
    store.dispatch('global/setAlert', { msg: 'Your gift card has been applied.' })
    gcErrors.value = []
    code.value = ''
  } catch (errors) {
    gcErrors.value = [...errors]
  }
  applyDisabled.value = false
}
</script>

<style scoped>
.transactions {
  max-width: 750px;
}

.button-container {
  padding-left: 8px;
}

</style>
