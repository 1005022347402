<template>
  <div
    class="d-flex align-items-center me-2">
    <svg-star
      class="d-inline pe-1"
      fill="#850D54"
      stroke="none"/>
    <p class="d-inline star-number">
      {{ review.overallStarRating }}
    </p>
  </div>
</template>

<script>
import SvgStar from '@/components/global/svg/SvgStar.vue'

export default {
  components: {
    SvgStar
  },
  props: {
    review: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
  .star-number {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }
</style>
