<template>
  <div class="profile-share-signup">
    <div class="text-center">
      <h6
        v-show="!!referralCampaign"
        class="header">
        {{ refereeDiscount }}% off your first month with Armoire!
      </h6>
      <p>
        Unlock our lowest pricing using {{ firstName }}’s friends + family discount.
        Code: <span class="font-weight-bold">{{ referralCode }}</span>
      </p>
      <sequin-button
        type="router-link"
        :to="{ name: 'style-quiz-intro', query: { r: referralCode } }">
        Take Your Style Quiz
      </sequin-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SequinButton from '../../global/sequin/SequinButton'

export default {
  name: 'ProfileShareSignup',
  components: { SequinButton },
  props: {
    firstName: {
      type: String,
      required: true
    },
    referralCode: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('subscribe', [
      'referralCampaign'
    ]),
    ...mapGetters('subscribe', [
      'refereeDiscount'
    ])
  },
  created () {
    this.getReferralCampaign()
  },
  methods: {
    ...mapActions('subscribe', [
      'getReferralCampaign'
    ])
  }
}
</script>

<style lang="scss" scoped>

.profile-share-signup {
  padding: 18px;
  background-color: $champagne;
}

.header {
  font-weight: $font-weight-semibold;
  color: $plum;
}

</style>
