
const pendingPromises = new Map()
/**
 * Allows a single pending promise per key. If a promise with the same key is pending,
 * it will return the existing promise instead of creating a new one.
 * Useful for preventing multiple requests for the same resource.
 *
 * @param {string} key - Unique key for the promise.
 * @param {function(resolve, reject): Promise} executor - Promise callback.
 *
 * @example
 * await getKeyedPromise('myKey', (resolve, reject) => {
 *  resolve(/* async result * /)
 * })
 */
export default function getKeyedPromise (key, executor) {
  if (pendingPromises.has(key)) {
    return pendingPromises.get(key)
  }
  const promise = new Promise(executor)
  pendingPromises.set(key, promise)
  return promise.finally(() => {
    pendingPromises.delete(key)
  })
}
