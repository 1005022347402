import useVuexModule from '@shared/composables/vuexModule.js'
import { defineStore } from 'pinia'

// TODO: Partial implementation (ENG-2859)
export const useSubscriptionStore = defineStore('subscription', () => {
  const vuex = useVuexModule('subscribe')

  const selectedPlanId = vuex.membershipPlanSelectedId

  return {
    ...vuex,
    selectedPlanId
  }
})
