<template>
  <div
    v-if="showWidget"
    class="d-flex">
    <div
      v-for="index in 5"
      :key="index"
      class="rating-button">
      <svg-star
        :class="starFilled(index)"
        class="rating-star"/>
    </div>
    <span
      v-if="showRating"
      class="text-gray small-text pt-1 ps-1">
      ({{ starRating }})
    </span>
    <span
      v-if="showNumReviews"
      class="text-gray small-text ps-1 pt-1">
      ({{ numReviews }} reviews)
    </span>
  </div>
</template>

<script>
import SvgStar from '@/components/global/svg/SvgStar.vue'

export default {
  components: {
    SvgStar
  },
  props: {
    styleColor: {
      type: Object,
      default: null
    },
    rating: {
      type: Number,
      default: 0
    },
    reviewCount: {
      type: Number,
      default: 0
    },
    showRating: {
      type: Boolean,
      default: false
    },
    showNumReviews: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showWidget () {
      return (this.styleColor && this.styleColor.numStarRatings > 0) || this.rating > 0
    },
    starRating () {
      return this.styleColor ? this.styleColor.avgStarRating : this.rating
    },
    numReviews () {
      return this.styleColor ? this.styleColor.totalReviewsCount : this.reviewCount
    }
  },
  methods: {
    starFilled (index) {
      if (this.starRating) {
        const diff = index - this.starRating

        // Quarter stars displayed, but breakpoints tweaked for aesthetics
        if (diff <= 0) return 'filled'
        else if (diff < 0.1) return 'filled'
        else if (diff < 0.375) return 'filled75'
        else if (diff < 0.625) return 'filled50'
        else if (diff < 0.875) return 'filled25'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rating-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0px;
  margin-bottom: 8px;
}

.rating-star {
  fill: rgba($ash, .5);
  stroke-width: 0;

  &.filled25 {
    fill: url(#gradient25);
  }

  &.filled50 {
    fill: url(#gradient50);
  }

  &.filled75 {
    fill: url(#gradient75);
  }

  &.filled {
    fill: $secondary;
  }
}
</style>
