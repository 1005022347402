import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      selectedReplacementChoice: null,
      replacementOptions: {
        BonusItem: {
          flyoutTitle: 'Bonus Item ($20 value)',
          settingsTitle: 'Bonus Item for Replacements',
          flyoutSubtitle: 'A bonus item will be added to your account. You’ll be able to use it to rent an extra item in a future Case.',
          settingsSubtitle: 'For any selected item that can’t be shipped, a free bonus item will be added to your account.'
        },
        Credit15: {
          flyoutTitle: '$15 Account Credit',
          settingsTitle: 'Account Credit for Replacements',
          flyoutSubtitle: 'Credit will be added to your account and automatically applied towards future purchases or subscription fees.',
          settingsSubtitle: 'For any selected item that can’t be shipped, a $15 credit will be added to your account.'
        },
        SurpriseItem: {
          flyoutTitle: 'Surprise Item',
          settingsTitle: 'Surprise Item Replacements',
          flyoutSubtitle: 'The Armoire team will select a new item to ship with your Case. Shipping speed will not be impacted.',
          settingsSubtitle: 'For any selected item that can’t be shipped, the Armoire team will select a surprise item.'
        }
      }
    }
  },
  computed: {
    ...mapGetters('client', [
      'selectedReplacementItem'
    ]),
    replacementItemSettingsTitle () {
      return this.replacementOptions[this.selectedReplacementItem].settingsTitle
    },
    replacementItemSettingsSubtitle () {
      return this.replacementOptions[this.selectedReplacementItem].settingsSubtitle
    }
  },
  async mounted () {
    if (this.selectedReplacementItem === null) {
      await this.updateReplacementItemChoice('BonusItem')
      this.selectedReplacementChoice = this.selectedReplacementItem
    } else {
      this.selectedReplacementChoice = this.selectedReplacementItem
    }
  },
  methods: {
    ...mapActions('account', [
      'updateReplacementItemChoice'
    ]),
    replacementOptionTitle (option) {
      return option.flyoutTitle
    },
    replacementOptionSubtitle (option) {
      return option.flyoutSubtitle
    }
  }
}
