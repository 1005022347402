import axios from './config'
import store from '../index'
import { serializeParams } from './helpers/helpers'
import logger from '../../logger'

const apiClient = {
  updateClient (data) {
    const username = store.state.client.username
    logger.info(username, data)
    return axios.patch('/api/clients/' + username + '/', data)
  },
  buyItem (data) {
    const username = store.state.client.username
    logger.info(username, data)
    return axios.post('/api/clients/' + username + '/buy_package_item/', data)
  },
  buyAllItems (data) {
    const username = store.state.client.username
    logger.info(username, data)
    return axios.post('/api/clients/' + username + '/buy_all_unreturned_package_items/', data)
  },
  getAllItemsPrice () {
    const username = store.state.client.username
    logger.info(username)
    return axios.get('/api/clients/' + username + '/unreturned_package_items_price/')
  },
  getCasePrice (data) {
    const username = store.state.client.username
    logger.info(username, data)
    return axios.post('/api/clients/' + username + '/case_price_quote/', data)
  },
  purchaseCase (data) {
    const username = store.state.client.username
    return axios.post('/api/clients/' + username + '/purchase_case/', data)
  },
  getShippingEligibility () {
    const username = store.state.client.username
    logger.info(username)
    return axios.get('/api/clients/' + username + '/shipping_eligibility/')
  },
  getClient () {
    const username = store.state.client.username
    if (username?.length > 0) {
      return axios.get('/api/clients/' + username + '/get_client/')
    }
    return Promise.reject(new Error('No username'))
  },
  registerExperiment (data) {
    const username = store.state.client.username
    logger.info(username, data)
    return axios.post('/api/clients/' + username + '/register_experiment/', data)
  },
  getPaymentInfo () {
    const username = store.state.client.username
    logger.info(username)
    return axios.get('/api/clients/' + username + '/payment/')
  },
  setStyleColorRating (data) {
    const username = store.state.client.username
    logger.info(username, data)
    return axios.post('/api/clients/' + username + '/style_color_rating/', data)
  },
  getValidatedShippingAddress () {
    const username = store.state.client.username
    logger.info(username)
    return axios.get('/api/clients/' + username + '/validated_shipping_address/')
  },
  getUnlockShipmentPricing () {
    const username = store.state.client.username
    logger.info(username)
    return axios.get('/api/clients/' + username + '/unlock_shipment_pricing/')
  },
  getReferrals () {
    const username = store.state.client.username
    logger.info(username)
    return axios.get('/api/clients/' + username + '/referrals/')
  },
  getBilling () {
    const username = store.state.client.username
    logger.info(username)
    return axios.get('/api/clients/' + username + '/billing/')
  },
  postSupportRequest (data) {
    const username = store.state.client.username
    logger.info(username, data)
    return axios.post('/api/clients/' + username + '/support_request/', data)
  },
  postDefaultPayment (data) {
    const username = store.state.client.username
    logger.info(username, data)
    return axios.post('/api/clients/' + username + '/default_payment/', data)
  },
  checkReferralCodeAvailability (code) {
    const username = store.state.client.username
    logger.info(username, code)
    return axios.get('/api/clients/' + username + '/referral_link_availability/?code=' + code)
  },
  cancelSubscription (cancelReason) {
    const username = store.state.client.username
    logger.info(username, cancelReason)
    return axios.post('/api/clients/' + username + '/cancel/', cancelReason)
  },
  pauseSubscription (months) {
    const username = store.state.client.username
    logger.info(username, months)
    return axios.post('/api/clients/' + username + '/pause/', { months })
  },
  unpauseSubscription () {
    const username = store.state.client.username
    logger.info(username)
    return axios.post('/api/clients/' + username + '/unpause/')
  },
  unlockShipments () {
    const username = store.state.client.username
    logger.info(username)
    return axios.post('/api/clients/' + username + '/unlock_next_shipments/', {})
  },
  /* API Community
    TEMPORARY HACK until we figure out the webpack bs that's
    preventing apiCommunity from being imported until too late
  */
  getFeedItems (client, tag, tagId, source) {
    let params = ''
    if (client || tag || tagId || source) {
      params = '?' + serializeParams({ client, tag, tagId, source: source })
    }
    const url = '/api/feeditems/' + params
    return axios.get(url)
  },
  getNextPage (endpoint) {
    return axios.get(endpoint)
  },
  getClientFollowers (clientId, shareToken) {
    let url = '/api/clients/' + clientId + '/followers/'
    if (shareToken) {
      url += '?share=' + shareToken
    }
    return axios.get(url)
  },
  getClientFollowing (clientId, shareToken) {
    if (clientId) {
      let url = '/api/clients/' + clientId + '/following/'
      if (shareToken) {
        url += '?share=' + shareToken
      }
      return axios.get(url)
    }
    return axios.get('/api/clients/' + clientId + '/following/')
  },
  followClient (clientIdToFollow) {
    const username = store.state.client.username
    return axios.post('/api/clients/' + username + '/follow/', { client: clientIdToFollow })
  },
  unfollowClient (clientIdToUnFollow) {
    const username = store.state.client.username
    return axios.post('/api/clients/' + username + '/unfollow/', { client: clientIdToUnFollow })
  },
  getSuggestedToFollow () {
    return axios.get('/api/clients/?type=suggestedFollow')
  },
  getLooks (client, shareToken) {
    let params = ''
    if (client) {
      params = '?client=' + client
      if (shareToken) {
        params += '&share=' + shareToken
      }
    }
    return axios.get('/api/looks/' + params)
  },
  getFeedItem (feedItemId) {
    return axios.get('/api/feeditems/' + feedItemId + '/')
  },
  getFeedItemComments (id) {
    return axios.get(
      '/api/feeditems/' + id + '/comments/')
  },
  getFeedItemLikes (id) {
    return axios.get(
      '/api/feeditems/' + id + '/likes/')
  },
  getClientPublicProfile (clientId, shareToken) {
    let url = '/api/clients/' + clientId + '/'
    if (shareToken) {
      url += '?share=' + shareToken
    }
    return axios.get(url)
  },
  postFeedItemReaction (feedItemId, likeData) {
    return axios.post('/api/feeditems/' + feedItemId + '/like/', likeData)
  },
  postFeedItemComment (commentData) {
    return axios.post('/api/feedcomments/', commentData)
  },
  patchFeedItemComment (commentId, commentData) {
    return axios.patch('/api/feedcomments/' + commentId + '/', commentData)
  },
  deleteFeedItemComment (feedItemCommentId) {
    return axios.delete('/api/feedcomments/' + feedItemCommentId)
  },
  patchCommunityProfile (data) {
    const username = store.state.client.username
    return axios.patch('/api/clients/' + username + '/', data)
  },
  postLook (data) {
    return axios.post('/api/looks/', data)
  },
  patchLook (data, lookId) {
    return axios.patch('/api/looks/' + lookId + '/', data)
  },
  deleteLook (lookId) {
    return axios.delete('/api/looks/' + lookId + '/')
  },
  deleteFeedItem (feedItemId) {
    return axios.delete('/api/feeditems/' + feedItemId + '/')
  },
  postGenericFeedItem (data) {
    return axios.post('/api/genericfeeditems/', data)
  },
  deleteGenericFeedItem (genericFeedItemId) {
    return axios.delete('/api/genericfeeditems/' + genericFeedItemId + '/')
  },
  getLook (lookId) {
    return axios.get('/api/looks/' + lookId + '/')
  },
  getSystemTags (category) {
    return axios.get('/api/tags/?type=system&category=' + category)
  },
  getRentalHistory (clientId, shareToken, filter) {
    let params = ''
    const url = '/api/clients/' + clientId + '/rental_history_v2/'
    if (shareToken || filter) {
      params = '?' + serializeParams({ filter, share: shareToken })
    }
    return axios.get(url + params)
  },
  getProfileImageUploadUrl () {
    const username = store.state.client.username
    return axios.get('/api/clients/' + username + '/profile_image_upload_url')
  },
  getImageUploadUrl () {
    return axios.get('/api/looks/image_upload_url/')
  },
  getGenericImageUploadUrl () {
    return axios.get('/api/genericfeeditems/image_upload_url/')
  },
  getUserFeedLikes () {
    const username = store.state.client.username
    return axios.get('/api/clients/' + username + '/likes/')
  },
  getReturnPackages (count = 5) {
    return axios.get(`/api/return_packages/?count=${count}`)
  },
  submitCaseBackupSize (packageId, data) {
    logger.info(packageId, data)
    return axios.post('/api/packages/' + packageId + '/case_backup_size/', data)
  },
  submitPickupRequest (data) {
    return axios.post('/api/packages/pickup_request/', data)
  },
  getShareToken (feature, resource) {
    const username = store.state.client.username
    return axios.get(`/api/clients/${username}/share_token/?feature=${feature}&resource=${resource}`)
  },
  checkImpersonation () {
    return axios.get('/api/clients/get_impersonation_info/')
  }
}

export default apiClient
