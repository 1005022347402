<template>
  <div class="style-cluster-container">
    <div
      class="style-cluster-background"
      :style="clusterBorderOffset"/>
    <div class="style-cluster">
      <style-cluster-image
        v-if="largePlacement === 0"
        :cluster="cluster"
        :image-shown-index="imagesShown.large"
        size="large"/>
      <div class="small-images">
        <style-cluster-image
          :cluster="cluster"
          :image-shown-index="imagesShown.small"
          size="small"/>
        <style-cluster-image
          :cluster="cluster"
          :image-shown-index="imagesShown.pattern"
          size="pattern"/>
      </div>
      <style-cluster-image
        v-if="largePlacement === 1"
        :cluster="cluster"
        :image-shown-index="imagesShown.large"
        size="large"/>
      <style-cluster-image
        v-if="largePlacement === 0"
        :cluster="cluster"
        :image-shown-index="imagesShown.large_2"
        size="large_2"/>
      <div class="small-images">
        <style-cluster-image
          :cluster="cluster"
          :image-shown-index="imagesShown.med_1"
          size="med_1"/>
        <style-cluster-image
          :cluster="cluster"
          :image-shown-index="imagesShown.med_2"
          size="med_2"/>
      </div>
      <style-cluster-image
        v-if="largePlacement === 1"
        :cluster="cluster"
        :image-shown-index="imagesShown.large_2"
        size="large_2"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StyleClusterImage from './StyleClusterImage'

export default {
  components: { StyleClusterImage },
  props: {
    cluster: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      largePlacement: Math.round(Math.random()),
      imagesShownIndex: 0,
      imagesShown: {
        large: 1,
        med_2: 1,
        pattern: 1,
        large_2: 1,
        med_1: 1,
        small: 1
      },
      borderOffsetBase: 32
    }
  },
  computed: {
    ...mapState('styleProfile', [
      'generalClusterOptions'
    ]),
    ...mapState('styleQuizNav', [
      'baseImageUrl'
    ]),
    baseClusterImageUrl () {
      return `${this.baseImageUrl}clusters/${this.cluster}/`
    },
    clusterIndex () {
      return this.generalClusterOptions.map(cluster => cluster.value).indexOf(this.cluster)
    },
    clusterBorderOffset () {
      const offset = this.clusterIndex % 4

      switch (offset) {
        case 0:
          return { top: -this.borderOffsetBase, left: -this.borderOffsetBase }
        case 1:
          return { top: -this.borderOffsetBase, left: this.borderOffsetBase }
        case 2:
          return { top: this.borderOffsetBase, left: this.borderOffsetBase }
        case 3:
          return { top: this.borderOffsetBase, left: -this.borderOffsetBase }
        default:
          return {}
      }
    }
  },
  mounted () {
    setTimeout(this.updateImagesShown, 2000)
  },
  methods: {
    updateImagesShown () {
      const key = Object.keys(this.imagesShown)[this.imagesShownIndex]
      this.imagesShown[key] = this.imagesShown[key] === 1 ? 2 : 1
      this.imagesShownIndex = (this.imagesShownIndex + 1) % Object.keys(this.imagesShown).length
      setTimeout(this.updateImagesShown, 3000)
    },
    getImageUrl (imageType) {
      return `${this.baseClusterImageUrl}${imageType}.jpg`
    }
  }
}
</script>

<style lang="scss" scoped>

.style-cluster-container {
  position: relative;
  width: 780px;
  height: 320px;
  margin: 40px auto 24px;
}

.style-cluster-background {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 10px solid $champagne;
}

.style-cluster {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.small-images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 17%;
  height: 100%;
}
</style>
