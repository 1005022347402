<template>
  <div
    class="d-flex flex-wrap col-12 justify-content-center"
    :class="containerClass">
    <preview-image-button
      v-for="styleColor in styleColorOptions"
      :key="styleColor.id"
      class="m-2"
      :src="styleColor.images.front.url"
      :size="imageSize"
      @click="onImageClick(styleColor.id)">
      <template #overlay>
        <div
          v-if="internalValue.includes(styleColor.id)"
          class="overlay-selected">
          <svg-circle-icon
            :height="42"
            :width="42"
            :padding="12"
            inverse/>
        </div>
      </template>
    </preview-image-button>
  </div>
</template>

<script>
import PreviewImageButton from '@shared/components/ADORN/PreviewImageButton.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'

export default {
  components: {
    PreviewImageButton,
    SvgCircleIcon
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    styleColorOptions: {
      type: Array,
      required: true
    },
    imageSize: {
      type: String,
      default: 'medium'
    },
    containerClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    internalValue: {
      get () { return this.value },
      set (v) { this.$emit('input', v) }
    }
  },
  methods: {
    onImageClick (styleColorId) {
      const selectedIdx = this.internalValue.indexOf(styleColorId)
      if (selectedIdx === -1) {
        this.internalValue.push(styleColorId)
      } else {
        this.internalValue.splice(selectedIdx, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.overlay-selected {
  width: 100%;
  height: 100%;
  background-color: rgba($secondary, .5);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
