<template>
  <div class="p-3 border">
    <div>
      <sequin-tag
        variant="danger"
        class="mb-3">
        <span v-if="membershipDetails.scheduledCancelDate">Cancellation requested</span>
        <span v-else>Inactive</span>
      </sequin-tag>
    </div>
    <div class="text-gray mb-2">
      {{ inactivePlanText }}
    </div>
  </div>
</template>

<script>
import SequinTag from '../../global/sequin/SequinTag'
import { toLocaleDateString, toMonthDayYearString } from '@/utils/stringParsing.js'

export default {
  name: 'InactivePlanCard',
  components: { SequinTag },
  props: {
    membershipStatus: {
      type: String,
      required: true
    },
    membershipDetails: {
      type: Object,
      required: true
    },
    outstandingItems: {
      type: Number,
      default: 0
    }
  },
  computed: {
    displayReturnDate () {
      return toMonthDayYearString(this.membershipDetails.scheduledCancelDate)
    },
    inactivePlanText () {
      if (this.membershipStatus === 'paused') {
        const resumeDate = toLocaleDateString(this.membershipDetails.pauseResumeDate)
        return `You've skipped a month! Your subscription will automatically resume on ${resumeDate}.`
      } else if (this.membershipDetails.scheduledCancelDate) {
        if (this.outstandingItems) {
          return `Make sure to purchase or drop your ${this.outstandingItems} outstanding items in the mail by
          ${this.displayReturnDate} to avoid any additional charges.`
        }
      }
      return 'Your plan is currently inactive. Rejoin today to get your next case!'
    }
  }
}
</script>
