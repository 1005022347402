<template>
  <div>
    <div>
      {{ address.addressLine1 }}
      {{ address.addressLine2 }}
    </div>
    <div>{{ address.city }}, {{ address.state }} {{ address.zipcode }}</div>
  </div>
</template>

<script>

export default {
  props: {
    address: {
      type: Object,
      required: true
    }
  }
}
</script>
