<template>
  <div class="reviews-summary">
    <div :class="isMobile ? 'p-4':'reviews-summary-wrapper'">
      <div
        v-if="!isMobile"
        class="d-flex reviews-summary-header justify-content-between align-items-center">
        <div
          class="d-flex align-items-center">
          <h4
            class="d-inline pe-2 mb-0">
            Reviews
          </h4>
          <closet-detail-star-rating
            :style-color="styleColor"/>
        </div>
        <div
          class="sort d-flex align-items-center">
          <p
            class="me-3 mb-0 small-text">
            Sort
          </p>
          <dropdown-single-select
            small
            placeholder="Members like me"
            :model-value="sortBy"
            :dropdown-options="sortOptions"
            :initial-selection-index="0"
            @update:modelValue="applySort"/>
        </div>
      </div>

      <closet-review-filters
        v-model="_modelValue"
        :style-color="styleColor"
        @reset-sort="resetSort"/>
      <dropdown-single-select
        v-if="isMobile"
        small
        class="w-100 pt-2 mb-0"
        placeholder="Members like me"
        :model-value="sortBy"
        :dropdown-options="sortOptions"
        :initial-selection-index="0"
        @update:modelValue="applySort"/>
      <div
        v-if="memberPhotoAlbumEntries.length && !isMobile"
        class="pt-4"
        :style="{marginRight: '40px'}">
        <h6 class="mb-3">
          Member Photos ({{ memberPhotoAlbumEntries.length }})
        </h6>
        <closet-photo-scroll
          :style-color-id="styleColor.id"
          :entries="memberPhotoAlbumEntries"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ClosetMemberPhotoMixin } from '../ClosetMemberPhotoMixin'
import { ReviewMixin } from '../ReviewMixin'
import ClosetReviewFilters from './ClosetReviewFilters'
import ClosetPhotoScroll from '../ClosetPhotoScroll'
import ClosetDetailStarRating from '../ClosetDetailStarRating.vue'
import DropdownSingleSelect from '../../global/sequin/DropdownSingleSelect'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    ClosetPhotoScroll,
    ClosetReviewFilters,
    ClosetDetailStarRating,
    DropdownSingleSelect
  },
  mixins: [ReviewMixin, ClosetMemberPhotoMixin],
  props: {
    styleColor: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Array,
      default: undefined
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      sortBy: 'similarity'
    }
  },
  computed: {
    styleColorId () {
      return this.$router.currentRoute.value.params.id
    },
    sortOptions () {
      const sortOptions = [
        { text: 'Members like me', value: 'similarity' },
        { text: 'By date', value: 'created' },
        { text: 'By rating', value: 'rating' }
      ]
      return sortOptions
    },
    _modelValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    sortBy (newValue, oldValue) {
      if ((newValue !== oldValue) && (newValue !== 'similarity')) {
        this.getSortedReviews({
          styleColorId: this.styleColor.id,
          sort: this.sortBy
        })
      }
      if ((newValue !== oldValue) && (newValue === 'similarity')) {
        this.sortBy = 'similarity'
        this.getSortedReviews({
          styleColorId: this.styleColor.id,
          sort: this.sortBy
        })
      }
    }
  },
  methods: {
    ...mapActions('closet', [
      'getSortedReviews'
    ]),
    applySort (selectedOption) {
      this.sortBy = selectedOption
    },
    resetSort () {
      this.sortBy = 'similarity'
    }
  }
}
</script>

<style lang="scss" scoped>
.reviews-summary {
    background-color: $origami;
    margin-left: 0;
}
.reviews-summary-wrapper {
  max-width: 1120px;
  margin: 2em auto 0;
  padding: 0 40px 2.5rem 71px;
}
.reviews-summary-header {
    padding-top: 2rem;
    padding-bottom: 1rem;
}
.number-of-reviews {
    margin-bottom: 0;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
}
:deep(.horizontal-scroll) {
  padding-bottom: 0;
}

:deep(label) {
  margin-bottom: 0;
}
</style>
