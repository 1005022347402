<template>
  <div>
    <!-- Default condition: shows when the user has finished bethebuyer organically. -->
    <style-game-outro
      v-if="!hasBeTheBuyerStylesToRate && beTheBuyerStylesRatedIndex !== 0"
      heading="We've sent your feedback directly to our buying team."
      subheading="Check back tomorrow to vote for more new items!">
      <div>
        <h4
          :class="'play_another marketing text-center'">
          Play another style game
        </h4>
      </div>
      <div class="d-flex align-items-center justify-content-center btn-container mt-0 mb-4">
        <style-game-cta
          :style-game="upcomingStyles"/>
      </div>
    </style-game-outro>
    <!-- condition to handle when the user sees the outro without having rated any styles -->
    <div v-else>
      <div class="mt-3">
        <h4
          :class="'play_another marketing text-center'">
          Play another style game
        </h4>
        <div class="d-flex align-items-center justify-content-center btn-container mt-0 mb-4">
          <style-game-cta
            :style-game="upcomingStyles"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import StyleGameOutro from './StyleGameOutro.vue'
import StyleGameCta from '../closet/styleGames/StyleGameCta.vue'
import SvgHeart from '@/components/global/svg/SvgHeart.vue'

export default {
  components: {
    StyleGameOutro,
    StyleGameCta
  },
  computed: {
    ...mapState('styleGames', [
      'beTheBuyerDailySummary',
      'beTheBuyerStylesRatedIndex'
    ]),
    ...mapGetters('styleGames', [
      'hasUpcomingStylesToRate',
      'hasBeTheBuyerStylesToRate'
    ]),
    upcomingStyles () {
      return Object.assign({
        name: 'New To You',
        description: 'See the newest Armoire inventory and new-to-you styles. Favorite, Collect, and rent today.',
        iconVariant: SvgHeart
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleGamesStyles.scss';
</style>
