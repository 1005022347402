<template>
  <div>
    <style-quiz-banner-image
      :image-styles="{ 'object-position': isMobile ? 'center' : 'top' }"
      :image-name="isMobile ? 'maternity-mobile' : 'maternity-desktop'"/>
    <div
      class="px-4 pb-2"
      :class="{'desktop-vertical-align': !isMobile }">
      <div class="text-center">
        <h6 :class="{'marketing': !isMobile}">
          Congratulations!
        </h6>
        <p class="small-text mb-0 px-5">
          We can’t wait to style your bump!
        </p>
        <p class="small-text mb-4">
          We carry 40 maternity brands and offer post-partum and nursing styles.
        </p>
        <h4>
          When is your due date?
        </h4>
        <div class="d-flex justify-content-center">
          <style-quiz-due-date-input/>
        </div>
      </div>
    </div>
    <style-quiz-bottom-nav
      :validations="v$.dueDate"/>
  </div>
</template>

<script>
import StyleQuizBannerImage from './StyleQuizBannerImage'
import StyleQuizBottomNav from './StyleQuizBottomNav.vue'
import StyleQuizDueDateInput from './StyleQuizDueDateInput'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { dDay } from '../global/vuelidate/customValidators'
import { mapState } from 'vuex'

export default {
  components: {
    StyleQuizBannerImage,
    StyleQuizBottomNav,
    StyleQuizDueDateInput
  },
  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    ...mapState('styleProfile', [
      'dueDate'
    ])
  },
  validations () {
    return {
      dueDate: {
        required,
        dDay
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleQuizStyleDefinitions.scss';
</style>
