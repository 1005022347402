<template>
  <div
    v-if="additionalColors.length">
    <h6 class="mb-3">
      Also available in
    </h6>
    <horizontal-scroll>
      <preview-image-button
        v-for="(styleColorId, index) in additionalColors"
        :key="styleColorId"
        class="me-3"
        size="small"
        :src="styleColorsMap[styleColorId].images.front.url"
        @click="handleThumbnailClick(styleColorId, index)"/>
    </horizontal-scroll>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HorizontalScroll from '../global/sequin/HorizontalScroll'
import PreviewImageButton from '@shared/components/ADORN/PreviewImageButton.vue'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'
import inViewport from 'vue-in-viewport-mixin'
import { track } from '../../plugins/analytics'
import { useFlyouts } from '@/composables/flyouts.js'

export default {
  name: 'ClosetDetailAdditionalColors',
  components: {
    HorizontalScroll,
    PreviewImageButton
  },
  mixins: [StyleColorMixin, inViewport],
  inject: {
    inClosetDetailFlyout: {
      default: false
    }
  },
  props: {
    styleColor: {
      type: Object,
      required: true
    }
  },
  setup () {
    const flyouts = useFlyouts()
    return {
      flyouts
    }
  },
  data () {
    return {
      hasBeenVisible: false
    }
  },
  computed: {
    ...mapState('closet', [
      'styleColorsMap'
    ])
  },
  watch: {
    'inViewport.now': function (visible) {
      if (visible && !this.hasBeenVisible) {
        this.hasBeenVisible = true
        for (let i = 0; i < this.additionalColors.length; i++) {
          track('Viewed Style Color', {
            styleColorId: this.additionalColors[i],
            ...this.getStyleColorSource(this.styleColor.id, i)
          })
        }
      }
    }
  },
  methods: {
    ...mapActions('closet', [
      'setStyleColorSource'
    ]),
    getStyleColorSource (styleColorId, index) {
      return {
        sourceId: this.styleColor.id,
        sourceType: 'additionalcolors',
        sourceName: null,
        sourceLocation: null,
        sourceSlot: null,
        sourcePosition: index + 1
      }
    },
    handleThumbnailClick (styleColorId, index) {
      this.setStyleColorSource({
        styleColorId,
        source: this.getStyleColorSource(styleColorId, index)
      })
      const styleColor = this.styleColorsMap[styleColorId]
      if (this.inClosetDetailFlyout) {
        this.flyouts.openClosetDetailFlyout(styleColor)
      } else {
        this.$router.push({
          name: 'closet-detail',
          params: { id: styleColorId, name: this.detailPageSlug(styleColor) }
        }).catch(() => { /* Allow router to intercept as necessary */ })
      }
    }
  }
}
</script>
