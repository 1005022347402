import { mapState, mapGetters } from 'vuex'

export const PaymentMixin = {
  computed: {
    ...mapState('client', [
      'membershipStatus'
    ]),
    ...mapState('subscribe', [
      'chargeAmount',
      'creditBalance',
      'casePrice',
      'caseTotal',
      'tax',
      'credit',
      'price',
      'discountAmount',
      'referralCampaign',
      'referralCode'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected',
      'discountPriceFromPlan',
      'selectedPlanReferralPrice'
    ]),
    promoEligible () {
      return !(this.membershipStatus === 'formerMember' || this.membershipStatus === 'active')
    },
    promoName () {
      if (!this.promoEligible || !this.membershipPlanSelected.appliedPromo) return ''
      return this.membershipPlanSelected.appliedPromo.promoCode.toUpperCase()
    },
    promoText () {
      if (!this.promoEligible || !this.membershipPlanSelected.appliedPromo || this.promoAmount === 0) return ''
      const months = this.membershipPlanSelected.appliedPromo.firstChargeOverride ? this.membershipPlanSelected.appliedPromo.trialMonths : this.membershipPlanSelected.appliedPromo.durationInMonths
      return (months > 1) ? `${months}-month promo: ${this.promoName}` : this.promoName
    },
    promoAmount () {
      if (this.discountAmount) return Number.parseFloat((this.discountAmount / 100.0)).toFixed(2)
      if (!this.promoEligible || !this.membershipPlanSelected.appliedPromo) return 0
      let amount = 0
      if (this.price != null && this.membershipPlanSelected && !this.membershipPlanSelected?.appliedPromo.firstChargeOverride) {
        amount = (this.membershipPlanSelected.basePrice - this.price)
      }
      amount = amount || (this.membershipPlanSelected.appliedPromo.firstChargeOverride && ((this.membershipPlanSelected.basePrice * this.membershipPlanSelected.appliedPromo.trialMonths) - this.membershipPlanSelected.appliedPromo.firstChargeOverride))
      if (amount % 100 === 0) return amount / 100

      return Number.parseFloat((amount / 100.0)).toFixed(2)
    },
    referralText () {
      if (!this.referralCampaign || !this.referralCode) return ''
      return `${this.referralCampaign.refereePlanPercentOff}% off your first month`
    },
    referralAmount () {
      if (!this.referralCampaign || !this.referralCode) return 0
      const referralPrice = this.membershipPlanSelected.basePrice / 100.0 - this.selectedPlanReferralPrice
      return referralPrice.toFixed(2)
    },
    subtotal () {
      let subtotal = null
      if (this.membershipPlanSelected.appliedPromo?.firstChargeOverride) {
        return this.membershipPlanSelected.appliedPromo.firstChargeOverride / 100
      }

      if (this.price != null) return this.price / 100

      if (this.membershipPlanSelected?.basePrice) {
        subtotal = this.membershipPlanSelected.basePrice / 100
      }

      return subtotal
    },
    total () {
      if (this.chargeAmount !== null) return (this.chargeAmount / 100)
      // if there's no chargeAmount, then the server has not calculated
      // tax (no address yet).  So just show whatever the plan's discounted
      // price is from the promo/referral code
      return this.discountPriceFromPlan(this.membershipPlanSelected)
    }
  },
  methods: {
    chargeIntervalCopy (plan) {
      let interval = ''
      switch (plan.chargeInterval) {
        case 1:
          interval = 'every month'
          break
        case 2:
          interval = 'every 2 months'
          break
        case 3:
          interval = 'every 3 months'
          break
        case 6:
          interval = 'every 6 months'
          break
        case 12:
          interval = 'every 12 months'
          break
      }
      return interval
    },
    promoDiscount (plan) {
      let discountAmount = 0
      if (plan.appliedPromo && plan.appliedPromo.dollarAmount) {
        discountAmount += plan.appliedPromo.dollarAmount
      } else if (plan.appliedPromo && plan.appliedPromo.percentDiscount) {
        discountAmount += plan.basePrice * plan.appliedPromo.percentDiscount
      }
      if (plan.prepaymentDiscount) {
        discountAmount += plan.prepaymentDiscount
      }
      return discountAmount
    }
  }
}
