<template>
  <div
    v-if="!itemsLoading"
    class="grid-wrapper">
    <div
      v-if="items.length > 0"
      class="d-flex flex-wrap">
      <plain-button
        v-for="(item, index) in items"
        :key="item.id"
        class="grid-item"
        :class="{ 'desktop': !isMobile, 'first-row': index < 3 }"
        @click="$emit('item-click', ({id: item.id, type: item.item ? 'feed-item' : 'look'}))">
        <profile-item-grid-image
          :item-image="item.image"
          in-viewport-root-margin="400px 0%"
          :in-viewport-once="true"
          @item-in-view="$emit('item-in-view', index)"/>
      </plain-button>
      <div
        v-for="index in numFillerSquares"
        :key="index"
        :class="{ 'desktop': !isMobile }"
        class="grid-item placeholder"/>
    </div>
    <div v-else>
      <div
        v-if="itemsLoadingError"
        class="empty-state">
        <placeholder-illustration class="mb-4"/>
        <h5>Excuse our wardrobe malfunction</h5>
        We are currently unable to load this user's items.
        <sequin-button
          mobile-block
          type="router-link"
          class="mt-4"
          :to="{ name: 'feed' }"
          variant="secondary">
          Back to Feed
        </sequin-button>
      </div>
      <div
        v-else-if="isOwnProfile"
        class="empty-state">
        <placeholder-illustration class="mb-3"/>
        <h5>You haven't shared any looks yet!</h5>
        Sharing a look is a great way to show your style (and spread your renting wisdom) to the Armoire community
        <sequin-button
          mobile-block
          class="mt-4"
          @click="isMobile ? $router.push({ name: 'share-a-look' }) : openModal({'name': 'share-a-look'})">
          Share a Look
        </sequin-button>
      </div>
      <div
        v-else
        class="empty-state extra-padding">
        <placeholder-illustration class="mb-4"/>
        <h5>{{ firstName }} currently has no looks shared</h5>
        <sequin-button
          mobile-block
          type="router-link"
          class="mt-4"
          :to="{ name: 'feed' }"
          variant="secondary">
          Back to Feed
        </sequin-button>
      </div>
    </div>
  </div>
  <div
    v-else
    class="d-flex flex-wrap">
    <div
      v-for="index in numPlaceholderItems"
      :key="index"
      :class="{ 'desktop': !isMobile, 'first-row': index < 3 }"
      class="grid-item placeholder animated"/>
  </div>
</template>

<script>
import ProfileItemGridImage from './ProfileItemGridImage'
import SequinButton from '../../global/sequin/SequinButton'
import PlainButton from '../../global/sequin/PlainButton'
import PlaceholderIllustration from '../common/PlaceholderIllustration'
import inViewport from 'vue-in-viewport-mixin'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    ProfileItemGridImage,
    PlainButton,
    PlaceholderIllustration,
    SequinButton
  },
  mixins: [inViewport],
  props: {
    itemsLoading: {
      type: Boolean,
      default: true
    },
    itemsLoadingError: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    isOwnProfile: {
      type: Boolean,
      default: false
    },
    firstName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      numPlaceholderItems: 9
    }
  },
  computed: {
    // Fil out to 9, or fill out this row of 3
    numFillerSquares () {
      const numItems = this.items.length
      if (numItems < this.numPlaceholderItems) {
        return this.numPlaceholderItems - numItems
      }
      return numItems % 3 > 0 ? 3 - numItems % 3 : 0
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ])
  }
}
</script>

<style lang="scss" scoped>
.empty-state {
  min-height: 50vh;
  padding: 40px 52px;
  text-align: center;
  display: flex;
  background: $origami;
  align-items: center;
  flex-direction: column;

  &.extra-padding {
    padding: 8vh 52px;
  }
}

.grid-wrapper {
  margin: 0 -0.5px;
  overflow: hidden;
}

.grid-item {
  width: 33.3%;
  height: 33vw !important;
  padding: 1px .5px;

  &.first-row {
    padding-top: 0;
    border-top: none;
  }

  &.desktop {
    height: 166px !important;
  }

  &.placeholder {
    background: $origami;
    border: .5px solid $white;
    padding: 0;
  }

  &.animated {
    background: unset;
    @include placeholder-loading-animation;
  }

}
</style>
