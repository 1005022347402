// Creates get/set patterns so that you can directly update
// vuex state.

export function vuexAccessors (vModule, attrs = []) {
  const obj = {}
  for (const attr of attrs) {
    obj[attr] = {
      get () {
        return this.$store.state[vModule][attr]
      },
      set (v) {
        this.$store.commit(
          vModule + '/SET_' + attr.replace(/([A-Z])/g, '_$1').toUpperCase(), v)
      }
    }
  }
  return obj
}
