<template>
  <div class="py-3">
    <div v-if="submitted">
      <div class="popper">
        🎉
      </div>
      <div class="all-set">
        {{ successHeader }}
      </div>
      {{ successBody }}
    </div>
    <form
      v-else
      class="form"
      @submit.prevent="onSubmit">
      <div class="row align-items-center">
        <div class="col col-12 col-md-8">
          <base-input
            v-model="email"
            type="email"
            autocomplete="email"
            :validations="v$.email"
            label="Email Address"/>
        </div>
        <div class="col col-12 col-md-4">
          <sequin-button
            block
            class="submit"
            @click="onSubmit">
            {{ buttonText }}
          </sequin-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SequinButton from '../global/sequin/SequinButton'
import BaseInput from '../global/BaseInput'
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import apiGlobal from '../../store/api/apiGlobal'

export default {
  name: 'EmailCapture',
  components: { SequinButton, BaseInput },
  props: {
    source: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      default: 'Save 25%'
    },
    successHeader: {
      type: String,
      default: 'All set!'
    },
    successBody: {
      type: String,
      default: 'Your promo code is on its way.'
    },
    offer: {
      type: String,
      default: 'Shopify25Off'
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      email: '',
      submitted: false,
      showError: false
    }
  },
  methods: {
    onSubmit () {
      this.v$.$touch()
      if (this.v$.$invalid) return
      apiGlobal.subscribeToMarketingEmails({ email: this.email, source: this.source, offer: this.offer })
      this.submitted = true
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss" scoped>
  .popper {
    font-size: 24px;
  }

  .all-set {
    margin-top: 8px;
    font-weight: 600;
  }

</style>
