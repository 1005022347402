import api from '../api/api'
import { getSetters, parseError } from './helpers/shared'
import logger from '@/logger'

// initial state
export const state = {
  // consts
  FEED_ITEM_PRIVATE: 0,
  FEED_ITEM_PUBLIC: 10,
  MAX_BIO_LENGTH: 1000,
  MAX_LOCATION_LENGTH: 200,

  // feed, feed items
  feedItemFollowingIds: [],
  feedItemsById: {},
  feedItemsSource: '',
  feedItemComments: {},
  feedItemLikes: {},
  feedItemLikesAndFavorites: {},
  feedLoading: true,
  feedIsStale: false,
  feedItemsNextPage: null,
  feedItemsFollowingNextPage: null,
  userFeedLikes: [],

  // Helps us know whether to scroll to caption
  // on deeplink page based on how user got there
  deeplinkAction: null,

  // share and edit look
  lookBuilder: {
    caption: '',
    images: [],
    styleColors: [],
    tags: []
  },
  occasionTags: [],

  // For profile views
  clientInfoById: {},
  feedItemsByClientId: {},
  clientFeedItemsNextPage: null,
  clientRentalHistoryById: {},
  clientRentalStatsById: {},

  followersById: {},
  followingById: {},
  suggestedFollowers: [],
  addingSuggestedFollowers: false,

  // for tag views
  feedItemsByTag: {},

  // For edit profile
  editProfileUpdates: {
    bio: null,
    location: null,
    profilePic: null,
    displayFirstNameOnly: false,
    displayRentalHistory: true,
    associateProfileWithReviews: true,
    shareLooks: 'Members',
    feedSummary: true,
    displaySizes: false
  },
  isEditingProfile: false,

  // For client's own profile, if it is private
  clientLooksById: {},
  clientLooksNextPage: null,

  editProfileComponent: 'EditProfile',
  shareLookComponent: 'Core',

  // For edit and delete scenarios
  itemInFocus: null,

  lookImageUploadUrlFunc: api.apiClient.getImageUploadUrl,
  genericFeedItemImageUploadUrlFunc: api.apiClient.getGenericImageUploadUrl,
  profileImageUploadUrlFunc: api.apiClient.getProfileImageUploadUrl,

  profileShareToken: null
}

// Normalizes looks returned from server to be more similar to feed items
// Makes it easier to render private looks across our components
function normalizeLook (look) {
  return {
    ...look,
    image: look.images[0].url,
    styleColors: look.styleColors.map(styleColor => {
      return {
        id: styleColor.id,
        image: {
          url: styleColor.images.front.url
        }
      }
    })
  }
}

function compileLookDataForServer (state, rootState) {
  return {
    ...state.lookBuilder,
    styleColors: state.lookBuilder.styleColors.map(styleColor => styleColor.id),
    tags: state.lookBuilder.tags.map(tag => tag.tag),
    visibility: rootState.client.settings.privacy.shareLooks ? state.FEED_ITEM_PUBLIC : state.FEED_ITEM_PRIVATE
  }
}

function addAnalyticsMetrics (state, data) {
  return {
    ...data,
    analyticsMetrics: {
      styleColors: Object.values(state.feedItemsById)[0].styleColors.map(styleColor => styleColor.id)
    }
  }
}

export const getters = {
  getEditProfileHasValidUpdates: (state, getters, rootState) => {
    const { bio, location, profilePic, shareLooks, displayFirstNameOnly, displayRentalHistory, associateProfileWithReviews, feedSummary, displaySizes } = state.editProfileUpdates

    const hasUpdates = bio !== rootState.client.bio ||
      location !== rootState.client.location ||
      profilePic !== rootState.client.profilePic ||
      shareLooks !== rootState.client.settings.privacy.shareLooks ||
      displayFirstNameOnly !== rootState.client.settings.privacy.displayFirstNameOnly ||
      displayRentalHistory !== rootState.client.settings.privacy.displayRentalHistory ||
      associateProfileWithReviews !== rootState.client.settings.privacy.associateProfileWithReviews ||
      feedSummary !== rootState.client.settings.notifications.feedSummary ||
      displaySizes !== rootState.client.settings.privacy.displaySizes

    return hasUpdates && (!bio || bio.length <= state.MAX_BIO_LENGTH) && (!location || location.length <= state.MAX_LOCATION_LENGTH)
  },
  clientProfile: (state, getters, { client: { id, firstName, lastName, location, profilePic } }) => {
    return { id, firstName, lastName, location, profilePic }
  },
  clientCanViewCommunity: (state, getters, rootState) => {
    return rootState.client.canViewFeed
  },
  clientCanInteractCommunity: (state, getters, rootState, rootGetters) => {
    return rootGetters['client/active']
  },
  clientCanShareLook: (state, getters, rootState) => {
    return getters.clientCanInteractCommunity && rootState.client.shippedPackages
  },
  feedItemMetadata: () => (feedItem) => {
    return feedItem.metadata ? JSON.parse(feedItem.metadata) : {}
  },
  allRentalHistoryLoading: state => clientId => {
    return state.clientRentalHistoryById[clientId]
      ? state.clientRentalHistoryById[clientId]?.rentalHistory.length !== state.clientRentalHistoryById[clientId]?.count
      : true
  },
  isFollowing: (state, getters, rootState) => (targetId) => {
    const clientId = rootState.client.id
    if (Object.prototype.hasOwnProperty.call(state.followingById, clientId)) {
      return state.followingById[clientId].results.filter(x => x.id === targetId).length > 0
    }
    return false
  },
  followers: (state) => (targetId) => {
    if (Object.prototype.hasOwnProperty.call(state.followersById, targetId)) {
      return state.followersById[targetId].count
    }
    return '---'
  },
  following: (state) => (targetId) => {
    if (Object.prototype.hasOwnProperty.call(state.followingById, targetId)) {
      return state.followingById[targetId].count
    }
    return '---'
  }
}

export const actions = {
  async getFeedItems ({ state, commit, dispatch, rootGetters }, source = '') {
    try {
      // don't make the API call if plan doesn't allow it
      if (!rootGetters['client/hasPlanTierPermission']('view_feed')) return

      if (source === state.feedItemsSource) {
        // nothing to do; it's already been fetched
        return
      }
      let feedItems = null
      if ((source === 'following' && state.feedItemFollowingIds.length === 0) ||
          (source !== 'following' && Object.keys(state.feedItemsById).length === 0)) {
        commit('SET_FEED_LOADING', true)
      }
      if (source === 'all') {
        feedItems = await api.apiClient.getFeedItems()
      } else {
        feedItems = await api.apiClient.getFeedItems(null, null, null, source)
      }
      const { data: { results, next } } = feedItems
      const feedItemIds = results.map(({ id }) => id)
      dispatch('getUserFeedLikes')
      if (source === 'following') {
        commit('SET_FEED_ITEM_FOLLOWING_IDS', feedItemIds)
        commit('SET_FEED_ITEMS_FOLLOWING_NEXT_PAGE', next)
      } else {
        commit('SET_FEED_ITEMS_NEXT_PAGE', next)
      }
      commit('SET_FEED_ITEMS_SOURCE', source)
      setTimeout(async () => {
        // don't cache the feed forever; this will make sure we'll fetch
        // it the next time it's requested after 10 minutes have passed
        commit('SET_FEED_ITEMS_SOURCE', '')
      }, 1000 * 60 * 10)
      commit('SET_FEED_ITEMS_BY_ID', results)
      commit('SET_FEED_LOADING', false)
      commit('SET_FEED_IS_STALE', false)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getFeedItemsNextPage ({ state, commit }, source = '') {
    try {
      if (source === 'following' && state.feedItemsFollowingNextPage) {
        const { data: { results, next } } = await api.apiClient.getNextPage(state.feedItemsFollowingNextPage)
        const feedItemIds = results.map(({ id }) => id)
        commit('SET_FEED_ITEMS_BY_ID', results)
        commit('APPEND_FEED_ITEM_FOLLOWING_IDS', feedItemIds)
        commit('SET_FEED_ITEMS_FOLLOWING_NEXT_PAGE', next)
      } else if (state.feedItemsNextPage) {
        const { data: { results, next } } = await api.apiClient.getNextPage(state.feedItemsNextPage)
        commit('SET_FEED_ITEMS_BY_ID', results)
        commit('SET_FEED_ITEMS_NEXT_PAGE', next)
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  async getFeedItem ({ commit, dispatch }, feedItemId) {
    try {
      dispatch('getUserFeedLikes')
      const feedItem = await api.apiClient.getFeedItem(feedItemId)
      commit('SET_FEED_ITEM_BY_ID', feedItem.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getFeedItemComments ({ commit }, feedItemId) {
    try {
      const feedItemComments = await api.apiClient.getFeedItemComments(feedItemId)
      commit('SET_FEED_ITEM_COMMENTS', { comments: feedItemComments.data, feedItemId })
    } catch (err) {
      throw parseError(err)
    }
  },
  async getFeedItemLikes ({ commit, rootState }, feedItemId) {
    try {
      const feedItemLikes = await api.apiClient.getFeedItemLikes(feedItemId)
      commit('SET_FEED_ITEM_LIKES', { likes: feedItemLikes.data, feedItemId, clientId: rootState.client.id })
    } catch (err) {
      throw parseError(err)
    }
  },
  async getFeedItemLikesAndFavorites ({ commit }, feedItemId) {
    try {
      const res = await api.apiCommunity.getFeedItemLikesAndFavorites(feedItemId)
      commit('SET_FEED_ITEM_LIKES_AND_FAVORITES', { feedItemId, data: res.data })
    } catch (err) {
      throw parseError(err)
    }
  },
  async toggleLikeFeedItem ({ commit, getters, state: { userFeedLikes } }, feedItemId) {
    const liked = userFeedLikes.includes(feedItemId)
    if (liked) {
      commit('UNLIKE_FEED_ITEM', feedItemId)
    } else {
      commit('LIKE_FEED_ITEM', feedItemId)
    }

    try {
      let data = { like: !liked }
      if (!liked) {
        data = addAnalyticsMetrics(state, data)
      }
      const res = await api.apiClient.postFeedItemReaction(feedItemId, data)
      commit('UPDATE_LIKES_AND_FAVORITES', { feedItemId, data: res.data })
      commit('UPDATE_FIRST_INTERACTION_CLIENT', { feedItemId, clientProfile: getters.clientProfile })
    } catch (err) {
      logger.error(err)
    }
  },
  async postGenericFeedItem ({ dispatch, state }, data) {
    try {
      await api.apiClient.postGenericFeedItem({ ...data, visibility: state.FEED_ITEM_PUBLIC })

      // retrieve new set of feed items
      dispatch('getFeedItems')
    } catch (err) {
      throw parseError(err)
    }
  },
  async postLook ({ dispatch, state, rootState }) {
    try {
      const data = addAnalyticsMetrics(state, compileLookDataForServer(state, rootState))
      await api.apiClient.postLook(data)

      // success, now clear out look builder and
      // retrieve new set of feed items
      dispatch('getFeedItems')
      dispatch('clearLookBuilder')
    } catch (err) {
      throw parseError(err)
    }
  },
  async updateLook ({ dispatch, state, rootState }, { feedItemId, lookId }) {
    try {
      const data = compileLookDataForServer(state, rootState)
      await api.apiClient.patchLook(data, lookId)

      // success, now retrieve updated feed item or look
      // and clear out the look builder
      if (feedItemId) {
        await dispatch('getFeedItem', feedItemId)
      } else {
        await dispatch('getLook', lookId)
      }

      dispatch('clearLookBuilder')
    } catch (err) {
      throw parseError(err)
    }
  },
  async deleteLook ({ commit }, { feedItemId, lookId }) {
    try {
      await api.apiClient.deleteLook(lookId)

      commit('REMOVE_FEED_ITEM', feedItemId)
      commit('REMOVE_CLIENT_LOOK', lookId)
    } catch (err) {
      throw parseError(err)
    }
  },
  async deleteFeedItem ({ commit }, { feedItemId }) {
    try {
      await api.apiClient.deleteFeedItem(feedItemId)
      commit('REMOVE_FEED_ITEM', feedItemId)
    } catch (err) {
      throw parseError(err)
    }
  },
  async deleteGenericFeedItem ({ commit }, { feedItemId, genericFeedItemId }) {
    try {
      await api.apiClient.deleteGenericFeedItem(genericFeedItemId)
      commit('REMOVE_FEED_ITEM', feedItemId)
    } catch (err) {
      throw parseError(err)
    }
  },
  async postFeedItemComment ({ dispatch }, commentData) {
    try {
      await api.apiClient.postFeedItemComment(addAnalyticsMetrics(state, commentData))

      // success, now retrieve feed item comments so that we can show
      // the most up-to-date version
      await dispatch('getFeedItemComments', commentData.feedItem)
      dispatch('getFeedItem', commentData.feedItem)
    } catch (err) {
      throw parseError(err)
    }
  },
  async updateFeedItemComment ({ dispatch }, { feedItemId, commentId, comment }) {
    try {
      await api.apiClient.patchFeedItemComment(commentId, { feedItem: feedItemId, comment })

      // success, now retrieve updated comments
      await dispatch('getFeedItemComments', feedItemId)
      dispatch('getFeedItem', feedItemId)
    } catch (err) {
      throw parseError(err)
    }
  },
  async deleteFeedItemComment ({ dispatch }, { feedItemId, commentId }) {
    try {
      await api.apiClient.deleteFeedItemComment(commentId)

      // success, now retrieve feed item comments updated
      await dispatch('getFeedItemComments', feedItemId)
      dispatch('getFeedItem', feedItemId)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getAllClientRentalHistory ({ state, dispatch }, { clientId, shareToken }) {
    let history = state.clientRentalHistoryById[clientId]
    while (!history || history.next) {
      await dispatch('getClientRentalHistory', { clientId: clientId, shareToken: shareToken })
      history = state.clientRentalHistoryById[clientId]
    }
  },
  async getClientRentalHistory ({ commit, state }, { clientId, shareToken, filter }) {
    try {
      if (filter === '') {
        commit('SET_CLIENT_FILTERED_RENTAL_HISTORY', {
          clientId,
          data: {
            filteredRentalHistory: null
          }
        })
      } else if (filter) {
        const res = await api.apiClient.getRentalHistory(clientId, shareToken, filter)
        commit('SET_CLIENT_FILTERED_RENTAL_HISTORY', {
          clientId,
          data: {
            filteredRentalHistory: res.data
          }
        })
      } else {
        const previous = state.clientRentalHistoryById[clientId]
        if (!previous) {
          const res = await api.apiClient.getRentalHistory(clientId, shareToken, filter)
          commit('SET_CLIENT_RENTAL_HISTORY', {
            clientId,
            data: {
              filteredRentalHistory: null,
              count: res.data.count,
              rentalHistory: res.data.results,
              next: res.data.next
            }
          })
        } else {
          if (previous.next) {
            const res = await api.apiClient.getNextPage(previous.next)
            commit('APPEND_CLIENT_RENTAL_HISTORY', {
              clientId,
              data: {
                count: res.data.count,
                rentalHistory: res.data.results,
                next: res.data.next
              }
            })
          }
        }
      }
    } catch (err) {
      if (err?.response?.status === 404) commit('SET_CLIENT_RENTAL_HISTORY', { clientId, rentalHistory: [] })
      throw parseError(err)
    }
  },
  async getOccasionTags ({ commit }) {
    try {
      const occasionTags = await api.apiClient.getSystemTags('occasion')
      commit('SET_OCCASION_TAGS', occasionTags.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getClientFeedItems ({ commit }, client) {
    try {
      const feedItems = await api.apiClient.getFeedItems(client)
      commit('SET_FEED_ITEMS_BY_CLIENT_ID', { clientId: client, feedItems: feedItems.data.results })
      commit('SET_CLIENT_FEED_ITEMS_NEXT_PAGE', feedItems.data.next)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getClientFeedItemsNextPage ({ commit, state }, client) {
    try {
      if (state.clientFeedItemsNextPage) {
        const feedItems = await api.apiClient.getNextPage(state.clientFeedItemsNextPage)
        commit('APPEND_FEED_ITEMS_BY_CLIENT_ID', { clientId: client, feedItems: feedItems.data.results })
        commit('SET_FEED_ITEMS_BY_ID', feedItems.data.results)
        commit('SET_CLIENT_FEED_ITEMS_NEXT_PAGE', feedItems.data.next)
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  async getTagFeedItems ({ commit }, { tag, tagId }) {
    try {
      const feedItems = await api.apiClient.getFeedItems(null, tag, tagId)
      commit('SET_FEED_ITEMS_BY_TAG', { tag: tag, feedItems: feedItems.data })
    } catch (err) {
      throw parseError(err)
    }
  },
  async getTagFeedItemsNextPage ({ commit, state }, tag) {
    try {
      const feedItemsForTag = state.feedItemsByTag[tag]
      if (feedItemsForTag.next) {
        const feedItems = await api.apiClient.getNextPage(feedItemsForTag.next)
        commit('APPEND_FEED_ITEMS_BY_TAG', { tag: tag, feedItems: feedItems.data })
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  async getLook ({ commit }, lookId) {
    try {
      const look = await api.apiClient.getLook(lookId)
      commit('SET_CLIENT_LOOKS_BY_ID', [normalizeLook(look.data)])
    } catch (err) {
      throw parseError(err)
    }
  },
  async getClientLooks ({ commit }, { client, shareToken }) {
    try {
      const looks = await api.apiClient.getLooks(client, shareToken)
      commit('SET_CLIENT_LOOKS_BY_ID', looks.data.results.map(look => normalizeLook(look)))
      commit('SET_CLIENT_LOOKS_NEXT_PAGE', looks.data.next)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getClientLooksNextPage ({ state, commit }) {
    try {
      if (state.clientLooksNextPage) {
        const looks = await api.apiClient.getNextPage(state.clientLooksNextPage)
        commit('SET_CLIENT_LOOKS_BY_ID', looks.data.results.map(look => normalizeLook(look)))
        commit('SET_CLIENT_LOOKS_NEXT_PAGE', looks.data.next)
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  async getClientProfile ({ commit }, { clientId, shareToken }) {
    try {
      const clientCommunityProfile = await api.apiClient.getClientPublicProfile(clientId, shareToken)
      commit('SET_CLIENT_INFO', clientCommunityProfile.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async unfollowClient ({ commit, dispatch, rootState }, clientIdToUnFollow) {
    try {
      await api.apiClient.unfollowClient(clientIdToUnFollow)
      commit('UNFOLLOW_CLIENT', { clientId: rootState.client.id, unfollowId: clientIdToUnFollow })
      await dispatch('getFeedItems', 'following')
    } catch (err) {
      logger.error(err)
      throw parseError(err)
    }
  },
  async followClient ({ commit, dispatch, rootState }, clientIdToFollow) {
    try {
      const results = await api.apiClient.followClient(clientIdToFollow)
      commit('FOLLOW_CLIENT', { clientId: rootState.client.id, followId: clientIdToFollow, data: results.data })
      await dispatch('getFeedItems', 'following')
    } catch (err) {
      logger.error(err)
      throw parseError(err)
    }
  },
  async getClientFollowers ({ commit }, { clientId, shareToken }) {
    try {
      if (Object.prototype.hasOwnProperty.call(state.followersById, clientId)) {
        // already exists, get next page
        if (state.followersById[clientId].next) {
          const followers = await api.apiClient.getNextPage(state.followersById[clientId].next)
          commit('APPEND_FOLLOWERS_BY_ID', { clientId: clientId, followers: followers.data })
        }
      } else {
        const followers = await api.apiClient.getClientFollowers(clientId, shareToken)
        commit('SET_FOLLOWERS_BY_ID', { clientId: clientId, followers: followers.data })
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  async getClientFollowing ({ commit }, { clientId, shareToken }) {
    try {
      if (Object.prototype.hasOwnProperty.call(state.followingById, clientId)) {
        // already exists, get next page
        if (state.followingById[clientId].next) {
          const following = await api.apiClient.getNextPage(state.followingById[clientId].next)
          commit('APPEND_FOLLOWING_BY_ID', { clientId: clientId, following: following.data })
        }
      } else {
        const following = await api.apiClient.getClientFollowing(clientId, shareToken)
        commit('SET_FOLLOWING_BY_ID', { clientId: clientId, following: following.data })
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  async getSuggestedToFollow ({ commit, state }) {
    try {
      if (state.suggestedFollowers.length === 0) {
        const suggestions = await api.apiClient.getSuggestedToFollow()
        commit('SET_SUGGESTED_FOLLOWERS', suggestions.data)
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  updateEditProfile ({ commit }, data) {
    commit('SET_EDIT_PROFILE', data)
  },
  async updateClientProfile ({ commit, dispatch, state, rootState }) {
    try {
      const updates = state.editProfileUpdates

      // We currently default privacy setting display location
      // to false. If user adds a location, we want to show it,
      // so set to true with this call
      if (updates.location !== null && updates.location !== '') {
        updates.displayLocation = true
      }

      // Need everything to be a string for the server
      // And no null values
      const serverUpdates = {}
      Object.keys(updates).forEach(key => {
        if (updates[key]) {
          serverUpdates[key] = updates[key].toString()
        } else {
          serverUpdates[key] = ''
        }
      })

      await api.apiClient.patchCommunityProfile(serverUpdates)

      // success, now update our client information
      const {
        bio, location, profilePic, shareLooks, displayFirstNameOnly,
        displayLocation, displayRentalHistory, associateProfileWithReviews, feedSummary, displaySizes
      } = updates
      const localUpdates = {
        bio,
        location,
        profilePic,
        settings: {
          privacy: { shareLooks, displayFirstNameOnly, displayRentalHistory, associateProfileWithReviews, displayLocation, displaySizes },
          notifications: { feedSummary }
        }
      }

      commit('client/UPDATE_CLIENT', localUpdates, { root: true })
      commit('SET_FEED_IS_STALE', true)
      await dispatch('getClientProfile', { clientId: rootState.client.id })
    } catch (err) {
      throw parseError(err)
    }
  },
  clearLookBuilder ({ commit }, timeout = 500) {
    // Allow a little time for closing modal or
    // page that is currently using the look builder data
    setTimeout(() => {
      commit('SET_LOOK_BUILDER_TAGS', [])
      commit('SET_LOOK_BUILDER_STYLE_COLORS', [])
      commit('SET_LOOK_BUILDER_CAPTION', '')
      commit('SET_LOOK_BUILDER_IMAGES', [])
    }, timeout)
  },
  initializeLookBuilder ({ commit }, { item }) {
    commit('SET_LOOK_BUILDER_TAGS', item.tags)
    commit('SET_LOOK_BUILDER_STYLE_COLORS', item.styleColors)
    commit('SET_LOOK_BUILDER_CAPTION', item.caption)
    commit('SET_LOOK_BUILDER_IMAGES', [item.image])
  },
  cleanupEditProfile ({ commit }, timeout = 500) {
    // Allow a little time for closing modal or
    // page that is currently using the edit profile data
    setTimeout(() => {
      commit('SET_EDIT_PROFILE', {
        bio: null,
        location: null,
        profilePic: null,
        displayFirstNameOnly: false,
        displayRentalHistory: true,
        associateProfileWithReviews: true,
        shareLooks: true,
        feedSummary: true,
        displaySizes: false
      })
      commit('SET_IS_EDITING_PROFILE', false)
    }, timeout)
  },
  initializeEditProfile ({ commit, rootState }) {
    const { bio, location, profilePic, settings } = rootState.client
    const { displayFirstNameOnly, shareLooks, displaySizes, displayRentalHistory, associateProfileWithReviews } = settings.privacy
    const { feedSummary } = settings.notifications

    commit('SET_EDIT_PROFILE', {
      bio,
      location,
      profilePic,
      displayFirstNameOnly,
      displayRentalHistory,
      associateProfileWithReviews,
      shareLooks,
      feedSummary,
      displaySizes
    })

    commit('SET_IS_EDITING_PROFILE', true)
  },
  async getUserFeedLikes ({ commit }) {
    try {
      const res = await api.apiClient.getUserFeedLikes()
      commit('SET_USER_FEED_LIKES', res.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getProfileShareToken ({ commit, rootState }) {
    try {
      const res = await api.apiClient.getShareToken('profile', rootState.client.id)
      commit('SET_PROFILE_SHARE_TOKEN', res.data.token)
    } catch (err) {
      throw parseError(err)
    }
  }
}

export const mutations = {
  ...getSetters(state),
  'LIKE_FEED_ITEM' (state, feedItemId) {
    state.userFeedLikes.push(feedItemId)
    state.feedItemsById[feedItemId].likes = {
      total: state.feedItemsById[feedItemId].likes.total + 1,
      first: state.feedItemsById[feedItemId].likes.first
    }
  },
  'UNLIKE_FEED_ITEM' (state, feedItemId) {
    const index = state.userFeedLikes.findIndex(id => id === feedItemId)
    state.userFeedLikes.splice(index, 1)
    state.feedItemsById[feedItemId].likes = {
      total: state.feedItemsById[feedItemId].likes.total - 1,
      first: state.feedItemsById[feedItemId].likes.first
    }
  },
  'SET_FEED_ITEMS_BY_ID' (state, feedItems) {
    feedItems.forEach(feedItem => {
      state.feedItemsById[feedItem.id] = feedItem
    })
  },
  'SET_FEED_ITEM_BY_ID' (state, feedItem) {
    state.feedItemsById[feedItem.id] = feedItem
  },
  'APPEND_FEED_ITEM_FOLLOWING_IDS' (state, feedItemIds) {
    state.feedItemFollowingIds = state.feedItemFollowingIds.concat(feedItemIds)
  },
  'REMOVE_FEED_ITEM' (state, feedItemId) {
    delete state.feedItemsById[feedItemId]
  },
  'SET_FEED_ITEM_COMMENTS' (state, { comments, feedItemId }) {
    state.feedItemComments[feedItemId] = comments.reverse()
  },
  'SET_FEED_ITEM_LIKES' (state, { likes, feedItemId, clientId }) {
    state.feedItemLikes[feedItemId] = likes
    state.feedItemsById[feedItemId].likes = { total: likes.length, first: likes.length ? likes[0] : null, self: likes.map(likeUser => likeUser.id).indexOf(clientId) > -1 }
  },
  'SET_FEED_ITEM_LIKES_AND_FAVORITES' (state, { feedItemId, data }) {
    state.feedItemLikesAndFavorites[feedItemId] = data
  },
  'SET_LOOK_BUILDER_TAGS' (state, tags) {
    state.lookBuilder.tags = tags
  },
  'SET_LOOK_BUILDER_STYLE_COLORS' (state, styles) {
    state.lookBuilder.styleColors = styles
  },
  'SET_LOOK_BUILDER_IMAGES' (state, images) {
    state.lookBuilder.images = images
  },
  'SET_LOOK_BUILDER_CAPTION' (state, caption) {
    state.lookBuilder.caption = caption
  },
  'SET_EDIT_PROFILE' (state, updates) {
    Object.keys(updates).forEach(updateKey => {
      state.editProfileUpdates[updateKey] = updates[updateKey]
    })
  },
  'SET_CLIENT_INFO' (state, client) {
    state.clientInfoById[client.id] = client
  },
  'SET_CLIENT_RENTAL_HISTORY' (state, { clientId, data }) {
    state.clientRentalHistoryById[clientId] = data
  },
  'SET_CLIENT_FILTERED_RENTAL_HISTORY' (state, { clientId, data }) {
    state.clientRentalHistoryById[clientId].filteredRentalHistory = data.filteredRentalHistory
  },
  'APPEND_CLIENT_RENTAL_HISTORY' (state, { clientId, data }) {
    state.clientRentalHistoryById[clientId].rentalHistory = state.clientRentalHistoryById[clientId].rentalHistory.concat(data.rentalHistory)
    state.clientRentalHistoryById[clientId].next = data.next
  },
  'SET_FEED_ITEMS_BY_CLIENT_ID' (state, { clientId, feedItems }) {
    state.feedItemsByClientId[clientId] = feedItems
  },
  'APPEND_FEED_ITEMS_BY_CLIENT_ID' (state, { clientId, feedItems }) {
    state.feedItemsByClientId[clientId] = state.feedItemsByClientId[clientId].concat(feedItems)
  },
  'SET_FEED_ITEMS_BY_TAG' (state, { tag, feedItems }) {
    state.feedItemsByTag[tag] = feedItems
  },
  'APPEND_FEED_ITEMS_BY_TAG' (state, { tag, feedItems }) {
    state.feedItemsByTag[tag].results = state.feedItemsByTag[tag].results.concat(feedItems.results)
    state.feedItemsByTag[tag].next = feedItems.next
  },
  'SET_FOLLOWERS_BY_ID' (state, { clientId, followers }) {
    state.followersById[clientId] = followers
  },
  'APPEND_FOLLOWERS_BY_ID' (state, { clientId, followers }) {
    state.followersById[clientId].results = state.followersById[clientId].results.concat(followers.results)
    state.followersById[clientId].next = followers.next
  },
  'SET_FOLLOWING_BY_ID' (state, { clientId, following }) {
    state.followingById[clientId] = following
  },
  'APPEND_FOLLOWING_BY_ID' (state, { clientId, following }) {
    state.followingById[clientId].results = state.followingById[clientId].results.concat(following.results)
    state.followingById[clientId].next = following.next
  },
  'UNFOLLOW_CLIENT' (state, { clientId, unfollowId }) {
    state.followingById[clientId].results = state.followingById[clientId].results.filter((x) => {
      return x.id !== unfollowId
    })
    state.followingById[clientId].count -= 1
    // remove the follower from the target f necessary
    if (Object.prototype.hasOwnProperty.call(state.followersById, unfollowId)) {
      state.followersById[unfollowId].results = state.followersById[unfollowId].results.filter((x) => {
        return x.id !== clientId
      })
      state.followersById[unfollowId].count -= 1
    }
  },
  'FOLLOW_CLIENT'  (state, { clientId, followId, data }) {
    if (Object.prototype.hasOwnProperty.call(state.followingById, clientId)) {
      state.followingById[clientId].results.unshift(data.target)
      state.followingById[clientId].count += 1
    } else {
      state.followingById[clientId] = {
        results: [data.target],
        count: 1,
        next: null
      }
    }
    // mark that the target has a follower
    if (Object.prototype.hasOwnProperty.call(state.followersById, followId)) {
      state.followersById[followId].results.unshift(data.follower)
      state.followersById[followId].count += 1
    } else {
      state.followersById[followId] = {
        results: [data.follower],
        count: 1,
        next: null
      }
    }
  },
  'SET_CLIENT_LOOKS_BY_ID' (state, looks) {
    // state.clientLooksById = {}
    looks.forEach(look => {
      state.clientLooksById[look.id] = look
    })
  },
  'REMOVE_CLIENT_LOOK' (state, lookId) {
    delete state.clientLooksById[lookId]
  },
  'UPDATE_LIKES_AND_FAVORITES' (state, { feedItemId, data }) {
    state.feedItemsById[feedItemId].likesAndFavorites = {
      ...state.feedItemsById[feedItemId].likesAndFavorites,
      ...data
    }
  },
  'UPDATE_FIRST_INTERACTION_CLIENT' (state, { feedItemId, clientProfile }) {
    const total = state.feedItemsById[feedItemId].likesAndFavorites.total
    if (total === 1) {
      state.feedItemsById[feedItemId].likesAndFavorites.first = clientProfile
    } else if (total === 0) {
      state.feedItemsById[feedItemId].likesAndFavorites.first = null
    }
  }
}
