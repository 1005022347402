<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    :stroke="stroke"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-chevron-left">
    <polyline points="15 18 9 12 15 6"/>
  </svg>
</template>
<script>
export default {
  props: {
    stroke: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
