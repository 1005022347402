import ItemReviewMixin from '../global/mixins/ItemReviewMixin'
import { ReviewMixin } from './ReviewMixin'
export const ClosetMemberPhotoMixin = {
  mixins: [ItemReviewMixin, ReviewMixin],
  computed: {
    memberPhotoAlbumEntries () {
      const { imageReviews, looks } = this.styleColor
      const reviewEntries = imageReviews ? imageReviews.flatMap(review => this.reviewToAlbumEntry(review)) : []
      const lookEntries = looks ? looks.flatMap(look => this.lookToAlbumEntry(look)) : []
      return [...lookEntries, ...reviewEntries].map((entry, index) => ({ ...entry, photoAlbumIndex: index }))
    }
  },
  methods: {
    reviewToAlbumEntry (review) {
      return review.images.map(image => ({
        image: image.url,
        reviewId: review.id,
        clientId: review.clientId,
        starRating: review.overallStarRating,
        body: review.feedbackText,
        subtext: `Size rented: ${review.feedbackSize}` +
          `${this.getFitText(review) ? ` Fit: ${this.getFitText(review)}` : ''}`,
        clientSizeList: this.buildClientSizesList(review)
      }))
    },
    lookToAlbumEntry (look) {
      return look.images.map(image => ({
        image: image.url,
        clientId: look.client.id,
        tag: (look.tags && look.tags.length) && look.tags[0],
        body: look.caption,
        subtext: `Size rented: ${look.sizeRented}` +
          `${this.getFitText(look) ? ` Fit: ${this.getFitText(look)}` : ''}`,
        linkText: 'View this look >',
        link: `/community/feeditem/${look.feedItemId}`,
        clientSizeList: this.buildClientSizesList(look)
      }))
    }
  }
}
