import api from '../api/api'
import { getSetters, parseError } from './helpers/shared'

export const state = {
  myCaseFlyoutName: 'core',
  coreTabSelected: 0,
  caseLoading: true,

  previousMyCaseFlyoutName: null,

  addedToCase: false,

  expeditedReturnSelected: null,

  checkoutRecommendationsSlots: [],

  confirmedSelectedItems: [],
  confirmedPackageId: null,
  confirmedPackageIsStylistCurated: false,
  confirmedCaseExpedited: false,
  confirmedCaseExpeditedPickup: false,

  shippingAddressEditing: null,
  shippingAddressEditingError: null,
  shippingOffers: [],
  setShippingAddressEditingSelected: false,

  itemToPurchase: null,
  itemsHeldForPurchase: [],

  extraItemsBasePrice: null,

  addOnDetailsBackClick: '',
  availableAddOnItems: [],
  addOnItemViewing: null,
  addOnItemsToPurchase: [],
  purchasedAddOnItems: [],
  addOnDetailsShowAddToCase: true,

  purchaseSuggestions: [],
  purchaseSuggestionsInCase: [],

  // Post submission selections
  postSubmissionSelectionsPackageId: null,

  backupItemTypes: [],
  typeForBackupTypes: null,
  selectedBackupItemTypeId: null,

  // purchasing all "with_client" items at once
  allItemsPricing: null,
  itemsPurchased: null,
  // pricing
  casePrice: null,
  fetchingCasePrice: false,

  shippingOptions: null,
  selectedShippingId: null,
  stylistRequest: null,

  // case satisfaction
  caseSatisfactionSurveyOptions: [],
  caseSatisfactionSurveyAnswers: {}
}

export const getters = {
  validShippingOptions: (state) => {
    if (!state.shippingOptions) { return [] }
    let options = state.shippingOptions.filter((x) => {
      const valid = Date.now() < Date.parse(x.validUntil)
      return x.validTo === null || valid
    })

    // if there is more than one expedited shipping method
    // that is valid, only show Priority
    if (options.filter(x => x.type === 'expedited').length > 1) {
      options = options.filter((x) => {
        return x.type === 'standard' || x.name.includes('Priority')
      })
    }
    return options
  },
  selectedShipper: (state) => {
    if (state.selectedShippingId) {
      const shippers = state.shippingOptions.filter(x => x.id === state.selectedShippingId)
      if (shippers.length > 0) {
        return shippers[0]
      }
    }
    return null
  },
  shippingCharge: (state) => {
    if (state.selectedShipper) {
      if (state.selectedShipper.discountedCharge !== null) {
        return state.selectedShipper.discountedCharge
      }
      return state.selectedShipper.charge
    }
    return 0
  },
  availableCheckoutRecommendations: (state) => {
    return state.checkoutRecommendationsSlots
  },
  availablePurchaseSuggestions: (state) => {
    return state.purchaseSuggestions.filter(x => {
      return state.purchaseSuggestionsInCase.filter(y => y.id === x.id).length === 0
    })
  },
  extraItemBasePrice: (state) => {
    if (state.extraItemsBasePrice !== null) return state.extraItemsBasePrice
    return 2000
  },
  nextFlyout: (state, getters, rootState, rootGetters) => {
    const needToChooseShipping = !state.selectedShippingId && rootState.client.shippingAddress &&
         !rootState.client.shippingEligibility?.employee.employeeMustPickup
    const showReplacementItemChoice = rootState.client.settings.replacementItemChoice === null
    const checkoutStates = [
      'core',
      'purchase-suggestions',
      'checkout-recommendations',
      'address-selection',
      'replacement-items',
      'choose-shipping'
    ]
    if (checkoutStates.includes(state.myCaseFlyoutName)) {
      if (rootGetters['client/numAvailableCheckoutRecommendations'] > 0 && state.myCaseFlyoutName === 'core') {
        return 'checkout-recommendations'
      } else if (rootGetters['client/numAvailablePurchaseSuggestions'] > 0 && state.myCaseFlyoutName === 'core') {
        return 'purchase-suggestions'
      } else if (needToChooseShipping) {
        return 'choose-shipping'
      } else if (showReplacementItemChoice) {
        return 'replacement-items'
      }
      return 'confirm-case'
    }
    // fallback
    return 'core'
  },
  expeditedShipper () {
    if (!state.shippingOptions) { return null }
    const shippers = state.shippingOptions.filter(x => x.type === 'expedited')
    if (shippers.length > 0) {
      return shippers[0]
    }
    return null
  }
}

export const actions = {
  handleCaseBackups ({ commit, state }, data) {
    commit('SET_POST_SUBMISSION_SELECTIONS_PACKAGE_ID', data.package.id)

    // Handle backup item options
    const backupItemTypes = data.backupItemTypes
    if (backupItemTypes.length) {
      const scId = backupItemTypes[0].styleColor
      const type = state.confirmedSelectedItems.find(
        x => x.styleColor === scId)
      commit('SET_TYPE_FOR_BACKUP_TYPES', type)
      commit('SET_BACKUP_ITEM_TYPES', backupItemTypes)
    }
  },
  async selectBackupSize ({ commit, state }) {
    try {
      await api.apiClient.submitCaseBackupSize(
        state.postSubmissionSelectionsPackageId,
        [state.selectedBackupItemTypeId]
      )
      const type = state.backupItemTypes.find(x => x.id === state.selectedBackupItemTypeId)
      const index = state.confirmedSelectedItems.findIndex(x => x.styleColor === type.styleColor)
      // insert the selected backup item type just below the item
      // type that triggered the offer
      commit('SET_CONFIRMED_SELECTED_ITEMS', [
        ...state.confirmedSelectedItems.slice(0, index + 1),
        type,
        ...state.confirmedSelectedItems.slice(index + 1)
      ])
    } catch (err) {
      throw parseError(err)
    }
  },
  async resetShipping ({ commit }) {
    commit('SET_SELECTED_SHIPPING_ID', null)
    commit('SET_SHIPPING_OPTIONS', null)
  },
  async getShippingOffers ({ commit }) {
    try {
      const res = await api.apiCloset.getShippingOffers()
      commit('SET_SHIPPING_OFFERS', res.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getShippingOptions ({ commit, rootState, rootGetters }) {
    const selectedItemTypes = rootState.closet.selectedItemTypes.map(itemType => itemType.id)
    const types = selectedItemTypes.join(',')
    const intent = rootGetters['client/hasRentalPlan'] ? 'rent' : 'purchase'
    const res = await api.apiCloset.getShippingOptions(types, intent)
    commit('SET_SHIPPING_OPTIONS', res.data)
  },
  async getCasePrice ({ commit, state, rootState, rootGetters, dispatch }, promoCode = null) {
    try {
      if (state.fetchingCasePrice) {
        // it's an expensive call; let's not shoot ourselves in the foot here.
        return
      }
      commit('SET_FETCHING_CASE_PRICE', true)
      const data = {}
      let res = {}
      data.outbound_shipping_id = state.selectedShippingId
      data.selected_item_types = rootState.closet.selectedItemTypes.map(itemType => itemType.id)
      if (promoCode) {
        data.promo_code = promoCode
      }
      if (rootGetters['client/hasRentalPlan']) {
        data.purchase_bonus_items = true
        if (state.addOnItemsToPurchase.length > 0) {
          data.addOnItemTypes = state.addOnItemsToPurchase.map(x => {
            return {
              id: x.addOnTypeId,
              quantity: x.quantity
            }
          })
        }
        if (state.purchaseSuggestionsInCase.length > 0) {
          data.purchase_items = state.purchaseSuggestionsInCase.map(itemType => itemType.id)
        }
        res = await dispatch('client/getCasePrice', data, { root: true })
      } else {
        data.preview = true
        if (data.selected_item_types.length === 0) {
          // last item was removed
          commit('SET_CASE_PRICE', null)
          commit('SET_FETCHING_CASE_PRICE', false)
          return
        }
        res = await dispatch('client/purchaseCase', data, { root: true })
      }
      commit('SET_CASE_PRICE', res.data)
      commit('SET_FETCHING_CASE_PRICE', false)
    } catch (err) {
      commit('SET_FETCHING_CASE_PRICE', false)
      throw parseError(err)
    }
  },
  setMyCaseFlyoutName ({ state, commit }, name) {
    commit('SET_PREVIOUS_MY_CASE_FLYOUT_NAME', state.myCaseFlyoutName)
    commit('SET_MY_CASE_FLYOUT_NAME', name)
  },
  setMyCaseFlyoutContext ({ commit }, data) {
    commit('UPDATE_CASE_CONTEXT', data)
  },
  addToCase ({ commit }) {
    commit('SET_ADDED_TO_CASE', true)
    setTimeout(() => { commit('SET_ADDED_TO_CASE', false) }, 2000)
  },
  async getCheckoutRecommendations ({ commit }) {
    const res = await api.apiItems.getCheckoutRecommendations()
    commit('SET_CHECKOUT_RECOMMENDATIONS_SLOTS', res.data.slots)
    commit('closet/UPDATE_STYLE_COLORS_MAP', res.data.styleColors, { root: true })
  },
  async getItemPurchaseSuggestions ({ commit }) {
    const res = await api.apiItems.getItemPurchaseSuggestions()
    commit('SET_PURCHASE_SUGGESTIONS', res.data.items)
    commit('closet/UPDATE_STYLE_COLORS_MAP', res.data.styleColors, { root: true })
  },
  async purchaseAllItems ({ commit }, data) {
    try {
      const packageItemIds = data.items.map(x => x.id)
      const res = await api.apiClient.buyAllItems({
        packageItemIds: packageItemIds,
        source: data.source
      })
      commit('SET_ITEMS_PURCHASED', [...data.items])
      packageItemIds.forEach(
        id => commit('closet/ITEM_PURCHASED', id, { root: true })
      )
      commit('client/SET_CLOSET_ITEM_NUMBER', res.data.closetItemNumber, { root: true })
      commit('client/SET_STATE', res.data.state, { root: true })
      commit('client/SET_STATUS', res.data.status, { root: true })
    } catch (err) {
      throw parseError(err)
    }
  },
  async getAllItemsPrice ({ commit }) {
    try {
      const res = await api.apiClient.getAllItemsPrice()
      commit('SET_ALL_ITEMS_PRICING', res.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async holdForPurchase ({ commit, dispatch }, packageItem) {
    try {
      commit('APPEND_HELD_PURCHASE_ITEM', packageItem)
      dispatch('client/postSupportRequest', {
        action: 'purchase',
        source: 'my-case',
        package_item_id: packageItem.id
      }, { root: true })
    } catch (err) {
      throw parseError(err)
    }
  },
  async getAvailableAddOnItems ({ commit }) {
    try {
      const res = await api.apiItems.getAvailableAddOnItems()
      commit('SET_AVAILABLE_ADD_ON_ITEMS', res.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getBaseExtraItemsPrice ({ commit }) {
    try {
      const res = await api.apiItems.getBonusItemsPrice(1)
      commit('SET_EXTRA_ITEMS_BASE_PRICE', res.data.price)
    } catch (err) {
      throw parseError(err)
    }
  },
  async removePackageItemsFromReturning ({ commit, dispatch }, items = []) {
    try {
      const res = await api.apiCloset.removePackageItemsFromReturning(items)
      if (res.data) {
        commit(
          'client/UPDATE_CLIENT',
          res.data,
          { root: true })
        await dispatch('closet/getPackageItems', null, { root: true })
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  clearAddOnItems ({ commit }) {
    commit('SET_ADD_ON_ITEMS_TO_PURCHASE', [])
    commit('SET_ADD_ON_ITEMS_PRICE', null)
  },
  updateAddOnItemsQuantity ({ state, commit, dispatch }, data) {
    commit('UPDATE_ADDON_PURCHASE_QUANTITY', data)
    if (state.addOnItemsToPurchase.length === 0) {
      dispatch('clearAddOnItems')
    }
  },
  addItemToPurchase ({ commit, dispatch }, data) {
    commit('ADD_ITEM_FOR_PURCHASE', data)
    dispatch('getCasePrice')
  },
  removeItemToPurchase ({ commit, dispatch }, data) {
    commit('REMOVE_ITEM_FOR_PURCHASE', data)
    dispatch('getCasePrice')
  },
  updateOccasionTags ({ commit }, data) {
    commit('ADD_OR_REMOVE_OCCASION_TAGS', data)
  },
  updateCaseSatisfactionSurveyAnswers ({ commit }, data) {
    commit('UPDATE_SURVEY_ANSWERS', {
      packageItemId: data.id,
      primaryChoiceId: data.cid,
      secondaryChoiceId: data.scid
    })
  },
  updateCaseSatisfactionSurveyText ({ commit }, data) {
    commit('UPDATE_SURVEY_ANSWER_TEXT', {
      packageItemId: data.id,
      text: data.text
    })
  },
  async fetchCaseSatisfactionSurveyOptions ({ commit }) {
    const res = await api.apiItems.fetchCaseSatisfactionSurveyOptions()
    commit('SET_CASE_SATISFACTION_SURVEY_OPTIONS', res.data)
  },
  async submitCaseSatisfactionSurveyAnswers ({ commit, state }) {
    try {
      for (const [key, value] of Object.entries(state.caseSatisfactionSurveyAnswers)) {
        await api.apiItems.submitCaseSurveyAnswerByItem({ packageItemId: key, ...value })
      }
      commit('CLEAR_SURVEY_ANSWERS')
    } catch (err) {
      throw parseError(err)
    }
  }
}

export const mutations = {
  ...getSetters(state),

  'UPDATE_CASE_CONTEXT' (state, data) {
    Object.keys(data).forEach(key => {
      state[key] = data[key]
    })
    state.loaded = true
  },
  'ADD_ITEM_FOR_PURCHASE' (state, data) {
    if (state.purchaseSuggestionsInCase.filter(x => x.id === data.id).length === 0) {
      state.purchaseSuggestionsInCase = state.purchaseSuggestionsInCase.concat(data)
    }
  },
  'REMOVE_ITEM_FOR_PURCHASE' (state, data) {
    state.purchaseSuggestionsInCase = state.purchaseSuggestionsInCase.filter(x => x.id !== data.id)
  },
  'APPEND_HELD_PURCHASE_ITEM' (state, item) {
    if (state.itemsHeldForPurchase.filter(
      x => x.id === item.id).length === 0) {
      state.itemsHeldForPurchase = state.itemsHeldForPurchase.concat(item)
    }
  },
  'UPDATE_ADDON_PURCHASE_QUANTITY' (state, data) {
    if (data.quantity === 0) {
      state.addOnItemsToPurchase = state.addOnItemsToPurchase.filter(x => x.addOnTypeId !== data.addOnTypeId)
    } else {
      const itemsToPurchase = state.addOnItemsToPurchase.filter(
        x => x.addOnTypeId === data.addOnTypeId)
      if (itemsToPurchase.length > 0) {
        itemsToPurchase[0].quantity = data.quantity
      } else {
        state.addOnItemsToPurchase = state.addOnItemsToPurchase.concat({
          addOnTypeId: data.addOnTypeId,
          quantity: data.quantity
        })
      }
    }
  },
  'REMOVE_ADDON_PURCHASE_ITEM' (state, addOnItem) {
    state.addOnItemsToPurchase = state.addOnItemsToPurchase.filter(
      x => x.addOnItem.id !== addOnItem.id)
  },
  'SET_STYLIST_REQUEST' (state, stylistRequest) {
    state.stylistRequest = { ...stylistRequest }
  },
  'CLEAR_STYLIST_REQUEST' (state) {
    state.stylistRequest = null
  },
  'ADD_OR_REMOVE_OCCASION_TAGS' (state, data) {
    const { tagId } = data
    const tag = state.stylistRequest.occasions.find(e => e.id === tagId)
    tag.selected = !tag.selected
  },
  'UPDATE_STYLIST_REQUEST' (state, data) {
    state.stylistRequest = { ...state.stylistRequest, ...data }
  },
  'UPDATE_STYLIST_REQUEST_SIZES' (state, { category, sizes }) {
    switch (category) {
      case 'shirts':
        state.stylistRequest.shirtSizes = sizes
        break
      case 'waist':
        state.stylistRequest.waistSizes = sizes
        break
      case 'dresses':
        state.stylistRequest.dressSizes = sizes
        break
      case 'pants':
        state.stylistRequest.pantSizes = sizes
        break
      case 'jumpsuits':
        state.stylistRequest.jumpsuitSizes = sizes
        break
    }
  },
  'CLEAR_SURVEY_ANSWERS'  (state) {
    state.caseSatisfactionSurveyAnswers = {}
  },
  'UPDATE_SURVEY_ANSWERS' (state, data) {
    if (!state.caseSatisfactionSurveyAnswers[data.packageItemId]) {
      state.caseSatisfactionSurveyAnswers[data.packageItemId] = {}
    }
    state.caseSatisfactionSurveyAnswers[data.packageItemId] = {
      primaryChoiceId: data.primaryChoiceId,
      secondaryChoiceId: data.secondaryChoiceId
    }
  },
  'UPDATE_SURVEY_ANSWER_TEXT' (state, data) {
    state.caseSatisfactionSurveyAnswers[data.packageItemId].text = data.text
  }
}
