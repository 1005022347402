<template>
  <plan-select-page
    header="Change Plan"
    subheader="Select your new plan"
    :selectable-plans="availableMonthlyPlans"
    :breadcrumb="{ name: 'Change Plan', to: 'change-plan' }"/>
</template>

<script>
import MembershipMixin from './MembershipMixin'
import PlanSelectPage from './PlanSelectPage'
import { vuexAccessors } from '../../global/helpers/vuex'

export default {
  name: 'ChangePlan',
  components: { PlanSelectPage },
  mixins: [MembershipMixin],
  computed: {
    ...vuexAccessors('account', [
      'confirmChangeTitle'
    ])
  },
  mounted () {
    this.confirmChangeTitle = 'Confirm Plan Change'
  }
}
</script>
