<template>
  <div>
    <horizontal-scroll>
      <style-color-preview
        v-for="(similarStyleColor, index) in similarStyleColors"
        :key="similarStyleColor"
        class="me-3"
        size="large"
        :style-color="styleColorsMap[similarStyleColor]"
        :style-color-set="{source: `similar-${styleColor.id}`}"
        :style-color-source-index="index"/>
    </horizontal-scroll>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HorizontalScroll from '../global/sequin/HorizontalScroll'
import StyleColorPreview from '@shared/components/ADORN/StyleColorPreview.vue'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'

export default {
  name: 'ClosetDetailSimilarStyles',
  components: {
    HorizontalScroll,
    StyleColorPreview
  },
  mixins: [StyleColorMixin],
  props: {
    styleColor: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    similarStyleColors () {
      if (this.styleColor && this.styleColor.similar) {
        return this.styleColor.similar
      }
      return []
    }
  }
}
</script>
