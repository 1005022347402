<template>
  <div
    v-if="errors.length"
    class="text-danger text-start">
    <p
      v-for="(error, index) in errors"
      :key="index"
      class="form-errors"
      v-html="error"/>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-errors {
    white-space: pre-wrap;
  }
</style>
