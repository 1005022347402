<template>
  <AuthButton
    name="Apple"
    @click="onClick">
    <SvgApple
      height="26"
      width="26"/>
  </AuthButton>
</template>

<script setup>
import { onMounted } from 'vue'
import AuthButton from './AuthButton.vue'
import SvgApple from '@/components/global/svg/SvgApple.vue'
import useMobileApp, { IncomingMobileAppMessage, MobileAppMessage } from '@/composables/mobileApp.js'
import { useLogger } from 'vue-logger-plugin'

const logger = useLogger()
const emit = defineEmits(['success', 'error'])

onMounted(() => {
  if (typeof window.AppleID === 'undefined') {
    const script = document.createElement('script')
    script.id = '_sign-in-with-apple-script'
    script.type = 'text/javascript'
    script.onload = () => {
      window.AppleID.auth.init({
        clientId: 'style.armoire',
        scope: 'name email',
        redirectURI: `https://${window.location.hostname}/`,
        usePopup: true
      })
    }
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
    document.head.appendChild(script)
  }
})

async function signIn () {
  try {
    const data = await window.AppleID.auth.signIn()
    const formData = {
      appleToken: data.authorization.id_token,
      code: data.authorization.code
    }
    // Only returned on initial login/signup.
    if (Object.hasOwn(data, 'user')) {
      formData.email = data.user.email
      formData.firstName = data.user.name.firstName
      formData.lastName = data.user.name.lastName
    }
    emit('success', formData)
  } catch ({ error }) {
    if (error !== 'popup_closed_by_user') {
      logger.error(error)
      emit('error', error)
    }
  }
}

const { inWebView, post, subscribeMessageListener } = useMobileApp()

function onClick () {
  if (inWebView()) {
    post(MobileAppMessage.SignUpWithApple)
  } else {
    signIn()
  }
}

subscribeMessageListener(IncomingMobileAppMessage.OnAppleSignIn, (data) => {
  if (data.error) {
    emit('error', data.error)
  } else {
    emit('success', data)
  }
})
</script>
