<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.6629 16.5922C22.4147 17.1415 22.3058 17.7807 22.4404 18.4213L23.0215 21.186C23.2061 22.0642 22.4326 22.8451 21.5527 22.6688L18.7214 22.1015C18.0936 21.9758 17.4689 22.083 16.9303 22.3243C15.6148 22.9134 14.127 23.25 12.5927 23.25C6.44478 23.25 1.48828 18.2459 1.48828 12C1.48828 5.75413 6.44478 0.75 12.5927 0.75C18.6701 0.75 23.6294 5.75131 23.6294 12C23.6294 13.6281 23.2913 15.2014 22.6629 16.5922Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"/>
  </svg>
</template>
