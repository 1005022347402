<template>
  <div
    :class="{'d-flex mobile-sizes' : isMobile}">
    <p class="d-inline ">
      <span v-if="!isMobile">Typical</span> Sizes
      <span class="text-orchid">
        {{ review.clientSizeRange[0] }}
        <span v-if="review.clientSizeRange.length >1">- {{ review.clientSizeRange[1] }}</span>
      </span>
    </p>
    <sequin-tag
      v-if="isExactSizeMatch && !isMobile"
      variant="tertiary"
      class="ms-2">
      My Size
    </sequin-tag>
    <span
      v-if="isMobile"
      class="px-1">|</span>
    <p class="mb-0">
      Rented Size: <span class="text-orchid">{{ review.feedbackSize }}</span>
    </p>
    <span
      v-if="isMobile"
      class="px-1">|</span>
    <p>Fit: <span class="text-orchid">{{ shortFitText }}</span></p>
  </div>
</template>

<script>
import { ReviewMixin } from '../../ReviewMixin'
import SequinTag from '../../../global/sequin/SequinTag'

export default {
  components: {
    SequinTag
  },
  mixins: [ReviewMixin],
  props: {
    review: {
      type: Object,
      required: true
    },
    isExactSizeMatch: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-sizes {
  font-size: 12px;
  background-color: $origami;
  border-radius: 10px;
  padding: 3px 10px;
  p {
    margin-bottom: 0;
    white-space: nowrap;
  }
}
</style>
