<template>
  <button
    ref="self"
    class="switch"
    @click="onToggleClick(!state)">
    <span
      class="slider"
      :class="{ 'on': currentState }"><span class="toggle"/></span>
  </button>
</template>

<script>
export default {
  name: 'SequinToggle',
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentState: this.state
    }
  },
  watch: {
    state (newState, state) {
      if (newState !== state) {
        this.updateState(newState)
      }
    }
  },
  methods: {
    async updateState (newState) {
      this.currentState = newState
    },
    onToggleClick (newState) {
      this.currentState = newState
      this.$refs.self.blur()
      setTimeout(() => { this.$emit('toggle-click', this.state) }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-switch {
  display: flex;
  justify-content: center;
  margin: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  border-radius: 34px;
  border: none;
  background-color: $secondary;

  &:focus {
    outline-color: $ash;
    box-shadow: 0 0 1px $ash;
  }

  &:active {
    border: none;
    outline: none;
  }
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $ash;
  border-radius: 34px;
  @include transition-base-all;

  .toggle {
    position: absolute;
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    @include transition-base-all;
  }

  &:focus {
    border: none;
    outline: none;
  }

  &.on {
    background-color: $plum;

    .toggle {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px $ash;
}

</style>
