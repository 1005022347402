// Pinia store for front-end toast notifications.
// These are distinct from the backend notifications displayed in the flyout/overlay.

import { defineStore } from 'pinia'
import { computed, nextTick, ref } from 'vue'
import { useOverlaysStore } from './overlays'

let toastNotificationId = 0
export function createToastNotification ({ message, type = 'transient', link = null, icon = null } = {}) {
  if (typeof message !== 'string') {
    throw new Error('Toast notification message must be a string.')
  }

  const _id = toastNotificationId++
  if (link !== null) {
    if (!link.text || typeof link.text !== 'string') {
      throw new Error('Toast notification link text must be a string.')
    }
    if (!link.clickHandler || typeof link.clickHandler !== 'function') {
      throw new Error('Toast notification link clickHandler must be a function.')
    }
  }
  const isValidString = typeof icon === 'string' && icon.startsWith('svg-')
  const isValidComponent = icon !== null && typeof icon === 'object' &&
    icon.name.startsWith('Svg') && typeof icon.render === 'function'
  if (icon !== null && !isValidString && !isValidComponent) {
    throw new Error('Toast notification icon must be an svg component.')
  }

  return {
    get id () { return _id },
    get type () { return type },
    get isTransient () { return this.type === 'transient' },
    get isPersistent () { return this.type === 'persistent' },
    get isSmart () { return this.type === 'smart' },
    get message () { return message },
    get link () { return link },
    get icon () { return icon }
  }
}

export const useToastNotificationsStore = defineStore('toastNotifications', () => {
  const overlaysStore = useOverlaysStore()

  const toastNotifications = ref([])

  const transientToastNotifications = computed(() => toastNotifications.value.filter(toast => toast.isTransient))
  const persistentToastNotifications = computed(() => toastNotifications.value.filter(toast => toast.isPersistent))
  const smartToastNotifications = computed(() => toastNotifications.value.filter(toast => toast.isSmart))

  function getToastNotificationById (id) {
    return toastNotifications.value.find(toast => toast.id === id)
  }
  function showToastNotification (toast) {
    if (toast.isTransient && overlaysStore.isAnyOverlayOpen) {
      // We don't want to show transient toasts when overlays are open.
      return null
    }
    toastNotifications.value.push(toast)
    if (toast.isTransient) {
      nextTick(() => {
        setTimeout(() => dismissToastNotification(toast.id), 6000)
      })
    }
    return toast.id
  }
  // Transient notifications are the default and are automatically dismissed after a short time.
  function showTransientToast (message, link = null, icon = null) {
    const toast = createToastNotification({ message, link, icon })
    return showToastNotification(toast)
  }
  // Persistent notifications require the user to do something before they're dismissed.
  function showPersistentToast (message, link = null, icon = null) {
    const toast = createToastNotification({ message, link, icon, type: 'persistent' })
    return showToastNotification(toast)
  }
  // Smart notifications denote when we do something smart on behalf of the user.
  function showSmartToast (message, link = null, icon = null) {
    const toast = createToastNotification({ message, link, icon, type: 'smart' })
    return showToastNotification(toast)
  }
  function dismissToastNotification (id) {
    const index = toastNotifications.value.findIndex(toast => toast.id === id)
    if (index !== -1) {
      toastNotifications.value.splice(index, 1)
    }
  }
  function clearToastNotifications () {
    toastNotifications.value = []
  }

  return {
    toastNotifications,
    transientToastNotifications,
    persistentToastNotifications,
    smartToastNotifications,
    getToastNotificationById,
    showToastNotification,
    showTransientToast,
    showPersistentToast,
    showSmartToast,
    dismissToastNotification,
    clearToastNotifications
  }
})
