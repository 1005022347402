import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export function useQueryStringFilters () {
  const store = useStore()
  const route = useRoute()

  const baseFilters = computed(() => store.state.closet.baseFilters)
  const queryStringFilters = computed(() =>
    JSON.parse(JSON.stringify(Object.keys(baseFilters.value).reduce((acc, key) => {
      const queryValue = route.query[key]
      acc[key] = queryValue
        ? Array.isArray(queryValue)
          ? queryValue
          : [queryValue]
        : baseFilters.value[key]
      return acc
    }, {})))
  )
  const selectedQueryStringFilters = computed(() =>
    Object.fromEntries(Object.entries(queryStringFilters.value).filter(([, value]) => value.length))
  )
  const queryStringSort = computed(() => route.query.sort)

  return {
    queryStringFilters,
    selectedQueryStringFilters,
    queryStringSort
  }
}
