<template>
  <div>
    <div
      v-if="!isEditingCommentInline"
      class="d-flex align-items-start">
      <div>
        <plain-button
          class="p-0 m-0"
          @click="onAvatarClicked(feedItemComment.client)">
          <avatar-image
            :client-id="feedItemComment.client.id"
            size="small"
            :profile-pic="feedItemComment.client.profilePic"/>
        </plain-button>
      </div>
      <div class="ms-2 comment-container">
        <feed-item-comment-text :feed-item-comment="feedItemComment"/>
        <div class="d-flex align-items-center">
          <feed-item-timestamp
            condensed
            :timestamp="feedItemComment.created"/>
          <div
            v-if="feedItemComment.client.id === id"
            class="ms-2">
            <plain-button
              v-if="isMobile"
              class="p-0"
              @click="onMoreClicked">
              <svg-more
                width="20"
                height="20"/>
            </plain-button>
            <edit-delete-comment-menu-inline
              v-else
              @edit-click="startEditing"
              @more-click="onMoreClicked"/>
          </div>
        </div>
      </div>
    </div>
    <edit-comment-inline
      v-else
      @comment-saved="cancelEditing"
      @cancel-click="cancelEditing"/>
  </div>
</template>

<script>
import AvatarImage from '../common/AvatarImage'
import PlainButton from '../../global/sequin/PlainButton'
import EditDeleteCommentMenuInline from './update/EditDeleteCommentMenuInline'
import EditCommentInline from './update/EditCommentInline'
import FeedItemTimestamp from './FeedItemTimestamp'
import FeedItemCommentText from './FeedItemCommentText'
import { mapState } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgMore from '@/components/global/svg/SvgMore.vue'

export default {
  components: {
    AvatarImage,
    EditDeleteCommentMenuInline,
    EditCommentInline,
    FeedItemCommentText,
    FeedItemTimestamp,
    PlainButton,
    SvgMore
  },
  props: {
    feedItemId: {
      type: String,
      required: true
    },
    feedItemComment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEditingCommentInline: false
    }
  },
  computed: {
    ...mapState('client', [
      'id'
    ])
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    startEditing () {
      this.isEditingCommentInline = true
    },
    cancelEditing () {
      this.isEditingCommentInline = false
    },
    onAvatarClicked (client) {
      this.$store.commit('community/SET_CLIENT_INFO', client)
      this.$router.push({ name: 'profile', params: { id: client.id } })
    },
    onMoreClicked () {
      this.$store.commit('community/SET_ITEM_IN_FOCUS', {
        type: 'comment',
        commentId: this.feedItemComment.id,
        feedItemId: this.feedItemId,
        item: this.feedItemComment
      })

      if (this.isMobile) {
        this.openModal({ name: 'edit-delete-menu' })
      }
    }
  }
}
</script>

<style lang="scss">
.comment-container {
  margin-bottom: 6px;
}
</style>
