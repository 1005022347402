<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 42.71 41.9"
    fill="none"
    stroke="#000"
    stroke-miterlimit="10"
    class="feather feather-package">
    <path
      class="cls-1"
      d="M21.35 41.4C9.66 41.4.5 36.69.5 30.68V10.76H2v19.92c0 5 8.86 9.22 19.35 9.22s19.36-4.22 19.36-9.22V10.76h1.5v19.92c0 6.01-9.16 10.72-20.86 10.72Z"/><path
        class="cls-1"
        d="M21.35 21.47C9.66 21.47.5 16.77.5 10.76a.75.75 0 0 1 1.5 0c0 5 8.86 9.21 19.35 9.21s19.36-4.22 19.36-9.21a6.74 6.74 0 0 0-2.42-5c-1.9-1.76-6.54-4.47-16.38-3.6-.46 0-6.82.1-9.41 3.73a2.8 2.8 0 0 0-.5 2.34 4 4 0 0 0 2 2.55c2.47 1.41 12.39 2.31 15.9-.74a.78.78 0 0 0 .3-.59.67.67 0 0 0-.31-.51C28.64 8 24.7 7.21 20.5 8a.75.75 0 1 1-.28-1.48c4.43-.85 8.84-.06 10.54 1.19a2.15 2.15 0 0 1 .91 1.66 2.25 2.25 0 0 1-.81 1.79c-4.16 3.61-14.88 2.47-17.62.9a5.5 5.5 0 0 1-2.71-3.52 4.34 4.34 0 0 1 .72-3.49C13.87 1.39 19.4.81 21.28.73c8-.78 14.36.61 18 3.92a8.16 8.16 0 0 1 2.92 6.13c.01 5.99-9.15 10.69-20.85 10.69ZM6.87 37.1a.75.75 0 0 1-.75-.75V28.9a.75.75 0 0 1 1.5 0v7.45a.76.76 0 0 1-.75.75Zm4.51 2.52a.75.75 0 0 1-.75-.75v-7.44a.75.75 0 0 1 1.5 0v7.44a.74.74 0 0 1-.75.75Zm4.88.79a.75.75 0 0 1-.75-.75v-7.45a.75.75 0 0 1 1.5 0v7.45a.76.76 0 0 1-.75.75Zm10.08.27a.75.75 0 0 1-.75-.75v-7.45a.75.75 0 1 1 1.5 0v7.45a.76.76 0 0 1-.75.75Zm5.08-1.2a.76.76 0 0 1-.75-.75v-7.45a.75.75 0 0 1 1.5 0v7.45a.76.76 0 0 1-.75.75Zm4.72-1.59a.75.75 0 0 1-.75-.75v-7.45a.75.75 0 1 1 1.5 0v7.45a.76.76 0 0 1-.75.75Zm-14.85 3.05a.75.75 0 0 1-.75-.75V27.36a.75.75 0 0 1 1.5 0v12.83a.75.75 0 0 1-.75.75Z"/>
  </svg>
</template>
