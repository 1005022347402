<template>
  <div class="w-100 feed-item">
    <div class="d-flex w-100 justify-content-center">
      <div
        v-for="image in previewImages"
        :key="image"
        class="preview-image-container">
        <img
          class="preview-image"
          :alt="`${name} Preview Image`"
          :src="image">
      </div>
    </div>
    <div class="bg-origami border-top w-100 p-3">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <text-line-limit
            tag="h6"
            class="mb-1">
            {{ name }}
          </text-line-limit>
          <p class="small-text text-pewter mb-0">
            {{ count }}
          </p>
        </div>
        <svg-chevron-right v-if="withArrow"/>
      </div>
    </div>
  </div>
</template>

<script>
import TextLineLimit from '../global/sequin/TextLineLimit.vue'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'

export default {
  components: {
    TextLineLimit,
    SvgChevronRight
  },
  props: {
    previewImages: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    itemCount: {
      type: Number,
      required: true
    },
    withArrow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    count () {
      return `${this.itemCount} item${this.itemCount === 1 ? '' : 's'}`
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-image-container {
  width: 33.3%;

  @supports (aspect-ratio: 1) {
    aspect-ratio: .75;
  }

  @supports not (aspect-ratio: 1) {
    height: 212px;
  }

  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.feed-item {
  height: fit-content;
}

</style>
