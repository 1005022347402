// Intermediate mixin to catch errors thrown from vue-in-viewport-mixin. Do not import this module directly.
import { captureException } from '@sentry/vue'
import inViewport from 'vue-in-viewport-mixin-original'

export default {
  mixins: [inViewport],
  methods: {
    async addInViewportHandlers () {
      try {
        inViewport.methods.addInViewportHandlers.call(this)
      } catch (e) {
        const metadata = {
          file: this.$options?.__file,
          parent: this.$parent?.$options?.__file ?? this.$parent.$options?.name
        }
        captureException(e, { extra: metadata })
        // Importing logger directly causes weird errors elsewhere in the project,
        // likely due to the webpack aliasing.
        const { default: logger } = await import('@/logger')
        logger.error(e, metadata)
      }
    }
  }
}
