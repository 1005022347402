<template>
  <div :class="isMobile ? 'row' : 'container'">
    <action-header
      v-if="isMobile"
      class="col col-12"
      page-header>
      Confirm Swap
    </action-header>
    <div
      v-else
      class="mt-5">
      <div class="d-flex align-items-center mt-3 mb-3">
        <back-button
          :back-default-route="{ name: 'closet' }"
          class="back-button"
          with-border/>
        <div class="col">
          <h4 class="mb-0 text-center">
            Confirm Swap
          </h4>
        </div>
      </div>
    </div>
    <app-message
      class="text-center w-100"
      :class="{ 'mt-4': !isMobile }"
      variant="brand">
      <p class="semi-bold mb-0">
        One last check!
      </p>
      Confirm that you'd like to swap the following items.
    </app-message>
    <div
      v-if="isMobile"
      class="col mb-5 pb-4">
      <div
        v-for="item in itemsToSwap"
        :key="item.id"
        class="row">
        <review-package-item-card
          class="pt-4"
          :package-item="item"
          :selected="false"
          :selectable="false"/>
      </div>
    </div>
    <div
      v-else
      class="row mt-4">
      <div
        v-for="item in itemsToSwap"
        :key="item.id"
        class="col col-6 mb-3">
        <review-package-item-card
          class="border-0"
          :package-item="item"
          :selected="false"
          :selectable="false"/>
      </div>
    </div>
    <div
      :class="isMobile
        ? 'col col-12 mobile-button'
        : 'd-flex justify-content-end mt-3 mb-3 me-3'">
      <app-message
        v-if="errors.length"
        class="text-center"
        variant="danger">
        {{ errors.join('. ') }}
      </app-message>
      <sequin-button
        mobile-block
        :disabled="isSubmitting"
        @click="onSwap">
        Confirm Swap
      </sequin-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ActionHeader from '../global/sequin/ActionHeader'
import AppMessage from '../global/sequin/AppMessage'
import BackButton from '../global/sequin/BackButton'
import ReviewPackageItemCard from './ReviewPackageItemCard'
import SequinButton from '../global/sequin/SequinButton'

export default {
  components: {
    ActionHeader,
    AppMessage,
    BackButton,
    ReviewPackageItemCard,
    SequinButton
  },
  data () {
    return {
      isSubmitting: false,
      errors: []
    }
  },
  computed: {
    ...mapState('review', [
      'itemsToSwap'
    ])
  },
  methods: {
    ...mapActions('review', [
      'swapItems'
    ]),
    async onSwap () {
      try {
        this.isSubmitting = true
        await this.swapItems()
        this.$router.replace({ name: 'swap-success' })
      } catch (err) {
        this.isSubmitting = false
        this.errors = err
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobile-button {
    padding: 12px;
    border-top: $default-border;
    background-color: $white;
    z-index: 2;
    position: fixed;
    bottom: 0;
  }

  .list-padding {
    padding-bottom: 128px;
  }
</style>
