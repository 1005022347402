<template>
  <div>
    <div
      class="col-sm-12 px-0 d-flex justify-content-center">
      <div
        v-if="!isMobile"
        class="left-nav bg-white border col-4 me-3">
        <div
          v-if="loaded"
          data-intercom-target="intercom-profile">
          <plain-button
            :disabled="!clientCanViewCommunity"
            type="router-link"
            :to="{ name: 'profile', params: { id } }"
            :class="{'selected': $route.name === 'profile' && $route.params.id === id }"
            class="left-nav-button ps-3 border-bottom">
            <avatar-image
              :alt="`Your Avatar Image`"
              :profile-pic="profilePic"
              :client-id="id"/>
            <h5 class="ms-2 mb-0">
              {{ firstName }} <span v-if="settings && !settings.privacy.displayFirstNameOnly">{{ lastName }}</span>
            </h5>
          </plain-button>
        </div>
        <div>
          <plain-button
            type="router-link"
            data-intercom-target="intercom-all-looks-button"
            class="left-nav-button border-bottom"
            :class="{'selected': $route.name === 'feed'}"
            :to="{ name: 'feed' }"
            :disabled="!clientCanViewCommunity"
            @click="scrollToTopIfFeedSelected">
            <svg-looks
              width="24"
              class="pe-1"/>
            <h5 class="ms-3 mb-0">
              All
            </h5>
          </plain-button>
        </div>
        <div>
          <plain-button
            type="router-link"
            data-intercom-target="intercom-all-looks-button"
            class="left-nav-button border-bottom"
            :class="{'selected': $route.name === 'feed-by-source'}"
            :to="{ name: 'feed-by-source', params: { source: 'following' } }"
            :disabled="!clientCanViewCommunity"
            @click="scrollToTopIfFeedSelected">
            <svg-follow
              class="pe-1"/>
            <h5 class="ms-3 mb-0">
              Following
            </h5>
          </plain-button>
        </div>
        <div>
          <plain-button
            class="left-nav-button"
            data-intercom-target="intercom-share-look-button"
            :disabled="!clientCanViewCommunity || !clientCanInteractCommunity"
            @click="openModal({ 'name': 'share-a-look' })">
            <svg-add height="20"/>
            <h5 class="ms-3 mb-0">
              Share a Look
            </h5>
          </plain-button>
        </div>
      </div>
      <div
        class="slot-content p-0"
        :class="{ 'desktop': !isMobile }">
        <slot/>
      </div>
    </div>
    <div
      v-if="isMobile && loaded"
      class="d-flex bottom-nav bg-white">
      <div
        class="col-3">
        <plain-button
          type="router-link"
          class="p-0 m-0 mx-auto bottom-button"
          :to="{ name: 'feed' }"
          :disabled="!clientCanViewCommunity"
          @click="scrollToTopIfFeedSelected">
          <svg-looks
            height="24"
            :class="{'feed-selected': $route.name === 'feed' || ($route.name === 'profile' && $route.params.id !== id)}"/>
        </plain-button>
      </div>
      <div
        class="col-3">
        <plain-button
          type="router-link"
          class="p-0 m-0 mx-auto bottom-button"
          :to="{ name: 'feed-by-source', params: { source: 'following' } }"
          :disabled="!clientCanViewCommunity"
          @click="scrollToTopIfFeedSelected">
          <svg-follow
            height="24"
            :class="{'feed-selected': $route.name === 'feed-by-source'}"/>
        </plain-button>
      </div>
      <div
        class="col-3"
        data-intercom-target="intercom-share-look-button">
        <plain-button
          type="router-link"
          class="p-0 m-0 mx-auto bottom-button"
          :disabled="!clientCanViewCommunity || !clientCanInteractCommunity"
          :to="{ name: 'share-a-look' }">
          <svg-add
            height="26"
            width="26"/>
        </plain-button>
      </div>
      <div
        class="col-3"
        data-intercom-target="intercom-profile">
        <plain-button
          type="router-link"
          class="p-0 m-0 mx-auto bottom-button"
          :to="{ name: 'profile', params: { id } }"
          :disabled="!clientCanViewCommunity">
          <avatar-image
            :profile-pic="profilePic"
            :client-id="id"
            :alt="`Your avatar image`"
            bordered
            :selected="$route.name === 'profile' && $route.params.id === id"
            size="tiny"/>
        </plain-button>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarImage from './AvatarImage'
import PlainButton from '../../global/sequin/PlainButton'
import { mapState, mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgLooks from '@/components/global/svg/SvgLooks.vue'
import SvgFollow from '@/components/global/svg/SvgFollow.vue'
import SvgAdd from '@/components/global/svg/SvgAdd.vue'

export default {
  name: 'CommunityNavBar',
  components: {
    AvatarImage,
    PlainButton,
    SvgLooks,
    SvgFollow,
    SvgAdd
  },
  props: {
    leftNav: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'lastName',
      'profilePic',
      'id',
      'isStaff',
      'settings',
      'loaded'
    ]),
    ...mapGetters('community', [
      'clientCanViewCommunity',
      'clientCanInteractCommunity'
    ])
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    scrollToTopIfFeedSelected () {
      if (this.$route.name === 'feed' || this.$route.name === 'feed-by-source') {
        this.$nextTick(() => {
          window.scrollTo({ top: 0 })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-top {
  top: 80px;
}

.left-nav {
  height: fit-content;
  border: $default-border;
  max-width: 268px;
  flex-shrink: 1;
}

.left-nav-button {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 32px;

  &:hover {
    color: $primary;
  }

  &.selected {
    background: $primary;
    color: $white;
  }
}

.bottom-nav {
  position: sticky;
  z-index: 1022;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 16px 28px;
  text-align: center;
  border: $default-border;

  .bottom-button {
    height: 28px;
    width: 28px;
  }
}

.feed-selected {
  color: $orchid;
}

#share-look-button {
  border-radius: 0;
}

.slot-content {
  min-height: 100vh;
  width: 100%;
  background-color: $white;
  padding: 24px;

  &.desktop {
    width: 500px;
    background-color: unset;
    flex-shrink: 0;
  }
}

</style>
