<template>
  <SignUpScreen>
    <SignUpProgressBar
      v-if="!isMobile"
      :progress="styleGameProgress"
      class="pt-xs px-md"/>
    <div class="text-center">
      Style Game
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import SignUpProgressBar from '@/components/signUp/SignUpProgressBar.vue'
import useScreenSize from '@shared/composables/screenSize.js'
import { storeToRefs } from 'pinia'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'

const { isMobile } = useScreenSize()
const { styleGameProgress } = storeToRefs(useSignUpNavigationStore())
</script>
