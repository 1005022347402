<template>
  <transition
    :name="transitionName"
    mode="out-in">
    <div
      :key="shareLookComponent"
      class="col-12 col-md-6 mx-auto">
      <action-header
        v-if="shareLookComponent === 'Core'"
        page-header
        no-navigation
        class="p-3"
        :disabled="isSubmitting"
        :show-confirm="lookBuilder.images.length > 0 && lookBuilder.styleColors.length > 0"
        @back-click="onClose"
        @confirm-click="submit">
        <template #back>
          <svg-x data-intercom-target="intercom-modal-exit"/>
        </template>
        {{ editLook ? 'Edit Your Look' : 'Share a Look' }}
        <template #confirm>
          <div class="d-flex align-items-center submit">
            <p class="m-0">
              {{ editLook ? 'Save' : 'Share' }}
            </p>
            <svg-chevron-right
              v-if="!editLook"
              height="18"
              width="18"
              stroke-width="3"/>
          </div>
        </template>
      </action-header>
      <action-header
        v-else
        page-header
        no-navigation
        class="p-3"
        :show-confirm="pageComponent.showConfirm"
        @back-click="setShareLookComponent('Core')"
        @confirm-click="pageComponent.onConfirm">
        {{ pageComponent.title }}
      </action-header>
      <component
        :is="pageComponent.componentName"
        v-bind="pageComponent.props"
        v-on="pageComponent.handlers"/>
    </div>
  </transition>
</template>

<script>
import ActionHeader from '../../global/sequin/ActionHeader'
import AddPhotoBody from './AddPhotoBody'
import ShareALookMixin from './ShareALookMixin'
import ShareALookBody from './ShareALookBody'
import TagStylesBody from './TagStylesBody'
import TagOccasionBody from './TagOccasionBody'
import { mapState } from 'vuex'
import goBack from '../../global/mixins/goBack'
import SvgX from '@/components/global/svg/SvgX.vue'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'

export default {
  components: {
    ActionHeader,
    ShareALookBody,
    AddPhotoBody,
    TagStylesBody,
    TagOccasionBody,
    SvgX,
    SvgChevronRight
  },
  mixins: [goBack, ShareALookMixin],
  props: {
    editLook: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('community', [
      'shareLookComponent'
    ]),
    pageComponentDetails () {
      return {
        ...this.componentDetails,
        Core: {
          ...this.componentDetails.Core,
          props: {
            ...this.componentDetails.Core.props,
            editLook: this.editLook
          }
        }
      }
    },
    pageComponent () {
      return this.pageComponentDetails[this.shareLookComponent]
    }
  },
  methods: {
    async submit () {
      if (this.editLook) {
        await this.saveLookUpdates()
      } else {
        await this.shareLook()
      }

      if (!this.submitError) {
        if (this.editLook) {
          this.goBack({ name: 'feed' })
        } else {
          this.$router.replace({ name: 'feed' })
        }
      }
    },
    onClose () {
      this.clearLookBuilder()
      if (this.editLook) {
        this.goBack({ name: 'feed' })
      } else {
        this.$router.replace({ name: 'feed' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-left-leave-active, .slide-right-leave-active {
  transition: all .15s ease-in-out;
}

.slide-left-enter-active, .slide-right-enter-active {
  transition: all .15s ease-out;
}

.slide-left-leave-to, .slide-right-enter-from, .slide-left-enter-from, .slide-right-leave-to {
  opacity: 0;
}

.slide-left-leave-to, .slide-right-enter-from {
  transform: translateX(-8vw);
  -webkit-transform: translateX(-8vw);
  -ms-transform: translateX(-8vw);
}
.slide-left-enter-from, .slide-right-leave-to {
  transform: translateX(8vw);
  -webkit-transform: translateX(8vw);
  -ms-transform: translateX(8vw);
}

.submit {
  font-weight: $font-weight-bold;
  color: $orchid;

  svg {
    margin-top: 2px;
  }
}
</style>
