<template>
  <div class="action-bar">
    <div data-intercom-target="intercom-action-bar">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex">
          <div class="d-flex me-3">
            <plain-button
              :disabled="!clientCanInteractCommunity"
              class="action-button"
              aria-label="Toggle like button"
              @click="$emit('like-click')">
              <svg-like-filled-in
                v-if="likedByUser"
                class="filled-in"
                height="24"/>
              <svg-like
                v-else
                height="24"/>
            </plain-button>
            <span class="action-count">
              Like
            </span>
          </div>
          <div class="d-flex">
            <plain-button
              :disabled="!clientCanInteractCommunity"
              class="action-button"
              aria-label="Comment on look"
              @click="$emit('comment-click')">
              <svg-comment height="24"/>
            </plain-button>
            <span class="action-count">
              Comment {{ comments && comments.count > 0 ? `(${comments.count})` : '' }}
            </span>
          </div>
        </div>
        <feed-item-timestamp
          v-if="timestamp"
          :timestamp="timestamp"/>
      </div>
      <div
        v-if="likesAndFavorites.first"
        class="mt-3 small-text d-flex align-items-center">
        <feed-item-action-icons
          :show-likes="likesAndFavorites && likesAndFavorites.totalLikes > 0"
          :show-favorites="likesAndFavorites && likesAndFavorites.totalFavorites > 0"/>
        <text-link
          type="router-link"
          variant="secondary"
          :to="{ name: 'profile', params: { id: likesAndFavorites.first.id } }"
          @click="onNavigateToProfile(likesAndFavorites.first.id)">
          {{ getName(likesAndFavorites.first) }}
        </text-link>
        <span v-if="likesAndFavorites.total > 1">
          &nbsp;and
          <text-link
            variant="secondary"
            @click="onViewLikesClick">
            {{ likesAndFavorites.total - 1 }} other<span v-if="likesAndFavorites.total - 2 > 0">s</span>
          </text-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import PlainButton from '../../global/sequin/PlainButton'
import FeedItemTimestamp from './FeedItemTimestamp'
import TextLink from '../../global/sequin/TextLink'
import { mapState, mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import FeedItemActionIcons from './FeedItemActionIcons'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgLikeFilledIn from '@/components/global/svg/SvgLikeFilledIn.vue'
import SvgLike from '@/components/global/svg/SvgLike.vue'
import SvgComment from '@/components/global/svg/SvgComment.vue'

export default {
  components: {
    FeedItemActionIcons,
    FeedItemTimestamp,
    PlainButton,
    TextLink,
    SvgLikeFilledIn,
    SvgLike,
    SvgComment
  },
  props: {
    feedItemId: {
      type: String,
      required: true
    },
    likesAndFavorites: {
      type: Object,
      required: true
    },
    comments: {
      type: Object,
      required: true
    },
    timestamp: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...mapState('community', [
      'userFeedLikes'
    ]),
    ...mapGetters('community', [
      'clientCanInteractCommunity'
    ]),
    likedByUser () {
      return this.userFeedLikes && this.userFeedLikes.includes(this.feedItemId)
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    getName (like) {
      if (like.id === this.id) {
        return 'You'
      } else if (like.lastName) {
        return `${like.firstName} ${like.lastName}`
      }
      return `${like.firstName}`
    },
    onViewLikesClick () {
      if (this.isMobile) {
        this.$router.push({ name: 'feed-item-likes', params: { id: this.feedItemId } })
      } else {
        this.openModal({ name: 'view-likes', context: { feedItemId: this.feedItemId } })
      }
    },
    onNavigateToProfile (client) {
      this.$store.commit('community/SET_CLIENT_INFO', client)
    }
  }
}
</script>

<style lang="scss" scoped>
.action-count {
  margin: 6px 0 0 6px;
  color: $pewter;
  font-size: $small-text-font-size;
  line-height: 18px;
}

.action-bar {
  padding: 0 28px;
}

.action-button {
  padding: 1px;

  svg {
    color: $pewter;

    &.filled-in {
      color: $plum;
    }
  }

  &:hover {
    svg {
      color: $plum;
    }
  }
}

</style>
