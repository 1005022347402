<template>
  <image-uploader
    v-bind="listeners"
    note="After uploading, you can delete looks at any time from your profile"
    class="border"
    :local-images="locallyAddedPhotos"
    :image-upload-url-function="lookImageUploadUrlFunc"/>
</template>

<script>
import { mapState } from 'vuex'
import ImageUploader from '../../global/sequin/ImageUploader'
import { getListeners } from '../../global/helpers/vue'

export default {
  compatConfig: {
    INSTANCE_LISTENERS: false // $listeners are now apart of $attrs in Vue 3
  },
  components: {
    ImageUploader
  },
  props: {
    locallyAddedPhotos: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('community', [
      'lookImageUploadUrlFunc'
    ]),
    listeners () {
      return getListeners(this.$attrs)
    }
  }
}
</script>
