<template>
  <transition
    name="fade"
    mode="out-in">
    <div>
      <h4
        :class="'marketing text-center mb-4'">
        New To You
      </h4>
      <p
        class="subhead text-center">
        See the newest Armoire inventory and new-to-you styles. Favorite, Collect, and rent today.
      </p>
      <upcoming-styles-intro
        v-if="ratingIndex === -1"
        key="upcoming-styles-intro"
        :prev-path-name="prevRoute"
        @start-game="ratingIndex = upcomingStylesRatedIndex"/>
      <upcoming-styles-outro
        v-else-if="ratingIndex >= upcomingStylesToRate.length"
        key="upcoming-styles-outro"
        :sorted-by-favorited-upcoming-styles="sortedByFavoritedUpcomingStyles"
        :favorited-upcoming-styles="favoritedUpcomingStyles"/>
      <style-game-rate-style
        v-else
        key="rate-upcoming-style"
        :current-index="ratingIndex"
        :num-styles-to-rate="upcomingStylesToRate.length"
        :image-source="currentStyle.image"
        :lottie-animations="lottieAnimations"
        yes-rating-name="favorite"
        no-rating-name="dislike"
        show-skip-button
        yes-rating-confirmation="Saved to Favorites"
        :brand="currentStyle.brand"
        :style-name="currentStyle.styleName"
        @style-rating="onRating">
        <template #rating-intro>
          <h4
            class="style-brand-name">
            {{ currentStyle.brand }}
          </h4>
          <h5
            class="style-item-name">
            {{ currentStyle.styleName }}
          </h5>
          <div class="mt-1">
            <div class="d-flex">
              <p class="small-text text-pewter mb-0 mx-2">
                Retail Value {{ formatPrice(currentStyle.retailValue, true, true) }}
              </p>
              <p class="small-text text-pewter mb-0">
                |
              </p>
              <p class="smaller-text text-pewter mb-0 mx-2">
                Size{{ currentStyle.sizes.length > 1 ? 's' : '' }} <span class="semi-bold">{{ currentStyle.sizes.join(', ') }}</span>
              </p>
            </div>
            <div
              v-if="currentStyle.votedFor"
              class="d-flex justify-content-center align-items-center text-plum mt-1 small-text">
              <svg-check
                height="20px"
                width="20px"
                class="me-1"/>
              <span
                class="semi-bold">
                You voted for this style
              </span>
              <base-tooltip>
                <template #trigger>
                  <svg-circle-icon
                    class="ms-1"
                    variant="neutral"
                    :height="18"
                    :width="18">
                    <svg-question-mark/>
                  </svg-circle-icon>
                </template>
                <template #content>
                  <span>
                    We asked "Should we buy this?", and you said yes! Thanks to your
                    vote, this style is now part of the Armoire closet.
                  </span>
                </template>
              </base-tooltip>
            </div>
          </div>
        </template>
      </style-game-rate-style>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import upcomingStylesFavorite from '../lottieAnimations/upcomingStylesFavorite.json'
import upcomingStylesFavoriteButton from '../lottieAnimations/upcomingStylesFavoriteButton.json'
import upcomingStylesDislike from '../lottieAnimations/upcomingStylesDislike.json'
import beTheBuyerNoButton from '../lottieAnimations/beTheBuyerNoButton.json'
import UpcomingStylesIntro from './UpcomingStylesIntro'
import UpcomingStylesOutro from './UpcomingStylesOutro'
import StyleGameRateStyle from './StyleGameRateStyle.vue'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'
import { formatPrice } from '@/utils/stringParsing.js'
import { vuexAccessors } from '../global/helpers/vuex'
import { track } from '../../plugins/analytics'
import BaseTooltip from '../global/BaseTooltip'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgQuestionMark from '@/components/global/svg/SvgQuestionMark.vue'

export default {
  components: {
    BaseTooltip,
    UpcomingStylesIntro,
    UpcomingStylesOutro,
    StyleGameRateStyle,
    SvgCheck,
    SvgCircleIcon,
    SvgQuestionMark
  },
  mixins: [StyleColorMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setPrevPathName(from)
    })
  },
  data () {
    return {
      ratingIndex: 0,
      prevRoute: null
    }
  },
  computed: {
    ...mapState('styleGames', [
      'upcomingStylesToRate'
    ]),
    ...mapState('closet', [
      'ratingsMap',
      'styleColorsMap'
    ]),
    ...vuexAccessors('styleGames', [
      'upcomingStylesRatedIndex'
    ]),
    lottieAnimations () {
      return {
        yesImage: upcomingStylesFavorite,
        yesButton: upcomingStylesFavoriteButton,
        noImage: upcomingStylesDislike,
        noButton: beTheBuyerNoButton
      }
    },
    translatedUpcomingStyles () {
      return this.upcomingStylesToRate.map(style => ({
        id: style.id,
        retailValue: style.style.retailPrice,
        brand: `${style.style.brand}`,
        styleName: `${style.style.name}`,
        image: style.images.front.url,
        votedFor: style.prebuyVotedFor,
        sizes: style.itemTypes.reduce((memberSizes, itemType) => {
          if (itemType.isMemberSize) memberSizes.push(itemType.trueSize)
          return memberSizes
        }, [])
      }))
    },
    sortedByFavoritedUpcomingStyles () {
      const sortedByFavesUpcomingStyles = [...this.upcomingStylesToRate].sort((styleA, styleB) => this.ratingsMap[styleB.id] - this.ratingsMap[styleA.id])
      return sortedByFavesUpcomingStyles
    },
    favoritedUpcomingStyles () {
      return this.upcomingStylesToRate.filter(style => this.ratingsMap[style.id] === 1)
    },
    currentStyle () {
      return this.translatedUpcomingStyles[this.ratingIndex]
    }
  },
  watch: {
    ratingIndex () {
      if (this.currentStyle) {
        track('Viewed Upcoming Styles Style', { styleColorId: this.currentStyle.id, index: this.ratingIndex + 1 })
      }
    }
  },
  created () {
    if (!this.upcomingStylesToRate) this.getUpcomingStylesToRate()
  },
  methods: {
    ...mapActions('styleGames', [
      'rateUpcomingStyle',
      'getUpcomingStylesToRate'
    ]),
    ...mapActions('closet', [
      'favorite'
    ]),
    formatPrice,
    onRating ({ rating }) {
      const styleColorId = this.currentStyle.id
      this.rateUpcomingStyle({ rating, styleColorId, index: this.ratingIndex + 1 })
      if (rating === 1) {
        this.favorite({ styleColorId })
      }
      this.upcomingStylesRatedIndex = ++this.ratingIndex
    },
    setPrevPathName (prevPath) {
      this.prevRoute = prevPath.name
    }
  }
}
</script>
