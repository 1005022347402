<template>
  <svg
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 162.9 162.9">
    <title>Share via Facebook</title>
    <defs>
      <clipPath
        id="clip-path"
        transform="translate(0 -0.02)">
        <rect
          class="cls-1"
          x="0.02"
          width="162.87"
          height="162.87"/>
      </clipPath>
    </defs>
    <g
      id="Layer_2"
      data-name="Layer 2">
      <g
        id="Layer_1-2"
        data-name="Layer 1">
        <g class="cls-2">
          <path
            :fill="backgroundFill"
            d="M160.7,100.18a81.43,81.43,0,1,0-98,60.54A81.43,81.43,0,0,0,160.7,100.18Z"
            transform="translate(0 -0.02)"/>
          <path
            class="cls-4"
            :fill="color"
            d="M68.05,82.45v50a1.17,1.17,0,0,0,1.22,1.22H87.75A1.17,1.17,0,0,0,89,132.4V81.64h13.41a1.31,1.31,0,0,0,1.22-1.22l1.22-15.23a1.35,1.35,0,0,0-1.22-1.42H89v-11a4.6,4.6,0,0,1,4.68-4.67H104a1.17,1.17,0,0,0,1.22-1.22V31.68A1.17,1.17,0,0,0,104,30.46H86.53A18.63,18.63,0,0,0,67.85,49.14V64H58.51a1.17,1.17,0,0,0-1.22,1.22V80.42a1.17,1.17,0,0,0,1.22,1.22h9.34v.81Z"
            transform="translate(0 -0.02)"/>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    backgroundFill: {
      type: String,
      default: '#3c5a9a'
    },
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>
<style scoped>
  .cls-1 {
    fill: none;
  }

  .cls-2 {
    clip-path: url(#clip-path);
  }

  .cls-4 {
    fill-rule: evenodd;
  }
</style>
