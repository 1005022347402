import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useOverlaysStore } from '@/stores/overlays.js'
import { slugify } from '@/utils/stringParsing.js'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

// Helper composable built on top of the overlays store for opening flyouts.
export const useFlyouts = () => {
  const overlays = useOverlaysStore()
  const { flyoutName } = storeToRefs(overlays)
  const { openFlyout } = overlays
  const store = useStore()
  const router = useRouter()

  const isMyCaseFlyoutOpen = computed(() => flyoutName.value === 'my-case')
  async function openMyCaseFlyout (flyoutName = 'core') {
    await store.dispatch('case/setMyCaseFlyoutName', flyoutName)
    openFlyout({ name: 'my-case' })
  }

  async function openClosetDetailFlyout (styleColor, source = null, sourceIndex = null) {
    if (source) {
      await store.dispatch('closet/setStyleColorSource', {
        styleColorId: styleColor.id,
        source,
        sourceIndex
      })
    }
    const isMobile = store.getters['global/isMobile']
    if (isMobile) {
      const name = slugify(`${styleColor.style.brand} ${styleColor.style.name}`)
      router.push({
        name: 'closet-detail',
        params: { id: styleColor.id, name }
      }).catch(() => { /* Allow router to intercept as necessary */ })
    } else {
      openFlyout({
        name: 'closet-detail',
        context: { styleColorId: styleColor.id },
        reuse: true
      })
    }
  }
  function openFiltersFlyout (styleColorSet) {
    openFlyout({ name: 'style-color-set-filters', context: { styleColorSet } })
  }

  return {
    isMyCaseFlyoutOpen,
    openMyCaseFlyout,
    openClosetDetailFlyout,
    openFiltersFlyout
  }
}
