<template>
  <div class="background d-flex justify-content-center align-items-center flex-column">
    <div class="d-flex">
      <div>
        <div
          v-for="(section, index) in styleQuizSectionsDisplayed"
          :key="`rect-${section.name}`"
          class="progress-container">
          <div
            class="progress-box"
            :class="{ 'next': index === progressIndex + 1,
                      'complete': index === progressIndex,
                      'past': index < progressIndex }">
            <div
              v-if="index === progressIndex"
              class="fill-in"/>
            <svg-check
              v-if="index <= progressIndex"
              class="check"
              :class="{ 'animate': index === progressIndex}"/>
          </div>
          <div
            v-if="index < styleQuizSectionsDisplayed.length -1"
            :class="{ 'complete': index === progressIndex,
                      'past': index < progressIndex }"
            class="separator"/>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center ms-3">
        <div
          v-for="(section, index) in styleQuizSectionsDisplayed"
          :key="section.name"
          class="section-title">
          <h6
            v-if="index === progressIndex || index === progressIndex + 1"
            :class="{ 'complete' : index === progressIndex, 'next': index === progressIndex + 1 }"
            class="marketing subhead">
            {{ index === progressIndex ? 'Saved' : 'Next Up' }}
          </h6>
          <h6
            class="marketing header"
            :class="{ 'complete' : index === progressIndex, 'next': index === progressIndex + 1 }">
            {{ section.name }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    SvgCheck
  },
  props: {
    progressIndex: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      progressBoxHeight: 28,
      progressBoxStroke: 4,
      separatorHeight: 32,
      separatorWidth: 3,
      separatorStroke: 1
    }
  },
  computed: {
    ...mapGetters('styleQuizNav', [
      'styleQuizSections',
      'getNextStyleQuizRoute',
      'getNextStyleQuizRouteForRouter'
    ]),
    sectionHeight () {
      return this.progressBoxHeight + this.progressBoxStroke + this.separatorHeight + this.separatorStroke
    },
    svgHeight () {
      return this.styleQuizSections.length * (this.sectionHeight)
    },
    styleQuizSectionsDisplayed () {
      return this.styleQuizSections.filter(section => section.showInProgressAnimation)
    }
  },
  mounted () {
    setTimeout(() => {
      this.$router.push({ ...this.getNextStyleQuizRouteForRouter({ ...this.$route }), query: this.$route.query })
    }, 5000)
  },
  methods: {
    ...mapActions('closet', [
      'getRatingsMap'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import './StyleQuizStyleDefinitions.scss';

$sectionHeight: 44px;
$sectionSeparation: 28px;
$progressBoxHeight: 28px;
$inactiveOpacity: .3;

$delay-1: .6s;
$duration-1: .8s;
$delay-2: $delay-1 + $duration-1;
$duration-2: .2s;
$separator-fill-duration: 1s;
$separator-fill-delay: $delay-2 + $duration-2 + 1s;
$delay-3: $separator-fill-delay + $separator-fill-duration;
$header-duration: .25s;

.header {
  line-height: $sectionHeight;
  opacity: $inactiveOpacity;

  &.complete {
    opacity: 1;
    animation: fade-out $header-duration ease-in $delay-3 forwards;
  }

  &.next {
    animation: fade-out $header-duration ease-in $delay-3 reverse forwards;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: $inactiveOpacity;
  }
}

.section-title {
  height: $sectionHeight;
  margin-bottom: $sectionSeparation;
  position: relative;
}

.subhead {
  position: absolute;
  top: -8;
  font-size: $small-text-font-size;
  line-height: $small-text-line-height;
  letter-spacing: 2px;
  color: rgba($white, $inactiveOpacity * 2);
  opacity: 0;

  &.complete {
    animation: fade-up $header-duration ease-in $delay-2 forwards,
    fade-in $header-duration ease-out $delay-3 reverse forwards;
  }

  &.next {
    animation: fade-up $header-duration ease-in $delay-3 forwards,
  }
}

.progress-container {
  width: $sectionHeight;
  height: $sectionHeight;
  margin-bottom: $sectionSeparation;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  position: absolute;
  width: 4px;
  border: .5px solid $white;
  height: $sectionSeparation + 18;
  transform: translateX(-50%);
  top: $progressBoxHeight + 7;
  left: 50%;

  &.past {
    background-color: $white;
 }

  &.complete {
    background-size: 100% 200%;
    background: linear-gradient(to bottom, $white 50%, rgba($white, 0) 50%) left bottom;
    animation: move-background $separator-fill-duration cubic-bezier(.4,.01,0,1) $separator-fill-delay forwards;

    @keyframes move-background {
      from {
        background-position: left bottom;
      }
      to {
        background-position: left top;
      }
    }
  }
}

.progress-box {
  width: $progressBoxHeight;
  height: $progressBoxHeight;
  margin: 0 auto;
  border: 4px solid $white;
  display: flex;
  justify-content: center;
  align-items: center;

  &.next {
    @include scale-up-down-animation;
    animation: scale-up-down $duration-2 ease-in forwards $delay-3,
  }

  &.complete {
    animation: scale-up $duration-1 ease-out $delay-1 forwards,
      scale-up .2s ease-in $delay-2 reverse forwards,
      scale-up-down $duration-2 forwards $delay-2 + .2s;
  }

  &.past {
    background-color: $white;
  }
}

.check {
  position: absolute;
  color: $primary;
  height: 20px;
  stroke-width: 4px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: 50% 50%;

  &.animate {
    stroke-dasharray: 24;
    stroke-dashoffset: 24;
    animation: stroke $duration-2 ease-in $delay-2 + .2s forwards;

    @keyframes stroke {
      100% {
        stroke-dashoffset: 48;
      }
    }
  }

}

.fill-in {
  width: 2px;
  height: 2px;
  background-color: $white;
  opacity: 0;
  animation: fill-in $duration-1 - .3s ease-in-out $delay-1 + .3s forwards;

  @keyframes fill-in {
    0% {
      opacity: 1;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(14);
    }
  }
}

.background {
  animation: fade-in .25s ease-out normal forwards;
  position: fixed;
  z-index: $zindex-fixed + 1;
  top: -1;
  left: -1;
  right: -1;
  bottom: -1;
  color: $white;
  background: $gradient-background;
}
</style>
