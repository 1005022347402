<template>
  <svg
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 0.5H1V6.5H2.5V0.5Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M6.5 0.5H5V6.5H6.5V0.5Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>
