<template>
  <div
    class="occasion-tag">
    <div
      v-if="!isMobile"
      class="w-100">
      <div class="d-flex align-items-center">
        <div class="icon-background">
          <img
            v-if="tag.iconUrl"
            class="icon"
            :src="getIconImage(tag.iconUrl)">
        </div>
        <div
          class="ms-3 name">
          <div>
            {{ tag.tag }}
            <br>
            <span class="small-text text-pewter no-text-transform">{{ looks }} looks</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-100">
      <div
        class="d-flex justify-content-center mt-2">
        <div class="icon-background">
          <img
            v-if="tag.iconUrl"
            class="icon"
            :src="getIconImage(tag.iconUrl)">
        </div>
      </div>
      <div
        class="d-flex justify-content-center mt-1 mb-3">
        <div
          class="small-text text-pewter no-text-transform">
          {{ looks }} looks
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    tag: {
      type: Object,
      required: true
    },
    looks: {
      type: Number,
      required: true
    }
  },
  methods: {
    getIconImage (imageName) {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}system-tags/${imageName}`
    }
  }
}
</script>

<style lang="scss" scoped>

.no-text-transform {
  text-transform: none;
}

.occasion-tag {
  height: 90px;
  width: 100%;
  padding: 0 20px;
  //color: $plum;
  background-color: $white;
  font-weight: $font-weight-semibold;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 576px) {
    height: 94px;
  }

  .name {
    width: calc(100% - 100px);
  }

  .icon-background {
    background-color: $origami;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 576px) {
      height: 64px;
      width: 64px;
    }

    .icon {
      width: 24px;
      object-fit: contain;

      @media (min-width: 576px) {
        height: 36px;
        width: 36px;
      }

    }

  }
}

</style>
