<template>
  <slot
    v-if="showVariant"
    v-bind="$attrs"
    :name="`variant${toPascalCase(variant)}`"/>
  <slot
    v-else-if="showOriginal"
    v-bind="$attrs"/>
</template>

<script setup>
import useExperiment from '@shared/composables/experiment.js'
import { toPascalCase } from '@/utils/stringParsing.js' // TODO: ENG-3647 - Isolate shared code.

const props = defineProps({
  name: {
    type: String,
    required: true
  }
})

const { variant, showVariant, showOriginal } = useExperiment(props.name)
</script>
