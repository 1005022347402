<template>
  <div class="d-flex">
    <svg-circle-icon
      v-if="complete"
      variant="primary"
      :height="24"
      :width="24"
      :stroke-width="3">
      <svg-check/>
    </svg-circle-icon>
    <div
      v-else
      class="empty-circle"/>
    <span>
      <slot name="detail"/>
    </span>
  </div>
</template>

<script>
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  name: 'ReferralProgressCheck',
  components: {
    SvgCircleIcon,
    SvgCheck
  },
  props: {
    complete: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .empty-circle {
    display: inline-block;
    border: 2px solid $ash;
    width: 28px;
    height: 28px;
    border-radius: 15px;
  }
</style>
