<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.95 25.15">
    <title>Notifications</title>
    <path
      d="M19.6,21.28H2.37A1.7,1.7,0,0,1,.77,20.2a1.86,1.86,0,0,1,.29-2,9.74,9.74,0,0,0,2.39-6.43v-.24A7.81,7.81,0,0,1,5.7,6.05a7.37,7.37,0,0,1,5.42-2.23,7.82,7.82,0,0,1,7.38,8,9.71,9.71,0,0,0,2.39,6.38,1.84,1.84,0,0,1,.29,2A1.62,1.62,0,0,1,19.6,21.28ZM11,4.66a6.59,6.59,0,0,0-4.71,2,7,7,0,0,0-2,4.92v.24a10.55,10.55,0,0,1-2.63,7,.9.9,0,0,0-.14,1,.88.88,0,0,0,.84.57H19.57a.88.88,0,0,0,.85-.57,1,1,0,0,0-.15-1,10.64,10.64,0,0,1-2.62-7,6.93,6.93,0,0,0-6.56-7.12A.31.31,0,0,0,11,4.66Z"
      stroke="currentColor"
      stroke-width="0.7"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M11,24.55A3.13,3.13,0,0,1,8.26,22a.64.64,0,0,1,.21-.69c.21-.1.42,0,.5.29a2.27,2.27,0,0,0,2,1.87,2.26,2.26,0,0,0,2-1.87c.08-.25.28-.39.49-.29a.6.6,0,0,1,.21.69A3,3,0,0,1,11,24.55Z"
      stroke="currentColor"
      stroke-width="0.7"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M12.26,4.91h-.11a6.64,6.64,0,0,0-1.54-.21,6.05,6.05,0,0,0-1.79.21.54.54,0,0,1-.37-.08.39.39,0,0,1-.19-.34V2.6a2.12,2.12,0,0,1,2.18-2,2.12,2.12,0,0,1,2.17,2V4.55a.36.36,0,0,1-.17.31A.35.35,0,0,1,12.26,4.91Zm-1.76-1h.14a7.13,7.13,0,0,1,1.23.14V2.6a1.37,1.37,0,1,0-2.74,0V4.05a8.58,8.58,0,0,1,1.33-.14Z"
      stroke="currentColor"
      stroke-width="0.7"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>
