<template>
  <div :class="{'desktop': !isMobile}">
    <div class="px-4 py-3 d-flex align-items-center">
      <div class="avatar-placeholder"/>
      <div class="ms-3">
        <div class="rectangle-name mb-2"/>
        <div class="rectangle-tag"/>
      </div>
    </div>
    <div class="image-placeholder"/>
    <div class="bottom my-3 bg-white"/>
  </div>
</template>

<style lang="scss" scoped>
.desktop {
  background-color: $white;
  border: $default-border;
}

.bottom {
  height: 36px;
}

.rectangle-name, .rectangle-tag, .avatar-placeholder, .image-placeholder  {
  @include placeholder-loading-animation
}

.avatar-placeholder {
  height: 52px;
  width: 52px;
  border-radius: 50%;
}

.image-placeholder {
  width: calc(100% - 1px);
  padding: .5px;
  height: 80vh;
}

.rectangle-name {
  width: 140px;
  height: 16px;
}

.rectangle-tag {
  width: 100px;
  height: 16px;
}

</style>
