<template>
  <div class="px-md-4 py-4 border-top">
    <div class="d-flex justify-content-between mb-2">
      <div class="d-flex align-items-center">
        <subscribe-step-indicator
          :variant="expanded ? 'in-progress' : 'to-do'"
          :step="3"
          class="me-2"/>
        <h4 class="mb-0">
          Finalize Membership
        </h4>
      </div>
      <text-link
        v-if="!expanded"
        @click="$emit('review-click')">
        edit
      </text-link>
    </div>
    <div v-if="expanded">
      <div class="d-flex flex-column">
        <subscribe-lead-source/>
        <div class="d-flex flex-column">
          <div>
            <div
              v-if="formErrors"
              class="row g-0">
              <div class="col-12">
                <base-form-errors :errors="formErrors"/>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <h5>Order Summary</h5>
            <div class="row">
              <div class="col text-start small-text semi-bold py-1">
                {{ planNameLineDisplay }}
              </div>
              <div class="col text-end small-text semi-bold py-1 ">
                {{ priceLineDisplay }}
              </div>
            </div>
            <div
              v-if="showItemPromoDetails"
              class="small-text mb-2 mt-3 mt-md-0">
              <ul
                class="plan-list mb-0">
                <li v-if="bonusItemsOnSignup > 0">
                  {{ bonusItemsOnSignup }} Free Bonus Item<span v-if="bonusItemsOnSignup > 1">s</span>
                </li>
                <li v-if="membershipPlanSelected.appliedPromo.itemCouponsOnSignup">
                  {{ getCouponDescription(membershipPlanSelected.appliedPromo.itemCouponsOnSignup) }}
                </li>
                <li v-if=" membershipPlanSelected.appliedPromo.itemCouponsOnTrialEnd">
                  {{ getCouponDescription(membershipPlanSelected.appliedPromo.itemCouponsOnTrialEnd) }} after 30 days
                </li>
                <li v-if="swapsOnSignUp > 0">
                  {{ swapsOnSignUp }} Free Swap<span v-if="swapsOnSignUp > 1">s</span>
                </li>
              </ul>
            </div>
            <div
              v-if="promoAmount > 0 && displayFirstChargeOverride"
              class="row">
              <div
                class="col-8 text-start small-text semi-bold py-1 text-success">
                <span>
                  {{ promoText }}
                </span>
              </div>
              <div class="col-4 text-end small-text semi-bold py-1 text-success">
                -${{ promoAmount }}
              </div>
            </div>
            <div
              v-else-if="isPrepayFlow">
              <div class="row">
                <div class="col text-end small-text py-1">
                  <span>
                    ${{ membershipPlanSelected.basePrice/100 }}
                  </span>
                  <span v-if="membershipPlanSelected.name !== 'Armoire Unlimited Monthly'">
                    due after first month</span>
                  <span v-else>
                    after
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col text-end small-text semi-bold py-1 text-success">
                  <span>
                    You save {{ formatPrice(promoDiscount(membershipPlanSelected), true) }}!
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="promoAmount > 0 && showPromoDiscount && !referringClient && !isPrepayFlow && !displayFirstChargeOverride"
              class="row">
              <div class="col-8 text-start small-text text-success py-1 semi-bold">
                {{ promoText }}
              </div>
              <div
                class="col-4 text-success text-end small-text py-1">
                <b>–${{ promoAmount }}</b>
              </div>
            </div>
            <div
              v-if="referringClient"
              class="row">
              <div class="col-8 text-start small-text text-success py-1 semi-bold">
                {{ referralDiscountText(membershipPlanSelected) }} off
              </div>
              <div class="col-4 text-success text-end small-text py-1">
                <b>–{{ formatPrice(discountAmount) }}</b>
              </div>
            </div>
          </div>
          <div
            v-for="(item, index) in lineItems"
            :key="'lineItem-' + index"
            class="row">
            <div class="col text-start small-text py-1">
              {{ item.description }}
            </div>
            <div
              class="col text-end small-text py-1 text-success semi-bold">
              {{ item.price }}
            </div>
          </div>
          <div
            v-if="credit"
            class="row">
            <div class="col text-start small-text py-1 text-success">
              Credit
            </div>
            <div class="col text-end small-text py-1 text-success">
              {{ formatPrice(credit) }}
            </div>
          </div>
          <div
            v-if="activatedGiftCardAmount"
            class="row">
            <div class="col text-start small-text py-1 text-success">
              Available gift card amount
            </div>
            <div class="col text-end small-text py-1 text-success">
              {{ formatPrice(activatedGiftCardAmount) }}
            </div>
          </div>
          <div
            v-for="gc in validGiftCards"
            :key="gc.code"
            class="row">
            <div
              class="col-8 text-start small-text semi-bold py-1 text-success">
              <span>
                Gift card {{ gc.code }}
              </span>
            </div>
            <div class="col-4 text-end small-text semi-bold py-1 text-success">
              {{ formatPrice(gc.value) }}
            </div>
          </div>
          <div
            class="row">
            <div class="col text-start small-text py-1">
              Tax
            </div>
            <div class="col text-end small-text py-1">
              {{ taxDisplay }}
            </div>
          </div>

          <hr
            class="w-100">
          <div
            class="row mb-4">
            <div class="col-5 text-start small-text semi-bold">
              Today You Pay
              <br>
            </div>
            <div class="col-7 text-end">
              <div class="small-text semi-bold">
                {{ formatPrice(total, false, true) }}
              </div>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-between align-items-center pt-2 pb-3">
            <base-input
              v-model="enteredCode"
              class="w-100 pe-md"
              :disabled="formDisabledLocal || formDisabled"
              :label="referralEligible ? 'Gift, referral, or promo code' : 'Gift or promo code'"/>
            <sequin-button
              small
              :disabled="formDisabledLocal || formDisabled || !enteredCode"
              variant="secondary"
              @click="apply">
              Apply
            </sequin-button>
          </div>
          <div
            v-if="creditBalance"
            class="row mb-1">
            <div class="col-5 text-start small-text semi-bold text-plum">
              Remaining Gift Card Balance
            </div>
            <div class="col-7 text-end">
              <div class="small-text semi-bold text-plum">
                {{ formatPrice(creditBalance) }}
              </div>
            </div>
          </div>
          <div
            v-if="creditBalance"
            class="row mb-1 fine-print text-plum">
            <div class="col-12">
              Your remaining balance will be added to your account as
              subscription credit
            </div>
          </div>
          <app-message
            v-if="referringClient"
            class="mb-1"
            variant="success">
            Your {{ referralDiscountText(membershipPlanSelected) }} off gift from {{ referringClient.firstName }} has been applied!
          </app-message>
        </div>

        <slot/>
        <div>
          <p class="fine-print text-center semi-bold">
            {{ chargePostScript }}
          </p>
        </div>
        <div
          v-if="membershipStatus !== 'active' && finePrintText.length"
          class="border-top">
          <p class="fine-print text-center pt-md">
            By clicking the “{{ finePrintText }}” button you agree to the
            <text-link
              type="a"
              class="fine-print mb-0 py-0 fine-print-link"
              variant="secondary"
              href="https://support.armoire.style/rental-policy/"
              target="_blank">
              Rental Policy
            </text-link>
            and agree to be charged {{ formatPrice(membershipPlanSelected.basePrice, false ) }} automatically {{ chargeIntervalCopy(membershipPlanSelected) }} until you cancel the subscription service as set forth in the
            <text-link
              type="a"
              class="fine-print mb-0 py-0 fine-print-link"
              variant="secondary"
              href="https://support.armoire.style/legal/"
              target="_blank">
              Terms of Service.
            </text-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions as mapVuexActions, mapState, mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

import { formatPrice } from '@/utils/stringParsing.js'
import SequinButton from '../global/sequin/SequinButton'
import SubscribeStepIndicator from './SubscribeStepIndicator'
import SubscribeLeadSource from './SubscribeLeadSource'
import TextLink from '../global/sequin/TextLink'
import { PaymentMixin } from '../global/mixins/PaymentMixin'
import ReferralsMixin from '../global/mixins/ReferralsMixin'
import AppMessage from '../global/sequin/AppMessage'
import prepayMixin from '../global/mixins/prepayMixin'
import BaseFormErrors from '@/components/global/BaseFormErrors.vue'
import BaseInput from '@/components/global/BaseInput.vue'

export default {
  components: {
    SequinButton,
    SubscribeStepIndicator,
    SubscribeLeadSource,
    TextLink,
    AppMessage,
    BaseFormErrors,
    BaseInput
  },
  mixins: [PaymentMixin, ReferralsMixin, prepayMixin],
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    formDisabled: {
      type: Boolean,
      default: false
    },
    finePrintText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      enteredCode: '',
      formDisabledLocal: false,
      lineItems: [
        { description: 'Personal Styling', price: 'Free' },
        { description: 'Cleaning', price: 'Free' },
        { description: 'Shipping', price: 'Free' }
      ],
      formErrors: []
    }
  },
  computed: {
    ...mapState('client', [
      'membershipStatus'
    ]),
    ...mapState('subscribe', [
      'tax',
      'referringClient',
      'activatedGiftCardAmount',
      'validGiftCards',
      'invalidGiftCards'
    ]),
    ...mapGetters('subscribe', [
      'defaultPromoForSelectedPlan',
      'membershipPlanSelected',
      'referralDiscountText'
    ]),
    ...mapState('styleQuizNav', [
      'isPrepayFlow'
    ]),
    planName () {
      if (this.isPrepayFlow) {
        return this.prepayPlanGroup().planCardNameOverride
      }
      return this.membershipPlanSelected.displayName
    },
    planNameLineDisplay () {
      if (this.displayFirstChargeOverride) {
        return `${this.planName}, ${this.membershipPlanSelected.appliedPromo.trialMonths} month${this.membershipPlanSelected.appliedPromo.trialMonths > 1 ? 's' : ''}`
      }
      return `${this.membershipPlanSelected.displayName}`
    },
    priceLineDisplay () {
      if (this.displayFirstChargeOverride) {
        return `$${(this.membershipPlanSelected.basePrice * this.membershipPlanSelected.appliedPromo.trialMonths) / 100.0}`
      }
      return `$${this.membershipPlanSelected.basePrice / 100} ${this.membershipPlanSelected.chargeInterval === 1 ? '/month' : ''}`
    },
    showPromoDiscount () {
      return (this.membershipPlanSelected?.appliedPromo && (this.membershipPlanSelected.appliedPromo.dollarAmount > 0 || this.membershipPlanSelected.appliedPromo.trialMonths > 0 || this.membershipPlanSelected.appliedPromo.percentDiscount > 0))
    },
    showItemPromoDetails () {
      return this.membershipPlanSelected?.appliedPromo
    },
    taxDisplay () {
      if (this.tax === null) { return '---' }
      return '$' + (this.tax / 100).toFixed(2)
    },
    referralEligible () {
      return this.membershipStatus !== 'former_member'
    },
    displayFirstChargeOverride () {
      return !this.isPrepayFlow && this.membershipPlanSelected?.appliedPromo && this.membershipPlanSelected.appliedPromo?.firstChargeOverride && !this.membershipPlanSelected.prepayment
    },
    bonusItemsOnSignup () {
      return this.membershipPlanSelected?.appliedPromo?.bonusItemsOnSignup || 0
    },
    swapsOnSignUp () {
      return this.membershipPlanSelected?.appliedPromo?.shipmentsOnSignup || 0
    },
    chargePostScript () {
      if (this.displayFirstChargeOverride) {
        return `You will be charged $${this.membershipPlanSelected.basePrice / 100} ${this.chargeIntervalCopy(this.membershipPlanSelected)} after your promotional period ends`
      } else if (this.showPromoDiscount) {
        return `You will be charged $${this.membershipPlanSelected.basePrice / 100} ${this.chargeIntervalCopy(this.membershipPlanSelected)} after your first month`
      }
      return `You will be charged $${this.membershipPlanSelected.basePrice / 100} ${this.chargeIntervalCopy(this.membershipPlanSelected)}`
    }
  },
  methods: {
    ...mapVuexActions('subscribe', [
      'applyGeneralPromoCode'
    ]),
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    formatPrice,
    getCouponDescription (itemCouponsCount) {
      if (!this.membershipPlanSelected?.appliedPromo) { return '' }
      let discount = ''
      if (this.membershipPlanSelected.appliedPromo.itemCouponsDiscountDollarAmount) {
        discount = formatPrice(this.membershipPlanSelected.appliedPromo.itemCouponsDiscountDollarAmount)
      } else {
        discount = `${this.membershipPlanSelected.appliedPromo.itemCouponsDiscountPercentage}%`
      }
      return `${discount} off ${itemCouponsCount > 1 ? itemCouponsCount : 'an'} item${itemCouponsCount > 1 ? 's' : ''} you love`
    },
    async apply () {
      this.formErrors = []
      if (!this.enteredCode) {
        return
      }
      this.formDisabledLocal = true
      this.formErrors = await this.applyGeneralPromoCode(this.enteredCode)
      if (this.invalidGiftCards && this.invalidGiftCards.length > 0) {
        let invalid = 'The following code(s) are not valid:'
        for (const code of this.invalidGiftCards) {
          invalid += ` ${code}`
        }
        this.formErrors.push(invalid)
      }
      this.formDisabledLocal = false
      this.enteredCode = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.fine-print-link{
  color: $pewter;
}
:deep(.form-control) {
  margin-top: 0 !important;
}
:deep(.form-group) {
  margin-top: 0 !important;
  .input-group {
    margin-top: 0 !important;
  }
}
.plan-list {
  padding-left: 24px;
}
</style>
