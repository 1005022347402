import axios from './util/axios.js'

import { serializeParams } from '@/store/api/helpers/helpers.js'

export function postStyleColorClick (styleColorId, data) {
  return axios.post(`/api/stylecolors/${styleColorId}/click/`, data)
}

export function getHomepageSections () {
  return axios.get('/api/stylecolorsections/homepage_sections/')
}

export function getSection (id) {
  return axios.get(`/api/stylecolorsections/${id}/`)
}

export function getGlobalFilterOptions () {
  return axios.get('/api/closets/filters/')
}

export function getSectionStyleColors (data) {
  let params = `?offset=${data.offset}&amount=${data.amount}&filter_version=2`
  if (data.available === true) params += `&available=${data.available}`
  if (data.sort) params += `&sort=${data.sort}`
  if ('sectionFilters' in data) params += '&' + serializeParams(data.sectionFilters)
  if ('sizeFilter' in data) params += `&sizeFilter=${data.sizeFilter}`
  if (typeof data.dedupeKey === 'string') params += `&dedupeKey=${data.dedupeKey}`
  return axios.get(`/api/stylecolorsections/${data.id}/style_colors/${params}`)
}
