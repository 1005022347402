<template>
  <div class="d-flex flex-row flex-wrap gap-xs">
    <ReviewItemCheckbox
      v-for="option in options"
      :key="option.value"
      v-model="value"
      :input-value="option.value"
      :text="option.text"/>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import ReviewItemCheckbox from './ReviewItemCheckbox.vue'

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  modelValue: {
    type: [Boolean, Array],
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

defineOptions({
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
})
</script>
