<template>
  <div
    class="bg-white">
    <action-header
      :fallback-route="{ name: 'feed' }"
      page-header>
      <div v-if="!feedItemLoading && !feedItemLoadError && feedItem">
        {{ feedItem.client.firstName }}'s {{ feedItemType }}
      </div>
      <div v-else-if="feedItemLoadError">
        Loading Error
      </div>
    </action-header>
    <feed-item-deeplink-error
      v-if="!feedItemLoading && feedItemLoadError"
      class="bg-origami p-4"/>
    <div v-else-if="feedItem && !feedItemLoading">
      <feed-item-header
        class="pt-3 px-4 mb-3"
        :feed-item="feedItem"/>
      <feed-item-image
        v-if="feedItem.image"
        :feed-item-id="feedItem.id"
        :image-source-url="feedItem.image"/>
      <feed-item-collection-preview
        v-if="metadata.type === 'collection'"
        :id="feedItem.item.id"
        class="mb-4"
        :horizontal-border="false"
        :style-colors="feedItem.styleColors"
        :metadata="metadata"/>
      <div
        v-if="showSizes"
        class="sizes-wrapper">
        <feed-item-sizes
          :client-name="feedItem.client.firstName"
          :style-profile="feedItem.client.styleProfile"
          :style-colors="feedItem.styleColors"
          class="feed-item-sizes"/>
      </div>
      <div class="my-3">
        <feed-item-style-color
          v-if="feedItem.styleColors && feedItem.styleColors[0] && metadata.type === 'case-rented' && metadata.type !== 'collection'"
          class="pb-2"
          :feed-item-id="feedItem.id"
          :style-colors="feedItem.styleColors"/>
        <feed-item-action-bar
          v-if="feedItem.item"
          class="action-bar"
          :feed-item-id="feedItem.id"
          :likes-and-favorites="feedItem.likesAndFavorites"
          :comments="feedItem.comments"
          :timestamp="feedItem.created"
          @comment-click="$refs.addCommentBar.focus()"
          @like-click="$emit('like-click', feedItem.id)"/>
        <feed-item-style-color
          v-if="feedItem.styleColors && feedItem.styleColors[0] && metadata.type !== 'case-rented' && metadata.type !== 'collection'"
          :feed-item-id="feedItem.id"
          :style-colors="feedItem.styleColors"/>
        <feed-item-caption
          ref="feedItemCaptionMobile"
          class="caption"
          :caption="feedItem.caption"/>
      </div>
      <div class="comment-container">
        <div
          v-if="feedItemComments && feedItemComments.length > 0"
          class="mt-4 mb-5 feedComments">
          <feed-item-comment
            v-for="feedItemComment in feedItemComments"
            :key="feedItemComment.id"
            class="comment"
            :feed-item-id="feedItem.id"
            :feed-item-comment="feedItemComment"/>
        </div>
        <div v-else-if="commentsLoading">
          <svg-spinner
            width="40"
            height="40"
            class="d-block mx-auto"/>
        </div>
        <error-message
          v-else-if="commentsLoadError"
          class="mx-4">
          We were unable to load the comments for this {{ type }}
        </error-message>
      </div>
      <feed-item-add-comment
        v-if="feedItem.item"
        ref="addCommentBar"
        :feed-item-id="feedItem.id"/>
    </div>
    <feed-item-placeholder v-else/>
  </div>
</template>

<script>
import FeedItemAddComment from './FeedItemAddComment'
import FeedItemHeader from './FeedItemHeader'
import FeedItemImage from './FeedItemImage'
import FeedItemActionBar from './FeedItemActionBar'
import FeedItemComment from './FeedItemComment'
import FeedItemCaption from './FeedItemCaption'
import FeedItemCollectionPreview from './FeedItemCollectionPreview.vue'
import FeedItemPlaceholder from './FeedItemPlaceholder'
import FeedItemDeeplinkError from './FeedItemDeeplinkError'
import FeedItemStyleColor from './FeedItemStyleColor'
import FeedItemSizes from './FeedItemSizes'
import ErrorMessage from '../common/ErrorMessage'
import ActionHeader from '../../global/sequin/ActionHeader'
import scrollCommand from '../../global/mixins/scrollCommand'
import { mapGetters } from 'vuex'
import { vuexAccessors } from '../../global/helpers/vuex'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  name: 'FeedItemDeeplinkMobile',
  components: {
    ActionHeader,
    ErrorMessage,
    FeedItemImage,
    FeedItemHeader,
    FeedItemActionBar,
    FeedItemAddComment,
    FeedItemCollectionPreview,
    FeedItemComment,
    FeedItemCaption,
    FeedItemDeeplinkError,
    FeedItemPlaceholder,
    FeedItemStyleColor,
    FeedItemSizes,
    SvgSpinner
  },
  mixins: [scrollCommand],
  props: {
    feedItem: {
      type: Object,
      default: () => {}
    },
    feedItemLoading: {
      type: Boolean,
      default: true
    },
    feedItemLoadError: {
      type: Boolean,
      default: false
    },
    feedItemComments: {
      type: Array,
      default: () => []
    },
    commentsLoading: {
      type: Boolean,
      default: true
    },
    commentsLoadError: {
      type: Boolean,
      default: false
    },
    styleColorSource: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters('community', [
      'feedItemMetadata'
    ]),
    ...vuexAccessors('community', [
      'deeplinkAction'
    ]),
    showSizes () {
      const { styleColors, client } = this.feedItem
      return styleColors.length &&
        client.styleProfile &&
        this.feedItemType === 'look' &&
        this.metadata.type !== 'collection'
    },
    metadata () {
      return this.feedItemMetadata(this.feedItem)
    },
    feedItemType () {
      if (this.feedItem.item && this.metadata.type === 'case-rented') {
        return 'Case'
      }
      if (this.feedItem.item && this.metadata.type === 'collection') {
        return 'Collection'
      }
      return this.feedItem.item ? this.feedItem.item.type === 'generic feed item' ? 'Post' : this.feedItem.item.type : 'look'
    }
  },
  mounted () {
    if (this.deeplinkAction && (this.deeplinkAction === 'comment' || this.deeplinkAction === 'caption') && this.isMobile) {
      this.$nextTick(() => this.scrollIntoViewWithOffset(this.$refs.feedItemCaptionMobile.$el, 72))
      this.deeplinkAction = null
    }
  }
}
</script>

<style lang="scss" scoped>
$header-height: 88px;

.caption {
  margin: 12px 28px 8px;
}

.comment {
  margin: 16px 28px;
}

.timestamp {
  margin: 0 28px;
}

.comment-container {
  min-height: 40vh;
  padding-bottom: $header-height;
}

.sizes-wrapper {
  position: relative;
}

.feed-item-sizes {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
