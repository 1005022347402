import { computed, toValue, watch } from 'vue'
import { useStore } from 'vuex'

export default function useCollection (collection) {
  const name = computed(() => toValue(collection)?.name)
  const itemCountText = computed(() => {
    const count = toValue(collection)?.count
    return typeof count === 'number' ? `${count} item${count === 1 ? '' : 's'}` : null
  })
  const previewImageUrls = computed(() => toValue(collection)?.previewImages ?? [])
  const routeLocation = computed(() => {
    const id = toValue(collection)?.id
    return id ? { name: 'collection', params: { id } } : { path: '#' }
  })

  return {
    name,
    itemCountText,
    previewImageUrls,
    routeLocation
  }
}

export function useCollectionById (id) {
  const store = useStore()
  const collection = computed(() => store.state.collections.collectionsById[toValue(id)])

  watch(id, () => {
    if (!collection.value || (toValue(id) && collection.value.id !== toValue(id))) {
      store.dispatch('collections/getCollection', { collectionId: toValue(id) })
    }
  }, { immediate: true })

  return useCollection(collection)
}
