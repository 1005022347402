<template>
  <div>
    <div class="d-flex justify-content-center">
      <text-placeholder
        variant="h5"
        size="large"
        class="my-4"/>
    </div>
    <div class="px-4">
      <div class="my-3">
        <text-placeholder
          variant="small-text"
          size="small"/>
        <text-placeholder
          variant="h6"
          size="large"/>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-5">
        <preview-image-placeholder size="x-large"/>
      </div>
      <text-placeholder
        class="w-100"
        variant="h2"
        size="large"/>
      <text-placeholder
        class="w-100 mb-4"
        variant="h2"
        size="large"/>
    </div>
  </div>
</template>

<script>
import PreviewImagePlaceholder from '@shared/components/ADORN/PreviewImagePlaceholder.vue'
import TextPlaceholder from '../../global/sequin/TextPlaceholder'

export default {
  components: {
    PreviewImagePlaceholder,
    TextPlaceholder
  }
}
</script>
