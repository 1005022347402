import axios from './config'
import store from '../index'
import { serializeParams } from './helpers/helpers'

const apiCommunity = {
  getFeedItems (client, tag, tagId, source, category, closetOccasion, seasons, feedContentTypes, availableInMySize) {
    let params = ''
    if (client || tag || tagId || source || category || closetOccasion || seasons || feedContentTypes || availableInMySize) {
      params = '?' + serializeParams({ client, tag, tagId, source, category, closetOccasion, seasons, feedContentTypes, availableInMySize })
    }
    const url = '/api/feeditems/' + params
    return axios.get(url)
  },
  getNextPage (endpoint) {
    return axios.get(endpoint)
  },
  getLooks (client) {
    let params = ''
    if (client) {
      params = '?client=' + client
    }
    return axios.get('/api/looks/' + params)
  },
  getFeedItem (feedItemId) {
    return axios.get('/api/feeditems/' + feedItemId + '/')
  },
  getFeedItemComments (id) {
    return axios.get(
      '/api/feeditems/' + id + '/comments/')
  },
  getFeedItemLikes (id) {
    return axios.get(
      '/api/feeditems/' + id + '/likes/')
  },
  getClientPublicProfile (clientId) {
    return axios.get(
      '/api/clients/' + clientId + '/'
    )
  },
  postFeedItemReaction (feedItemId, likeData) {
    return axios.post('/api/feeditems/' + feedItemId + '/like/', likeData)
  },
  postFeedItemComment (commentData) {
    return axios.post('/api/feedcomments/', commentData)
  },
  patchFeedItemComment (commentId, commentData) {
    return axios.patch('/api/feedcomments/' + commentId + '/', commentData)
  },
  deleteFeedItemComment (feedItemCommentId) {
    return axios.delete('/api/feedcomments/' + feedItemCommentId)
  },
  patchCommunityProfile (data) {
    const username = store.state.client.username
    return axios.patch('/api/clients/' + username + '/', data)
  },
  postLook (data) {
    return axios.post('/api/looks/', data)
  },
  patchLook (data, lookId) {
    return axios.patch('/api/looks/' + lookId + '/', data)
  },
  deleteLook (lookId) {
    return axios.delete('/api/looks/' + lookId + '/')
  },
  getLook (lookId) {
    return axios.get('/api/looks/' + lookId + '/')
  },
  getSystemTags (category) {
    return axios.get('/api/tags/?type=system&category=' + category)
  },
  getProfileImageUploadUrl () {
    const username = store.state.client.username
    return axios.get('/api/clients/' + username + '/profile_image_upload_url')
  },
  getImageUploadUrl () {
    return axios.get('/api/looks/image_upload_url/')
  },
  getUserFeedLikes () {
    const username = store.state.client.username
    return axios.get('/api/clients/' + username + '/likes/')
  },
  getFeedItemLikesAndFavorites (feedItemId) {
    return axios.get(`/api/feeditems/${feedItemId}/likes_and_favorites/`)
  }
}

export default apiCommunity
