<!-- This file exists as to not break the appearance of the "New to You" style game, which is still handled in a separate page. -->
<template>
  <div class="outro-container mt-6">
    <h5 class="outroHeading">
      {{ heading }}
    </h5>
    <div class="subhead-container">
      <p
        v-if="subheading"
        class="subhead">
        {{ subheading }}
      </p>
    </div>
    <div class="outro-children">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true
    },
    subheading: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleGamesStyles.scss';
</style>
