<template>
  <div>
    <div class="row">
      <h2
        class="col col-12 text-center text-md-start mb-2 ps-md-0">
        {{ headerText }}
      </h2>
    </div>
    <div class="row">
      <div class="col col-12 col-md-9 text-center text-md-start ps-md-0">
        <div class="text-md-start">
          <div v-if="clientIsPaused">
            <h5
              :class="{'pb-3': !pauseCredit}">
              Your subscription will automatically resume on {{ pauseEndDate }}.
            </h5>
            <h5
              v-if="pauseCredit">
              We've added a {{ formatPrice(pauseCredit) }} credit to your account.
            </h5>
            <p
              v-if="pauseCredit">
              Please return all items in your prepaid return bag to avoid being charged
              for outstanding items.
            </p>
            <h5 class="pb-1">
              Need more time?
            </h5>
            <div
              v-if="canExtendPause && !eligibleForPause && ineligibleForPauseReason === 'ITEMS_OUT'"
              class="pb-3">
              Your Armoire membership is on pause but we noticed you still have
              items at home. Before you can extend you pause please return or
              purchase any outstanding items. Otherwise your membership will
              automatically resume on {{ pauseEndDate }}.
              <h5
                class="pt-3 pb-2">
                Return your items
              </h5>
              If you need any help with this process, please visit our
              <text-link
                size="paragraph"
                variant="primary"
                type="router-link"
                :to="{ name: 'returns' }">
                returns page
              </text-link>
              or feel free to
              <text-link
                size="paragraph"
                @click="onReachOutClick()">
                reach out to us
              </text-link>.
              <h5
                class="pt-3 pb-2">
                Purchase your items
              </h5>
              Utilize our members only discount and add some (or all) of your
              items to your permanent wardrobe.
            </div>
            <div
              v-else-if="canExtendPause && !eligibleForPause"
              class="pb-3">
              If you need to skip another month, please email us at
              <a
                href="mailto:hi@armoire.style?subject=I%20have%20a%20question">
                hi@armoire.style
              </a>
            </div>
            <div
              v-else-if="canExtendPause"
              class="pb-3">
              If you need to skip another month, make sure to do so before
              {{ toLocaleDateString(pauseEndDate) }}.
            </div>
            <div
              v-else
              class="pb-3">
              You can choose to skip another month one week before your reactivation date.
              You're eligible to extend your break starting on {{ toLocaleDateString(extensionEligibleDate) }}.
            </div>
          </div>
          <div v-else-if="pauseScheduled">
            <h5
              class="pt-3 pb-3 text-md-start">
              Your account will be paused from {{ pauseStartDate }} to {{ pauseEndDate }}
            </h5>
            <p class="pb-3">
              Please return all items in your prepaid return bag by {{ pauseStartDate }} to avoid being
              charged for outstanding items.
            </p>
            <p class="pb-3">
              We'll send you an email reminder a few days before your subscription resumes. And don't worry -- if your
              plans change, you can always reactivate your membership early.
            </p>
            <h5>
              Need to make a change?
            </h5>
            <p class="pb-3">
              Contact our support team at
              <text-link
                type="a"
                href="mailto:hi@armoire.style"
                target="_blank">
                hi@armoire.style
              </text-link>
              to edit your pause.
            </p>
          </div>
          <div v-else-if="hasUpgradePlan || !canPause">
            <h5
              class="pt-3 text-md-start">
              Sometimes you just need a break
            </h5>
            <p class="pb-3">
              Contact our support team at
              <text-link
                type="a"
                href="mailto:hi@armoire.style"
                target="_blank">
                hi@armoire.style
              </text-link>
              to request to pause your membership.
            </p>
          </div>
          <div v-else>
            <h5
              class="pt-2 pb-3 text-md-start">
              Sometimes you just need a break
            </h5>
            <p class="pb-3">
              <span v-if="clientIsPrepay">
                Keep all your prepay benefits when you pause your subscription for one month.
              </span>
              <span v-else>
                Keep all of your member benefits when you pause your subscription for one month.
                Don’t worry -- we’ll send you an email reminder before your subscription resumes
                in case you need to add more time.
              </span>
            </p>
            <div>
              <h5
                class="pb-3 text-md-start">
                What does pausing my membership mean?
              </h5>
              <p class="pb-3">
                <span v-if="clientIsPrepay">
                  Pausing your membership means that your subscription is inactive
                  and you’ll need to return any case items that are currently with you.
                  Your prepay renewal billing date will be pushed out by the length of
                  your pause. Unlike cancelling, pausing your membership allows you to
                  retain all of your benefits, including {{ availableSwaps }} available
                  shipment{{ availableSwaps === 1 ? '' : 's' }},
                  {{ bonusItems }} bonus items, and
                  <span v-if="creditAvailable">
                    {{ formatPrice(creditAvailable) }}
                  </span> unused referral credits.
                </span>
                <span v-else>
                  Pausing your membership means that you will not be charged for a month of your
                  Armoire membership, and you’ll need to return any case items that are
                  currently with you. Monthly charges will resume one month later. Unlike
                  cancelling, pausing your membership allows you to retain all of your benefits,
                  including {{ availableSwaps }} available shipment{{ availableSwaps > 1 ? 's' : '' }}, {{ bonusItems }} bonus item{{ bonusItems > 1 ? 's' : '' }}, and
                  <span v-if="creditAvailable">
                    {{ formatPrice(creditAvailable) }}
                  </span> unused referral credits.
                </span>
              </p>
            </div>
            <div
              v-if="outstandingItems.length">
              <h5
                class="pb-3 text-md-start">
                Anything I need to do?
              </h5>
              <p class="pb-3">
                <span>
                  Please return the {{ outstandingItems.length }} item{{ outstandingItems.length > 1 ? 's' : '' }}
                  that you have with your prepaid shipping label. These items must be received
                  and processed by Armoire within 30 days, otherwise you will not be able
                  to extend your pause and your membership will be reinstated.
                </span>
              </p>
            </div>
            <div
              class="deterrent chat-help">
              <h5 class="col col-12 text-center text-md-start ps-md-0 mb-1 mb-md-2">
                Other questions?
              </h5>
              <p class="col col-12 text-center text-md-start ps-md-0 mb-0 mb-md-0">
                <text-link
                  size="paragraph"
                  variant="primary"
                  type="a"
                  name="Chat with customer support"
                  @click="initiateChatClick">
                  Chat
                </text-link>
                with our customer support team to address any outstanding questions.
              </p>
            </div>
            <div
              v-if="clientIsPrepay">
              <h5
                class="mb-3">
                Your pause will begin immediately and will end in a month.
                Your billing date will move from  {{ toLocaleDateString(paidThrough) }}
                to {{ toLocaleDateString(nextBillingDate) }}.
              </h5>
              <p class="pb-3">
                We'll send you an email reminder before your membership resumes.
              </p>
            </div>
            <div
              v-else-if="pauseStartDate && !isMobile"
              class="pb-3 mt-3">
              <h5>Pause my membership from {{ pauseStartDate }} to {{ pauseEndDate }}</h5>
            </div>
          </div>
          <div
            v-for="error in errors"
            :key="error"
            class="mb-2 text-danger"
            v-html="error"/>
          <sequin-button
            v-if="!isMobile"
            :to="{ name: 'account' }"
            :type="canPause ? 'button': 'router-link'"
            :variant="canPause ? 'primary' : 'secondary'"
            :disabled="buttonDisabled"
            @click="onPauseClick">
            {{ canPause ? 'Pause Membership' : 'My Account' }}
          </sequin-button>
        </div>
      </div>
    </div>
    <action-footer v-if="isMobile">
      <template
        v-if="canPause"
        #message>
        <div
          class="text-center pt-3">
          <span v-if="clientIsPrepay">
            Pause my membership until
          </span>
          <span v-else>
            Pause my membership from
          </span>
          <br>
          <h5
            v-if="clientIsPrepay">
            {{ pauseEndDate }}
          </h5>
          <h5
            v-else>
            {{ pauseStartDate }} to {{ pauseEndDate }}
          </h5>
        </div>
      </template>
      <template #button>
        <sequin-button
          mobile-block
          :to="{ name: 'account' }"
          :type="canPause ? 'button': 'router-link'"
          :variant="canPause ? 'primary' : 'secondary'"
          :disabled="buttonDisabled"
          @click="onPauseClick">
          {{ canPause ? 'Pause Membership' : 'My Account' }}
        </sequin-button>
      </template>
    </action-footer>
  </div>
</template>

<script>
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions, mapGetters, mapState } from 'vuex'
import { toLocaleDateString, formatPrice } from '@/utils/stringParsing.js'
import ActionFooter from '../ActionFooter'
import MembershipMixin from './MembershipMixin'
import TextLink from '../../global/sequin/TextLink'

export default {
  name: 'PausePage',
  components: { TextLink, ActionFooter, SequinButton },
  mixins: [MembershipMixin],
  data () {
    return {
      buttonDisabled: false,
      pauseStartDate: null,
      pauseEndDate: null,
      pauseCredit: 0,
      nextBillingDate: null,
      errors: null
    }
  },
  computed: {
    ...mapState('client', [
      'paidThrough',
      'membershipStatus',
      'plans'
    ]),
    ...mapGetters('closet', [
      'outstandingItems'
    ]),
    headerText () {
      if (this.clientIsPaused) return "You've skipped this month"
      if (this.pauseScheduled) return "You're scheduled to skip a month"
      return 'Pause Membership'
    },
    hasUpgradePlan () {
      return !!this.plans.currentUpgradePlan && this.plans.currentUpgradePlan.upgrade === true
    },
    canPause () {
      return this.eligibleForPause && !this.hasUpgradePlan && (!this.pauseScheduled || (this.clientIsPaused && this.canExtendPause))
    },
    clientIsPaused () {
      return this.membershipStatus === 'paused'
    },
    extensionEligibleDate () {
      // Note: this business logic should be moved to the backend
      if (this.membershipDetails && this.membershipDetails.pauseResumeDate) {
        const eligibleDate = new Date(Date.parse(this.membershipDetails.pauseResumeDate))
        eligibleDate.setDate(eligibleDate.getDate() - 7)
        eligibleDate.setHours(0, 0, 0, 0)
        return eligibleDate
      }
      return null
    },
    canExtendPause () {
      return this.extensionEligibleDate !== null && Date.now() > this.extensionEligibleDate
    },
    pauseScheduled () {
      if (this.membershipDetails && this.membershipDetails.pauseResumeDate) {
        return new Date(this.membershipDetails.pauseResumeDate) > new Date()
      }
      return false
    }
  },
  created () {
    if (!this.displayPauseAction) {
      this.$router.push({ name: 'membership-overview' })
    }
  },
  mounted () {
    if (this.pauseScheduled) {
      this.pauseStartDate = new Date(this.membershipDetails.pauseDate).toLocaleDateString('en-US')
      this.pauseEndDate = new Date(this.membershipDetails.pauseResumeDate).toLocaleDateString('en-US')
    } else {
      // Calculate the potential pause dates based on paid-through date
      this.initializePauseDates()
    }
    this.breadcrumbs = [
      { name: 'Membership', to: { name: 'membership-overview' } },
      { name: 'Pause Membership' }
    ]
  },
  methods: {
    ...mapActions('account', [
      'pauseSubscription'
    ]),
    toLocaleDateString,
    formatPrice,
    async onReachOutClick () {
      // eslint-disable-next-line
      Intercom('show')
    },
    async onPauseClick () {
      this.buttonDisabled = true
      this.errors = null
      try {
        const res = await this.pauseSubscription({ months: 1 })
        this.pauseStartDate = toLocaleDateString(res.data.pauseStartDate)
        this.pauseEndDate = toLocaleDateString(res.data.pauseEndDate)
        this.pauseCredit = res.data.credit
      } catch (err) {
        this.errors = err
      }
    },
    initializePauseDates () {
      const paidThrough = new Date(Date.parse(this.paidThrough))
      let startDate = new Date(new Date(paidThrough).setHours(paidThrough.getHours() - 1))
      let endDate = new Date(new Date(startDate).setMonth(startDate.getMonth() + 1))
      if (this.clientIsPrepay) {
        startDate = new Date(Date.now())
        this.nextBillingDate = endDate
        endDate = new Date(new Date(startDate).setMonth(startDate.getMonth() + 1))
      }
      this.pauseStartDate = startDate.toLocaleDateString('en-US')
      this.pauseEndDate = endDate.toLocaleDateString('en-US')
    },
    initiateChatClick () {
      window.Intercom('showNewMessage')
    }
  }
}
</script>

<style scoped>

</style>
