<template>
  <div
    class="d-flex flex-column">
    <div
      class="d-flex justify-content-center gap-3"
      :class="isMobile ? 'mt-4' : 'mt-5 mb-4'">
      <base-checkbox
        v-for="option in sizingTypes.filter(x => x.value !== 'waist')"
        :key="option.value"
        :value="option.visible"
        :input-value="option.visible"
        :text="option.text"
        :disabled="toggleDisabled(option.value)"
        col-class="mr-3"
        @input="toggleSizeTypeView($event, option.value, 'styleQuiz')"/>
    </div>
    <size-selection
      v-for="sizeType in visibleSizeTypes"
      :key="sizeType.value"
      class="size-selection-class pt-4"
      container-class="size-container"
      :sizes="sizeType.sizes"
      :title="sizeType.text"
      :initial-selections="initialSizeSelections(sizeType.value, 'allSizes', 'styleQuiz')"
      emit-ref="allSizes"
      @update-size-selections="updateSizes($event, sizeType.value)"/>
    <size-selection
      v-if="visibleSizeTypes.length > 0"
      class="size-selection-class border-top pt-4"
      :class="isMobile ? 'mt-4' : 'mt-5'"
      container-class="size-container"
      :sizes="waistSizeType.sizes"
      :title="waistSizeType.text"
      :initial-selections="waistSizes"
      :emit-ref="waistSizeType.value"
      @update-size-selections="updateSizes">
      <template #subtitle>
        <text-link
          class="semi-bold font-montserrat"
          size="paragraph"
          @click="openModal({ 'name': 'denim-sizes' })">
          Need Help?
        </text-link>
      </template>
    </size-selection>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import SizeSelection from '../global/sequin/SizeSelection.vue'
import sizeSelectionMixin from '../global/sequin/sizeSelectionMixin'
import { useOverlaysStore } from '@/stores/overlays.js'
import TextLink from '@/components/global/sequin/TextLink.vue'
import BaseCheckbox from '@/components/global/BaseCheckbox.vue'

export default {
  components: {
    SizeSelection,
    TextLink,
    BaseCheckbox
  },
  mixins: [sizeSelectionMixin],
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    async updateSizes (sizeData, sizeType) {
      sizeData.sizes = this.getFilteredSizes(sizeData, sizeType)
      await this.setSizesFromStyleQuiz({ sizeData })
    }
  }
}
</script>

<style lang="scss" scoped>

:deep(.checkbox-text) {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400;
}

.size-selection-class {
  width: 90%;
  margin: 0 auto;
  max-width: 680px;
  @media (max-width: 750px) {
    width: 90%;
    max-width: 330px;
  }
}

:deep(.size-container) {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: .75rem;
  @media (min-width: 750px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

:deep(.size-category-title) {
  padding-bottom: 1rem;
}

</style>
