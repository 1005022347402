<template>
  <div class="search-bar">
    <label for="search-input">
      {{ label }}
    </label>
    <input
      v-bind="$attrs"
      id="search-input"
      class="filter-input"
      @input="$emit('input', $event.target.value)"
      @keyup="$emit('keyup', $event.target.value)">
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  background-color: $origami;
  display: flex;
  padding: 12px 8px;
  align-items: center;

  .search-icon {
    height: 20px;
    color: $pewter;
    width: auto;
    margin-right: 12px;
    flex-grow: 0;
  }

  .filter-input {
    height: 24px;
    border: none;
    background: inherit;
    color: $pewter;
    min-width: 0;

    &:focus {
      outline: none;
    }
  }
}
</style>
