import { inMobileWebView } from '@/utils/mobileApp'
import axios from './config'

const maybeRequestAuthToken = (data) => inMobileWebView() ? { ...data, getAuthToken: true } : data

// AUTH
const apiAuth = {
  login (data) {
    return axios.post('/api/login/', maybeRequestAuthToken(data))
  },
  signup (data) {
    return axios.post('/api/signup/', maybeRequestAuthToken(data))
  }
}

export default apiAuth
