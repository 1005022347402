<template>
  <div :class="['base-accordion', 'd-flex', 'flex-column', { expanded, fixed }]">
    <div
      class="header d-flex column-gap-xs align-items-center"
      @click="toggle">
      <SvgChevronDown
        v-if="!fixed && chevronPlacement === 'left'"
        class="chevron"/>
      <div class="flex-grow-1">
        <slot name="title">
          <h3 class="m-0">
            {{ title }}
          </h3>
        </slot>
      </div>
      <slot
        v-if="$slots.decorator"
        name="decorator"/>
      <SvgChevronDown
        v-if="!fixed && chevronPlacement === 'right'"
        class="chevron"/>
    </div>
    <div
      ref="content"
      class="accordion-content"
      :style="`--content-height: ${contentHeight}px;'`">
      <slot/>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import SvgChevronDown from '@/components/global/svg/SvgChevronDown.vue'
import { useElementSize } from '@/composables/element.js'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  startExpanded: {
    type: Boolean,
    default: false
  },
  // If fixed is true, the accordion will not be able to be toggled.
  fixed: {
    type: Boolean,
    default: false
  },
  chevronPlacement: {
    type: String,
    default: 'left',
    validator: value => ['left', 'right'].includes(value)
  }
})

const expand = ref(props.startExpanded)
const expanded = computed(() => expand.value || props.fixed)
function toggle () {
  if (!props.fixed) {
    expand.value = !expand.value
  }
}
watch(() => props.startExpanded, (value) => { expand.value = value })

const content = ref(null)
const size = useElementSize(content)
// Add 1px to prevent the content with borders from being cut off.
const contentHeight = computed(() => size.value.scrollHeight + 1)
</script>

<style lang="scss" scoped>
.base-accordion {
  row-gap: 0px;
  @include transition-base(row-gap);

  &:not(.fixed) .header {
    cursor: pointer;
  }

  .header .chevron {
    @include transition-base-all;
  }
  .accordion-content {
    max-height: 0px;
    overflow: hidden;
    @include transition-base(max-height);
  }

  &.expanded {
    row-gap: var(--accordion-content-spacing, $spacing-lg);

    .chevron {
      transform: rotate(180deg);
    }
    .accordion-content {
      display: block;
      max-height: var(--content-height);
    }
  }
}
</style>
