<template>
  <div class="error-message">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.error-message {
  background-color: lighten($danger-dark, 55%);
  font-size: $small-text-font-size;
  color: $danger-dark;
  padding: 8px 24px;
  border: 1px solid lighten($danger-dark, 40%);
}
</style>
