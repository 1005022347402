<template>
  <p
    class="text-center w-100 my-3">
    <span
      v-if="noShipments"
      class="mb-0">
      Your next Case Swap unlocks <b>{{ shipmentDate }}.</b>
    </span>
    <span
      v-else-if="caseInTransit">
      You have a case on the way!<br>
      Want more clothes? Reach out to us <text-link
        class="inline-link"
        size="paragraph"
        type="a"
        :href="emailLink">
        here
      </text-link>
    </span>
    <span
      v-else-if="tooManyItemsOut">
      You have too many items out.<br>
      You cannot select new items until we receive your returns.
    </span>
    <span
      v-else-if="isReviewOOC">
      You must review items before you can add this to your case.
    </span>
    <swaps-available-case-link
      v-if="noShipments"
      @my-case-link="openMyCaseFlyout('purchase-shipment')">
      <template #preLinkText>
        <span/>
      </template>
      <template #linkText>
        <span>Unlock early</span>
      </template>
      <template #postLinkText>
        <span> to rent this now</span>
      </template>
    </swaps-available-case-link>
    <swaps-available-case-link
      v-else-if="(tooManyItemsOut || isReviewOOC ) && !caseInTransit"
      class="inline-link"
      @my-case-link="openMyCaseFlyout"/>
  </p>
</template>

<script>
import { mapActions as mapVuexActions, mapState, mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import { toMonthDayString } from '@/utils/stringParsing.js'
import { ItemTypeStatusMixin } from './ItemTypeStatusMixin'
import SwapsAvailableCaseLink from '../global/swapsAvailable/SwapsAvailableCaseLink.vue'
import TextLink from '../global/sequin/TextLink.vue'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    SwapsAvailableCaseLink,
    TextLink
  },
  mixins: [ItemTypeStatusMixin],
  computed: {
    ...mapState('client', [
      'membershipDetails',
      'tooManyItemsOut',
      'firstName',
      'lastName'
    ]),
    ...mapState('closet', [
      'pendingItems'
    ]),
    ...mapGetters('client', [
      'nextShipmentDate',
      'itemsInTransit'
    ]),
    ...mapGetters('review', [
      'isReviewOOC'
    ]),
    caseInTransit () {
      return this.itemsInTransit
    },
    noShipments () {
      return this.membershipDetails.allowedShipments === 0
    },
    shipmentDate () {
      return toMonthDayString(this.nextShipmentDate, 'short')
    },
    statusMessage () {
      const shipmentDate = toMonthDayString(this.nextShipmentDate, 'short')
      if (this.caseInTransit) {
        return this.pendingItems.length > 0 ? 'A stylist is preparing your Case!' : 'You already have a Case on the way!'
      } else if (this.membershipDetails.allowedShipments === 0) {
        return `Your next Case Swap unlocks ${shipmentDate} Unlock early to rent this now`
      }
      return 'No Case Slots Available'
    },
    emailLink () {
      const subject = `Additional Shipment Purchase Request for ${this.firstName} ${this.lastName}`
      const body = `Hi there,%0DI’d like to request an additional shipment of [insert number of items] items.%0DCan you let me know next steps?%0D%0DBest,%0D${this.firstName}`
      const email = `mailto:hi@armoire.style?subject=${subject}&body=${body}`
      return email
    }
  },
  methods: {
    ...mapVuexActions('case', [
      'setMyCaseFlyoutName'
    ]),
    ...mapActions(useOverlaysStore, [
      'openFlyout'
    ]),
    openMyCaseFlyout (flyoutName = 'core') {
      this.setMyCaseFlyoutName(flyoutName)
      this.openFlyout({ name: 'my-case' })
    }
  }
}
</script>
<style lang="scss" scoped>
.inline-link {
  display: inline-block !important;
}
</style>
