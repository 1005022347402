<template>
  <div class="wrapper">
    <div
      v-for="referee in referrals"
      :key="referee.name"
      class="border-bottom px-4 py-4">
      <div>
        <h6>
          {{ referee.refereeEmail }}
        </h6>
        <p class="text-gray">
          {{ formatReferralSource(referee.source) }}
        </p>
      </div>
      <div>
        <div>
          <referral-progress-check :complete="referee.joined">
            <template #detail>
              <div class="ms-2">
                Joined
              </div>
            </template>
          </referral-progress-check>
        </div>
        <div class="mt-4">
          <referral-progress-check :complete="referee.conversionAwarded">
            <template #detail>
              <div
                :class="!referee.conversionAwarded ? 'd-flex align-items-center' : ''"
                class="ms-2">
                Credit Applied
                <div
                  v-if="referee.conversionAwarded"
                  class="text-gray small-text">
                  {{ formatDate(referee.modified) }}
                </div>
                <sequin-tooltip
                  v-if="!referee.conversionAwarded"
                  icon-class="ms-1">
                  Referral rewards are automatically added to your account 7 days after a friend signs up
                </sequin-tooltip>
              </div>
            </template>
          </referral-progress-check>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReferralProgressCheck from './ReferralProgressCheck'
import ReferralsMixin from '../global/mixins/ReferralsMixin'
import SequinTooltip from '../global/sequin/SequinTooltip.vue'

export default {
  name: 'ReferralHistoryMobile',
  components: { SequinTooltip, ReferralProgressCheck },
  mixins: [ReferralsMixin],
  props: {
    referrals: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    margin-left: -15px;
    margin-right: -15px;
    border-top: $default-border;
  }
</style>
