<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round">
    <path
      id="Layer"
      class="s0"
      d="m3 4h7"/>
    <path
      id="Layer"
      class="s0"
      d="m14 4h7"/>
    <path
      id="Layer"
      class="s0"
      d="m3 12h9"/>
    <path
      id="Layer"
      class="s0"
      d="m16 12h5"/>
    <path
      id="Layer"
      class="s0"
      d="m3 20h5"/>
    <path
      id="Layer"
      class="s0"
      d="m12 20h9"/>
    <path
      id="Layer"
      class="s0"
      d="m10 1v6"/>
    <path
      id="Layer"
      class="s0"
      d="m16 9v6"/>
    <path
      id="Layer"
      class="s0"
      d="m8 17v6"/>
  </svg>
</template>
