<template>
  <svg
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 162.9 162.9">
    <title>Share via Email</title>
    <g data-name="Layer 2">
      <g
        class="cls-2"
        data-name="Layer 1">
        <path
          :fill="backgroundFill"
          d="M160.7 100.16a81.43 81.43 0 1 0-98 60.54 81.43 81.43 0 0 0 98-60.54"/><g class="cls-4">
            <path
              :fill="color"
              d="M118 116.89a7.43 7.43 0 0 0 5.17-2L98 89.77l-1.76 1.21-4.58 3.27A30 30 0 0 1 87 96.68a14.1 14.1 0 0 1-5.45 1.2h-.11a14.1 14.1 0 0 1-5.44-1.2 26.6 26.6 0 0 1-4.69-2.39c-1.2-.77-2.72-1.85-4.57-3.27-.55-.38-1.09-.82-1.75-1.25l-25.23 25.21a7.55 7.55 0 0 0 5.17 2ZM43.08 73.92a25.4 25.4 0 0 1-5-4.31v38.29l22.16-22.22q-6.63-4.7-17.16-11.76m76.8 0c-6.76 4.57-12.48 8.49-17.21 11.82l22.16 22.16V69.61a27.4 27.4 0 0 1-4.95 4.31"/><path
                :fill="color"
                d="M118 48.1H45a7.14 7.14 0 0 0-5.89 2.56A10.16 10.16 0 0 0 37 57.14a11.6 11.6 0 0 0 2.73 6.75 22.8 22.8 0 0 0 5.82 5.72c1.15.82 4.53 3.16 10.19 7.08q4.57 3.2 8 5.56l5 3.49c.16.1.38.27.76.54l1.36 1c1.09.76 2 1.42 2.67 1.91s1.58 1 2.56 1.63A18.3 18.3 0 0 0 79 92.16a8.5 8.5 0 0 0 2.51.44h.11a8.7 8.7 0 0 0 2.5-.44 13.4 13.4 0 0 0 2.84-1.36c1-.6 1.85-1.14 2.55-1.63s1.58-1.09 2.67-1.91c.55-.38 1-.71 1.37-1s.59-.44.76-.54c1.09-.77 2.78-1.91 5-3.49l18.19-12.64a23.7 23.7 0 0 0 6-6.1 13.1 13.1 0 0 0 2.5-7.44 7.65 7.65 0 0 0-2.34-5.61A8 8 0 0 0 118 48.1"/>
          </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    backgroundFill: {
      type: String,
      default: '#ef4849'
    },
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style scoped>
  .cls-1 {
    fill: none;
  }

  .cls-2 {
    clip-path: url(#clip-path);
  }

  .cls-4 {
    clip-path: url(#clip-path-2);
  }
</style>
