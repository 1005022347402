<template>
  <div class="scroll-wrapper">
    <div class="scroll-button-wrapper up">
      <plain-button
        v-if="showScrollUp"
        class="scroll-button"
        @click="scroll('up')">
        <svg-chevron-up
          class="scroll-chevron"/>
      </plain-button>
    </div>
    <div
      ref="scroll"
      class="scroll">
      <slot/>
    </div>
    <div class="scroll-button-wrapper down">
      <plain-button
        v-if="showScrollDown"
        class="scroll-button"
        @click="scroll('down')">
        <svg-chevron-down
          class="scroll-chevron"/>
      </plain-button>
    </div>
  </div>
</template>

<script>
import PlainButton from '../global/sequin/PlainButton'
import SvgChevronUp from '@/components/global/svg/SvgChevronUp.vue'
import SvgChevronDown from '@/components/global/svg/SvgChevronDown.vue'

const THUMBNAIL_HEIGHT = 96
const THUMBNAIL_PADDING = 8

export default {
  name: 'ClosetThumbnailScroll',
  components: {
    PlainButton,
    SvgChevronUp,
    SvgChevronDown
  },
  data () {
    return {
      showScrollUp: false,
      showScrollDown: false
    }
  },
  computed: {
    scrollPosition () {
      return this.$refs.scroll && this.$refs.scroll.scrollTop
    }
  },
  mounted () {
    if (this.$refs.scroll) {
      this.$refs.scroll.onscroll = ({ target }) => {
        this.checkScrollButtons(target)
      }
      this.checkScrollButtons(this.$refs.scroll)
    }
  },
  activated () {
    this.checkScrollButtons(this.$refs.scroll)
  },
  methods: {
    scroll (direction) {
      const distance = (THUMBNAIL_HEIGHT + THUMBNAIL_PADDING) * 3
      const scrollY = distance * (direction === 'down' ? 1 : -1)
      this.$refs.scroll.scrollBy(0, scrollY)
    },
    checkScrollButtons (target) {
      const { scrollHeight, scrollTop, clientHeight } = target
      const scrollLimitReached = scrollHeight - scrollTop === clientHeight
      this.showScrollUp = scrollTop > 0
      this.showScrollDown = !scrollLimitReached
    }
  }
}
</script>

<style lang="scss" scoped>
  $thumbnail-height: 96px;
  $thumbnail-padding: 20px;
  $num-above-fold: 4;
  $max-height: ($thumbnail-height + $thumbnail-padding) * $num-above-fold;

  .scroll-wrapper {
    position: relative;
    max-height: $max-height;
  }

  .scroll {
    max-height: $max-height;
    overflow: scroll;
    scroll-behavior: smooth;
    @include hide-scrollbar;
  }

  .scroll-button-wrapper {
    width: 100%;
    position: absolute;
    &.up { top: 0 }
    &.down { bottom: 0 }
  }

  .scroll-button {
    display: flex;
    justify-content: center;
    opacity: 90%;
    width: 100%;
    padding: 0;
    background: $white;
    color: $pewter;
    border: $default-border;
  }

  .scroll-chevron {
    border-radius: 2px;
    stroke-width: 1.5px;
  }
</style>
