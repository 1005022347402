import api from '../api/api'
import { getSetters } from './helpers/shared'
import { captureException } from '@sentry/vue'

export const state = {
  notifications: [],
  notificationsLoading: false,
  notificationsNextPage: null,
  notificationsOpen: false,
  refreshTimerRunning: false,
  lastRefreshCheck: null
}

export const getters = {
  newNotifications: (state) => {
    return state.notifications.filter(x => !x.viewed)
  },
  notNewNotifications: (state) => {
    return state.notifications.filter(x => x.viewed)
  },
  unreadNotificationCount: (state, getters) => {
    return getters.newNotifications.length ? getters.newNotifications.length : 0
  },
  recentNotifications: (state, getters) => {
    function isLessThan60DaysPast (dateToCheck) {
      // Parse the provided date string to a JavaScript Date object
      const date = new Date(dateToCheck)
      // Calculate the difference in milliseconds between the provided date and today's date
      const difference = new Date() - date
      // Convert the difference to days
      const differenceInDays = difference / (1000 * 60 * 60 * 24)
      // Check if the difference is less than 30 days
      return differenceInDays < 60
    }

    // Filter notifications that are not new and were created less than 30 days ago
    return getters.notNewNotifications.filter(notification => {
      return isLessThan60DaysPast(notification.created)
    })
  },
  visibleNotifications: (state, getters) => {
    return [...getters.newNotifications, ...getters.recentNotifications]
  },
  readNotificationCount: (state, getters) => {
    return getters.notNewNotifications.length ? 1 : 0
  }
}

async function getNotifications (state, commit, firstPage = false, forceMore = false) {
  try {
    if (!forceMore && (state.notificationsLoading || state.notificationsOpen)) {
      // don't fetch more notifications if we're already loading them
      // or if the view is open (since we don't want to move things around
      // when the viewer is looking at them)
      return
    }
    commit('SET_NOTIFICATIONS_LOADING', true)
    let res = null
    if (!firstPage && state.notificationsNextPage) {
      res = await api.apiNotifications.getNextPage(state.notificationsNextPage)
      if (res.status !== 204) {
        commit('APPEND_NOTIFICATIONS', res.data.results)
        commit('SET_NOTIFICATIONS_NEXT_PAGE', res.data.next)
      }
    } else {
      res = await api.apiNotifications.getNotifications(state.lastRefreshCheck)
      const now = new Date()
      commit('SET_LAST_REFRESH_CHECK', parseInt(now.getTime() / 1000).toFixed(0))
      if (res.status !== 204) {
        commit('SET_NOTIFICATIONS', res.data.results)
        commit('SET_NOTIFICATIONS_NEXT_PAGE', res.data.next)
      }
    }
  } catch (err) {
    captureException(err)
  } finally {
    commit('SET_NOTIFICATIONS_LOADING', false)
  }
}

export const actions = {
  async getNotifications ({ state, commit }) {
    await getNotifications(state, commit)

    if (!state.refreshTimerRunning) {
      commit('SET_REFRESH_TIMER_RUNNING', true)
      if (process.env.VUE_APP_NOTIFICATIONS_POLLING_PERIOD > 0) {
        setInterval(getNotifications, process.env.VUE_APP_NOTIFICATIONS_POLLING_PERIOD,
          state, commit, true)
      }
    }
  },
  async getMoreNotifications ({ state, commit }) {
    await getNotifications(state, commit, false, true)
  },
  async addNotification (context, data) {
    try {
      await api.apiNotifications.addNotification(data)
    } catch (err) {
      captureException(err)
    }
  },
  async deleteNotification ({ commit }, notification) {
    commit('DELETE_NOTIFICATION', notification)
  },
  async markAllAsViewed ({ state, commit, getters }) {
    try {
      if (getters.newNotifications.length === 0) {
        return
      }
      await api.apiNotifications.markAllAsViewed()
      const readNotifications = state.notifications.map(notification => {
        return {
          ...notification,
          viewed: true
        }
      })
      commit('SET_NOTIFICATIONS', readNotifications)
    } catch (err) {
      captureException(err)
    }
  }
}

export const mutations = {
  ...getSetters(state),
  'DELETE_NOTIFICATION' (state, notification) {
    const index = state.notifications.map(x => x.id).indexOf(notification.id)
    if (index > -1) {
      state.notifications.splice(index, 1)
    }
  },
  'APPEND_NOTIFICATIONS' (state, newNotifications) {
    state.notifications = state.notifications.concat(newNotifications)
  }
}
