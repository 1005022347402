<template>
  <plain-button
    block
    type="router-link"
    :name="name"
    :to="collection.toRoute"
    class="d-flex collection-preview-full-width"
    :class="[direction, { 'mobile': isMobile }]">
    <div
      class="d-flex collection-images"
      :class="{ 'mobile': isMobile }">
      <img
        v-for="image in previewImages"
        :key="image"
        alt="Item Preview from Client's Collection"
        class="preview-image"
        :class="{ 'mobile': isMobile }"
        :src="image">
      <div
        v-for="index in (isMobile ? 4 : 5) - previewImages.length"
        :key="`placeholder-image-${index}`"
        class="preview-image placeholder"/>
    </div>
    <div
      class="collection-title"
      :class="[backgroundVariant, { 'mobile': isMobile }]">
      <component
        :is="isMobile ? 'h6' : 'h4'"
        class="mb-3">
        {{ collection.name }}
      </component>
      <p
        class="mb-0 mb-md-4"
        :class="{'small-text': isMobile, 'text-white': backgroundVariant === 'gradient' }">
        {{ collection.length }}
      </p>
      <p
        v-if="!isMobile"
        class="small-text mb-0"
        :class="{ 'text-white': backgroundVariant === 'gradient' }">
        {{ collection.previewDescription }}
      </p>
    </div>
  </plain-button>
</template>

<script>
import PlainButton from '../global/sequin/PlainButton.vue'

export default {
  components: {
    PlainButton
  },
  props: {
    backgroundVariant: {
      type: String,
      default: 'gradient',
      validator: v => v.match(/(gradient|champagne)/)
    },
    collection: {
      type: Object,
      required: true
    },
    direction: {
      type: String,
      validator: r => r.match('forward|reverse'),
      default: 'forward'
    },
    name: {
      type: String,
      default: 'Link to collection'
    }
  },
  computed: {
    previewImages () {
      return this.collection.styleColors
        ? this.collection.styleColors.slice(0, this.isMobile ? 4 : 5).map(styleColor => styleColor.images.front.url)
        : []
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-preview-full-width {
  max-height: 35vh;

  &.mobile {
    max-height: 28vh;
  }

  border: $default-border;
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  flex-direction: row;

  &.reverse {
    flex-direction: row-reverse;
  }
}

.collection-images {
  width: 75%;
  z-index: 2;
  padding-left: 1%;

  &.mobile {
    padding-left: 0;
  }
}

.collection-title {
  display: flex;
  width: 25%;
  flex-direction: column;
  justify-content: center;
  padding: 40px 32px;
  @include sequin-gradient;
  color: $white;

  &.champagne {
    background: $champagne;
    color: $armor;
  }

  &.mobile {
    min-width: 124px;
    min-height: 22vh;
    padding: 24px 20px;
  }
}

.preview-image {
  height: 100%;
  width: 25%;
  border-radius: 8px;
  margin-left: -3%;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  border-left: $default-border;

  &.mobile {
    width: 35%;
  }

  &.placeholder {
    background-color: lighten($origami, 3%);
    border-left: .5px dashed $ash;
  }
}
</style>
