<template>
  <div>
    <div
      v-if="showLink"
      class="d-flex align-items-center w-100">
      <span
        v-if="prefix"
        class="pe-1 mt-1 text-gray">
        {{ prefix }}
      </span>
      <base-input
        class="input"
        :class="{'highlight': highlight}"
        read-only
        :model-value="displayLink"/>
    </div>
    <sequin-button
      block
      :variant="buttonVariant"
      :class="buttonClassMargin"
      :small="small"
      @click="copyLink">
      <div
        class="d-flex align-items-center justify-content-center"
        :class="{'scale': showConfirmation}">
        <svg-link
          class="me-1"
          height="16"
          width="16"/>
        {{ showConfirmation ? confirmationText : buttonText }}
        <svg-check
          v-if="showConfirmation"
          class="check scale"
          height="16"
          width="16"/>
      </div>
    </sequin-button>
  </div>
</template>

<script>
import BaseInput from '../BaseInput'
import SequinButton from './SequinButton'
import SvgLink from '@/components/global/svg/SvgLink.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  name: 'CopyLink',
  components: {
    BaseInput,
    SequinButton,
    SvgLink,
    SvgCheck
  },
  props: {
    prefix: {
      type: String,
      default: ''
    },
    showLink: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    displayLink: {
      type: String,
      required: true
    },
    fullLink: {
      type: String,
      required: true
    },
    highlight: {
      type: Boolean,
      default: false
    },
    buttonVariant: {
      type: String,
      default: 'primary'
    },
    buttonText: {
      type: String,
      default: 'Copy link'
    },
    confirmationText: {
      type: String,
      default: 'Copied!'
    }
  },
  data () {
    return {
      showConfirmation: false
    }
  },
  computed: {
    buttonClassMargin () {
      const routePath = this.$route.path
      return routePath.includes('profile') ? 'mt-0' : 'mt-2'
    }
  },
  methods: {
    copyLink () {
      navigator.clipboard.writeText(this.fullLink)
      this.showConfirmation = true
      this.$emit('copy-link-click')
      setTimeout(() => {
        this.showConfirmation = false
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>

  .scale {
    @include scale-up-down-animation;
  }

  .input {
    width: 100%;
    margin: 10px 0 !important;
    &.highlight :read-only {
      color: $plum;
      font-weight: $font-weight-semibold;
      background-color: white;
    }
  }

  .check {
    stroke-width: 4px;
    margin-left: 6px;

    transform-origin: 50% 50%;
    stroke-dasharray: 24;
    stroke-dashoffset: 24;
    animation: stroke .2s ease-in .4s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 48;
    }
  }
</style>
