<template>
  <div>
    <div class="px-4 px-md-0 pb-5">
      <text-placeholder
        variant="h6"
        class="mb-3"/>
      <div class="d-flex">
        <preview-image-placeholder
          v-for="index in 3"
          :key="index"
          size="tiny"
          class="me-3"/>
      </div>
    </div>
    <div class="px-4 px-md-0 pb-5">
      <text-placeholder
        variant="h6"
        class="mb-3"/>
      <div
        v-for="index in 3"
        :key="index"
        class="d-flex my-2">
        <text-placeholder
          variant="h5"
          size="small"
          class="me-5"/>
        <text-placeholder
          variant="h5"
          size="large"/>
        <text-placeholder
          variant="h5"
          size="medium"
          class="me-3"/>
        <text-placeholder
          variant="h5"
          size="tiny"/>
      </div>
    </div>
  </div>
</template>

<script>
import TextPlaceholder from '../../global/sequin/TextPlaceholder'
import PreviewImagePlaceholder from '@shared/components/ADORN/PreviewImagePlaceholder.vue'

export default {
  components: {
    TextPlaceholder,
    PreviewImagePlaceholder
  }
}
</script>
