<template>
  <div class="row">
    <div
      v-if="noStyleColors && !hideFilters && !styleColorsLoading"
      class="col-12 text-center">
      <b>No Results Found</b><br>
      <div v-if="selectedFilters.length > 0">
        <p>Try removing filters to see more options</p><br>
        <TextLink
          class="ps-2"
          @click="emit('clear-all-filters')">
          Clear All Filters
        </TextLink>
      </div>
    </div>
    <StyleColorPreview
      v-for="(styleColor, index) in styleColors"
      :key="`${styleColorSet.source || styleColorSet.id}-${styleColor.id}`"
      :class="styleColorPreviewClass"
      :description-props="{ showStocking: true }"
      :style-color-source-index="index"
      :style-color="styleColor"
      :style-color-set="styleColorSet"
      size="auto"/>
    <StyleColorPreviewPlaceholder
      v-for="index in numPlaceholders"
      :key="index"
      :class="styleColorPreviewClass"/>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useWindowScroll, useDebounceFn } from '@vueuse/core'
import StyleColorPreviewPlaceholder from '@shared/components/ADORN/StyleColorPreviewPlaceholder.vue'
import StyleColorPreview from '@shared/components/ADORN/StyleColorPreview.vue'
import TextLink from '@/components/global/sequin/TextLink.vue'
import useStyleColorSet from '@/composables/styleColorSet.js'
import useScreenSize from '@shared/composables/screenSize.js'

const props = defineProps({
  styleColorSet: {
    type: Object,
    required: true
  },
  styleColorsLoading: {
    type: Boolean,
    default: false
  },
  selectedFilters: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(['bottom-of-set', 'clear-all-filters'])

const styleColorPreviewClass = 'col-6 col-md-3 col-lg-2 mb-lg'

const { sourceType, styleColors, length } = useStyleColorSet(() => props.styleColorSet)
const hideFilters = computed(() => styleColors.value?.length === 0 && sourceType.value === 'search')
const noStyleColors = computed(() => styleColors.value?.length === 0)

const { screenSizeValue } = useScreenSize()
const numPlaceholders = computed(() => {
  if (!props.styleColorsLoading) return 0
  const rowSize = screenSizeValue({ mobile: 2, tablet: 4, desktop: 6 }).value
  const remainder = (styleColors.value?.length ?? 0) % rowSize
  return remainder === 0 ? rowSize * 2 : rowSize - remainder + rowSize * 2 // Show at least 2 full rows
})

const loadMoreStyleColors = () => {
  emit('bottom-of-set')
}

const isNearBottom = () => {
  const scrollPosition = window.scrollY + window.innerHeight
  const documentHeight = document.documentElement.scrollHeight
  return documentHeight - scrollPosition <= 100
}
const canLoadMore = computed(() => styleColors.value?.length < length.value)

const checkAndLoadMore = () => {
  if (isNearBottom() && !props.styleColorsLoading && canLoadMore.value) {
    loadMoreStyleColors()
  }
}

const debouncedCheckAndLoadMore = useDebounceFn(checkAndLoadMore, 300)

const { y: scrollY } = useWindowScroll()
watch(scrollY, debouncedCheckAndLoadMore)

defineOptions({
  compatConfig: {
    WATCH_ARRAY: false
  }
})
</script>
