<template>
  <StyleColorSetPage
    :style-color-set="{
      ...browseSet,
      length: browseLength,
      styleColors: browseStyleColorsInfo,
      showAvailabilityToggle: true
    }"
    :style-colors-loading="!browseSectionLoaded || browseSectionLoadingPage"
    @bottom-of-set="debounceNextPage"
    @on-available-now-toggle="toggleAvailability()"
    @clear-all-filters="clearFilters"
    @remove-filter="removeFilter"
    @on-share-style-color-set-page="openShareModal(shareContext)"/>
</template>

<script>
import browseMixin from './browseMixin.vue'
import StyleColorSetPage from '@/components/styleColorSet/StyleColorSetPage.vue'

import { mapActions as mapVuexActions, mapState } from 'vuex'
import { track } from '../../../plugins/analytics'
import { useModals } from '@/composables/modals.js'
import { useQueryStringFilters } from '@/composables/queryStringFilters.js'

export default {
  name: 'BrowsePage',
  components: {
    StyleColorSetPage
  },
  mixins: [browseMixin],
  setup () {
    const { openShareModal } = useModals()
    const { queryStringFilters, queryStringSort } = useQueryStringFilters()

    return {
      queryStringFilters,
      queryStringSort,
      openShareModal
    }
  },
  computed: {
    ...mapState('closet', [
      'browseStyleColors',
      'browseSectionLoaded',
      'browseSectionLoadingPage',
      'baseFilters',
      'browseSectionDetails'
    ]),
    browseStyleColorsInfo () {
      return this.browseStyleColors.data?.results
    },
    browseLength () {
      return this.browseStyleColors.data?.count
    },
    shareContext () {
      return { ...this.browseSet, styleColors: this.browseStyleColorsInfo }
    }
  },
  created () {
    track('Viewed Browse Page')
    // Global filters may not be loaded in the non-signed in case
    if (!this.globalFilters?.options) {
      this.getGlobalFilterOptions()
    }
  },
  async mounted () {
    const params = {
      section: this.$route.params.section,
      subsection: this.browseSubsectionParam,
      available: true,
      filters: this.queryStringFilters,
      sort: this.queryStringSort ?? this.sort
    }
    if (!this.browseSet?.styleColors) {
      // BaseFlyout data is not very reactive, this is to keep the flyout from being laggy & have loading UI state present
      await this.getBrowseSectionFilterOptions({ ...params, filters: this.baseFilters })
      this.getStyleColorsByBrowseSection(params)
    }
  },
  methods: {
    ...mapVuexActions('closet', [
      'getBrowseSectionsNextPage'
    ]),
    removeFilter ({ filter }) {
      const filters = JSON.parse(JSON.stringify(this.browseSet.filters))
      filters[filter.filterType] = filters[filter.filterType].filter(value => value !== filter.value)
      this.applyFilters({ filters })
    },
    debounceNextPage () {
      clearTimeout(this.debounceNextBrowsePageTimeout)
      this.debounceNextBrowsePageTimeout = setTimeout(() => {
        this.getBrowseSectionsNextPage()
      }, 300)
    }
  }
}
</script>
