<template>
  <button
    v-if="show"
    class="back-button d-flex align-items-center justify-content-center p-0"
    @click="goBack">
    <SvgChevronLeft
      width="32"
      height="32"
      stroke-width="1"/>
  </button>
</template>

<script setup>
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import useLink from '@shared/composables/link.js'
import { useClientStore } from '@shared/stores/client.js'
import { useNavBarStore } from '@shared/stores/navBar.js'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  allowFallback: {
    type: Boolean,
    default: false
  }
})

const { canGoBack } = storeToRefs(useNavBarStore())
const { isAuthenticated } = storeToRefs(useClientStore())
const show = computed(() => canGoBack.value || props.allowFallback)

const router = useRouter()
const { activate: goHome } = useLink(() => isAuthenticated.value ? { name: 'home' } : '/')

function goBack () {
  if (canGoBack.value) {
    router.back()
  } else if (props.allowFallback) {
    goHome()
  }
}
</script>

<style lang="scss" scoped>
.back-button {
  border: none;
  background-color: transparent;
  color: inherit;
}
</style>
