<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { toHourMinuteString } from '@/utils/stringParsing.js'

export default {
  data () {
    return {
      timePassed: 0,
      timerInterval: null
    }
  },
  computed: {
    ...mapState('closet', [
      'selectedItemsMismatch',
      'selectedItemTypes',
      'unavailableItemTypes',
      'styleColorsMap',
      'deliveredItems',
      'soldPackingItems',
      'soldTransitItems',
      'soldFutureItems',
      'mustReviewItems',
      'awaitingReviewItems',
      'packingItems',
      'pendingItems',
      'toReturnItems',
      'transitItems',
      'sectionsLoading',
      'state',
      'itemTypePricing'

    ]),
    ...mapState('client', [
      'caseSize',
      'closetItemNumber',
      'readyForStyling',
      'membershipDetails',
      'membershipStatus',
      'status',
      'username',
      'tooManyItemsOut',
      'validatedShippingAddress',
      'pickupAddress',
      'shippingAddress',
      'shippingEligibility',
      'state',
      'selectedPackages',
      'transitPackages',
      'returnLabelEligible',
      'firstCaseDueDate'
    ]),
    ...mapState('case', [
      'caseLoading',
      'itemToPurchase',
      'itemsHeldForPurchase'
    ]),
    ...mapGetters('closet', [
      'selectedAvailableItemTypes',
      'deliveredItemsByReturnDate',
      'outstandingItems'
    ]),
    ...mapGetters('case', [
      'extraItemBasePrice'
    ]),
    ...mapGetters('client', [
      'active',
      'bonusItems',
      'canPickItems',
      'canSelectItems',
      'casePurchaseItems',
      'hasPlanTierPermission',
      'hasRentalPlan',
      'nextShipmentDate',
      'numAllSlots',
      'numAllSlotsAvailable',
      'numAllSlotsFilled',
      'numSelectedItems',
      'numBonusItems',
      'numCaseSlots',
      'numStandardSlotsFilled',
      'numStandardSlotsAvailable',
      'numBonusItemSlotsFilled',
      'numBonusItemSlotsAvailable',
      'numExtraSlotsValid',
      'numExtraSlotsOverLimit',
      'numRolloverItems',
      'numRolloverSlotsAvailable',
      'numPotentialNewRolloverSlots',
      'selectedStandardItems',
      'selectedBonusItems',
      'selectedExtraItemsValid',
      'selectedExtraItemsOverLimit',
      'tooFewSelected',
      'pickupPackages',
      'shippedPackages',
      'itemsInTransit',
      'canSwapItems',
      'maxNumExtraItems',
      'canRequestStyling',
      'rolloverItems'
    ]),
    ...mapGetters('review', [
      'isReviewOOC',
      'numItemsToReview',
      'minItemsToReview'
    ]),
    primaryCaseState () {
      // Determines the most import or relevant user action within my case
      return this.primaryCaseStateSubscriptionPlan
    },
    primaryCaseStateSubscriptionPlan () {
      if (!this.active) return 'inactive-member'

      if (!this.hasRentalPlan) {
        return 'default'
      }

      if (this.caseLoading) return 'default'
      if (this.tooManyItemsOut) return 'awaiting-returns'
      if (this.canSelectItems) return 'selecting'

      if (this.itemsInTransit) {
        if (this.pendingItems.length > 0) return 'pending-stylist-case'
        if (this.packingItems.length > 0) return 'packing'
        if (this.pickupPackages.length > 0) return 'ready-for-pickup'
        if (this.shippedPackages.length > 0) return 'shipping'
      }

      if (this.deliveredItems.length > 0) return 'delivered'
      if (this.membershipDetails.allowedShipments === 0) return 'awaiting-swap'

      return 'default'
    },
    selectedCheckoutRecTypes () {
      const selectedIds = this.selectedExtraItemsValid.map(x => x.id)
      return this.styleColor.itemTypes.filter(it => selectedIds.includes(it.id))
    }
  },
  methods: {
    ...mapActions('client', [
      'getValidatedShippingAddress',
      'getItemTypePrice'
    ]),
    ...mapActions('closet', [
      'checkForSelectedItemsMismatch'
    ]),
    heldForPurchase (item) {
      return this.itemsHeldForPurchase.filter(
        x => x.id === item.id).length > 0
    },
    getDeliveryDay (deliveryBy) {
      let deliveryDate = Date.parse(deliveryBy)
      if (isNaN(deliveryDate)) { return '(unknown)' }
      deliveryDate = new Date(deliveryDate)

      const today = new Date()
      if (deliveryDate.getDay() === today.getDate()) {
        return 'today'
      }

      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      if (tomorrow.getDay() === deliveryDate.getDay()) {
        return 'tomorrow'
      }
      return deliveryDate.toLocaleString('en-us', { weekday: 'long' })
    },
    deliveryTime (capitalize = true, option) {
      let day = this.getDeliveryDay(option.deliveryBy)
      let time = toHourMinuteString(option.deliveryBy)

      if (option.expeditedCutoff !== null) {
        const missedCutoff = Date.now() > Date.parse(option.expeditedCutoff)
        if (missedCutoff) {
          day = this.getDeliveryDay(option.missedCutoffDeliveryBy)
          time = toHourMinuteString(option.missedCutoffDeliveryBy)
        }
      }
      const body = `${day} before ${time}`
      if (capitalize) {
        return body[0].toUpperCase() + body.substring(1)
      }
      return body
    }
  }
}
</script>
