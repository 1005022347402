<template>
  <div class="mobile-cluster pb-3">
    <div class="d-flex justify-content-between gap-3">
      <style-cluster-image
        v-if="largePlacement === 0"
        :cluster="cluster"
        :image-shown-index="imagesShown.large"
        size="large"/>
      <div class="small-images">
        <style-cluster-image
          v-if="patternPlacement === 0"
          :cluster="cluster"
          :image-shown-index="imagesShown.pattern"
          size="pattern"/>
        <style-cluster-image
          :cluster="cluster"
          :image-shown-index="imagesShown.small"
          size="small"/>
        <style-cluster-image
          v-if="patternPlacement === 1"
          :cluster="cluster"
          :image-shown-index="imagesShown.pattern"
          size="pattern"/>
      </div>
      <style-cluster-image
        v-if="largePlacement === 1"
        :cluster="cluster"
        :image-shown-index="imagesShown.large"
        size="large"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StyleClusterImage from './StyleClusterImage'

export default {
  components: { StyleClusterImage },
  props: {
    cluster: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      largePlacement: Math.round(Math.random()),
      patternPlacement: Math.round(Math.random()),
      medPlacement: Math.round(Math.random()),
      imagesShownIndex: 0,
      imagesShown: {
        large: 1,
        med_2: 1,
        pattern: 1,
        med_1: 1,
        small: 1
      }
    }
  },
  computed: {
    ...mapState('styleQuizNav', [
      'baseImageUrl'
    ]),
    baseClusterImageUrl () {
      return `${this.baseImageUrl}clusters/${this.cluster}/`
    }
  },
  mounted () {
    setTimeout(this.updateImagesShown, 2000)
  },
  methods: {
    updateImagesShown () {
      const key = Object.keys(this.imagesShown)[this.imagesShownIndex]
      this.imagesShown[key] = this.imagesShown[key] === 1 ? 2 : 1
      this.imagesShownIndex = (this.imagesShownIndex + 1) % 5
      setTimeout(this.updateImagesShown, 3000)
    },
    getImageUrl (imageType) {
      return `${this.baseClusterImageUrl}${imageType}.jpg`
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-cluster {
  width: 100%;
  max-width: 47vh;
  margin: 0 auto;
}

.small-images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
