<template>
  <div class="px-md-4 py-md-4 py-4">
    <div class="d-flex justify-content-between mb-2">
      <div class="d-flex align-items-center">
        <subscribe-step-indicator
          class="me-2"
          :step="2"
          :variant="stepVariant"/>
        <h4 class="mb-0">
          Payment Method
        </h4>
      </div>
      <text-link
        v-if="!expanded"
        :disabled="formDisabled"
        @click="$emit('edit-click')">
        edit
      </text-link>
    </div>
    <subscribe-payment-expanded
      v-if="expanded"
      v-bind="listeners"
      :can-show-payment-request="canShowPaymentRequest"
      :stripe="stripe"
      :card="card"/>
    <div v-else>
      <div
        v-if="token"
        class="text-start d-flex align-items-center">
        <p class="small-text mb-0">
          <img
            class="me-2"
            :src="`${baseUrl}${token.card.brand.replace(/\s/g,'')}.png`"
            height="30">
          ending in {{ token.card.last4 }}
          <br>
        </p>
      </div>
      <div v-else-if="canShowPaymentRequest">
        <p class="small-text mb-1">
          {{ applePay ? 'Apple pay' : 'Express payment method' }} detected
        </p>
        <p
          v-if="!applePay"
          class="fine-print mb-0">
          <i>You'll be able to review your browser saved card, Google Pay or Microsoft Pay information when you click confirm.</i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SubscribePaymentExpanded from './SubscribePaymentExpanded'
import SubscribeStepIndicator from './SubscribeStepIndicator'
import TextLink from '../global/sequin/TextLink'
import { getListeners } from '../global/helpers/vue'

export default {
  compatConfig: {
    INSTANCE_LISTENERS: false // $listeners are now apart of $attrs in Vue 3
  },
  components: {
    SubscribePaymentExpanded,
    SubscribeStepIndicator,
    TextLink
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    stripe: {
      type: Object,
      default: () => null
    },
    card: {
      type: Object,
      default: () => null
    },
    token: {
      type: Object,
      default: () => null
    },
    canShowPaymentRequest: {
      type: Boolean,
      default: false
    },
    applePay: {
      type: Boolean,
      default: false
    },
    formDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_WEBSITE_IMAGES_URL
    }
  },
  computed: {
    stepVariant () {
      return this.expanded ? 'in-progress' : (this.canShowPaymentRequest || this.token) ? 'complete' : 'to-do'
    },
    listeners () {
      return getListeners(this.$attrs)
    }
  }
}
</script>

<style lang="scss" scoped>
  .disabled {
    pointer-events: none;
    opacity: .5;
  }
</style>
