import anchorme from 'anchorme'

export default {
  methods: {
    parseLinks (input) {
      return anchorme({
        input,
        options: {
          attributes: {
            target: '_blank'
          }
        }
      })
    },
    linkHashTags (input) {
      return input.replace(/#(\w+)/g, '<a href="" class="hashtaglink hashtag-$1">#$1</a>')
    }
  }
}
