<template>
  <div>
    <div
      v-if="isMobile"
      class="pt-4 mb-3">
      <action-header page-header>
        <h5>Account</h5>
      </action-header>
    </div>
    <div
      v-else
      class="container pt-4 px-0">
      <bread-crumb
        class="mb-3"
        :current-location="breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].name : ''"
        :crumbs="[{ name: 'Account', to: { name: 'account' } }].concat(breadcrumbs.slice(0, breadcrumbs.length - 1))"/>
    </div>
    <div class="container">
      <router-view #default="{ Component }">
        <transition
          name="fade"
          mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import ActionHeader from '../../global/sequin/ActionHeader'
import BreadCrumb from '../../global/sequin/BreadCrumb'
import { mapState } from 'vuex'

export default {
  name: 'AccountMembership',
  components: { ActionHeader, BreadCrumb },
  computed: {
    ...mapState('account', [
      'breadcrumbs'
    ])
  }
}
</script>
