<template>
  <BaseTextArea
    v-model="qualityFeedbackText"
    :placeholder="placeholder"
    :validations="validations.qualityFeedbackText"
    class="h5-label"/>
</template>

<script setup>
import { ref, toRefs, watchEffect } from 'vue'
import BaseTextArea from '@/components/global/BaseTextArea.vue'

const props = defineProps({
  review: {
    type: Object,
    required: true
  },
  placeholder: {
    type: String,
    required: true
  },
  validations: {
    type: Object,
    required: true
  }
})

const { review, validations } = toRefs(props)

const qualityFeedbackText = ref(review.qualityFeedbackText)

watchEffect(() => {
  review.value.qualityFeedbackText = qualityFeedbackText
})

const emit = defineEmits(['update:review'])

watchEffect(() => {
  emit('update:review',
    {
      ...review.value,
      qualityFeedbackText: qualityFeedbackText.value
    })
})

</script>
