<template>
  <SignUpScreen @next="personalizeNow">
    <template #default>
      <SignUpOnboardingSlide
        v-for="(slide, index) in slides"
        :key="slide.text"
        :is-active="isActive(slide)"
        :slide="{...slide, slideNum: index }"
        @personalize-now="personalizeNow"
        @personalize-later="personalizeLater"/>
    </template>
    <template #additional-footer-content>
      <TrackingButton
        v-if="signUpPhase2"
        text="Subscribe now, personalize later"
        variant="text-link"
        app-entry-point="signup"
        event-label="Personalize Later footer"
        :to="nextScreenRouterLink"
        @click="personalizeLater"/>
    </template>
  </SignUpScreen>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import SignUpOnboardingSlide from '@/components/signUp/SignUpOnboardingSlide.vue'
import TrackingButton from '@shared/components/ADORN/TrackingButton.vue'
import { useCycleList } from '@vueuse/core'
import { useSignUpExperiments } from '@shared/composables/experiment.js'
import { SignUpMode, useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { storeToRefs } from 'pinia'

defineOptions({
  compatConfig: {
    // Suppress warning from OnboardingScreen
    WATCH_ARRAY: false
  }
})

const { signUpPhase1, signUpPhase2 } = useSignUpExperiments()

const { mode, nextScreenRouterLink } = storeToRefs(useSignUpNavigationStore())
const signUpNav = useSignUpNavigationStore()

function personalizeNow () {
  if (signUpPhase1.value) {
    signUpNav.next()
  } else {
    mode.value = SignUpMode.Default
  }
}
function personalizeLater () {
  mode.value = SignUpMode.PersonalizeLater
}

const heroCopy = `
  <h1>Clothing Rental, <br> Personalized</h1>
`
const slide1Copy = `
  <h2>Skip the scrolling. </h2>
  <h3>We’ll curate your closet from 15,000 styles ...</h3>
`
const slide2Copy = `
  <h3>... and give you access to a</h3>
  <h2>team of stylists</h2>
`
const slide3Copy = `
  <h2>Shipping is free & fast.<br> Plus, no laundry -- cleaning is on us.</h2>
`
const slide4Copy = `
  <h4>"Best for Personalized Options"</h4>
  <h5>- Elle</h5>
  <h4>"Best for Fast Shipping"<h4>
  <h5>- New York Post</h5>
`
const imgSrc = process.env.VUE_APP_WEBSITE_IMAGES_URL

function getImgSrc (slide, img) {
  return `${imgSrc}sign-up/onboarding/${slide}/${img}.jpg`
}

const slides = computed(() =>
  [
    {
      copyHTML: heroCopy,
      logo: true
    },
    {
      look: getImgSrc('1', 'look'),
      styles: [
        getImgSrc('1', 'style1'),
        getImgSrc('1', 'style2')
      ],
      copyHTML: slide1Copy
    },
    {
      look: getImgSrc('2', 'look'),
      styles: [
        getImgSrc('2', 'style1')
      ],
      copyHTML: slide2Copy
    },
    {
      look: getImgSrc('3', 'look'),
      styles: [
        getImgSrc('3', 'style1'),
        getImgSrc('3', 'style2')
      ],
      copyHTML: slide3Copy
    },
    {
      look: getImgSrc('4', 'look'),
      styles: [
        getImgSrc('4', 'style1'),
        getImgSrc('4', 'style2')
      ],
      copyHTML: slide4Copy
    }
  ])

const { state, next } = useCycleList(slides)
const intervalId = ref(null)

function isActive (slide) { return slide === state.value }

function startSlideShow () {
  if (intervalId.value) {
    clearInterval(intervalId.value)
  }

  intervalId.value = setTimeout(() => {
    next()
    startMainInterval()
  }, 2000)
}

function startMainInterval () {
  intervalId.value = setInterval(() => {
    const currentIndex = slides.value.findIndex(slide => slide === state.value)

    if (currentIndex === slides.value.length - 1) {
      clearInterval(intervalId.value)
    } else {
      next()
    }
  }, 3400)
}

onMounted(() => {
  startSlideShow()
})

onUnmounted(() => {
  clearInterval(intervalId.value)
})

</script>

<style scoped lang="scss">
:deep(.sign-up-screen-content) {
  overflow: hidden;
  position: relative;
}
</style>
