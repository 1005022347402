<template>
  <div class="w-100">
    <div class="border">
      <div class="card-header small-text">
        Credit or debit card
        <a
          href="https://stripe.com/"
          target="_blank">
          <svg-stripe
            class="float-end my-1 me-1"/>
        </a>
      </div>
      <div
        :class="{ 'card-body': !isMobile, 'disabled': formDisabled}">
        <div
          :id="cardElementId"
          class="sentry-mask"/>
        <div
          id="card-errors"
          class="small-text mt-2 stripe-error"
          role="alert"/>
        <div
          v-if="stripeErrorMessage"
          class="mt-2">
          <p class="small-text mt-3 mb-0 stripe-error">
            {{ stripeErrorMessage }}
            <br><span
              class="small-text stripe-error"
              v-html="stripeErrorFollowup"/>
          </p>
        </div>
      </div>
    </div>
    <sequin-button
      id="btn-stripe"
      class="mt-3"
      block
      :disabled="formDisabled"
      @click="updateCard">
      Update Payment Method
    </sequin-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { StripeMixin } from './StripeMixin'
import SequinButton from '../sequin/SequinButton'
import SvgStripe from '@/components/global/svg/SvgStripe.vue'

export default {
  components: {
    SequinButton,
    SvgStripe
  },
  mixins: [StripeMixin],
  props: {
    cardElementId: {
      type: String,
      default: 'card-element'
    }
  },
  data () {
    return {
      stripeErrorMessage: null,
      stripeErrorFollowup: null,
      formDisabled: false
    }
  },
  mounted () {
    this.initializeStripe(this.cardElementId)
    this.$store.commit('client/SET_IS_UPDATING_PAYMENT', true)
  },
  beforeUnmount () {
    this.$store.commit('client/SET_IS_UPDATING_PAYMENT', false)
  },
  methods: {
    ...mapActions('client', [
      'postDefaultPayment'
    ]),
    async updateCard () {
      this.formDisabled = true
      this.stripeErrorMessage = null
      this.stripeErrorFollowup = null
      const res = await this.stripe.createToken(this.card)
      if (res.error) {
        // Inform the user if there was an error.
        const errorElement = document.getElementById('card-errors')
        errorElement.textContent = res.error.message
      } else {
        // Send the token to your server.
        try {
          await this.postDefaultPayment(res.token)
          this.$emit('default-payment-updated')
        } catch (errors) {
          this.stripeErrorMessage = errors[0]
        }
      }
      this.formDisabled = false
    }
  }
}

</script>

<style lang="scss" scoped>

.stripe-error {
  color: $danger-light;
  text-align: left;
}

.card-header {
  background: $white;
  border-bottom: $default-border;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>
