<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4 4.66016H1.6C0.716344 4.66016 0 5.47951 0 6.49024V15.6407C0 16.6514 0.716344 17.4707 1.6 17.4707H14.4C15.2837 17.4707 16 16.6514 16 15.6407V6.49024C16 5.47951 15.2837 4.66016 14.4 4.66016Z"
      fill="currentColor"/>
    <path
      d="M11.1998 4.66017V2.83008C11.1998 2.34471 11.0312 1.87923 10.7312 1.53602C10.4311 1.19281 10.0242 1 9.59981 1H6.39981C5.97546 1 5.56849 1.19281 5.26843 1.53602C4.96838 1.87923 4.7998 2.34471 4.7998 2.83008V4.66017"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>
