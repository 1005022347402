<!-- Tracking button used to start the style quiz -->
<template>
  <TrackingButton
    :app-entry-point="appEntryPoint"
    :event-label="`${toTitleCase(location)} Get Started`"
    :text="text"
    :to="startUrl"/>
</template>

<script setup>
import TrackingButton from '@shared/components/ADORN/TrackingButton.vue'
import { toTitleCase } from '@/utils/stringParsing.js'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { storeToRefs } from 'pinia'

defineProps({
  appEntryPoint: {
    type: String,
    default: 'homepage'
  },
  location: {
    type: String,
    required: true
  },
  text: {
    type: String,
    default: 'Get Started'
  }
})

const { startUrl } = storeToRefs(useSignUpNavigationStore())
</script>
