import api from '../api/api'

import { getSetters, parseError } from './helpers/shared'

export const state = {
  reviewItems: [],
  reviews: {},
  reviewForEditPhotos: null,
  sizingRatingOptions: [
    { text: 'Small', value: -2 },
    { text: 'Somewhat small', value: -1 },
    { text: 'True to size', value: 0 },
    { text: 'Somewhat large', value: 1 },
    { text: 'Large', value: 2 }
  ],
  itemsToSwap: [],
  clientReviewsSummary: null,
  canSkipReviews: false,
  deliveredReviewedItems: [],
  fiveStarReviewCount: 0,
  currentCompletedReviews: [],
  showReferralLinkMsg: false,
  adminEditReviewLink: null
}

export const getters = {
  numItemsToReview: (state, getters, rootState) => {
    return rootState.closet.awaitingReviewItems.length + rootState.closet.deliveredItems.filter(item => !item.reviewed).length
  },
  isReviewOOC: (state, getters, rootState) => {
    return getters.numItemsToReview > rootState.closet.maxUnreviewedItems
  },
  minItemsToReview: (state, getters, rootState) => {
    return getters.numItemsToReview - rootState.closet.maxUnreviewedItems
  },
  itemsAvailableToReview: (state, getters, rootState) => {
    const items = rootState.closet.deliveredItems
      .concat(rootState.closet.toReturnItems)
      .filter(item => !item.reviewed)
      .concat(rootState.closet.awaitingReviewItems)
    const uniqueIds = new Set(items.map(item => item.id))
    return Array.from(uniqueIds).map(id => items.find(item => item.id === id))
  }
}

export const actions = {
  async submitReviews ({ commit, dispatch, state, rootState }, { isLastReview, currentReviewItemIndex }) {
    try {
      // Set relevant state to the last review
      if (isLastReview) commit('GET_TOTAL_FIVE_STAR_REVIEWS')
      if (state.fiveStarReviewCount >= 2) commit('SET_SHOW_REFERRAL_LINK_MSG', true)

      const deliveredItemIds = rootState.closet.deliveredItems.map(item => item.id)
      commit('SET_DELIVERED_REVIEWED_ITEMS', state.reviewItems.filter(reviewItem => deliveredItemIds.includes(reviewItem.id)))

      const reviewItem = state.reviewItems[currentReviewItemIndex]
      const review = state.reviews[reviewItem.item.id]
      const data = {
        reviews: [{
          ...review,
          // only include selected tags if num wears > 0, and then only include their ID
          occasionTags: review.numWears === '0' ? [] : review.occasionTags.filter(e => e.selected).map(e => ({ id: e.id }))
        }]
      }

      const res = await api.apiReview.submitReviews(data)

      // Get client reviews summary
      await dispatch('getClientReviewsSummary')

      // Successful submission will return new client state
      commit(
        'client/UPDATE_CLIENT',
        res.data,
        { root: true })

      // We also need to get the changes to package items
      await dispatch('closet/getPackageItems', null, { root: true })

      if (isLastReview) commit('RESET_FIVE_STAR_REVIEWS')
    } catch (err) {
      throw parseError(err)
    }
  },
  async swapItems ({ commit, dispatch }) {
    try {
      const res = await api.apiReview.swapItems(state.itemsToSwap.map(item => item.id))
      if (res.data) {
        commit(
          'client/UPDATE_CLIENT',
          res.data,
          { root: true })
        await dispatch('closet/getPackageItems', null, { root: true })
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  async updateReviewPhotos ({ state }, images) {
    try {
      await api.apiReview.updateReviewPhotos(state.reviewForEditPhotos.id, images)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getClientReviewsSummary ({ commit }) {
    try {
      const res = await api.apiReview.getClientReviewsSummary()
      commit('SET_CLIENT_REVIEWS_SUMMARY', res.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  updateOccasionTags ({ commit }, data) {
    commit('ADD_OR_REMOVE_OCCASION_TAGS', data)
  },
  submitReviewSuccess () {
    // Do nothing. This is used as an action trigger for listeners to subscribe to.
  }
}

export const mutations = {
  ...getSetters(state),
  'CLEAR_REVIEWS' (state) {
    state.reviews = {}
  },
  'SET_REVIEW' (state, review) {
    state.reviews[review.itemId] = { ...review }
  },
  'ADD_OR_REMOVE_OCCASION_TAGS' (state, data) {
    const { tagId, itemId } = data
    const review = state.reviews[itemId]
    if (review) {
      const tag = review.occasionTags.find(e => e.id === tagId)
      tag.selected = !tag.selected
    }
  },
  'COUNT_FIVE_STAR_REVIEWS' (state, review) {
    const foundReviewIndex = state.currentCompletedReviews.findIndex(rev => rev.itemId === review.itemId)
    if (foundReviewIndex === -1) {
      state.currentCompletedReviews.push(review)
    } else {
      state.currentCompletedReviews.splice(foundReviewIndex, 1, review)
    }
  },
  'RESET_FIVE_STAR_REVIEWS' (state) {
    state.fiveStarReviewCount = 0
    state.currentCompletedReviews = []
  },
  'GET_TOTAL_FIVE_STAR_REVIEWS' (state) {
    const arrOfReviews = Object.entries(state.currentCompletedReviews).map((e) => e[1])
    for (const rev of arrOfReviews) {
      if (rev.overallStarRating === 5) state.fiveStarReviewCount++
    }
  }
}
