<template>
  <div class="background d-flex flex-column justify-content-center align-items-center">
    <h2 class="header text-white">
      Referral offer applied!
    </h2>
    <div class="check-box">
      <svg-check
        class="check"/>
    </div>
    <p class="subtitle">
      Your promo code will be automatically applied at checkout
    </p>
  </div>
</template>

<script>
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    SvgCheck
  },
  mounted () {
    setTimeout(() => {
      this.$emit('confirmation-complete')
    }, 4500)
  }
}
</script>

<style lang="scss" scoped>
@import './StyleQuizStyleDefinitions.scss';

.background {
  position: fixed;
  z-index: $zindex-fixed + 1;
  top: -1;
  left: -1;
  right: -1;
  bottom: -1;
  color: $white;
  background: $gradient-background;
}

.subtitle {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  max-width: 340px;
  text-align: center;
  opacity: 0;
  animation: fade-in .25s ease-out 1.5s normal forwards;
}

.header {
  opacity: 0;
  animation: fade-in .25s ease-out .25s normal forwards;
}

.check-box {
  @include scale-up-down-animation;
  width: 60px;
  height: 60px;
  margin: 24px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: scale-up .4s ease-in .25s reverse forwards,
      scale-up-down .4s forwards .5s;
}

.check {
  color: $primary;
  stroke-width: 3px;
  width: 44px;
  height: 44px;
  stroke-dasharray: 44;
  stroke-dashoffset: 44;
  animation: stroke .5s ease-in .25s forwards;

  @keyframes stroke {
    100% {
      stroke-dashoffset: 88;
    }
  }

}
</style>
