import { mapGetters, mapState } from 'vuex'

export const ItemTypeStatusMixin = {
  computed: {
    ...mapState('closet', [
      'deliveredItems',
      'packingItems',
      'transitItems',
      'soldPackingItems',
      'soldTransitItems',
      'soldFutureItems',
      'sale'
    ]),
    ...mapGetters('closet', [
      'selectedAvailableItemTypes'
    ]),
    ...mapGetters('client', [
      'shippedPackages',
      'itemsInTransit',
      'hasRentalPlan'
    ]),
    ...mapState('client', [
      'isStaff',
      'membershipStatus'
    ]),
    selectedAvailableItemTypeIds () {
      return this.selectedAvailableItemTypes.map(itemType => itemType.id)
    },
    packingItemTypeIds () {
      return this.packingItems.map(packingItem => packingItem.item.itemType)
    },
    transitItemTypeIds () {
      return this.transitItems.map(transitItem => transitItem.item.itemType)
    },
    deliveredItemTypeIds () {
      return this.deliveredItems.map(deliveredItem => deliveredItem.item.itemType)
    },
    soldItemTypeIds () {
      return [
        ...this.soldPackingItems,
        ...this.soldTransitItems,
        ...this.soldFutureItems
      ].map(soldItem => soldItem.itemType)
    },
    itemCanBePurchased () {
      return this.selectedItemType && this.itemTypeAvailable(this.selectedItemType)
    },
    itemInCase () {
      return this.selectedItemType && this.clientItemTypeStatus === 'selected'
    },
    itemCanBuyNow () {
      return this.membershipStatus === 'active' && this.itemCanBePurchased && !this.itemInCase && this.styleColor.canPurchase
    }
  },
  methods: {
    itemTypeAvailable (itemType) {
      return this.getClientItemTypeAvailability(itemType) === 'available'
    },
    getTransitItemShippedPackage (itemType) {
      const transitItemIndex = this.transitItemTypeIds.indexOf(itemType.id)

      if (transitItemIndex > -1) {
        const packageId = this.transitItems[transitItemIndex].package.id
        const packageIndex = this.shippedPackages.map(shippedPackage => shippedPackage.id).indexOf(packageId)
        if (packageIndex > -1) return this.shippedPackages[packageIndex]
      }

      return null
    },
    getItemToPurchase (itemType) {
      const index = this.deliveredItemTypeIds.indexOf(itemType.id)
      if (index > -1) return this.deliveredItems[index]
      return null
    },
    getClientItemTypeStatus (itemType) {
      if (!itemType) return 'default'

      const itemTypeId = itemType.id

      if (this.soldItemTypeIds.indexOf(itemTypeId) > -1) return 'sold'
      if (this.deliveredItemTypeIds.indexOf(itemTypeId) > -1) return 'with-me'
      if (this.transitItemTypeIds.indexOf(itemTypeId) > -1 || this.packingItemTypeIds.indexOf(itemTypeId) > -1) return 'in-transit'
      if (this.selectedAvailableItemTypeIds.indexOf(itemTypeId) > -1) return 'selected'

      return 'default'
    },
    getClientItemTypeAvailability (itemType) {
      if (!itemType) return 'unavailable'
      if (itemType.numAvailable > 0) return 'available'
      return 'unavailable'
    }
  }
}
