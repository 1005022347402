
export default {
  methods: {
    buildDescription (settings, keys) {
      let description = ''
      keys.forEach(key => {
        if (description.length !== 0) {
          description += ', '
        }
        if (settings.notifications[key] && key.endsWith('Push')) {
          description += 'Push'
        }
        if (settings.notifications[key] && key.endsWith('Email')) {
          description += 'Email'
        }
      })
      return description
    }
  }
}
