<template>
  <stacked-menu
    :menu-items="availableActions">
    <template #menuItemSlot="{ menuItemName }">
      <sequin-tag
        v-if="menuItemName === 'Prepay + Save'"
        variant="tertiary"
        class="popular-tag">
        Special offer
      </sequin-tag>
    </template>
  </stacked-menu>
</template>

<script>
import { mapState } from 'vuex'
import MembershipMixin from './MembershipMixin'
import SequinTag from '../../global/sequin/SequinTag'
import StackedMenu from '../../global/sequin/StackedMenu.vue'

export default {
  components: {
    StackedMenu,
    SequinTag
  },
  mixins: [MembershipMixin],
  computed: {
    ...mapState('client', [
      'membershipStatus',
      'membershipDetails',
      'plans'
    ]),
    changePlanText () {
      return this.clientIsMonthly ? 'Change Plan' : 'Switch to Monthly Plan'
    },
    availableActions () {
      const actions = [
        {
          to: 'change-plan',
          name: this.changePlanText,
          available: this.membershipStatus === 'active' &&
            !this.clientIsTrialing
        },
        {
          to: 'prepay',
          name: this.clientIsMonthly ? 'Prepay + Save' : 'Adjust Prepay Plan',
          available: (this.clientIsMonthly || this.plans.currentBasePlan.chargeInterval < 12) && this.membershipStatus === 'active' && !this.clientIsTrialing
        },
        {
          to: 'pause',
          name: 'Pause Membership',
          available: this.displayPauseAction
        },
        {
          to: 'cancel',
          name: 'Cancel Membership',
          available: this.cancelEligible
        },
        {
          to: 'resume',
          name: 'Resume Membership',
          available: this.membershipStatus === 'paused' || this.membershipStatus === 'items_out_pending_cancel'
        },
        {
          to: 'select-plan',
          name: 'Rejoin',
          available: this.membershipStatus === 'former_member'
        }
      ]
      return actions.filter(action => action.available)
    }
  }
}
</script>

<style lang="scss">
.popular-tag {
  margin-left: 15px;
}
</style>
