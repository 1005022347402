<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.50008 18.3333C7.96032 18.3333 8.33341 17.9602 8.33341 17.5C8.33341 17.0398 7.96032 16.6667 7.50008 16.6667C7.03984 16.6667 6.66675 17.0398 6.66675 17.5C6.66675 17.9602 7.03984 18.3333 7.50008 18.3333Z"
      fill="#585B65"
      stroke="#585B65"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M16.6666 18.3333C17.1268 18.3333 17.4999 17.9602 17.4999 17.5C17.4999 17.0398 17.1268 16.6667 16.6666 16.6667C16.2063 16.6667 15.8333 17.0398 15.8333 17.5C15.8333 17.9602 16.2063 18.3333 16.6666 18.3333Z"
      fill="#585B65"
      stroke="#585B65"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M0.833252 0.833321H4.16658L6.39992 11.9917C6.47612 12.3753 6.68484 12.72 6.98954 12.9652C7.29424 13.2105 7.6755 13.3408 8.06658 13.3333H16.1666C16.5577 13.3408 16.9389 13.2105 17.2436 12.9652C17.5483 12.72 17.757 12.3753 17.8333 11.9917L19.1666 4.99999H4.99992"
      stroke="#585B65"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>
