<template>
  <plain-button
    :class="{ 'mobile': isMobile }"
    class="create-collections-container"
    @click="onStart">
    <empty-collection-card
      class="create-collections"
      :class="titleVariant"
      :title-variant="titleVariant"
      background-variant="gradient"
      title="Collections">
      <div class="d-flex flex-column align-items-center">
        <p
          class="mt-2 text-pewter text-center"
          :class="{ 'small-text': isMobile }">
          Create <strong>collections</strong> to organize and share styles!
        </p>
        <text-link
          small
          @click="onStart">
          Create Collection
        </text-link>
      </div>
    </empty-collection-card>
  </plain-button>
</template>

<script>
import EmptyCollectionCard from './EmptyCollectionCard.vue'
import PlainButton from '../global/sequin/PlainButton.vue'
import TextLink from '../global/sequin/TextLink.vue'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    EmptyCollectionCard,
    PlainButton,
    TextLink
  },
  props: {
    titleVariant: {
      type: String,
      default: 'overlay-top'
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    onStart () {
      this.openModal({ name: 'create-collection' })
    }
  }
}
</script>

<style lang="scss" scoped>

.create-collections-container {
  width: 23.5%;
  padding: 0;

  &.mobile {
    width: 45%;
  }
}

.create-collections {
  width: 100%;

  @supports not (aspect-ratio: 1) {
    min-height: 240px;
  }

  &.overlay {
    aspect-ratio: .9;
  }

  padding: 0;
  border: $default-border;
  border-radius: 8px;
  overflow: hidden;
}
</style>
