<template>
  <AuthButton
    name="Facebook"
    @click="onClick">
    <SvgFacebook
      height="26"
      width="26"/>
  </AuthButton>
</template>

<script setup>
import { onMounted } from 'vue'
import AuthButton from './AuthButton.vue'
import SvgFacebook from '@/components/global/svg/SvgFacebook.vue'
import { IncomingMobileAppMessage, MobileAppMessage } from '@/utils/mobileApp.js'
import useMobileApp from '@/composables/mobileApp.js'
import { initFacebookSDK } from '@/plugins/fb-sdk.js'

const emit = defineEmits(['success', 'error'])

onMounted(() => {
  if (!document.getElementById('fb-root')) {
    const fbRoot = document.createElement('div')
    fbRoot.id = 'fb-root'
    document.body.insertAdjacentElement('afterbegin', fbRoot)
  }
  initFacebookSDK()
})

function signIn () {
  if (typeof window.FB === 'undefined') {
    emit('error', 'Please disable any ad-blockers and reload the page to login with Facebook.')
    return
  }
  window.FB.login(
    ({ status, authResponse }) => {
      if (status === 'connected') {
        const formData = {
          facebook_token: authResponse.accessToken
        }
        emit('success', formData)
      }
    },
    { scope: 'email,public_profile' }
  )
}

const { inWebView, post, subscribeMessageListener } = useMobileApp()

function onClick () {
  if (inWebView()) {
    post(MobileAppMessage.SignUpWithFacebook)
  } else {
    signIn()
  }
}

subscribeMessageListener(IncomingMobileAppMessage.OnFacebookSignIn, (data) => {
  if (data.error) {
    emit('error', data.error)
  } else {
    emit('success', data)
  }
})
</script>
