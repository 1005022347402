<template>
  <div
    :class="[titleVariant, { 'mobile': isMobile, 'visible': visible }]"
    class="preview-placeholder-container">
    <div
      class="preview-placeholder"
      :class="[titleVariant, { 'visible': visible }]"/>
    <div
      v-if="titleVariant === 'below' && visible"
      class="mt-3">
      <text-placeholder
        size="large"
        variant="h6"/>
      <text-placeholder/>
    </div>
  </div>
</template>

<script>
import TextPlaceholder from '../global/sequin/TextPlaceholder.vue'

export default {
  components: {
    TextPlaceholder
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    titleVariant: {
      type: String,
      validator: v => v.match(/(overlay|below|full-width)/),
      default: 'overlay'
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-placeholder-container {
  width: 23.5%;

  &.mobile {
    width: 45%;
  }

  &.full-width {
    width: 100%;
  }

}

.preview-placeholder {
  width: 100%;
  border-radius: 8px;

  // Back ups for safari
  // not supporting aspect ratio
  @supports not (aspect-ratio: 1) {
    height: 18vw;

    &.mobile {
      height: 40vw;
    }
  }

  @supports (aspect-ratio: 1) {
    aspect-ratio: 1;
  }

  &.overlay {
    height: 100%;

    @supports (aspect-ratio: 1) {
      aspect-ratio: .9;
    }
  }

  &.full-width {
    width: 100%;
    height: 35vh;

    &.mobile {
      height: 28vh;
    }
  }

  &.visible {
    @include placeholder-loading-animation;
  }
}
</style>
