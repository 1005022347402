<template>
  <StyleColorSetPage
    :style-color-set="styleColorSection"
    :style-colors-loading="brandSection && brandSection.loading"
    @bottom-of-set="getNextPage(brandId)"
    @clear-all-filters="clearAllFilters"
    @remove-filter="removeFilter"
    @on-share-style-color-set-page="onShareClick"
    @on-available-now-toggle="applyFilters({ 'availability': !brandSection.available })"/>
</template>

<script>
import StyleColorSetPage from '@/components/styleColorSet/StyleColorSetPage.vue'
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import { track } from '../../plugins/analytics'
import { useOverlaysStore } from '@/stores/overlays.js'
import { useModals } from '@/composables/modals.js'
import { useQueryStringFilters } from '@/composables/queryStringFilters.js'

export default {
  components: { StyleColorSetPage },
  setup () {
    const { openShareModal } = useModals()
    const { queryStringFilters } = useQueryStringFilters()

    return {
      queryStringFilters,
      openShareModal
    }
  },
  computed: {
    ...mapState('closet', [
      'baseFilters'
    ]),
    ...mapState('brands', [
      'brandDetails',
      'brandSections',
      'sizeFilter'
    ]),
    brandId () {
      return this.$route.params.brandId
    },
    brandDetail () {
      return this.brandDetails[this.brandId]
    },
    brandSection () {
      return this.brandSections[this.brandId]
    },
    styleColorSection () {
      return {
        ...this.brandDetail,
        ...this.brandSection,
        imageUrl: this.brandDetail?.logoUrl,
        source: `brand-${this.brandId}`,
        sizeFilter: this.sizeFilter,
        hideSort: true,
        showAvailabilityToggle: true
      }
    }
  },
  async created () {
    if (!this.brandDetail) {
      this.getBrandDetails(this.brandId)
    }
    if (!this.brandSection) {
      this.getStyleColors({ brandId: this.brandId, filters: this.queryStringFilters })
    }
  },
  mounted () {
    track('Viewed Closet Brand Page')
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openFlyout'
    ]),
    ...mapVuexActions('brands', [
      'getBrandDetails',
      'getStyleColors',
      'initializeFilters',
      'getNextPage',
      'clearResults',
      'updateAvailability',
      'clearFilters',
      'updateFilters',
      'updateSizeFilters'
    ]),
    applyFilters ({ filters, availability }) {
      if (availability !== this.brandSection.available) {
        track('Availability Toggled', {
          available: availability,
          source: 'Closet Brand Filters',
          id: this.brandDetail.id,
          brandName: this.brandDetail.name
        })
      }
      this.updateAvailability({ brandId: this.brandId, state: availability })
      this.updateFilters({
        brandId: this.brandId,
        filters: { ...filters }
      })
    },
    removeFilter ({ filter }) {
      const appliedFilters = this.brandSection.filters
      this.updateFilters({
        brandId: this.brandId,
        filters: {
          ...appliedFilters,
          [filter.filterType]: appliedFilters[filter.filterType].filter(x => x !== filter.value)
        }
      })
    },
    clearAllFilters () {
      this.clearFilters(this.brandId)
    },
    onShareClick () {
      if (this.brandSection) {
        track('Clicked Share on Brand Page', {
          channel: 'web-share',
          name: this.brandDetail.name,
          id: this.brandDetail.id
        })
      }
      this.openShareModal(this.styleColorSection)
    }
  }
}
</script>
