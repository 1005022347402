<template>
  <div
    v-if="isDesktop"
    class="create-collection-box text-center">
    <div class="header">
      <h6 class="m-0">
        Collections
      </h6>
    </div>
    <div class="description">
      <p class="m-0">
        Use Collections to organize and share styles!
      </p>
      <BaseButton
        text="Create New Collection"
        variant="text-link"
        @click="openCreateCollectionModal"/>
    </div>
  </div>
  <BaseButton
    v-else
    text="Create Collection"
    class="w-100"
    @click="openCreateCollectionModal"/>
</template>

<script setup>
import { useModals } from '@/composables/modals.js'
import useScreenSize from '@shared/composables/screenSize.js'
import BaseButton from '@shared/components/ADORN/BaseButton'

const { isDesktop } = useScreenSize()

const { openCreateCollectionModal } = useModals()
</script>

<style lang="scss" scoped>
.create-collection-box {
  background-color: $white;
  border: 1px solid $ash;
  border-radius: $sequin-border-radius;
  overflow: hidden;

  .header {
    background-color: $armor;
    color: $white;
    padding: 20px;
  }

  .description {
    padding: 28px;
    p {
      color: $pewter;
    }
  }
}
</style>
