<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none">
    <path
      d="M24.2054 30.1973C20.7822 35.8298 17.4077 41.3828 14 46.9906C17.5907 48.6761 21.1003 50.3238 24.6648 51.9969C26.9592 45.6919 29.2174 39.4865 31.4926 33.2347C29.0313 32.2094 26.6481 31.2157 24.2054 30.1973ZM32.6375 33.2355C34.9242 39.5158 37.1901 45.7382 39.4699 52C43.0652 50.3099 46.5749 48.6591 50.1308 46.9875C46.6914 41.3303 43.3146 35.7749 39.9239 30.1973C37.4727 31.2195 35.0917 32.2125 32.6375 33.2355ZM32.1415 33.8339C32.0844 33.8339 32.0464 33.8339 31.9893 33.8339C29.796 39.8679 27.5902 45.9038 25.3907 51.9564C29.86 51.9564 34.2639 51.9564 38.7401 51.9564C36.5267 45.8698 34.3302 39.8525 32.1415 33.8339ZM32.3194 32.5977C32.5742 32.5059 32.7842 32.4402 32.9864 32.3561C34.9381 31.5469 36.8805 30.7138 38.8453 29.9371C39.3711 29.7294 39.5506 29.4677 39.5483 28.9048C39.5259 24.1726 39.5316 19.4319 39.5293 14.6997C39.5293 14.4889 39.5054 14.281 39.4877 14C39.2792 14.0942 39.1572 14.1421 39.043 14.2031C36.96 15.3172 34.8848 16.4484 32.7888 17.5378C32.3781 17.7509 32.2785 18.0011 32.28 18.4296C32.2939 22.9371 32.2885 27.4439 32.2901 31.9515C32.2908 32.1484 32.3078 32.3453 32.3194 32.5977ZM44.3023 18.5885C42.8965 17.0906 41.5253 15.6334 40.1758 14.1958C40.1758 17.014 40.1758 19.854 40.1758 22.639C41.6241 21.2191 43.0671 19.7992 44.3023 18.5885Z"
      fill="currentColor"/>
    <path
      d="M30.9827 32.3561C31.185 32.4402 31.395 32.5058 31.6498 32.5977C31.6614 32.3453 31.6783 32.1484 31.6791 31.9515C31.6807 27.4439 31.6752 22.9371 31.6891 18.4296C31.6907 18.0011 31.5911 17.7509 31.1804 17.5378C29.0843 16.4484 27.0091 15.3172 24.9262 14.2031C24.8119 14.1421 24.69 14.0942 24.4815 14C24.4638 14.2811 24.4399 14.4889 24.4399 14.6997C24.4376 19.4319 24.4433 24.1726 24.4209 28.9048C24.4186 29.4677 24.5981 29.7294 25.1238 29.9371C27.0886 30.7138 29.0311 31.5469 30.9827 32.3561Z"
      fill="currentColor"/>
    <path
      d="M23.7934 14.1997C22.4438 15.6373 21.0727 17.0945 19.6668 18.5924C20.9021 19.8031 22.345 21.223 23.7934 22.6429V14.1997Z"
      fill="currentColor"/>
  </svg>
</template>
