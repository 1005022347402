<template>
  <div class="d-flex flex-column row-gap-2">
    <HorizontalScrollSection
      title="My Collections"
      :link="{
        text: 'View Collections',
        to: routeLocation
      }">
      <SectionTileButton
        v-for="sectionId in myCollectionsSectionIds"
        :key="sectionId"
        :class="tileClasses"
        :section-id="sectionId"/>
      <CollectionTileButton
        v-for="collection in collections"
        :key="collection.id"
        :class="tileClasses"
        :collection="collection"/>
    </HorizontalScrollSection>
    <CreateCollectionButton/>
  </div>
</template>

<script setup>
import useMyStuff from '@/composables/myStuff.js'
import HorizontalScrollSection from '@shared/components/ADORN/HorizontalScrollSection.vue'
import SectionTileButton from './SectionTileButton.vue'
import CollectionTileButton from './CollectionTileButton.vue'
import CreateCollectionButton from './CreateCollectionButton.vue'

const { myCollectionsSectionIds, collections, routeLocation } = useMyStuff()
const tileClasses = 'col-6 col-md-4 col-lg-5 col-xxl-4'
</script>
