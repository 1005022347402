<template>
  <plain-button
    :block="block"
    :mobile-block="mobileBlock"
    :type="type"
    :href="href"
    :to="to"
    :class="[variant, { 'small': small, 'skinny': skinny, 'disabled': disabled }]"
    :disabled="disabled"
    class="sequin-button"
    @click="$emit('click')">
    <slot/>
  </plain-button>
</template>

<script>
import PlainButton from './PlainButton'
export default {
  name: 'SequinButton',
  components: {
    PlainButton
  },
  props: {
    // The underlying component type: button, a, or router-link
    type: {
      type: String,
      default: 'button',
      validator: value => {
        return value.match(/(button|a|router-link|)/)
      }
    },
    // The styling variant: primary (default), secondary, or danger
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/(primary|secondary|active|danger|inverse)/)
      }
    },
    // Pass for full width button
    block: {
      type: Boolean,
      default: false
    },
    // pass for full width button only on mobile
    mobileBlock: {
      type: Boolean,
      default: false
    },
    // pass for smaller button (less padding)
    small: {
      type: Boolean,
      default: false
    },
    // pass for thinner button (less left/right padding)
    skinny: {
      type: Boolean,
      default: false
    },
    // For links, the href
    href: {
      type: String,
      default: null
    },
    // For router-links, the destination
    to: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>

.sequin-button {
  font-family: $headings-font-family;
  border-radius: $sequin-border-radius;
  font-size: $small-text-font-size;
  line-height: 1.25rem;
  font-weight: $font-weight-semibold;
  text-align: center;
  text-decoration: none;

  &.small {
    padding: 8px 16px;
  }

  &.skinny {
    padding-left:  16px;
    padding-right: 16px;
  }

  &.disabled {
    border-style: dashed;
    opacity: .5;
  }
}

.primary {
  background-color: $primary;
  color: $origami;
  border: .5px solid $primary;

  &:hover {
    border-color: lighten($primary, 5%);
    background-color: lighten($primary, 5%);
    color: $white;
  }
  &:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
    box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
  }
}

.secondary {
  color: $pewter;
  background-color: $white;
  border: .5px solid $ash;
  &:hover {
    color: darken($pewter, 10%);
  }
}

.active {
  color: $orchid;
  border: .5px solid $orchid;
  background-color: rgba($orchid, 0.1);
}

.danger {
  color: $danger-dark;
  border: .5px solid $ash;
  &:hover {
    color: darken($danger-dark, 10%);
  }
}

.inverse {
  background-color: $white;
  color: $secondary;
  &:hover {
    color: darken($secondary, 10%);
  }
}

.w-330{
  width: 330px;
}
</style>
