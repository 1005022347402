<template>
  <div :class="['preview-image-button', sizeClass, variant]">
    <PreviewImagePlaceholder
      v-if="variant === 'default'"
      class="position-relative"
      :size="size">
      <template #overlay>
        <slot name="overlay"/>
      </template>
    </PreviewImagePlaceholder>
    <img
      v-else-if="variant === 'dress'"
      class="placeholder-image"
      :src="dressSrc">
    <div v-else-if="variant === 'browse'">
      <p class="small-text">
        Explore looks from other members and discover new favorites.
      </p>
      <TextLink
        type="router-link"
        :to="{ name: 'feed'}">
        Browse Looks
      </TextLink>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import PreviewImagePlaceholder from './PreviewImagePlaceholder.vue'
import TextLink from '@/components/global/sequin/TextLink.vue'

const props = defineProps({
  size: {
    type: String,
    default: 'auto',
    validator: value => ['auto', 'xx-large', 'x-large', 'large', 'medium', 'small', 'tiny'].includes(value)
  },
  variant: {
    type: String,
    default: 'default',
    validator: value => {
      return value.match(/(default|empty|dress|browse)/)
    }
  }
})
const dressSrc = `${process.env.VUE_APP_WEBSITE_IMAGES_URL}armoire-dress-dark.png`

const sizeClass = computed(() => props.variant === 'default' ? props.size : props.size === 'auto' ? 'medium' : props.size)
</script>

<style lang="scss" scoped>
@import './PreviewImage.scss';
@import './PreviewImageButton.scss';

.preview-image-button {
  &.empty {
    background-color: $white;
    border: 1px dashed $ash;
  }
  &.dress {
    background-color: $origami;
    img {
      opacity: 0.2;
      width: auto;
      height: 48px;
    }
    &.small, &.tiny {
      img {
        height: 36px;
      }
    }
  }
  &.browse {
    background-color: $origami;
    padding: 12px;

    &.large, &.x-large {
      padding: 20px;
    }
  }
  &:not(.default) {
    display: grid;
    place-items: center;
    aspect-ratio: #{$image-aspect-ratio};
    @include preview-image-sizes using ($size, $width, $height) {
      width: #{$width};
      height: auto;
    }
  }
}
</style>
