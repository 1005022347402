<template>
  <div class="d-flex align-items-center w-100">
    <plain-button
      class="m-0 p-0"
      type="router-link"
      :to="{ name: 'profile', params: { id: client.id } }"
      @click="onAvatarClicked">
      <avatar-image
        :client-id="client.id"
        :announcement="isAnnouncement"
        :profile-pic="client.profilePic"
        :alt="`${client.firstName}'s Avatar Image`"
        :staff="client.staff"/>
    </plain-button>
    <div class="w-100 ms-3 d-flex justify-content-between align-items-center">
      <div>
        <plain-button
          class="m-0 p-0"
          type="router-link"
          :to="{ name: 'profile', params: { id: client.id } }"
          @click="onAvatarClicked">
          <span class="m-0">
            <span class="semi-bold">
              {{ client.firstName }} {{ client.lastName }}
            </span>
            <slot name="name"/>
          </span>
        </plain-button>
        <staff-header
          v-if="client.staff"/>
        <p
          v-if="isAnnouncement"
          class="subhead announcement">
          Announcement
        </p>
        <div
          v-else-if="tags && tags[0]">
          <text-link
            v-if="tags[0].id"
            class="subhead"
            type="router-link"
            :underline="false"
            :to="{ name: 'feed-item-tag-id', params: {
              tagId: tags[0].id, tag: tags[0].tag } }">
            {{ tags[0].tag }}
          </text-link>
          <span
            v-else
            class="subhead text-pewter">
            {{ tags[0].tag }}
          </span>
        </div>
        <p
          v-else-if="location"
          class="subhead">
          {{ location }}
        </p>
      </div>
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
import AvatarImage from '../common/AvatarImage'
import StaffHeader from '../common/StaffHeader'
import PlainButton from '../../global/sequin/PlainButton'
import TextLink from '../../global/sequin/TextLink'
import { mapState } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    AvatarImage,
    PlainButton,
    StaffHeader,
    TextLink
  },
  props: {
    client: {
      type: Object,
      default: null
    },
    isAnnouncement: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: null
    },
    location: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: 'Avatar image'
    }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    isEditable () {
      return !this.isAnnouncement
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    onAvatarClicked () {
      this.$store.commit('community/SET_CLIENT_INFO', this.client)
    }
  }
}
</script>

<style lang="scss" scoped>
.subhead {
  font-size: $small-text-font-size;
  line-height: $small-text-line-height;
  text-transform: capitalize;
  margin-bottom: 0;
  padding: 0;
  font-weight: $font-weight-normal;
  &.announcement {
    font-weight: $font-weight-semibold;
  }
}

</style>
