import { computed } from 'vue'
import { useStore } from 'vuex'

// Composable to simplify access to client data from the Composition API.
// When we convert the client store to Pinia, most if not all of this won't be necessary.
export default function useClient () {
  const store = useStore()

  const isLoaded = computed(() => store.state.client.loaded === true)
  const isAuthenticated = computed(() => store.state.client?.isAuthenticated === true)
  const isImpersonated = computed(() => store.state.client.isImpersonated)
  const impersonator = computed(() => store.state.client.impersonator)
  const impersonatorName = computed(() => `${impersonator.value.first_name} ${impersonator.value.last_name}`)
  const impersonatorGroups = computed(() => impersonator.value?.groups)
  const impersonatorIsStylist = computed(() => impersonatorGroups.value?.includes('Remote Stylist'))

  const clientId = computed(() => store.state.client.id)
  const firstName = computed(() => store.state.client.firstName)
  const lastName = computed(() => store.state.client.lastName)
  const name = computed(() => `${firstName.value} ${lastName.value}`)
  const greetingName = computed(() => firstName.value?.length > 0 ? firstName.value : 'Boss Lady')
  const username = computed(() => store.state.client.username)

  const profileImageUrl = computed(() => store.state.client.profilePic)
  const hasProfileImage = computed(() => profileImageUrl.value?.length > 0)
  const hasCustomProfileImage = computed(() => hasProfileImage.value && !profileImageUrl.value.includes('dresses'))

  const firstCaseDueDate = computed(() => {
    const dueDate = store.state.client.firstCaseDueDate
    return dueDate ? new Date(dueDate) : null
  })
  const paymentIsExpired = computed(() => store.getters['client/paymentIsExpired'])
  const showFirstCaseCountdown = computed(() => store.getters['client/showFirstCaseCountdown'])

  return {
    isLoaded,
    isAuthenticated,
    isImpersonated,
    impersonator,
    impersonatorName,
    impersonatorGroups,
    impersonatorIsStylist,
    clientId,
    firstName,
    lastName,
    name,
    greetingName,
    username,
    profileImageUrl,
    hasProfileImage,
    hasCustomProfileImage,
    firstCaseDueDate,
    paymentIsExpired,
    showFirstCaseCountdown
  }
}
