<template>
  <div>
    <sequin-button
      block
      @click="purchaseItemClicked">
      Buy Now
    </sequin-button>
    <p class="small-text text-pewter mb-0 mt-3 text-center">
      <strong class="me-1">
        This style is with you!
      </strong>
    </p>
    <p class="small-text text-pewter mb-0 text-center">
      Want to keep it forever? Purchase it at our members&#8209;only discount for <strong>${{ itemToPurchase.item.priceClient }}</strong>.
    </p>
  </div>
</template>

<script>
import { mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import SequinButton from '../global/sequin/SequinButton'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    SequinButton
  },
  props: {
    itemToPurchase: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openFlyout'
    ]),
    ...mapVuexActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    purchaseItemClicked () {
      this.setMyCaseFlyoutContext({
        itemToPurchase: this.itemToPurchase
      })
      this.setMyCaseFlyoutName('purchase-item')
      this.openFlyout({ name: 'my-case' })
    }
  }
}
</script>
