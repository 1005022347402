<template>
  <div class="position-relative">
    <div
      ref="scrollWrapper"
      class="scroll-wrapper"
      :style="scrollWrapperStyles">
      <slot name="scrollable"/>
    </div>
    <div class="overlay">
      <slot name="overlay"/>
    </div>
    <slot name="static"/>
    <div
      v-if="length > 1"
      class="d-flex justify-content-center pt-3">
      <div
        v-for="(_, index) in length"
        :key="index"
        class="mx-1 dot"
        :class="{ 'selected': index === currentIndex }"
        @click="clickDot(index)"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhotoSnapScroll',
  props: {
    initialIndex: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 1
    },
    width: {
      type: Number,
      default: 320 // xlPhotoSize
    },
    alwaysScroll: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentIndex: this.initialIndex
    }
  },
  computed: {
    scrollWrapperStyles () {
      if (this.fullWidth) {
        return { maxWidth: '100%' }
      } else {
        return { maxWidth: '320px' }
      }
    }
  },
  activated () {
    this.currentIndex = this.initialIndex
    this.$emit('update-index', this.currentIndex)
    this.initializeScroll()
  },
  mounted () {
    this.initializeScroll()
    if (this.$refs.scrollWrapper) {
      this.$refs.scrollWrapper.onscroll = ({ target: { scrollLeft } }) => {
        this.currentIndex = Math.round(scrollLeft / this.width)
        this.$emit('update-index', this.currentIndex)
      }
    }
  },
  deactivated () {
    this.currentIndex = 0
  },
  methods: {
    clickDot (index) {
      if (this.isMobile || this.alwaysScroll) {
        this.$refs.scrollWrapper.scrollLeft = index * this.width
      }
      this.currentIndex = index
      this.$emit('update-index', index)
    },
    // Hack for setting initial image in the scroll wrapper
    initializeScroll () {
      setTimeout(() => {
        if (this.$refs.scrollWrapper) {
          this.$refs.scrollWrapper.scrollLeft = this.currentIndex * this.width
        }
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>

  $xlPhotoSize: 320px;

  .dot {
    height: 10px;
    width: 10px;
    background-color: $ash;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s ease;

    &.selected {
      background-color: $pewter;
    }
  }

  .scroll-wrapper {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;

    :slotted(.scroll-item) {
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .overlay {
    position: relative;
  }
</style>
