<template>
  <div
    class="box border text-pewter mt-3"
    :class="{ 'text-center': centered }">
    <div
      class="inner-box d-flex align-items-center"
      :class="{ 'full-width': fullWidth, 'justify-content-center': centered }">
      <div
        class="p-2 d-flex justify-content-center">
        <div>
          <img
            v-if="iconName"
            height="25px"
            class="dress p-1"
            :src="`${baseUrl}community-profile-sizes/${iconName}`">
          <div>
            {{ label }}
          </div>
          <div
            class="mt-1">
            <b>{{ value }}</b>
            <slot/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      default: ''
    },
    centered: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_WEBSITE_IMAGES_URL
    }
  }
}

</script>

<style lang="scss" scoped>

.box {
  background-color: white;

  &.centered {
    justify-content: center;
  }

}

.inner-box {
  padding: 10px;

  &.full-width {
    width: 100%;
  }
}

</style>
