<template>
  <div
    class="timestamp"
    :class="{'small-print': condensed}">
    {{ getPrintableDateTime(timestamp) }}
  </div>
</template>

<script>
import { toMonthDayString } from '@/utils/stringParsing.js'

export default {
  props: {
    timestamp: {
      type: String,
      required: true
    },
    condensed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getPrintableDateTime (timestamp) {
      const now = new Date()
      const created = new Date(timestamp)
      const difference = now - created

      // if within the last 24 hours, show a shortened version of timestamp
      // with the current time as a reference
      if (difference / 1000 / 60 / 60 < 24) {
        const differenceInHours = Math.round(difference / 1000 / 60 / 60)
        const differenceInMinutes = Math.round(difference / 1000 / 60)

        // happened within the last minute
        if (differenceInMinutes < 1) {
          return 'just now'
        }

        if (this.condensed) {
          return differenceInHours > 0
            ? `${differenceInHours}hr`
            : `${differenceInMinutes}m`
        }

        return differenceInHours > 0
          ? `${differenceInHours} hour${differenceInHours > 1 ? 's' : ''} ago`
          : `${differenceInMinutes} minute${differenceInMinutes > 1 ? 's' : ''} ago`
      }

      // otherwise, show month day
      return toMonthDayString(timestamp)
    }
  }
}
</script>

<style lang="scss" scoped>
.timestamp {
  color: $gray;
  font-size: $small-text-font-size;
  line-height: 20px;
}

.small-print {
  font-size: .75rem;
  line-height: 1rem;
}
</style>
