/* eslint-disable */

export default {
  methods: {
    /**
     * Scrolls the passed element into view with the offset from 
     * @param element
     * @param topOffset
     * @returns {*}
     */
    scrollIntoViewWithOffset (element, topOffset) {
      const scrollY = element.getBoundingClientRect().top - topOffset
      window.scrollBy(0, scrollY)
    }
  }   
}