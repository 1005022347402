<template>
  <plan-select-page
    header="Prepay + Save"
    confirmation-title="Confirm Prepay"
    :selectable-plans="availablePrepayPlans.sort((a, b) => planSort(a, b))"
    :breadcrumb="{ name: 'Prepay + Save', to: 'prepay' }"/>
</template>

<script>
import MembershipMixin from './MembershipMixin'
import PlanSelectPage from './PlanSelectPage'

export default {
  name: 'PrepayPage',
  components: { PlanSelectPage },
  mixins: [MembershipMixin],
  methods: {
    planSort (a, b) {
      // Sort by non capsule vs capsule, # items descending, then price
      // Remember that the sort() function returns
      //   - negative if a should come before b
      //   - positive if a should come after b
      //   - and 0 if they are equal
      if (a.shipmentsPerInterval === null) {
        if (b.shipmentsPerInterval !== null) {
          return -1
        }
      } else if (b.shipmentsPerInterval === null) {
        return 1
      }
      // Sort by # items then price
      if (a.nItems === b.nItems) {
        return b.basePrice - a.basePrice
      } else {
        return b.nItems - a.nItems
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
