<template>
  <svg
    class="spinner"
    width="65"
    height="65"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      class="path"
      fill="none"
      cx="33"
      cy="33"
      r="30"/>
  </svg>
</template>

<style lang="scss" scoped>

body {
  display: flex;
  align-items: center;
  justify-content: center;
}

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: $orchid;
  animation: dash $duration ease-in-out infinite
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: calc($offset / 4);
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}
</style>
