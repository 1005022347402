<template>
  <div
    class="bubble-selector-wrapper">
    <label
      v-for="option in options"
      :key="option.value"
      class="bubble-select-option"
      :class="{'active' : isActive(option.value)}">
      {{ option.text }}
      <svg-circle-icon
        :inverse="!isActive(option.value)"
        stroke="#850D54"
        variant="champagne">
        <svg-check v-if="isActive(option.value)"/>
      </svg-circle-icon>
      <input
        v-model="selection"
        :style="{ position: 'absolute', opacity: 0 }"
        :value="option.value"
        name="options"
        type="radio">
    </label>
  </div>
</template>
<script>
import SvgCircleIcon from '../svg/SvgCircleIcon.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    SvgCircleIcon,
    SvgCheck
  },
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selection: ''
    }
  },
  watch: {
    selection (val) {
      this.setBubbleSelection(val)
    }
  },
  methods: {
    isActive (optionValue) {
      return this.selection === optionValue
    },
    setBubbleSelection (selection) {
      this.$emit('set-bubble-selection', selection)
    }
  }
}
</script>
<style lang="scss" scoped>
.bubble-selector-wrapper {
  display: flex;
  flex-direction: column;
}
.bubble-select-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  border: 0.5px solid var(--Ash, #BABABF);
  padding: 15px 21px;
}
.active {
  color: $white;
  background-color: $plum;
}
</style>
