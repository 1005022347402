<template>
  <div
    v-if="menuItems.length > 0"
    class="stacked-menu">
    <plain-button
      v-for="(menuItem, index) in menuItems"
      :key="menuItem.name"
      block
      type="router-link"
      :to="{ name: menuItem.to }"
      :class="['menuItem', { 'border-bottom-0': index === menuItems.length - 1, 'selected': menuItems[index].selected }]"
      @click="$emit('click', menuItem.name)">
      <div class="d-flex justify-content-between w-100">
        <div>
          <div class="d-flex justify-items-between">
            <h5 class="mb-0">
              {{ menuItem.name }}
            </h5>
            <slot
              name="menuItemSlot"
              :menu-item-name="menuItem.name"/>
          </div>
          <div
            v-if="menuItem.description"
            class="small-text">
            {{ menuItem.description }}
          </div>
          <!-- Parent can pass custom slot rendered after menu item title -->
        </div>
        <svg-chevron-right/>
      </div>
    </plain-button>
  </div>
</template>

<script>
import PlainButton from './PlainButton'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'

export default {
  components: {
    PlainButton,
    SvgChevronRight
  },
  props: {
    /**  Each menuItem entry should be an object with these properties:
     * (1) Name - The name of the item as shown to the user
     * (2) To -- The name of the vue route where the item should navigate on click
    */
    menuItems: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>

.stacked-menu {
  width: 100%;
  border: $default-border;
  border-radius: $sequin-border-radius;

  .selected {
    background-color: $origami;
  }

  .menuItem {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $default-border;
    border-radius: 0;

    .menuItemTitle {
      display: flex;
      align-items: center;
    }
  }
}

</style>
