<template>
  <div
    class="small-text notification"
    :class="variant">
    <div class="w-100">
      <slot/>
    </div>
    <plain-button
      v-if="hasCloseButton"
      class="close"
      :class="variant"
      @click="$emit('close-clicked')">
      <svg-x height="20"/>
    </plain-button>
  </div>
</template>

<script>
import PlainButton from './PlainButton'
import SvgX from '@/components/global/svg/SvgX.vue'

export default {
  components: {
    PlainButton,
    SvgX
  },
  props: {
    variant: {
      type: String,
      default: 'info',
      validator: value => {
        return value.match(/(info|danger|brand|success)/)
      }
    },
    hasCloseButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.notification {
  padding: 16px;
  text-align: left;
  display: flex;

  &.danger {
    background: rgba($danger-light, .05);
    color: $danger-dark;
  }

  &.info {
    background: rgba($info-light, .05);
    color: $info-dark;
  }

  &.success {
    background: rgba($success-light, .05);
    color: $success-dark;
  }

  &.brand {
    background: $origami;
    color: $plum;
  }
}

.close {
  background: rgba($white, 0);
  margin-left: 12px;
  flex-shrink: 0;
  padding: 0;

  svg {
    stroke-width: 2px;
  }

  &.danger {
    color: $danger-dark;

    &:hover {
      color: $danger-dark;
    }
  }

  &.info {
    color: $info-dark;

     &:hover {
      color: $info-dark;
    }
  }

  &.success {
    color: $success-dark;

     &:hover {
      color: $success-dark;
    }
  }

  &.brand {
    color: $plum;

     &:hover {
      color: $plum;
    }
  }

}
</style>
