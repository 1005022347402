<template>
  <base-tooltip
    v-bind="$attrs"
    :max-width="maxWidth">
    <template #trigger>
      <svg-circle-icon
        :class="iconClass"
        variant="neutral"
        :height="18"
        :width="18">
        <svg-question-mark/>
      </svg-circle-icon>
    </template>
    <template #content>
      <slot/>
    </template>
  </base-tooltip>
</template>

<script>
import BaseTooltip from '@/components/global/BaseTooltip.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgQuestionMark from '@/components/global/svg/SvgQuestionMark.vue'

export default {
  components: {
    BaseTooltip,
    SvgCircleIcon,
    SvgQuestionMark
  },
  props: {
    iconClass: {
      type: String,
      default: 'ms-2'
    },
    maxWidth: {
      type: String,
      default: '300'
    }
  }
}
</script>
