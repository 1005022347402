<template>
  <div
    :class="{'mobile-container': isMobile}">
    <div
      class="d-flex justify-content-between pointer align-items-center py-sm"
      @click="onOccasionClick">
      <h5 class="m-0">
        Where did you wear this?
      </h5>
      <plain-button class="p-0 me-md">
        <svg-chevron-right
          height="25"
          width="25"/>
      </plain-button>
    </div>
    <div
      v-if="tagsSelected.length > 0">
      <div>
        <transition-group name="fade">
          <plain-button
            v-for="tag in tagsSelected"
            :key="tag.tag"
            class="occasion-tag"
            @click="tagOccasion(tag)">
            <occasion-tag
              :tag="tag"
              :show-remove="tag.selected"
              inner-padding/>
          </plain-button>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import PlainButton from '../global/sequin/PlainButton'
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import OccasionTag from '../community/sharelook/OccasionTag'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'

export default {
  name: 'ReviewItemOccasions',
  components: {
    OccasionTag,
    PlainButton,
    SvgChevronRight
  },
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('community', [
      'occasionTags'
    ]),
    tagsSelected () {
      return this.review.occasionTags.filter(e => e.selected)
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    ...mapVuexActions('review', [
      'updateOccasionTags'
    ]),
    onOccasionClick () {
      this.openModal({ name: 'pick-occasion', context: { review: this.review } })
    },
    tagOccasion (tag) {
      this.updateOccasionTags({
        tagId: tag.id,
        itemId: this.review.itemId
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.mobile-container {
  max-width: 100vw;
}

.button {
  padding-top: 0px;
  padding-right: 0px;
}

.occasion-tag {
  width: 100%;
  height: 68px;
  padding: 0px;
  background-color: $white;

  + .occasion-tag {
    border-top: $default-border;
  }
}

</style>
