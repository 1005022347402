import '@types'
import { useExperimentsStore } from '@shared/stores/experiments.js'
import { toPascalCase } from '@/utils/stringParsing.js' // TODO: ENG-3647 - Isolate shared code.
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export default function useExperiment (name, options = {}) {
  const experimentsStore = useExperimentsStore()
  const { updateMetadata, registerForExperiment, fetchRegisteredExperiments } = experimentsStore
  const { experiments, experimentsLoaded } = storeToRefs(experimentsStore)

  updateMetadata(name, options)

  const experiment = computed(() => experiments.value[name])
  const showOriginalWhenInactive = computed(() => experiment.value?.showOriginalWhenInactive ?? false)
  const isActive = computed(() => experiment.value?.isActive === true)
  const isRegistered = computed(() => experiment.value?.isRegistered === true)
  const variantNames = computed(() => experiment.value?.variantNames ?? [])
  const variant = computed(() => experiment.value?.variantName)
  const isOriginal = computed(() => variant.value === 'original')
  /**
   * @type {ComputedRef<{ [k: string]: boolean }>}
   */
  const variants = computed(() => variantNames.value.reduce((acc, variantName) => {
    acc[variantName] = variant.value === variantName
    return acc
  }, {}))
  /** @deprecated Use `showVariant{Name}` instead. */
  const showVariantDeprecated = computed(() =>
    experimentsLoaded.value &&
    isActive.value &&
    typeof variant.value === 'string' &&
    variant.value.length > 0 &&
    variants.value[variant.value] === true
  )
  const showOriginal = computed(() =>
    experimentsLoaded.value &&
    !showVariantDeprecated.value &&
    (isActive.value
      ? isOriginal.value || showOriginalWhenInactive.value
      : showOriginalWhenInactive.value)
  )

  // Generate `isVariant{Name}` computed properties.
  const isVariant = variantNames.value.reduce((acc, variantName) => {
    acc[`isVariant${toPascalCase(variantName)}`] = computed(() => variant.value === variantName)
    return acc
  }, {})

  // Generate `showVariant{Name}` computed properties.
  const showVariant = variantNames.value.reduce((acc, variantName) => {
    acc[`showVariant${toPascalCase(variantName)}`] = computed(() =>
      experimentsLoaded.value &&
      isActive.value &&
      variants.value[variantName] === true)
    return acc
  }, {})

  async function register () {
    await registerForExperiment(name)
  }

  if (!experimentsLoaded.value) {
    fetchRegisteredExperiments()
  }

  return {
    name,
    variantNames,
    showOriginalWhenInactive,
    isActive,
    isRegistered,
    variant,
    variants,
    isOriginal,
    ...isVariant,
    showVariant: showVariantDeprecated,
    ...showVariant,
    showOriginal,
    register
  }
}

export function useHomepageRedesignExperiment () {
  return useExperiment('homepage_redesign', {
    variantNames: ['redesign'],
    showOriginalWhenInactive: true
  })
}

// Sign-up experiments: Phase 2 overrides phase 1
export function useSignUpExperiments () {
  function useSignUpExperiment (phase) {
    return useExperiment(`sign_up_phase${phase}`, {
      variantNames: ['redesign'],
      showOriginalWhenInactive: true
    })
  }

  const phase1 = useSignUpExperiment(1)
  const phase2 = useSignUpExperiment(2)

  const signUpPhase1 = computed(() => phase1.showVariantRedesign.value && !phase2.showVariantRedesign.value)
  const signUpPhase2 = computed(() => phase2.showVariantRedesign.value)

  return {
    signUpPhase1,
    signUpPhase2
  }
}
