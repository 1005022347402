<template>
  <div>
    <div
      class="p-3 d-flex align-items-center justify-content-center position-relative">
      <back-button
        class="back-button"
        :back-default-route="{ name: 'profile'}"/>
      {{ viewingProfileName }}
    </div>
    <div
      v-if="membersCount && !addingSuggestedFollowers"
      class="d-flex align-items-center justify-content-center p-2 count-box border">
      {{ membersCount }} {{ type }}
    </div>
    <suggested-follow
      v-else/>
    <div
      v-for="(client, index) in members"
      :key="index">
      <profile-follow
        :client="client"
        :type="type"
        :is-own-profile="isOwnProfile"
        @follow-in-view="onFollowInView(index)"/>
    </div>
  </div>
</template>

<script>
import ProfileFollow from './ProfileFollow'
import SuggestedFollow from './SuggestedFollow'
import BackButton from '../../global/sequin/BackButton'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BackButton,
    ProfileFollow,
    SuggestedFollow
  },
  props: {
    type: {
      type: String,
      required: true
    },
    viewerProfileId: {
      type: String,
      required: true
    },
    viewingProfileId: {
      type: String,
      required: true
    },
    viewingProfileName: {
      type: String,
      required: true
    },
    isOwnProfile: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('community', [
      'addingSuggestedFollowers',
      'followersById',
      'followingById'
    ]),
    membersCount () {
      if (this.base[this.viewingProfileId]) {
        return this.base[this.viewingProfileId].count
      }
      return '---'
    },
    base () {
      if (this.type === 'followers') {
        return this.followersById
      }
      return this.followingById
    },
    members () {
      if (this.base[this.viewingProfileId] &&
          this.base[this.viewingProfileId].results) {
        return this.base[this.viewingProfileId].results
      }
      return []
    }
  },
  mounted () {
    this.getResults()
  },
  methods: {
    ...mapActions('community', [
      'getClientFollowers',
      'getClientFollowing'
    ]),
    getResults () {
      if (this.type === 'followers') {
        this.getClientFollowers({ clientId: this.viewingProfileId })
      } else if (this.type === 'following') {
        this.getClientFollowing({ clientId: this.viewingProfileId })
      }
    },
    onFollowInView (index) {
      if (this.members.length - 1 === index) {
        this.getResults()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.count-box {
  color: $pewter;
  background-color: $origami;
}

.back-button {
  position: absolute;
  left: 0;
}

</style>
