<template>
  <div class="col col-12 px-0">
    <app-message
      :variant="useDangerText ? 'danger' : 'brand'"
      class="mb-3">
      <h5
        class="col col-12 text-center text-md-start ps-md-0"
        :class="{ 'text-danger': useDangerText }">
        <span v-if="clientHasLegacyPlan">Are you sure?</span>
        <span v-else-if="!clientIsMonthly">You have prepaid through {{ toLocaleDateString(paidThrough) }}</span>
        <span v-else>Need a break?</span>
      </h5>
      <p
        class="col col-12 text-center text-md-start ps-md-0 mb-2 mb-md-2"
        :class="{ 'text-danger': useDangerText }">
        <span v-if="clientHasLegacyPlan">You will lose access to your discounted pricing.</span>
        <span v-else-if="!clientIsMonthly">Your cancellation will go into effect at the end of your current prepay cycle.</span>
        <span v-else>
          You can
          <text-link
            type="router-link"
            :to="{ name: 'pause' }">
            Pause Membership
          </text-link>
          without losing any of your member benefits or perks -- like bonus items!
        </span>
      </p>
    </app-message>
    <p
      v-if="clientHasLegacyPlan"
      class="col col-12 text-center text-md-start ps-md-0 font-italic text-gray">
      By cancelling, you permanently forfeit your special rate of {{ legacyPlanPricing }} for your existing plan.
      If you choose to rejoin, it will be on our
      <text-link
        type="a"
        size="paragraph"
        href="https://support.armoire.style/plans">
        updated plans and pricing.
      </text-link>
    </p>
  </div>
</template>

<script>
import MembershipMixin from './MembershipMixin'
import TextLink from '../../global/sequin/TextLink'
import { formatPrice, toLocaleDateString } from '@/utils/stringParsing.js'
import AppMessage from '../../global/sequin/AppMessage'

export default {
  name: 'CancelReason',
  components: { AppMessage, TextLink },
  mixins: [MembershipMixin],
  computed: {
    clientHasLegacyPlan () {
      if (this.plans) {
        const { currentBasePlan, currentUpgradePlan } = this.plans
        const availablePlanIds = this.membershipPlans.map(plan => plan.id)
        return !!currentUpgradePlan || !availablePlanIds.includes(currentBasePlan.id)
      }
      return false
    },
    legacyPlanPricing () {
      const currentPlan = this.plans.currentBasePlan
      const price = formatPrice(currentPlan.basePrice)
      let interval = 'month'
      if (currentPlan.chargeInterval > 1) {
        interval = currentPlan.chargeInterval === 12 ? 'year' : `${currentPlan.chargeInterval} months`
      }
      return `${price}/${interval}`
    },
    useDangerText () {
      return this.clientHasLegacyPlan || !this.clientIsMonthly
    }
  },
  methods: {
    toLocaleDateString
  }
}
</script>
