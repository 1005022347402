<template>
  <div>
    <div
      class="row"
      :class="{'justify-content-center': isMobile}">
      <h2
        class="mb-3">
        Membership
      </h2>
    </div>
    <transition name="fade">
      <div
        v-if="showPlanUpdateSuccess"
        class="row">
        <app-message
          variant="success"
          has-close-button
          class="col col-12 mb-md-3"
          @close-clicked="onClose">
          <div
            class="p-1 d-flex justify-content-between align-items-center">
            Success! Your plan has been updated.
          </div>
        </app-message>
      </div>
    </transition>
    <div
      class="row">
      <div
        class="col-md-6 col-lg-5 col-xl-4"
        :class="{'mb-4': isMobile}">
        <plan-card
          v-if="membershipStatus === 'active' && !membershipDetails.scheduledCancelDate"
          :plan="plans.currentBasePlan"
          header-text="Current Plan"
          :class="{'mx-auto': isMobile}"
          :show-member-price="true"
          @change-click="onChangeClick"/>
        <inactive-plan-card
          v-else
          :membership-status="membershipStatus"
          :membership-details="membershipDetails"
          :outstanding-items="outstandingItems.length"/>
      </div>
      <div
        class="col-md-5 col-lg-7 offset-md-1 offset-lg-0"
        :class="{'ps-0': isMobile}">
        <membership-actions/>
      </div>
    </div>
  </div>
</template>

<script>
import PlanCard from '../../subscribe/PlanCard'
import { mapActions, mapGetters, mapState } from 'vuex'
import AppMessage from '../../global/sequin/AppMessage'
import { vuexAccessors } from '../../global/helpers/vuex'
import MembershipActions from './MembershipActions'
import InactivePlanCard from './InactivePlanCard'
import MembershipMixin from './MembershipMixin'
import { track } from '../../../plugins/analytics'

export default {
  components: { InactivePlanCard, MembershipActions, AppMessage, PlanCard },
  mixins: [MembershipMixin],
  props: {
    selected: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('client', [
      'membershipStatus',
      'membershipDetails'
    ]),
    ...mapGetters('closet', [
      'outstandingItems'
    ]),
    ...vuexAccessors('account', [
      'showPlanUpdateSuccess'
    ])
  },
  mounted () {
    this.membershipPlanSelectedId = null
    this.breadcrumbs = [{ name: 'Membership' }]
    this.fetchPlans()
    if (this.showPlanUpdateSuccess) {
      setTimeout(() => { this.showPlanUpdateSuccess = false }, 5000)
    }
    track('Viewed Membership Overview', {
      currentPlan: this.plans.currentBasePlan.displayName
    })
  },
  methods: {
    ...mapActions('subscribe', [
      'fetchPlans'
    ]),
    onClose () {
      this.showPlanUpdateSuccess = false
    },
    onChangeClick () {
      this.$router.push({ name: 'change-plan' })
    }
  }
}
</script>
