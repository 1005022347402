<template>
  <div
    class="mt-md-4 pb-md-4"
    :class="{ 'row': isMobile }">
    <action-header
      v-if="isMobile"
      class="col col-12"
      page-header>
      Edit Review Photos
    </action-header>
    <div :class="{ 'row mx-auto justify-content-center desktop': !isMobile }">
      <div
        v-if="!isMobile"
        class="col-5 mx-auto">
        <div class="pt-2 pb-3 ms-0 row align-items-center justify-content-start">
          <back-button
            :back-default-route="{ name: 'closet-sections' }"
            with-border/>
          <div>
            <h4 class="mb-0 ms-3">
              Edit Review Photos
            </h4>
          </div>
        </div>
        <review-item-card
          v-if="!isMobile"
          compact
          class="p-3 mt-1 w-100 border"
          :image-source="imageSource"
          :item-style="itemStyle"
          :size="size"/>
      </div>
      <div
        :class="isMobile ? 'col col-12 px-0' : 'mx-auto edit-photos-desktop col-7'">
        <div
          class="border-bottom-0"
          :class="{ 'border': !isMobile }">
          <review-item-card
            v-if="isMobile"
            compact
            class="p-3 border-bottom"
            :image-source="imageSource"
            :item-style="itemStyle"
            :size="size"/>
          <review-image-upload
            :existing-review-images="existingImages"
            :review="reviewForEditPhotos"
            @photos-changed="onPhotosChanged"/>
          <div class="px-1 px-md-4">
            <closet-review
              class="border-0"
              :style-color-id="reviewForEditPhotos.styleColorId"
              :show-review-actions="false"
              :review="reviewForEditPhotos"/>
          </div>
        </div>
        <div
          class="p-3 border"
          :class="{ 'save-button': isMobile }">
          <p
            v-if="submitErrors.length"
            class="text-danger small-text text-center">
            {{ submitErrors.join('. ') }}
          </p>
          <sequin-button
            :disabled="!hasUpdates || isSubmitting"
            block
            @click="onSave">
            Save Changes
          </sequin-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { vuexAccessors } from '../global/helpers/vuex'
import ActionHeader from '../global/sequin/ActionHeader'
import BackButton from '../global/sequin/BackButton'
import ClosetReview from '../closet/ClosetReview'
import ReviewImageUpload from './ReviewImageUpload'
import ReviewItemCard from './ReviewItemCard'
import SequinButton from '../global/sequin/SequinButton'
import { mapActions, mapState } from 'vuex'
import goBack from '../global/mixins/goBack'

export default {
  components: {
    ActionHeader,
    BackButton,
    ClosetReview,
    ReviewImageUpload,
    ReviewItemCard,
    SequinButton
  },
  mixins: [goBack],
  data () {
    return {
      updatedImages: [],
      isSubmitting: false,
      submitErrors: []
    }
  },
  computed: {
    ...vuexAccessors('review', [
      'reviewForEditPhotos'
    ]),
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    existingImages () {
      return this.reviewForEditPhotos.images.map(image => image.url)
    },
    itemStyle () {
      return this.styleColorsMap[this.reviewForEditPhotos.styleColorId].style
    },
    imageSource () {
      return this.styleColorsMap[this.reviewForEditPhotos.styleColorId].images.front.url
    },
    size () {
      return this.reviewForEditPhotos.feedbackSize
    },
    hasUpdates () {
      return JSON.stringify(this.existingImages.slice().sort()) !== JSON.stringify(this.updatedImages.slice().sort())
    }
  },
  mounted () {
    this.updatedImages = this.existingImages.slice()
  },
  methods: {
    ...mapActions('closet', [
      'getReviews'
    ]),
    ...mapActions('review', [
      'updateReviewPhotos'
    ]),
    onPhotosChanged (photos) {
      this.updatedImages = photos
    },
    async onSave () {
      try {
        this.isSubmitting = true
        this.submitErrors = []
        await this.updateReviewPhotos(this.updatedImages)
        await this.getReviews(this.reviewForEditPhotos.styleColorId)
        this.goBack()
      } catch (err) {
        this.submitErrors = err
        this.isSubmitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.desktop {
  max-width: 960px;
}

.edit-photos-desktop {
  margin-top: 66px;
}

.save-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: $default-border;
  z-index: $zindex-fixed;
}
</style>
