<template>
  <community-nav-wrapper>
    <action-header
      v-if="isMobile"
      page-header
      :fallback-route="{ name: 'feed' }">
      <div>
        <span v-if="!occasionTag">#</span>{{ tag }}
      </div>
    </action-header>
    <div
      v-if="taggedFeedItems.length > 0"
      class="d-flex flex-wrap">
      <feed-tag-header
        v-if="occasionTag"
        :tag="occasionTag"
        :looks="taggedFeedItemsCount"/>
      <feed-hash-tag-header
        v-else
        :tag="tag"
        :looks="taggedFeedItemsCount"
        :image="headerImage"/>
      <plain-button
        v-for="(item, index) in taggedFeedItems"
        :key="item.id"
        class="grid-item"
        :class="{ 'desktop': !isMobile, 'first-row': index < 3 }"
        @click="handleItemGridClick(item)">
        <profile-item-grid-image
          :item-image="item.image"
          in-viewport-root-margin="400px 0%"
          :in-viewport-once="true"
          @item-in-view="handleItemInView(index)"/>
      </plain-button>
      <div
        v-for="index in numFillerSquares"
        :key="index"
        :class="{ 'desktop': !isMobile }"
        class="grid-item placeholder"/>
    </div>
    <div
      v-else>
      <feed-item-placeholder/>
    </div>
  </community-nav-wrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ActionHeader from '../../global/sequin/ActionHeader'
import CommunityNavWrapper from '../common/CommunityNavWrapper'
import ProfileItemGridImage from '../profile/ProfileItemGridImage'
import PlainButton from '../../global/sequin/PlainButton'
import FeedHashTagHeader from './FeedHashTagHeader'
import FeedTagHeader from './FeedTagHeader'
import FeedItemPlaceholder from './FeedItemPlaceholder'

export default {
  name: 'FeedByTag',
  components: {
    ActionHeader,
    CommunityNavWrapper,
    FeedItemPlaceholder,
    FeedHashTagHeader,
    FeedTagHeader,
    ProfileItemGridImage,
    PlainButton
  },
  data () {
    return {
      numPlaceholderItems: 3
    }
  },
  computed: {
    ...mapState('community', [
      'feedItemsByTag',
      'occasionTags'
    ]),
    ...mapGetters('community', [
      'clientCanViewCommunity'
    ]),
    tag () {
      return this.$route.params.tag
    },
    tagId () {
      return this.$route.params.tagId
    },
    occasionTag () {
      const tags = this.occasionTags.filter(t => t.tag === this.tag)
      if (tags.length > 0) {
        return tags[0]
      }
      return null
    },
    taggedFeedItems () {
      if (this.feedItemsByTag && this.feedItemsByTag[this.tag]) {
        return this.feedItemsByTag[this.tag].results
      }
      return []
    },
    taggedFeedItemsCount () {
      if (this.feedItemsByTag && this.feedItemsByTag[this.tag]) {
        return this.feedItemsByTag[this.tag].count
      }
      return null
    },
    headerImage () {
      if (this.taggedFeedItemsCount > 0) {
        const item = this.taggedFeedItems[this.taggedFeedItems.length - 1]
        return item.image
      }
      return ''
    },
    numFillerSquares () {
      const numItems = this.taggedFeedItems.length
      if (numItems < this.numPlaceholderItems) {
        return this.numPlaceholderItems - numItems
      }
      return numItems % 3 > 0 ? 3 - numItems % 3 : 0
    }
  },
  mounted () {
    this.getTagFeedItems({
      tag: this.tag,
      tagId: this.tagId
    })
    if (this.occasionTags.length === 0) {
      this.getOccasionTags()
    }
  },
  methods: {
    ...mapActions('community', [
      'getTagFeedItems',
      'getTagFeedItemsNextPage',
      'getOccasionTags'
    ]),
    handleItemGridClick (item) {
      this.$router.push({ name: 'feed-item', params: { id: item.id } })
    },
    handleItemInView (index) {
      if (index === this.taggedFeedItems.length - 1) {
        this.getTagFeedItemsNextPage(this.tag)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-wrapper {
  margin: 0 -0.5px;
  overflow: hidden;
}

.grid-item {
  width: 33.3%;
  height: 33vw !important;
  padding: 1px .5px;

  &.first-row {
    padding-top: 0;
    border-top: none;
  }

  &.desktop {
    height: 166px !important;
  }

  &.placeholder {
    background: $origami;
    border: .5px solid $white;
    padding: 0;
  }

  &.animated {
    background: unset;
    @include placeholder-loading-animation;
  }

}
</style>
