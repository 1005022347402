<template>
  <SignUpScreen :scrollable="true">
    <div class="plan-choice-screen mx-auto">
      <h2 class="text-center mt-xs mb-sm mb-lg-xl">
        Your Recommended Plan
      </h2>
      <div
        class="plan-cards d-flex gap-sm align-items-center mx-auto"
        :class="{ 'flex-column': isTabletOrSmaller }">
        <PlanChoiceCard
          v-if="selectedPlanId"
          :plan-id="selectedPlanId"
          :selectable="false"
          :expanded="expandRecommendation"
          class="w-100"
          :class="`order-lg-${getPlanOrder(selectedPlanId)}`"/>
        <p
          v-if="isTabletOrSmaller"
          class="semi-bold">
          Additional Plans
        </p>
        <PlanChoiceCard
          v-for="plan in additionalPlans"
          :key="plan.id"
          :plan-id="plan.id"
          :class="[`order-lg-${getPlanOrder(plan.id)}`, { 'w-100': isTabletOrSmaller }]"/>
      </div>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import PlanChoiceCard from '@/components/signUp/PlanChoiceCard.vue'
import { computed, onMounted, ref } from 'vue'
import { useSubscriptionStore } from '@/stores/subscribe.js'
import { storeToRefs } from 'pinia'
import useScreenSize from '@shared/composables/screenSize.js'

const { isTabletOrSmaller } = useScreenSize()

const subscriptionStore = useSubscriptionStore()
const { membershipPlans, selectedPlanId } = storeToRefs(subscriptionStore)
const { fetchPlans, selectMembershipPlan } = subscriptionStore

const availablePlans = computed(() => membershipPlans.value?.filter(plan => !plan.prepayment && plan.basePrice > 0))
const additionalPlans = computed(() =>
  availablePlans.value?.filter(plan => plan.id !== selectedPlanId.value)
    .toSorted((a, b) => {
      /*
      Unlimited selected: 7-item, 4-item.
      7-item selected: Unlimited, 4-item.
      4-item selected: 7-item, Unlimited.

      This just happens to currently map to sorting by nItems descending.
      */
      return b.nItems - a.nItems
    })
)

function getPlanOrder (planId) {
  if (planId === selectedPlanId.value) {
    return 1 // Selected plan in the center (assuming 3 total)
  }
  const index = additionalPlans.value.findIndex(plan => plan.id === planId)
  if (index === 0) {
    return 0 // First additional plan on the left
  }
  return 2 // Remaining additional plans on the right
}

const unlimitedPlanId = 'XzpND94jx1'
onMounted(async () => {
  if (!availablePlans.value) {
    await fetchPlans()
  }
  if (!selectedPlanId.value) {
    await selectMembershipPlan({ planId: unlimitedPlanId })
  }
})

const expandRecommendation = ref(false)
const expandTimeout = ref(null)
onMounted(() => {
  if (expandTimeout.value) {
    clearTimeout(expandTimeout.value)
  }
  expandTimeout.value = setTimeout(() => {
    expandRecommendation.value = true
  }, 1200)
})
</script>

<style lang="scss" scoped>
.plan-choice-screen {
  max-width: 1075px;
}

@include media-tablet-or-smaller {
  .plan-cards {
    width: 330px;
  }
}
</style>
