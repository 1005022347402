<template>
  <style-game-intro
    :subhead="subheadText"
    title="New For You"
    button-text="Rate Styles"
    :styles-loaded="upcomingStylesToRate !== null"
    :intro-animation="upcomingStylesFavorite"
    :intro-image-source="introImage"
    :num-styles-to-rate="numStylesToRate"
    @start-game="$emit('start-game')">
    <template #description>
      <div
        v-if="upcomingStylesToRate && numStylesToRate === 0">
        There are no more new styles for you to rate today! Come back tomorrow to rate again.
        <div>
          <sequin-button
            variant="secondary"
            class="mt-4 mb-5"
            type="router-link"
            :to="{ name: 'closet-sections' }">
            View Closet
          </sequin-button>
        </div>
      </div>
      <div
        v-else
        class="text-center">
        New items in your <span class="semi-bold">{{ 'saved sizes' }}</span> are ready for you to rate{{ '!\n' }}
        Take a look and save what you love to Favorites.
      </div>
    </template>
  </style-game-intro>
</template>

<script>
import upcomingStylesFavorite from '../lottieAnimations/upcomingStylesFavorite.json'
import { mapState } from 'vuex'
import StyleGameIntro from './StyleGameIntro.vue'
import SequinButton from '../global/sequin/SequinButton.vue'
import { track } from '../../plugins/analytics'

export default {
  components: {
    SequinButton,
    StyleGameIntro
  },
  props: {
    prevPathName: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      upcomingStylesFavorite
    }
  },
  computed: {
    ...mapState('styleGames', [
      'upcomingStylesToRate',
      'upcomingStylesRatedIndex'
    ]),
    introImage () {
      return this.upcomingStylesToRate && this.upcomingStylesToRate.length > 0
        ? this.upcomingStylesToRate[this.upcomingStylesRatedIndex].images.front.url
        : null
    },
    numStylesToRate () {
      return this.upcomingStylesToRate
        ? this.upcomingStylesToRate.length - this.upcomingStylesRatedIndex
        : 0
    },
    subheadText () {
      return this.prevPathName === 'closet-sections' ? 'Member Exclusive' : 'Next Up'
    }
  },
  mounted () {
    track('Viewed Upcoming Styles - Intro')
  }
}
</script>
