<template>
  <div
    class="bg-origami py-3 border-top px-4">
    <profile-box
      full-width
      :centered="false"
      label="Clothing Rented"
      :value="`${profile.stats.itemsRented} Styles`">
      <span class="ms-1">|</span>
      <span class="ms-1 text-success">
        <b>
          {{ formatPrice(profile.stats.retailValueReceived, true, true) }}
        </b>
      </span>
    </profile-box>
    <profile-box
      class="mb-0"
      full-width
      :centered="false"
      label="Favorite Brand"
      :value="profile.stats.topBrands[0]"/>
    <div
      class="d-flex justify-content-between">
      <profile-box
        class="w-50"
        full-width
        :centered="false"
        label="Go-To Color"
        value="">
        <span :style="`color: ${topColorHex}`">
          <b>{{ topColor }}</b>
        </span>
      </profile-box>
      <profile-box
        class="w-50 ms-4"
        full-width
        :centered="false"
        label="Favorite Pattern"
        :value="topPattern"/>
    </div>
  </div>
</template>

<script>
import ProfileBox from './ProfileBox'
import { formatPrice } from '@/utils/stringParsing.js'

export default {
  components: {
    ProfileBox
  },
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  computed: {
    topColor () {
      if (this.profile && this.profile.stats && this.profile.stats.topColors.length > 0) {
        return this.profile.stats.topColors[0].name
      }
      return '---'
    },
    topColorHex () {
      if (this.profile && this.profile.stats && this.profile.stats.topColors.length > 0) {
        if (this.profile.stats.topColors[0].color === '#ffffff') {
          return '#585b65' // show pewter instead of white, so it's legible
        }
        return this.profile.stats.topColors[0].color
      }
      return ''
    },
    topPattern () {
      if (this.profile && this.profile.stats && this.profile.stats.topPatterns.length > 0) {
        return this.profile.stats.topPatterns[0].name
      }
      return '---'
    }
  },
  methods: {
    formatPrice
  }
}

</script>
