<template>
  <div
    :class="{
      'disabled': disabled,
      'justify-content-start p-0': variant === 'worn-to',
      'reverse': variant === 'reverse'
    }"
    class="occasion-tag">
    <div
      class="d-flex align-items-center inner-container"
      :class="{'inner-padding': innerPadding}">
      <div
        class="icon-background me-2"
        :class="{'reverse': variant === 'reverse'}">
        <img
          v-if="tag.iconUrl"
          class="icon"
          :src="getIconImage(tag.iconUrl)">
      </div>
      <div
        v-if="variant === 'worn-to'"
        class="ps-2">
        <div class="text-gray small-text">
          Worn to
        </div>
        {{ tag.tag }}
      </div>
      <h5
        v-else
        class="m-0 name">
        {{ tag.tag }}
      </h5>
      <div
        class="remove-wrapper">
        <plain-button
          class="m-0 p-0"
          :disabled="disabled"
          @click="$emit('remove-click')">
          <svg-x
            v-if="showRemove"
            class="remove"
            :class="{'reverse': variant === 'reverse'}"/>
        </plain-button>
      </div>
    </div>
  </div>
</template>

<script>
import PlainButton from '../../global/sequin/PlainButton'
import SvgX from '@/components/global/svg/SvgX.vue'

export default {
  components: {
    PlainButton,
    SvgX
  },
  props: {
    tag: {
      type: Object,
      required: true
    },
    showRemove: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    innerPadding: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'default',
      validator: value => {
        return value.match(/(default|worn-to|reverse)/)
      }
    }
  },
  methods: {
    getIconImage (imageName) {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}system-tags/${imageName}`
    }
  }
}
</script>

<style lang="scss" scoped>

.inner-container {
  height: 100%;
}

.inner-padding {
  padding: 0 15px;
}

.occasion-tag {
  height: 64px;
  width: 100%;
  padding: 0px 20px;
  color: $plum;
  background-color: $white;
  font-weight: $font-weight-semibold;
  text-transform: capitalize;

  &.disabled {
    opacity: .7;
  }

  &.reverse {
    background-color: $origami;
  }

  .name {
    width: calc(100% - 96px);
  }

  .remove-wrapper {
    width: 28px;
  }

  .icon-background {
    background-color: $origami;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.reverse {
      background-color: $white;
    }

    .icon {
      // height: 20px;
      width: 24px;
      object-fit: contain;
    }

  }
}

.remove {
  color: $ash;
  background-color: white;

  &.reverse {
    background-color: $origami;
  }
}
</style>
