<template>
  <div
    class="text-center pt-3 pt-md-5 bg-white">
    <h1 class="text-secondary">
      Account
    </h1>
    <div class="row">
      <div class="col-md-8 mx-auto">
        <div
          v-if="showPrepayAd"
          class="text-start">
          <prepay-ad/>
        </div>
        <transition-group name="fade">
          <base-alert
            v-if="showAlert"
            key="alert"/>
          <div
            key="accountsections"
            class="default-animated">
            <div
              v-if="notificationsEnabled !== null"
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Notifications
              </h4>
              <div class="d-flex justify-content-between align-items-center bg-white p-3">
                <div class="box-content">
                  <div class="semi-bold">
                    Your account notifications are <span v-if="notificationsEnabled">
                      ON</span><span v-else>OFF</span> for this device
                  </div>
                  <div>
                    <span v-if="notificationsEnabled">We'll</span><span v-else>We won't</span>
                    send you important shipping, delivery and closet updates<span v-if="membershipDetails.allowedShipments !== null">
                      <span v-if="notificationsEnabled"> and will</span><span v-else> or</span>
                      notify you when you're eligible for a new case</span>.
                  </div>
                </div>
                <div>
                  <button
                    class="btn btn-primary"
                    @click="onSubscribeClick()">
                    Turn <span v-if="notificationsEnabled">
                      Off
                    </span>
                    <span v-else>On</span>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Notification Settings
              </h4>
              <div class="d-flex justify-content-between bg-white p-3">
                <div>
                  Change your settings for Membership, PWR Feed, or Armoire Announcement Notifications
                </div>
                <div>
                  <sequin-button
                    @click="onNotificationsSettingsClick()">
                    Change
                  </sequin-button>
                </div>
              </div>
            </div>
            <div
              v-if="pickupLocationKiosks && pickupLocationKiosks.length && hasRentalPlan"
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Armoire Go
              </h4>
              <div class="d-flex justify-content-between bg-white p-3">
                <div>
                  Sign in to an Armoire Go location
                </div>
                <div>
                  <sequin-button
                    type="router-link"
                    :to="{ name: 'boutique-checkin' }">
                    Armoire Go Sign In
                  </sequin-button>
                </div>
              </div>
            </div>
            <div
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Membership
              </h4>
              <div class="d-flex justify-content-between bg-white p-3">
                <plan-status-display
                  class="pe-2"/>
                <div>
                  <sequin-button
                    v-if="membershipStatus !== 'unpaid'"
                    @click="onMembershipClick()">
                    {{ membershipButtonText }}
                  </sequin-button>
                </div>
              </div>
            </div>
            <div
              v-if="membershipStatus !== 'former_member'"
              ref="billingSection"
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Billing
              </h4>
              <div class="d-flex justify-content-between bg-white p-3">
                <div>
                  <default-payment
                    :show-link="false"
                    :inline-edit="false"/>
                  <div>
                    Your last payment: {{ formatPrice(lastCharge) }}
                  </div>
                  <div v-if="creditBalance">
                    Your credits: {{ formatPrice(creditBalance) }}
                  </div>
                  <div v-if="showBillingDate">
                    Your next billing date: {{ toLocaleDateString(nextBillingDate) }}
                  </div>
                </div>
                <div>
                  <sequin-button
                    @click="openModal({ 'name': 'update-credit-card' })">
                    <span v-if="defaultPayment">
                      Change
                    </span>
                    <span v-else>
                      Add Card
                    </span>
                  </sequin-button>
                </div>
              </div>
              <div
                v-if="paymentIsExpired || paymentSoonToExpire"
                class="cc-warning p-3 d-flex align-items-center mt-3"
                :class="paymentIsExpired ? 'bg-orchid text-white' : 'bg-white text-orchid'">
                <svg-discover
                  :width="isMobile ? 35 : 25"
                  :height="isMobile ? 35 : 25"
                  :fill="paymentIsExpired ? 'white' : '#850D54'"
                  :stroke="paymentIsExpired ? 'white' : '#850D54'"
                  class="me-3"/>
                <h6 class="mb-0">
                  <span v-if="paymentIsExpired">
                    Your credit card has expired.
                  </span>
                  <span v-else>
                    Your credit card expires this month.
                  </span>
                  <span
                    :class="{'whitespace-no-wrap' : !isMobile}"
                    class="font-weight-normal">
                    Update your card to avoid disruptions.
                  </span>
                </h6>
              </div>
            </div>
            <div
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Shipping + Returns
              </h4>
              <app-message
                v-if="!canChangeShipping"
                variant="info">
                You currently have a Case in transit and cannot make updates to your shipping preferences until it
                arrives.
                <text-link
                  class="text-center"
                  variant="info"
                  @click="contactSupport">
                  Contact support
                </text-link> with any questions.
              </app-message>
              <div class="d-flex justify-content-between bg-white pt-3 pb-3">
                <pickup-address
                  v-if="pickupAddress"
                  class="ps-3"
                  :show-pick="true"
                  :show-label="true"
                  :pickup-address="pickupAddress"/>
                <address-wrapper
                  v-else-if="shippingAddress"
                  class="ps-3"
                  :address="shippingAddress"/>
                <span v-else>
                  Please add a shipping address or select a pickup location.
                </span>
                <div class="pe-3">
                  <sequin-button
                    v-if="canChangeShipping"
                    type="router-link"
                    :to="{ name: 'update-address' }">
                    Change
                  </sequin-button>
                </div>
              </div>
              <div>
                <change-replacement-items
                  v-if="selectedReplacementChoice"
                  :title="replacementItemSettingsTitle"
                  :subtitle="replacementItemSettingsSubtitle"/>
              </div>
              <div
                v-if="!shippedPackages"
                class="flex-row justify-content-between bg-white border-top px-1 pt-3 ps-3 pb-2">
                <base-radios
                  v-model="packagingChoice"
                  :options="packagingOptions"
                  label="Packaging Preferences"
                  sequin
                  @update:modelValue="updatePackagingChoice()"/>
                <div class="d-flex justify-content-between p-1 mt-2 small-text">
                  When you select minimal packaging, we'll minimize the packing
                  materials for your case. No matter which option you chose, your
                  clothes will always arrive in great shape.
                </div>
              </div>
              <div class="flex-row justify-content-between bg-white border-top px-1 pt-3 ps-3 pb-2">
                <base-radios
                  v-model="carrierChecked"
                  :options="carrierOptions"
                  label="Return packages via:"
                  sequin
                  @update:modelValue="updateReturnCarrierPreference()"/>
              </div>
              <accordion-with-header
                v-if="showDropOff || isFedEx || isUSPS"
                :expanded="fedexPickupExpanded"
                :expanded-max-height="1600"
                class=""
                :title="`${isFedEx || isUSPS ? 'Schedule Free Return Pickups + ' : ''}Drop Off Information`"
                border
                @accordion-click="toggleFedExPickupExpanded">
                <fed-ex-pickup
                  v-if="isFedEx"
                  :first-name="firstName"
                  :last-name="lastName"
                  :phone="mainPhone"
                  :address="shippingAddress"
                  :pickup-instructions="pickupInstructions"/>
                <div
                  v-if="isUSPS">
                  If you have a USPS return label, you can
                  <text-link
                    type="a"
                    size="paragraph"
                    :href="USPS_PICKUP_URL"
                    target="_blank">
                    schedule a free USPS returns pickup
                  </text-link> from anywhere in the U.S.
                </div>
                <pickup-locations
                  v-if="showDropOff"
                  class="mt-3"/>
              </accordion-with-header>
              <returns-tracking
                :expanded="returnsTrackingExpanded"
                :class="{'border-top-0' : carrierChecked === 'FEDEX'}"
                @accordion-click="toggleReturnsTrackingExpanded"/>
              <div
                class="returns-item d-flex justify-content-between align-items-center">
                <h5>Lost Your Return Label?</h5>
                <sequin-button
                  v-if="carrierChecked !== 'FEDEX'"
                  @click="contactSupport">
                  Contact Support
                </sequin-button>
                <sequin-button
                  v-else
                  type="a"
                  target="_blank"
                  :href="printReturnLabelLink">
                  Print a New One
                </sequin-button>
              </div>
              <div
                class="returns-item">
                <h5>
                  Still have questions? View the full <text-link
                    type="a"
                    size="paragraph"
                    :href="FAQ_URL"
                    target="_blank">
                    FAQ
                  </text-link> or <text-link
                    size="paragraph"
                    @click="contactSupport">
                    Contact support
                  </text-link>.
                </h5>
              </div>
            </div>
            <div
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Account
              </h4>
              <div class="d-flex justify-content-between bg-white p-3">
                Password: ******
                <sequin-button
                  type="a"
                  href="/password_change/">
                  Change
                </sequin-button>
              </div>
              <div
                ref="deleteAccountSection"
                class="d-flex justify-content-between bg-white">
                <div class="w-100">
                  <accordion-with-header
                    :expanded="deleteExpanded"
                    :expanded-max-height="300"
                    class="delete-account"
                    title="delete account"
                    :border="false"
                    @accordion-click="toggleDeleteExpanded">
                    <div
                      class="d-flex justify-content-between">
                      <div>
                        <p
                          v-if="membershipStatus === 'active'">
                          Please <text-link
                            size="paragraph"
                            type="router-link"
                            :to="{ name: 'cancel'}">
                            cancel your membership
                          </text-link> before deleting your account.
                        </p>
                        <p
                          v-else-if="showCancellationPendingMessage">
                          Your cancellation needs to be complete before deleting your account.
                        </p>
                        <p
                          v-else-if="showPausedMessaging">
                          You must be unpaused and canceled before deleting your account.
                        </p>
                        <p
                          v-else-if="showUnpaidMessaging">
                          Your account must be current to delete your account
                        </p>
                        <p v-else>
                          Please confirm you would like to delete your Armoire account.
                        </p>
                      </div>
                      <sequin-button
                        v-if="showDeleteAccountButton"
                        variant="danger"
                        @click="handleDeleteAccountLink">
                        Delete account
                      </sequin-button>
                    </div>
                  </accordion-with-header>
                </div>
              </div>
            </div>
            <div
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Community Profile
              </h4>
              <div class="d-flex justify-content-between bg-white p-3">
                View and manage your community profile and the Looks that you've shared
                <sequin-button
                  type="router-link"
                  :to="{ name: 'profile', params: { id } }">
                  View
                </sequin-button>
              </div>
            </div>
            <div
              v-if="hasReviewPhotos"
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Photos
              </h4>
              <div class="d-flex justify-content-between bg-white p-3">
                View and manage the photos you've shared
                <sequin-button
                  type="a"
                  href="/account/photos/">
                  Manage
                </sequin-button>
              </div>
            </div>
            <div
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Gift Cards
              </h4>
              <div class="d-flex justify-content-between bg-white p-3">
                <div>Check your gift card balances and more</div>
                <BaseButton
                  class="ms-3"
                  text="View"
                  @click="onViewGiftCardsClick"/>
              </div>
            </div>
            <div
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                My Rewards
              </h4>
              <div class="d-flex justify-content-between bg-white p-3">
                <div>Track your Armoire rewards</div>
                <sequin-button
                  type="router-link"
                  class="ms-3"
                  :to="{ name: 'rewards' }">
                  View
                </sequin-button>
              </div>
            </div>
            <div
              v-if="retailValueReceived"
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                Rental History
              </h4>
              <div class="d-flex justify-content-between bg-white p-3">
                <retail-value/>
              </div>
            </div>
            <div
              v-if="!employee"
              class="text-start pt-5">
              <h4 class="group-header ps-3">
                My Referrals
              </h4>
              <div class="d-flex justify-content-between align-items-center bg-white p-3">
                Track your Armoire referrals and credits
                <sequin-button
                  class="ms-3"
                  type="router-link"
                  :to="{ name: 'referral' }">
                  View
                </sequin-button>
              </div>
            </div>
          </div>
        </transition-group>
        <div
          class="mb-5">
          <!-- provides some bottom of page padding -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions as mapVuexActions, mapGetters, mapState } from 'vuex'
import { mapActions } from 'pinia'
import AddressWrapper from '../global/address/AddressWrapper'
import DefaultPayment from '../global/stripe/DefaultPayment'
import PickupAddress from '../global/address/PickupAddress'
import PickupLocations from './returns/PickupLocations'
import PlanStatusDisplay from './PlanStatusDisplay'
import PrepayAd from './PrepayAd'
import RetailValue from './RetailValue'
import BaseRadios from '../global/BaseRadios'
import BaseAlert from '@/components/global/BaseAlert.vue'
import AppMessage from '../global/sequin/AppMessage'
import SequinButton from '../global/sequin/SequinButton'
import BaseButton from '@shared/components/ADORN/BaseButton'
import { toLocaleDateString, formatPrice } from '@/utils/stringParsing.js'
import TextLink from '../global/sequin/TextLink'
import ReplacementMixin from '../account/replacements/ReplacementMixin'
import ChangeReplacementItems from './ChangeReplacementItems'
import AccordionWithHeader from '../global/sequin/AccordionWithHeader.vue'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgDiscover from '../global/svg/SvgDiscover.vue'
import FedExPickup from './FedExPickup'
import ReturnsTracking from './returns/ReturnsTracking.vue'
import ReturnsMixin from './returns/ReturnsMixin.js'
import ShippingAddressMixin from './ShippingAddressMixin'

export default {
  components: {
    AddressWrapper,
    BaseButton,
    DefaultPayment,
    FedExPickup,
    PickupAddress,
    PickupLocations,
    PlanStatusDisplay,
    PrepayAd,
    RetailValue,
    SequinButton,
    TextLink,
    BaseRadios,
    BaseAlert,
    AppMessage,
    ChangeReplacementItems,
    AccordionWithHeader,
    SvgDiscover,
    ReturnsTracking
  },
  mixins: [ShippingAddressMixin, ReplacementMixin, ReturnsMixin],
  data () {
    return {
      notificationsEnabled: null,
      packagingChoice: 'standard',
      packagingOptions: [
        { text: 'Minimal', value: 'minimal' },
        { text: 'Standard', value: 'standard' }],
      carrierChecked: null,
      carrierOptions: [
        { text: 'FedEx', value: 'FEDEX' },
        { text: 'USPS', value: 'USPS' },
        { text: 'UPS', value: 'UPS' }
      ],
      deleteExpanded: false,
      fedexPickupExpanded: false,
      returnsTrackingExpanded: false
    }
  },
  computed: {
    ...mapState('client', [
      'id',
      'defaultPayment',
      'shippingAddress',
      'lastCharge',
      'pickupAddress',
      'plans',
      'membershipStatus',
      'membershipDetails',
      'nextBillingDate',
      'canChangeShipping',
      'shippedPackages',
      'hasReviewPhotos',
      'packagingPreference',
      'retailValueReceived',
      'returnCarrierPreference',
      'username',
      'firstName',
      'lastName',
      'mainPhone',
      'pickupInstructions'
    ]),
    ...mapState('global', [
      'showAlert'
    ]),
    ...mapGetters('client', [
      'creditBalance',
      'employee',
      'hasRentalPlan',
      'itemsInTransit',
      'paymentIsExpired',
      'paymentSoonToExpire'
    ]),
    ...mapGetters('account', [
      'pickupLocationKiosks',
      'localPickupLocations'
    ]),
    ...mapState('account', [
      'returnLabelLink'
    ]),
    isFedEx () {
      return this.carrierChecked === 'FEDEX'
    },
    isUSPS () {
      return this.carrierChecked === 'USPS'
    },
    showDropOff () {
      return this.localPickupLocations.length > 0
    },
    showPrepayAd () {
      return this.membershipStatus !== 'paused' &&
        this.plans && this.plans.currentBasePlan &&
        this.plans.currentBasePlan.name === 'Armoire Monthly'
    },
    showBillingDate () {
      return this.membershipStatus === 'active' &&
        this.plans?.currentBasePlan?.basePrice > 0
    },
    membershipButtonText () {
      if (this.membershipStatus === 'non_member' || this.membershipStatus === 'former_member') {
        return 'Subscribe'
      }
      return 'Change'
    },
    showDeleteAccountButton () {
      return ['non_member', 'former_member'].includes(this.membershipStatus)
    },
    showPausedMessaging () {
      return this.membershipStatus === 'paused'
    },
    showUnpaidMessaging () {
      return this.membershipStatus === 'unpaid'
    },
    showCancellationPendingMessage () {
      return this.membershipDetails.scheduledCancellationDate || this.membershipStatus === 'items_out_pending_cancel'
    }
  },
  mounted: function () {
    // account for custom scroll to section router paths

    if (this.$route.name === 'account-billing') {
      setTimeout(() => {
        this.scrollToSection(this.$refs.billingSection)
      }, 500)
    }
    if (this.$route.name === 'delete-account') {
      setTimeout(() => {
        this.scrollToSection(this.$refs.deleteAccountSection)
      }, 500)
    }
    this.getPickupLocations()
    this.packagingChoice = this.packagingPreference
    this.carrierChecked = this.returnCarrierPreference
    if (!this.employee) {
      this.getReferrals()
      this.getBilling()
    }
    const outsideThis = this
    const OneSignal = window.OneSignal || []
    OneSignal.push(function () {
      OneSignal.init({
        appId: `${process.env.VUE_APP_ONESIGNAL_APP_ID}`,
        notifyButton: {
          enable: false
        }
      })
      if (OneSignal.isPushNotificationsSupported()) {
        OneSignal.isPushNotificationsEnabled(function (isEnabled) {
          outsideThis.notificationsEnabled = isEnabled
        })
      }
    })
    OneSignal.push(function () {
      OneSignal.on('notificationDisplay', function (event) {
        if (!Object.prototype.hasOwnProperty.call(event, 'data') ||
            !Object.prototype.hasOwnProperty.call(event.data, 'id')) {
          outsideThis.addNotification({
            message: event.content,
            title: event.heading,
            image: event.icon,
            url: event.url
          })
        }
      })
    })
  },
  methods: {
    ...mapVuexActions('account', [
      'updateClient',
      'getPickupLocations',
      'deleteAccount'
    ]),
    ...mapVuexActions('client', [
      'getBilling',
      'getReferrals'
    ]),
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    ...mapVuexActions('global', [
      'setAlert'
    ]),
    ...mapVuexActions('notifications', [
      'addNotification'
    ]),
    toLocaleDateString,
    formatPrice,
    onViewGiftCardsClick () {
      this.$router.push({ name: 'gift-cards' })
    },
    onSubscribeClick () {
      /* eslint-disable */
      let outsideThis = this
      if (this.notificationsEnabled) {
        OneSignal.push(() => {
        	OneSignal.setSubscription(false)
          OneSignal.sendSelfNotification(
            'Armoire',
            	'Notifications are now turned off.',
            'https://www.armoire.style/home/',
            `${process.env.VUE_APP_WEBSITE_IMAGES_URL}dress-small.png`
          )
        })
        outsideThis.notificationsEnabled = false
      } else {
        OneSignal.push(() => {
        	OneSignal.registerForPushNotifications()
        	OneSignal.setSubscription(true)
          // sendSelfNotification throws an error (can't send to not subscribed)
          new window.Notification('Armoire', {
            body: `You will receive notifications from Armoire.`,
            icon: `${process.env.VUE_APP_WEBSITE_IMAGES_URL}dress-small.png`
          })
          outsideThis.notificationsEnabled = true
          OneSignal.setExternalUserId(outsideThis.id)
        })
      }
    },
    onMembershipClick () {
      if (this.membershipStatus === 'non_member' || this.membershipStatus === 'former_member') {
        this.$router.push({ name: 'select-plan' })
      } else {
        this.$router.push({ name: 'membership-overview' })
      }
    },
    onNotificationsSettingsClick () {
      this.$router.push({ path: '/account/notifications' })
    },
    contactSupport () {
      window.Intercom('showNewMessage')
    },
    async updatePackagingChoice () {
      try {
        await this.updateClient({
          'packagingPreference': this.packagingChoice
        })
        this.setAlert({ msg: 'Your packaging choice has been updated.' })
      } catch (errors) {
        this.setAlert({
          msg: 'There was a problem updating your packaging choice.',
          style: 'warning'
        })
      }
    },
    async updateReturnCarrierPreference () {
      try {
        await this.updateClient({
          'returnCarrierPreference': this.carrierChecked
        })
        this.setAlert({ msg: 'Your return carrier preference has been set.' })
      } catch (err) {
        this.setAlert({
          msg: 'There was a problem setting your return carrier preference.',
          style: 'warning'
        })
      }
    },
    scrollToSection (section) {
      section.scrollIntoView({ block: 'start', behavior: 'smooth' })
    },
    toggleDeleteExpanded () {
      this.deleteExpanded = !this.deleteExpanded
    },
    toggleFedExPickupExpanded () {
      this.fedexPickupExpanded = !this.fedexPickupExpanded
    },
    toggleReturnsTrackingExpanded () {
      this.returnsTrackingExpanded = !this.returnsTrackingExpanded
    },
    handleDeleteAccountLink () {
      this.openModal({ 'name': 'delete-account' })
    }
  }
}
</script>

<style lang="scss" scoped>

.box-content {
  max-width: 70%;
}

.group-header {
  color: $info;
}

.cc-warning h6{
  font-size: 14px !important;
}

// This is not global because it messes up the centering of the div as it
// fades out in the style profile during signup.  However, it's needed here
// to ensure the elements in the transition group below the alert smoothly
// animate up
.fade-leave-active {
  position: absolute;
  width: 100%;
}

.group-header {
  border-bottom: $ash solid 1px;
  padding-bottom: 12px;
}

.returns-item {
  width: 100%;
  padding: 16px 16px;
}


</style>
