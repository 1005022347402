<template>
  <IconButton
    :title="name"
    @click="onClick">
    <template #icon>
      <slot/>
    </template>
  </IconButton>
</template>

<script setup>
import IconButton from '@shared/components/ADORN/IconButton.vue'
import useAnalytics from '@shared/composables/analytics.js'

const emit = defineEmits(['click'])

const props = defineProps({
  name: {
    type: String,
    required: true
  }
})

const { track } = useAnalytics()
const onClick = () => {
  track('Clicked third party signup', { buttonName: props.name })
  emit('click')
}

</script>
