<template>
  <div
    :style="cssVars"
    class="pivot"
    :class="{ 'centered': centered, 'mobile': isMobile, 'full-width': fullWidth }">
    <button
      v-for="(tab, index) in tabs"
      :key="tab.id"
      :ref="`tab-${index}`"
      class="tab small-text"
      :class="`${variant} ${tabSelectedDisplay === index ? 'selected': ''}`"
      :data-intercom-target="tab.intercomId"
      @click="onTabClick(index)">
      <div class="tab-slot d-flex align-items-center">
        {{ tab.name }} <slot :name="`${tab.id}-slot`"/>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SequinPivot',
  props: {
    // Array of tabs, each containing an id and name, which are strings
    tabs: {
      type: Array,
      required: true
    },
    // The styling variant: primary (default), secondary, or danger
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/(primary|secondary)/)
      }
    },
    // optionally, supply the index of the tab that is currently selected
    tabSelected: {
      type: Number,
      default: null
    },
    centered: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tabSelectedPrivate: 0
    }
  },
  computed: {
    tabSelectedDisplay () {
      return this.tabSelected !== null ? this.tabSelected : this.tabSelectedPrivate
    },
    cssVars () {
      return {
        '--tab-length': this.tabs.length
      }
    },
    cssVariant () {
      if (this.variant !== 'primary') {
        return `${this.variant}`
      }
      return ''
    },
    tabWidths () {
      return this.tabs.map((_, index) => {
        const ref = this.$refs['tab-' + index][0]
        return ref.clientWidth
      })
    },
    tabOffsets () {
      return this.tabWidths.map((_, index) => {
        return this.tabWidths.slice(0, index).reduce((prev, curr) => prev + curr, 0)
      })
    }
  },
  methods: {
    onTabClick (tabIndex) {
      this.tabSelectedPrivate = tabIndex
      this.$emit('select-tab', { id: this.tabs[tabIndex].id, offset: this.tabOffsets[tabIndex] })
      this.$refs['tab-' + tabIndex][0].blur()
    }
  }
}
</script>

<style lang="scss" scoped>
.pivot {
  display: flex;
  background: $white;

  &.centered {
    justify-content: center;
  }

  &.full-width {
    width: 100%;
    align-items: center;

    .tab {
      width: calc(100% / var(--tab-length));
      margin: 0;
    }
  }
}

.tab {
  background-color: $white;
  border: none;
  font-weight: $font-weight-semibold;
  color: $ash;
  border-bottom: 4px solid $white;
  margin: 0;
  padding: 4px 12px;
  line-height: 36px;

  &.selected {
    border-bottom: 4px solid $orchid;
    color: $armor;
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    -webkit-box-shadow: 0 0 0 0.2rem rgba($ash, 0.5);
    box-shadow: 0 0 0 0.2rem rgba($ash, 0.5);
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
}

.tab-slot {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.secondary {
  background-color: rgba($origami, .3);
  border-top: $default-border;
  border-bottom: 4px solid rgba($origami, .3);
}

</style>
