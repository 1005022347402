import axios from './config'
import { serializeParams } from './helpers/helpers'

const apiBrands = {
  getBrand (id) {
    return axios.get(`/api/brands/${id}/`)
  },
  getTopBrands () {
    return axios.get('/api/brands/?top_brands=true')
  },
  getStyleColors (data) {
    let params = `?offset=${data.offset}&amount=${data.amount}`
    if (data.available === true) params += `&available=${data.available}`
    if ('filters' in data) params += '&' + serializeParams(data.filters)
    if ('sizeFilter' in data) params += `&sizeFilter=${data.sizeFilter}`
    return axios.get(`/api/brands/${data.brandId}/style_colors/${params}`)
  },
  searchBrands (query, n = 2) {
    return axios.get(`/api/brands/?q=${query}&n=${n}`)
  }
}

export default apiBrands
