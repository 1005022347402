<template>
  <div class="sign-up-page-header d-flex flex-column gap-xxs">
    <div class="header-bar px-md py-xs p-lg-lg align-items-center">
      <div
        class="d-flex justify-content-start align-items-center color-plum gap-xxs gap-md-md"
        style="height: 32px">
        <BackButton :allow-fallback="true"/>
        <ArmoireLogo
          class="logo"
          :height="isMobile ? 18 : 24"/>
      </div>
      <div>
        <SignUpProgressBar v-if="!isMobile"/>
      </div>
      <div class="d-flex justify-content-end">
        <Transition
          name="fade"
          mode="out-in">
          <BaseButton
            v-if="currentScreen.canSkip"
            text="Skip"
            variant="secondary"
            :to="nextScreenRouterLink"
            class="skip"/>
          <LoginLink
            v-else-if="showLoginButton"
            app-entry-point="signup"
            event-label="Navbar Login"
            class="text-button text-uppercase color-armor"/>
        </Transition>
      </div>
    </div>
    <SignUpProgressBar
      v-if="isMobile"
      class="px-md"/>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import useScreenSize from '@shared/composables/screenSize.js'
import SignUpProgressBar from '@/components/signUp/SignUpProgressBar.vue'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import LoginLink from '@shared/components/LoginLink.vue'
import ArmoireLogo from '@shared/components/ArmoireLogo.vue'
import BackButton from '@shared/components/ADORN/BackButton.vue'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { storeToRefs } from 'pinia'

const { currentScreen, nextScreenRouterLink } = storeToRefs(useSignUpNavigationStore())

const route = useRoute()
const { isMobile } = useScreenSize()
const showLoginButton = computed(() =>
  [
    'style-quiz-intro', 'style-quiz-create-account', // Phase 1
    'sign-up-onboarding', 'sign-up-create-account'
  ].includes(route.name)
)
</script>

<style scoped lang="scss">
.sign-up-page-header {
  .header-bar {
    display: grid;
    grid-template-columns: auto 1fr auto;
    border-bottom: 1px solid $velvet;
    background-color: $origami;

    @include transition-base-all;

    @include media-tablet-or-larger {
      grid-template-columns: repeat(3, 1fr);
    }

    --logo-transform: #{$spacing-xxs};
    @include media-tablet-or-larger {
      --logo-transform: #{$spacing-md};
    }
    .logo {
      @include transition-base-all;
      position: relative;
      right: calc(var(--logo-transform) * -1);
      transform: translateX(calc(var(--logo-transform) * -1));
    }
    .back-button + .logo {
      left: calc(var(--logo-transform) * -1);
      transform: translateX(var(--logo-transform));
    }

    .back-button {
      animation: scale-up $transition-base-duration $transition-base-function;

      @keyframes scale-up {
        0% {
          transform: scale(0);
          opacity: 0;
        }
        100% {
          transform: scale(1);
          opacity: 1;
        }
      }
    }

    .skip {
      height: $spacing-xl;
    }
  }
}
</style>
