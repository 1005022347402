<template>
  <div>
    <div
      v-if="!isMobile"
      class="d-flex align-top justify-content-between">
      <h6>
        Fit Details
      </h6>
      <sequin-button
        v-if="sizeGuideUrl"
        class="py-2 px-2"
        variant="secondary"
        small
        @click="goToSizeChart(sizeGuideUrl)">
        <svg-tape-measure
          height="14"
          width="14"
          class="me-2 mb-1"/>
        Size Chart
      </sequin-button>
    </div>
    <div
      v-if="numSizingRatings">
      <div class="small-text">
        <span class="semi-bold">Fit: </span>
        <span>Based on {{ numSizingRatings }} review{{ numSizingRatings > 1 ? 's' : '' }}</span>
        <span v-if="sizingBiasRecommendation !== null"> we suggest <span class="semi-bold">sizing {{ sizingBiasRecommendation }}</span></span>
      </div>
      <sizing-rating :rating="avgSizingRating"/>
    </div>
  </div>
</template>

<script>
import SizingRating from '../global/sequin/SizingRating'
import SequinButton from '../global/sequin/SequinButton.vue'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'
import SvgTapeMeasure from '@/components/global/svg/SvgTapeMeasure.vue'

export default {
  name: 'ClosetSizingRating',
  components: {
    SizingRating,
    SequinButton,
    SvgTapeMeasure
  },
  mixins: [StyleColorMixin],
  props: {
    avgSizingRating: {
      type: Number,
      default: 0
    },
    numSizingRatings: {
      type: Number,
      default: 0
    },
    sizingBiasRecommendation: {
      type: [String, null],
      default: null
    },
    styleColor: {
      type: Object,
      required: true
    }
  },
  methods: {
    goToSizeChart (sizeGuideUrl) {
      window.open(sizeGuideUrl, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
