<template>
  <component
    :is="tag"
    ref="element"
    :title="tooltip"
    class="character-limit">
    {{ truncatedText }}
  </component>
</template>

<script setup>
import { computed, ref } from 'vue'
import { truncate } from '@/utils/stringParsing.js'

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  characterLimit: {
    type: [Number, String],
    required: true,
    validator: value => value === 'none' || Number(value) > 0
  },
  tag: {
    type: String,
    default: 'p',
    validator: value => ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div'].includes(value)
  },
  allowTooltip: {
    type: Boolean,
    default: true
  }
})

const element = ref(null)

const truncatedText = computed(() =>
  typeof props.characterLimit === 'number'
    ? props.text
      ? truncate(props.text, props.characterLimit, '...')
      : ''
    : props.text
)
const isTextTruncated = computed(() => truncatedText.value !== props.text)
const tooltip = computed(() => props.allowTooltip && isTextTruncated.value ? props.text : null)
</script>
