<template>
  <div
    class="background small-text"
    :class="variant">
    <svg-check
      v-if="variant === 'complete'"
      height="12"
      width="12"
      stroke-width="3"/>
    <span v-else>{{ step }}</span>
  </div>
</template>

<script>
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    SvgCheck
  },
  props: {
    variant: {
      type: String,
      default: 'to-do',
      validator: value => {
        return value.match(/(to-do|in-progress|complete)/)
      }
    },
    step: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.background {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 1px solid $ash;
  display: flex;
  justify-content: center;
  align-items: center;

  &.complete {
    background-color: $velvet;
    border-color: $velvet;
    color: $white;
  }

  &.to-do {
    border-color: $ash;
    color: $ash;
  }

  &.in-progress {
    border-color: $armor;
    color: $armor;
  }
}
</style>
