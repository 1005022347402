<template>
  <div>
    <error-message v-if="saveError">
      {{ errorMessage }}
    </error-message>
    <div
      class="pb-4 bg-white">
      <div class="p-3">
        <avatar-image
          :client-id="id"
          size="x-large"
          class="mx-auto my-2"
          :profile-pic="editProfileUpdates.profilePic"/>
        <text-link
          :disabled="disabled"
          size="paragraph"
          class="mx-auto d-block my-2 "
          @click="$emit('edit-photo-click')">
          Edit
        </text-link>
      </div>
      <div class="edit-item">
        <div class="label">
          Name
        </div>
        <p class="m-0">
          {{ firstName }} {{ lastName }}
        </p>
      </div>
      <div class="edit-item">
        <div class="label d-flex justify-content-between">
          Location
          <character-remaining-warning :chars-remaining="locationCharsRemaining"/>
        </div>
        <textarea
          v-model="localLocation"
          :disabled="disabled"
          class="profile-input"
          rows="1"
          placeholder="e.g. Seattle, WA"
          @keyup="debouncedCommitLocation"/>
      </div>
      <div class="edit-item">
        <div class="label d-flex justify-content-between">
          Bio
          <character-remaining-warning :chars-remaining="bioCharsRemaining"/>
        </div>
        <textarea
          v-model="localBio"
          :disabled="disabled"
          class="profile-input"
          rows="3"
          placeholder="Tell us about yourself..."
          @keyup="debouncedCommitBio"/>
      </div>
      <div
        v-for="section in Object.keys(profileSettings)"
        :key="section"
        class="edit-item">
        <div class="label mt-2">
          {{ section }}
        </div>
        <div
          v-for="setting in profileSettings[section]"
          :key="setting.id"
          class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <p class="privacy-setting">
              {{ setting.title }}
            </p>
            <single-select-row
              v-if="setting.type === 'radio'"
              :value="editProfileUpdates[setting.id]"
              :options="setting.options"
              @input="(e) => { updateSetting(setting, e) }"/>
            <sequin-toggle
              v-else
              class="flex-shrink-0"
              :disabled="disabled"
              :state="initialSettings[setting.id]"
              @toggle-click="toggleSetting(setting)"/>
          </div>
          <sequin-accordion
            v-if="setting.description || setting.finePrint"
            :expand-link-props="{ variant: 'tertiary', size: 'fine-print' }">
            <div>
              <p class="small-text text-pewter my-2">
                {{ setting.description[editProfileUpdates[setting.id]] }}
              </p>
              <p class="fine-print text-pewter mb-1">
                {{ setting.finePrint }}
              </p>
            </div>
          </sequin-accordion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SequinAccordion from '../../../global/sequin/SequinAccordion'
import AvatarImage from '../../common/AvatarImage'
import CharacterRemainingWarning from './CharacterRemainingWarning'
import ErrorMessage from '../../common/ErrorMessage'
import SequinToggle from '../../../global/sequin/SequinToggle'
import TextLink from '../../../global/sequin/TextLink'
import SingleSelectRow from '../../../global/sequin/SingleSelectRow'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    SequinAccordion,
    AvatarImage,
    CharacterRemainingWarning,
    ErrorMessage,
    TextLink,
    SingleSelectRow,
    SequinToggle
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    saveError: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localLocation: '',
      localBio: '',
      errorMessage: 'We encountered an error while trying to save changes to your profile. Please try again.',
      profileSettings: {
        privacy: [
          {
            id: 'shareLooks',
            title: 'My Profile',
            type: 'radio',
            options: ['Private', 'Members', 'Public'],
            description: {
              Private: 'Your looks will only be visible to you, via your profile, and will not appear in any feeds, style reviews or search results.',
              Members: 'Members of the Armoire community can search for, view and interact with your profile, looks, and rental history. Your posts will be shared in our members-only feed and as reviews for any styles you have tagged.',
              Public: 'Members of the Armoire community can search for, view and interact with your profile, looks, and rental history. You can share your profile with friends outside Armoire using your unique link. Your photos may be used in Armoire marketing materials or social media channels.'
            }
          },
          {
            id: 'displayRentalHistory',
            title: 'Display my rental history on my profile'
          },
          {
            id: 'displaySizes',
            title: 'Display my sizes on my profile and looks I post',
            description: {
              true: 'Size information includes size of items rented, usual sizes, height, and bust size',
              false: 'Size information includes size of items rented, usual sizes, height, and bust size'
            }
          },
          {
            id: 'displayFirstNameOnly',
            title: 'Display first name only'
          },
          {
            id: 'associateProfileWithReviews',
            title: 'Associate my profile with my reviews '
          }
        ],
        notifications: [
          {
            id: 'feedSummary',
            title: 'Receive email notifications',
            description: {
              true: 'A daily summary of likes, comments, and favorites for looks you\'ve shared',
              false: 'A daily summary of likes, comments, and favorites for looks you\'ve shared'
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'lastName',
      'settings',
      'location',
      'bio',
      'id'
    ]),
    ...mapState('community', [
      'MAX_BIO_LENGTH',
      'MAX_LOCATION_LENGTH',
      'editProfileUpdates',
      'isEditingProfile'
    ]),
    initialSettings () {
      return this.isEditingProfile
        ? this.editProfileUpdates
        : {
            ...this.settings.privacy,
            ...this.settings.notifications
          }
    },
    bioCharsRemaining () {
      return this.localBio ? this.MAX_BIO_LENGTH - this.localBio.length : this.MAX_BIO_LENGTH
    },
    locationCharsRemaining () {
      return this.localLocation ? this.MAX_LOCATION_LENGTH - this.localLocation.length : this.MAX_LOCATION_LENGTH
    }
  },
  mounted () {
    this.localLocation = this.isEditingProfile ? this.editProfileUpdates.location : this.location
    this.localBio = this.isEditingProfile ? this.editProfileUpdates.bio : this.bio
  },
  methods: {
    ...mapActions('community', [
      'updateEditProfile'
    ]),
    updateSetting (setting, value) {
      const updates = {}
      updates[setting.id] = value
      this.updateEditProfile(updates)
    },
    toggleSetting (setting) {
      this.updateSetting(setting, !this.editProfileUpdates[setting.id])
    },
    debouncedCommitLocation () {
      clearTimeout(this.debounceLocationTimeout)
      this.debounceLocationTimeout = setTimeout(() => {
        this.updateEditProfile({ location: this.localLocation })
      }, 500)
    },
    debouncedCommitBio () {
      clearTimeout(this.debounceBioTimeout)
      this.debounceBioTimeout = setTimeout(() => {
        this.updateEditProfile({ bio: this.localBio })
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-item {
  background-color: $white;
  padding: 12px 32px;
  border-top: $default-border;

  .label {
    font-size: 12px;
    text-transform: capitalize;
    color: $plum;
  }
}

.privacy-setting {
  max-width: 300px;
  margin-bottom: 0;
}

.profile-input {
  width: 100%;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  resize: none;

  &:focus {
    outline: none;
    border: none;
  }

  &:disabled {
    background-color: $white;
  }
}
</style>
