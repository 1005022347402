<template>
  <div>
    <style-quiz-banner-image
      :image-name="isMobile ? 'birthday-mobile' : 'birthday-desktop'"/>
    <div
      class="px-4 pb-2"
      :class="{'desktop-vertical-align': !isMobile }">
      <div class="text-center">
        <h6 :class="{'marketing': !isMobile}">
          Like surprises?
        </h6>
        <p class="small-text">
          So do we! We’ll make sure to send you one on your big day.
        </p>
        <h4 class="mt-4 mb-0">
          When is your birthday?
        </h4>
      </div>
      <div class="d-flex justify-content-center my-sm">
        <base-input
          v-model="birthDate"
          :validations="v$.birthDate"
          :cleave-options="{
            date: true,
            datePattern: ['m', 'd', 'Y']}"
          label="Birthday"
          helper-text="mm/dd/yyyy"/>
      </div>
    </div>
    <style-quiz-bottom-nav
      :validations="v$.birthDate"/>
  </div>
</template>

<script>
import StyleQuizBannerImage from './StyleQuizBannerImage'
import StyleQuizBottomNav from './StyleQuizBottomNav.vue'
import BaseInput from '@/components/global/BaseInput.vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { birthYear } from '../global/vuelidate/customValidators'
import { vuexAccessors } from '../global/helpers/vuex'

export default {
  components: {
    StyleQuizBannerImage,
    StyleQuizBottomNav,
    BaseInput
  },
  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    ...vuexAccessors('styleProfile', [
      'birthDate'
    ])
  },
  validations: {
    birthDate: {
      required,
      birthYear
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleQuizStyleDefinitions.scss';

</style>
