export default {
  methods: {
    goBack (fallbackRoute = { name: 'feed' }) {
      if (window.history.length > 1) {
        this.$router.back()
      } else {
        this.$router.push(fallbackRoute)
      }
    }
  }
}
