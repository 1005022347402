<template>
  <accordion-with-header
    :expanded="expanded"
    title="Bra Size"
    @accordion-click="e => $emit('accordion-click', e)">
    <template #subtitle>
      <p class="ms-2 mb-0 text-pewter">
        {{ braSizeBand }}{{ braSizeCup }}
      </p>
    </template>
    <style-quiz-bra-size
      class="gap-2"
      show-labels/>
  </accordion-with-header>
</template>

<script>
import { mapState } from 'vuex'
import AccordionWithHeader from '../global/sequin/AccordionWithHeader.vue'
import StyleQuizBraSize from '../styleQuiz/StyleQuizBraSize'

export default {
  components: {
    AccordionWithHeader,
    StyleQuizBraSize
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('styleProfile', [
      'braSizeBandOptions',
      'braSizeCupOptions',
      'braSizeBand',
      'braSizeCup'
    ])
  }
}
</script>
