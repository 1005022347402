<template>
  <div>
    <div class="px-4 pb-2 mb-5 brands-container">
      <div :class="{'desktop-center-row': !isMobile}">
        <base-checkboxes
          v-model="selectedBrands"
          :options="brandOptions"
          col-class="col-6 col-md-3 p-2"
          class="pb-4"
          row-class="justify-content-center"
          image>
          <template #default="{ option }">
            <div>
              <base-image
                :src="brandImageURLBase + encodeURIComponent(option.value) + ' - Brand Card - Style Quiz.png'"
                class="img-container"/>
            </div>
          </template>
          <template #overlay>
            <div>
              <base-image-overlay
                :class="{ 'rose-overlay': !smallCheck }"
                persistent>
                <svg-heart
                  class="img-overlay-icon"
                  height="50"
                  width="50"/>
              </base-image-overlay>
            </div>
          </template>
        </base-checkboxes>
      </div>
    </div>
    <style-quiz-bottom-nav/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { vuexAccessors } from '../global/helpers/vuex'
import StyleQuizBottomNav from './StyleQuizBottomNav'
import { underscoreToCapitalized } from '@/utils/stringParsing.js'
import SvgHeart from '@/components/global/svg/SvgHeart.vue'
import BaseCheckboxes from '@/components/global/BaseCheckboxes.vue'
import BaseImage from '@/components/global/BaseImage.vue'
import BaseImageOverlay from '@/components/global/BaseImageOverlay.vue'

export default {
  components: {
    StyleQuizBottomNav,
    SvgHeart,
    BaseCheckboxes,
    BaseImage,
    BaseImageOverlay
  },
  computed: {
    ...mapState('styleProfile', [
      'brandOptions',
      'selectedBrands'
    ]),
    ...vuexAccessors('styleProfile', [
      'selectedBrands'
    ]),
    ...mapState('styleQuizNav', [
      'brandImageURLBase'
    ]),
    methods: {
      underscoreToCapitalized
    }
  }
}
</script>
<style lang="scss" scoped>
@import './StyleQuizStyleDefinitions.scss';

.brands-container {
  margin: 20px;
}
:deep(.base-checkbox) {
  box-shadow: none;
  border: $default-border;
  border-radius: $sequin-border-radius;
  color: $pewter;

  &:focus-within {
    box-shadow: none;
    border-color: $armor;
  }
}

:deep(.rose-overlay) {
  background-color: rgba($orchid, .5);
  border-radius: $sequin-border-radius;
}

.img-container {
  box-shadow: none;
  border-radius: $sequin-border-radius;
}

:deep(.faded) {
  opacity: 0.5;
}

.img-overlay-icon {
  color: white;
  fill: white;
}

</style>
