<template>
  <div class="px-4 pb-2 mb-5">
    <style-quiz-progress-page
      v-if="showProgressAnimation"
      :progress-index="endOfSectionIndex"/>
    <div v-if="clusterIndex !== null">
      <style-cluster-mobile
        v-if="isMobile"
        :cluster="cluster"/>
      <style-cluster-desktop
        v-else
        :cluster="cluster"/>
      <div
        class="w-100 d-flex justify-content-center"
        :class="{'mt-5': !isMobile, 'mt-3': isMobile }">
        <base-radios
          :model-value="clusterRatings[cluster]"
          col-class="style-quiz-radio"
          :options="generalClusterRatingOptions"
          buttons
          @update:modelValue="onResponse"/>
      </div>
    </div>
    <style-quiz-bottom-nav
      :validations="selectedClusterRating"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import StyleClusterMobile from './StyleClusterMobile.vue'
import StyleClusterDesktop from './StyleClusterDesktop'
import StyleQuizProgressPage from './StyleQuizProgressPage'
import { vuexAccessors } from '../global/helpers/vuex'
import StyleQuizBottomNav from './StyleQuizBottomNav'
import { MobileAppMessage, postToMobileWebView } from '@/utils/mobileApp.js'
import BaseRadios from '@/components/global/BaseRadios.vue'

export default {
  components: {
    StyleQuizBottomNav,
    StyleClusterDesktop,
    StyleClusterMobile,
    StyleQuizProgressPage,
    BaseRadios
  },
  data () {
    return {
      cluster: this.$route.params.cluster,
      showProgressAnimation: false
    }
  },
  computed: {
    ...mapState('styleProfile', [
      'generalClusterOptions',
      'generalClusterRatingOptions'
    ]),
    ...vuexAccessors('styleProfile', [
      'clusterRatings'
    ]),
    ...mapGetters('styleQuizNav', [
      'getNextStyleQuizRouteForRouter',
      'getCurrentStyleQuizRoute',
      'styleQuizRoutes'
    ]),
    currentRoute () {
      return this.getCurrentStyleQuizRoute({ ...this.$route })
    },
    endOfSectionIndex () {
      return this.currentRoute.endOfSection
    },
    nextRoute () {
      return this.getNextStyleQuizRouteForRouter({ ...this.$route })
    },
    clusterIndex () {
      return this.generalClusterOptions.length > 0
        ? this.generalClusterOptions.map(cluster => cluster.value).indexOf(this.cluster)
        : null
    },
    selectedClusterRating () {
      return { $invalid: this.clusterRatings[this.cluster] === undefined }
    }
  },
  mounted () {
    if (this.clusterIndex === 0) {
      postToMobileWebView(MobileAppMessage.StyleProfileLoaded)
    }
  },
  methods: {
    ...mapActions('styleProfile', [
      'patchStyleProfile'
    ]),
    async onResponse (value) {
      this.clusterRatings = { ...this.clusterRatings, [this.cluster]: value }
      // Next Navigation will call patchStyleProfile to save changes
    }
  }
}
</script>
<style lang="scss">
@import './StyleQuizStyleDefinitions.scss';
.style-quiz-radio { padding: 0.64rem 1.5rem !important}
</style>
