<template lang="">
  <div class="game-cta px-0 pt-1 mt-1">
    <plain-button
      class="d-flex align-items-start justify-content-between pe-0 ps-3"
      @click="onStyleGameCompletion()">
      <div class="text-container mt-2">
        <h5 class="marketing mb-1 text-armor">
          {{ styleGame.name }}
        </h5>
        <p class="text-armor">
          {{ styleGame.description }}
        </p>
      </div>
      <div class="d-flex justify-content-end col-3 mt-2 ms-1">
        <div class="outer-circle d-flex align-items-center justify-content-center">
          <svg-circle-icon
            class="inner-icon"
            :variant="'neutral'"
            :inverse="false"
            :height="54"
            :width="54"
            :padding="22"
            :stroke-width="1">
            <component :is="styleGame.iconVariant"/>
          </svg-circle-icon>
        </div>
      </div>
    </plain-button>
  </div>
</template>
<script>
import SvgCircleIcon from '../../global/svg/SvgCircleIcon.vue'
import PlainButton from '../../global/sequin/PlainButton.vue'

export default {
  name: 'StyleGameCta',
  components: {
    PlainButton,
    SvgCircleIcon
  },
  props: {
    styleGame: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss">
.game-cta {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transition: border-color .5s ease-in-out;
  background: transparent;
  min-height: 150px;
  width: 100%;
  & .button {
    border-top: 1px solid $armor;
    background: transparent;
    border-radius: 0;
  }
  &:hover, &:focus {
    border-color: $armor;
  }
}

.game-cta > * {
  color: $armor;
}

.outer-circle {
  border-radius: 50%;
  color: $armor;
  border: 2px solid $armor;
  height: 64px;
  width: 64px;
}

.inner-icon {
  border-radius: 50%;
  background-color: transparent !important;
  border: 2px solid $armor;
}
</style>
