<template>
  <div>
    <div
      class="border border-bottom-0 p-3">
      <h5
        v-if="!isMobile">
        {{ header }}
      </h5>
      <span
        class="small-text">
        {{ headerDescription }}
      </span>
    </div>
    <div
      v-for="(setting, index) in displaySettings"
      :key="setting.key"
      class="d-flex justify-content-between border p-3 setting-container"
      :class="['setting', { 'border-bottom-0': index !== displaySettings.length - 1 }]">
      {{ setting.name }}
      <div
        v-if="setting.fixedOn"
        class="text-ash">
        On
      </div>
      <sequin-toggle
        v-else
        :disabled="false"
        :state="settings.notifications[setting.settingKey]"
        @toggle-click="e => toggleSetting(setting.settingKey, !e)"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { vuexAccessors } from '../../global/helpers/vuex'
import SequinToggle from '../../global/sequin/SequinToggle'

export default {
  components: { SequinToggle },
  props: {
    header: {
      type: String,
      required: true
    },
    headerDescription: {
      type: String,
      required: true
    },
    displaySettings: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...vuexAccessors('account', [
      'breadcrumbs'
    ]),
    ...mapState('client', [
      'settings'
    ])
  },
  methods: {
    ...mapActions('client', [
      'updateNotificationSetting'
    ]),
    toggleSetting (key, value) {
      this.updateNotificationSetting({ key: key, value })
    }
  }
}
</script>

<style lang="scss" scoped>

.setting-container {
  background-color: white;
}

</style>
