<template>
  <review-single-select
    v-model="nWears"
    label="How many times did you wear this?"
    :options="nWearsOptions"
    :validations="validations"/>
</template>

<script>
import ReviewSingleSelect from './ReviewSingleSelect'

export default {
  name: 'ReviewItemNumWears',
  components: { ReviewSingleSelect },
  model: {
    prop: 'review',
    event: 'change'
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      nWearsOptions: [
        {
          text: 'Never',
          value: '0'
        },
        {
          text: 'Tried it On',
          value: '1'
        },
        {
          text: 'Wore Once',
          value: '2'
        },
        {
          text: 'Wore 2+ Times',
          value: '3'
        }
      ]
    }
  },
  computed: {
    nWears: {
      get () {
        return this.review.nWears
      },
      set (val) {
        this.$emit('change', { ...this.review, nWears: val })
      }
    }
  }
}
</script>
