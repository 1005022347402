<template>
  <div>
    <div :class="{ 'd-flex flex-wrap justify-content-between': !useMobileView }">
      <label
        v-for="option in options"
        :key="option.id"
        :class="['style-quiz-checkbox-label',
                 { 'selected': modelValue.includes(option.value),
                   'disabled': maxSelected && !_modelValue.includes(option.value),
                   'w-100': useMobileView }]">
        {{ option.text }}
        <input
          v-model="_modelValue"
          :disabled="maxSelected && !_modelValue.includes(option.value)"
          :value="option.value"
          class="style-quiz-checkbox"
          type="checkbox">
        <svg-check
          v-if="modelValue.includes(option.value)"
          class="check"/>
      </label>
    </div>
  </div>
</template>

<script>
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    SvgCheck
  },
  props: {
    max: {
      type: Number,
      default: null
    },
    modelValue: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    useMobileView: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    _modelValue: {
      get () { return this.modelValue },
      set (v) { this.$emit('update:modelValue', v) }
    },
    maxSelected () {
      return this.modelValue.length >= this.max
    }
  }
}
</script>

<style lang="scss" scoped>
.style-quiz-checkbox-label {
  width: 48%;
  position: relative;
  border: $default-border;
  padding: 14px 20px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $small-text-font-size;
  font-weight: $font-weight-semibold;
  color: $pewter;
  margin-bottom: 12px;
  background-color: $white;

  &.disabled {
    cursor: default;
    opacity: .6;
  }

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }

}

.check {
  position: absolute;
  right: 26px;
  color: $primary;
  width: 14px;
  stroke-width: 4px;
}

.style-quiz-checkbox {
  border: $default-border;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  outline: none;
  -webkit-appearance: none;
  border-width: 1px;

  &:checked {
    background-color: $white;
    border-color: $white;
  }
}
</style>
