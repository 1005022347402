<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Case</title>
    <path
      d="M21.9827 6.33332H3.33141C2.04381 6.33332 1 7.52723 1 8.99999V22.3333C1 23.8061 2.04381 25 3.33141 25H21.9827C23.2703 25 24.3141 23.8061 24.3141 22.3333V8.99999C24.3141 7.52723 23.2703 6.33332 21.9827 6.33332Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M17.3199 6.33334V3.66667C17.3199 2.95942 17.0743 2.28115 16.637 1.78105C16.1998 1.28095 15.6068 1 14.9885 1H10.3257C9.70734 1 9.11434 1.28095 8.67712 1.78105C8.23989 2.28115 7.99426 2.95942 7.99426 3.66667V6.33334"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>
