<template>
  <plain-button
    class="back-button"
    :class="{'with-border': withBorder, 'inverse': variant === 'inverse'}"
    aria-label="Back Button"
    @click="onBack">
    <svg-chevron-left/>
  </plain-button>
</template>

<script>
import PlainButton from './PlainButton'
import goBack from '../mixins/goBack'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'

export default {
  components: {
    PlainButton,
    SvgChevronLeft
  },
  mixins: [goBack],
  props: {
    backDefaultRoute: {
      type: Object,
      required: true
    },
    withBorder: {
      type: Boolean,
      default: false
    },
    // prevents the default navigation behavior
    noNavigation: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'default',
      validator: value => {
        return value.match(/(default|inverse)/)
      }
    }
  },
  methods: {
    onBack () {
      this.$emit('back-click')
      if (!this.noNavigation) {
        this.goBack(this.backDefaultRoute)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.back-button {
  padding: 6px;
  background: inherit;

  &.with-border {
    background: $white;
    border: $default-border;
  }

  &.inverse {
    svg { stroke: $white }
  }

  svg {
    stroke: $pewter;
  }
}
</style>
