<template>
  <div v-if="!profileInfoLoadingError && !profileInfoLoading">
    <div class="p-3 p-md-4 position-relative">
      <div class="">
        <avatar-image
          :client-id="profile.id"
          :size="isMobile ? 'large' : 'x-large'"
          :staff="profile.staff"
          :profile-pic="profile.profilePic"/>
      </div>
      <div
        class="text-center mt-3"
        :class="{ 'mobile': isMobile }">
        <h5 class="mb-0">
          {{ profile.firstName }}
          <span v-if="!isOwnProfile || !firstNameOnly"> {{ profile.lastName }}</span>
        </h5>
        <staff-header
          v-if="profile.staff"/>
        <p class="mb-0 mt-2 text-pewter location">
          {{ profile.location }}
        </p>
        <div
          class="d-flex justify-content-around flex-wrap my-3 text-pewter">
          <div>
            <div>
              <b>{{ looks }}</b>
            </div>
            <div>
              Looks
            </div>
          </div>
          <component
            :is="!!collections ? 'plain-button' : 'div' "
            type="router-link"
            :to="{ name: 'client-collections', params: { clientId: profile.id }}"
            class="p-0 text-pewter">
            <p class="mb-0 text-pewter">
              <b>{{ collections === null ? '---' : collections }}</b>
            </p>
            <p class="mb-0 text-pewter">
              Collection{{ collections === 1 ? '' : 's' }}
            </p>
          </component>
          <div
            :class="{'pointer' : followers(profile.id) }"
            @click="onFollowerFollowingClick('followers', followers(profile.id))">
            <div>
              <b>{{ followers(profile.id) }}</b>
            </div>
            <div>
              Followers
            </div>
          </div>
          <div
            :class="{'pointer' : isOwnProfile || following(profile.id) }"
            @click="onFollowerFollowingClick('following', following(profile.id))">
            <div>
              <b>{{ following(profile.id) }}</b>
            </div>
            <div>
              Following
            </div>
          </div>
        </div>
        <div
          class="text-pewter px-4 mb-3"
          v-html="parseLinks(profile.bio)"/>
        <div class="d-flex justify-content-center">
          <sequin-button
            v-if="isOwnProfile"
            id="edit-profile"
            variant="secondary"
            small
            data-intercom-target="intercom-profile-edit"
            @click="isMobile ? $router.push({ name: 'edit-profile'}) : openModal({ 'name': 'edit-profile' })">
            Edit Profile
          </sequin-button>
          <copy-link
            v-if="isOwnProfile"
            class="ms-3"
            small
            display-link=""
            button-text="Share Profile"
            confirmation-text="Link copied!"
            :full-link="profileLink"
            :show-link="false"/>
          <sequin-button
            v-if="!isOwnProfile && isFollowing(profile.id) && id"
            variant="secondary"
            class="ms-3"
            small
            @click="unfollowClient(profile.id)">
            Unfollow
          </sequin-button>
          <sequin-button
            v-else-if="!isOwnProfile && id"
            class="ms-3"
            small
            @click="followOrLogin()">
            Follow
          </sequin-button>
        </div>
        <div
          v-if="isOwnProfile"
          class="fine-print text-pewter mx-5 px-3 mt-3">
          Renting is more fun with friends. Share your profile so they can see your rental history and looks.
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="p-3 p-md-4 d-flex flex-column align-items-center">
      <div
        :class="{ 'mobile': isMobile, 'placeholder': profileInfoLoading }"
        class="avatar"/>
      <div
        v-if="profileInfoLoading"
        class="mt-3 mt-md-4 d-flex flex-column align-items-center">
        <text-placeholder size="large"/>
        <text-placeholder/>
      </div>
      <div
        v-else
        class="ms-3 ms-md-4">
        We are currently unable to load this user's profile.
      </div>
    </div>
  </div>
</template>

<script>
import AvatarImage from '../common/AvatarImage'
import StaffHeader from '../common/StaffHeader'
import linkParsing from '../../global/mixins/linkParsing'
import { mapActions as mapVuexActions, mapGetters, mapState } from 'vuex'
import { mapActions } from 'pinia'
import PlainButton from '../../global/sequin/PlainButton.vue'
import SequinButton from '../../global/sequin/SequinButton'
import TextPlaceholder from '../../global/sequin/TextPlaceholder.vue'
import CopyLink from '../../global/sequin/CopyLink'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    AvatarImage,
    CopyLink,
    PlainButton,
    StaffHeader,
    SequinButton,
    TextPlaceholder
  },
  mixins: [linkParsing],
  props: {
    collections: {
      type: Number,
      default: null
    },
    profile: {
      type: Object,
      required: true
    },
    isOwnProfile: {
      type: Boolean,
      required: true
    },
    firstNameOnly: {
      type: Boolean,
      required: true
    },
    profileInfoLoading: {
      type: Boolean,
      required: true
    },
    profileInfoLoadingError: {
      type: Boolean,
      required: true
    },
    looks: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters('community', [
      'isFollowing',
      'followers',
      'following'
    ]),
    ...mapState('client', [
      'id',
      'settings'
    ]),
    ...mapGetters('client', [
      'shareQueryString'
    ]),
    topBrand () {
      if (this.profile && this.profile.stats.topBrands.length > 0) {
        return this.profile.stats.topBrands[0]
      }
      return ''
    },
    profileLink () {
      return `https://${window.location.hostname}/community/profile/${this.id}/` + this.shareQueryString
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    ...mapVuexActions('community', [
      'followClient',
      'unfollowClient',
      'getClientProfile'
    ]),
    followOrLogin () {
      if (!this.id) {
        this.$router.push({ name: 'login' })
      } else {
        this.followClient(this.profile.id)
      }
    },
    onFollowerFollowingClick (type, count) {
      if (!this.id) {
        this.$router.push({ name: 'login' })
      } else {
        if ((this.isOwnProfile && type === 'following') || count > 0) {
          this.$router.push({ name: 'profile', params: { id: this.profile.id, tab: type } })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-link {
  position: absolute;
  top: 10px;
  right: 10px;
}

.avatar {
  border-radius: 50%;
  width: 96px;
  height: 96px;
  background: $origami;
  flex-shrink: 0;

  &.mobile {
    width: 72px;
    height: 72px;
  }
}

.placeholder {
  margin: 0;
  @include placeholder-loading-animation;
}
</style>
