<template>
  <sequin-button
    block
    skinny
    :disabled="addToCaseDisabled"
    :variant="addToCaseButtonType"
    :type="mustSwap ? 'router-link' : 'button'"
    :to="{ name: 'swap-select' }"
    :class="{'added-to-case': addToCaseButtonState === 'added', 'disabled' : (tooManyItemsOut || isReviewOOC)}"
    @click="onAddToCaseClick">
    <svg-case-filled
      v-if="!mustSwap"
      height="16"
      :class="{'scale': addToCaseButtonState === 'added'}"
      class="mb-1 me-1"/>
    <svg-check
      v-if="addToCaseButtonState === 'added'"
      class="check"/>
    {{ addToCaseButtonText }}
  </sequin-button>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { captureException } from '@sentry/vue'
import { formatPrice } from '@/utils/stringParsing.js'
import SequinButton from '../global/sequin/SequinButton'
import SvgCaseFilled from '@/components/global/svg/SvgCaseFilled.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    SequinButton,
    SvgCaseFilled,
    SvgCheck
  },
  props: {
    itemInCase: {
      type: Boolean,
      default: false
    },
    itemAvailable: {
      type: Boolean,
      required: true
    },
    selectedItemType: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      price: '',
      itemJustAdded: false,
      addedToCaseAffirmations: [
        'Great pick!',
        'So stylish!',
        'Nice choice!'
      ]
    }
  },
  computed: {
    ...mapState('client', [
      'tooManyItemsOut',
      'closetItemNumber',
      'membershipDetails',
      'membershipStatus'
    ]),
    ...mapState('closet', [
      'itemTypePricing',
      'sale'
    ]),
    ...mapGetters('client', [
      'numFreeSlotsAvailable',
      'maxNumExtraItems',
      'canSelectItems',
      'canPickItems',
      'itemsInTransit',
      'hasRentalPlan',
      'itemContainerText',
      'hasPlanTierPermission'
    ]),
    ...mapGetters('review', [
      'isReviewOOC'
    ]),
    addToCaseButtonState () {
      if (this.itemJustAdded) {
        return 'added'
      } else if (this.itemInCase) {
        return 'remove'
      } else if (this.addToCaseDisabled) {
        return 'disabled'
      } else if (this.mustSwap) {
        return 'mustSwap'
      } else {
        return 'add'
      }
    },
    addToCaseButtonType () {
      switch (this.addToCaseButtonState) {
        case 'remove':
          return 'secondary'
        case 'disabled':
          return 'secondary'
        default:
          return 'primary'
      }
    },
    addToCaseButtonText () {
      const pricing = this.price ? ` - ${formatPrice(this.price, true)}` : ''
      switch (this.addToCaseButtonState) {
        case 'added':
          return this.addedToCaseAffirmations[Math.round(Math.random() * (this.addedToCaseAffirmations.length - 1))]
        case 'remove':
          return `Remove from ${this.itemContainerText}`
        case 'mustSwap':
          return `Swap Items to Add to ${this.itemContainerText}`
        case 'add':
        default:
          if (this.sale) {
            if (this.membershipStatus === 'former_member') {
              return 'Re-Join to Rent or Buy'
            } else if (this.membershipStatus === 'non_member') {
              return 'Subscribe to rent this style'
            }
          } else {
            if ((['former_member', 'non_member'].includes(this.membershipStatus)) ||
              (this.membershipStatus === 'active' && !this.hasRentalPlan)) {
              return 'Subscribe to rent this style'
            }
          }
          return `Add to ${this.itemContainerText}${pricing}`
      }
    },
    addToCaseButtonDisabled () {
      switch (this.addToCaseButtonState) {
        case 'added':
          return true
        case 'add':
          return false
        case 'remove':
        default:
          return false
      }
    },
    mustSwap () {
      return this.closetItemNumber === 0 && !this.addToCaseDisabled
    },
    noSwapsAvailable () {
      return this.membershipDetails.allowedShipments === 0
    },
    caseInTransit () {
      return this.itemsInTransit > 0
    },
    addToCaseDisabled () {
      if (this.hasRentalPlan) {
        return !this.itemAvailable || this.tooManyItemsOut || this.isReviewOOC || this.noSwapsAvailable || this.caseInTransit
      }
      if (this.hasPlanTierPermission('purchase_anytime')) {
        return !this.itemAvailable
      } else if (this.hasPlanTierPermission('purchase_during_sale') && this.sale) {
        return !this.itemAvailable
      } else {
        return true
      }
    },
    hasPricing () {
      if (!this.selectedItemType) return null
      return Object.prototype.hasOwnProperty.call(this.itemTypePricing, this.selectedItemType.id)
    }
  },
  watch: {
    selectedItemType: async function () {
      if (!this.hasRentalPlan && this.selectedItemType) {
        if (this.hasPricing) {
          this.price = this.itemTypePricing[this.selectedItemType.id].client
        } else {
          try {
            this.price = ''
            await this.getItemTypePrice(this.selectedItemType)
            this.price = this.itemTypePricing[this.selectedItemType.id].client
          } catch (err) {
            // shouldn't happen, but if it does, just don't show the price
            this.$logger.error(err)
            captureException(err, { extra: { itemtype: this.selectedItemType.id } })
          }
        }
      }
    }
  },
  async mounted () {
    if (!this.hasRentalPlan && this.selectedItemType) {
      this.price = this.itemTypePricing[this.selectedItemType.id].client
    }
  },
  methods: {
    ...mapActions('client', [
      'getItemTypePrice'
    ]),
    onAddToCaseClick () {
      const prevAddToCaseButtonState = this.addToCaseButtonState

      if (this.addToCaseButtonState === 'add') {
        this.itemJustAdded = true
        setTimeout(() => { this.itemJustAdded = false }, 1500)
      }

      this.$emit(`${prevAddToCaseButtonState}-click`)
    }
  }
}
</script>

<style lang="scss" scoped>
.added-to-case {
  &:disabled {
    opacity: 1;
  }
}

.scale {
  @include scale-up-down-animation;
}

.check {
  color: $primary;
  height: 12px;
  padding: 2px;
  stroke-width: 7px;
  margin-left: -28px;

  transform-origin: 50% 50%;
  stroke-dasharray: 24;
  stroke-dashoffset: 24;
  animation: stroke .2s ease-in .4s forwards;

  @keyframes stroke {
    100% {
      stroke-dashoffset: 48;
    }
  }
}
</style>
