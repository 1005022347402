<template>
  <horizontal-scroll>
    <preview-image-button
      v-for="entry in entries"
      :key="entry.id"
      class="me-3"
      :src="entry.image"
      size="tiny"
      @click="viewPhotoAlbum(entry.photoAlbumIndex)"/>
  </horizontal-scroll>
</template>

<script>
import PreviewImageButton from '@shared/components/ADORN/PreviewImageButton.vue'
import HorizontalScroll from '../global/sequin/HorizontalScroll'
import { vuexAccessors } from '../global/helpers/vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    PreviewImageButton,
    HorizontalScroll
  },
  props: {
    styleColorId: {
      type: String,
      required: true
    },
    entries: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...vuexAccessors('closet', [
      'photoAlbumIndex'
    ])
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    viewPhotoAlbum (index) {
      this.photoAlbumIndex = index
      if (this.isMobileWidth) {
        this.$router.push({
          name: 'closet-detail-member-photos',
          params: this.$route.params
        })
      } else {
        this.openModal({ name: 'member-photo-detail', context: { styleColorId: this.styleColorId } })
      }
    }
  }
}
</script>
