<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 15.1667C14.6444 15.1667 15.1667 14.6444 15.1667 14C15.1667 13.3557 14.6444 12.8334 14 12.8334C13.3557 12.8334 12.8334 13.3557 12.8334 14C12.8334 14.6444 13.3557 15.1667 14 15.1667Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M22.1667 15.1667C22.811 15.1667 23.3333 14.6444 23.3333 14C23.3333 13.3557 22.811 12.8334 22.1667 12.8334C21.5223 12.8334 21 13.3557 21 14C21 14.6444 21.5223 15.1667 22.1667 15.1667Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M5.83329 15.1667C6.47762 15.1667 6.99996 14.6444 6.99996 14C6.99996 13.3557 6.47762 12.8334 5.83329 12.8334C5.18896 12.8334 4.66663 13.3557 4.66663 14C4.66663 14.6444 5.18896 15.1667 5.83329 15.1667Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>
