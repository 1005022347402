<template>
  <div class="card">
    <div>
      <SequinTag
        v-if="shippingId === address.id"
        class="mb-sm">
        My Address
      </SequinTag>
      <h5 class="mb-0">
        {{ firstName }} {{ lastName }}
      </h5>
      <address-wrapper :address="address"/>
      <TextLink
        v-if="shippingId !== address.id"
        @click="deleteShippingAddress(address)">
        Remove
      </TextLink>
    </div>
    <TextLink
      v-if="shippingId !== address.id"
      @click="selectAddress(address)">
      Select
    </TextLink>
    <TextLink
      v-if="shippingId === address.id"
      @click="editShippingAddress(address)">
      Edit
    </TextLink>
  </div>
</template>

<script>
import { mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import AddressWrapper from '../global/address/AddressWrapper'
import { useOverlaysStore } from '@/stores/overlays.js'
import SequinTag from '@/components/global/sequin/SequinTag.vue'
import TextLink from '../global/sequin/TextLink.vue'

export default {
  components: {
    AddressWrapper,
    SequinTag,
    TextLink
  },
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    address: {
      type: Object,
      required: true
    },
    shippingId: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    ...mapVuexActions('account', [
      'deleteShippingAddress'
    ]),
    selectAddress (address) {
      if (this.shippingId !== address.id) {
        this.$emit('select-address', address)
      }
    },
    editShippingAddress (address) {
      this.openModal({
        name: 'account-edit-shipping-address',
        context: {
          address: address,
          setSelectedInitialValue: false
        }
      })
    }
  }
}
</script>
