<template>
  <MyClosetSectionPreview :section-id="id"/>
</template>

<script setup>
import { useWearItAgainSection } from '@/composables/closetSection.js'
import MyClosetSectionPreview from '@/components/closet/MyClosetSectionPreview.vue'

const { id } = useWearItAgainSection()
</script>
