<!-- An icon for stylists to quickly see if a style color has been rented before by the customer when they are impersonating a customer. Not a button, just an icon. Using the button container for styling consistency. -->
<template>
  <StyleColorQuickActionButton
    class="icon"
    :style-color="styleColor"
    :style-color-source="styleColorSource"
    :style-color-source-index="styleColorSourceIndex"
    :size="size">
    <SvgCase v-if="!inRentalHistory"/>
    <SvgCaseFilled v-else/>
  </StyleColorQuickActionButton>
</template>

<script setup>
import { computed } from 'vue'
import StyleColorQuickActionButton from '@shared/components/ADORN/StyleColorQuickActionButton.vue'
import SvgCase from '@/components/global/svg/SvgCase.vue'
import SvgCaseFilled from '@/components/global/svg/SvgCaseFilled.vue'
import { useStylingStore } from '@/stores/styling'
import { storeToRefs } from 'pinia'

const props = defineProps({
  styleColor: {
    type: Object,
    required: true
  },
  /** @type {PropType<StyleColorSource>} */
  styleColorSource: {
    type: Object,
    required: true
  },
  styleColorSourceIndex: {
    type: Number,
    default: () => null
  },
  size: {
    type: String,
    default: 'medium',
    validator: value => ['x-large', 'large', 'medium', 'small', 'tiny'].includes(value)
  }
})

const store = useStylingStore()
const { rentalHistory } = storeToRefs(store)

const inRentalHistory = computed(() => rentalHistory.value.includes(props.styleColor.id))

</script>

<style lang="scss" scoped>
@import './StyleColorQuickActions.scss';
</style>
