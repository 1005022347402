<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.02006 12.88C4.59006 12.74 5.13006 12.62 5.67006 12.48C6.33006 12.31 6.30006 11.69 5.65006 11.09C4.57006 10.09 3.82006 9.48997 2.74006 8.49997C2.30006 8.10997 1.76006 8.22997 1.55006 8.78997C1.04006 10.11 0.55006 11.43 0.0700602 12.76C-0.15994 13.39 0.19006 13.78 0.85006 13.64C1.38006 13.52 1.91006 13.4 2.46006 13.27C2.48006 13.27 2.50006 13.28 2.50006 13.29C2.57006 13.45 2.64006 13.59 2.70006 13.73C4.34006 17.57 8.22006 19.98 12.3201 19.71C16.5301 19.44 20.0901 16.58 21.2401 12.54C22.5501 7.92997 20.2701 2.90997 15.9501 0.91997C11.5201 -1.12003 6.22006 0.37997 3.55006 4.43997C3.50006 4.51997 3.44006 4.61997 3.39006 4.71997C3.19006 5.12997 3.36006 5.64997 3.78006 5.80997C4.11006 5.92997 4.40006 5.81997 4.63006 5.52997C4.95006 5.11997 5.22006 4.66997 5.57006 4.27997C8.16006 1.39997 12.1701 0.69997 15.7401 2.49997C18.8701 4.06997 20.6401 7.85997 19.9501 11.5C19.2801 15 16.2901 17.75 12.7001 18.16C9.12006 18.57 5.61006 16.57 4.17006 13.29C4.11006 13.17 4.07006 13.05 4.02006 12.88Z"
      fill="currentColor"/>
    <path
      d="M10.97 4.65995C10.97 6.31995 10.97 7.98995 10.97 9.64995C10.97 9.88995 10.93 10.1999 11.05 10.4199C11.15 10.5899 11.34 10.7499 11.47 10.8899C12.15 11.6199 12.83 12.3499 13.51 13.0799C14.04 13.6499 14.56 14.2199 15.09 14.7799C15.6 15.3299 16.42 14.5099 15.91 13.9599C14.59 12.5399 13.28 11.1299 11.96 9.70995C12.02 9.84995 12.07 9.97995 12.13 10.1199C12.13 8.29995 12.13 6.47995 12.13 4.64995C12.13 3.90995 10.97 3.90995 10.97 4.65995Z"
      fill="currentColor"/>
  </svg>
</template>
