<template>
  <div>
    <plain-button
      type="router-link"
      :to="{ name: 'collection', params: { id }}"
      class="p-0 w-100"
      :class="{ 'border': horizontalBorder, 'border-top border-bottom': !horizontalBorder}">
      <collection-feed-item
        with-arrow
        :preview-images="previewImages"
        :name="metadata.name"
        :item-count="metadata.count"/>
    </plain-button>
  </div>
</template>

<script>
import CollectionFeedItem from '../../collections/CollectionFeedItem.vue'
import PlainButton from '../../global/sequin/PlainButton.vue'

export default {
  components: {
    CollectionFeedItem,
    PlainButton
  },
  props: {
    styleColors: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    horizontalBorder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    previewImages () {
      return this.styleColors.slice(0, 3).map(sc => sc.image.url)
    }
  }
}

</script>
