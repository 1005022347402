<template>
  <div
    v-if="!packageItemsLoaded"
    class="text-center">
    <svg-spinner
      class="mt-2"
      height="40"/>
  </div>
  <div
    v-else-if="itemPurchased"
    class="text-center semi-bold">
    You purchased this item
  </div>
  <closet-item-type-with-me
    v-else-if="clientItemTypeStatus === 'with-me'"
    class="item-status"
    :item-to-purchase="getItemToPurchase(selectedItemType)"/>
  <div v-else-if="!clientHasPurchased">
    <div
      class="d-flex flex-column">
      <closet-item-type-add-to-case
        v-if="styleColor.rentable || !hasRentalPlan"
        v-bind="listeners"
        :selected-item-type="selectedItemType"
        :item-in-case="itemInCase"
        :item-available="itemTypeAvailable(selectedItemType)"/>
      <closet-item-type-buy-now
        v-if="itemCanBuyNow && hasRentalPlan"
        class="mt-2 mt-md-3"
        :selected-item-type="selectedItemType"
        :style-color="styleColor"/>
    </div>
    <closet-item-type-cannot-select v-if="showCannotSelect"/>
    <closet-item-type-in-transit
      v-if="clientItemTypeStatus === 'in-transit'"
      :item-type-shipped-package="getTransitItemShippedPackage(selectedItemType)"/>
  </div>
  <div
    v-else-if="clientHasPurchased"
    class="text-center">
    <h6>You purchased this item</h6>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ClosetItemTypeAddToCase from './ClosetItemTypeAddToCase'
import ClosetItemTypeInTransit from './ClosetItemTypeInTransit'
import ClosetItemTypeWithMe from './ClosetItemTypeWithMe'
import ClosetItemTypeCannotSelect from './ClosetItemTypeCannotSelect'
import ClosetItemTypeBuyNow from './ClosetItemTypeBuyNow'
import { ItemTypeStatusMixin } from './ItemTypeStatusMixin'
import { getListeners } from '../global/helpers/vue'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  compatConfig: {
    INSTANCE_LISTENERS: false // $listeners are now apart of $attrs in Vue 3
  },
  components: {
    ClosetItemTypeAddToCase,
    ClosetItemTypeCannotSelect,
    ClosetItemTypeInTransit,
    ClosetItemTypeWithMe,
    ClosetItemTypeBuyNow,
    SvgSpinner
  },
  mixins: [ItemTypeStatusMixin],
  props: {
    selectedItemType: {
      type: Object,
      default: null
    },
    styleColor: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState('closet', [
      'packageItemsLoaded',
      'soldPackingItems'
    ]),
    ...mapState('client', [
      'closetItemNumber',
      'membershipDetails'
    ]),
    ...mapGetters('client', [
      'canSelectItems',
      'nextShipmentDate'
    ]),
    ...mapGetters('review', [
      'isReviewOOC'
    ]),
    clientItemTypeStatus () {
      return this.getClientItemTypeStatus(this.selectedItemType)
    },
    itemPurchased () {
      if (!this.selectedItemType) { return false }
      return this.soldPackingItems.filter(x => x.itemType === this.selectedItemType.id).length > 0
    },
    itemCanBeAddedOrRemoved () {
      return this.selectedItemType && this.itemTypeAvailable(this.selectedItemType) && this.canSelectItems && this.styleColor.rentable
    },
    clientHasPurchased () {
      return this.clientItemTypeStatus === 'sold'
    },
    listeners () {
      return getListeners(this.$attrs)
    },
    showCannotSelect () {
      if (this.itemCanBuyNow) return false
      return !this.itemCanBeAddedOrRemoved || this.isReviewOOC
    }
  }
}
</script>
