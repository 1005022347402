<template>
  <div class="notification-item">
    <div>
      <div class="notification-item-image-container">
        <div
          v-if="props.content.primaryImageUrl"
          @click="onPrimaryImageClick()">
          <img
            :src="content.primaryImageUrl"
            :class="content.primaryImageUrl.includes('dress') ? 'primary-image-dress' : 'primary-image'">
        </div>
      </div>
      <div
        v-if="!props.content.viewed"
        class="notification-item-new"/>
    </div>

    <div class="notification-item-content-wrapper">
      <div
        class="text-left d-flex align-items-center"
        :class="{ 'pointer': props.content.actionType }"
        @click="onContentClick()">
        <div>
          <p class="small-text m-0 p-0">
            <span
              v-if="content.title"
              class="semi-bold">
              {{ content.title }}:
            </span>
            <span v-html="parseMarkdown(getMessage(content), true)"/>
          </p>

          <p
            class="fine-print text-uppercase mb-0"
            :style="{ padding: '4px 0' }">
            {{ toDifferenceTime(content.created) }}
          </p>
        </div>
      </div>
      <BaseButton
        v-if="showButton"
        variant="secondary"
        :text="buttonText"
        @click="onContentClick('button')"/>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { parseMarkdown, toDifferenceTime } from '@/utils/stringParsing.js'
import BaseButton from '@shared/components/ADORN/BaseButton'
import { useStore } from 'vuex'
import { useFlyouts } from '@/composables/flyouts'
import { track } from '@/plugins/analytics.js'
import useNavigation from '@/composables/navigation'

const router = useRouter()
const store = useStore()
const { openMyCaseFlyout } = useFlyouts()
const { navBar } = useNavigation()

const props = defineProps({
  content: {
    type: Object,
    required: true
  }
})
const showButton = computed(() => {
  return props.content.actionType === 'ship-external-link' ||
    props.content.secondaryActionType === 'chat' ||
    props.content.secondaryActionType === 'follow'
})
const buttonText = computed(() => {
  if (props.content.actionType === 'ship-external-link') {
    return 'Track'
  } else if (props.content.secondaryActionType === 'chat') {
    return 'Chat'
  } else if (props.content.secondaryActionType === 'follow') {
    return 'Follow'
  }
  return ''
})
function getMessage (notification) {
  return notification.markdownMessage ? notification.markdownMessage : notification.message
}
function safeNavUtil (name, params) {
  const shallowCompare = (obj1, obj2) =>
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(key =>
      Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key])
  if (router.currentRoute.value.name !== name || !shallowCompare(router.currentRoute.value, params)) {
    router.push({ name, params })
  }
}
function safeNav (name, params) {
  if (name === '') {
    // don't go anywhere; this is an inline external link in
    // the message
    return
  }
  if (props.content.actionType === 'mycase-reviews') {
    name = 'review-select'
    safeNavUtil(name)
  }
  if (props.content.actionType === 'mycase') {
    store.dispatch('case/setMyCaseFlyoutName', 'core')
    openMyCaseFlyout()
  }
  if (props.content.actionType === 'welcome') {
    window.open(props.content.externalActionUrl, '_blank')
  }
  if (props.content.actionType === 'ship-external-link' ||
    props.content.actionType === 'pickup-external-link') {
    window.open(props.content.externalActionUrl, '_blank')
  } else {
    safeNavUtil(name, params)
  }
}
function getRouteNameFromActionType (actionType) {
  switch (actionType) {
    case 'profile':
    case 'feed':
    case 'referral':
    case 'rewards':
    case 'closet-section':
      return actionType
    case 'feeditem':
      return 'feed-item'
    case 'stylecolor':
      return 'closet-detail'
    case 'generic-inline-external-link':
      return ''
    case 'account-prepay':
      return 'prepay'
    default:
      return 'closet-sections'
  }
}
function onPrimaryImageClick () {
  let actionType = props.content.primaryImageActionType
  let actionId = props.content.primaryImageActionId
  if (!actionType) {
    actionType = props.content.actionType
    actionId = props.content.actionId
  }
  const params = { id: actionId }
  if (props.content.primaryImageActionName) {
    params.name = props.content.primaryImageActionName
  }
  safeNav(getRouteNameFromActionType(actionType), params)
  navBar.collapse('notification')
  trackInteraction()
}
function trackInteraction () {
  track('Notification Interaction', {
    actionType: props.content.actionType,
    message: getMessage(props.content),
    title: props.content.title ? props.content.title : '',
    actionId: props.content.actionId ? props.content.actionId : '',
    notificationSource: 'webApp'
  })
}
async function onContentClick (source = '') {
  if (props.content.secondaryActionType === 'chat' && source === 'button') {
    // eslint-disable-next-line
    window.Intercom('show')
  } else if (props.content.secondaryActionType === 'follow' && source === 'button') {
    await store.dispatch('community/followClient', props.content.secondaryActionId)
    store.dispatch('notifications/deleteNotification', props.content)
    // redirect to the feed, filtered by following
    navBar.collapse('notification')

    safeNav('feed-by-source', { source: 'following' })
  } else {
    const params = props.content.actionId ? { id: props.content.actionId } : {}
    if (props.content.actionName) {
      params.name = props.content.actionName
    }
    navBar.collapse('notification')

    safeNav(getRouteNameFromActionType(props.content.actionType), params)
  }
  trackInteraction()
}
</script>

<style lang="scss" scoped>
.notification-item {
  --image-container-size: 32px;
  --image-size: 20px;
  display: flex;
  padding: 12px;

  .notification-item-image-container {
    height: var(--image-container-size);
    width: var(--image-container-size);
    border-radius: 50%;
    border: $dark-border;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .primary-image {
    height: var(--image-container-size);
    width: var(--image-container-size);
  }

  .primary-image-dress {
    height: var(--image-size);
    width: var(--image-size);
    margin: auto;
  }

  .notification-item-new {
    position: relative;
    left: 24px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $orchid;
    top: calc(var(--image-container-size) * -1);
  }
}

.notification-item-content-wrapper {
  padding-left: 12px;
}
</style>
