<template>
  <div class="dropdown">
    <plain-button
      class="p-0"
      @click="onClick">
      <slot name="button"/>
    </plain-button>
    <div
      v-if="showDropdown"
      v-click-outside="closeDropdown"
      class="dropdown-menu show"
      :class="variant">
      <slot
        name="dropdown-content"
        :close-dropdown="closeDropdown"/>
    </div>
  </div>
</template>

<script>
import PlainButton from './PlainButton'

export default {
  components: {
    PlainButton
  },
  props: {
    variant: {
      type: String,
      default: 'left',
      validator: value => {
        return value.match(/(left|right)/)
      }
    }
  },
  data () {
    return {
      showDropdown: false
    }
  },
  methods: {
    onClick () {
      this.openDropdown()
      this.$emit('click')
    },
    closeDropdown () {
      this.showDropdown = false
    },
    openDropdown () {
      this.showDropdown = true
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  box-shadow: 0 0 0.3rem 0.3rem rgba($ash, 0.15);
  padding: 20px;
  width: 280px;

  &.left {
    left: 0;
    right: unset;
  }

  &.right {
    right: 0;
    left: unset;
  }
}

</style>
