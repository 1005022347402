import api from '../api/api'
import { getSetters, parseError } from './helpers/shared'

export const state = {
  beTheBuyerStylesToRate: [],
  beTheBuyerDailySummary: null,
  upcomingStylesToRate: [],
  styleGamesLoading: true,
  upcomingStylesRatedIndex: 0,
  beTheBuyerStylesRatedIndex: 0
}

export const getters = {
  hasBeTheBuyerStylesToRate: (state) => {
    return state.beTheBuyerStylesToRate?.length > state.beTheBuyerStylesRatedIndex
  },
  hasUpcomingStylesToRate: (state) => {
    return state.upcomingStylesToRate?.length > state.upcomingStylesRatedIndex
  },
  showStyleGamesPrompts: (state, getters) => {
    return getters.hasBeTheBuyerStylesToRate || getters.hasUpcomingStylesToRate
  }
}

export const actions = {
  async initializeStyleGames ({ dispatch, rootState }) {
    try {
      dispatch('getUpcomingStylesToRate')
      if (rootState.client.membershipStatus === 'active') dispatch('getBeTheBuyerStylesToRate') // Member Exclusive
    } catch (err) {
      throw parseError(err)
    }
  },
  async getUpcomingStylesToRate ({ commit }, offset = 0) {
    try {
      const res = await api.apiStyleGames.getUpcomingStylesToRate(offset)
      commit('SET_UPCOMING_STYLES_TO_RATE', res.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getBeTheBuyerStylesToRate ({ commit }) {
    try {
      const res = await api.apiStyleGames.getBeTheBuyerStyles()
      commit('SET_BE_THE_BUYER_STYLES_TO_RATE', res.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getBeTheBuyerSummary ({ commit }) {
    try {
      const res = await api.apiStyleGames.getBeTheBuyerDailySummary()
      commit('SET_BE_THE_BUYER_DAILY_SUMMARY', res.data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async rateBeTheBuyerStyle (context, data) {
    try {
      const { id, rating, index } = data
      await api.apiStyleGames.rateBeTheBuyerStyleColor({
        rating,
        prebuyStyleColor: { id },
        index: index
      })
    } catch (err) {
      throw parseError(err)
    }
  },
  async rateUpcomingStyle ({ commit }, data) {
    try {
      const { styleColorId, rating, index } = data

      await api.apiClient.setStyleColorRating({
        style_color_id: styleColorId,
        liked: rating,
        source: 'explore',
        index: index,
        isUpcomingStyle: true
      })

      commit('closet/SET_RATING', { styleColorId, value: rating }, { root: true })
    } catch (err) {
      throw parseError(err)
    }
  }
}

export const mutations = {
  ...getSetters(state)
}
