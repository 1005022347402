import axios from './config'
import store from '../index'

// AUTH
const apiReview = {
  getClientReviewsSummary () {
    const username = store.state.client.username
    return axios.get('/api/clients/' + username + '/reviews_summary/')
  },
  submitReviews (data) {
    const username = store.state.client.username
    return axios.post('/api/clients/' + username + '/submit_reviews/', data)
  },
  swapItems (data) {
    return axios.post('/api/packageitems/mark_returning/', data)
  },
  updateReviewPhotos (reviewId, images) {
    return axios.put('/api/itemfeedbacks/' + reviewId + '/images/', images)
  }
}

export default apiReview
