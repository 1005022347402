<template>
  <Transition
    name="slide-up-transition"
    mode="out-in"
    appear>
    <slot/>
  </Transition>
</template>

<style lang="scss">
.slide-up-transition {
  &-enter-active,
  &-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  &-enter-from {
    transform: translateY(100%);
    opacity: 0;
  }
  &-leave-to {
    transform: translateY(100%);
    opacity: 0;
  }
}
</style>
