<template>
  <div>
    <div
      class="row">
      <div class="col pe-1 my-sm">
        <base-input
          v-model="data.shippingFirstName"
          :validations="v$.shippingFirstName"
          autocomplete="given-name"
          input-id="first-name"
          label="First name"/>
      </div>
      <div class="col ps-1 my-sm">
        <base-input
          v-model="data.shippingLastName"
          :validations="v$.shippingLastName"
          autocomplete="family-name"
          input-id="last-name"
          label="Last name"/>
      </div>
    </div>
    <div class="my-sm">
      <base-input
        v-model="data.addressLine1"
        :validations="v$.addressLine1"
        autocomplete="shipping address-line1"
        label="Address"
        name="addressLine1"/>
    </div>
    <div class="my-sm">
      <base-input
        v-model="data.addressLine2"
        autocomplete="shipping address-line2"
        helper-text="Apartment, Suite, etc. (optional)"/>
    </div>
    <div class="my-sm">
      <base-input
        v-model="data.city"
        :validations="v$.city"
        autocomplete="shipping locality"
        label="City"/>
    </div>
    <div class="row">
      <div class="col my-sm">
        <base-input
          v-model="data.state"
          :validations="v$.state"
          autocomplete="shipping region"
          label="State"/>
      </div>
      <div class="col my-sm">
        <base-input
          v-model="data.zipcode"
          type="number"
          :validations="v$.zipcode"
          :cleave-options="{
            numericOnly: true,
            blocks: [5]}"
          autocomplete="shipping postal-code"
          label="ZIP code"/>
      </div>
    </div>
    <div
      v-if="props.showPhoneNumberInput"
      class="row my-sm">
      <div class="col">
        <base-input
          v-model="data.phone"
          type="tel"
          prepend="+1-"
          :validations="v$.phone"
          :cleave-options="{
            delimiter: '-',
            numericOnly: true,
            blocks: [3, 3, 4]}"
          autocomplete="tel tel-national"
          label="Phone"/>
        <base-checkbox
          v-if="data.showSMSConsent"
          v-model="data.consentToSMSMarketing"
          text="I agree to be contacted by SMS for marketing messages."
          class="mt-xs"
          :input-value="1"/>
      </div>
    </div>
    <base-checkbox
      v-if="props.showSetSelected"
      v-model="data.setAsSelectedShipping"
      text="Set as my selected shipping address"
      class="mt-2"
      :input-value="1"/>
  </div>
</template>

<script setup>
import BaseInput from '@/components/global/BaseInput.vue'
import BaseCheckbox from '@/components/global/BaseCheckbox.vue'
import { useVuelidate } from '@vuelidate/core'
import { onMounted, ref, defineExpose } from 'vue'
import { required, requiredUnless } from '@vuelidate/validators'
import { phone, stateAbbr, zipCode } from '../global/vuelidate/customValidators'

const props = defineProps({
  firstName: { type: String, default: '' },
  lastName: { type: String, default: '' },
  address: { type: Object, default: null },
  phone: { type: String, default: '' },
  showSetSelected: { type: Boolean, default: false },
  setSelectedInitialValue: { type: Boolean, default: true },
  showPhoneNumberInput: { type: Boolean, default: true },
  showSMSConsent: { type: Boolean, default: true }
})

const data = ref({
  shippingFirstName: '',
  shippingLastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipcode: '',
  phone: '',
  setAsSelectedShipping: false,
  consentToSMSMarketing: true
})

const rules = {
  shippingFirstName: {
    required
  },
  shippingLastName: {
    required
  },
  addressLine1: {
    required
  },
  city: {
    required
  },
  state: {
    required,
    stateAbbr
  },
  zipcode: {
    required,
    zipCode
  },
  phone: {
    required: requiredUnless(vm => {
      return vm.showPhoneNumberInput
    }),
    phone
  }
}
const v$ = useVuelidate(rules, data)

async function isValid () {
  return await v$.value.$validate()
}

defineExpose({
  data,
  isValid
})

onMounted(() => {
  data.value.shippingFirstName = props.firstName
  data.value.shippingLastName = props.lastName

  if (props.phone.indexOf('+1-') === 0) {
    data.value.phone = props.phone.replace('+1-', '')
  } else if (props.phone.indexOf('+1') === 0) {
    data.value.phone = props.phone.replace('+1', '')
  } else {
    data.value.phone = props.phone
  }
  data.value = {
    ...data.value,
    ...props.address
  }
})

</script>
