<template>
  <div>
    <plain-button
      class="w-100 p-0"
      :disabled="disabled"
      @click="$emit('add-photo-click')">
      <add-photo-cta
        ref="imageArea"
        cta="Add a photo to your look"
        :images="lookBuilder.images"/>
    </plain-button>
    <plain-button
      class="w-100 bg-white border-top py-2 px-4"
      :disabled="disabled"
      @click="$emit('tag-styles-click')">
      <tag-styles-cta
        :style-colors="lookBuilder.styleColors"/>
    </plain-button>
    <tag-occasion-cta
      :selected-tags="lookBuilder.tags"
      :occasion-tags="occasionTags"
      :disabled="disabled"
      @full-tag-occasion-clicked="$emit('tag-occasion-click')"
      @tag-occasion-clicked="tagOccasion"/>
    <div
      class="bg-white border-top share-details"
      :class="{ 'mobile': isMobile, 'disabled': disabled }">
      <h5 v-if="localCaption === ''">
        Share Details...
      </h5>
      <textarea
        ref="captionTextArea"
        v-model="localCaption"
        rows="4"
        :disabled="disabled"
        class="caption-text-area"
        placeholder="Writing a quick caption helps other members better understand this item's fit and quality—and we always love hearing your styling tips!"
        @keyup="debouncedCommitCaption"
        @focus="scrollUp"
        @blur="scrollDown"/>
    </div>
  </div>
</template>

<script>
import AddPhotoCta from './AddPhotoCta'
import PlainButton from '../../global/sequin/PlainButton'
import TagOccasionCta from './TagOccasionCta'
import TagStylesCta from './TagStylesCta'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AddPhotoCta,
    PlainButton,
    TagOccasionCta,
    TagStylesCta
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localCaption: '',
      debounceTimeout: null
    }
  },
  computed: {
    ...mapState('community', [
      'occasionTags',
      'lookBuilder'
    ]),
    ...mapGetters('community', [
      'clientCanInteractCommunity',
      'clientCanShareLook'
    ])
  },
  mounted () {
    const promises = []
    if (this.occasionTags.length === 0) {
      promises.push(this.getOccasionTags())
    }

    Promise.all(promises).then(() => {
      // If we received tags from the server for look builder,
      // we need to get their iconUrl from the occasion tags array
      if (this.lookBuilder.tags.length > 0 && !this.lookBuilder.tags[0].iconUrl) {
        const occasionTagNames = this.occasionTags.map(tag => tag.tag)
        this.tagOccasion([this.occasionTags[occasionTagNames.indexOf(this.lookBuilder.tags[0].tag)]])
      }
    })

    this.localCaption = this.lookBuilder.caption
  },
  methods: {
    ...mapActions('community', [
      'getOccasionTags',
      'postLook',
      'clearLookBuilder'
    ]),
    tagOccasion (tag) {
      this.$store.commit('community/SET_LOOK_BUILDER_TAGS', tag)
    },
    scrollUp () {
      // help mobile users write their caption
      if (this.isMobile) {
        this.$refs.captionTextArea.scrollIntoView({ behavior: 'smooth' })
      }
    },
    scrollDown () {
      if (this.isMobile) {
        this.$refs.imageArea.$el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    debouncedCommitCaption () {
      clearTimeout(this.debounceTimeout)

      // Once a user pauses typing for at least half second,
      // commit their local caption to the store
      this.debounceTimeout = setTimeout(() => {
        this.$store.commit('community/SET_LOOK_BUILDER_CAPTION', this.localCaption)
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.share-details {
  padding: 24px;

  &.disabled {
    opacity: .7;
  }

  &.mobile {
    height: 70vh;
  }

  .caption-text-area {
    width: 100%;
    padding: 0;
    outline: none;
    border: none;
    resize: none;

    &:focus {
      outline: none;
    }

    &:disabled {
      background: $white;
    }

    &::-webkit-input-placeholder {
      color: $ash;
    }
  }
}
</style>
