<template>
  <img
    class="img-fluid"
    @load="$emit('load')">
</template>

<style lang="scss" scoped>
  img {
    max-width: 100%;
    max-height: 100%;
  }
</style>
