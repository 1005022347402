<template>
  <form @submit.prevent="submit">
    <base-form-errors :errors="formErrors"/>
    <address-input
      ref="address"
      :first-name="firstName"
      :last-name="lastName"
      :address="address"
      :phone="mainPhone"
      show-sms-consent
      :show-phone-number-input="showPhoneNumberInput"
      :show-set-selected="setAsSelectedShipping"/>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AddressInput from '@/components/subscribe/AddressInput.vue'
import BaseFormErrors from '@/components/global/BaseFormErrors.vue'

export default {
  components: {
    AddressInput,
    BaseFormErrors
  },
  props: {
    // If an address is passed in, this will update the address
    // instead of creating a new address.
    address: {
      type: Object,
      default: null
    },
    showSetSelected: {
      type: Boolean,
      default: false
    },
    setSelectedInitialValue: {
      type: Boolean,
      default: true
    },
    showPhoneNumberInput: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      formErrors: [],
      setAsSelectedShipping: false,
      consentToSMSMarketing: true
    }
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'lastName',
      'shippingAddress',
      'mainPhone'
    ])
  },
  mounted: function () {
    this.setAsSelectedShipping = this.setSelectedInitialValue
  },
  methods: {
    ...mapActions('account', [
      'addAddress',
      'updateAddress',
      'updateClient',
      'selectShippingAddress'
    ]),
    async submit () {
      const address = this.$refs.address
      if (!address.isValid()) {
        return
      }
      let addedAddress = null

      const clientData = {
        firstName: address.data.shippingFirstName,
        lastName: address.data.shippingLastName
      }
      if (this.showPhoneNumberInput) {
        clientData.main_phone = address.data.phone
        clientData.consent_to_sms_marketing = address.data.consentToSMSMarketing
      }

      try {
        await this.updateClient(clientData)

        const data = {
          addressLine1: address.data.addressLine1,
          addressLine2: address.data.addressLine2,
          city: address.data.city,
          state: address.data.state.trim().toUpperCase(),
          zipcode: address.data.zipcode
        }

        if (this.address !== null && this.address.id) {
          data.id = this.address.id
          await this.updateAddress(data)
        } else {
          addedAddress = await this.addAddress(data)
        }
        if (this.setAsSelectedShipping) {
          await this.selectShippingAddress(addedAddress || data)
        }
        this.$emit('address-added-updated')
        this.formErrors = []
      } catch (error) {
        if (error.correctable) {
          this.$emit('correctable-address-error', { error, setAsSelectedShipping: this.setAsSelectedShipping })
        } else {
          this.formErrors = [
            'Sorry, there appears to be an error in your address. ' +
            'Please make sure you entered it correctly.']
          if (error && error.constructor === Array) {
            this.formErrors = this.formErrors.concat(error)
          } else if (error.errors) {
            this.formErrors = this.formErrors.concat(error.errors)
          }
        }
      }
    }
  }
}
</script>
