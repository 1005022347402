<template>
  <div>
    <p class="visit-case">
      <slot name="preLinkText">
        Visit
      </slot>
      <text-link
        size="paragraph"
        @click="$emit('my-case-link')">
        <slot name="linkText">
          Case
        </slot>
      </text-link>
      <slot name="postLinkText">
        for more details.
      </slot>
    </p>
  </div>
</template>

<script>
import TextLink from '../sequin/TextLink.vue'

export default {
  components: {
    TextLink
  }
}
</script>

<style lang="scss" scoped>
.swap-available-icon {
  padding: 0 .35rem;
  border: 1.25px solid #000000;
  border-radius: 4px;
  display: flex;
  width: fit-content;
}

</style>
