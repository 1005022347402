<template>
  <div
    :class="{ 'site-page': !isMobile}">
    <div
      v-if="isMobile">
      <action-header
        class="border-bottom p-3"
        :fallback-route="backButtonDefaultRoute">
        <span v-if="title && title.length">{{ title }}</span>
        <template #rightHeaderSlot>
          <slot name="headerSlotMobile"/>
        </template>
      </action-header>
      <div class="mx-3 mt-4">
        <slot/>
      </div>
    </div>
    <div v-else>
      <div>
        <div
          class="d-flex justify-content-center align-items-center position-relative">
          <back-button
            class="back"
            with-border
            :back-default-route="backButtonDefaultRoute"/>
          <text-line-limit
            v-if="title"
            tag="h4"
            class="my-1 header">
            {{ title }}
          </text-line-limit>
          <text-placeholder
            v-else
            class="mt-1"
            variant="h4"/>
        </div>
        <slot name="headerSlotDesktop"/>
      </div>
      <div class="ps-4 mt-5">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import ActionHeader from './ActionHeader.vue'
import BackButton from './BackButton.vue'
import TextLineLimit from './TextLineLimit.vue'
import TextPlaceholder from './TextPlaceholder.vue'

export default {
  components: {
    ActionHeader,
    BackButton,
    TextLineLimit,
    TextPlaceholder
  },
  props: {
    title: {
      type: String,
      required: true
    },
    backButtonDefaultRoute: {
      type: Object,
      default: () => ({ name: 'closet-sections' })
    }
  }
}
</script>

<style lang="scss" scoped>
.site-page {
  margin: 48px auto;
  max-width: 1100px;
  padding-right: 24px;
}

.header {
  width: 70vw;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
}

.back {
  position: absolute;
  left: 24px;
  width: 40px;
  height: 40px;
  top: 0;
}
</style>
