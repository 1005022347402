<template>
  <div
    class="col-12 col-md-6 mx-auto">
    <action-header
      page-header
      no-navigation
      class="p-3"
      :show-confirm="shouldShowConfirm"
      :disabled="isSaving"
      @back-click="goBack()"
      @confirm-click="onSave()">
      <template #back>
        <svg-x/>
      </template>
      Edit Comment
      <template #confirm>
        <div class="d-flex align-items-center font-weight-bold text-primary">
          <p class="m-0">
            Save
          </p>
        </div>
      </template>
    </action-header>
    <error-message v-if="saveError">
      {{ errorMessage }}
    </error-message>
    <edit-comment
      focus-on-mount
      :initial-comment="itemInFocus.item.comment"
      class="d-flex p-3"
      @comment-update="updateComment"/>
  </div>
</template>

<script>
import ActionHeader from '../../../global/sequin/ActionHeader'
import EditComment from './EditComment'
import ErrorMessage from '../../common/ErrorMessage'
import { mapState, mapActions } from 'vuex'
import goBack from '../../../global/mixins/goBack'
import SvgX from '@/components/global/svg/SvgX.vue'

export default {
  components: {
    ActionHeader,
    EditComment,
    ErrorMessage,
    SvgX
  },
  mixins: [goBack],
  data () {
    return {
      isSaving: false,
      saveError: false,
      errorMessage: 'We encountered an error while trying to save your comment. Please try again.',
      comment: ''
    }
  },
  computed: {
    ...mapState('community', [
      'itemInFocus'
    ]),
    shouldShowConfirm () {
      return this.comment !== this.itemInFocus.item.comment
    }
  },
  mounted () {
    this.comment = this.itemInFocus.item.comment
  },
  methods: {
    ...mapActions('community', [
      'updateFeedItemComment'
    ]),
    updateComment (comment) {
      this.comment = comment
    },
    async onSave () {
      this.isSaving = true
      this.saveError = false

      try {
        await this.updateFeedItemComment({ ...this.itemInFocus, comment: this.comment })
      } catch {
        this.saveError = true
        this.isSaving = false
      }

      if (!this.saveError) {
        this.goBack()
      }
    }
  }
}
</script>
