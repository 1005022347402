<template>
  <div class="comment-bar">
    <error-message v-if="saveError">
      {{ errorMessage }}
    </error-message>
    <div class="comment-bar-content d-flex justify-content-between align-items-start">
      <edit-comment
        ref="editComment"
        class="edit-comment"
        :disabled="disabled"
        @comment-update="updateComment"
        @comment-height-change="$emit('comment-height-change')"/>
      <plain-button
        class="text-primary bg-origami my-0 mx-2 p-2"
        :disabled="disabled || comment.trim().length === 0"
        @click="postComment">
        <h5 class="m-0">
          Post
        </h5>
      </plain-button>
    </div>
  </div>
</template>

<script>
import EditComment from './update/EditComment'
import PlainButton from '../../global/sequin/PlainButton'
import ErrorMessage from '../common/ErrorMessage'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    EditComment,
    ErrorMessage,
    PlainButton
  },
  props: {
    feedItemId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      comment: '',
      isSaving: false,
      saveError: false,
      errorMessage: 'We encountered an error while trying to post your comment. Please try again.'
    }
  },
  computed: {
    ...mapGetters('community', [
      'clientCanInteractCommunity'
    ]),
    disabled () {
      return this.isSaving || !this.clientCanInteractCommunity
    }
  },
  methods: {
    ...mapActions('community', [
      'postFeedItemComment'
    ]),
    focus () {
      this.$refs.editComment.focus()
    },
    updateComment (comment) {
      this.comment = comment
    },
    async postComment () {
      this.isSaving = true
      this.saveError = false

      const data = {
        comment: this.comment,
        feedItem: this.feedItemId
      }

      try {
        await this.postFeedItemComment(data)
      } catch (err) {
        this.saveError = true
      }

      this.isSaving = false

      if (!this.saveError) {
        this.$refs.editComment.reset()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-bar {
  position: fixed;
  z-index: 1022;
  bottom: 0;
  right: 0;
  left: 0;
}

.comment-bar-content {
  background-color: $origami;
  padding: 12px;
}

.edit-comment {
  width: calc(100% - 64px)
}
</style>
