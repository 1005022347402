<template>
  <component
    :is="condensed ? 'text-line-limit' : 'div'"
    class="caption-overflow"
    :line-limit="3">
    <span v-html="linkHashTags(parseLinks(caption))"/>
  </component>
</template>

<script>
import linkParsing from '../../global/mixins/linkParsing'
import TextLineLimit from '../../global/sequin/TextLineLimit.vue'

export default {
  components: {
    TextLineLimit
  },
  mixins: [linkParsing],
  props: {
    condensed: {
      type: Boolean,
      default: false
    },
    caption: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.caption-overflow {
  word-break: break-word;
  overflow-wrap: break-word;
}

.hashtaglink {
  border-bottom: 0;
}
</style>
