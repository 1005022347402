<template>
  <div>
    <div
      class="d-flex align-items-center">
      <div class="button-wrapper">
        <plain-button
          v-if="leftArrowVisible"
          class="scroll-button me-2"
          aria-label="Scroll right"
          @click="leftArrowClick">
          <svg-chevron-left
            :width="22"
            :height="22"
            stroke-width="1"
            class="scroll-chevron"/>
        </plain-button>
      </div>
      <div class="d-flex flex-column review-photo-gallery">
        <preview-image-button
          key="hero"
          class="hero-image mb-2"
          :src="reviewHeroImage.image"
          size="large"
          @click="viewPhotoAlbum(reviewHeroImage.photoAlbumIndex)"/>
        <div
          v-if="hasMultipleEntries"
          class="small-images-row">
          <preview-image-button
            v-for="entry in filteredEntries"
            :key="entry.id"
            :src="entry.image"
            class="p-0"
            @click="viewPhotoAlbum(entry.photoAlbumIndex)"/>
        </div>
      </div>
      <div
        class="button-wrapper">
        <plain-button
          v-if="rightArrowVisible"
          class="scroll-button ms-2"
          aria-label="Scroll right"
          @click="rightArrowClick">
          <svg-chevron-right
            :width="22"
            :height="22"
            stroke-width="1"
            class="scroll-chevron"/>
        </plain-button>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewImageButton from '@shared/components/ADORN/PreviewImageButton.vue'
import PlainButton from '../../global/sequin/PlainButton'
import { vuexAccessors } from '../../global/helpers/vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'

export default {
  components: {
    PreviewImageButton,
    PlainButton,
    SvgChevronLeft,
    SvgChevronRight
  },
  props: {
    styleColorId: {
      type: String,
      required: true
    },
    entries: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...vuexAccessors('closet', [
      'photoAlbumIndex'
    ]),
    reviewHeroImage () {
      return this.entries[0]
    },
    filteredEntries () {
      return this.entries.slice(0, 4)
    },
    hasMultipleEntries () {
      return this.entries.length > 1
    },
    leftArrowVisible () {
      const xx = this.reviewHeroImage
      const firstImage = this.filteredEntries[0]
      return (xx !== firstImage) && this.hasMultipleEntries
    },
    rightArrowVisible () {
      const xx = this.reviewHeroImage
      const entriesLength = this.filteredEntries.length
      const lastImage = this.filteredEntries[entriesLength - 1]
      return (xx !== lastImage) && this.hasMultipleEntries
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    photoGalleryClick (image) {
      const newHeroImage = image
      this.reviewHeroImage = newHeroImage
      return this.reviewHeroImage
    },
    rightArrowClick () {
      const heroImage = this.reviewHeroImage
      const indexOfHeroImage = this.filteredEntries.indexOf(heroImage)
      this.reviewHeroImage = this.filteredEntries[indexOfHeroImage + 1]
    },
    leftArrowClick () {
      const heroImage = this.reviewHeroImage
      const indexOfHeroImage = this.filteredEntries.indexOf(heroImage)
      this.reviewHeroImage = this.filteredEntries[indexOfHeroImage - 1]
    },
    viewPhotoAlbum (index) {
      this.photoAlbumIndex = index
      if (!this.isMobileWidth) {
        this.openModal({ name: 'member-photo-detail', context: { styleColorId: this.styleColorId } })
      } else {
        this.$router.push({
          name: 'closet-detail-member-photos',
          params: this.$route.params
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.small-images-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;

  :deep(.preview-image) {
    width: 34px;
  }
}
.button-wrapper {
  width: 30px;
}
.scroll-button {
  padding: 0;
  background: $white;
  color: $pewter;
  border: $default-border;
  .scroll-chevron {
   border-radius: 2px;
   padding: 4px;
  }
}

</style>
