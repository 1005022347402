import { track } from '../../plugins/analytics'
import api from '../api/api'
import router from '../../router'
import { getSetters, parseError } from './helpers/shared'
import { captureException } from '@sentry/vue'

// initial state
export const state = {
  searchSessionActive: false,

  autosuggestResults: [],
  autosuggestTerm: null,
  autosuggestMemberResults: [],
  autosuggestBrandResults: [],
  autosuggestCollectionResults: [],

  searchResults: null,
  searchResultsLoading: false,
  searchResultsTerm: null,
  searchMembersResults: [],
  searchMembersResultsLoading: false,
  searchMembersResultsNextPage: null,
  searchMembersResultsCount: 0,
  searchCollectionResults: {}, // Result set
  similarSearchTerms: [],

  // ideally we get some smart suggestions
  // hard-coding these for now
  emptyAutosuggestItems: [
    'maxi dress',
    'cardigan',
    'wide leg',
    'patterned blazer',
    'floral'
  ],

  recentSearches: JSON.parse(localStorage.getItem('recentSearches')) || [],

  // filtering
  filterOptions: {
    brands: [],
    categories: [],
    colors: [],
    seasons: [],
    patterns: [],
    fabrics: [],
    lengths: [],
    silhouettes: [],
    sleeveLengths: [],
    sleeveTypes: [],
    necklines: [],
    occasions: [],
    wash: [],
    rise: []
  },
  filters: {
    brands: [],
    categories: [],
    colors: [],
    seasons: [],
    patterns: [],
    fabrics: [],
    lengths: [],
    silhouettes: [],
    sleeveLengths: [],
    sleeveTypes: [],
    necklines: [],
    occasions: [],
    wash: [],
    rise: []
  },
  available: true,
  sizeFilter: true,
  reloadingStyleColors: false,
  sort: 'recommended'
}

export const getters = {
  emptyAutosuggestList: (state) => {
    let suggestions = state.recentSearches.map(item => { return { displayText: item, query: item, type: 'recent' } })

    suggestions = state.emptyAutosuggestItems.reduce((suggestionsToShow, item) => {
      if (suggestionsToShow.length < 10 && state.recentSearches.indexOf(item) === -1) {
        suggestionsToShow.push({ displayText: item, query: item, type: 'default' })
      }
      return suggestionsToShow
    }, suggestions)

    return suggestions
  }
}

async function getMembers (state, commit, query, firstPage = false) {
  try {
    if (state.searchMembersResultsLoading) {
      // don't fetch more notifications if we're already loading them
      // or if the view is open (since we don't want to move things around
      // when the viewer is looking at them)
      return
    }
    commit('SET_SEARCH_MEMBERS_RESULTS_LOADING', true)
    let res = null
    if (state.searchMembersResultsNextPage) {
      res = await api.apiSearch.getNextPage(state.searchMembersResultsNextPage)
      commit('APPEND_SEARCH_MEMBERS', res.data.results)
      commit('SET_SEARCH_MEMBERS_RESULTS_NEXT_PAGE', res.data.next)
    } else if (firstPage) {
      res = await api.apiSearch.searchMembers(query)
      commit('SET_SEARCH_MEMBERS_RESULTS', res.data.results)
      commit('SET_SEARCH_MEMBERS_RESULTS_NEXT_PAGE', res.data.next)
      commit('SET_SEARCH_MEMBERS_RESULTS_COUNT', res.data.count)
    }
  } catch (err) {
    captureException(err)
  } finally {
    commit('SET_SEARCH_MEMBERS_RESULTS_LOADING', false)
  }
}

export const actions = {
  startSearchSession ({ commit }) {
    commit('SET_SEARCH_SESSION_ACTIVE', true)
  },
  endSearchSession ({ commit }) {
    commit('SET_SEARCH_SESSION_ACTIVE', false)
    commit('SET_AUTOSUGGEST_RESULTS', [])
    commit('SET_AUTOSUGGEST_MEMBER_RESULTS', [])
    commit('SET_AUTOSUGGEST_BRAND_RESULTS', [])
    commit('SET_AUTOSUGGEST_COLLECTION_RESULTS', [])
  },
  clearAutosuggest ({ commit }) {
    commit('SET_AUTOSUGGEST_RESULTS', [])
    commit('SET_AUTOSUGGEST_MEMBER_RESULTS', [])
    commit('SET_AUTOSUGGEST_BRAND_RESULTS', [])
    commit('SET_AUTOSUGGEST_COLLECTION_RESULTS', [])
  },
  async getMoreMembers ({ state, commit }) {
    await getMembers(state, commit, null)
  },
  async getAutosuggest ({ state, commit, dispatch }, q) {
    try {
      q = q.trim()
      if (q) {
        commit('SET_AUTOSUGGEST_TERM', q)
        const apiCalls = []
        apiCalls.push(api.apiSearch.getAutosuggest(q))
        apiCalls.push(api.apiSearch.getAutosuggestMembers(q))
        apiCalls.push(api.apiBrands.searchBrands(q))
        apiCalls.push(dispatch('collections/searchCollections', { name: q, autosuggest: true }, { root: true }))
        const apiResults = await Promise.all(apiCalls)
        // disregard results if there's a new search term
        if (state.autosuggestTerm === q) {
          commit('SET_AUTOSUGGEST_RESULTS', apiResults[0].data.suggestionGroups[0].searchSuggestions)
          commit('SET_AUTOSUGGEST_MEMBER_RESULTS', apiResults[1].data)
          commit('SET_AUTOSUGGEST_BRAND_RESULTS', apiResults[2].data)
          commit('SET_AUTOSUGGEST_COLLECTION_RESULTS', apiResults[3].data)
        }
        return
      } else {
        commit('SET_AUTOSUGGEST_RESULTS', [])
        commit('SET_AUTOSUGGEST_MEMBER_RESULTS', [])
        commit('SET_AUTOSUGGEST_BRAND_RESULTS', [])
        commit('SET_AUTOSUGGEST_COLLECTION_RESULTS', [])
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  async search ({ state, commit }, data) {
    try {
      if (data.query) {
        data.skip = state.searchResults ? state.searchResults.styleColors.length : 0
        data.top = 100
        if (state.available) { data.available = true }
        data.sizeFilter = state.sizeFilter
        data.filters = { ...state.filters }

        const res = await api.apiSearch.search(data)
        commit('SET_SIMILAR_SEARCH_TERMS', [])

        if (res.data.redirectUrl) {
          router.push({ path: res.data.redirectUrl })
          return
        }

        if (data.skip > 0) {
          track('User Search Load More', {
            searchTerm: data.query,
            resultCount: res ? res.data.sectionLength : 1,
            offset: data.skip,
            items: res.data.styleColors.map(sc => { return sc.id })
          })
        }

        if (state.searchResults) {
          commit('APPEND_SEARCH_RESULT_STYLE_COLORS', res.data.styleColors)
        } else {
          commit('SET_SEARCH_RESULTS', res.data)
        }
        if (res.data.styleColors.length < 3) {
          const res = await api.apiSearch.fetchSimilarSearchTerms(data)
          commit('SET_SIMILAR_SEARCH_TERMS', res.data.similarTerms)
        }
        return res
      } else {
        commit('SET_SEARCH_RESULTS', {})
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  async reloadSearch ({ state, commit, dispatch }) {
    commit('SET_SEARCH_RESULTS', null)
    commit('SET_RELOADING_STYLE_COLORS', true)
    await dispatch('search', { query: state.searchResultsTerm })
    commit('SET_RELOADING_STYLE_COLORS', false)
  },
  async newSearch ({ state, commit, dispatch }, { data, isAutocomplete = false }) {
    commit('SET_SEARCH_RESULTS_TERM', data.query)
    commit('SET_SEARCH_RESULTS', null)
    commit('SET_AVAILABLE', true)
    commit('SET_SIZE_FILTER', true)
    commit('SET_SEARCH_MEMBERS_RESULTS', [])
    commit('SET_SEARCH_MEMBERS_RESULTS_NEXT_PAGE', null)
    commit('SET_SEARCH_MEMBERS_RESULTS_COUNT', 0)
    commit('CLEAR_SEARCH_COLLECTION_RESULTS')
    dispatch('clearSearchFilters')

    commit('SET_SEARCH_RESULTS_LOADING', true)
    const calls = []
    calls.push(dispatch('search', { query: state.searchResultsTerm }))
    calls.push(getMembers(state, commit, state.searchResultsTerm, true))
    calls.push(dispatch('searchCollections', { query: state.searchResultsTerm }))
    const results = await Promise.all(calls)

    if (results[0]) {
      commit('SET_FILTER_OPTIONS', results[0].data.filterOptions)
      commit('PREPEND_RECENT_SEARCH', state.searchResultsTerm)
    }
    commit('SET_SEARCH_RESULTS_LOADING', false)

    track('User Search', {
      searchTerm: data.query,
      isAutocomplete: isAutocomplete,
      resultCount: results[0] ? results[0].data.sectionLength : 0,
      offset: 0,
      items: results[0] ? results[0].data.styleColors.map(sc => { return sc.id }) : null
    })
  },
  updateSearchFilters ({ commit }, data) {
    commit('UPDATE_SEARCH_FILTERS', data)
  },
  clearSearchFilters ({ commit, rootState }) {
    commit('SET_FILTERS', { ...rootState.closet.baseFilters })
  },
  async searchCollections ({ state, commit, dispatch }, { query, nextPage = false }) {
    const { searchCollectionResults } = state
    try {
      if (searchCollectionResults.loading) {
        return
      }
      commit('SET_SEARCH_COLLECTION_RESULTS', { ...searchCollectionResults, loading: true })
      if (nextPage) {
        if (searchCollectionResults.next) {
          const res = await dispatch('collections/searchCollectionsNextPage', searchCollectionResults.next, { root: true })
          commit('SET_SEARCH_COLLECTION_RESULTS', {
            ...searchCollectionResults,
            results: [...searchCollectionResults.results, ...res.data.results],
            next: res.data.next
          })
        }
      } else {
        const res = await dispatch('collections/searchCollections', { name: query }, { root: true })
        commit('SET_SEARCH_COLLECTION_RESULTS', res.data)
      }
    } catch (err) {
      captureException(err)
    } finally {
      commit('SET_SEARCH_COLLECTION_RESULTS', {
        ...searchCollectionResults,
        loading: false
      })
    }
  },
  async getMoreCollections ({ dispatch }) {
    dispatch('searchCollections', { nextPage: true })
  }
}

export const mutations = {
  ...getSetters(state),
  'APPEND_SEARCH_RESULT_STYLE_COLORS' (state, styleColors) {
    state.searchResults.styleColors.push(...styleColors)
  },
  'UPDATE_SEARCH_FILTERS' (state, data) {
    state.filters[data.key] = data.value
  },
  'PREPEND_RECENT_SEARCH' (state, searchTerm) {
    state.recentSearches = state.recentSearches.reduce((recentSearches, query) => {
      if (query !== searchTerm && recentSearches.length < 5) recentSearches.push(query)
      return recentSearches
    }, [searchTerm])
    localStorage.setItem('recentSearches', JSON.stringify(state.recentSearches))
  },
  'APPEND_SEARCH_MEMBERS' (state, newSearchMembers) {
    state.searchMembersResults = state.searchMembersResults.concat(newSearchMembers)
  },
  'SET_SEARCH_COLLECTION_RESULTS' (state, data) {
    Object.keys(data).forEach(k => {
      state.searchCollectionResults[k] = data[k]
    })
  },
  'CLEAR_SEARCH_COLLECTION_RESULTS' (state) {
    state.searchCollectionResults = {}
  },
  setSimilarSearchTerms (state, terms) {
    state.similarSearchTerms = terms
  }
}
