<template>
  <div class="p-md-4 py-4 d-flex flex-column align-items-center">
    <h4
      class="text-center mt-2 mb-1"
      :class="{ 'mb-3': isClientsCollection }">
      <span v-if="isClientsCollection">
        <span v-if="isFirstRun">
          Add styles to this collection
        </span>
        <span v-else>
          Add similar styles to your Collection
        </span>
      </span>

      <span v-else>
        Similar to this Collection
      </span>
    </h4>
    <div
      v-if="!isClientsCollection"
      class="style-profile-cta">
      <p class="mb-3">
        Available now in your
        <text-link
          size="paragraph"
          variant="primary"
          type="router-link"
          name="style profile"
          :to="{ name: 'style-profile' }">
          saved sizes
        </text-link>
      </p>
    </div>
    <div
      v-if="isClientsCollection"
      class="d-flex justify-content-center text-center">
      <transition
        name="fade"
        mode="out-in">
        <div
          v-if="loading"
          class="d-flex flex-wrap col-12 justify-content-center"
          :class="suggestionContainerClass">
          <preview-image-placeholder
            v-for="i in maxNumSuggestions"
            :key="'placeholder-' + i"
            :size="suggestionImageSize"
            class="p-0 m-2"/>
        </div>
        <div
          v-else
          :key="suggestedStyleColors[0].id">
          <collection-select-style-colors
            v-model="selectedSuggestions"
            class="mx-auto"
            :image-size="suggestionImageSize"
            :container-class="`${suggestionContainerClass} mb-4`"
            :style-color-options="suggestedStyleColors.slice(0, maxNumSuggestions)"/>
          <collection-suggestions-confirmation
            v-if="showConfirmButton"
            class="mb-3"
            :collection-id="collectionId"
            :selected-suggestions="selectedSuggestions"
            @confirmation-success="selectedSuggestions = []"/>
        </div>
      </transition>
    </div>
    <div
      v-else
      class="suggestions">
      <div v-if="loading">
        <horizontal-scroll>
          <div
            v-for="i in maxNumSuggestions"
            :key="'placeholder-' + i"
            class="mx-2 mx-md-3">
            <style-color-preview-placeholder :size="isMobile ? 'medium' : 'large'"/>
          </div>
        </horizontal-scroll>
      </div>
      <div v-else>
        <horizontal-scroll>
          <style-color-preview
            v-for="styleColor in suggestedStyleColors.slice(0, maxNumSuggestions)"
            :key="styleColor.id"
            class="mx-2 mx-md-3"
            :style-color="styleColor"
            :size="isMobile ? 'medium' : 'large'"
            :style-color-set="{source: 'collection-suggestions'}"/>
        </horizontal-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CollectionSuggestionsConfirmation from './CollectionSuggestionsConfirmation.vue'
import CollectionSelectStyleColors from './CollectionSelectStyleColors.vue'
import PreviewImagePlaceholder from '@shared/components/ADORN/PreviewImagePlaceholder.vue'
import HorizontalScroll from '../global/sequin/HorizontalScroll'
import StyleColorPreview from '@shared/components/ADORN/StyleColorPreview.vue'
import StyleColorPreviewPlaceholder from '@shared/components/ADORN/StyleColorPreviewPlaceholder.vue'
import TextLink from '../global/sequin/TextLink.vue'

export default {
  components: {
    StyleColorPreviewPlaceholder,
    StyleColorPreview,
    HorizontalScroll,
    PreviewImagePlaceholder,
    CollectionSuggestionsConfirmation,
    CollectionSelectStyleColors,
    TextLink
  },
  props: {
    collectionId: {
      type: String,
      required: true
    },
    suggestionImageSize: {
      type: String,
      default: 'small',
      validator: v => v.match(/small|medium|large/)
    },
    maxNumSuggestions: {
      type: Number,
      default: 18
    },
    suggestionContainerClass: {
      type: String,
      default: ''
    },
    showConfirmButton: {
      type: Boolean,
      default: true
    },
    isClientsCollection: {
      type: Boolean,
      default: false
    },
    isFirstRun: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedSuggestions: [],
      confirming: false,
      loading: true
    }
  },
  computed: {
    ...mapState('collections', [
      'collectionSuggestionsById'
    ]),
    suggestedStyleColors () {
      return this.collectionSuggestionsById[this.collectionId]
    }
  },
  watch: {
    selectedSuggestions () {
      this.$emit('selected-suggestions-updated', this.selectedSuggestions)
    }
  },
  async mounted () {
    if (!this.suggestedStyleColors) {
      await this.getCollectionSuggestions({ collectionId: this.collectionId })
    }
    this.loading = false
  },
  methods: {
    ...mapActions('collections', [
      'getCollectionSuggestions'
    ])
  }
}
</script>

<style lang="scss" scoped>
.overlay-selected {
  width: 100%;
  height: 100%;
  background-color: rgba($secondary, .8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.suggestions {
  padding: 12px;
  width: 100%;
  max-width: 1100px;
}
</style>
