<template>
  <div>
    <div class="section-header mt-md-4 d-flex justify-content-between">
      <h4 class="mb-0">
        Sizing
      </h4>
      <div
        class="d-flex gap-2">
        <base-checkbox
          v-for="option in sizingTypes.filter(x => x.value !== 'waist')"
          :key="option.value"
          :value="option.visible"
          :input-value="option.visible"
          :text="option.text"
          :disabled="toggleDisabled(option.value)"
          col-class="mr-3"
          @input="toggleSizeTypeView($event, option.value, 'styleProfile')"/>
      </div>
    </div>
    <div>
      <accordion-with-header
        v-for="sizeAccordion in sizeAccordions('styleProfile')"
        :key="sizeAccordion.id"
        class="border-top-0"
        :expanded-max-height="600"
        :plus-variant="!sizeAccordion.selections.length"
        :expanded="expandedSection === sizeAccordion.id"
        :title="sizeAccordion.title"
        @accordion-click="e => $emit('accordion-click', e ? sizeAccordion.id : null)">
        <template #subtitle>
          <p
            :class="sizeAccordion.subtitle === 'Required' ? 'text-orchid' : ''"
            class="ms-2 mb-0 text-pewter">
            {{ sizeAccordion.subtitle }}
          </p>
        </template>
        <size-selection
          v-if="sizeAccordion.id === 'waist'"
          :key="waistSizeType.text"
          container-class="size-container"
          :sizes="waistSizeType.sizes"
          :title="waistSizeType.text"
          :initial-selections="waistSizes"
          :emit-ref="sizeAccordion.id"
          @update-size-selections="updateSizes"/>
        <size-selection
          v-for="sizeType in visibleSizeTypes"
          v-else
          :key="sizeType.value"
          container-class="size-container"
          :sizes="sizeType.sizes"
          :title="sizeType.text"
          :initial-selections="initialSizeSelections(sizeType.value, sizeAccordion.id, 'styleProfile')"
          :emit-ref="sizeAccordion.id"
          source="styleProfile"
          @update-size-selections="updateSizes($event, sizeType.value)"/>
      </accordion-with-header>
    </div>
  </div>
</template>

<script>
import AccordionWithHeader from '../global/sequin/AccordionWithHeader.vue'
import SizeSelection from '../global/sequin/SizeSelection.vue'
import sizeSelectionMixin from '../global/sequin/sizeSelectionMixin.js'
import BaseCheckbox from '@/components/global/BaseCheckbox.vue'

export default {
  components: {
    AccordionWithHeader,
    SizeSelection,
    BaseCheckbox
  },
  mixins: [sizeSelectionMixin],
  props: {
    expandedSection: {
      type: String,
      default: null
    },
    validations: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sizesInitialized: false
    }
  },
  methods: {
    async updateSizes (sizeData, sizeType) {
      sizeData.sizes = this.getFilteredSizes(sizeData, sizeType)
      await this.setSizesFromStyleProfile({ sizeData })
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.size-container) {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 12px;
}

:deep(.size-category-title) {
  margin-bottom: .75rem !important;
  font-family: 'Open Sans', sans-serif !important;
}

.section-header {
  width: 100%;
  background-color: $origami;
  padding: 16px 16px;
  border: $default-border;
  border-top-left-radius: $sequin-border-radius;
  border-top-right-radius: $sequin-border-radius;
}
</style>
