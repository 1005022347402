<template>
  <div class="d-flex">
    <plain-button
      v-for="index in 5"
      :key="index"
      class="rating-button"
      :disabled="disabled"
      @mouseover="mouseOverIndex = index"
      @mouseleave="mouseOverIndex = 0"
      @click="onRatingClick(index)">
      <svg-star
        :class="{'filled': starFilled(index)}"
        class="rating-star"/>
      <div
        class="fine-print rating-text"
        :class="{'visible': ratingTextVisible(index) }">
        {{ ratingsText[index - 1] }}
      </div>
    </plain-button>
  </div>
</template>

<script>
import PlainButton from './PlainButton'
import SvgStar from '@/components/global/svg/SvgStar.vue'

export default {
  components: {
    PlainButton,
    SvgStar
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mouseOverIndex: 0,
      ratingsText: ['Terrible', 'Bad', 'Okay', 'Great', 'Amazing'],
      rating: null
    }
  },
  methods: {
    starFilled (index) {
      return this.mouseOverIndex === 0
        ? this.rating && this.rating >= index
        : this.mouseOverIndex >= index
    },
    ratingTextVisible (index) {
      return this.mouseOverIndex === 0
        ? this.rating && this.rating === index
        : this.mouseOverIndex === index
    },
    onRatingClick (index) {
      this.rating = index
      this.$emit('rating-selected', this.rating)
    }
  }
}
</script>

<style lang="scss" scoped>
.rating-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 12px;
}

.rating-star {
  fill: rgba($ash, .5);
  stroke-width: 0;

  &.filled {
    fill: $secondary;
  }
}

.rating-text {
  visibility: hidden;
  text-align: center;
  position: absolute;
  bottom: 0;
  margin-bottom: -24px;

  &.visible {
    visibility: visible;
  }
}
</style>
