<template>
  <share-a-look-page edit-look/>
</template>

<script>
import ShareALookPage from './ShareALookPage'

export default {
  components: {
    ShareALookPage
  }
}
</script>
