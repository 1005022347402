<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.41521 14.3218L15.3942 7.34296C15.6536 7.11609 15.9952 6.99331 16.3475 7.00028C16.6999 7.00726 17.0358 7.14345 17.2851 7.3804L24.6234 14.357L25 14.715V25H17.9286V18.5827H14.0714V25H7V14.685L7.41521 14.3218ZM16.3199 8.22236C16.3086 8.22236 16.3156 8.22469 16.3204 8.22923C16.3254 8.22469 16.3312 8.22236 16.3199 8.22236ZM8.28572 23.7777H12.7857V18.5827C12.7857 18.2586 12.9212 17.9477 13.1623 17.7184C13.4034 17.4892 13.7304 17.3604 14.0714 17.3604H17.9286C18.2696 17.3604 18.5966 17.4892 18.8377 17.7184C19.0788 17.9477 19.2143 18.2586 19.2143 18.5827V23.7777H23.7143V15.2213L16.3199 8.2447C16.3194 8.24424 16.3193 8.24382 16.3189 8.24337L8.28572 15.2213V23.7777Z"
      fill="currentColor"/>
  </svg>
</template>
