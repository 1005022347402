import '@types'
import { captureException } from '@sentry/vue'
import useAnalytics from '@shared/composables/analytics.js'
import { computed, toValue } from 'vue'
import { useRouter } from 'vue-router'
import { useLogger } from 'vue-logger-plugin'

/**
 * Composable to determine the type of link to use based on the input.
 * @param {import('vue-router').RouteLocation | string} to
 * @param {object} [options]
 * @param {string} options.fallbackType The type of element to use if the link is not a RouterLink.
 */
export default function useLink (to, options = { fallbackType: 'a' }) {
  const router = useRouter()

  const isExternalLink = computed(() => /^https?:\/\//.test(toValue(to)))
  const isRouterLink = computed(() => {
    const value = toValue(to)
    if (!value || isExternalLink.value) {
      return false
    }
    try {
      const { matched: [{ redirect }] } = router.resolve(toValue(to))
      return redirect !== '/404'
    } catch (e) {
      return false
    }
  })
  /** Local to this site, but not to this router */
  const isLocalLink = computed(() => !isRouterLink.value && /^\//.test(toValue(to)))
  const isHyperlink = computed(() => isExternalLink.value || isLocalLink.value)

  const type = computed(() => isRouterLink.value ? 'RouterLink' : isHyperlink.value ? 'a' : options.fallbackType)
  const linkParams = computed(() =>
    isRouterLink.value
      ? { to: toValue(to) }
      : isHyperlink.value
        ? isExternalLink.value
          ? { href: toValue(to), target: '_blank' }
          : { href: toValue(to) }
        : null
  )

  function activate () {
    const value = toValue(to)
    if (isRouterLink.value) {
      router.push(value)
    } else if (isHyperlink.value) {
      if (isExternalLink.value) {
        window.open(value, '_blank')
      } else {
        window.location.href = value
      }
    }
  }

  return {
    isExternalLink,
    isRouterLink,
    isLocalLink,
    isHyperlink,
    type,
    linkParams,
    activate
  }
}

/**
 * Composable to consolidate click logic of TrackingButton and TrackingLink.
 * @param {ComposableInput<string>} appEntryPoint
 * @param {ComposableInput<string>} eventLabel
 * @param {import('vue').SetupContext['emit']} emit
 * @param {ComposableInput<ReturnType<useLink>>} link
 */
export function useTrackingLinkClick (appEntryPoint, eventLabel, emit, link) {
  const logger = useLogger()
  const { trackClickEvent } = useAnalytics()

  async function onClick (event) {
    const { isHyperlink, isExternalLink, activate } = toValue(link)
    const preventDefault = isHyperlink.value && !isExternalLink.value
    try {
      if (preventDefault) {
        // If an href is present, we'll prevent the navigation
        // until we have a chance to track the click event.
        event.preventDefault()
      }
      await trackClickEvent(toValue(appEntryPoint), toValue(eventLabel))
      emit('click', event)
    } catch (error) {
      logger.error(error)
      captureException(error)
    } finally {
      if (preventDefault) {
        activate()
      }
    }
  }

  return onClick
}
