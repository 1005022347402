<template>
  <BaseButton
    ref="button"
    @click="onClick"/>
</template>

<script setup>
import { ref } from 'vue'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import { useTrackingLinkClick } from '@shared/composables/link.js'

const props = defineProps({
  appEntryPoint: {
    type: String,
    required: true,
    validator: value => ['homepage', 'website', 'signup'].includes(value)
  },
  eventLabel: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})

const button = ref(null)
const onClick = useTrackingLinkClick(
  () => props.appEntryPoint,
  () => props.eventLabel,
  emit,
  () => button.value.link
)
</script>
