<template>
  <collection-preview-placeholder
    v-if="!favoritesSection || favoritesSection.length === null"
    :title-variant="variant === 'full-width' ? 'full-width' : 'overlay'"/>
  <full-width-collection-preview
    v-else-if="variant === 'full-width'"
    direction="reverse"
    name="Favorites Collection"
    :collection="{
      ...favoritesSectionAsCollection,
      previewDescription: 'Click the heart icon anywhere on Armoire to add styles to Favorites.'
    }"/>
  <empty-collection-card
    v-else-if="favoritesSection.length === 0"
    background-variant="gradient"
    class="empty-favorites"
    title="Favorites"
    icon="heart"
    title-variant="overlay-top">
    <div
      class="d-flex flex-column align-items-center text-pewter text-center"
      :class="{ 'small-text mt-4': isMobile }">
      <p class="semi-bold">
        See something you love?
      </p>
      <p>
        Tap the heart icon to save it to Favorites.
      </p>
    </div>
  </empty-collection-card>
  <collection-preview
    v-else
    background-variant="gradient"
    :send-default-analytics-events="false"
    :collection="favoritesSectionAsCollection"/>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { track } from '../../plugins/analytics'
import { slugify } from '@/utils/stringParsing.js'
import EmptyCollectionCard from './EmptyCollectionCard.vue'
import CollectionPreview from './CollectionPreview.vue'
import CollectionPreviewPlaceholder from './CollectionPreviewPlaceholder.vue'
import FullWidthCollectionPreview from './FullWidthCollectionPreview.vue'
import { useFavoritesSection } from '@/composables/closetSection'
import SvgHeart from '@/components/global/svg/SvgHeart.vue'

export default {
  components: {
    EmptyCollectionCard,
    CollectionPreview,
    CollectionPreviewPlaceholder,
    FullWidthCollectionPreview
  },
  props: {
    variant: {
      type: String,
      validator: value => value.match(/(default|full-width)/),
      default: 'default'
    }
  },
  setup () {
    useFavoritesSection({ loadOnMount: true })
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    ...mapGetters('closet', [
      'favoritesSection'
    ]),
    encodedName () {
      return slugify(this.favoritesSection.name)
    },
    favoritesSectionAsCollection () {
      if (this.favoritesSection) {
        const styleColors = this.favoritesSection.styleColors.map(styleColorId => this.styleColorsMap[styleColorId])
        return {
          name: 'Favorites',
          description: 'Items you\'ve favorited',
          length: `${this.favoritesSection.length} style${this.favoritesSection.length === 1 ? '' : 's'} available`,
          client: {
            id: this.id
          },
          previewIcon: SvgHeart,
          toRoute: { name: 'closet-section', params: { id: this.favoritesSection.id, name: this.encodedName } },
          styleColors,
          previewImages: styleColors.map(sc => sc.images.front.url)
        }
      }
      return {}
    }
  },
  async mounted () {
    if (this.favoritesSection?.length === null) {
      await this.loadStyleColors({
        id: this.favoritesSection.id,
        amount: 20
      })
    }
    if (this.favoritesSection) {
      track('Viewed Closet Section Preview', {
        name: this.favoritesSectionAsCollection.name,
        sectionId: this.favoritesSection.id,
        items: this.favoritesSection.styleColors.slice(0, 5),
        displayType: 'collection-preview'
      })
    }
  },
  methods: {
    ...mapActions('closet', [
      'loadStyleColors'
    ])
  }
}
</script>

<style lang="scss" scoped>
.favorites-preview-full-width {
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  flex-direction: row-reverse;
}

.empty-favorites {
  border-radius: 8px;
  border: $default-border;
  overflow: hidden;
}

.favorites-images {
  width: 76%;
  flex-direction: row-reverse;
  padding-left: 72px;
}

.favorites-title {
  display: flex;
  width: 24%;
  flex-direction: column;
  justify-content: center;
  @include sequin-gradient;
  padding: 36px 32px;
}

.preview-image {
  border-radius: 8px;
  margin-left: -72px;
  overflow: hidden;
}
</style>
