<template>
  <img
    :width="width"
    :height="height"
    :src="getPlaceholderIllustrations()">
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100'
    },
    height: {
      type: String,
      default: '100'
    }
  },
  data () {
    return {
      placeholderIllustrations: [`${process.env.VUE_APP_WEBSITE_IMAGES_URL}look-s.png`, `${process.env.VUE_APP_WEBSITE_IMAGES_URL}look2-s.png`]
    }
  },
  methods: {
    getPlaceholderIllustrations () {
      return this.placeholderIllustrations[Math.round(Math.random()) * (this.placeholderIllustrations.length - 1)]
    }
  }
}
</script>
