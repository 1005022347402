<template>
  <p class="staff">
    Armoire Team
  </p>
</template>

<style lang="scss" scoped>
.staff {
  color: $ash;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
</style>
