<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-plus"
    :class="{'circle-bg': variant === 'circular' }">
    <line
      x1="12"
      y1="5"
      x2="12"
      y2="19"/>
    <line
      x1="5"
      y1="12"
      x2="19"
      y2="12"/>
  </svg>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'plain',
      validator: value => {
        return value.match(/(plain|circular)/)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.circle-bg {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
}

</style>
