<template>
  <div>
    <component
      :is="isTabletOrSmaller ? 'h4' : 'h2'"
      class="text-center">
      Similar search terms
    </component>
    <div class="similar-search-grid">
      <sequin-button
        v-for="(term, index) in similarSearchTerms"
        :key="index"
        class="similar-search-item"
        variant="secondary"
        @click="searchSimilarTerms(term)">
        <svg-search/>
        <p>
          {{ term }}
        </p>
      </sequin-button>
    </div>
  </div>
</template>
<script setup>
import SequinButton from '../global/sequin/SequinButton'
import SvgSearch from '@/components/global/svg/SvgSearch.vue'
import useScreenSize from '@shared/composables/screenSize'

defineProps({
  similarSearchTerms: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['search-similar-terms'])
const { isTabletOrSmaller } = useScreenSize()
function searchSimilarTerms (term) {
  emit('search-similar-terms', term)
}

</script>
<style lang="scss" scoped>
.similar-search {
  &-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
    @media screen and (min-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &-item {
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  p {
    margin: 0 1em;
    text-transform: capitalize;
    }
  }
}
</style>
