<template>
  <div>
    <span v-if="membershipStatus === 'paused'">
      Your subscription was paused on {{ pauseDate }} and is set to resume on {{ resumeDate }}
    </span>
    <span v-else-if="membershipStatus === 'unpaid'">
      We weren't able to process your credit card successfully. Please update
      your card below, as some of your personal information may have changed.
    </span>
    <span v-else-if="membershipStatus === 'former_member'">
      Your membership is currently inactive, re-subscribe today to get your next case.
    </span>
    <span v-else-if="active">
      <div
        v-if="membershipStatus === 'active'"
        class="plan-name">
        Active: {{ plans.currentBasePlan.displayName }}
      </div>
      <div
        v-if="planItems">
        {{ planItems }} item case
      </div>
      <div v-if="membershipDetails.allowedShipments">
        Shipments available: <b>{{ membershipDetails.allowedShipments }}</b>
      </div>
      <div
        v-if="shipments">
        <span v-if="shipments === 'Unlimited'">
          {{ shipments }} Shipments every {{ interval }}
        </span>
        <span v-else>
          <b>{{ shipments }}</b> Shipment{{ shipments > 1 ? 's' : '' }} every {{ interval }}
        </span>
      </div>
      <div>
        <div
          v-if="plans.currentBasePlan.membersPrice === 0">
          Free
        </div>
        <div
          v-else>
          <span v-if="plans.currentBasePlan.chargeInterval > 1">
            Price every {{ plans.currentBasePlan.chargeInterval }} months:
          </span>
          <span v-else>
            Monthly price:
          </span> <b>{{ formatPrice(plans.currentBasePlan.membersPrice) }}</b>
        </div>
      </div>
    </span>
    <span
      v-else-if="membershipStatus === 'items_out_pending_cancel'">
      Your subscription is currently pending cancellation.
    </span>
    <span v-else>
      Subscribe today to get your first case.
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { toLocaleDateString, formatPrice } from '@/utils/stringParsing.js'

export default {
  computed: {
    ...mapState('client', [
      'membershipStatus',
      'membershipDetails',
      'paidThrough',
      'plans'
    ]),
    ...mapGetters('client', [
      'active'
    ]),
    planItems () {
      if (this.plans.currentUpgradePlan) {
        return this.plans.currentUpgradePlan.nItems
      }
      if (this.plans.currentBasePlan) {
        return this.plans.currentBasePlan.nItems
      }
      return 0
    },
    pauseDate () {
      return toLocaleDateString(this.membershipDetails.pauseDate)
    },
    resumeDate () {
      return toLocaleDateString(this.membershipDetails.pauseResumeDate)
    },
    shipments () {
      if (this.plans.currentBasePlan.shipmentsPerInterval) {
        return this.plans.currentBasePlan.shipmentsPerInterval
      }
      if (this.planItems) {
        return 'Unlimited'
      }
      return null
    },
    interval () {
      if (this.plans.currentBasePlan.chargeInterval === 1) { return 'month' }
      if (this.plans.currentBasePlan === 12) { return 'year' }
      return `${this.plans.currentBasePlan.chargeInterval} months`
    }
  },
  mounted () {
    if (!this.membershipDetails) {
      this.getClient()
    }
  },
  methods: {
    ...mapActions('client', [
      'getClient'
    ]),
    formatPrice
  }
}
</script>

<style lang="scss" scoped>

.plan-name {
  font-weight: $font-weight-semibold;
}

</style>
