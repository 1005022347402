<template>
  <div class="border-top p-4 px-md-0">
    <div class="d-flex justify-content-between py-2">
      <div class="flex-grow-1">
        <div class="d-flex align-items-center">
          <sequin-tag
            v-if="isClient"
            variant="secondary"
            class="mb-3 me-2">
            My Review
          </sequin-tag>
          <div v-else>
            <sequin-tag
              v-if="isExactSizeMatch"
              variant="tertiary"
              class="mb-3 me-2">
              My Size
            </sequin-tag>
            <sequin-tag
              v-if="isExactBustMatch"
              variant="tertiary"
              class="mb-3 me-2">
              Bust Size
            </sequin-tag>
            <sequin-tag
              v-if="isHeightMatch"
              variant="tertiary"
              class="mb-3 me-2">
              Height Match
            </sequin-tag>
          </div>
          <sequin-tag
            v-if="review.expertReview"
            variant="success"
            light
            class="me-2 mb-3">
            Expert Review
          </sequin-tag>
        </div>
        <div class="d-flex align-items-center justify-content-between flex-grow-1">
          <star-rating-display
            :rating="review.overallStarRating"/>
          <p class="small-text text-pewter mb-2">
            {{ toMonthDayString(review.created, 'short') }}
          </p>
        </div>

        <div
          v-if="review.occasionTags.length > 0"
          class="smaller text-pewter">
          Worn to:
          <span
            v-for="(tag, index) in review.occasionTags"
            :key="tag.id"
            class="occasion-tag">
            {{ tag.tag }}<span v-if="index < review.occasionTags.length - 1">,</span>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="review.feedbackText"
      class="pb-3">
      {{ review.feedbackText }}
    </div>
    <div
      v-if="review.sizingRating !== null"
      class="sizing-rating">
      <sizing-rating :rating="review.sizingRating"/>
    </div>
    <div class="text-gray small-text pb-2">
      Size rented: {{ review.feedbackSize }}
      <span class="px-1">
        •
      </span>
      <span>
        Fit: {{ fitText }}
      </span>
      <span
        v-if="isClient"
        class="px-1">
        •
      </span>
      <span v-if="isClient">
        Worn: {{ wornFrequencyText }}
      </span>
    </div>
    <div
      class="text-gray small-text pt-2">
      Color rented:
      <text-link
        v-if="styleColorId != review.styleColorId"
        @click="onColorClick(review.styleColorId)">
        {{ review.color }}
      </text-link>
      <span
        v-else>
        {{ review.color }}
      </span>
    </div>
    <div
      v-if="!isClient"
      class="text-gray small-text">
      <span
        v-for="(entry, index) in clientSizeList"
        :key="index">
        <span class="font-italic">{{ entry }}</span>
        <span
          v-if="index !== clientSizeList.length - 1"
          class="px-1">
          •
        </span>
      </span>
    </div>
    <div
      v-if="review.images.length && photoAlbumEntries.length"
      class="py-3">
      <closet-photo-scroll
        :style-color-id="styleColorId"
        :entries="photoAlbumEntries"/>
    </div>

    <div
      v-if="!isClient && loaded && showReviewActions"
      class="mt-3">
      <div
        v-if="memberFoundHelpful || othersHelped > 0"
        class="text-gray small-text mb-2">
        {{ helpfulnessText }}
      </div>
      <sequin-button
        v-if="!isClient"
        :variant="memberFoundHelpful ? 'active' : 'secondary'"
        class="p-2"
        @click="toggleRating">
        <div class="d-flex align-items-center justify-content-between">
          <svg-like
            height="18"
            class="me-1"/>
          Helpful
        </div>
      </sequin-button>
    </div>
    <sequin-button
      v-if="isClient && showReviewActions"
      class="mt-3"
      variant="secondary"
      small
      @click="openEditReviewPhotos">
      {{ review.images.length === 0 ?
        'Add Review Photos' :
        'Edit Review Photos' }}
    </sequin-button>
    <sequin-button
      v-if="isClient && showReviewActions && isStaff && review.adminEditReviewLink"
      class="mt-3"
      variant="secondary"
      small
      @click="openEditReviewAdmin">
      {{ "Edit Review in Admin" }}
    </sequin-button>
  </div>
</template>

<script>
import { ReviewMixin } from './ReviewMixin'
import { toMonthDayString } from '@/utils/stringParsing.js'
import ClosetPhotoScroll from './ClosetPhotoScroll'
import SequinTag from '../global/sequin/SequinTag'
import StarRatingDisplay from '../global/sequin/StarRatingDisplay'
import SizingRating from '../global/sequin/SizingRating'
import { mapActions, mapState } from 'vuex'
import SequinButton from '../global/sequin/SequinButton'
import { vuexAccessors } from '../global/helpers/vuex'
import TextLink from '../global/sequin/TextLink'
import SvgLike from '@/components/global/svg/SvgLike.vue'

export default {
  components: {
    SequinButton,
    SizingRating,
    SequinTag,
    ClosetPhotoScroll,
    StarRatingDisplay,
    TextLink,
    SvgLike
  },
  mixins: [ReviewMixin],
  props: {
    review: {
      type: Object,
      required: true
    },
    photoAlbumEntries: {
      type: Array,
      default: () => []
    },
    showReviewActions: {
      type: Boolean,
      default: true
    },
    styleColorId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('client', [
      'id',
      'styleProfileComplete',
      'loaded',
      'isStaff'
    ]),
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    ...vuexAccessors('review', [
      'reviewForEditPhotos'
    ]),
    isClient () {
      return this.id === this.review.clientId
    },
    isHeightMatch () {
      return this.styleProfileComplete && this.review.sizing?.heightDistance <= 0.5
    },
    isExactSizeMatch () {
      return this.styleProfileComplete && this.review.sizing?.sizeDistance === 0
    },
    isExactBustMatch () {
      return this.styleProfileComplete && this.review.sizing?.bustSizeMatch === true
    },
    memberFoundHelpful () {
      return this.review.memberFoundHelpful
    },
    othersHelped () {
      return this.review.membersHelped - (this.memberFoundHelpful ? 1 : 0)
    },
    helpfulnessText () {
      if (this.memberFoundHelpful) {
        if (this.review.membersHelped === 1) {
          return 'You found this review helpful'
        }
        return `You and ${this.othersHelped} other member${this.othersHelped > 1 ? 's' : ''} found this review helpful`
      } else {
        return `${this.othersHelped} member${this.othersHelped > 1 ? 's' : ''} found this review helpful`
      }
    }
  },
  methods: {
    ...mapActions('closet', [
      'rateReview'
    ]),
    toMonthDayString,
    toggleRating () {
      this.rateReview({
        styleColorId: this.styleColorId,
        itemFeedbackId: this.review.id,
        rating: this.memberFoundHelpful ? 0 : 1
      })
    },
    openEditReviewPhotos () {
      this.reviewForEditPhotos = { ...this.review, styleColorId: this.styleColorId }
      this.$router.push({ name: 'edit-review-photos' })
    },
    openEditReviewAdmin () {
      window.location.href = this.review.adminEditReviewLink
    },
    onColorClick (styleColorId) {
      this.$router.push({
        name: 'closet-detail',
        params: { id: styleColorId, name: this.detailPageSlug(this.styleColorsMap[styleColorId]) }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .sizing-rating {
    margin-bottom: 16px;
    max-width: 240px;
  }

  .occasion-tag {
    text-transform: capitalize;
  }
</style>
