<template>
  <div v-if="rentalHistory.length === 0">
    <div
      class="sticky-top-search"
      :class="{ 'desktop': isModal }"/>
    <div class="bg-white border-end border-start border-bottom">
      <rental-history-list-item-placeholder
        v-for="index in 4"
        :key="index"/>
    </div>
  </div>
  <div v-else>
    <div
      v-if="locallySelectedStyles.length >= 4"
      class="max-styles"
      :class="{ 'desktop': isModal }">
      You may only tag up to 4 styles to a look
    </div>
    <search-bar
      v-else
      v-model="filterTerm"
      placeholder="Search rental history"
      class="sticky-top-search"
      :class="{ 'desktop': isModal }"/>
    <div
      class="sticky-top-styles"
      :class="{ 'desktop': isModal }">
      <rental-history-list-item
        v-for="item in locallySelectedStyles"
        :key="item.id"
        selected
        class="border-end border-start"
        :hide-outside-of-viewport="!isModal"
        :item="rentalHistory[rentalHistoryIds.indexOf(item.id)]"
        @style-color-click="unselectStyle">
        <template #listItemDetails>
          <p class="text-pewter mb-1">
            Size {{ rentalHistory[rentalHistoryIds.indexOf(item.id)].itemType.trueSize }}
          </p>
          <p
            class="fine-print text-ash mt-2 mb-0">
            Rented {{ toMonthDayYearString(rentalHistory[rentalHistoryIds.indexOf(item.id)].shippedDate) }}
          </p>
        </template>
      </rental-history-list-item>
    </div>
    <rental-history-list-item
      v-for="(item, index) in getUnselectedStylesFiltered()"
      :key="index"
      class="border-end border-start"
      :selection-disabled="locallySelectedStyles.length >= 4"
      :item="item"
      :hide-outside-of-viewport="!isModal"
      @style-color-click="selectStyle">
      <template #listItemDetails>
        <p class="text-pewter mb-1">
          Size {{ item.itemType.trueSize }}
        </p>
        <p
          class="fine-print text-ash mt-2 mb-0">
          Rented {{ toMonthDayYearString(item.shippedDate) }}
        </p>
      </template>
    </rental-history-list-item>
  </div>
</template>

<script>
import RentalHistoryListItem from '../common/RentalHistoryListItem'
import RentalHistoryListItemPlaceholder from '../common/RentalHistoryListItemPlaceholder'
import RentalHistoryMixin from '../common/RentalHistoryMixin'
import SearchBar from '../../global/sequin/SearchBar'
import { toMonthDayYearString } from '@/utils/stringParsing.js'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    RentalHistoryListItem,
    RentalHistoryListItemPlaceholder,
    SearchBar
  },
  mixins: [RentalHistoryMixin],
  props: {
    locallySelectedStyles: {
      type: Array,
      required: true
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filterTerm: ''
    }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...mapState('community', [
      'clientRentalHistoryById'
    ]),
    rentalHistory () {
      return this.clientRentalHistoryById[this.id]?.rentalHistory || []
    },
    rentalHistoryIds () {
      return this.rentalHistory.map(item => item.styleColor.id)
    }
  },
  mounted () {
    if (this.rentalHistory.length === 0) {
      // this will get the 1st page of results (50).  That's probably fine.
      // we could implement paging etc, but the effort is probably not warranted.
      // If 50 is not "good enough", we could automatically fetch the next
      // page or two to bump up that number
      this.getClientRentalHistory({ clientId: this.id })
    }
  },
  methods: {
    ...mapActions('community', [
      'getClientRentalHistory'
    ]),
    toMonthDayYearString,
    getUnselectedStylesFiltered () {
      const unselectedStyles = this.rentalHistory.slice()
      const unselectedStyleIds = unselectedStyles.map(item => item.styleColor.id)

      this.locallySelectedStyles.forEach(style => {
        const index = unselectedStyleIds.indexOf(style.id)
        unselectedStyles.splice(index, 1)
        unselectedStyleIds.splice(index, 1)
      })

      return this.filterRentalHistoryItems(this.filterTerm, unselectedStyles)
    },
    getSearchIcon () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}search-icon.png`
    },
    unselectStyle (styleColor) {
      this.$emit('unselect-style', styleColor)
    },
    selectStyle (styleColor) {
      this.$emit('select-style', styleColor)
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-top-styles {
  position: sticky;
  top: 105;
  z-index: 1023;
  background-color: $origami;

  &.desktop {
    top: 48px;
  }
}

.sticky-top-search, .max-styles {
  padding: 12px 24px;
  position: sticky;
  top: 57;
  z-index: 1023;
  height: 48px;
  border-bottom: $default-border;

  &.desktop {
    top: 0;
    border: $default-border;
  }
}

.max-styles {
  background-color: lighten($info-light, 40%);
  color: $info-dark;
  border: 1px solid rgba($info-dark, .1);
  font-size: $small-text-font-size;
}
</style>
