<template>
  <div
    v-if="iconState"
    class="circle-state-indicator">
    <svg-plus
      v-if="iconState === 'plus'"
      class="plus-sign"/>
    <span
      v-else
      class="num-selected">{{ numSelectedItems }}</span>
  </div>
</template>

<script>
import MyCaseMixin from './MyCaseMixin'
import SvgPlus from '@/components/global/svg/SvgPlus.vue'

export default {
  components: {
    SvgPlus
  },
  mixins: [MyCaseMixin],
  computed: {
    iconState () {
      if (this.canSelectItems) {
        if (this.numSelectedItems) {
          return this.numSelectedItems
        } else {
          return 'plus'
        }
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>

.plus-sign {
  width: 8px;
  height: 8px;
  stroke-width: 3px;
}

.num-selected {
  height: 10px;
  width: 10px;
  line-height: 10px;
  font-size: 9px;
  font-weight: $font-weight-normal;
}

.circle-state-indicator {
  background: $primary;
  color: $white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @include scale-up-down-animation;
}
</style>
