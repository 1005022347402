import axios from './config'

const apiAccount = {
  addNotification (data) {
    return axios.post('/api/notifications/', data)
  },
  updateNotification (data) {
    return axios.patch('/api/notifications/' + data.id + '/', data)
  },
  getNotifications (lastCheck = null) {
    let url = '/api/notifications/'
    if (lastCheck) {
      url = url + '?last_check=' + lastCheck
    }
    return axios.get(url)
  },
  getNextPage (endpoint) {
    return axios.get(endpoint)
  },
  markAllAsViewed () {
    return axios.post('/api/notifications/mark_all_as_viewed/')
  }
}

export default apiAccount
