<template>
  <plain-button
    v-bind="$attrs"
    :type="type"
    :href="href"
    :to="to"
    :name="name"
    :disabled="disabled"
    :class="[variant, size, { 'no-underline': !underline, 'semi-bold': bold}]"
    class="text-link"
    @click="$emit('click', $event)">
    <slot/>
  </plain-button>
</template>

<script>
import PlainButton from './PlainButton'
export default {
  name: 'TextLink',
  components: {
    PlainButton
  },
  props: {
    // The underlying component type: button, a, or router-link
    type: {
      type: String,
      default: 'button',
      validator: value => {
        return value.match(/(button|a|router-link|)/)
      }
    },
    // For links, the href
    href: {
      type: String,
      default: null
    },
    // For links, the name for ADA/accessibility
    name: {
      type: String,
      default: null
    },
    // For router-links, the destination
    to: {
      type: Object,
      default: null
    },
    // The styling variant: primary (default), secondary, tertiary, danger
    // Use inherit if you want to apply your own color for a special circumstance
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/(primary|secondary|tertiary|danger|inherit|inherit-new|primary-new)/)
      }
    },
    // The text-size: paragraph, small-text (default), or fine-print
    size: {
      type: String,
      default: 'small-text',
      validator: value => {
        return value.match(/(paragraph|small-text|fine-print)/)
      }
    },
    underline: {
      type: Boolean,
      default: true
    },
    bold: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.text-link {
  padding: 4px 0;
  color: $primary;
  text-decoration: underline;
  font-size: $small-text-font-size;
  background: rgba($white, 0);
  white-space: nowrap;

  &.paragraph {
    font-size: $font-size-base;
    line-height: $body-line-height;
  }

  &.fine-print {
    font-size: $fine-print-font-size;
    line-height: $fine-print-line-height;
  }

  &.no-underline {
    text-decoration: none;
  }
  &.primary-new {
    color: $armor;
    font-family: $headings-font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
  }
  &.secondary {
    color: $armor;
  }

  &.tertiary {
    color: $pewter;
  }

  &.danger {
    color: $danger-dark;
  }

  &.info {
    color: $info-dark;
  }

  &.inherit {
    color: inherit;
    &-new {
      font-family: $headings-font-family;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }

  &:hover {
    color: $secondary;
    text-decoration: underline;
  }

}
</style>
