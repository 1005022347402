import NotFound from '@/components/misc/NotFound'

/**
 * @typedef {import('vue-router').RouteRecordRaw} RouteRecordRaw
 * @typedef {Readonly<RouteRecordRaw[]>} Routes
 * @typedef {import('vue-router').RouteMeta} RouteMeta
 */

/**
 * Creates a new array of routes by merging the two input arrays.
 * @param {Routes} r1
 * @param {Routes} r2
 */
export function mergeRoutes (r1, r2) {
  const routesByPath = new Map(r1.map(route => [route.path, route]))

  for (const route of r2) {
    const existingRoute = routesByPath.get(route.path)
    if (existingRoute) {
      const sharedKeys = getSharedKeys(existingRoute, route)
      const allowedSharedKeys = new Set(['path', 'children', 'meta'])
      const disallowedSharedKeys = new Set([...sharedKeys].filter(key => !allowedSharedKeys.has(key)))
      if (disallowedSharedKeys.size > 0) {
        throw new Error(`Route "${route.path}" has duplicate keys: ${Array.from(disallowedSharedKeys).join(', ')}`)
      }

      for (const key of Object.keys(route)) {
        if (key === 'children') {
          existingRoute.children = mergeRoutes(existingRoute.children ?? [], route.children)
        } else if (key === 'meta') {
          try {
            existingRoute.meta = mergeRouteMeta(existingRoute.meta ?? {}, route.meta)
          } catch (e) {
            throw new Error(`Error merging "${route.path}" route. ${e.message}`)
          }
        } else {
          existingRoute[key] = route[key]
        }
      }
    } else {
      routesByPath.set(route.path, route)
    }
  }

  return Array.from(routesByPath.values())
}

/**
 * @param {Object} obj1
 * @param {Object} obj2
 */
function getSharedKeys (obj1, obj2) {
  const keys = new Set(Object.keys(obj1))
  return Object.keys(obj2).filter(key => keys.has(key))
}

/**
 * Merges two route meta objects.
 * @param {RouteMeta} meta1
 * @param {RouteMeta} meta2
 * @returns {RouteMeta}
 */
function mergeRouteMeta (meta1, meta2) {
  const sharedKeys = getSharedKeys(meta1, meta2)
  for (const key of sharedKeys) {
    if (meta1[key] !== meta2[key]) {
      throw new Error(`Meta key ${key} has conflicting values: ${meta1[key]} and ${meta2[key]}`)
    }
  }
  return { ...meta1, ...meta2 }
}

/** Routes shared by the homepage and main website.
 * @type {Routes}
 * */
export const sharedRoutes = [
  {
    path: '/404',
    name: 'not-found',
    component: NotFound
  }
]
