<template>
  <div
    v-if="membershipPlans"
    class="text-center">
    <div class="px-3">
      <h6
        class="plans-pricing">
        Plans + Pricing
      </h6>
      <h4>
        {{ subheaderText }}
      </h4>
      <p
        :class="{'mb-0':isPrepayFlow}">
        Our flexible membership allows you to <span v-if="!isPrepayFlow">switch plans,</span> pause<span v-if="!isPrepayFlow">,</span> or cancel at any time.
      </p>
    </div>
    <div
      :class="{ 'membership-desktop': !isMobile, 'p-2 my-4 pb-5': !isPrepayFlow }">
      <div
        :class="{ 'd-flex flex-wrap justify-content-center': !isPrepayFlow, 'px-0 single-card' : isPrepayFlow}">
        <div
          v-for="membershipPlan in plans"
          :key="membershipPlan.id"
          class="p-2"
          @click="selectPlan(membershipPlan.id, false, true)">
          <plan-card
            :plan="membershipPlan"
            :selected="membershipPlan.id === membershipPlanSelectedId"
            :header-text="(!membershipPlan.prepayment && membershipPlan.shipmentsPerInterval === null) ? 'Best Value' : ''"/>
        </div>
      </div>
    </div>
    <style-quiz-bottom-nav
      button-text="Continue"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import StyleQuizBottomNav from './StyleQuizBottomNav.vue'
import AnalyticsMixin from '../global/mixins/analyticsMixin'
import PlanCard from '../subscribe/PlanCard'
import PrepayMixin from '../global/mixins/prepayMixin'
import { StripeMixin } from '../global/stripe/StripeMixin'
import { MobileAppMessage, postToMobileWebView } from '@/utils/mobileApp.js'

export default {
  components: {
    StyleQuizBottomNav,
    PlanCard
  },
  mixins: [AnalyticsMixin, PrepayMixin, StripeMixin],
  computed: {
    ...mapState('subscribe', [
      'membershipPlanSelectedId',
      'membershipPlans',
      'referralCampaign',
      'referralCode'
    ]),
    ...mapState('styleQuizNav', [
      'isPrepayFlow'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected',
      'bonusItemsFromPlanId'
    ]),
    subheaderText () {
      if (this.isPrepayFlow) {
        return 'Spice up your wardrobe with Armoire'
      } else {
        return 'Choose Your Membership'
      }
    },
    plans () {
      if (this.isPrepayFlow) {
        return [this.prepayHeroPlan()]
      } else {
        return this.membershipPlans.filter(plan => !plan.prepayment && plan.basePrice > 0)
      }
    }
  },
  created () {
    this.loadStripe()
  },
  mounted () {
    postToMobileWebView(MobileAppMessage.SubscribeLoaded)
  },
  methods: {
    ...mapActions('subscribe', [
      'selectMembershipPlan'
    ]),
    async selectPlan (planId, nextPage = false, trackEvent = false) {
      await this.selectMembershipPlan({ planId, trackEvent })
      if (nextPage) {
        await this.$router.push({ name: 'subscribe' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleQuizStyleDefinitions.scss';
.membership-desktop {
  margin: 0 auto;
}
</style>
