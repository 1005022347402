import axios from 'axios'
import { getConfig, attach } from 'retry-axios'
import logger from '@shared/logger.js'

axios.defaults.withCredentials = true

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
})

if (process.env.VUE_APP_BUILD_TIME) {
  instance.defaults.headers.common['APP-BUILD-TIME'] = process.env.VUE_APP_BUILD_TIME
}

instance.defaults.raxConfig = {
  instance: instance,

  // Milliseconds to delay at first
  retryDelay: 100,

  // need custom shouldRetry method for this to work, which is dumb.
  // see: https://github.com/JustinBeckwith/retry-axios/issues/1
  // maybe switch to gaxios at some point?
  // note: this "fix" doesn't work with the latest version of axios
  // (v.0.19.0 as of this writing), so we'd have to switch should we decide
  // this version of axios (0.18.1) isn't cutting it anymore
  shouldRetry: (err) => {
    const noRetryStatusCodes = [400, 401, 403, 404, 500]
    const cfg = getConfig(err)
    if (cfg.currentRetryAttempt < 5 && !noRetryStatusCodes.includes(err.response.status)) { return true }
    return false
  },

  onRetryAttempt: (err) => {
    const cfg = getConfig(err)
    logger.debug(`Retry attempt #${cfg.currentRetryAttempt}`)
  }
}
attach(instance)

instance.interceptors.response.use(
  response => response, error => {
    if (error.response) {
      if (error.response.status === 400) {
        // client is out of date.  This is extremely unlikely.  Force a reload to
        // get the latest app version.
        if (error.response.data && error.response.data instanceof String &&
          error.response.data.startsWith('Client upgrade required')) {
          setTimeout(() => { location.reload() }, 5000)
        } else {
          throw error
        }
      } else if (error.response.status === 401) {
        // axios "eats" 401 responses for some reason... (no error is thrown
        // unlike for other 400/500 response codes, then the response object
        // returned from the promise is undefined)
        // this ensures that the error is correctly thrown up the chain
        // and we display the correct invalid username/password error message
        throw error
      }
    } else {
      throw error
    }
    return Promise.reject(error)
  }
)

if (window.armoireAppUserInfo) {
  instance.defaults.headers.common.Authorization = `KToken ${window.armoireAppUserInfo.authToken}`
}

export default instance
