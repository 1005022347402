<template>
  <div class="photo-container">
    <img
      v-if="inViewport.now"
      class="photo"
      :src="itemImage">
  </div>
</template>

<script>
import inViewport from 'vue-in-viewport-mixin'

export default {
  mixins: [inViewport],
  props: {
    itemImage: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      hasBeenVisible: false
    }
  },
  watch: {
    'inViewport.now': function (visible) {
      if (visible && !this.hasBeenVisible) {
        this.$emit('item-in-view')
        this.hasBeenVisible = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.photo-container {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;

  .photo {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
</style>
