<template>
  <div class="d-flex justify-content-center text-center mt-5">
    <div class="px-4">
      <div class="d-flex justify-content-center mb-4">
        <svg-circle-icon
          :height="36"
          :width="36"
          class="success-icon"
          variant="success">
          <svg-check/>
        </svg-circle-icon>
      </div>
      <h2
        class="mb-3 thanks-msg"
        :class="{'mobile flex-wrap': isMobile}">
        Swap Complete!
      </h2>
      <div
        v-if="closetItemNumber > 0"
        class="col-12 col-md-8 col-xl-6 mx-auto">
        <h4 class="next-up mb-4">
          Next Up:
        </h4>
        <div
          v-for="(step, index) in steps"
          :key="index">
          <h6 class="next-steps-circle d-flex justify-content-center align-items-center mb-2 mx-auto">
            {{ step.stepNumber }}
          </h6>
          <h6 class="mb-2">
            {{ step.stepHeading }}
          </h6>
          <p class="mb-4">
            {{ step.stepContent }}
          </p>
        </div>
        <p class="mb-4">
          Hang onto something from your old case in their place so you keep {{ plans.currentBasePlan.nItems }} item{{ plans.currentBasePlan.nItems > 1 ? 's' : '' }} at home.
        </p>
      </div>
      <sequin-button
        type="router-link"
        :to="{ name: 'closet-sections' }"
        class="my-4">
        Browse Closet
      </sequin-button>
    </div>
  </div>
</template>

<script>
import SvgCircleIcon from '../global/svg/SvgCircleIcon'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'
import { mapState } from 'vuex'
import SequinButton from '../global/sequin/SequinButton'
import { vuexAccessors } from '../global/helpers/vuex'

export default {
  name: 'ReviewSuccess',
  components: {
    SequinButton,
    SvgCircleIcon,
    SvgCheck
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'closetItemNumber',
      'plans'
    ]),
    ...vuexAccessors('review', [
      'itemsToSwap'
    ]),
    steps () {
      return [
        {
          stepNumber: 1,
          stepHeading: 'Never be without clothes',
          stepContent: "We've marked the styles you just swapped as returning -- but no need to send them back just yet. You can still enjoy them until your next case arrives."
        },
        {
          stepNumber: 2,
          stepHeading: 'Build your next case',
          stepContent: `Select ${this.closetItemNumber} style${this.closetItemNumber > 1 ? 's' : ''} for your new case.`
        },
        {
          stepNumber: 3,
          stepHeading: 'Once your new case arrives',
          stepContent: 'Within 24 hours of receiving your case, use the prepaid shipping label to send back your returns and any items that don’t fit from your new case.'
        }
      ]
    }
  },
  mounted () {
    this.$store.commit('review/CLEAR_REVIEWS')
    this.itemsToSwap = []
  }
}
</script>

<style lang="scss" scoped>
  .success-icon {
    box-shadow: 0 0 0 6px lighten($success-light, 20%);
  }
  .thanks-msg {
    &.mobile {
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      line-height: $h6-line-height;
    }
  }
  .next-steps-circle {
    border-radius: 50%;
    height: 36px;
    width: 36px;
    background: $tertiary;
    color: $white;
  }
</style>
