<template>
  <sequin-button
    block
    :variant="buttonType"
    @click="onBuyNowClick">
    <svg-shopping-cart-white
      v-if="buttonType === 'primary'"
      class="me-1"/>
    <svg-shopping-cart-grey
      v-else
      class="me-1"/>
    Buy Now {{ pricing }}
  </sequin-button>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mapActions } from 'pinia'
import SequinButton from '../global/sequin/SequinButton'
import { formatPrice } from '@/utils/stringParsing.js'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgShoppingCartWhite from '@/components/global/svg/SvgShoppingCartWhite.vue'
import SvgShoppingCartGrey from '@/components/global/svg/SvgShoppingCartGrey.vue'

export default {
  components: {
    SequinButton,
    SvgShoppingCartWhite,
    SvgShoppingCartGrey
  },
  props: {
    selectedItemType: {
      type: Object,
      default: null
    },
    styleColor: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('client', [
      'canSelectItems'
    ]),
    ...mapState('closet', [
      'itemTypePricing'
    ]),
    buttonType () {
      if (this.canSelectItems && this.styleColor.rentable) { return 'secondary' }
      return 'primary'
    },
    pricing () {
      if (this.buttonType === 'primary' && this.selectedItemType &&
          this.selectedItemType.id in this.itemTypePricing) {
        const price = this.itemTypePricing[this.selectedItemType.id].client
        return `- ${formatPrice(price, true)}`
      }
      return ''
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal',
      'openModal'
    ]),
    onBuyNowClick () {
      this.openModal({
        name: 'buy-item',
        context: {
          selectedItemType: this.selectedItemType,
          styleColor: this.styleColor
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
