
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0769 8.38462C11.3809 8.38462 8.38462 11.3809 8.38462 15.0769C8.38462 18.773 11.3809 21.7692 15.0769 21.7692C18.773 21.7692 21.7692 18.773 21.7692 15.0769C21.7692 11.3809 18.773 8.38462 15.0769 8.38462ZM7 15.0769C7 10.6162 10.6162 7 15.0769 7C19.5377 7 23.1538 10.6162 23.1538 15.0769C23.1538 17.0581 22.4406 18.8726 21.2568 20.2777L24.7972 23.8182C25.0676 24.0885 25.0676 24.5269 24.7972 24.7972C24.5269 25.0676 24.0885 25.0676 23.8182 24.7972L20.2777 21.2568C18.8726 22.4406 17.0581 23.1538 15.0769 23.1538C10.6162 23.1538 7 19.5377 7 15.0769Z"
      fill="currentColor"/>
  </svg>
</template>
