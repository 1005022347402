<template>
  <div class="d-flex p-2 border-bottom">
    <div class="multi-wrapper d-flex flex-wrap">
      <div
        v-for="image of images"
        :key="image"
        class="position-relative m-2">
        <img
          :src="image"
          class="multi-thumbnail">
        <plain-button
          class="delete-photo p-0"
          @click="$emit('delete-image', image)">
          <svg-circle-icon variant="dark">
            <svg-x/>
          </svg-circle-icon>
        </plain-button>
      </div>
      <plain-button
        v-if="allowAddAnother"
        class="add-another m-2"
        @click="$emit('add-another')">
        <svg-plus class="add-another-icon"/>
      </plain-button>
    </div>
  </div>
</template>

<script>
import PlainButton from '../PlainButton'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgPlus from '@/components/global/svg/SvgPlus.vue'
import SvgX from '@/components/global/svg/SvgX.vue'

export default {
  name: 'MultiImagePreview',
  components: {
    PlainButton,
    SvgCircleIcon,
    SvgPlus,
    SvgX
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    allowAddAnother: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../StyleImage/StyleImage.scss';

  $imagePreviewRowCount: 5;

  .multi-wrapper {
    max-width: $imageTinyBase * $imagePreviewRowCount;
  }

  .multi-thumbnail {
    max-width: $imageTinyBase;
    object-fit: scale-down;
  }

  .delete-photo {
    background: none;
    position: absolute;
    top: -6px;
    right: -8px;
  }

  .add-another {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    max-width: $imageTinyBase;
    background-color: $origami;
  }

  .add-another-icon {
    color: $pewter;
  }

</style>
