<template>
  <plain-button
    v-if="!showMore"
    class="p-0"
    @click="moreClicked">
    <svg-more
      height="20"
      width="20"/>
  </plain-button>
  <div
    v-else
    class="d-flex">
    <plain-button
      id="update-button"
      @click="onEdit">
      Edit
    </plain-button>
    <plain-button
      id="update-button"
      @click="onDelete">
      Delete
    </plain-button>
  </div>
</template>

<script>
import PlainButton from '../../../global/sequin/PlainButton'
import { mapState, mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgMore from '@/components/global/svg/SvgMore.vue'

export default {
  components: {
    PlainButton,
    SvgMore
  },
  data () {
    return {
      showMore: false
    }
  },
  computed: {
    ...mapState('community', [
      'itemInFocus'
    ])
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    ...mapVuexActions('community', [
      'deleteFeedItemComment'
    ]),
    moreClicked () {
      this.showMore = true
      this.$emit('more-click')
    },
    onEdit () {
      this.$emit('edit-click')
    },
    async onDelete () {
      this.openModal({ name: 'community-delete-confirmation' })
    }
  }
}
</script>

<style lang="scss" scoped>
#update-button {
  color: $primary;
  font-weight: $font-weight-semibold;
  padding: 0 4px;
  font-size: $small-text-font-size;
}

</style>
