<template>
  <div
    :style="cssVars"
    class="text-center background-success">
    <style-quiz-banner-image
      :image-name="isMobile ? 'next-up-mobile' : 'next-up-desktop'"
      :class="['background-image', { 'animate': startAnimation && isMobile }]"
      @image-loaded="startAnimation = true"/>
    <div
      :class="{ 'desktop-vertical-align py-5': !isMobile, 'mobile-container': isMobile }">
      <div :class="['header mb-4', { 'animate': startAnimation }]">
        <div>
          <h6
            class="text-center"
            :class="{'marketing': !isMobile}">
            What's next?
          </h6>
          <h4
            class="text-center px-4 mb-0 mt-md-2">
            {{ header }}
          </h4>
        </div>
      </div>
      <div class="content-container">
        <div
          v-for="(step, index) in nextUpSteps"
          :key="index"
          :style="{ animationDelay: `${getAnimationDelay(index)}s` }"
          :class="{'animate': startAnimation}"
          class="step d-flex flex-column align-items-center my-3">
          <div class="circle-bg mb-2">
            <h6 class="mb-0">
              {{ index + 1 }}
            </h6>
          </div>
          <h6 class="mb-0 perk-title">
            {{ step.title }}
          </h6>
          <p class="perk-desc">
            <span v-if="isMobile && step.mobileDescription">
              {{ step.mobileDescription }}
            </span>
            <span v-else>
              {{ step.description }}
            </span>
          </p>
          <div
            v-if="step.perkInfo"
            class="d-flex align-items-center bg-orchid text-white px-3 py-2">
            <div
              v-if="!isMobile"
              class="step-dress-icon">
              <svg-origami-dress-fixed/>
            </div>
            <div>
              <h6>{{ step.perkInfo.title }}</h6>
              <p class="mb-0">
                {{ step.perkInfo.description }}
              </p>
            </div>
            <div
              v-if="!isMobile"
              class="step-dress-icon">
              <svg-origami-dress-fixed/>
            </div>
          </div>
          <div>
            <a
              v-if="step.showAppLink && showApple"
              class="appDownloadLink"
              target="_blank"
              :href="getPlatformSpecificLink('ios')">
              <img
                class="appDownloadIcon"
                :alt="getPlatformSpecificAltText('ios')"
                :src="getPlatformSpecificIcon('ios')">
            </a>
            <a
              v-if="step.showAppLink && showAndroid"
              class="appDownloadLink"
              target="_blank"
              :href="getPlatformSpecificLink('android')">
              <img
                class="appDownloadIcon"
                :alt="getPlatformSpecificAltText('android')"
                :src="getPlatformSpecificIcon('android')">
            </a>
          </div>
        </div>
      </div>
    </div>
    <style-quiz-bottom-nav
      class="footer"
      :style="{ animationDelay: `${getAnimationDelay(3)}s` }"
      :class="{'animate': startAnimation}"
      :next-route-override="{ name: 'closet-sections'}"
      button-text="Let's go"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import StyleQuizBannerImage from './StyleQuizBannerImage.vue'
import StyleQuizBottomNav from './StyleQuizBottomNav.vue'
import appDownload from '../global/mixins/appDownload'
import SvgOrigamiDressFixed from '../global/svg/SvgOrigamiDressFixed.vue'
import { inMobileWebView } from '@/utils/mobileApp.js'

export default {
  components: {
    StyleQuizBottomNav,
    StyleQuizBannerImage,
    SvgOrigamiDressFixed
  },
  mixins: [appDownload],
  data () {
    return {
      animationDelayBase: 0.25,
      animationOffset: 0.6,
      startAnimation: false,
      newMember: {
        header: "Let's get started",
        steps: [
          {
            title: 'Browse Your Closet',
            description: 'Your first curated Closet is ready! Click the heart icon on clothes you love so we can better learn your style. Each time you visit, you’ll get fresh recommendations.',
            mobileDescription: 'Your first curated Closet is ready! Click the heart icon on clothes you love so we can better learn your style.'
          },
          {
            title: 'Fill Your First Case',
            description: 'Hit “Add to Case” on the items you want now. When all spots are filled, you’re ready to check out.'
          },
          {
            title: 'Download The Mobile App',
            description: 'Unlock the best of Armoire – browse your Closet from anywhere, easily swap items for new ones, and more.',
            mobileDescription: 'Browse your Closet from anywhere, easily swap items for new ones, and more.',
            showAppLink: true
          }
        ]
      },
      resubscribingMember: {
        header: 'Welcome back',
        steps: [
          {
            title: 'Build your case',
            description: 'Browse your Closet, read reviews, and add items to your Case before checking out.'
          },
          {
            title: 'Download the mobile app',
            description: 'Get real-time notifications about your Case and review styles on-the-go.',
            showAppLink: true
          },
          {
            title: 'Favorite styles for better recommendations',
            description: 'Select the heart icon on styles you love. Your recommendations will get more personalized as you share your preferences.'
          }
        ]
      },
      quizRetakeMember: {
        header: 'Thanks for helping us learn more about you, ',
        steps: [
          {
            title: 'Build your next case',
            description: 'Check out your refreshed closet to select items for your next Case.'
          },
          {
            title: 'Rate while you wait',
            description: 'Favorite the styles you see in your closet, this helps us get smarter and more personalized with our recommendations.'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('client', [
      'firstName'
    ]),
    ...mapState('styleQuizNav', [
      'isNonMember',
      'isRejoiningMember'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected'
    ]),
    header () {
      if (this.isStyleQuizRetake) {
        return this.quizRetakeMember.header + this.firstName
      }
      if (this.isRejoiningMember) {
        return this.resubscribingMember.header
      }
      return this.newMember.header
    },
    nextUpSteps () {
      if (this.isStyleQuizRetake) {
        return this.quizRetakeMember.steps
      } else {
        let steps = this.newMember.steps
        if (this.isRejoiningMember) {
          steps = this.resubscribingMember.steps
        }
        if (this.getUserAgent() === 'native') {
          steps = steps.slice(1, steps.length)
        }
        if (this.membershipPlanSelected && this.membershipPlanSelected.appliedPromo && this.membershipPlanSelected.appliedPromo.bonusItemsOnSignup) {
          const items = this.membershipPlanSelected.appliedPromo.bonusItemsOnSignup
          steps[1].perkInfo = {
            title: 'New Member Perk!',
            description: 'We’re treating you to ' + items + ' additional item' + (items > 1 ? 's' : '') + ' of your choice for your first Case.'
          }
        }
        return steps
      }
    },
    isStyleQuizRetake () {
      return !this.isNonMember || inMobileWebView()
    },
    cssVars () {
      return {
        '--fade-duration': `${this.animationOffset / 2}s`,
        '--animation-delay-base': `${this.animationDelayBase}s`
      }
    },
    showApple () {
      return ['web', 'ios'].includes(this.getUserAgent())
    },
    showAndroid () {
      return ['web', 'android'].includes(this.getUserAgent())
    }
  },
  methods: {
    getAnimationDelay (index) {
      return this.animationDelayBase + (this.animationOffset * index)
    },
    getUserAgent () {
      if (inMobileWebView()) {
        return 'native'
      }
      const userAgent = navigator.userAgent

      if (/android/i.test(userAgent)) {
        return 'android'
      }

      if (/iPad|iPhone|iPod/i.test(userAgent)) {
        return 'ios'
      }
      return 'web'
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleQuizStyleDefinitions.scss';

.header {
  opacity: 0;

  &.animate {
    animation: fade-up var(--fade-duration) forwards .7s ease-in-out;
  }
}

.content-container {
  max-width: 32rem;
}

.step {
  opacity: 0;
  &.animate {
    animation: fade-up var(--fade-duration) forwards var(--animation-delay-base) ease-in-out;
  }
}

.footer {
  opacity: 0;
  &.animate {
    animation: fade-in var(--fade-duration) forwards var(--animation-delay-base) ease-in;
  }
}

.background-image {
  &.animate {
    animation: blur-fade 1.5s forwards var(--animation-delay-base) ease-out;
  }
  padding-left: 2rem;
}

.circle-bg {
  border-radius: 50%;
  background-color: $plum;
  width: 32px;
  height: 32px;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-container {
  padding-bottom: 100px;
}

.appDownloadLink {
  margin: 0 12px 12px 0;
  display: inline-block;
}

.appDownloadIcon {
  width: 132px;
  height: auto;
}

.background-success {
  background: linear-gradient(167.11deg, rgba(133, 13, 84, 0.05) 100%, rgba(172, 153, 164, 0.05) 18.01%);
  max-width: 100vw;
  min-height: 100vh;
}
</style>
