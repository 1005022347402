<template>
  <div>
    <div class="row">
      <div class="col col-12 col-md-9 ps-md-0">
        <p class="text-center text-md-start">
          Please let us know the main reason you've decided to cancel.
        </p>
      </div>
    </div>
    <div class="row">
      <div
        class="dropdown col col-md-5 ps-md-0">
        <dropdown-single-select
          v-model="selectedPrimaryReasonId"
          :dropdown-options="primaryOptions"
          class="w-100"
          @update:modelValue="onPrimaryReasonChange($event)"/>
      </div>
    </div>
    <div
      v-if="secondaryOptions.length"
      class="row">
      <div class="col col-12 col-md-9 ps-md-0 mt-3">
        <dropdown-single-select
          v-model="selectedSecondaryReasonId"
          :dropdown-options="secondaryOptions"
          :class="{ 'w-100': isMobile }"
          class="dropdown"
          @update:modelValue="onSecondaryReasonChange($event)"/>
      </div>
    </div>
    <div class="row">
      <base-text-area
        v-model="feedback"
        placeholder="Please provide additional details as to why you're cancelling."
        rows="4"
        class="col col-12 col-md-9 ps-md-0 mt-3"
        @input="onFeedbackChange($event)"/>
    </div>
  </div>
</template>

<script>
import BaseTextArea from '../../global/BaseTextArea'
import DropdownSingleSelect from '../../global/sequin/DropdownSingleSelect'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CancelReason',
  components: { DropdownSingleSelect, BaseTextArea },
  props: {
    cancelReason: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedPrimaryReasonId: null,
      selectedSecondaryReasonId: null,
      feedback: ''
    }
  },
  computed: {
    ...mapState('subscribe', [
      'cancelReasons'
    ]),
    primaryOptions () {
      return this.cancelReasons.map(r => {
        return { text: r.reason, value: r.id }
      })
    },
    secondaryOptions () {
      const primaryOptions = this.cancelReasons.filter(r => r.id === this.selectedPrimaryReasonId)
      if (primaryOptions.length === 0) { return [] }
      const pr = primaryOptions[0]
      if (!('secondaryReasons' in pr)) { return [] }
      return pr.secondaryReasons.map(sr => {
        return {
          text: sr.reason,
          value: sr.id
        }
      })
    }
  },
  async mounted () {
    if (!this.cancelReasons.length) {
      await this.getCancelReasons()
    }
  },
  methods: {
    ...mapActions('subscribe', [
      'getCancelReasons'
    ]),
    onPrimaryReasonChange (e) {
      this.$logger.debug('cur primary reason', this.selectedPrimaryReasonId, 'event', e)
      this.selectedSecondaryReasonId = null
      this.$emit('input', {
        reason_choice_primary: { id: e },
        reason_choice_secondary: this.selectedSecondaryReasonId,
        secondary_required: this.secondaryOptions.length > 0,
        feedback: this.feedback
      })
    },
    onSecondaryReasonChange (e) {
      this.$logger.debug('cur secondary reason', this.selectedSecondaryReasonId, 'event', e)
      this.$emit('input', {
        reason_choice_primary: { id: this.selectedPrimaryReasonId },
        reason_choice_secondary: { id: e },
        feedback: this.feedback
      })
    },
    onFeedbackChange (e) {
      this.$logger.debug('cur feedback', this.feedback, 'event', e)
      this.$emit('input', {
        reason_choice_primary: { id: this.selectedPrimaryReasonId },
        reason_choice_secondary: { id: this.selectedSecondaryReasonId },
        feedback: e
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .dropdown {
    min-width: 325px;
  }
</style>
