<template>
  <div>
    <address-form-fields
      ref="addressFormFields"
      :address="address"
      :show-set-selected="showSetSelected"
      :set-selected-initial-value="setSelectedInitialValue"
      :show-phone-number-input="showPhoneNumberInput"
      @correctable-address-error="onCorrectableAddressError"
      @address-added-updated="$emit('address-added-updated')"/>
    <base-button-async :func="submit">
      <slot name="button">
        <sequin-button
          block>
          {{ buttonLabel }}
        </sequin-button>
      </slot>
    </base-button-async>
    <div
      v-if="showPhoneNumberInput"
      class="fine-print my-3 text-center text-pewter">
      By continuing, I agree to share my contact information with the Armoire team and be contacted by SMS
      or phone for account updates and/or marketing messages.
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import AddressFormFields from './AddressFormFields'
import SequinButton from '../global/sequin/SequinButton'
import { useOverlaysStore } from '@/stores/overlays.js'
import BaseButtonAsync from '@/components/global/BaseButtonAsync.vue'

export default {
  components: {
    AddressFormFields,
    SequinButton,
    BaseButtonAsync
  },
  props: {
    // If an address is passed in, this will update the address
    // instead of creating a new address.
    address: {
      type: Object,
      default: null
    },
    buttonLabel: {
      type: String,
      default: 'Save Shipping Address'
    },
    showSetSelected: {
      type: Boolean,
      default: false
    },
    setSelectedInitialValue: {
      type: Boolean,
      default: true
    },
    nextRoute: {
      type: String,
      default: ''
    },
    showPhoneNumberInput: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    onCorrectableAddressError ({ error, setAsSelectedShipping }) {
      this.openModal({
        name: 'correct-shipping',
        context: {
          originalAddress: error.originalAddress,
          correctable: error.correctable,
          correctedAddress: error.correctedAddress,
          setAsSelectedShipping: setAsSelectedShipping,
          nextRoute: this.nextRoute
        }
      })
    },
    async submit () {
      await this.$refs.addressFormFields.submit()
    }
  }
}
</script>
