import ItemReviewMixin from '../global/mixins/ItemReviewMixin'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'

export const ReviewMixin = {
  mixins: [StyleColorMixin, ItemReviewMixin],
  methods: {
    buildClientSizesList (review) {
      const { clientHeightFeet, clientHeightInches, clientSizeRange, clientBustSize } = review
      const sizesList = []
      if (clientSizeRange) {
        const sizeRange = clientSizeRange.length > 1
          ? `${clientSizeRange[0]} - ${clientSizeRange[clientSizeRange.length - 1]}`
          : clientSizeRange[0]
        sizesList.push(`Usually wears: ${sizeRange}`)
      }
      if (clientHeightFeet && clientHeightInches) {
        sizesList.push(`Height: ${clientHeightFeet}'${clientHeightInches}"`)
      }
      if (clientBustSize) {
        sizesList.push(`Bust: ${clientBustSize}`)
      }
      return sizesList
    }
  },
  computed: {
    size () {
      return this.review.feedbackSize
    },
    overallRating () {
      return (this.getOverallRatingText(this.review.overallRating))
    },
    fitText () {
      return this.getFitText(this.review)
    },
    shortFitText () {
      return this.getShortFitText(this.review)
    },
    clientSizeList () {
      return this.buildClientSizesList(this.review)
    },
    wornFrequencyText () {
      return this.getWornFrequencyText(this.review)
    },
    reviewClientIsPrivate () {
      return this.review.client === null
    },
    reviewClientDisplayName () {
      if (this.reviewClientIsPrivate) {
        return 'Armoire Member'
      } else if (this.review.client?.lastName) {
        return `${this.review.client.firstName} ${this.review.client?.lastName.charAt(0)}.`
      } else {
        return `${this.review.client.firstName}`
      }
    }
  }
}
