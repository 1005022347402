<template>
  <div class="small-text">
    <div class="mt-3 d-flex align-items-center position-relative">
      <div class="axis"/>
      <div
        class="rectangle"
        :style="rectanglePosition"/>
    </div>
    <div class="d-flex justify-content-between mt-2">
      <span
        v-for="(rating, index) in sizingRatingOptions"
        :key="rating.text">
        <span v-if="index % 2 === 0">
          {{ rating.text }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SizingRating',
  props: {
    rating: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState('review', [
      'sizingRatingOptions'
    ]),
    rectanglePosition () {
      const lowValue = this.sizingRatingOptions[0].value
      const ratingPercent = (this.rating - lowValue) * (1 / (this.sizingRatingOptions.length - 1)) * 100

      let marginLeft = -20
      if (ratingPercent === 0) marginLeft = 0
      if (ratingPercent === 100) marginLeft = -40
      return { marginLeft, left: `${ratingPercent}%` }
    }
  }
}
</script>

<style lang="scss" scoped>

  .axis {
    width: 100%;
    flex-grow: 1;
    height: 1px;
    background-color: $ash;
  }

  .rectangle {
    width: 40px;
    height: 6px;
    background-color: $plum;
    position: absolute;
  }

</style>
