<template>
  <div class="background">
    <transition name="fade">
      <div
        v-if="outstandingItems.length">
        <div class="container mb-4">
          <h2 class="my-4">
            Please return your clothes
          </h2>
          <p>
            You requested to cancel your membership on {{ toMonthDayYearString(membershipDetails.scheduledCancelDate) }}, but we have not received your outstanding items. If we do not receive your items by {{ toMonthDayYearString(membershipDetails.cancelGracePeriodDate) }}, you will be charged the full member price for the clothing you have kept.
          </p>
          <div>
            <p>
              Print a new return label if you’ve lost yours.
              <text-link
                :to="{ name: 'returns'}"
                type="router-link"
                size="paragraph">
                Get Label
              </text-link>
            </p>
          </div>
        </div>
        <div class="bg-origami py-3">
          <div
            class="container"
            :class="{'d-flex': !isMobile}">
            <div
              :class="{'me-3': !isMobile}">
              <h2>
                Already returned your items?
              </h2>
              <p>Track the status of any items you’ve already dropped in the mail.</p>
            </div>
            <sequin-button
              small
              type="router-link"
              :to="{ name: 'returns'}"
              :class="{'ms-5': !isMobile}">
              Return Status
            </sequin-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { toMonthDayYearString } from '@/utils/stringParsing.js'
import TextLink from '../global/sequin/TextLink'
import SequinButton from '../global/sequin/SequinButton'

export default {
  name: 'PendingCancel',
  components: {
    TextLink,
    SequinButton
  },
  computed: {
    ...mapState('client', [
      'membershipDetails'
    ]),
    ...mapGetters('closet', [
      'outstandingItems'
    ])
  },
  created () {
    this.getPackageItems()
  },
  methods: {
    ...mapActions('closet', [
      'getPackageItems'
    ]),
    toMonthDayYearString
  }
}
</script>

<style lang="scss" scoped>
  .background {
    height: 100vh;
  }
</style>
