
export const ClosetImagesMixin = {
  computed: {
    frontImage () {
      return this.styleColor.images.front
    },
    backImage () {
      return this.styleColor.images.back
    },
    alternativeImages () {
      return this.styleColor.images.alternative
    },
    customerImages () {
      if (!this.reviews) return []

      if (typeof this.reviews !== 'undefined') {
        const reviewImages = this.reviews.map((review) => {
          const images = review.images.map((image) => {
            return {
              review: { ...review },
              url: image.url
            }
          })
          return images
        })
        return reviewImages
      }
      return []
    },
    allImages () {
      const images = [this.frontImage]
      if (this.backImage) images.push(this.backImage)

      return images.concat(this.alternativeImages).concat(...this.customerImages)
    }
  },
  methods: {
    mod (n, m) {
      // fixes behavior for negative numbers
      return (n + m) % m
    },
    imageSwipeHandler (direction) {
      if (direction === 'left') {
        this.shiftRight()
      } else if (direction === 'right') {
        this.shiftLeft()
      }
    },
    shiftLeft () {
      this.selectedIndex = this.mod(this.selectedIndex - 1, this.allImages.length)
    },
    shiftRight () {
      this.selectedIndex = this.mod(this.selectedIndex + 1, this.allImages.length)
    }
  }
}
