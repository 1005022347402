<template>
  <router-view
    :key="$route.path"
    #default="{ Component }">
    <keep-alive>
      <component
        :is="Component"
        class="custom-fade"/>
    </keep-alive>
  </router-view>
</template>

<script>
import { vuexAccessors } from '../global/helpers/vuex'
export default {
  computed: {
    ...vuexAccessors('review', [
      'canSkipReviews',
      'reviewItems'
    ])
  },
  beforeUnmount () {
    this.reviewItems = []
    this.canSkipReviews = false
  }
}
</script>

<style lang="scss" scoped>
  /*
    Vue transitions have issues with keep-alive, so we should stick with
    a simple CSS animation for now (https://github.com/quasarframework/quasar/issues/1466)
   */
  .custom-fade {
    animation: appear .5s ease;
    @keyframes appear {
      0% { opacity: 0 }
      100% { opacity: 1 }
    }
  }
</style>
