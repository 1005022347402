import { mapActions, mapGetters, mapState } from 'vuex'
import { vuexAccessors } from '../../global/helpers/vuex'
import SequinButton from '../../global/sequin/SequinButton'
import PlanSelector from '../PlanSelector'

export default {
  components: { SequinButton, PlanSelector },
  computed: {
    ...mapState('client', [
      'plans',
      'membershipDetails',
      'membershipStatus',
      'paidThrough',
      'balance'
    ]),
    ...mapState('closet', [
      'deliveredItems',
      'toReturnItems'
    ]),
    ...vuexAccessors('subscribe', [
      'membershipPlans',
      'membershipPlanSelectedId',
      'membershipPlanQuotes'
    ]),
    ...vuexAccessors('account', [
      'breadcrumbs',
      'confirmChangeTitle'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected'
    ]),
    clientIsMonthly () {
      if (this.plans && this.plans.currentBasePlan) {
        return this.plans.currentBasePlan.chargeInterval === 1
      }
      return true
    },
    clientIsPrepay () {
      if (this.plans && this.plans.currentBasePlan) {
        return this.plans.currentBasePlan.prepayment
      }
      return false
    },
    clientIsTrialing () {
      if (this.membershipDetails && !this.membershipDetails.planChangeEligible) {
        return this.membershipDetails.planChangeIneligibleReason === 'TRIALING'
      }
      return false
    },
    availablePlans () {
      if (this.membershipPlans) {
        return this.membershipPlans
      }
      return []
    },
    availableMonthlyPlans () {
      return this.availablePlans.filter(plan => !plan.prepayment)
    },
    availablePrepayPlans () {
      return this.availablePlans.filter(plan => plan.prepayment)
    },
    eligibleForChange () {
      return this.membershipDetails && this.membershipDetails.planChangeEligible
    },
    ineligibilityReason () {
      return this.membershipDetails ? this.membershipDetails.planChangeIneligibleReason : null
    },
    ineligibilityReasonText () {
      const reason = this.membershipDetails ? this.membershipDetails.planChangeIneligibleReason : null
      if (reason === 'OOC') {
        return `You can't change your membership because you have too many outstanding items.
        Once we receive these items at our warehouse, you can switch to one of the follow plans.`
      }
      if (reason === 'PACKING_CASE') {
        return `You can't change your membership because we're packing your latest case. Once your
        items have shipped, you can switch to one of the following plans.`
      }
      if (reason === 'NEW_SUBSCRIPTION') {
        return 'You can\'t change your membership because your subscription is too new.'
      }
      if (reason === 'TRIALING') {
        return 'You can\'t change your membership because your subscription is still on promotional pricing.'
      }
      if (reason) {
        return reason
      }
      return ''
    },
    eligibleForPause () {
      return this.membershipDetails.selfServicePauseEligible
    },
    ineligibleForPauseReason () {
      return this.membershipDetails.selfServicePauseIneligibleReason
    },
    displayPauseAction () {
      return this.membershipStatus === 'active' || this.membershipStatus === 'paused'
    },
    cancellationScheduled () {
      return this.membershipDetails && this.membershipDetails.scheduledCancelDate
    },
    cancelEligible () {
      return this.membershipDetails && this.membershipDetails.cancelEligible
    },
    bonusItems () {
      return this.membershipDetails.bonusItems.length > 0 ? this.membershipDetails.bonusItems.length : null
    },
    creditAvailable () {
      return this.balance < 0 ? -this.balance : null
    },
    availableSwaps () {
      return this.membershipDetails.allowedShipments > 0 ? this.membershipDetails.allowedShipments : null
    }
  },
  methods: {
    ...mapActions('subscribe', [
      'fetchPlans',
      'getQuote'
    ]),
    ...mapActions('client', [
      'updateMembershipPlan'
    ]),
    onPlanSelected (planId) {
      if (this.membershipPlanSelectedId === planId) {
        this.membershipPlanSelectedId = null
      } else {
        this.membershipPlanSelectedId = planId
      }
    },
    onPlanChangeSuccess () {
      this.updateMembershipPlan(this.membershipPlanSelected)
    }
  }
}
