<template>
  <div>
    <h5 :class="{'text-danger': validations.$error}">
      {{ label }}<span
        v-if="validations.required"
        class="required-asterisk">*</span>
    </h5>
    <BubbleSelector
      v-if="useBubbleSelector"
      v-model="internalValue"
      :options="options"
      @set-bubble-selection="emitSetBubbleSelection"/>
    <SingleSelectRow
      v-else
      v-model="internalValue"
      :options="options"/>
    <base-errors
      :class="{ 'error-scroll': validations.$error }"
      :validations="validations"/>
  </div>
</template>

<script>
import BubbleSelector from '@/components/global/sequin/BubbleSelector.vue'
import SingleSelectRow from '../global/sequin/SingleSelectRow'
import BaseErrors from '@/components/global/BaseErrors.vue'

export default {
  name: 'ReviewSingleSelect',
  components: {
    SingleSelectRow,
    BubbleSelector,
    BaseErrors
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: undefined,
      validator: v => typeof v === 'string' || v === undefined
    },
    options: {
      type: Array,
      required: true
    },
    validations: {
      type: Object,
      required: true
    },
    useBubbleSelector: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalValue: {
      get () { return this.value },
      set (v) { this.$emit('input', v) }
    }
  },
  methods: {
    emitSetBubbleSelection (selection) {
      this.internalValue = selection
      this.$emit('input', selection)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './ReviewItem.scss';
</style>
