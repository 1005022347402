<template>
  <div class="d-flex align-items-center">
    <svg-circle-icon
      inverse
      :variant="status.color">
      <component :is="status.icon"/>
    </svg-circle-icon>
    <span
      class="ms-2 semi-bold"
      :class="{ 'text-success': trackingStatus === 'DELIVERED' }">
      {{ status.status }}
    </span>
  </div>
</template>

<script>
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'
import SvgArrowRight from '@/components/global/svg/SvgArrowRight.vue'
import SvgArrowLeft from '@/components/global/svg/SvgArrowLeft.vue'
import SvgX from '@/components/global/svg/SvgX.vue'

export default {
  name: 'ReturnStatus',
  components: {
    SvgCircleIcon,
    SvgCheck,
    SvgArrowRight,
    SvgArrowLeft,
    SvgX
  },
  props: {
    trackingStatus: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      statusDisplayObjects: {
        DELIVERED: { icon: SvgCheck, status: 'Delivered', color: 'success' },
        TRANSIT: { icon: SvgArrowRight, status: 'In Transit', color: 'info' },
        RETURNED: { icon: SvgArrowLeft, status: 'Returned', color: 'warning' },
        FAILURE: { icon: SvgX, status: 'Failure', color: 'danger' }
      }
    }
  },
  computed: {
    status () {
      return this.statusDisplayObjects[this.trackingStatus] || {}
    }
  }
}
</script>
