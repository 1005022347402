import axios from './config'
import { serializeParams } from './helpers/helpers'

const apiSearch = {
  getAutosuggest (query) {
    return axios.get('/api/search/autosuggest/?q=' + query)
  },
  search (data, baseUrl = '/api/search/search/?q=') {
    const url = baseUrl + data.query

    let params = `&skip=${data.skip}&top=${data.top}`
    if ('available' in data) params += `&available=${data.available}`
    if ('sizeFilter' in data) params += `&sizeFilter=${data.sizeFilter}`
    if ('filters' in data) params += '&' + serializeParams(data.filters)

    return axios.get(url + params)
  },
  fetchSimilarSearchTerms (data) {
    return this.search(data, '/api/search/similar_terms/?q=')
  },
  getAutosuggestMembers (query) {
    return axios.get('/api/clients/?search=' + query + '&autosuggest=1')
  },
  searchMembers (query) {
    return axios.get('/api/clients/?search=' + query)
  },
  getNextPage (endpoint) {
    return axios.get(endpoint)
  }
}

export default apiSearch
