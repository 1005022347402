<template>
  <div
    class="placeholder">
    <div class="placeholder-image"/>
    <div class="ms-3 ">
      <div class="placeholder-info-1 mb-2"/>
      <div class="placeholder-info-2 mb-2 "/>
      <div class="placeholder-info-3"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.placeholder {
  height: 156px;
  background: $white;
  display: flex;
  padding: 12px 24px;

  .placeholder-image {
    @include placeholder-loading-animation;
    width: 99px;
    height: 130px;
  }

  .placeholder-info-1, .placeholder-info-2, .placeholder-info-3 {
    @include placeholder-loading-animation;
    height: 20px;
    border-radius: 2px;
    width: 90px;
  }

  .placeholder-info-2 {
    width: 160px;
  }

  .placeholder-info-3 {
    width: 152px;
  }
}

</style>
