<template>
  <div>
    <div class="filter-tags">
      <FilterTag
        v-for="filter in selectedFilters"
        :key="filter.value"
        class="me-2"
        :filter="filter"
        @remove-filter="emit('remove-filter', $event)"/>
    </div>
    <div
      class="filter-tags-clear-button">
      <TextLink
        variant="primary-new"
        @click="$emit('clear-all-filters')">
        Clear All
      </TextLink>
    </div>
  </div>
</template>

<script setup>
import TextLink from '@/components/global/sequin/TextLink.vue'
import FilterTag from '@/components/styleColorSet/filtersFlyout/FilterTag.vue'

defineProps({
  selectedFilters: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(['remove-filter', 'clear-all-filters'])
</script>
<style scoped lang="scss">
.filter-tags {
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
}
.filter-tags-clear-button {
  flex: 1 0 auto;
  margin: 0 8px;
  @include media-tablet-or-smaller {
    margin-left: 0;
  }
}
</style>
