<template>
  <div
    ref="buttonAsync"
    @click.stop="click($event)">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    func: {
      type: Function,
      required: true
    },
    args: {
      type: Array,
      default: () => { return [] }
    },
    reenable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async click (event) {
      // We disable both the target (element that was clicked)
      // and the base div, in case the element that was clicked
      // does not support the disabled class.
      const el = event.target
      const buttonAsync = this.$refs.buttonAsync

      if (el.disabled || buttonAsync.disabled) return

      el.disabled = true
      buttonAsync.disabled = true
      try {
        await this.func(...this.args)
      } catch (err) {
        this.$logger.error(err)
      }
      if (this.reenable) {
        el.disabled = false
        buttonAsync.disabled = false
      }
    }
  }
}
</script>
