<template>
  <div>
    <header
      v-if="showHeader"
      class="bg-white fixed-top">
      <nav
        class="navbar navbar-expand-md navbar-light navbar-header align-items-center"
        role="navigation"
        aria-label="Main menu">
        <back-button
          v-if="showBackButton"
          class="bg-white back-button p-0 border-0 ms-3 me-2"
          :back-default-route="{ name: 'style-quiz-intro' }"/>
        <div class="d-flex align-items-center py-2">
          <!-- Mobile text -->
          <router-link
            :to="isAuthenticated ? { path: '/home' } : { name: 'login' }"
            class="navbar-brand p-2 me-0">
            <img
              class="m-0 h-auto"
              alt="Armoire"
              :class="!isMobile && 'ms-2'"
              :width="isMobile ? '116' : '140'"
              height="auto"
              :src="logoSource">
          </router-link>
        </div>
      </nav>
    </header>

    <div
      class="style-quiz row g-0"
      :class="{ 'sign-up-experiment': showingSignUpExperiment }">
      <style-quiz-header
        v-if="!showingSignUpExperiment"
        class="px-4"
        :page-header="currentRoute ? currentRoute.pageHeader : null"
        :page-subheader="currentRoute ? currentRoute.pageSubheader : null"/>
      <div
        class="router-box"
        :class="{ 'mobile': isMobile, 'd-grid': showingSignUpExperiment }">
        <router-view
          :key="$route.path"
          #default="{ Component }">
          <transition
            name="fade"
            mode="out-in">
            <component :is="Component"/>
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { vuexAccessors } from '../global/helpers/vuex'
import AnalyticsMixin from '../global/mixins/analyticsMixin'
import BaseNavigationMixin from '../global/BaseNavigationMixin'
import StyleQuizHeader from './StyleQuizHeader'
import BackButton from '../global/sequin/BackButton'
import { MobileAppMessage, inMobileWebView, postToMobileWebView } from '@/utils/mobileApp.js'
import { useSignUpExperiments } from '@shared/composables/experiment.js'
import { useRoute } from 'vue-router'
import { computed, watch } from 'vue'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'

export default {
  components: {
    StyleQuizHeader,
    BackButton
  },
  mixins: [BaseNavigationMixin, AnalyticsMixin],
  setup () {
    const { signUpPhase1 } = useSignUpExperiments()
    const replacedRoutes = {
      'style-quiz-intro': 'sign-up-onboarding',
      'style-quiz-create-account': 'sign-up-create-account'
    }
    const route = useRoute()
    const showingSignUpExperiment = computed(() =>
      signUpPhase1.value && Object.keys(replacedRoutes).includes(route.name)
    )

    // Keep the store in sync with the current route.
    const signUpNav = useSignUpNavigationStore()
    watch([route, showingSignUpExperiment], () => {
      if (showingSignUpExperiment.value) {
        signUpNav.goTo(replacedRoutes[route.name])
      }
    }, { immediate: true })

    return {
      showingSignUpExperiment
    }
  },
  computed: {
    ...mapState('client', [
      'styleProfileId',
      'isAuthenticated',
      'styleProfileComplete'
    ]),
    ...mapState('subscribe', [
      'membershipPlans'
    ]),
    ...mapState('styleQuizNav', [
      'isPrepayFlow',
      'isEvent'
    ]),
    ...mapGetters('styleQuizNav', [
      'getCurrentStyleQuizRoute',
      'getNextStyleQuizRouteForRouter',
      'shouldShowNextButtonForRoute'
    ]),
    ...mapState('styleQuizNav', [
      'isEvent'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected'
    ]),
    ...vuexAccessors('styleQuizNav', [
      'isNonMember',
      'isRejoiningMember'
    ]),
    showHeader () {
      return !inMobileWebView() && !this.showingSignUpExperiment
    },
    nextRoute () {
      return this.shouldShowNextButtonForRoute({ ...this.$route })
        ? this.getNextStyleQuizRouteForRouter({ ...this.$route })
        : null
    },
    showBackButton () {
      return !((inMobileWebView() && this.$route.name === 'style-quiz-intro') ||
               (this.isEvent && ['style-quiz-create-account', 'style-quiz-choose-membership'].includes(this.$route.name)))
    }
  },
  watch: {
    $route: function () {
      this.sendMembershipAnalytics()
    },
    styleProfileComplete: {
      handler (newValue, oldValue) {
        if (newValue && !oldValue) {
          postToMobileWebView(MobileAppMessage.StyleProfileComplete)
        }
      },
      immediate: true
    }
  },
  async mounted () {
    await this.getStyleProfileOptions()
    this.sendMembershipAnalytics()
    this.getReferralCampaign()
    await this.parseQueryParams({ query: this.$route.query })
    await this.fetchPlans()
    if (this.isAuthenticated) {
      await this.getClient()
    }
    if (!this.membershipPlanSelected && !this.isPrepayPlan) {
      const defaultPlan = this.membershipPlans.find(plan => plan.name === 'Armoire Unlimited Monthly')
      await this.selectMembershipPlan({ planId: defaultPlan.id })
    }

    this.isRejoiningMember = this.membershipStatus === 'former_member'

    // For messaging at the end of the quiz, we need to know if they start as a member
    this.isNonMember = !this.membershipStatus || this.membershipStatus === 'non_member'
    this.$store.commit('styleQuizNav/SET_STYLE_QUIZ_ROUTE_DISPLAYED', { name: 'style-quiz-prepay-plan', display: this.isPrepayFlow })
  },
  methods: {
    ...mapActions('subscribe', [
      'fetchPlans',
      'getReferralCampaign',
      'selectMembershipPlan',
      'parseQueryParams'
    ]),
    ...mapActions('client', [
      'getClient'
    ]),
    ...mapActions('styleProfile', [
      'getStyleProfileOptions'
    ])
  }
}
</script>

<style lang="scss" scoped>
.style-quiz {
  background: linear-gradient(167.11deg, rgba(133, 13, 84, 0.05) 100%, rgba(172, 153, 164, 0.05) 18.01%);
  max-width: 100vw;
  min-height: 100dvh;
}

@include experiment('sign-up-phase1', 'redesign') {
  .style-quiz.sign-up-experiment {
    @include adorn-gradient;
  }
}

.router-box {
  margin: 0 auto;
  width: 100vw;
  min-height: calc(100dvh - 140px);

  &.mobile {
    min-height: 100dvh;
  }
}

.back-button {
  margin-top: 2px;

  &:focus {
    outline: none;
  }
}
</style>
