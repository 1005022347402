<template>
  <plan-select-page
    v-if="!clientIsPrepay"
    header="Resume Membership"
    confirmation-title="Resume Membership"
    :selectable-plans="membershipPlans.filter(plan => !plan.prepayment)"
    :breadcrumb="{ name: 'Resume Membership', to: 'resume' }"/>
  <div
    v-else>
    <div class="row">
      <h2
        class="col col-12 text-center text-md-start mb-2 ps-md-0">
        Resume Membership
      </h2>
    </div>
    <div class="row">
      <div class="col col-12 col-md-9 text-center text-md-start ps-md-0">
        <p
          class="pb-3">
          Your prepay cycle will resume immediately and you can select new styles
          right away.
        </p>
        <div
          v-for="error in errors"
          :key="error"
          class="mb-2 text-danger"
          v-html="error"/>
        <sequin-button
          v-if="!isMobile"
          type="button"
          variant="primary"
          :disabled="buttonDisabled"
          @click="onResumeClick">
          Resume Today
        </sequin-button>
      </div>
    </div>
    <action-footer v-if="isMobile">
      <template #button>
        <sequin-button
          mobile-block
          type="button"
          variant="primary"
          :disabled="buttonDisabled"
          @click="onResumeClick">
          Resume Today
        </sequin-button>
      </template>
    </action-footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ActionFooter from '../ActionFooter'
import PlanSelectPage from './PlanSelectPage'
import MembershipMixin from './MembershipMixin'
import SequinButton from '../../global/sequin/SequinButton'

export default {
  name: 'ResumeMembership',
  components: { PlanSelectPage, ActionFooter, SequinButton },
  mixins: [MembershipMixin],
  data () {
    return {
      buttonDisabled: false,
      errors: null
    }
  },
  async mounted () {
    if (!this.membershipPlans) {
      await this.fetchPlans()
    }
    const { currentBasePlan } = this.plans
    const existingPlan = this.membershipPlans.find(plan => plan.id === currentBasePlan.id)
    this.membershipPlanSelectedId = existingPlan.id
  },
  methods: {
    ...mapActions('account', [
      'unpauseSubscription'
    ]),
    async onResumeClick () {
      this.buttonDisabled = true
      this.errors = null
      try {
        await this.unpauseSubscription()
        this.$router.push({ name: 'closet-sections' })
      } catch (err) {
        this.errors = err
      }
    }
  }
}
</script>

<style scoped>

</style>
