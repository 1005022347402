<template>
  <div class="d-flex justify-content-center">
    <div>
      <div class="row pt-4">
        <div
          v-for="(item, index) in summaryMetrics"
          :key="'metric-' + index"
          class="col"
          :class="{'bordered': index < summaryMetrics.length - 1}">
          <h3 class="metric">
            {{ item.metric }}
          </h3>
          <p class="text-center">
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from '@/utils/stringParsing.js'

export default {
  name: 'ReferralRewardSummary',
  props: {
    creditAvailable: {
      type: Number,
      default: 0
    },
    referrals: {
      type: Array,
      required: true
    },
    referralAwards: {
      type: Object,
      required: true
    }
  },
  computed: {
    summaryMetrics () {
      return [
        { metric: formatPrice(this.creditAvailable, true), description: 'Credits available' },
        { metric: formatPrice(this.referralAwards.credit || 0, true), description: 'Credits earned' },
        { metric: this.referrals.filter(referral => referral.joined).length, description: 'Friends joined' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .bordered {
    border-right: 1px solid lightgrey;
  }
  .metric {
    text-align: center;
    color: $plum;
  }
</style>
