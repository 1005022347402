<template>
  <div>
    <div
      class="border py-3 px-5 text-center text-pewter bg-white">
      <div>
        <svg-follow
          class="pe-1 follow-icon"/>
      </div>
      <div
        class="pt-3">
        Follow other members to see their latest looks
        and rental activity.
      </div>
    </div>
    <div
      class="border border-top-0 text-center p-3 bg-origami">
      Recommended for you
    </div>
    <div
      v-for="(client, index) in suggestedFollowers"
      :key="index">
      <profile-follow
        :client="client"
        :is-own-profile="false"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { vuexAccessors } from '../../global/helpers/vuex'
import ProfileFollow from './ProfileFollow'
import SvgFollow from '@/components/global/svg/SvgFollow.vue'

export default {
  components: {
    ProfileFollow,
    SvgFollow
  },
  computed: {
    ...mapState('community', [
      'suggestedFollowers'
    ]),
    ...vuexAccessors('community', [
      'addingSuggestedFollowers'
    ])
  },
  mounted () {
    this.addingSuggestedFollowers = true
    this.getSuggestedToFollow()
  },
  beforeUnmount () {
    this.addingSuggestedFollowers = false
  },
  methods: {
    ...mapActions('community', [
      'getSuggestedToFollow'
    ])
  }
}

</script>

<style lang="scss" scoped>

.follow-icon {
  height: 50px;
  width: 50px;
  stroke: $ash;
}

</style>
