<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-star">
    <linearGradient
      id="gradient25"
      x1="0"
      x2="1"
      y1="0"
      y2="0">
      <stop
        offset="0%"
        stop-color="#520844"/>
      <stop
        offset="35%"
        stop-color="#520844"/>
      <stop
        offset="35%"
        stop-color="#D7D7DA"/>
    </linearGradient>
    <linearGradient
      id="gradient50"
      x1="0"
      x2="1"
      y1="0"
      y2="0">
      <stop
        offset="0%"
        stop-color="#520844"/>
      <stop
        offset="50%"
        stop-color="#520844"/>
      <stop
        offset="50%"
        stop-color="#D7D7DA"/>
    </linearGradient>
    <linearGradient
      id="gradient75"
      x1="0"
      x2="1"
      y1="0"
      y2="0">
      <stop
        offset="0%"
        stop-color="#520844"/>
      <stop
        offset="65%"
        stop-color="#520844"/>
      <stop
        offset="65%"
        stop-color="#D7D7DA"/>
    </linearGradient>
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
  </svg>
</template>
