<template>
  <div>
    <h6 class="mb-lg">
      Add a photo to your review
    </h6>
    <image-uploader
      v-if="showUploader"
      allow-multiple
      :height="300"
      :local-images="images"
      :image-upload-url-function="imageUploadUrlFunction"
      @remove-local-upload="onDelete"
      @add-local-upload="onUpload"/>
    <plain-button
      v-else
      block
      class="p-0"
      @click="showUploader = true">
      <add-photo-cta
        class="flex px-2"
        :cta="imageUploadButtonText"
        :images="[]"/>
    </plain-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import apiGlobal from '../../store/api/apiGlobal'
import AddPhotoCta from '../community/sharelook/AddPhotoCta'
import PlainButton from '../global/sequin/PlainButton'
import ImageUploader from '../global/sequin/ImageUploader/ImageUploader'

export default {
  components: {
    ImageUploader,
    AddPhotoCta,
    PlainButton
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    existingReviewImages: {
      type: Array,
      default: () => []
    },
    imageUploadButtonText: {
      type: String,
      default: 'Add a photo to your review'
    }
  },
  data () {
    return {
      id: null,
      images: this.existingReviewImages.slice(),
      showUploader: this.existingReviewImages.length
    }
  },
  computed: {
    ...mapState('client', [
      'username'
    ]),
    imageObjects () {
      return this.images.map(url => ({ preprocessedUrl: url }))
    }
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    onUpload (url) {
      this.images.push(url)
      this.$emit('photos-changed', this.imageObjects)
    },
    onDelete (url) {
      this.images.splice(this.images.indexOf(url), 1)
      this.$emit('photos-changed', this.imageObjects)
      if (this.images.length === 0) this.showUploader = false
    },
    imageUploadUrlFunction (data) {
      return apiGlobal.presignAWS({ ...data, itemId: this.review.itemId })
    },
    closeUpload () {
      this.showUppy = false
      this.uppy.close()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
