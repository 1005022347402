<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M8.00008 18.8333C8.46032 18.8333 8.83341 18.4602 8.83341 18C8.83341 17.5398 8.46032 17.1667 8.00008 17.1667C7.53984 17.1667 7.16675 17.5398 7.16675 18C7.16675 18.4602 7.53984 18.8333 8.00008 18.8333Z"
        fill="white"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"/>
      <path
        d="M17.1666 18.8333C17.6268 18.8333 17.9999 18.4602 17.9999 18C17.9999 17.5398 17.6268 17.1667 17.1666 17.1667C16.7063 17.1667 16.3333 17.5398 16.3333 18C16.3333 18.4602 16.7063 18.8333 17.1666 18.8333Z"
        fill="white"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"/>
      <path
        d="M1.33325 1.33332H4.66658L6.89992 12.4917C6.97612 12.8753 7.18484 13.22 7.48954 13.4652C7.79424 13.7105 8.1755 13.8408 8.56658 13.8333H16.6666C17.0577 13.8408 17.4389 13.7105 17.7436 13.4652C18.0483 13.22 18.257 12.8753 18.3333 12.4917L19.6666 5.49999H5.49992"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>
