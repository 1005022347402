<template>
  <router-view #default="{ Component }">
    <transition
      name="fade"
      mode="out-in">
      <component
        :is="Component"
        class="mx-auto"/>
    </transition>
  </router-view>
</template>

<script>

export default {
  created () {
    if (typeof Stripe === 'undefined') {
      // load the Stripe library (once), the first time the user enters the
      // Account page.  Takes ~300-400ms on a 3G connection, so this
      // will be done a long time before it's actually needed (in
      // updating card details)
      const script = document.createElement('script')
      script.id = '_stripe-checkout-script'
      script.src = 'https://js.stripe.com/v3/'
      script.type = 'text/javascript'
      document.head.appendChild(script)
    }
  }
}
</script>
