<template>
  <accordion-with-header
    :expanded="expanded"
    :plus-variant="!birthDate"
    title="Birthday"
    @accordion-click="e => $emit('accordion-click', e)">
    <template #subtitle>
      <p class="ms-2 mb-0 text-pewter">
        {{ getBirthdateText }}
      </p>
    </template>
    <base-input
      v-model="birthDate"
      :validations="v$.birthDate"
      :cleave-options="{
        date: true,
        datePattern: ['m', 'd', 'Y']}"
      label="Birthday"
      helper-text="mm/dd/yyyy"/>
  </accordion-with-header>
</template>

<script>
import { vuexAccessors } from '../global/helpers/vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { birthYear } from '../global/vuelidate/customValidators'

import BaseInput from '@/components/global/BaseInput.vue'
import AccordionWithHeader from '../global/sequin/AccordionWithHeader.vue'

export default {
  components: {
    BaseInput,
    AccordionWithHeader
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    ...vuexAccessors('styleProfile', [
      'birthDate'
    ]),
    getBirthdateText () {
      if (this.birthDate && this.birthDate.length > 0) {
        return `${this.birthDate}`
      } else {
        return ''
      }
    }
  },
  validations: {
    birthDate: {
      required,
      birthYear
    }
  }
}
</script>
