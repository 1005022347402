<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.20769 18.7118C7.98096 17.9385 9.02974 17.5041 10.1233 17.5041H17.126C18.2196 17.5041 19.2684 17.9385 20.0416 18.7118C20.8149 19.485 21.2493 20.5338 21.2493 21.6274V23.378C21.2493 23.7215 20.9709 24 20.6274 24C20.2839 24 20.0054 23.7215 20.0054 23.378V21.6274C20.0054 20.8637 19.7021 20.1313 19.1621 19.5913C18.6221 19.0513 17.8897 18.748 17.126 18.748H10.1233C9.35964 18.748 8.62725 19.0513 8.08726 19.5913C7.54727 20.1313 7.2439 20.8637 7.2439 21.6274V23.378C7.2439 23.7215 6.96545 24 6.62195 24C6.27846 24 6 23.7215 6 23.378V21.6274C6 20.5338 6.43442 19.485 7.20769 18.7118Z"
      fill="currentColor"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6246 8.2439C12.0344 8.2439 10.7452 9.53306 10.7452 11.1233C10.7452 12.7136 12.0344 14.0027 13.6246 14.0027C15.2149 14.0027 16.504 12.7136 16.504 11.1233C16.504 9.53306 15.2149 8.2439 13.6246 8.2439ZM9.5013 11.1233C9.5013 8.84607 11.3474 7 13.6246 7C15.9018 7 17.7479 8.84607 17.7479 11.1233C17.7479 13.4005 15.9018 15.2466 13.6246 15.2466C11.3474 15.2466 9.5013 13.4005 9.5013 11.1233Z"
      fill="currentColor"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.6511 18.0843C22.737 17.7517 23.0762 17.5517 23.4088 17.6376C24.2934 17.866 25.0771 18.3817 25.6368 19.1037C26.1965 19.8258 26.5006 20.7133 26.5013 21.6269V23.378C26.5013 23.7215 26.2228 24 25.8794 24C25.5359 24 25.2574 23.7215 25.2574 23.378V21.6278C25.2569 20.9899 25.0445 20.37 24.6537 19.8658C24.2628 19.3616 23.7156 19.0015 23.0978 18.842C22.7653 18.7561 22.5653 18.4169 22.6511 18.0843Z"
      fill="currentColor"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1495 7.58144C19.2347 7.24868 19.5735 7.04799 19.9063 7.13319C20.7932 7.36029 21.5794 7.87611 22.1408 8.59935C22.7022 9.32259 23.0069 10.2121 23.0069 11.1276C23.0069 12.0432 22.7022 12.9327 22.1408 13.6559C21.5794 14.3792 20.7932 14.895 19.9063 15.1221C19.5735 15.2073 19.2347 15.0066 19.1495 14.6739C19.0643 14.3411 19.265 14.0023 19.5978 13.9171C20.2171 13.7585 20.7661 13.3983 21.1581 12.8932C21.5502 12.3882 21.763 11.767 21.763 11.1276C21.763 10.4883 21.5502 9.86713 21.1581 9.36208C20.7661 8.85702 20.2171 8.49681 19.5978 8.33822C19.265 8.25302 19.0643 7.9142 19.1495 7.58144Z"
      fill="currentColor"/>
  </svg>
</template>
