<template>
  <div class="single-select-row d-flex">
    <label
      v-for="option in parsedOptions"
      :key="option.id"
      :class="{ 'selected': internalValue === option.value }"
      class="text-center flex-grow-1 px-xs py-sm">
      <input
        :value="option.value"
        :name="name"
        type="radio"
        @input="internalValue = option.value">
      {{ option.text }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      validator: v => typeof v === 'string' || v == null,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    parsedOptions () {
      if (typeof this.options[0] === 'string') {
        return this.options.map(x => {
          return { text: x, value: x }
        })
      }
      return this.options
    },
    internalValue: {
      get () { return this.value },
      set (v) { this.$emit('input', v) }
    },
    name () {
      return String(this._uid)
    }
  }
}
</script>

<style lang="scss" scoped>
.single-select-row {
  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    color: $plum;
    font-family: $body-font-family;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
    background-color: $origami;
    $border: 1px solid $plum;
    border: $border;
    border-left: none;
    cursor: pointer;

    &:first-child {
      border-radius: $sequin-border-radius 0 0 $sequin-border-radius;
      border-left: $border;
    }

    &:last-child {
      border-radius: 0 $sequin-border-radius $sequin-border-radius 0;
    }

    &.selected {
      background-color: $plum;
      color: $white;
    }
  }
}
</style>
