<template>
  <profile-share-signup
    v-if="referralProfile"
    class="collection-share-signup"
    :referral-code="referralProfile.referralCode"
    :first-name="referralProfile.firstName"/>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProfileShareSignup from '../community/profile/ProfileShareSignup.vue'

export default {
  components: {
    ProfileShareSignup
  },
  props: {
    collectionProfileId: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...mapState('community', [
      'clientInfoById'
    ]),
    activeShareToken () {
      return this.$route.query.share
    },
    shareProfileId () {
      return this.$route.query.shareProfileId
    },
    shareProfile () {
      return this.clientInfoById[this.shareProfileId]
    },
    clientCollectionProfile () {
      return this.collectionProfileId ? this.clientInfoById[this.collectionProfileId] : null
    },
    referralProfile () {
      // make sure that if someone is signed in, they never see this referral footer
      if (this.id) return null
      // Use the share token client first, if there is one
      // If not, use the client who owns the collection(s), if we have a referral code available
      return this.shareProfile || (this.clientCollectionProfile?.referralCode ? this.clientCollectionProfile : null)
    }
  },
  mounted () {
    if (this.activeShareToken && this.shareProfileId) {
      this.getClientProfile({ clientId: this.shareProfileId, shareToken: this.activeShareToken })
    }
  },
  methods: {
    ...mapActions('community', [
      'getClientProfile'
    ])
  }
}
</script>

<style lang="scss" scoped>
.collection-share-signup {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
