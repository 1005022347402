<template>
  <svg
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0802002 10.7263C0.0802002 9.82779 0.357395 9.55133 1.25828 9.55133C3.06005 9.55133 4.89647 9.55133 6.69823 9.55133C7.52982 9.55133 7.84166 9.86234 7.84166 10.6917C7.84166 14.2511 7.84166 17.8105 7.84166 21.3698C7.84166 22.3029 7.56447 22.5793 6.59429 22.6139C4.79252 22.6139 2.99075 22.6139 1.22363 22.6139C0.392045 22.6139 0.0802002 22.3029 0.0802002 21.4735C0.0802002 19.6765 0.0802002 12.4887 0.0802002 10.7263Z"
      fill="currentColor"/>
    <path
      d="M27.1414 14.8386C27.1414 14.0092 26.7603 13.2835 26.1366 12.7997C26.1019 12.7651 26.1019 12.7306 26.0673 12.696C26.0673 12.6615 26.0326 12.6615 26.0326 12.6269C26.2752 12.2468 26.4138 11.7975 26.4138 11.3483C26.4138 10.0351 25.3396 8.96387 24.023 8.96387C23.9537 8.96387 21.355 8.92931 20.3501 8.96387C19.4146 8.96387 19.1027 9.03298 19.3106 7.71982C19.3106 7.68526 19.3453 7.6507 19.3453 7.61615C19.6918 6.02652 19.865 4.40234 19.7957 2.74361C19.7611 1.98336 19.5878 1.2231 19.0681 0.635632C18.1672 -0.366521 16.9198 -0.159179 16.4347 1.08487C16.2268 1.60323 16.0882 2.15614 15.9496 2.70905C15.5685 4.16045 15.0141 5.57728 14.1825 6.82133C13.0044 8.54918 11.5145 9.79323 9.33161 9.93146C8.91582 9.96602 8.77722 10.1734 8.77722 10.588C8.77722 12.4196 8.77722 19.7111 8.77722 21.5426C8.77722 21.9919 8.91582 22.1992 9.40091 22.3029C10.4404 22.5102 11.4452 22.8558 12.4847 23.1322C13.4895 23.4087 14.4944 23.8234 15.5339 23.8925C17.7514 24.0307 22.4984 23.9962 22.5677 23.9962C23.8844 23.9962 24.9585 22.9249 24.9585 21.6117C24.9585 21.197 24.8545 20.7824 24.6466 20.4368C24.6466 20.4368 24.6813 20.4368 24.6813 20.4022C25.5822 20.0567 26.2405 19.1927 26.2405 18.156C26.2405 17.7759 26.1366 17.4303 25.998 17.1193C26.0673 17.0502 26.1019 16.9811 26.1366 16.912C26.7603 16.3936 27.1414 15.6679 27.1414 14.8386Z"
      fill="currentColor"/>
  </svg>
</template>
