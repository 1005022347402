import { mapGetters, mapState } from 'vuex'
import { track } from '../../../plugins/analytics'
import { useExperimentsStore } from '@shared/stores/experiments'

export default {
  computed: {
    ...mapGetters('styleQuizNav', [
      'getCurrentStyleQuizRoute'
    ]),
    ...mapState('styleProfile', [
      'birthDate',
      'braSizeBand',
      'braSizeCup',
      'heightFeet',
      'heightInches',
      'signupReasons'
    ]),
    currentRoute () {
      return this.getCurrentStyleQuizRoute({ ...this.$route })
    }
  },
  methods: {
    async gatherSignUpProperties () {
      const { getAnalyticsData } = useExperimentsStore()
      // NOTE the Segment Chrome debugging extension does not like array properties.
      // Comment out signupReasons temporarily if you want to inspect sign-up track() events
      const analyticsData = await getAnalyticsData()
      const properties = {
        isReferral: 'r' in this.$route.query,
        signupReasons: this.signupReasons,
        heightFeet: this.heightFeet,
        heightInches: this.heightInches,
        braSizeBand: this.braSizeBand,
        braSizeCup: this.braSizeCup,
        birthDate: this.birthDate,
        ...analyticsData
      }
      this.$logger.info(properties)
      return properties
    },
    async sendMembershipAnalytics () {
      let eventName = null
      switch (this.$route.name) {
        case 'select-plan' :
          eventName = 'Viewed Select Plan Page'
          break
        case 'subscribe' :
          eventName = 'Viewed Subscribe Page'
          break
        default:
          if (this.currentRoute) {
            eventName = this.currentRoute.analyticsEventName
          }
          break
      }

      if (eventName) {
        track(eventName, await this.gatherSignUpProperties())
      }
    }
  }
}
