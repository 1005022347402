<template>
  <div
    v-click-outside="cancelEditing">
    <error-message
      v-if="saveError"
      id="small-error">
      {{ errorMessage }}
    </error-message>
    <edit-comment
      focus-on-mount
      :initial-comment="itemInFocus.item.comment"
      :disabled="isSavingComment"
      @comment-update="updateLocalComment"/>
    <div class="d-flex ms-5">
      <plain-button
        id="inline-button"
        :disabled="comment === itemInFocus.item.comment || isSavingComment"
        @click="saveCommentUpdate">
        Save
      </plain-button>
      <plain-button
        id="inline-button"
        :disabled="isSavingComment"
        @click="cancelEditing">
        Cancel
      </plain-button>
    </div>
  </div>
</template>

<script>
import PlainButton from '../../../global/sequin/PlainButton'
import ErrorMessage from '../../common/ErrorMessage'
import EditComment from './EditComment'
import { mapState, mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    EditComment,
    ErrorMessage,
    PlainButton
  },
  data () {
    return {
      isSavingComment: false,
      saveError: false,
      errorMessage: 'We encountered an error while trying to save your comment. Please try again.',
      comment: ''
    }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...mapState('community', [
      'itemInFocus'
    ])
  },
  mounted () {
    this.comment = this.itemInFocus.item.comment
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    ...mapVuexActions('community', [
      'updateFeedItemComment'
    ]),
    updateLocalComment (comment) {
      this.comment = comment
    },
    cancelEditing () {
      this.$emit('cancel-click')
    },
    async saveCommentUpdate () {
      this.isSavingComment = true
      this.saveError = false

      try {
        await this.updateFeedItemComment({ ...this.itemInFocus, comment: this.comment })
      } catch {
        this.saveError = true
      }

      this.isSavingComment = false

      if (!this.saveError) {
        this.$emit('comment-saved')
      }
    }
  }
}
</script>

<style lang="scss">
#inline-button {
  color: $orchid;
  padding: 0;
  margin: 4px;
  font-weight: $font-weight-semibold;
  font-size: $small-text-font-size;

  &:disabled {
    opacity: .5;
  }
}

#small-error {
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
}
</style>
