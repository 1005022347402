import { toSnakeCase } from '@/utils/stringParsing.js'

/**
 * Converts an object into a query string
 * @param {object} params
 */
export function serializeParams (params) {
  return Object.keys(params).map(key => {
    const val = params[key]
    if (Array.isArray(val)) {
      if (!val.length) return ''
      return val.map(x => key + '=' + encodeURIComponent(x)).join('&')
    }
    if (val) {
      return key + '=' + encodeURIComponent(val)
    }
    return ''
  }).filter(x => x !== '').join('&')
}

/**
 * Filters out null and undefined values from an object
 * @param {object} params
 */
export function filterEmptyParams (params) {
  if (!params) return {}
  return Object.keys(params).reduce((acc, key) => {
    const val = params[key]
    if (Array.isArray(val)) {
      if (val.length) {
        acc[key] = val.filter(x => x !== null)
      }
    } else if (val) {
      acc[key] = val
    }
    return acc
  }, {})
}

/**
 * Converts params to snake_case and filters out empty values
 * @param {object} params
 */
export function normalizeParams (params) {
  const normalized = filterEmptyParams(params)
  return Object.keys(normalized).reduce((acc, key) => {
    acc[toSnakeCase(key)] = normalized[key]
    return acc
  }, {})
}
