<template>
  <accordion-with-header
    :expanded="expanded"
    :expanded-max-height="800"
    title="Return tracking"
    @accordion-click="e => $emit('accordion-click', e)">
    <div class="mt-3 text-center w-100">
      <div
        v-if="loading"
        class="d-flex justify-content-center">
        <svg-spinner height="40"/>
      </div>
      <div
        v-else-if="returnPackages.length"
        class="w-100">
        <returns-list-mobile
          v-if="isMobile"
          :return-packages="returnPackages"/>
        <returns-list
          v-else
          :return-packages="returnPackages"/>
      </div>
      <div
        v-else
        class="w-100 px-4 px-md-0 mt-md-4 text-center">
        <div>
          You don't currently have any return packages in transit. Packages returned using their enclosed return label will be trackable here.
        </div>
      </div>
      <p class="mt-4 text-gray font-italic px-3 px-md-0 text-center small-text">
        Return packages using labels printed at home are not displayed.
      </p>
    </div>
  </accordion-with-header>
</template>

<script>
import AccordionWithHeader from '../../global/sequin/AccordionWithHeader.vue'
import ReturnsList from './ReturnsList'
import ReturnsListMobile from './ReturnsListMobile'
import apiClient from '../../../store/api/apiClient'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  components: {
    AccordionWithHeader,
    ReturnsListMobile,
    ReturnsList,
    SvgSpinner
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      returnPackages: []
    }
  },
  async created () {
    try {
      const res = await apiClient.getReturnPackages()
      this.returnPackages = res.data
    } catch (err) {
      this.returnPackages = []
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .returns-table {
    width: 100%;
    border: $default-border;
  }
</style>
