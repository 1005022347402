
import { mapGetters, mapActions, mapState, mapActions as mapVuexActions } from 'vuex'

export default {
  computed: {
    ...mapState('styleProfile', [
      'shirtSizes',
      'waistSizes',
      'pantSizes',
      'dressSizes',
      'jumpsuitSizes',
      'sizingTypes'
    ]),
    ...mapGetters('styleProfile', [
      'waistSizeOptionsMapped',
      'numberToLetterSizeOptions',
      'waistSizeType',
      'standardSizeType',
      'plusSizeType'
    ]),
    ...mapState('case', [
      'stylistRequest'
    ]),
    visibleSizeTypes () {
      return this.sizingTypes.filter(x => x.visible === true)
    }
  },
  methods: {
    ...mapActions('styleProfile', [
      'setVisibleSizeTypes',
      'stripSizeType'
    ]),
    ...mapVuexActions('styleProfile', [
      'setSizesFromStyleQuiz',
      'setSizesFromStyleProfile'
    ]),
    getSizeStoreSource (source) {
      switch (source) {
        case 'stylistRequest':
          return this.stylistRequest
        case 'styleQuiz':
        case 'styleProfile':
          return this
      }
    },
    sizesToSubtitle (sizes) {
      if (sizes.length === 0) {
        return 'Required'
      }
      const mappedSizes = sizes.map(size => {
        const options = this.numberToLetterSizeOptions()
        const matchingOption = options.find(option => option.value === size)
        if (matchingOption) {
          return matchingOption.text
        } else {
          return size
        }
      })

      return mappedSizes.join(', ')
    },
    initialSizeSelections (sizeType, sizeCategory, source) {
      const sizeStore = this.getSizeStoreSource(source)
      return this.filterOutSizeType(sizeType, sizeCategory, sizeStore)
    },
    toggleSizeTypeView (val, sizeType, source) {
      const inUse = this.sizingTypes.filter(x => x.visible === true)
      if (inUse.length === 1 && inUse[0].value === sizeType && inUse[0].visible && val === false) {
        return
      }
      this.setVisibleSizeTypes({ category: sizeType, visible: val })
      if (val === false) {
        if (sizeType === 'plus') {
          this.stripSizeType({ sizeType: 'standard', source: source })
        } else {
          this.stripSizeType({ sizeType: 'plus', source: source })
        }
      }
    },
    toggleDisabled (cat) {
      const visibleCats = this.sizingTypes.filter(x => x.visible === true)
      return visibleCats.length === 1 && visibleCats[0].value === cat
    },
    sizeAccordions (source = null) {
      const dataRef = this.getSizeStoreSource(source)
      return [
        {
          title: 'Tops',
          id: 'shirts',
          subtitle: this.sizesToSubtitle(dataRef.shirtSizes),
          selections: dataRef.shirtSizes,
          onInput: e => { dataRef.shirtSizes = e }
        },
        {
          title: 'Pants',
          id: 'waist',
          subtitle: this.sizesToSubtitle(dataRef.waistSizes),
          selections: dataRef.waistSizes,
          onInput: e => { dataRef.waistSizes = e }
        },
        {
          title: 'Dresses',
          id: 'dresses',
          subtitle: this.sizesToSubtitle(dataRef.dressSizes),
          selections: dataRef.dressSizes,
          onInput: e => { dataRef.dressSizes = e }
        },
        {
          title: 'Jumpsuits',
          id: 'jumpsuits',
          subtitle: this.sizesToSubtitle(dataRef.jumpsuitSizes),
          selections: dataRef.jumpsuitSizes,
          onInput: e => { dataRef.jumpsuitSizes = e }
        }
      ]
    },
    async stripSizeType ({ sizeType, source }) {
      const sizeStore = this.getSizeStoreSource(source)
      let commitCall = ''
      let sizeCategories = ['shirts', 'pants', 'dresses', 'jumpsuits']

      switch (source) {
        case 'stylistRequest':
          commitCall = 'case/UPDATE_STYLIST_REQUEST_SIZES'
          break
        case 'styleQuiz':
          sizeCategories = ['allSizes']
          // eslint-disable-next-line no-fallthrough
        case 'styleProfile':
          commitCall = 'styleProfile/SET_SIZES'
          break
      }
      for (const category of sizeCategories) {
        const filteredSizes = this.filterOutSizeType(sizeType, category, sizeStore)
        this.$store.commit(commitCall, { category: category, sizes: filteredSizes })
      }
    },
    getFilteredSizes (sizeData, sizeType, store = this) {
      let filteredSizes = []
      if (sizeType === 'plus') {
        filteredSizes = this.filterOutSizeType('standard', sizeData.ref, store)
      } else {
        filteredSizes = this.filterOutSizeType('plus', sizeData.ref, store)
      }
      return sizeData.sizes.concat(filteredSizes)
    },
    filterOutSizeType (sizeType, sizeCategory, store) {
      let sizes = []
      switch (sizeCategory) {
        case 'shirts':
          sizes = store.shirtSizes
          break
        case 'waist':
          return []
        case 'allSizes':
          sizes = [...new Set([...store.shirtSizes, ...store.dressSizes, ...store.jumpsuitSizes, ...store.pantSizes])]
          break
        case 'dresses':
          sizes = store.dressSizes
          break
        case 'jumpsuits':
          sizes = store.jumpsuitSizes
          break
        case 'pants':
          sizes = store.pantSizes
          break
      }
      return sizes.filter(x => this.numberToLetterSizeOptions(sizeType === 'plus').map(size => size.value).includes(x))
    }
  }
}
