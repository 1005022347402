import {
  getStylingInfo,
  getStylistClosets,
  postAddClientNote,
  postAddStyleColor,
  postDeleteClientNote,
  postRemoveStyleColor,
  postSaveStylingNote,
  postStylingRequest,
  postStylistCloset
} from '@shared/api/styling.js'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export const useStylingStore = defineStore('styling', () => {
  // state
  const stylingInfo = ref({})
  const clientNotes = ref([])
  const clientUsername = ref('')
  const closetId = ref(null)
  const closetItems = ref([])
  const closetStyleColorIds = ref([])
  const newClientNote = ref('')
  const stylistClosetInProgress = ref(false)
  const stylistId = ref(null)
  const stylistInitiated = ref(null)
  const stylingNote = ref('')
  const recentClosets = ref([])

  // getters
  const rentalHistory = computed(() => stylingInfo.value?.rentedStyleColors)

  // initialize vuex store to use for dispatching closet actions in addStyleColor and removeStyleColor
  const store = useStore()

  // helper functions
  function clearCloset () {
    closetId.value = null
    closetItems.value = []
    closetStyleColorIds.value = []
    stylistClosetInProgress.value = false
    stylistInitiated.value = null
    stylistClosetInProgress.value = false
    stylingNote.value = ''
  }
  function getStyleColorIdsFromClosetItems () {
    const styleColorIds = []
    closetItems.value.forEach(closetItem => {
      styleColorIds.push(closetItem.type.styleColor.id)
    })
    closetStyleColorIds.value = styleColorIds
  }
  function selectCloset (selectedClosetId) {
    const closet = recentClosets.value.find(closet => closet.pk === selectedClosetId)
    closetId.value = closet.pk
    stylingNote.value = closet.stylingNote
    closetItems.value = closet.items
    stylistInitiated.value = closet.stylistInitiated
    getStyleColorIdsFromClosetItems()
  }
  function createNewStylistCloset () {
    clearCloset()
    stylistClosetInProgress.value = true
  }
  function resumeStyling (selectedClosetId) {
    stylistClosetInProgress.value = true
    selectCloset(selectedClosetId)
  }
  function stopStyling () {
    clearCloset()
    fetchClosets({ username: clientUsername.value, stylistId: stylistId.value })
  }
  // api calls
  async function createStylingRequest (value) {
    stylistInitiated.value = value
    const data = {
      username: clientUsername.value,
      source: 'Impersonation',
      stylistInitiated: stylistInitiated.value,
      stylist_id: stylistId.value
    }
    const response = await postStylingRequest(data)
    closetId.value = response.data.closet.pk
    stylingNote.value = response.data.closet.stylingNote
  }
  async function fetchClosets (data) {
    clientUsername.value = data.username
    stylistId.value = data.stylistId
    const response = await getStylistClosets()
    if (response.data.closets) {
      recentClosets.value = response.data.closets
      if (response.data.closets.length === 1) {
        const closet = response.data.closets[0]
        selectCloset(closet.pk)
      }
    }
  }
  async function fetchStylingInfo () {
    const response = await getStylingInfo()
    stylingInfo.value = response.data
    clientNotes.value = response.data.clientNotes
  }
  async function addClientNote (impersonator, note) {
    const payload = {
      impersonator: impersonator,
      note: note
    }
    const response = await postAddClientNote(payload)
    clientNotes.value.push(response.data)
    newClientNote.value = ''
  }
  async function deleteClientNote (noteId) {
    const payload = {
      noteId: noteId
    }
    await postDeleteClientNote(payload)
    clientNotes.value = clientNotes.value.filter(note => note.id !== noteId)
  }
  async function saveStylistCloset () {
    const data = {
      closet_id: closetId.value,
      stylist_id: stylistId.value,
      styling_note: stylingNote.value
    }
    await postStylistCloset(data)
    clearCloset()
    fetchClosets(clientUsername.value, stylistId.value)
  }
  async function addStyleColor (styleColorId) {
    const payload = {
      closetId: closetId.value,
      styleColorId: styleColorId
    }
    const response = await postAddStyleColor(payload)
    closetItems.value.push(response.data.item)
    // when an item is added, the myStuff page should update to reflect the new item
    store.dispatch('closet/refreshStylistSection')
    getStyleColorIdsFromClosetItems()
  }
  async function removeStyleColor (styleColorId) {
    const closetItemToRemove = closetItems.value.find(closetItem => closetItem.type.styleColor.id === styleColorId)
    const payload = {
      closetId: closetId.value,
      itemId: closetItemToRemove.pk
    }
    const response = await postRemoveStyleColor(payload)
    if (response.data.success) {
      closetStyleColorIds.value = closetStyleColorIds.value.filter(id => id !== styleColorId)
      closetItems.value = closetItems.value.filter(closetItem => closetItem !== closetItemToRemove)
      // when an item is removed, the myStuff page should update to reflect the change
      store.dispatch('closet/refreshStylistSection')
    }
  }
  async function saveStylingNote (newStylingNote) {
    await postSaveStylingNote({ closet_id: closetId.value, styling_note: newStylingNote })
    stylingNote.value = newStylingNote
  }

  return {
    // state
    stylingInfo,
    clientNotes,
    clientUsername,
    closetId,
    closetItems,
    closetStyleColorIds,
    newClientNote,
    stylistClosetInProgress,
    stylistId,
    stylistInitiated,
    stylingNote,
    recentClosets,
    // getters,
    rentalHistory,
    // actions
    clearCloset,
    getStyleColorIdsFromClosetItems,
    selectCloset,
    createNewStylistCloset,
    resumeStyling,
    stopStyling,
    createStylingRequest,
    fetchClosets,
    fetchStylingInfo,
    addClientNote,
    deleteClientNote,
    saveStylistCloset,
    addStyleColor,
    removeStyleColor,
    saveStylingNote
  }
})
