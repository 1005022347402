<script>
export default {
  methods: {
    filterRentalHistoryItems (filterTerm, rentalHistoryItems) {
      const str = filterTerm.toLowerCase()
      if (!str) return rentalHistoryItems

      return rentalHistoryItems.filter(item => {
        let pos = 0
        let itemBrand = item.styleColor.style.brand.toLowerCase()
        const itemName = item.styleColor.style.name.toLowerCase()
        return [...str].every(s => {
          itemBrand = itemBrand.substr(pos)
          pos = itemBrand.indexOf(s) + 1
          return pos > 0
        }) || itemName.includes(str)
      })
    }
  }
}
</script>
