<!--
Basic horizontal scroll component with scroll buttons at the container edges.
If a button container is provided, the scroll buttons will be teleported to it.

Some styling can be adjusted with CSS variables:
  --button-indent: The distance from the edge of the container to the scroll buttons.
  --item-gap: The gap between items in the scroll container.
  --item-gap-start: The gap between the first item and the edge of the container.
      Defaults to 0, and --item-gap when the fullBleed prop is set.
  --item-gap-end: The gap between the last item and the edge of the container.
      Defaults to 0, and --item-gap when the fullBleed prop is set.
-->
<template>
  <div
    class="horizontal-scroll row"
    :class="{ 'full-bleed' : fullBleed, 'mx-0': !fullBleed }">
    <div
      ref="scrollContent"
      class="scroll-content d-flex g-0"
      :class="{ 'overflow-x-hidden': placeholder }">
      <slot/>
    </div>
    <Teleport
      :to="buttonContainer"
      :disabled="!teleportScrollButtons">
      <ScrollButton
        v-show="showLeftScrollButton"
        direction="left"
        class="scroll-button left"
        @click="scrollLeft"/>
      <ScrollButton
        v-show="showRightScrollButton"
        direction="right"
        class="scroll-button right"
        @click="scrollRight"/>
    </Teleport>
  </div>
</template>

<script setup>
import { useResizeObserver, useScroll } from '@vueuse/core'
import { computed, onActivated, onDeactivated, ref } from 'vue'
import ScrollButton from '@shared/components/ADORN/ScrollButton.vue'
import useScreenSize from '@shared/composables/screenSize.js'

const props = defineProps({
  fullBleed: {
    type: Boolean,
    default: false
  },
  buttonContainer: {
    type: [String, HTMLElement],
    default: () => null
  },
  showButtons: {
    type: Boolean,
    default: true
  },
  placeholder: {
    type: Boolean,
    default: false
  }
})

const scrollContent = ref(null)
const { x: scrollX, arrivedState, isScrolling, measure } = useScroll(scrollContent, { behavior: 'smooth' })
// useScroll doesn't react to resize events. We need this to update the arrivedState on first render.
useResizeObserver(scrollContent, measure)

const scrollPosition = ref(null)
onActivated(() => {
  if (scrollPosition.value) {
    // Restore scroll position on reactivation, bypassing the smooth scroll.
    scrollContent.value.scrollLeft = scrollPosition.value
  }
})
onDeactivated(() => {
  scrollPosition.value = scrollX.value
})

const canScrollLeft = computed(() => !props.placeholder && !arrivedState.left)
const canScrollRight = computed(() => !props.placeholder && !arrivedState.right)

let scrollTimeout = null
function scroll (direction) {
  if (scrollTimeout) {
    clearTimeout(scrollTimeout)
  }
  scrollTimeout = setTimeout(() => {
    scrollTimeout = null
    const distance = scrollContent.value.clientWidth
    scrollX.value += Math.sign(direction) * distance
  })
}
const scrollLeft = () => scroll(-1)
const scrollRight = () => scroll(1)

const teleportScrollButtons = computed(() => props.buttonContainer !== null)
const { isDesktop } = useScreenSize()
const showLeftScrollButton = computed(() =>
  props.showButtons && (teleportScrollButtons.value || (isDesktop.value && canScrollLeft.value))
)
const showRightScrollButton = computed(() =>
  props.showButtons && (teleportScrollButtons.value || (isDesktop.value && canScrollRight.value))
)

defineExpose({ canScrollLeft, canScrollRight, scrollContent, isScrolling })

defineOptions({
  compatConfig: {
    // Suppress warning from useScroll()
    WATCH_ARRAY: false
  }
})
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";

.horizontal-scroll {
  position: relative;
  --button-indent: 60px;
  --item-gap: #{$spacing-sm};

  .scroll-content {
    column-gap: var(--item-gap);
    flex-wrap: nowrap;
    align-items: flex-start;

    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    > :first-child {
      margin-left: var(--item-gap-start, unset);
    }
    > :last-child {
      margin-right: var(--item-gap-end, unset);
    }

    > :slotted(*) {
      flex-basis: auto;

      // Adjust grid-columns so we can peek the last item.
      @each $breakpoint in map-keys($grid-breakpoints) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        $column-adjustment: 2;
        @if $breakpoint == 'lg' or $breakpoint == 'xl' {
          $column-adjustment: 1;
        }
        $adjusted-columns: add($grid-columns, $column-adjustment);
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
          @for $i from 1 through $grid-columns {
            &.col#{$infix}-#{$i} {
              @include make-col($i, $adjusted-columns);
            }
          }
        }
      }
    }
  }

  .scroll-button {
    position: absolute;
    top: 40%;

    &.left {
      left: var(--button-indent);
    }
    &.right {
      right: var(--button-indent);
    }
  }

  &.full-bleed {
    .scroll-content {
      > :first-child {
        margin-left: var(--item-gap-start, var(--item-gap));
      }
      > :last-child {
        margin-right: var(--item-gap-end, var(--item-gap));
      }
    }
  }
}
</style>
