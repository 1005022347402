<template>
  <div>
    <p
      class="text-pewter mb-0">
      <span class="font-italic">
        {{ clientSizeList[1] }}
      </span>
      <span
        v-if="clientSizeList[2]">
        |
      </span>
      <span class="font-italic">
        {{ clientSizeList[2] }}
      </span>
    </p>
  </div>
</template>

<script>
import { ReviewMixin } from '../../ReviewMixin'

export default {
  mixins: [ReviewMixin],
  props: {
    review: {
      type: Object,
      required: true
    }
  }
}
</script>
