<template>
  <svg
    width="26"
    height="22"
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Follow</title>
    <path
      d="M18.4546 20.6364V18.4546C18.4546 17.2973 17.9949 16.1874 17.1765 15.369C16.3582 14.5507 15.2483 14.0909 14.091 14.0909H5.36365C4.20634 14.0909 3.09643 14.5507 2.27808 15.369C1.45974 16.1874 1 17.2973 1 18.4546V20.6364"
      stroke="currentColor"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M9.72718 9.7273C12.1372 9.7273 14.0908 7.77363 14.0908 5.36365C14.0908 2.95367 12.1372 1 9.72718 1C7.3172 1 5.36353 2.95367 5.36353 5.36365C5.36353 7.77363 7.3172 9.7273 9.72718 9.7273Z"
      stroke="currentColor"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M25 20.6364V18.4546C24.9993 17.4878 24.6775 16.5485 24.0852 15.7844C23.4928 15.0203 22.6634 14.4745 21.7273 14.2328"
      stroke="currentColor"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M17.3636 1.14172C18.3023 1.38205 19.1342 1.92795 19.7284 2.69334C20.3225 3.45873 20.6449 4.40009 20.6449 5.36901C20.6449 6.33793 20.3225 7.27929 19.7284 8.04468C19.1342 8.81008 18.3023 9.35597 17.3636 9.5963"
      stroke="currentColor"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>
