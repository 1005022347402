<template>
  <site-page-with-header
    v-if="collectionsData"
    :title="collectionSetHeader"
    :back-button-default-route="{ name: 'closet-sections' }">
    <template #headerSlotDesktop>
      <p class="text-center text-pewter">
        {{ collectionCount }}
      </p>
    </template>
    <div
      class="full-height"
      :class="{ 'share-banner-padding': !id }">
      <p
        v-if="isMobile"
        class="text-pewter small-text text-center mb-4">
        {{ collectionCount }}
      </p>
      <div class="d-flex justify-content-between flex-wrap">
        <collection-preview
          v-for="collection in collectionsData"
          :key="collection.id"
          class="client-collection-preview"
          :class="{ 'mobile': isMobile }"
          title-variant="below"
          :analytics-source="{ type: 'generic-collections', collectionSetFilter }"
          :collection="{
            ...collection,
            toRoute: { name: 'collection', params: { id: collection.id } },
            length: `${collection.count} style${collection.count > 1 ? 's' : ''}`,
          } "/>
        <collection-preview-placeholder
          v-for="index in numPlaceholders"
          :key="`preview-${index}`"
          title-variant="below"
          class="client-collection-preview"
          :visible="collectionsLoading"/>
      </div>
    </div>
  </site-page-with-header>
  <site-page-with-header
    v-else
    :title="collectionSetHeader"
    :back-button-default-route="{ name: 'closet-sections' }">
    <div>
      <div class="d-flex flex-column align-items-center me-md-3">
        <text-placeholder/>
      </div>
      <div class="d-flex justify-content-between flex-wrap mt-5">
        <collection-preview-placeholder
          v-for="(x, index) in 8"
          :key="`preview-loading-${index}`"
          title-variant="below"
          class="client-collection-preview"/>
      </div>
    </div>
  </site-page-with-header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SitePageWithHeader from '../global/sequin/SitePageWithHeader.vue'
import CollectionPreview from './CollectionPreview.vue'
import CollectionPreviewPlaceholder from './CollectionPreviewPlaceholder.vue'
import TextPlaceholder from '../global/sequin/TextPlaceholder.vue'

export default {
  components: {
    SitePageWithHeader,
    CollectionPreview,
    CollectionPreviewPlaceholder,
    TextPlaceholder
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...mapGetters('collections', [
      'numGenericCollections',
      'genericCollections'
    ]),
    collectionSetFilterType () {
      return this.$route.params.filterType
    },
    collectionSetFilter () {
      return this.$route.params.filter
    },
    collectionSetHeader () {
      return this.$route.params.header
    },
    collectionMinItems () {
      return this.$route.params.minItems
    },
    collectionsData () {
      return this.genericCollections(this.collectionSetFilter)
    },
    collectionsLoading () {
      return !this.collectionsData
    },
    numCollections () {
      return this.numGenericCollections(this.collectionSetFilter)
    },
    numPlaceholders () {
      if (!this.collectionsData) return 0
      const rowSize = this.isMobile ? 2 : 4
      return (this.numCollections % rowSize ? (rowSize - this.numCollections % rowSize) : 0) +
        (this.collectionsLoading ? 4 : 0)
    },
    collectionCount () {
      return `${this.numCollections} Collection${this.numCollections === 1 ? '' : 's'}`
    }
  },
  mounted () {
    if (!this.collectionsData) this.fetchGenericCollections({ filterType: this.collectionSetFilterType, filter: this.collectionSetFilter, minItems: this.collectionMinItems })
    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapActions('collections', [
      'fetchGenericCollections'
    ])
  }
}
</script>

<style lang="scss" scoped>
.desktop-buttons {
  max-width: 400px;
}

.full-height {
  min-height: 85vh;
}

.client-collection-preview {
  margin-bottom: 36px;

  &.mobile {
    margin-bottom: 24px;
  }
}

.share-banner-padding {
  padding-bottom: 160px;
}
</style>
