<template>
  <sequin-button
    v-bind="$attrs"
    :variant="(allowSkip && selectedSuggestions.length === 0) ? 'secondary' : 'primary'"
    :disabled="(selectedSuggestions.length === 0 && !allowSkip) || confirming"
    @click="onConfirm">
    {{ (allowSkip && selectedSuggestions.length === 0) ?
      'Skip for Now' :
      `Add ${ selectedSuggestions.length > 0 ? selectedSuggestions.length : '' } Style${ selectedSuggestions.length === 1 ? '' : 's' } to Collection`
    }}
  </sequin-button>
</template>

<script>
import { mapActions } from 'vuex'
import SequinButton from '../global/sequin/SequinButton.vue'

export default {
  components: {
    SequinButton
  },
  props: {
    allowSkip: {
      type: Boolean,
      default: false
    },
    collectionId: {
      type: String,
      required: true
    },
    selectedSuggestions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      confirming: false
    }
  },
  methods: {
    ...mapActions('collections', [
      'addSuggestedStyleColorsToCollection',
      'getCollectionSuggestions'
    ]),
    async onConfirm () {
      this.confirming = true
      if (this.selectedSuggestions.length > 0) {
        await this.addSuggestedStyleColorsToCollection({
          collectionId: this.collectionId,
          styleColors: this.selectedSuggestions
        })
        await this.getCollectionSuggestions({ collectionId: this.collectionId })
      }
      this.confirming = false

      this.$emit('confirmation-success')
    }
  }
}
</script>
