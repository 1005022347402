<!-- A SectionHeadline with buttons that control a horizontal scroll beneath it. -->
<template>
  <div class="d-flex flex-column row-gap-2">
    <SectionHeadline
      ref="headline"
      :title="title"
      :link="link"/>
    <HorizontalScroll
      :button-container="headline?.buttonContainer"
      :full-bleed="fullBleed">
      <slot/>
    </HorizontalScroll>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import SectionHeadline from '@/components/global/SectionHeadline.vue'
import HorizontalScroll from '@shared/components/ADORN/HorizontalScroll.vue'

defineProps({
  title: {
    type: String,
    required: true
  },
  link: {
    type: Object,
    default: () => null,
    validator: value => {
      return typeof value === 'object' &&
        typeof value.text === 'string' &&
        typeof value.to === 'object' &&
        ((typeof value.to.name === 'string' && typeof value.to.params === 'object') ||
          typeof value.to.path === 'string')
    }
  },
  fullBleed: {
    type: Boolean,
    default: false
  }
})

const headline = ref(null)
</script>
