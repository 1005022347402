<template>
  <div class="uploaded-image-wrapper bg-origami">
    <img
      :src="image"
      class="uploaded-image">
    <div class="delete-button-wrapper">
      <sequin-button
        variant="secondary"
        :disabled="!allowDelete"
        class="delete-button"
        @click="$emit('delete-image', image)">
        <svg-x class="x"/>
        Remove Photo
      </sequin-button>
    </div>
  </div>
</template>

<script>
import SequinButton from '../SequinButton'
import SvgX from '@/components/global/svg/SvgX.vue'

export default {
  name: 'SingleImagePreview',
  components: {
    SequinButton,
    SvgX
  },
  props: {
    image: {
      type: String,
      required: true
    },
    allowDelete: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .uploaded-image-wrapper {
    position: relative;

    &.disabled {
      opacity: .7;
    }

    .uploaded-image {
      width: 100%;
      height: auto;
      object-fit: scale-down
    }

    .delete-button-wrapper {
      width: 100%;
      margin: 0 auto;
      position: absolute;

      .delete-button {
        padding: 4px 8px;
        display: flex;
        align-items: center;
        margin: -44px auto 0;

        .x {
          width: 22px;
          height: 22px;
          padding: 2px;
          margin-right: 4px;
        }
      }
    }
  }
</style>
