<template>
  <div>
    <div>
      <div
        v-if="showTitle"
        class="size-category-title d-flex justify-content-between align-items-center">
        <h6
          class="mb-0">
          {{ title }}
        </h6>
        <slot name="subtitle"/>
      </div>

      <div v-if="pdpVariant">
        <div
          v-for="category in pdpSizingCategories"
          :key="category.value">
          <h6
            v-if="category.text"
            class="size-category-title-pdp">
            {{ category.text }}
          </h6>
          <div :class="[containerClass, { 'compact': compactVariant }]">
            <BasePill
              v-for="option in category.sizes"
              :key="`size-${option.value}`"
              type="radio"
              :model-value="itemSelection"
              :disabled="option.disabled"
              :value="option.value"
              name="sizes"
              :text="option.text"
              @update:modelValue="setItemSelection"/>
          </div>
        </div>
      </div>
      <div
        v-else
        :class="containerClass">
        <BasePill
          v-for="option in sizes"
          :key="`size-${option.value}`"
          v-model="itemSelection"
          :type="displayOnly ? 'display' : 'checkbox'"
          :text="option.text"
          :value="option.value"
          name="sizes"/>
      </div>
    </div>
  </div>
</template>

<script>
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import BasePill from '@shared/components/ADORN/BasePill.vue'

const variants = {
  default: 'default',
  compact: 'compact'
}

export default {
  components: {
    BasePill
  },
  mixins: [
    StyleColorMixin
  ],
  props: {
    title: {
      type: String,
      default: 'Sizes'
    },
    initialSelections: {
      type: [Array, String],
      default: () => ([])
    },
    sizes: {
      type: Array,
      default: null
    },
    emitRef: {
      type: String,
      required: true,
      default: null
    },
    containerClass: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    pdpSizingCategories: {
      type: Array,
      default: () => ([])
    },
    displayOnly: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: variants.default,
      validator: value => Object.values(variants).includes(value)
    }
  },
  data () {
    return {
      itemSelection: this.initialSelections ?? []
    }
  },
  computed: {
    showTitle () {
      const pantsSectionStyleProfile = this.source === 'styleProfile' && this.title === 'Pants'
      return !pantsSectionStyleProfile && !this.pdpVariant
    },
    pdpVariant () {
      return this.source === 'pdp'
    },
    compactVariant () {
      return this.variant === variants.compact
    }
  },
  watch: {
    itemSelection: {
      handler (newSelection) {
        if (this.pdpVariant) {
          this.setItemSelection(newSelection)
        } else {
          this.setSizeSelection(newSelection)
        }
      },
      deep: true
    }
  },
  async mounted () {
    if (this.pdpVariant) {
      this.setItemSelection(this.itemSelection)
    }
  },
  methods: {
    setSizeSelection (value) {
      this.$emit('update-size-selections', { ref: this.emitRef, sizes: value })
    },
    setItemSelection (value) {
      this.itemSelection = value
      this.$emit('update-item-selection', this.itemSelection)
    }
  }
}
</script>

<style lang="scss" scoped>
.size-category-title-pdp {
  margin-bottom: 20px;
  margin-top: 20px;
}

.compact {
  // !important flag overrides :deep() selector in parent components.
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  gap: 12px !important;
}
</style>
