<template>
  <date-picker
    v-model="dueDateLocal"
    class="mt-2"
    placeholder="Select due date"/>
</template>

<script>
import DatePicker from '../global/sequin/DatePicker'

import { toDayMonthYearString } from '@/utils/stringParsing.js'
import { mapState } from 'vuex'

export default {
  components: {
    DatePicker
  },
  computed: {
    ...mapState('styleProfile', [
      'dueDate'
    ]),
    dueDateLocal: {
      get () { return this.dueDate && this.dueDate.length > 0 ? new Date(this.dueDate) : null },
      set (v) {
        const dmy = toDayMonthYearString(v)
        this.$store.commit('styleProfile/SET_DUE_DATE', dmy === '...' ? null : dmy)
        this.$emit('input', this.dueDate)
      }
    }
  }
}
</script>
