<template>
  <BasePill
    @update:modelValue="emit('update:modelValue', $event)"/>
</template>

<script setup>
import BasePill from '@shared/components/ADORN/BasePill.vue'
defineOptions({
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
})
const emit = defineEmits(['update:modelValue'])
</script>

<style lang="scss" scoped>
.base-pill {
  box-shadow: 0px 1px 8px rgba($armor, 0.10);
  border-radius: $system-border-radius;
  border: 0.50px solid $ash;
  :deep(span) {
    font-weight: 600;
  }
}
</style>
