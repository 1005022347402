<!--
Button component that can show any combination of an icon, label, and description.
Wrapping element can be customized to alter the button's behavior.
-->
<template>
  <Component
    :is="wrapper"
    :class="['icon-layout px-xs py-sm', layout]"
    @click="emit('click')">
    <div
      v-if="$slots.icon"
      class="icon">
      <slot name="icon"/>
    </div>
    <div
      v-if="label"
      class="label text-button text-center">
      {{ label }}
    </div>
    <div
      v-if="description"
      class="description small-text text-center">
      {{ description }}
    </div>
    <slot/>
  </Component>
</template>

<script setup>
import { computed, useSlots } from 'vue'

const props = defineProps({
  wrapper: {
    type: String,
    default: 'button'
  },
  label: {
    type: String,
    default: null
  },
  description: {
    type: String,
    default: null
  }
})

const emit = defineEmits(['click'])

const slots = useSlots()
const layout = computed(() => {
  const hasIcon = !!slots.icon
  const hasLabel = !!props.label
  const hasDescription = !!props.description
  if (hasIcon && hasLabel && hasDescription) {
    return 'icon-label-description'
  }
  if (hasIcon && hasLabel) {
    return 'icon-label'
  }
  if (hasIcon && hasDescription) {
    return 'icon-description'
  }
  if (hasLabel && hasDescription) {
    return 'label-description'
  }
  if (hasIcon) {
    return 'icon'
  }
  return 'empty'
})
</script>

<style lang="scss" scoped>
.icon-layout {
  border: 0.5px solid $ash;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 1px 8px 0px rgba($armor, 0.10);
  display: flex;
  gap: $spacing-xxs;
  min-height: 64px;
  user-select: none;
  cursor: pointer;

  &:focus, &:focus-within {
    outline: none;
    box-shadow: 0 0 0 1px $plum;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.icon {
    align-items: center;
    justify-content: center;
  }
  &.icon-label {
    flex-direction: column;
    align-items: center;
  }
  &.icon-description {
    flex-direction: column;
    align-items: center;
  }
  &.label-description {
    flex-direction: column;
    align-items: center;
  }
  &.icon-label-description {
    display: grid;
    grid-template-areas: "icon label"
                         "description description";
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;

    .icon {
      grid-area: icon;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .label {
      grid-area: label;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .description {
      grid-area: description;
    }
  }

  .icon :slotted(svg) {
    fill: currentColor;
  }

  .label, .description{
    color: inherit;
  }
}
</style>
