<template>
  <StyleColorSetPage
    v-if="section"
    :style-color-set="styleColorSectionSet"
    :style-colors-loading="styleColorsLoading"
    :update-query-string-filters="enableQueryStringFilters"
    @on-available-now-toggle="toggleAvailability"
    @bottom-of-set="loadMoreStyleColors(100)"
    @clear-all-filters="clear"
    @remove-filter="removeFilter"
    @on-share-style-color-set-page="openShareModal(styleColorSectionSet)"/>
</template>

<script>
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { track } from '../../plugins/analytics'
import StyleColorSetPage from '@/components/styleColorSet/StyleColorSetPage.vue'
import { useModals } from '@/composables/modals.js'
import { useQueryStringFilters } from '@/composables/queryStringFilters.js'

export default {
  components: {
    StyleColorSetPage
  },
  setup () {
    const { openShareModal } = useModals()
    const { selectedQueryStringFilters, queryStringSort } = useQueryStringFilters()

    return {
      selectedQueryStringFilters,
      queryStringSort,
      openShareModal
    }
  },
  computed: {
    ...mapState('closet', [
      'defaultSort',
      'sections',
      'sectionsLoaded',
      'styleColorsLoading',
      'styleColorsMap',
      'globalFilters',
      'sizeFilter'
    ]),
    styleColors () {
      return this.section?.detailView?.styleColors
        ? this.section.detailView.styleColors.map(styleColorId => this.styleColorsMap[styleColorId])
        : null
    },
    displayLength () {
      return this.section.detailView.length
    },
    styleColorSectionSet () {
      return {
        ...this.section,
        filterOptions: {
          ...(this.section.source !== 'collections' ? this.globalFilters.options : this.section.filterOptions)
        },
        filters: JSON.parse(JSON.stringify(this.section.filters)),
        description: this.section.description,
        styleColors: this.styleColors,
        length: this.displayLength,
        available: this.section.detailView?.available,
        sizeFilter: this.sizeFilter,
        showAvailabilityToggle: this.showAvailabilityToggle,
        sort: this.section.detailView.sort ?? this.defaultSort
      }
    },
    allFilters () {
      if ('filters' in this.section) {
        return Object.values(this.section.filters).reduce((a, b) => [...a, ...b])
      }
      return []
    },
    section () {
      return this.sections.find(x => x.id === this.$router.currentRoute.value.params.id)
    },
    isAnonymous () {
      return this.$route.name === 'closet-section-anonymous'
    },
    showAvailabilityToggle () {
      if (!this.section) return false
      return this.section.available !== undefined || this.section.detailView.available !== undefined
    },
    enableQueryStringFilters () {
      return !['collections'].includes(this.section.source)
    }
  },
  watch: {
    async sectionsLoaded () {
      if (!this.section) {
        await this.getSection({
          id: this.$router.currentRoute.value.params.id,
          filters: this.selectedQueryStringFilters,
          sort: this.queryStringSort,
          useDetailView: true
        })
      } else if (this.section.styleColors?.length === 0 && this.section.length !== 0) {
        await this.loadStyleColors({
          id: this.section.id,
          amount: 100,
          filters: this.selectedQueryStringFilters,
          sort: this.queryStringSort,
          useDetailView: true
        })
      }
    }
  },
  async mounted () {
    if (!this.section && (this.sectionsLoaded || this.isAnonymous)) {
      await this.getSection({
        id: this.$router.currentRoute.value.params.id,
        filters: this.selectedQueryStringFilters,
        sort: this.queryStringSort,
        useDetailView: true
      })
    }

    if (this.section && this.section.length !== null) {
      track('Viewed Closet Section', {
        name: this.section.name,
        sectionId: this.section.id,
        itemCount: this.section.length,
        viewedCount: this.section.styleColors?.length || this.section.length,
        items: this.section.styleColors
      })
      // Loads 80 initially to get 100 when combined with the 20 preloaded with section
      if (this.section.length > this.section.styleColors?.length) {
        this.loadMoreStyleColors(80)
      }
    }

    if (!this.globalFilters.options) {
      await this.getGlobalFilterOptions()
    }
  },
  methods: {
    ...mapVuexActions('closet', [
      'getGlobalFilterOptions',
      'getSection',
      'clearFilters',
      'loadStyleColors',
      'updateSection',
      'updateSectionFilters',
      'reloadSection'
    ]),
    async loadMoreStyleColors (amount) {
      if (
        (this.isAnonymous || this.sectionsLoaded) &&
        !this.styleColorsLoading &&
        this.section.detailView.styleColors &&
        this.section.detailView.styleColors.length < this.section.detailView.length) {
        const initialLength = this.section.detailView.styleColors.length
        await this.loadStyleColors({
          id: this.section.id,
          amount: amount,
          useDetailView: true
        })

        // Send the track page view again, so we can log the new items
        if (initialLength !== this.section?.styleColors?.length) {
          track('Viewed Closet Section', {
            name: this.section.name,
            sectionId: this.section.id,
            itemCount: this.section.length,
            viewedCount: this.section.detailView.styleColors.length,
            items: this.section.detailView.styleColors.slice(initialLength, this.section.detailView.styleColors.length)
          })
        }
      }
    },
    scrollToTopOfFilteredView () {
      this.$nextTick(() => window.scrollTo({ top: 0, behavior: 'auto' }))
    },
    clear () {
      if (this.showAvailabilityToggle) {
        this.updateSection({
          id: this.section.id,
          available: false,
          useDetailView: true
        })
      }
      if (this.allFilters.length) {
        this.clearFilters(this.section.id)
      }
      this.reloadSection({ ...this.section, useDetailView: true })
      this.scrollToTopOfFilteredView()
    },
    removeFilter ({ filter }) {
      this.updateSectionFilters({
        section: this.section,
        key: filter.filterType,
        value: this.section.filters[filter.filterType].filter(x => x !== filter.value)
      })
      this.reloadSection({ ...this.section, useDetailView: true })
    },
    toggleAvailability () {
      this.updateSection({
        id: this.section.id,
        available: !this.styleColorSectionSet.available,
        useDetailView: true
      })
      this.reloadSection({ ...this.section, useDetailView: true })
      this.scrollToTopOfFilteredView()
    }
  }
}
</script>

<style lang="scss" scoped>
  svg.sliders {
    transform: rotate(270deg);
    display: inline-block;
    vertical-align: middle;
  }
  .closet-section-page {
    margin: 20px auto;
  }

  .filter-icon {
    font-family: $body-font-family;
    height: $h5-line-height;
  }

  .header-button {
    position: absolute;
    top: 18px;
    left: 6px;
  }

  .thumbnail {
    max-width: 256px;
    margin: 16px 8px;

    &.mobile {
      margin: 16px 4px;
    }
  }
  .max-width-thumbnail {
    max-width: 154px;
  }
  .stylist-name {
  margin-left: 4px;
  margin-bottom: -4px;
  font-size: 1.6em;
  }
</style>
