<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 13.4289C7 10.4126 9.46209 8 12.4603 8C13.7137 8 14.8922 8.41928 15.8314 9.14233C16.7603 8.42314 17.9269 8 19.19 8C22.2084 8 24.6833 10.3999 24.6833 13.4289C24.6833 15.1372 23.8983 16.5781 22.7914 17.5377L15.5379 24.16L8.58087 17.2661C7.57024 16.2646 7 14.9018 7 13.4289ZM12.4603 9.71129C10.3914 9.71129 8.71129 11.3735 8.71129 13.4289C8.71129 14.4519 9.10383 15.3751 9.78541 16.0505L15.5862 21.7987L21.6548 16.2581L21.6639 16.2503C22.44 15.5806 22.9721 14.593 22.9721 13.4289C22.9721 11.3721 21.2906 9.71129 19.19 9.71129C18.1088 9.71129 17.139 10.1572 16.4537 10.8803L15.8336 11.5346L15.2125 10.8812C14.5224 10.1552 13.5349 9.71129 12.4603 9.71129Z"
      fill="currentColor"/>
  </svg>
</template>
