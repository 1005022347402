<template>
  <div
    :class="[`${size}-container`, {'mobile': isMobile}]">
    <base-image
      class="cluster-image first"
      :class="{
        'appear': imageShownIndex === 1,
        'disappear': imageShownIndex === 2
      }"
      :src="getImageUrl(`${size}_1`)"/>
    <base-image
      class="cluster-image second"
      :class="{
        'appear': imageShownIndex === 2,
        'disappear': imageShownIndex === 1 }"
      :src="getImageUrl(`${size}_2`)"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseImage from '@/components/global/BaseImage.vue'

export default {
  components: {
    BaseImage
  },
  props: {
    cluster: {
      type: String,
      required: true
    },
    size: {
      type: String,
      validator: value => {
        return value.match(/(large|large_2|med_1|med_2|small|pattern)/)
      },
      default: 'med_1'
    },
    imageShownIndex: {
      type: Number,
      default: 1,
      validator: value => value === 1 || value === 2
    }
  },
  computed: {
    ...mapState('styleQuizNav', [
      'baseImageUrl'
    ]),
    baseClusterImageUrl () {
      return `${this.baseImageUrl}clusters/${this.cluster}/`
    }
  },
  methods: {
    getImageUrl (imageId) {
      return `${this.baseClusterImageUrl}${imageId}.jpg`
    }
  }

}
</script>

<style lang="scss" scoped>
$unit: 1vw;
$max-unit: .47vh;

.cluster-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;

  &.first { z-index: 2 }
  &.second { z-index: 1 }

  &.appear {
    animation: appear .9s forwards ease-out;
    @keyframes appear {
      0% {
        opacity: 0
      }
      100% {
        opacity: 1
      }
    }
  }

  &.disappear {
    animation: disappear .9s forwards ease-in;

     @keyframes disappear {
      0% {
        opacity: 1
      }

      100% {
        opacity: 0
      }
    }
  }
}

.image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 48.5%;
}

.large-container, .large_2-container {
  @extend .image-container;

  width: 31%;
  height: 100%;

  &.mobile {
    width: 64 * $unit;
    max-width: 64 * $max-unit;
    height: 85 * $unit;
    max-height: 85 * $max-unit;
  }
}

.small-container {
  @extend .image-container;
  &.mobile {
    width: 32*$unit;
    max-width: 32*$max-unit;
    height: 42.5*$unit;
    max-height: 42.5*$max-unit;
  }

}

.med_1-container {
  @extend .image-container;

  &.mobile {
    width: 53*$unit;
    max-width: 53*$max-unit;
    height: 57*$unit;
    max-height: 57*$max-unit;
  }

}

.med_2-container {
  @extend .image-container;

  &.mobile {
    width: 43*$unit;
    height: 57*$unit;
    max-width: 43*$max-unit;
    max-height: 57*$max-unit;
  }
}

.pattern-container {
  @extend .image-container;

  &.mobile {
    width: 32*$unit;
    height: 39*$unit;
    max-width: 32*$max-unit;
    max-height: 39*$max-unit;
  }
}
</style>
