<template>
  <div class="review-item-header-card d-flex column-gap-md px-sm py-xs px-md-0 py-md-0">
    <PreviewImage :src="imageSource"/>
    <div class="d-flex flex-column row-gap-sm">
      <h5 class="m-0">
        {{ brand }} {{ subcategory }}
      </h5>
      <p class="text-pewter">
        Size {{ size }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import { useStore } from 'vuex'

const props = defineProps({
  packageItem: {
    type: Object,
    required: true
  }
})

const store = useStore()
const styleColorsMap = computed(() => store.state.closet.styleColorsMap)

const styleColor = computed(() => styleColorsMap.value[props.packageItem.item.styleColor])
const imageSource = computed(() => styleColor.value.images.front.url)
const brand = computed(() => styleColor.value.style.brand)
const subcategory = computed(() => styleColor.value.style.subcategory)
const size = computed(() => props.packageItem.item.trueSize)
</script>

<style lang="scss" scoped>
.review-item-header-card {
  @include media-mobile {
    border-bottom: $dark-border;

    :deep(.preview-image) {
      width: 66px;
    }
  }

  @include media-tablet-or-larger {
    :deep(.preview-image) {
      width: 200px;
    }
  }
}
</style>
