// Utility methods for detecting and communicating with the mobile app.
import logger from '@shared/logger.js'

export function getMobileWebView () {
  return window.ReactNativeWebView
}

export function inMobileWebView () {
  return !!getMobileWebView()
}

// Enum of standard messages that can be received from the mobile app.
export const IncomingMobileAppMessage = {
  OnAppleSignIn: 'on apple sign in',
  OnFacebookSignIn: 'on facebook sign in',
  OnGoogleSignIn: 'on google sign in'
}
Object.freeze(IncomingMobileAppMessage)

// Enum of standard messages that can be sent to the mobile app.
export const MobileAppMessage = {
  Login: 'login',
  SignUpWithApple: 'sign up with apple',
  SignUpWithFacebook: 'sign up with facebook',
  SignUpWithGoogle: 'sign up with google',
  SignedUp: 'signed up',
  StyleProfileComplete: 'style profile complete',
  StyleProfileLoaded: 'style profile loaded',
  SubscribeLoaded: 'subscribe loaded',
  Subscribed: 'subscribed'
}
Object.freeze(MobileAppMessage)

/** @param {string} message */
export function postToMobileWebView (message, data = {}) {
  if (!Object.values(MobileAppMessage).includes(message)) {
    logger.warn(`Unknown mobile app message: ${message}`)
  }
  const webView = getMobileWebView()
  if (webView) {
    webView.postMessage(JSON.stringify({ message, data }))
  }
}
