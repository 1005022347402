<template>
  <div
    class="size-section-container"
    :class="{'justify-content-center' : isMobile}">
    <transition
      name="fade-photo">
      <style-quiz-banner-image
        v-if="!isMobile"
        :key="`shape-desktop-1`"
        image-name="shape-desktop-1"
        :image-styles="{ 'object-position': isMobile ? 'top' : 'center' }"/>
    </transition>
    <div
      :class="{'desktop-vertical-align sizing-desktop': !isMobile }">
      <div class="px-4 text-center">
        <h6 class="marketing size-subtitle">
          Styles for every shape
        </h6>
        <h4 class="size-title">
          What sizes do you wear?
        </h4>
        <p class="mb-0 small-text">
          Select all that apply. <br v-if="isMobile">You can adjust your sizes at any time.
        </p>
      </div>
      <style-quiz-size-section/>
      <style-quiz-bottom-nav
        class="bottom-nav-sizes"
        :validations="v$"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { minSelected } from '../global/vuelidate/customValidators'
import StyleQuizBottomNav from './StyleQuizBottomNav.vue'
import StyleQuizSizeSection from './StyleQuizSizeSection'
import StyleQuizBannerImage from './StyleQuizBannerImage'

export default {
  components: {
    StyleQuizBottomNav,
    StyleQuizBannerImage,
    StyleQuizSizeSection
  },
  props: {
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    ...mapState('styleProfile', [
      'dressSizes',
      'waistSizes'
    ])
  },
  validations: {
    dressSizes: {
      minSelected: minSelected(1)
    },
    waistSizes: {
      minSelected: minSelected(1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleQuizStyleDefinitions.scss';

.bottom-nav-sizes {
  z-index: 1 ;
}
.size-title {
  font-family: Montserrat;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem; /* 114.286% */
  margin-bottom: 16px;
  @media (max-width: 750px) {
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 24px; /* 120% */
  }
}
.size-subtitle {
  @media (max-width: 750px) {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
  }
}
.background-image {
  filter: blur(20px);
  -webkit-filter: blur(20px);
  opacity: .25;
}

:deep(.desktop-vertical-align) {
  height: 100%;
}

.size-section-container {
  display: flex;
  align-items: center;
  padding: 100px 0;
  @media (max-width: 750px) {
  padding-top: 20px;
  }
}

</style>
