<!-- Dislike Quick Action Button -->
<template>
  <StyleColorQuickActionButton
    :style-color="styleColor"
    :style-color-source="styleColorSource"
    :style-color-source-index="styleColorSourceIndex"
    :size="size"
    @click="onClick">
    <SvgDislike :filled="isDislike"/>
  </StyleColorQuickActionButton>
</template>

<script setup>
import { computed } from 'vue'
import { useModals } from '@/composables/modals.js'
import StyleColorQuickActionButton from './StyleColorQuickActionButton.vue'
import SvgDislike from '@/components/global/svg/SvgDislike.vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@shared/stores/client.js'
import { useClosetStore } from '@shared/stores/closet.js'

const props = defineProps({
  styleColor: {
    type: Object,
    required: true
  },
  /** @type {PropType<StyleColorSource>} */
  styleColorSource: {
    type: Object,
    required: true
  },
  styleColorSourceIndex: {
    type: Number,
    default: () => null
  },
  size: {
    type: String,
    default: 'medium',
    validator: value => ['x-large', 'large', 'medium', 'small', 'tiny'].includes(value)
  }
})
const emit = defineEmits(['click'])

const modals = useModals()

const { isAuthenticated } = storeToRefs(useClientStore())
const closetStore = useClosetStore()
const { ratingsMap } = storeToRefs(closetStore)
const isDislike = computed(() => ratingsMap.value[props.styleColor.id] === -1)
const hasDisLikes = computed(() => Object.values(ratingsMap.value).some(value => value === -1))

async function onClick (event) {
  if (!isAuthenticated.value || !hasDisLikes.value) {
    modals.openClosetRatingInfoModal('dislike')
  }
  if (isAuthenticated.value) {
    const action = isDislike.value ? 'undislike' : 'dislike'
    const payload = { styleColorId: props.styleColor.id }
    await closetStore.dispatch(action, payload)
  }
  emit('click', event)
}

defineExpose({
  isDislike
})
</script>

<style lang="scss" scoped>
// Dislike icon is a different size so we need to override the default sizing.
.action-button {
  --icon-size: 32px !important;
  &.small {
    --icon-size: 28px !important;
  }
}
</style>
