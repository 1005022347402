<template>
  <div class="fullpage d-flex align-items-center">
    <div class="row mx-auto d-flex align-items-center pt-5">
      <div class="col-md img-container pt-1">
        <img
          class="img-fluid py-5"
          src="https://s3-us-west-2.amazonaws.com/armoire-prod/404.png">
      </div>
      <div class="col-md">
        <p class="display-4">
          Wardrobe malfunction!
        </p>
        <p class="large pb-3">
          Sorry, this page does not exist.
        </p>
        <a
          href="/home"
          class="btn btn-outline-info">
          Return Home
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .large {
    font-size: 1.2rem;
  }

  .display-4 {
    font-weight: 500;
  }

  .img-fluid {
    max-height: 50vh;
  }
</style>
