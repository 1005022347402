
<template>
  <div
    class="square-image-container"
    :style="{ width, height: width }">
    <img
      v-if="!hideOutsideOfViewport || inViewport.now"
      class="square-image"
      :src="imageSource"
      :style="{ objectPosition }"
      :class="objectFit"
      :alt="alt">
  </div>
</template>

<script>
import inViewport from 'vue-in-viewport-mixin'

export default {
  mixins: [inViewport],
  props: {
    imageSource: {
      type: String,
      required: true
    },
    width: {
      type: [Number, String],
      default: 64
    },
    hideOutsideOfViewport: {
      type: Boolean,
      default: false
    },
    objectFit: {
      type: String,
      validator: v => v.match('contain|cover'),
      default: () => 'contain'
    },
    objectPosition: {
      type: String,
      default: 'center center'
    },
    alt: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.square-image-wrapper {
  background: $white;
}

.square-image {
  height: 100%;
  width: 100%;

  &.contain {
    object-fit: contain;
  }

  &.cover {
    object-fit: cover;
  }
}
</style>
