<template>
  <div>
    <sequin-button
      class="my-1"
      block
      variant="danger"
      @click="$emit('delete-click')">
      Delete {{ itemName }}
    </sequin-button>
    <sequin-button
      v-if="canEdit"
      class="my-1"
      block
      variant="secondary"
      @click="$emit('edit-click')">
      Edit {{ itemName }}
    </sequin-button>
    <sequin-button
      v-if="isMobile"
      class="my-1"
      block
      variant="secondary"
      @click="$emit('cancel-click')">
      Cancel
    </sequin-button>
  </div>
</template>

<script>
import SequinButton from '../../../global/sequin/SequinButton'

export default {
  components: {
    SequinButton
  },
  props: {
    itemName: {
      type: String,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  }
}
</script>
