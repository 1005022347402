<template>
  <div>
    <style-quiz-referral-applied
      v-if="showReferralBanner"/>
    <div v-else>
      <style-quiz-banner-image
        class="background-image"
        :image-styles="{'object-position': objectPosition ,'object-fit': objectFit}"
        :image-name="useMobileView ? 'intro_image_mobile' : 'intro_image_lrg'"
        :class="{ 'animate': useMobileView && startAnimation, 'image-mod': useMobileView && !isMobile , 'background-image-mobile' : useMobileView, 'background-image-desktop' : !useMobileView}"
        @image-loaded="startAnimation = true"/>
      <div
        class="content-container"
        :class="{ 'mobile': useMobileView }">
        <h4
          class="subtitle"
          :class="{ 'animate': startAnimation, 'text-center': useMobileView }">
          What makes you most excited about clothing rental?
        </h4>
        <style-quiz-checkboxes
          v-model="signupReasons"
          buttons
          class="mt-4 w-100 mb-1 pb-1 checkbox-animation"
          :class="{ 'animate': startAnimation }"
          :max="3"
          :use-mobile-view="useMobileView"
          :options="signupReasonOptions"/>
        <style-quiz-bottom-nav
          :validations="v$.signupReasons">
          <div
            v-if="footerResponse"
            class="text-center">
            <p class="small-text text-secondary semi-bold mb-0">
              {{ footerResponse.title }}
            </p>
            <p
              class="small-text text-secondary px-4"
              v-html="footerResponse.subtitle"/>
          </div>
        </style-quiz-bottom-nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { vuexAccessors } from '../global/helpers/vuex'
import { useVuelidate } from '@vuelidate/core'
import { minSelected } from '../global/vuelidate/customValidators'

import StyleQuizBannerImage from './StyleQuizBannerImage'
import StyleQuizBottomNav from './StyleQuizBottomNav.vue'
import StyleQuizCheckboxes from './StyleQuizCheckboxes'
import StyleQuizReferralApplied from './StyleQuizReferralApplied.vue'
import { MobileAppMessage, postToMobileWebView } from '@/utils/mobileApp.js'

export default {
  components: {
    StyleQuizBannerImage,
    StyleQuizBottomNav,
    StyleQuizCheckboxes,
    StyleQuizReferralApplied
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      startAnimation: false,
      showReferralBanner: false,
      signupReasonResponses: {
        brands: {
          title: 'You\'re so fancy! 💎',
          subtitle: 'We carry over 300 high-end brands in sizes 0-24, so you\'ll always look (and feel) fabulous.'
        },
        experimenting: {
          title: 'Ooh you\'re adventurous! 💃',
          subtitle: 'Armoire lets you shake up your style and take fashion risks without the commitment.'
        },
        money: {
          title: 'Look like a million bucks 💸',
          subtitle: 'Without <em>spending</em> a million bucks. Most members rent $1,200 of clothing each month!'
        },
        time: {
          title: 'Shopping is so 2018 🛍️',
          subtitle: 'Hello convenience, goodbye endless scrolling + trips to the mall.'
        },
        owning: {
          title: 'We\'re all about that Kon Mari life! ✨',
          subtitle: 'Unlimited style, zero clutter. Because you don\'t have to own it to <em>own</em> it.'
        },
        sustainability: {
          title: 'Green looks good on you! ♻️',
          subtitle: 'Renting is 82% more carbon efficient than creating a new generation of clothes.'
        },
        styling: {
          title: 'We can\'t wait to style you! 🧚',
          subtitle: 'Think of our personal stylist as your own fashion fairy godmothers.'
        },
        maternity: {
          title: 'Congratulations, Mama! 🍼',
          subtitle: 'Show off your bump with our exclusive selection of high-end maternity styles.'
        }
      }
    }
  },
  computed: {
    ...mapState('styleQuizNav', [
      'baseImageUrl'
    ]),
    ...mapState('styleProfile', [
      'signupReasonOptions'
    ]),
    ...vuexAccessors('styleProfile', [
      'signupReasons'
    ]),
    ...mapState('subscribe', [
      'referringClient'
    ]),
    useMobileView () {
      return this.isMobile || this.viewAspectRatio < 1.28
    },
    footerResponse () {
      return this.signupReasons.length > 0
        ? this.signupReasonResponses[this.signupReasons[this.signupReasons.length - 1]]
        : null
    },
    objectPosition () {
      return this.useMobileView ? '0% 0%' : 'center right'
    },
    objectFit () {
      return this.useMobileView ? 'cover' : 'contain'
    }
  },
  mounted () {
    if (this.referringClient) {
      this.showReferralBanner = true
    }
    postToMobileWebView(MobileAppMessage.StyleProfileLoaded)
  },
  validations () {
    return {
      signupReasons: {
        signupReasonsMinSelected: minSelected(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleQuizStyleDefinitions.scss';

.background-image {
  top: 0;
  padding-top: 5rem;
  -webkit-filter: blur(0);
  filter: blur(0);

  &.animate {
    animation: blur 0.75s forwards 1.6s ease-out;
  }

  &.image-mod {
    top: -25%;
  }

}
.background-image-mobile {
    width: 100vw;
    opacity: .125;
  }
  .background-image-desktop {
    @media screen and (max-width: 1495px) {
    max-width: 35vw;
    padding:5rem 2rem 0 2rem;
  }
  }
.content-container {
  display: flex;
  padding: 78px;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  min-width: 740px;
  height: 75%;

  &.mobile {
    padding: 4px 20px;
    margin-bottom: 32px;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: unset;
    height: 90%;
  }
}

.subtitle {
  opacity: 0;

  &.animate {
    animation: fade-up .2s forwards 1s ease-in-out;
  }
}

.checkbox-animation {
  opacity: 0;

  &.animate {
    animation: fade-up .2s forwards 1.4s ease-in-out;
  }
}

</style>
