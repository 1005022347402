<template>
  <svg
    width="24"
    height="12"
    viewBox="0 0 24 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="currentColor"
      stroke-width="1.4"
      d="M0 7.3h24"/><path
        d="M18.537 1.167C18.074.42 17.332 0 16.451 0c-1.9 0-3.523 1.703-4.45 3.78C11.097 1.703 9.474 0 7.55 0c-.88 0-1.623.42-2.087 1.167-.602.98-.626 2.076-.07 3.08C6.3 5.88 8.57 7 10.935 7c.371 0 .719-.023 1.067-.093.347.046.695.093 1.066.093 2.364 0 4.636-1.143 5.54-2.753.556-1.004.533-2.1-.07-3.08M6.623 3.57c-.301-.56-.301-1.12.046-1.657.186-.35.487-.513.881-.513 1.09 0 2.898 1.33 3.616 4.2-1.9.07-3.87-.793-4.543-2.03m10.778 0c-.672 1.237-2.642 2.1-4.566 2.03.695-2.87 2.503-4.2 3.616-4.2.394 0 .695.163.904.513.324.537.348 1.097.046 1.657"
        fill="currentColor"/><path
          d="M11 7.5c0 1.5-.8 4.3-4 3.5m6-3.5c0 1.5.8 4.299 4 3.5"
          stroke="currentColor"
          stroke-width="1.4"/>
  </svg>
</template>
