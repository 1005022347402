<template>
  <div
    class="text-placeholder"
    :class="`${variant} ${size} ${block ? 'block' : ''}`"/>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'h5',
      validator: value => {
        return value.match(/(h2|h3|h4|h5|h6|p|small-text|fine-print)/)
      }
    },
    block: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => {
        return value.match(/(large|medium|small|tiny)/)
      }
    }

  }
}
</script>

<style lang="scss" scoped>
$textWidthCoeffLarge: 10;
$textWidthCoeffMedium: 8;
$textWidthCoeffSmall: 5;
$textWidthCoeffTiny: 2;

.text-placeholder {
  @include placeholder-loading-animation;
  margin-bottom: 8px;

  &.h6 {
    height: $h6-line-height;
    width: $h6-font-size * $textWidthCoeffMedium;

    &.tiny {
      width: $h6-font-size * $textWidthCoeffTiny;
    }

    &.small {
      width: $h6-font-size * $textWidthCoeffSmall;
    }

    &.large {
      width: $h6-font-size * $textWidthCoeffLarge;
    }
  }

  &.h5 {
    height: $h5-line-height;
    width: $h5-font-size * $textWidthCoeffMedium;

    &.tiny {
      width: $h5-font-size * $textWidthCoeffTiny;
    }

    &.small {
      width: $h5-font-size * $textWidthCoeffSmall;
    }

    &.large {
      width: $h5-font-size * $textWidthCoeffLarge;
    }
  }

  &.h4 {
    height: $h4-line-height;
    width: $h4-font-size * $textWidthCoeffMedium;

    &.tiny {
      width: $h4-font-size * $textWidthCoeffTiny;
    }

    &.small {
      width: $h4-font-size * $textWidthCoeffSmall;
    }

    &.large {
      width: $h4-font-size * $textWidthCoeffLarge;
    }
  }

  &.h3 {
    height: $h3-line-height;
    width: $h3-font-size * $textWidthCoeffMedium;

    &.tiny {
      width: $h3-font-size * $textWidthCoeffTiny;
    }

    &.small {
      width: $h3-font-size * $textWidthCoeffSmall;
    }

    &.large {
      width: $h3-font-size * $textWidthCoeffLarge;
    }
  }

  &.h2 {
    height: $h2-line-height;
    width: $h2-font-size * $textWidthCoeffMedium;

    &.tiny {
      width: $h2-font-size * $textWidthCoeffTiny;
    }

    &.small {
      width: $h2-font-size * $textWidthCoeffSmall;
    }

    &.large {
      width: $h2-font-size * $textWidthCoeffLarge;
    }
  }

  &.small-text {
    height: $small-text-line-height;
    width: $small-text-font-size * $textWidthCoeffMedium;

    &.tiny {
      width: $small-text-font-size * $textWidthCoeffTiny;
    }

    &.small {
      width: $small-text-font-size * $textWidthCoeffSmall;
    }

    &.large {
      width: $small-text-font-size * $textWidthCoeffLarge;
    }
  }
}

</style>
