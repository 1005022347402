<template>
  <span
    v-if="charsRemaining < 20"
    :class="{ 'text-danger': charsRemaining < 0 }">
    {{ charsRemaining }} character{{ charsRemaining === 1 ? '' : 's' }} remaining
  </span>
</template>

<script>
export default {
  props: {
    charsRemaining: {
      type: Number,
      required: true
    }
  }
}
</script>
