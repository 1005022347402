import logger from '@/logger'
import { captureException, captureMessage } from '@sentry/vue'

export function getSetters (state) {
  const setters = {}
  for (const k of Object.keys(state)) {
    if (state[k] instanceof Function) {
      captureMessage(`Skipping function '${k}' in store state`, { level: 'error' })
      continue
    }
    setters['SET_' + k.replace(/([A-Z])/g, '_$1').toUpperCase()] = (state, val) => {
      // Clone the object to avoid unexpected state mutations.
      try {
        if (val instanceof Function) {
          throw new Error('Do not set functions in store state.')
        }
        if ('structuredClone' in window) {
          // eslint-disable-next-line no-undef
          state[k] = structuredClone(val)
        } else {
          state[k] = JSON.parse(JSON.stringify(val))
        }
      } catch (e) {
        logger.error(`Error setting ${k} to ${val instanceof Function ? 'function' : JSON.stringify(val)}`, e)
        captureException(e, { extra: { [k]: val } })
      }
    }
  }
  return setters
}

export function parseError (err) {
  logger.error(err)
  let errors = []
  captureException(err)

  if (err.response === undefined) {
    errors.push('Network Error')
  } else {
    const data = err.response.data
    if (typeof data === 'string') {
      // Parse Django's 500 html response
      errors.push(
        'Well, this is unexpected... An error has occurred and we\'re ' +
        'working to fix the problem. If you need immediate assistance, ' +
        'please contact us at ' +
        '<b><a href=\'mailto:hi@armoire.style?Subject=Help%20with%20Login target=\'_top\'>' +
        'hi@armoire.style</a></b>.')
    } else {
      // JSON responses should have a dictionary response
      const data = err.response.data

      // Errors from DRF APIException
      if ('detail' in data) {
        errors.push(data.detail)

        let errorCode = '.'
        if ('errorCode' in data) {
          errorCode = ` and refer to error code ${data.errorCode}.`
        }
        // default to showing the help text
        let showHelp = true
        if ('expected' in data) {
          showHelp = data.expected
        }
        if (!showHelp) {
          errors.push('If you need immediate assistance, ' +
            'please contact us at ' +
            '<a href=\'mailto:hi@armoire.style\' target=\'_blank\'>' +
            `hi@armoire.style</a>${errorCode}`)
        }
      }

      // Errors from DRF Serializer Validation
      if ('nonFieldErrors' in data) {
        for (const error of data.nonFieldErrors) errors.push(error)
      }

      // misc / other API errors
      if ('errors' in data) {
        errors = errors.concat(data.errors)
      }
    }
  }
  return errors
}
