<template>
  <svg
    v-bind="$attrs"
    viewBox="0 0 9 15"
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.56055 9.50049V8.81396C2.56055 8.22021 2.68734 7.70378 2.94092 7.26465C3.1945 6.82552 3.65837 6.35856 4.33252 5.86377C4.98193 5.3999 5.40869 5.02262 5.61279 4.73193C5.82308 4.44124 5.92822 4.11654 5.92822 3.75781C5.92822 3.35579 5.77979 3.04964 5.48291 2.83936C5.18604 2.62907 4.77165 2.52393 4.23975 2.52393C3.31201 2.52393 2.25439 2.82699 1.06689 3.43311L0.0556641 1.40137C1.4349 0.628255 2.89762 0.241699 4.44385 0.241699C5.71794 0.241699 6.72917 0.547852 7.47754 1.16016C8.2321 1.77246 8.60938 2.58887 8.60938 3.60938C8.60938 4.28971 8.45475 4.87728 8.14551 5.37207C7.83626 5.86686 7.2487 6.4235 6.38281 7.04199C5.78906 7.48112 5.41178 7.8151 5.25098 8.04395C5.09635 8.27279 5.01904 8.57275 5.01904 8.94385V9.50049H2.56055ZM2.26367 12.6733C2.26367 12.1538 2.40283 11.7611 2.68115 11.4951C2.95947 11.2292 3.36458 11.0962 3.89648 11.0962C4.40983 11.0962 4.80566 11.2323 5.08398 11.5044C5.36849 11.7765 5.51074 12.1662 5.51074 12.6733C5.51074 13.1619 5.36849 13.5485 5.08398 13.833C4.79948 14.1113 4.40365 14.2505 3.89648 14.2505C3.37695 14.2505 2.97493 14.1144 2.69043 13.8423C2.40592 13.564 2.26367 13.1743 2.26367 12.6733Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 16
    },
    width: {
      type: Number,
      default: 16
    }
  }
}
</script>
