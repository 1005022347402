import getKeyedPromise from '@/utils/keyedPromise.js'
import { isBeta, isProd } from '@shared/utils/environment'

export const StripeMixin = {
  data () {
    return {
      card: null,
      stripe: null,
      elements: null
    }
  },
  methods: {
    loadStripe (scriptId = '_stripe-checkout-script') {
      if (typeof Stripe === 'undefined') {
        // Load the Stripe library (if not yet loaded) when user enters Case flyout
        // Takes ~300-400ms on a 3G connection, so this
        // will be done a long time before it's actually needed (in
        // updating card details for purchasing items, bonus items, etc)
        const script = document.createElement('script')
        script.id = scriptId
        script.src = 'https://js.stripe.com/v3/'
        script.type = 'text/javascript'
        document.head.appendChild(script)
      }
    },
    untilStripeLoaded () {
      return getKeyedPromise('stripe', async (resolve) => {
        const checkIfStripeLoaded = () => {
          if (typeof Stripe !== 'undefined') {
            resolve()
          } else {
            this.$logger.info('Stripe not loaded yet, trying again in 1 second')
            setTimeout(checkIfStripeLoaded, 1000)
          }
        }
        checkIfStripeLoaded()
      })
    },
    initializeStripe (cardElementId = null) {
      this.$logger.info('initializeStripe called', cardElementId)
      // Create a Stripe client
      const useProdKey = isProd || isBeta
      const key = useProdKey
        ? process.env.VUE_APP_STRIPE_KEY_PROD
        : process.env.VUE_APP_STRIPE_KEY_TEST
      this.$logger.info(`Initializing Stripe with key for ${useProdKey ? 'prod' : 'test'}`)
      // eslint-disable-next-line
      this.stripe = Stripe(key)
      // Create an instance of Elements.
      this.elements = this.stripe.elements()

      // Add an instance of the card Element into the `card-element` <div>.
      if (cardElementId) {
        this.createCard(cardElementId)
      }

      return this.elements
    },
    createCard (cardElementId) {
      if (!this.card) {
        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        const style = {
          base: {
            color: '#32325d',
            lineHeight: '18px',
            fontFamily: 'Open Sans, "Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        }
        // Create an instance of the card Element.
        const card = this.elements.create('card', { style: style })
        this.card = card
      }

      this.card.mount(`#${cardElementId}`)

      // Handle real-time validation errors from the card Element.
      this.card.addEventListener('change', function (event) {
        const displayError = document.getElementById('card-errors')
        if (event.error) {
          displayError.textContent = event.error.message
        } else {
          displayError.textContent = ''
        }
      })
    }
  }
}
