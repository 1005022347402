<template>
  <div class="d-flex align-items-center me-2">
    <svg-circle-icon
      v-if="showLikes"
      class="interaction-icon like-icon"
      variant="secondary"
      :padding="10"
      :height="size"
      :width="size">
      <svg-like-filled-in/>
    </svg-circle-icon>
    <svg-circle-icon
      v-if="showFavorites"
      class="interaction-icon"
      fill="white"
      :stroke-width="0"
      :padding="10"
      :height="size"
      :width="size">
      <svg-heart/>
    </svg-circle-icon>
  </div>
</template>

<script>
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgLikeFilledIn from '@/components/global/svg/SvgLikeFilledIn.vue'
import SvgHeart from '@/components/global/svg/SvgHeart.vue'

export default {
  name: 'FeedItemActionIcons',
  components: {
    SvgCircleIcon,
    SvgLikeFilledIn,
    SvgHeart
  },
  props: {
    showLikes: {
      type: Boolean,
      default: false
    },
    showFavorites: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 24
    }
  }
}
</script>

<style lang="scss" scoped>
  .interaction-icon {
    outline: solid white 2px;
  }

  .like-icon {
    z-index: 1;
  }
</style>
