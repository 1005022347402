<template>
  <div
    class="bg-origami full-height"
    :style="cssVars">
    <div
      v-if="!feedItemLoadError"
      class="d-flex desktop-wrapper">
      <back-button
        id="back"
        class="me-3"
        with-border
        :back-default-route="{ name: 'feed' }"/>
      <div
        class="d-flex desktop-content bg-white">
        <div
          v-if="!feedItemLoading && (feedItem.image || metadata.type === 'collection')"
          class="image-desktop">
          <feed-item-image
            v-if="feedItem.image"
            :feed-item-id="feedItem.id"
            :height-ratio="feedItem.heightRatio"
            class="feed-deeplink-image"
            :image-source-url="feedItem.image"/>
          <feed-item-collection-preview
            v-if="metadata.type === 'collection'"
            :id="feedItem.item.id"
            :horizontal-border="false"
            :style-colors="feedItem.styleColors"
            :metadata="metadata"/>
          <feed-item-sizes
            v-if="showSizes"
            :client-name="feedItem.client.firstName"
            :style-profile="feedItem.client.styleProfile"
            :style-colors="feedItem.styleColors"
            class="feed-item-sizes"/>
        </div>
        <div
          class="info-desktop"
          :class="{'full-width': !feedItemLoading && (!feedItem.image && metadata.type !== 'collection')}">
          <div
            v-if="!feedItemLoading"
            class="feed-item-loaded">
            <feed-item-header
              class="px-4 py-3 mb-3 header"
              :feed-item="feedItem"/>
            <div class="scrollable">
              <feed-item-style-color
                v-if="feedItem.styleColors && feedItem.styleColors[0] && metadata.type !== 'collection'"
                class="style-color"
                :style-color-source="styleColorSource || source"
                :feed-item-id="feedItem.id"
                :style-colors="feedItem.styleColors"/>
              <feed-item-caption
                v-if="feedItem.caption && feedItem.caption !== ''"
                class="caption"
                :caption="feedItem.caption"/>
              <feed-item-timestamp
                class="timestamp"
                :timestamp="feedItem.created"/>
              <div
                v-if="feedItemComments && feedItemComments.length > 0"
                class="mt-4">
                <feed-item-comment
                  v-for="feedItemComment in feedItemComments"
                  :key="feedItemComment.id"
                  class="comment"
                  :feed-item-id="feedItem.id"
                  :feed-item-comment="feedItemComment"/>
              </div>
              <div v-else-if="commentsLoading">
                <svg-spinner
                  width="40"
                  height="40"
                  class="d-block mx-auto"/>
              </div>
              <error-message
                v-else-if="commentsLoadError"
                class="mx-4">
                We were unable to load the comments for this {{ feedItem.item.type }}
              </error-message>
              <feed-item-action-bar
                v-if="feedItem.item"
                class="action-bar"
                :feed-item-id="feedItem.id"
                :likes-and-favorites="feedItem.likesAndFavorites"
                :comments="feedItem.comments"
                @comment-click="$refs.addCommentBar.focus()"
                @like-click="$emit('like-click', feedItem.id)"/>
              <feed-item-add-comment
                v-if="feedItem.item"
                id="add-comment"
                ref="addCommentBar"
                :feed-item-id="feedItem.id"
                @comment-height-change="setCommentBarHeight"/>
            </div>
          </div>
          <feed-item-placeholder
            v-else
            class="placeholder-desktop"/>
        </div>
      </div>
    </div>
    <feed-item-deeplink-error
      v-else-if="feedItemLoadError"
      class="mt-5 mx-auto bg-origami"/>
  </div>
</template>

<script>
import FeedItemAddComment from './FeedItemAddComment'
import FeedItemHeader from './FeedItemHeader'
import FeedItemImage from './FeedItemImage'
import FeedItemActionBar from './FeedItemActionBar'
import FeedItemCollectionPreview from './FeedItemCollectionPreview.vue'
import FeedItemComment from './FeedItemComment'
import FeedItemCaption from './FeedItemCaption'
import FeedItemPlaceholder from './FeedItemPlaceholder'
import FeedItemDeeplinkError from './FeedItemDeeplinkError'
import FeedItemStyleColor from './FeedItemStyleColor'
import FeedItemTimestamp from './FeedItemTimestamp'
import FeedItemSizes from './FeedItemSizes'
import BackButton from '../../global/sequin/BackButton'
import ErrorMessage from '../common/ErrorMessage'
import { mapGetters } from 'vuex'
import { vuexAccessors } from '../../global/helpers/vuex'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  name: 'FeedItemDeeplinkDesktop',
  components: {
    BackButton,
    ErrorMessage,
    FeedItemImage,
    FeedItemHeader,
    FeedItemActionBar,
    FeedItemAddComment,
    FeedItemCollectionPreview,
    FeedItemComment,
    FeedItemCaption,
    FeedItemDeeplinkError,
    FeedItemPlaceholder,
    FeedItemStyleColor,
    FeedItemTimestamp,
    FeedItemSizes,
    SvgSpinner
  },
  props: {
    feedItem: {
      type: Object,
      default: () => {}
    },
    feedItemLoading: {
      type: Boolean,
      default: true
    },
    feedItemLoadError: {
      type: Boolean,
      default: false
    },
    feedItemComments: {
      type: Array,
      default: () => []
    },
    commentsLoading: {
      type: Boolean,
      default: true
    },
    commentsLoadError: {
      type: Boolean,
      default: false
    },
    styleColorSource: {
      type: String,
      default: null
    },
    source: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      addCommentBarDesktopHeight: '64px'
    }
  },
  computed: {
    ...mapGetters('community', [
      'feedItemMetadata'
    ]),
    ...vuexAccessors('community', [
      'deeplinkAction'
    ]),
    metadata () {
      return this.feedItemMetadata(this.feedItem)
    },
    showSizes () {
      if (!this.feedItem) return false
      const { styleColors, client, item } = this.feedItem
      return styleColors.length &&
        client.styleProfile &&
        item.type === 'look' &&
        this.metadata.type !== 'collection'
    },
    feedItemType () {
      return this.feedItem.item ? this.feedItem.item.type : 'look'
    },
    cssVars () {
      return {
        '--add-comment-desktop': this.addCommentBarDesktopHeight
      }
    }
  },
  methods: {
    setCommentBarHeight () {
      this.addCommentBarDesktopHeight = this.$refs.addCommentBar.$el.clientHeight + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
$action-bar-height: 88px;
$header-height: 92px;

.full-height {
  min-height: calc(100vh - 108px);
}

.comment-container {
  min-height: 40vh;
  padding-bottom: $header-height;
}

.image-desktop {
  position: relative;
  width: 50%;
  background-color: $white;
  display: flex;
  align-items: center;

  .feed-deeplink-image {
    width: 100%;
    max-height: 100%;
    overflow: hidden;

    &.autoHeight {
      height: auto;
    }
  }
}

.action-bar {
  padding: 0 28px 12px;
}

.info-desktop {
  width: 50%;
  position: relative;
  border-left: $default-border;
  height: 100%;

  &.full-width {
    width: 100%;
  }

  .feed-item-loaded {
    height: 100%;
  }

  .scrollable {
    margin-top: $header-height;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - #{$header-height} - #{$action-bar-height} - var(--add-comment-desktop));
    padding-bottom: 12px;
  }

  #add-comment {
    position: absolute;
    width: 100%;
    bottom: 0;
    min-height: 64px;
  }

  .action-bar {
    padding: 12px 28px;
    width: 100%;
    position: absolute;
    bottom: var(--add-comment-desktop);
    border-top: $default-border;
    background-color: $white;
  }

  .header {
    position: absolute;
    top: 0;
    border-bottom: $default-border;
    width: 100%;
  }
}

.caption {
  margin: 12px 28px 8px;
}

.comment {
  margin: 16px 28px;
}

.timestamp {
  margin: 0 28px;
}

#back {
  border: $default-border;
}

.desktop-wrapper {
  max-width: 960px;
  height: 80vh;
  max-height: 700px;
  margin: 0 auto;
}

.desktop-content {
  height: 100%;
  width: calc(100% - 24px);
  border: $default-border;
  border-radius: 2px;
}

.placeholder-desktop {
  height: 65vh;
  overflow: hidden;
  border: none;
  max-height: 600px;
}

.feed-item-sizes {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
