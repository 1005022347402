<template>
  <div class="accordion">
    <div
      ref="content"
      :style="{ maxHeight: expanded ? expandedMaxHeight : collapsedMaxHeight }"
      class="expandable-content">
      <slot/>
    </div>
    <text-link
      v-if="isOverflown"
      v-bind="expandLinkProps"
      @click="onExpandLinkClick">
      {{ expanded ? collapseText : expandText }}
    </text-link>
  </div>
</template>

<script>
import TextLink from './TextLink'
export default {
  components: {
    TextLink
  },
  props: {
    collapseText: {
      type: String,
      default: () => 'Hide Details'
    },
    expandText: {
      type: String,
      default: () => 'Details'
    },
    expandLinkProps: {
      type: Object,
      default: () => ({
        variant: 'tertiary'
      })
    },
    collapsedMaxHeight: {
      type: Number,
      default: () => 0
    },
    expandedMaxHeight: {
      type: Number || String,
      default: () => 200
    }
  },
  data () {
    return {
      isOverflown: true,
      expanded: false
    }
  },
  mounted () {
    // Refs are only accessible upon render, so this can't just be a computed property
    const { scrollHeight, clientHeight, scrollWidth, clientWidth } = this.$refs.content
    this.isOverflown = scrollHeight > clientHeight || scrollWidth > clientWidth
  },
  methods: {
    onExpandLinkClick () {
      this.expanded = !this.expanded
      this.$emit('accordion-click', this.expanded)
    }
  }
}
</script>

<style lang="scss" scoped>
.expandable-content {
  @include transition-base-all;
  overflow-y: hidden;
}

</style>
