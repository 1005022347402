<template>
  <component
    :is="condensed ? 'text-line-limit' : 'div'"
    class="comment"
    :line-limit="2">
    <b>{{ feedItemComment.client.firstName }}<span v-if="feedItemComment.client.lastName"> {{ feedItemComment.client.lastName }}</span></b>
    <span class="comment-text">{{ feedItemComment.comment }}</span>
  </component>
</template>

<script>
import TextLineLimit from '../../global/sequin/TextLineLimit'

export default {
  components: {
    TextLineLimit
  },
  props: {
    feedItemComment: {
      type: Object,
      required: true
    },
    condensed: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.comment {
  font-size: $small-text-font-size;
  line-height: 1.25rem;
  margin-bottom: 0;
}

.comment-text {
  color: $pewter;
  margin-left: 6px;
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
