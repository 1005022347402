<!-- Stylized checkbox for reviews -->
<template>
  <BaseCheckbox
    v-model="value"
    solid-box
    class="review-item-checkbox user-select-none"/>
</template>

<script setup>
import { computed } from 'vue'
import BaseCheckbox from '@/components/global/BaseCheckbox.vue'

const props = defineProps({
  modelValue: {
    type: [Boolean, Array],
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

defineOptions({
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
})
</script>

<style lang="scss" scoped>
.review-item-checkbox {
  :deep(label) {
    margin: 0px;
  }
}
</style>
