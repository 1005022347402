<template>
  <plan-select-page
    header="Rejoin"
    subheader="Select your new plan"
    :selectable-plans="membershipPlans.filter(plan => !plan.prepayment)"
    :breadcrumb="{ name: 'Rejoin', to: 'rejoin' }"/>
</template>

<script>
import PlanSelectPage from './PlanSelectPage'
import MembershipMixin from './MembershipMixin'

export default {
  name: 'RejoinPage',
  components: { PlanSelectPage },
  mixins: [MembershipMixin]
}
</script>

<style scoped>

</style>
