<template>
  <div
    class="bg-origami py-3 px-4 border-top">
    <div class="row">
      <div
        v-for="sizeBox in sizeBoxes"
        :key="sizeBox.label"
        class="col col-6 col-sm-4">
        <profile-box
          :label="sizeBox.label"
          :value="sizeBox.value"
          :icon-name="sizeBox.icon"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileBox from './ProfileBox'

export default {
  components: {
    ProfileBox
  },
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      getSizeRange: range => range ? range.length > 1 ? `${range[0]} - ${range[range.length - 1]}` : `${range[0]}` : ''
    }
  },
  computed: {
    height () {
      if (!this.profile) {
        return ''
      }
      return `${this.profile.styleProfile.heightFeet}'${this.profile.styleProfile.heightInches}"`
    },
    bust () {
      if (!this.profile) {
        return ''
      }
      return `${this.profile.styleProfile.braSizeBand}${this.profile.styleProfile.braSizeCup}`
    },
    dresses () {
      if (!this.profile) {
        return ''
      }
      return `${this.getSizeRange(this.profile.styleProfile.dressSizes)}`
    },
    tops () {
      if (!this.profile) {
        return ''
      }
      return `${this.getSizeRange(this.profile.styleProfile.shirtSizes)}`
    },
    pants () {
      if (!this.profile) {
        return ''
      }
      return `${this.getSizeRange(this.profile.styleProfile.pantSizes)}`
    },
    denim () {
      if (!this.profile) {
        return ''
      }
      return `${this.getSizeRange(this.profile.styleProfile.waistSizes)}`
    },
    sizeBoxes () {
      return [
        { label: 'Height', value: this.height, icon: 'height.png' },
        { label: 'Bust', value: this.bust, icon: 'bust.png' },
        { label: 'Dresses', value: this.dresses, icon: 'dresses.png' },
        { label: 'Tops', value: this.tops, icon: 'tops.png' },
        { label: 'Pants', value: this.pants, icon: 'pants.png' },
        { label: 'Denim', value: this.denim, icon: 'denim.png' }
      ]
    }
  }
}

</script>
