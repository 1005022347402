<template>
  <div>
    <style-quiz-banner-image
      :image-name="isMobile ? 'sizes-mobile' : 'sizes-desktop'"
      :class="['background-image', { 'animate': startAnimation && isMobile }]"
      :image-styles="{ 'object-position': 'top', 'object-fit': 'cover' }"
      @image-loaded="startAnimation = true"/>
    <div
      :class="{'desktop-vertical-align': !isMobile }"
      class="px-4 pb-2">
      <div class="text-center">
        <h6
          v-if="!isMobile"
          class="marketing">
          Sizing
        </h6>
        <component :is="isMobile ? 'h6': 'h4' ">
          Your perfect fit
        </component>
        <p
          :class="{ 'px-5': !isMobile }"
          class="small-text">
          Our algorithm learns your unique shape, and recommends styles to make you feel confident and comfortable.
        </p>
      </div>
      <div
        class="text-center form"
        :class="{ 'animate': startAnimation }">
        <h6 class="mt-3 mb-xs">
          How tall are you?
        </h6>
        <div class="d-flex justify-content-center gap-2 mb-4">
          <div class="d-flex">
            <p
              v-if="showLabels"
              class="mb-0 small-text">
              Feet
            </p>
            <dropdown-single-select
              v-model="heightFeet"
              small
              class="height-dropdown"
              placeholder="Feet"
              :initial-selection-index="getInitialSelectionIndex('heightFeet')"
              :dropdown-options="heightFeetOptions"
              :validations="v$.heightFeet"/>
          </div>
          <div>
            <p
              v-if="showLabels"
              class="mb-0 small-text">
              Inches
            </p>
            <dropdown-single-select
              v-model="heightInches"
              small
              class="height-dropdown"
              placeholder="Inches"
              :initial-selection-index="getInitialSelectionIndex('heightInches')"
              :dropdown-options="heightInchesOptions"
              :validations="v$.heightInches"/>
          </div>
        </div>
        <h6 class="mt-3 mb-xs">
          What size bra do you wear?
        </h6>
        <style-quiz-bra-size
          class="d-flex justify-content-center gap-2 mb-4"/>
        <h6 class="mb-0 mt-3 px-4">
          Are you pregnant and interested in maternity styles?
        </h6>
        <base-radios
          v-model="isMaternityCustomer"
          row-class="justify-content-center gap-2 mt-3 mx-5"
          col-class="px-3 semi-bold"
          buttons
          :options="[{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false'}]"/>
      </div>
    </div>
    <style-quiz-bottom-nav
      :validations="v$"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, between } from '@vuelidate/validators'

import BaseRadios from '@/components/global/BaseRadios.vue'
import DropdownSingleSelect from '../global/sequin/DropdownSingleSelect'
import StyleQuizBottomNav from './StyleQuizBottomNav.vue'
import { vuexAccessors } from '../global/helpers/vuex'
import StyleQuizBannerImage from './StyleQuizBannerImage.vue'
import StyleQuizBraSize from './StyleQuizBraSize'

export default {
  components: {
    BaseRadios,
    DropdownSingleSelect,
    StyleQuizBottomNav,
    StyleQuizBannerImage,
    StyleQuizBraSize
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      startAnimation: false
    }
  },
  computed: {
    ...mapState('styleProfile', [
      'braSizeBandOptions',
      'braSizeBand',
      'braSizeCupOptions',
      'braSizeCup',
      'heightFeetOptions',
      'heightInchesOptions',
      'heightFeet',
      'heightInches'
    ]),
    ...vuexAccessors('styleProfile', [
      'isMaternityCustomer',
      'heightFeet',
      'heightInches'
    ]),
    minHeightFeet () {
      return Math.min(...this.heightFeetOptions.map(x => parseInt(x.text)))
    },
    maxHeightFeet () {
      return Math.max(...this.heightFeetOptions.map(x => parseInt(x.text)))
    }
  },
  watch: {
    isMaternityCustomer (newValue) {
      this.$store.commit('styleQuizNav/SET_STYLE_QUIZ_ROUTE_DISPLAYED', { name: 'style-quiz-due-date', display: newValue === 'true' })
    }
  },
  methods: {
    getInitialSelectionIndex (type) {
      const optionType = `${type}Options`
      if (this[type] && this[optionType]) {
        const index = this[optionType].findIndex(option => option.value === this[type])
        if (index > -1) return index
      }

      return null
    }
  },
  validations () {
    return {
      braSizeBand: {
        required
      },
      braSizeCup: {
        required
      },
      heightFeet: {
        required,
        numeric,
        betweenValue: between(this.minHeightFeet, this.maxHeightFeet)
      },
      heightInches: {
        required,
        numeric,
        betweenValue: between(0, 11)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './StyleQuizStyleDefinitions.scss';

.height-dropdown {
  min-width: unset;
  width: 88px;
}

.background-image {
  &.animate {
    animation: blur-fade 1.5s forwards .8s ease-out;
  }
}

.form {
  opacity: 0;
  &.animate {
    animation: fade-up .4s forwards 1s ease-in;
  }
}
</style>
